import React from 'react'

function BrandCard({brand}) {
  return (
    <div className='col-md-6'>
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                    {/* <h5 className='card-title'>{brand.name}</h5> */}
                    <a className='btn btn-light' href={`/brands/${brand.brandId }/0`}>View</a>
                </div>
                <p className="small">
                    Brand Description : <strong>{brand.description}</strong>
                </p>
                <p className="small">
                    Brand Organization : <strong>{brand.organization.name}</strong>
                </p>
                <img src={brand.imageUrl} style={{width:"100px", height:"100px"}}/>
            </div>
        </div>
    </div>
  )
}

export default BrandCard