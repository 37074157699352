import { createCanvas, loadImage } from "canvas";
import * as tf from "@tensorflow/tfjs-core";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";

const detectEyes = async (file) => {
  try {
    // console.log("Test1");
    // const file = fileInputRef.current.files[0];
    const image = await loadImage(file);

    // const img = imgInputRef.current;
    const canvas = createCanvas(image.width, image.height);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // const model = await blazeface.load();
    const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    const detectorConfig = {
      runtime: "mediapipe", // or 'tfjs'
      solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
    };
    const detector = await faceLandmarksDetection.createDetector(
      model,
      detectorConfig
    );
    // console.log("Test2");

    const predictions = await detector.estimateFaces(canvas);
    // console.log(predictions, "Test3");
    let arr = [],
      minX = Infinity,
      minY = Infinity,
      yper = Infinity;

    if (predictions.length > 0) {
      predictions.forEach((prediction) => {
        const landmarks = prediction.keypoints;
        landmarks.forEach((landmark) => {
          if (
            landmark.name &&
            String(landmark.name).toLowerCase().trim() === "righteye"
            // String(landmark.name).toLowerCase().includes('eye')
          ) {
            arr.push(landmark);
            const x = landmark["x"];
            if (minX > x) minX = x;
            const y = landmark["y"];
            if (minY > y) minY = y;
            let yp = (y / canvas.height) * 100;
            if (yper > yp) yper = yp;
            ctx.beginPath();
            ctx.arc(x, y, 2, 0, 2 * Math.PI);
            ctx.fillStyle = "red";
            ctx.fill();
          }
        });
      });
    }
    // console.log(arr, "Arr");
    // console.log(minX, "X", minY, "Y", "Arr", yper, canvas.height);

    // changePosition(yper <= 30);
    return yper;
  } catch (err) {
    // console.log(err + "Testttt2");
    return Infinity;
  }
};

export default detectEyes;
