import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ModalTrash from "../../assets/icons/ModalTrash.svg";
import ReqIcon from "../../assets/icons/reqIcon.svg";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import RoundClose from "../../assets/icons/roundClose.svg";
import {
  DeleteButton,
  DeleteButtonText,
  DeleteHeader,
  DeleteText,
} from "../../pages/Organizations/styled";
import {
  BatchBx,
  BatchBxT1,
  BatchBxT2,
  BatchItem,
  BatchItemBox,
  BatchItemText,
  ContHeader,
  EmailCont,
  EmailContBdy,
  HeaderText,
  SubHeaderCont,
  SubHeaderCont1,
  SubHeaderCont1_1,
  SubHeaderCont1_1_1,
  SubHeaderCont1_1_1Text,
  SubHeaderCont1_1x,
  SubHeaderCont1_2,
  SubHeaderCont1_2Text,
  SubHeaderCont2,
  SubHeaderCont2Box,
  TextCont,
} from "./styled";
import GIcon from "../../assets/icons/GoogleIcon.svg";
import PhCheck from "../../assets/icons/phonecheck.svg";
import EmailIcon from "../../assets/icons/email.svg";
import CTextfield from "../CTextfield";
import GoogleRVDisplay from "../../pages/Installation/googleRvInstall/googleRVDisplay";
import { remainingTextCount } from "../../utils/format";
import {
  Section1_2Box,
  Section1_2BottomBox,
} from "../../pages/Installation/googleRvInstall/styled";
import ColorButton from "../ColorButton";
import { useMutation } from "@apollo/client";
import { SEND_GOOGLE_REVIEW_REQUEST } from "../../graphql/mutations/googleReview";
import { useSnackbar } from "notistack";

export default ({
  openModal,
  handleCloseModal,
  handleModalConfirm,
  isMobile,
  headerText = "Request a testimonial?",
  subHeaderText = "You are about to request for a testimonial",
  btnText = "Request",
  formstate,
  item,
  idsCount, //may not be needed
  batchRecord = [],
  br_id,
}) => {
  // formstate = {};
  const [sendGoogleReviewReq] = useMutation(SEND_GOOGLE_REVIEW_REQUEST);
  const { enqueueSnackbar } = useSnackbar();

  const [viewAll, setViewAll] = useState(false);
  const [batchRec, setBatchRec] = useState(batchRecord);
  idsCount = batchRec?.length ?? 0;
  // console.log(batchRecord, "batchRecord selected");
  const customStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.4)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      display: "flex",
      boxSizing: " border-box",

      flexDirection: "column",
      alignItems: "center",
      // alignItems: "flex-start",
      padding: "24px 32px",
      // padding: "16px 16px 24px 24px",
      // alignSelf: "center",
      // top: "50%",
      // left: "50%",
      // right: "auto",
      // bottom: "auto",
      // marginRight: "-50%",
      // transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "580px",
      top: "10%",
      maxidth: "90%",
      left: "30%",
      //   height: !isMobile && "312px",

      background: "#FFFFFF",
      gap: "32px",
      border: "1px solid #ECECF2",
      borderRadius: "15px",
    },
  };
  const textMessageCount = 400;

  const displayBatchItemComp = () => {
    return (
      <BatchItemBox>
        {batchRec.map((batchItem, index) => (
          <BatchItem key={`Bat${index}`}>
            <BatchItemText>{batchItem?.name}</BatchItemText>{" "}
            <img
              src={RoundClose}
              style={{ cursor: "pointer" }}
              onClick={() => removeItem(batchItem?._id)}
            />
          </BatchItem>
        ))}
      </BatchItemBox>
    );
  };

  const simpleStatement = () => {
    let cnt = batchRec.length;
    if (cnt < 2) {
      return `${batchRec.map((rec) => rec?.name).join("")} contact selected`;
    }
    let firstLeadName = batchRec[0]?.name;
    return `${firstLeadName} and ${cnt - 1} ${
      cnt - 1 > 1 ? "more contacts selected" : "contact selected"
    }`;
  };

  const removeItem = (item) => {
    setBatchRec(batchRec.filter((rec) => rec?._id !== item));
  };

  const handleSubmit = async () => {
    try {
      let leads =
        idsCount === 0 && item?._id
          ? [item?._id]
          : batchRec.map((el) => el?._id);
      let { data: dataContact, errors } = await sendGoogleReviewReq({
        variables: {
          input: { br_id, leads },
        },
      });

      if (!dataContact && errors.length > 0) {
        enqueueSnackbar(`Error: ${errors[0].message ?? ""}`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sent Successful", { variant: "success" });
        handleCloseModal();
      }
    } catch {
      enqueueSnackbar("Error Updating ", { variant: "error" });
    }
  };

  useEffect(() => {
    setBatchRec(batchRecord);
  }, [batchRecord]);

  return (
    <Modal
      isOpen={openModal}
      onRequest
      Close={handleCloseModal}
      ariaHideApp={false}
      style={customStyles}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <HeaderText>
          Request{" "}
          <span>
            <img src={GIcon} alt="" />
          </span>{" "}
          Google Review
        </HeaderText>
        <img
          src={RemoveIcon}
          style={{ cursor: "pointer" }}
          alt="modal-delete"
          onClick={handleCloseModal}
        />
      </div>
      <div
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          maxHeight: "70vh",
        }}
      >
        <SubHeaderCont>
          <SubHeaderCont1>
            <SubHeaderCont1_1x>
              <SubHeaderCont1_1>Send Request via </SubHeaderCont1_1>
              <SubHeaderCont1_1_1>
                <img src={EmailIcon} alt="envelope" />
                <SubHeaderCont1_1_1Text>Email</SubHeaderCont1_1_1Text>
              </SubHeaderCont1_1_1>
            </SubHeaderCont1_1x>
            <SubHeaderCont1_2>
              <img src={PhCheck} />
              <SubHeaderCont1_2Text>Phone Number</SubHeaderCont1_2Text>
            </SubHeaderCont1_2>
          </SubHeaderCont1>
          {idsCount === 0 ? (
            <SubHeaderCont2>
              <SubHeaderCont2Box>
                <CTextfield value={item?.email} disabled />
              </SubHeaderCont2Box>
              <SubHeaderCont2Box>
                <CTextfield disabled value={item?.phoneNumber ?? ""} />
              </SubHeaderCont2Box>
            </SubHeaderCont2>
          ) : (
            <BatchBx
              style={{
                display: idsCount > 1 && viewAll ? "inline-block" : undefined,
              }}
            >
              {viewAll ? (
                displayBatchItemComp()
              ) : (
                <BatchBxT1>{simpleStatement()}</BatchBxT1>
              )}

              {idsCount > 1 && (
                <BatchBxT2 onClick={() => setViewAll(!viewAll)}>
                  {viewAll ? "View Less" : "View All"}
                </BatchBxT2>
              )}
            </BatchBx>
          )}
        </SubHeaderCont>
        <EmailCont>
          <ContHeader>Email</ContHeader>
          {/* <EmailContBdy> */}
          <GoogleRVDisplay embed formstate={formstate} />
          {/* </EmailContBdy> */}
        </EmailCont>
        <TextCont>
          <ContHeader>Text Message</ContHeader>
          <Section1_2Box style={{ height: "auto" }}>
            <CTextfield
              outlineStyle={{ border: "none" }}
              rootStyle={{ height: "auto", borderRadius: "12px" }}
              multiline
              rows={3}
              disabled
              fullWidth
              value={formstate?.textMessage ?? ""}
              inputProps={{
                maxLength: textMessageCount,
              }}
            />
            <Section1_2BottomBox>
              {remainingTextCount(formstate?.textMessage, textMessageCount)}
            </Section1_2BottomBox>
          </Section1_2Box>
        </TextCont>
      </div>
      <ColorButton
        style={{ alignSelf: "flex-start" }}
        onClick={handleSubmit}
        title="Send Request"
        background={"#1A1B24"}
      />
    </Modal>
  );
};
