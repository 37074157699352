import styled from "@emotion/styled";
import { Box, Button, Divider, Typography, Link } from "@mui/material";

export const StyledTextButtonContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  height: 80vh;
  width: 60%;
  padding-top: 1rem;
  @media (max-width: 900px) {
    margin-top: 5rem;
  }
`;

export const StyledHeaderText = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

export const StyledLogoContainer = styled(Box)`
  width: 105px;
  height: 25.77px;
  left: 105px;
  top: 35.95px;
`;
export const StyledHeadingText = styled(Typography)`
  font-size: 34px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;
export const GoogleButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #c1c7ca;
  border-radius: 7px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
    margin-top: 1rem;
  }
`;
export const StyledFormLabel = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;

  color: #000000;

  @media (max-width: 900px) {
  }
`;
export const StyledDividerText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: -10px;

  @media (max-width: 900px) {
    margin-top: 0.5rem;
  }
`;
export const StyledDivider = styled(Divider)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    margin-top: 0.5rem;
  }
`;
export const TextInputContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledSignUpButton = styled(Button)`
  background: #070b0d;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  border-radius: 7px;
  color: #fff;
  text-transform: capitalize;
`;
export const StyledSignUpImageOverallContainer = styled(Box)`
  height: 100%;
  display: flex;
  width: 100%;
  padding: 15px;
  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;
export const StyledSignUpImageContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  padding-bottom: 25px;
  justify-content: space-between;
  border-radius: 21px;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
`;
export const StyledSignUpImageArrowContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledImageText = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;
export const StyledImageHeaderText = styled(Typography)`
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;
export const FlexRowLinksContainer = styled(Box)`
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const StyledLink = styled(Link)`
  // color: #8d6cff;
  color: #007bf7;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-decoration-line: underline;
`;
