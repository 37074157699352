import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import {
  CustomSelect,
  FlexContainer,
  InviteMemberMainText,
  InviteMemberText,
  LeftTitle,
  MembersContainer,
  OptionItem,
  OptionText,
  OrganizationText,
  OwnerText,
  SubTitle,
  SubTitleContainer,
  TimeText,
  TitleHeader,
} from "../TeamMembers/styled";
import Button from "../../components/Button";
import SubHeader from "../../components/SubHeader";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import Search from "../../components/Search";
import Table from "../../components/Table";
import Filter from "../../components/Filter";
import NameTag from "../../components/NameTag";
import { GET_MEMBERS_LIST } from "../../graphql/queries/invitation";
import {
  GET_USER_ORGANIZATION,
  GET_USER_OWNED_ORGANIZATION,
} from "../../graphql/queries/organizationQueries";
import { useSnackbar } from "notistack";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MultipleSelect from "../../components/MultipleSelect";

import { UPDATE_USER_ORGANIZATION_INVITE_ACCESS } from "../../graphql/mutations/userOrganization";
import {
  RESEND_USER_OGRANIZATION_INVITE,
  DELETE_USER_OGRANIZATION_INVITE,
} from "../../graphql/mutations/organizationMutation";
import moment from "moment/moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropdown from "../../components/Dropdown";
import EllipsisLink from "../../components/EllipsisLink";
import ButtonCust from "../../components/ButtonCust";
import Plus from "../../assets/icons/Plus.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import OrgnIcon from "../../assets/icons/OrgnIcon.svg";
import Modal from "../../components/Modal";
import { set } from "react-hook-form";
import SpinnerBig from "../../components/SpinnerBig";
import NameTagBrowse from "../../components/NameTag/NameTagBrowse";
import ColorButton from "../../components/ColorButton";
import { NavSearch } from "../UserDashboard/styled";
import { TabTitle } from "../../utils/HeaderTitle";

export default function BrowseAll() {
  TabTitle(`Browse All`);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalArray, setModalArray] = useState([]);
  const [members, setMembers] = useState([]);
  let organizationArray = [];
  const [expanded, setExpanded] = useState([]);
  // console.log("expanded", expanded);
  // const [expanded, setExpanded] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [org, setOrg] = useState("*");
  const navigate = useNavigate();
  const [getList] = useLazyQuery(GET_USER_ORGANIZATION);
  const { data: dataOrganization, loading: loadingOrganization } = useQuery(
    GET_USER_OWNED_ORGANIZATION
  );
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_USER_ORGANIZATION
  );
  const [updateList] = useMutation(UPDATE_USER_ORGANIZATION_INVITE_ACCESS);
  const [resendInvite] = useMutation(RESEND_USER_OGRANIZATION_INVITE);
  const [deleteInvite] = useMutation(DELETE_USER_OGRANIZATION_INVITE);
  const { enqueueSnackbar } = useSnackbar();

  const tableHeadCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Brand Name",
    },
    {
      id: "lastOpened",
      numeric: false,
      disablePadding: true,
      label: "Last Opened",
    },

    {
      id: "created",
      numeric: false,
      disablePadding: true,
      label: "Created",
    },
    {
      id: "others",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];
  const subSelectData = [
    { label: "can view", value: "view" },
    { label: "can edit", value: "edit" },
  ];

  const getOrganizations = () => {
    if (organizationData?.userOrganizations instanceof Array) {
      let result = organizationData?.userOrganizations.map((item) => {
        return {
          title: item?.organization?.name,
          id: item?.organization?._id,
        };
      });
      result.unshift({ title: "All", id: "*" });
      return result;
    }
    return [];
  };

  const getBrands = (organization) => {
    if (organizationData?.userOrganizations instanceof Array) {
      let organizationObj = organizationData?.userOrganizations.find(
        (item) => item?.organization?._id === organization
      );
      if (organizationObj && organizationObj?.brands instanceof Array) {
        let result = organizationObj?.brands.map((item, index) => {
          return {
            label: item?.brand?.name,
            value: item?.brand?._id,
            image: item?.brand?.imageUrl,
            background: item?.brand?.backgroundColor,
            color: item?.brand?.color,
          };
        });
        // console.log(result, "result");
        return result;
      }
    }

    return [];
  };

  const updateRole = async (item, val) => {
    try {
      // console.log(val, item?.inviteId, "Role to update");
      let brands = val.map((el) => ({
        access: el?.sub ?? "view",
        brand: el?.value,
      }));
      let resp = await updateList({
        variables: { input: { inviteId: item?.inviteId, brands } },
      });
      if (resp?.data) {
        enqueueSnackbar(`${item?.invitedUserEmail}'s brand(s) updated `, {
          variant: "success",
        });
        await getData();
      } else {
        enqueueSnackbar(
          `Update on ${item?.invitedUserEmail}'s brand(s) was not successful `,
          { variant: "error" }
        );
      }
    } catch (err) {
      // console.log(err);
      enqueueSnackbar(
        `${item?.invitedUserEmail}'s brand(s) was not successfully updated `,
        { variant: "error" }
      );
    }
  };

  const resendInviteMail = async (item) => {
    try {
      // console.log(item, "item");
      let resp = await resendInvite({
        variables: {
          input: {
            _id: item?.inviteId,
          },
        },
      });
      if (resp?.data) {
        enqueueSnackbar(
          `${item?.invitedUserEmail} was successfully reinvited `,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `Error: ${item?.invitedUserEmail} re-invite request was not successful `,
          { variant: "error" }
        );
      }
    } catch (e) {
      // console.log(e);
      enqueueSnackbar(
        `Error: Unable to send mail to ${item?.invitedUserEmail}: ${e} `,
        { variant: "error" }
      );
    }
  };

  const removeMember = async (item) => {
    setOpen(true);
    setModalContent(
      `Are you sure you want to remove this member  ${item?.invitedUserEmail} from organization ${item?.organization?.name} ?`
    );
    setModalArray([
      {
        title: "Yes",
        variant: "contained",
        color: "error",
        actFunction: () => removeMemberFn(item),
        size: "large",
        style: { width: "40%" },
      },

      {
        title: "No",
        color: "success",
        variant: "contained",
        actFunction: () => setOpen(false),
        size: "large",
        style: { width: "40%" },
      },
    ]);
  };

  const removeMemberFn = async (item) => {
    try {
      // console.log(item, "item");
      let resp = await deleteInvite({
        variables: {
          input: {
            _id: item?.inviteId,
          },
        },
      });
      if (resp?.data) {
        enqueueSnackbar(`${item?.invitedUserEmail} was successfully removed `, {
          variant: "success",
        });
        await getData();
      } else {
        enqueueSnackbar(
          `Error: ${item?.invitedUserEmail} removal request was not successful `,
          { variant: "error" }
        );
      }
    } catch (e) {
      // console.log(e);
      enqueueSnackbar(
        `Error: Unable to remove member ${item?.invitedUserEmail}: ${e} `,
        { variant: "error" }
      );
    } finally {
      setOpen(false);
    }
  };

  const getBrandAccess = (data) => {
    return data.map((el) => ({ sub: el?.access, value: el?.brand?._id }));
  };

  const options = (item) => {
    let optionArr = [
      {
        title: (
          <OptionItem>
            <OptionText>Resend Invite</OptionText>
          </OptionItem>
        ),
        link: () => resendInviteMail(item),
      },
      {
        title: (
          <OptionItem>
            <OptionText>Remove Member</OptionText>
          </OptionItem>
        ),
        link: () => removeMember(item),
      },
    ];
    if (item.accepted) optionArr.shift();
    return optionArr;
  };

  const getRows = (data) => {
    // console.log(data);
    return data.flatMap((item) => {
      let rows = [];
      for (let brand of item?.brands || []) {
        let newObj = {
          organizationId: item?.organization?._id,
          organizationName: item?.organization?.name,
        };
        for (let el of tableHeadCells) {
          switch (el.id) {
            case "name":
              newObj[el.id] = (
                <NameTagBrowse
                  upperText={brand?.brand?.name}
                  brandColor={brand?.brand?.brandColor}
                  lowerText={item?.invitedUserEmail}
                  image={item?.invitedUser?.thumbnail}
                  active={item?.accepted}
                />
              );
              break;

            case "lastOpened":
              newObj[el.id] = (
                <TimeText
                  style={{
                    color: "#1A1B24",
                    fontSize: "16px",
                    lineHeight: "18px",
                  }}
                >
                  {brand?.brand?.lastActive &&
                    moment(brand?.brand?.lastActive).fromNow()}
                </TimeText>
              );
              break;

            case "created":
              newObj[el.id] = (
                <TimeText
                  style={{
                    color: "#1A1B24",
                    fontSize: "16px",
                    lineHeight: "18px",
                  }}
                >
                  {brand?.brand?.created &&
                    moment(brand?.brand?.created).fromNow()}
                </TimeText>
              );
              break;

            case "others":
              newObj[el.id] = null;
              // newObj[el.id] = <EllipsisLink content={options(item)} />;
              break;

            default:
              newObj[el.id] = "";
          }
        }

        rows.push(newObj);
      }

      return rows;
    });
  };

  const getData = async () => {
    try {
      let { data } = await getList();
      if (data?.userOrganizations instanceof Array) {
        setMembers(getRows(data?.userOrganizations));
      }
    } catch (e) {
      // console.log(e);
    }
  };
  const handleOrgChange = (val) => setOrg(val);

  const handleAccordionChange = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };
  // console.log(members, "members");

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const displayContent = () => {
    if (organizationData?.userOrganizations instanceof Array) {
      organizationArray = organizationData?.userOrganizations.map((item) => ({
        _id: item?.organization?._id,
        name: item?.organization?.name,
        owner: item?.owner,
      }));
    }
    // console.log(organizationArray, "selectedOrg");
    // let selectedArr = searchQuery?organizationArray.filter((elm) => elm.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 )
    //     : organizationArray;

    // console.log(selectedArr, "selectedArr");
    let selectedArr =
      org === "*"
        ? organizationArray
        : organizationArray.filter((elm) => elm._id === org);

    // console.log(selectedArr, "selectedArr");

    let result = selectedArr
      .filter((el) => {
        return searchQuery
          ? el?.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
          : true;
      })
      .map((el, ind) => {
        // console.log("el", el?.name); // Log the mem object
        // console.log("membersId", members); // Log the mem object
        let orgMembers = members.filter((mem) => {
          // console.log("mem?.organizationId", mem?.organizationId); // Log the mem.organizationId
          // console.log("mem?", mem); // Log the mem.organizationId
          return mem?.organizationId === el?._id;
        });

        // console.log("orgS", searchQuery); // Log the mem object
        // console.log("orgMember", orgMembers); // Log the mem object

        const filteredMembers = searchQuery
          ? orgMembers.filter((mem) => {
              const isMatch =
                mem?.name?.props?.upperText
                  .toLowerCase()
                  .indexOf(searchQuery.toLowerCase()) !== -1 ||
                mem?.organizationName
                  .toLowerCase()
                  .indexOf(searchQuery.toLowerCase()) !== -1;
              // console.log("orgM", mem); // Log the mem object
              return isMatch;
            })
          : orgMembers;

        // const filteredMembers = searchQuery
        // ? orgMembers.map((mem) => {
        //     console.log(mem,"memR" ,mem.name); // Log the mem object
        //     return true; // Always return true to include all elements in the filtered result
        //   })
        // : orgMembers;

        // console.log(orgMembers, "orgMembers", members);

        return (
          <Accordion
            defaultExpanded={true}
            // expanded={expanded === ind + 1}
            // onChange={() => handleAccordionChange(ind)}
            key={`Accordion${el?._id}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{ display: "flex", flexShrink: 0 }}>
                <img src={OrgnIcon} alt="orgn icon" />
                {searchQuery ? (
                  <OrganizationText style={{ marginLeft: "10px" }}>
                    {el?.name}
                  </OrganizationText>
                ) : (
                  <OrganizationText style={{ marginLeft: "10px" }}>
                    {el?.name}
                  </OrganizationText>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Grid container spacing={0} sx={{ padding: 0 }}>
                <Grid item xs={12} md={12}>
                  <OrganizationText
                    style={{
                      marginLeft: "10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "fit-content",
                    }}
                  >
                    <NameTag
                      upperText={el?.owner?.name}
                      lowerText={el?.owner?.email}
                      image={el?.owner?.thumbnail}
                    />
                    <OwnerText>Owner</OwnerText>
                  </OrganizationText>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Table
                    headCells={tableHeadCells}
                    rows={filteredMembers}
                    stylehead
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      });

    return result;
  };

  useEffect(() => {
    getData();
  }, [dataOrganization]);
  if (loadingOrganization) return <SpinnerBig />;

  return (
    <>
      <MembersContainer>
        <SubHeader
          btnTitle=""
          btnLink="/browse_all/"
          arrLink={[
            { title: "Go back to dashboard", link: "/" },
            { title: "Browse All" },
          ]}
        />
        <FlexContainer>
          <TitleHeader>
            <LeftTitle>Organization & Brands </LeftTitle>
            <ColorButton
              image={Plus}
              title="Add Organization"
              width="186px"
              background="#1A1B24"
              order={true}
              onClick={() => navigate("/create_organization")}
            />
          </TitleHeader>
          <Grid
            container
            spacing={2}
            sx={{
              mb: 5,
            }}
          >
            <Grid
              item
              xs={12}
              md={9}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <SubTitle>Organization:</SubTitle>
              <Dropdown
                data={getOrganizations()}
                defaultValue="*"
                handleChange={handleOrgChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              // md={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <NavSearch style={{ marginTop: "0rem" }}>
                <img
                  src={SearchIcon}
                  alt="share-icon"
                  style={{ height: 20, marginLeft: "10px" }}
                />
                <Search
                  searchFunction={handleSearch}
                  searchQuery={searchQuery}
                />
              </NavSearch>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={1}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Filter />
            </Grid> */}
          </Grid>
          {displayContent()}
        </FlexContainer>
      </MembersContainer>
      <Modal
        open={open}
        setOpen={setOpen}
        content={modalContent}
        actions={modalArray}
      />
    </>
  );
}
