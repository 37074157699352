import React, { useEffect, useState } from "react";
import { useWorkflowContext } from "./workflowContext";
import { NodeModalContainerStyle } from "./index.styled";
import {
  Box,
  Button,
  CardContent,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { CreateButton } from "../WebsiteBuilder/styled";
import { Controller, useForm } from "react-hook-form";
import { useNodes } from "reactflow";
import { SubTitle, TemplateCard, Title } from "../Templates/styled";
import { format } from "date-fns";

const StarterModal = ({ saveNode }) => {
  const { setStarterOpen, starterOpen, groupList } = useWorkflowContext();
  const nodes = useNodes();
  const starterNode = nodes.find(({ id }) => id === "starter-id");
  const [leadsOption, setLeadsOption] = useState(
    starterNode?.data?.metadata?.value ?? "all"
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      value: starterNode?.data?.metadata?.value ?? "",
    },
  });

  useEffect(() => {
    if (groupList?.length || starterOpen) {
      console.log("update closed");
      setLeadsOption(starterNode?.data?.metadata?.value ? "select" : "all");
      reset({
        value: starterNode?.data?.metadata?.value ?? "",
      });
    }

    return () => {};
  }, [groupList, starterOpen,starterNode]);

  const onSubmit = ({ value }) => {
    if (leadsOption === "select" && !groupList?.length) {
      return;
    }
    saveNode({
      ...starterNode,
      data: {
        ...starterNode?.data,
        metadata: {
          ...starterNode?.data?.metadata,
          value: leadsOption === "select" ? value : null,
        },
      },
    });
    setStarterOpen(false);
  };

  return (
    <Modal
      open={starterOpen}
      onClose={() => setStarterOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <NodeModalContainerStyle
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        sx={{
          minHeight: "300px",
          maxHeight: "calc(100vh - 150px)",
          width: "900px !important",
        }}
      >
        <Typography
          sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
          id="modal-modal-title"
          variant="h5"
        >
          Select Starting Group
        </Typography>
        <Box>
          <Box
            sx={{
              mt: 2,
              px: 2,
              py: 1.5,
              pb: 2,
              borderTop: "1px solid #E5E3E3",
            }}
          ></Box>
          <Box
            sx={{
              px: 2,
            }}
          >
            <Box sx={{ width: "100%", px: 3 }}>
              <Select
                disabled={groupList?.length ? false : true}
                placeholder="select filter"
                sx={{
                  width: "50%",
                  fontFamily: "Switzer",
                  borderRadius: "12px",
                  mb: 2,
                }}
                onChange={(e) => {
                  setLeadsOption(e.target.value);
                }}
                value={leadsOption}
              >
                {[
                  { label: "All Leads", value: "all" },
                  { label: "Select Leads Groups", value: "select" },
                ].map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {leadsOption === "select" ? (
              <Box sx={{ width: "100%", px: 3 }}>
                {groupList?.length ? (
                  <Controller
                    rules={{
                      required: "Select a Group",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Stack
                        direction={"row"}
                        sx={{ overflowY: "scroll", height: "300px" }}
                        spacing={0}
                        useFlexGap
                        flexWrap="wrap"
                      >
                        {groupList.map((card) => (
                          <TemplateCard
                            key={card._id}
                            onClick={() => onChange(card._id)}
                            style={{
                              cursor: "pointer",
                              margin: "8px",
                              borderWidth: "20px",
                              borderColor: "grey",
                              width: "180px",
                              height: "fit-content",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              backgroundColor:
                                card._id === value ? "#101010" : "#D6DAE0",
                            }}
                          >
                            <CardContent>
                              <Title
                                sx={{
                                  color:
                                    card._id === value
                                      ? "#ffffff !important"
                                      : "#000000",
                                }}
                                style={{
                                  marginTop: "-5px",
                                  marginBottom: "-1px",
                                  fontSize: "1rem",
                                  lineHeight: "normal",
                                }}
                              >
                                {card.name}
                              </Title>
                              <SubTitle
                                style={{
                                  color:
                                    card._id === value ? "#ffffff" : "#000000",
                                  fontSize: "10px",
                                }}
                              >
                                {format(card?.createdAt, "dd MMM, yyyy pp")}
                              </SubTitle>
                            </CardContent>
                          </TemplateCard>
                        ))}
                      </Stack>
                    )}
                    control={control}
                    name={"value"}
                  />
                ) : (
                  <Box
                    sx={{
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      px: 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        px: 2,
                        fontFamily: "Switzer",
                        mt: 2,
                      }}
                    >
                      No Leads Group Present
                    </Typography>
                  </Box>
                )}
                {!!groupList?.length && (
                  <>
                    {errors?.["value"] && (
                      <Typography
                        color={"red"}
                        variant="caption"
                        display="block"
                      >
                        {errors?.["value"]?.message}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            ) : (
              <Box sx={{ height: "150px", px: 3 }} />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              px: 2,
              py: 2,
              borderTop: "1px solid #E5E3E3",
            }}
          >
            {leadsOption !== "select" ? (
              <CreateButton
                color="primary"
                onClick={() => {
                  saveNode({
                    ...starterNode,
                    data: {
                      ...starterNode?.data,
                      metadata: {
                        ...starterNode?.data?.metadata,
                        value: null,
                      },
                    },
                  });
                  setStarterOpen(false);
                }}
                type="button"
              >
                Save
              </CreateButton>
            ) : (
              <CreateButton type="submit" color="primary">
                Save
              </CreateButton>
            )}
          </Box>
        </Box>
      </NodeModalContainerStyle>
    </Modal>
  );
};

export default StarterModal;
