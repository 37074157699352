import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import {
  InvitationContainer,
  ContentContainer,
  ContentSubContainer,
  ContentTitle,
  SelectTitle,
  Description,
  SubTitle,
  JustDiv,
  MobileViewTitle,
  MobileViewDiv,
  MobileViewInnerDiv,
  MobileViewEmail,
  PlainText,
} from "./styled";

import Button from "../../components/Button";
// import ButtonCust from "../../components/ButtonCust";
import ButtonCust from "../../components/ColorButton";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { GET_USER_OWNED_ORGANIZATION } from "../../graphql/queries/organizationQueries";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "notistack";
import { USER_OGRANIZATION_INVITE } from "../../graphql/mutations/organizationMutation";
import SubHeader from "../../components/SubHeader";
import MultipleSelect from "../../components/MultipleSelect";
import { DashBoardContainer } from "../UserDashboard/styled";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "../../assets/images/Close.svg";
import { SkipButton } from "../OrganizationBrands/styled";
import { TabTitle } from "../../utils/HeaderTitle";
import { MenuItemC } from "../Customization/styled";

export default function Invitation({
  inputNumber = 4,
  inputOrganization = "",
  description,
  setInviteFormstate,
  removeLayout,
}) {
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const navigate = useNavigate();

  TabTitle(`Invite`);
  let { id: organizatioParamId } = useParams();

  const [organization, setOrganization] = useState(
    inputOrganization === "" ? organizatioParamId : inputOrganization
  );

  const [formstate, setFormstate] = useState({});
  const [focusState, setFocusState] = useState({});
  const [mobileInput, setMobileInput] = useState({});
  const [numberOfInput, setNumberOfInput] = useState(inputNumber);
  const [btnLoading, setBtnLoading] = useState(false);
  const [keyDiv, setKeyDiv] = useState(1);

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useQuery(GET_USER_OWNED_ORGANIZATION);

  const [inviteUser] = useMutation(USER_OGRANIZATION_INVITE);

  const handleOrganizationChange = (value) => {
    setOrganization(value);
    let obj = { ...formstate };
    for (let item in obj) {
      if (item.includes("brand")) obj[item] = undefined;
    }
    setFormstate(obj);
    setInviteFormstate && setInviteFormstate(obj);
    setKeyDiv(Math.random() * 1000);
  };

  const getOrganizationName = () => {
    if (data?.userOwnedOrganizations instanceof Array) {
      let result = data.userOwnedOrganizations.find(
        (item) => item?.organization?._id === organization
      );
      return result ? ` under ${result?.organization?.name}` : "";
    }
    return "";
  };

  const handleChange = (name, value, index = "") => {
    let obj = { [name]: value };
    if (index !== "") {
      obj[`error${index}`] = undefined;
    }
    obj = { ...formstate, ...obj };
    setFormstate(obj);
    setInviteFormstate && setInviteFormstate(obj);

    let count = Object.keys(formstate).filter((item) =>
      String(item).includes("email")
    ).length;
    if (count >= numberOfInput) {
      setNumberOfInput(numberOfInput + 1);
    }
  };

  const handleMobileAdd = () => {
    let notIncluded = true,
      i = 0,
      obj = {};
    while (notIncluded && i < numberOfInput) {
      if (!formstate[`email${i}`]) {
        notIncluded = false;
        break;
      }
      i++;
    }
    obj = {
      ...formstate,
      [`email${i}`]: mobileInput["email"],
      [`brand${i}`]: mobileInput["brand"],
    };
    setFormstate(obj);
    setInviteFormstate && setInviteFormstate(obj);
    setKeyDiv(Math.random());
    // console.log(i, "number of input", numberOfInput);
    setMobileInput({});
    if (i >= numberOfInput) {
      setNumberOfInput(i + 1);
    }
  };

  const setBrandSelect = (val, ind) => {
    handleChange(`brand${ind}`, val, ind);
  };

  const subSelectData = [
    { label: "Can view", value: "view" },
    { label: "Can edit", value: "edit" },
  ];

  let emailReq =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleBlur = (name, mode = "") => {
    ///validate email address
    let formObject = mode === "mobile" ? mobileInput : formstate;

    let obj = {};
    let str = name.substring(5);

    if (!emailReq.test(String(formObject[name]).trim())) {
      obj = {
        ...formObject,
        [`error${str}`]: true,
        Memail: mode === "mobile" && "invalid",
      };
    } else {
      obj = {
        ...formObject,
        [`error${str}`]: false,
        Memail: mode === "mobile" && "valid",
      };
    }
    if (mode === "mobile") {
      setMobileInput(obj);
    } else {
      setFormstate(obj);
      setInviteFormstate && setInviteFormstate(obj);
    }
  };

  const getOrganizations = () => {
    if (data?.userOwnedOrganizations instanceof Array) {
      let result = data.userOwnedOrganizations.map((item, index) => {
        return (
          <MenuItemC value={item?.organization?._id}>
            {item?.organization?.name}
          </MenuItemC>
        );
      });
      return result;
    }
    return null;
  };

  const getBrands = () => {
    if (data?.userOwnedOrganizations instanceof Array) {
      let organizationObj = data.userOwnedOrganizations.find(
        (item) => item?.organization?._id === organization
      );
      if (organizationObj && organizationObj?.brands instanceof Array) {
        let result = organizationObj?.brands.map((item, index) => {
          return {
            label: item?.brand?.name,
            value: item?.brand?._id,
            image: item?.brand?.imageUrl,
            background: item?.brand?.backgroundColor,
            color: item?.brand?.color,
          };
        });
        return result;
      }
    }
    return [];
  };

  const getBrandAccess = (brandInput = []) => {
    // console.log("getBrandAccess", brandInput);
    return brandInput.map((item) => ({
      access: item?.sub ?? "view",
      brand: item?.value,
    }));
  };

  const handleSubmit = async () => {
    let messageArr = [];

    try {
      setBtnLoading(true);
      let errorFlag = false;
      for (let i = 0; i < numberOfInput; i++) {
        if (formstate[`error${i}`]) {
          messageArr.push({
            type: "error",
            description: `Error: Please make sure all the email addresses are valid   `,
          });
          return;
        }
        if (
          formstate[`email${i}`] &&
          formstate[`email${i}`].trim() !== "" &&
          !formstate[`brand${i}`]
        ) {
          messageArr.push({
            type: "error",
            description: `Error: Empty brand assigned to ${formstate[
              `email${i}`
            ]?.trim()}  `,
          });
          errorFlag = true;
        }
      }
      if (errorFlag) return;

      for (let i = 0; i < numberOfInput; i++) {
        let emailInput = `email${i}`;
        let brandInputValue = formstate[`brand${i}`]; //use later
        let emailInputValue = String(formstate[emailInput]).trim();

        if (emailReq.test(emailInputValue)) {
          try {
            //send to server
            let { data: dataInvite, errors: errorInvite } = await inviteUser({
              variables: {
                input: {
                  invitedUserEmail: emailInputValue,
                  organization,
                  brands: getBrandAccess(brandInputValue),
                },
              },
            });
            if (dataInvite) {
              messageArr.push({
                type: "success",
                description: `${emailInputValue} successfully invited `,
              });
            } else {
              if (
                errorInvite &&
                errorInvite instanceof Array &&
                errorInvite[0]?.message
              ) {
                messageArr.push({
                  type: "error",
                  description: `Error occured with ${emailInputValue} : ${errorInvite[0]?.message}   `,
                });
              } else {
                messageArr.push({
                  type: "error",
                  description: `Error occured with ${emailInputValue}   `,
                });
              }
            }
          } catch (err) {
            messageArr.push({
              type: "error",
              description: `${emailInputValue} not invited: ${err}  `,
            });
          }
        }
      }
    } catch (e) {
      // console.log(e);
      messageArr.push({
        type: "error",
        description: `Error occurred... ${e} `,
      });
    } finally {
      setBtnLoading(false);
      showMessage(messageArr);
    }
  };

  const showMessage = (messageArr) => {
    if (messageArr.length > 0) {
      for (let item of messageArr) {
        enqueueSnackbar(item?.description, { variant: item?.type });
      }
    }
    return;
  };

  const removeIndex = (idx) => {
    let obj = { ...formstate };
    obj[`email${idx}`] = undefined;
    obj[`brand${idx}`] = undefined;
    obj[`error${idx}`] = undefined;
    setFormstate(obj);
  };

  const displayMobileContent = () => {
    return Object.keys(formstate).find(
      (el) => el.includes("email") && formstate[el]
    ) ? (
      <>
        <Grid item xs={12}>
          <MobileViewTitle>Email Address & Permissions</MobileViewTitle>
        </Grid>
        {new Array(numberOfInput).fill(0).map((_, index) => {
          return formstate[`email${index}`] ? (
            <Grid item xs={12}>
              <MobileViewDiv>
                <MobileViewInnerDiv>
                  <MobileViewEmail>
                    {formstate[`email${index}`]}
                  </MobileViewEmail>
                  <PlainText>Access permission</PlainText>
                  <MultipleSelect
                    subSelectData={subSelectData}
                    data={getBrandsArray}
                    title="Brand Permissions"
                    disabled
                    viewAllInput
                    defaultValue={formstate[`brand${index}`]}
                  />
                </MobileViewInnerDiv>
                <img src={CloseIcon} onClick={() => removeIndex(index)} />
              </MobileViewDiv>
            </Grid>
          ) : null;
        })}
      </>
    ) : null;
  };

  // console.log("formstate", formstate);
  // console.log("mobileformstate", mobileInput);
  // if (loading) return <Spinner />;
  let getBrandsArray = getBrands();
  let CustomizedLayout = removeLayout ? JustDiv : JustDiv;

  return (
    <CustomizedLayout>
      <DashBoardContainer>
        <InvitationContainer>
          {(!inputOrganization || inputOrganization === "") && (
            <SubHeader
              btnTitle="Manage Team"
              btnLink="/teammembers"
              arrLink={[
                { title: "Dashboard", link: "/" },
                { title: "Invite Member" },
              ]}
            />
          )}
          <ContentContainer>
            <ContentSubContainer isMobile={isMobile}>
              {isMobile ? (
                <div
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ContentTitle>Invite members</ContentTitle>
                  {removeLayout && (
                    <SkipButton onClick={() => navigate("/")}>
                      Skip for now
                    </SkipButton>
                  )}
                </div>
              ) : (
                <ContentTitle>Invite team members</ContentTitle>
              )}

              {(!inputOrganization || inputOrganization === "") && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6} md={6}>
                    <SelectTitle>Organization</SelectTitle>
                    <FormControl
                      sx={{
                        mt: 1,
                        minWidth: 120,
                        width: "100%",
                        ".MuiInputBase-root": {
                          borderRadius: "15px",
                        },
                      }}
                    >
                      <Select
                        value={organization}
                        onChange={(e) =>
                          handleOrganizationChange(e.target.value)
                        }
                        fullWidth
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          borderRadius: "8px",
                          // height: 41,
                          height: "50px",
                          minWidth: 203,

                          ".MuiOutlinedInput-notchedOutline": {
                            border: focusState.org
                              ? `1px solid #007bf7 !important`
                              : "1px solid #D4DAE1",
                            boxShadow: focusState.org
                              ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40) !important"
                              : undefined,

                            ":hover": {
                              border: focusState.org
                                ? `1px solid #007bf7 !important`
                                : "1px solid #1a1b24",
                            },
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "12px",
                              padding: "0.4em 0.3em",
                              boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                            },
                          },
                          sx: {
                            borderRadius: "12px",
                            marginTop: "10px",
                            "& .Mui-selected": {
                              backgroundColor: "rgba(25, 118, 210, 0.08)",
                            },
                          },
                        }}
                        onFocus={() =>
                          setFocusState({ ...focusState, org: true })
                        }
                        onBlur={() =>
                          setFocusState({ ...focusState, org: false })
                        }
                      >
                        {getOrganizations()}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} md={12}>
                  <Description>
                    {description ? (
                      description
                    ) : (
                      <>
                        All invited people will be granted access to brand(s){" "}
                        {getOrganizationName()}
                      </>
                    )}
                  </Description>
                </Grid>
              </Grid>
              {!isMobile && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={7}>
                    <SubTitle>Email address</SubTitle>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <SubTitle>Permissions</SubTitle>
                  </Grid>
                </Grid>
              )}
              {!isMobile &&
                new Array(numberOfInput).fill(0).map((item, index) => (
                  <Grid
                    container
                    spacing={1}
                    key={`${keyDiv}G${index}`}
                    sx={{ mt: index === 0 ? 0 : 1, mb: 0.5 }}
                  >
                    <Grid item xs={12} md={7}>
                      <TextField
                        placeholder="Enter email Address"
                        fullWidth
                        sx={{
                          ".MuiOutlinedInput-notchedOutline": {
                            border: focusState[`em${index}`]
                              ? `1px solid #007bf7 !important`
                              : "1px solid #D4DAE1",
                            boxShadow: focusState[`em${index}`]
                              ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"
                              : undefined,
                            ":hover": {
                              border: focusState[`em${index}`]
                                ? `1px solid #007bf7 !important`
                                : "1px solid #1a1b24",
                            },
                            borderRadius: "15px",
                          },
                        }}
                        onFocus={() =>
                          setFocusState({
                            ...focusState,
                            [`em${index}`]: true,
                          })
                        }
                        onBlur={() => {
                          setFocusState({
                            ...focusState,
                            [`em${index}`]: false,
                          });
                          handleBlur(`email${index}`);
                        }}
                        InputProps={{
                          sx: {
                            borderRadius: "14px",
                            height: "41px",
                            "& input:-internal-autofill-selected": {
                              /* Add styles for the autofill selected state */
                              height: "7px !important", // Add the desired height for the autofill selected state
                            },
                          },
                        }}
                        // size="small"
                        onChange={(e) =>
                          handleChange(`email${index}`, e.target.value, index)
                        }
                        error={formstate[`error${index}`]}
                        value={formstate[`email${index}`]}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <MultipleSelect
                        subSelectData={subSelectData}
                        data={getBrandsArray}
                        title="Brand Permissions"
                        setValues={(val) => setBrandSelect(val, index)}
                        placeholder="Assign permission"
                        viewAllInput
                        selheight="50px"
                        defaultValue={formstate[`brand${index}`]}
                      />
                    </Grid>
                  </Grid>
                ))}

              {/* mobile view */}
              {isMobile && (
                <>
                  <Grid container spacing={1} key={`Mobi${keyDiv}`}>
                    <Grid item xs={12} sx={{ alignItems: "center" }}>
                      <TextField
                        placeholder="Enter email Address"
                        fullWidth
                        InputProps={{
                          sx: { borderRadius: "15px", height: "41px" },
                        }}
                        // size="small"
                        onChange={(e) =>
                          setMobileInput({
                            ...mobileInput,
                            email: e.target.value,
                          })
                        }
                        error={mobileInput[`error`]}
                        value={mobileInput[`email`]}
                        onBlur={() => handleBlur(`email`, "mobile")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MultipleSelect
                        subSelectData={subSelectData}
                        data={getBrandsArray}
                        title="Brand Permissions"
                        setValues={(val) =>
                          setMobileInput({
                            ...mobileInput,
                            brand: val,
                          })
                        }
                        removeBorder={
                          !mobileInput.Memail ||
                          mobileInput.Memail === "invalid"
                        }
                        disabled={
                          !mobileInput.Memail ||
                          mobileInput.Memail === "invalid"
                        }
                        // setValues={(val) => setBrandSelect(val, index)} later things
                        placeholder="Assign permission"
                        viewAllInput
                        apply
                        applyFn={handleMobileAdd}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {displayMobileContent()}
                  </Grid>
                </>
              )}
              {/* End of Mobile view */}
              <>
                {(!inputOrganization || inputOrganization === "") && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div style={{ marginTop: 20 }}>&nbsp;</div>
                    <ButtonCust
                      title="Send Invites"
                      onClick={handleSubmit}
                      disabled={btnLoading}
                      style={{ cursor: "pointer" }}
                      width="300px"
                      background="#1A1B24"
                      fontWeight="0"
                      innerStyle={{ width: "300px" }}
                    />
                    <div style={{ marginBottom: 20 }}>&nbsp;</div>
                  </div>
                )}
              </>
            </ContentSubContainer>
          </ContentContainer>
        </InvitationContainer>
      </DashBoardContainer>
    </CustomizedLayout>
  );
}
