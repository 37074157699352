import { useEffect, useRef, useState } from "react";
import { Cont, Cont2 } from "./styled";

export default ({ text, readMoreFn }) => {
  const contRef = useRef();
  const [textCont, setTextCont] = useState(text);
  useEffect(() => {
    const testDiv = contRef.current;
    if (testDiv) {
      //   const sampleText = testDiv.textContent;
      //   const maxWidth = parseFloat(
      //     window.getComputedStyle(testDiv).width.replace("px", "")
      //   );
      //   const avgCharWidth = maxWidth / sampleText.length;
      //   const maxChars = Math.floor((maxWidth * 2) / avgCharWidth);
      const maxChars = 70;
      // console.log(
      //   "Max characters for two lines:",
      //   maxChars
      //   // maxWidth,
      //   // sampleText.length,
      //   // avgCharWidth
      // );
      let stmt =
        !text || (text && String(text).trim().length < maxChars - 12) ? (
          text
        ) : (
          <>
            {String(text)
              .trim()
              .substring(0, maxChars - 12)}
            {"..."}
            <Cont2>Read More</Cont2>
          </>
        );
      setTextCont(stmt);
    }
  }, [contRef]);
  return <Cont ref={contRef}>{textCont}</Cont>;
};
