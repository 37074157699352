import { gql } from "@apollo/client";

const GET_ORGANIZATIONS = gql`
  query organizations {
    organizations {
      _id
      organizationId
      name
      industry
    }
  }
`;
const GET_ORGANIZATIONS_FOR_USER = gql`
  query {
    organizationsForUser {
      _id
      organizationId
      name
      industry
    }
  }
`;
const GET_ORGANIZATION = gql`
  query organization($input: GetOrganizationInput!) {
    organization(input: $input) {
      _id
      organizationId
      name
      industry
    }
  }
`;
const GET_USER_ORGANIZATION = gql`
  query {
    userOrganizations {
      _id
      owned
      owner {
        name
        email
        thumbnail
      }
      organization {
        _id
        name
        industry
      }
      brands {
        access
        brand {
          _id
          name
          brandUrl
          description
          imageUrl
          brandId
          lastActive
          created
          brandColor
        }
      }
      user {
        _id
      }
    }
  }
`;

const GET_USER_OWNED_ORGANIZATION = gql`
  query {
    userOwnedOrganizations {
      _id
      organization {
        _id
        name
        industry
      }
      brands {
        access
        brand {
          _id
          name
          brandId

          backgroundColor
          color
        }
      }
      user {
        _id
      }
    }
  }
`;

const GET_BRAND_BY_ORGANIZATION = gql`
  query ($id: String!) {
    brandsOrganization(id: $id) {
      name

      sector
      brandId
    }
  }
`;
const SEARCH_ORGANIZATIONS_AND_BRANDS = gql`
  query SearchOrganizationsAndBrands($query: String!) {
    searchOrganizationsAndBrands(query: $query) {
      organizations {
        _id
        name
        industry
      }
      brands {
        _id
        name
        brandId
      }
    }
  }
`;

const SEARCH_VALUES = gql`
  query findValues($input: SearchValues!) {
    findValues(input: $input) {
      title
      subTitle
      link
      type
      brandColor
      imageUrl
      _id
      brandId
      access
      owned
    }
  }
`;

const RECENT_SEARCH_VALUES = gql`
  query {
    recentValues {
      search {
        inputValue
        search {
          title
          subTitle
          link
          type
          brandColor
          imageUrl
          _id
          brandId
          access
      owned

        }
      }
    }
  }
`;

const GET_ORGANIZATION_INVITES = gql`
  query invites($input: GetOrganizationInvites!) {
    invites(input: $input) {
      _id
      invitedUser {
        name
        email
      }
      accepted
      organization {
        organizationId
        name
      }
      invitedUserEmail
      invitationCode
      createdAt
    }
  }
`;
const GET_ORGANIZATION_USERS = gql`
  query organizationUsers($input: String!) {
    organizationUsers(input: $input) {
      _id
      user {
        _id
        name
        email
      }
      organization {
        _id
        organizationId
        name
        user
      }
      brands
      created_at
    }
  }
`;

export {
  GET_ORGANIZATIONS,
  GET_ORGANIZATION,
  GET_BRAND_BY_ORGANIZATION,
  GET_USER_ORGANIZATION,
  SEARCH_ORGANIZATIONS_AND_BRANDS,
  GET_ORGANIZATION_INVITES,
  GET_ORGANIZATIONS_FOR_USER,
  GET_ORGANIZATION_USERS,
  GET_USER_OWNED_ORGANIZATION,
  SEARCH_VALUES,
  RECENT_SEARCH_VALUES,
};
// import {gql,useQuery} from '@apollo/client'

// const GET_ORGANIZATIONS =gql`
//     query organizations{
//         organizations{
//             _id
//             organizationId
//             name
//

//             industry
//         }
//     }
// `
// const GET_ORGANIZATION =gql`
// query organization($input : GetOrganizationInput!){
//     organization(input:$input){
//         _id
//         organizationId
//         name
//
//         industry
//     }
// }
// `
// export {GET_ORGANIZATIONS,GET_ORGANIZATION}
