import React, { useState, useEffect, useMemo } from "react";
import { Dialog } from "@mui/material";
import ColorButton from "../../components/ColorButton";
import { Title } from "./styled";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useQuery, useMutation } from "@apollo/client";
import { SEND_EMAIL } from "../../graphql/mutations/sendEmail";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { GET_ALL_LEAD_GROUP } from "../../graphql/queries/leadgroup";

const SendMailDialog = ({
  isOpen,
  onClose,
  loadedHTML,
  designHTML,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}) => {
  const [recipientEmails, setRecipientEmails] = useState([]);
  const [recipientType, setRecipientType] = useState("emails");
  const [inputValue, setInputValue] = useState("");
  const [fromEmail, setFromEmail] = useState("");
  const [enteredEmails, setEnteredEmails] = useState([]);
  const [emailTag, setEmailTag] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const menu = useSelector((state) => state.menu);

  const { data: leadGroups } = useQuery(GET_ALL_LEAD_GROUP, {
    variables: {
      input: { br_id: menu.id },
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendEmailMutation] = useMutation(SEND_EMAIL);

  // useEffect(() => {
  //   if (dataLeads && dataLeads.fetchLeads) {
  //     const emails = dataLeads.fetchLeads.map((lead) => lead.email);
  //     setLeadsData(dataLeads.fetchLeads);
  //     setRecipientEmails(emails);
  //   }
  // }, [dataLeads]);

  useEffect(() => {
    if (leadGroups && leadGroups.leadGroups) {
      setGroupList(leadGroups.leadGroups);
    }
  }, [leadGroups]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" ||
      event.key === "," ||
      event.key === " " ||
      event.key === ";"
    ) {
      event.preventDefault();
      const email = inputValue.trim();
      if (email !== "") {
        setEnteredEmails([...enteredEmails, email]);
        setInputValue("");
      }
    }
  };

  // console.log("Loaded Design Body:", loadedHTML);

  const handleSendEmail = async () => {
    try {
      setLoading(true);

      //validate from email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(fromEmail)) {
        setSnackbarSeverity("error");
        setSnackbarMessage("Error: Invalid From email address");
        setLoading(false);
        return;
      }
      const emailsToSend =
        recipientType === "emails" ? enteredEmails : recipientEmails;

      const emailBody = designHTML || loadedHTML || "";
      // console.log("Email Body within HandleSend:", emailBody);

      // console.log("Payload before sending:", {
      //   recipientEmails: emailsToSend,
      //   subject: subject,
      //   body: emailBody,
      //   emailTag: emailTag || "",
      // });

      const { data, errors } = await sendEmailMutation({
        variables: {
          recipientEmails: emailsToSend,
          subject: subject,
          body: emailBody,
          recipientType: recipientType,
          emailTag: emailTag || "",
          fromEmail,
          brand: menu.id,
          leadGroupId: selectedGroup,
        },
      });

      // console.log("Response from server:", data);

      if (data && data.sendEmail) {
        setSnackbarSeverity("success");
        setSnackbarMessage("Email sent successfully!");
      } else {
        setSnackbarSeverity("error");
        if (errors instanceof Array && errors[0]?.message) {
          setSnackbarMessage("Failed to send email." + errors[0]?.message);
        } else {
          setSnackbarMessage("Failed to send email. Please try again later.");
        }
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbarSeverity("error");

      setSnackbarMessage("Failed to send email.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      onClose();
    }
  };

  const handleRemoveEnteredEmail = (index) => {
    const updatedEnteredEmails = [...enteredEmails];
    updatedEnteredEmails.splice(index, 1);
    setEnteredEmails(updatedEnteredEmails);
  };

  const handleRecipientTypeChange = (event) => {
    setRecipientType(event.target.value);
    setInputValue("");
    if (event.target.value === "emails") {
      setEnteredEmails([]);
    } else if (event.target.value === "leads") {
      setEnteredEmails([]);
    }
  };

  // console.log("Email body:", loadedHTML);
  // console.log("Subject:", subject);
  // console.log("Entered Emails:", enteredEmails);
  // console.log("Recipient Emails:", recipientEmails);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="send-email-dialog-title"
    >
      <div style={{ padding: "20px", fontFamily: "DM Sans" }}>
        <Title style={{ marginTop: "-5px" }}>Send Email...</Title>
        <label>From: </label>

        <input
          type="text"
          value={fromEmail}
          placeholder="Type email"
          style={{ marginBottom: "8px", marginTop: "8px", width: "100%" }}
          onChange={(e) => setFromEmail(e.target.value)}
        />
        <label htmlFor="recipientType">To: </label>
        <select
          id="recipientType"
          style={{
            borderRadius: "5px",
            marginLeft: "5px",
            fontFamily: "DM Sans",
          }}
          value={recipientType}
          onChange={handleRecipientTypeChange}
        >
          <option value="emails">Emails</option>
          <option value="leads">Leads</option>
        </select>
        {recipientType === "leads" && (
          <select
            id="recipientType"
            placeholder="Select Group"
            style={{
              borderRadius: "5px",
              marginLeft: "10px",
              fontFamily: "DM Sans",
            }}
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            <option value="">All Leads</option>
            {groupList.map((group) => (
              <option key={group._id} value={group._id}>
                {group.name}
              </option>
            ))}
          </select>
        )}
        <div>
          <input
            type="text"
            id="subject"
            value={subject}
            placeholder="Enter subject" //Add Subject here..
            style={{ marginBottom: "8px", marginTop: "8px", width: "100%" }}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div>
          <input
            type="text"
            id="emailTag"
            value={emailTag}
            placeholder="Give this campaign a tag"
            style={{ marginBottom: "8px", marginTop: "8px", width: "100%" }}
            onChange={(e) => setEmailTag(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "1px" }}>
          {recipientType === "emails" &&
            enteredEmails.map((email, index) => (
              <div
                key={index}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginTop: "8px",
                  backgroundColor: "#D6DAE0",
                  marginRight: "8px",
                  padding: "2px",
                  border: "1px solid #ccc",
                  borderRadius: "12px",
                  fontSize: "12px",
                }}
              >
                <span style={{ padding: "4px" }}>{email}</span>
                <IconButton
                  size="small"
                  style={{ padding: "0", marginLeft: "4px" }}
                  onClick={() => handleRemoveEnteredEmail(index)}
                >
                  <Cancel />
                </IconButton>
              </div>
            ))}
        </div>
        {recipientType === "emails" && (
          <div>
            <input
              type="text"
              id="recipientEmail"
              value={inputValue}
              placeholder="Type email, then press SPACE or ENTER"
              style={{ marginBottom: "8px", marginTop: "8px", width: "100%" }}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <p
              style={{
                fontStyle: "italic",
                color: "#888",
                fontSize: "12px",
                marginTop: "-5px",
              }}
            >
              Separate multiple emails with comma, space, semicolon, or enter.
            </p>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ColorButton
            title="Send Now"
            background="black"
            buttonTextStyle={{ color: "white" }}
            hoverColor="black"
            hoverBackgroundColor="#464646"
            borderWidth="2px"
            activeBorderColor="grey"
            activeBgColor="grey"
            hoverBorder="grey"
            onClick={handleSendEmail}
            style={{ marginRight: "12px" }}
            disabled={
              !(recipientType === "emails"
                ? enteredEmails && enteredEmails?.length > 0
                : true)
            }
          />
          <ColorButton
            title="Cancel"
            background="#D6DAE0"
            textColor="#000"
            hoverColor="black"
            hoverBackgroundColor="silver"
            borderWidth="2px"
            activeBorderColor="grey"
            activeBgColor="grey"
            hoverBorder="grey"
            onClick={onClose}
          />
        </div>
      </div>
      {loading && (
        <CircularProgress
          size={20}
          style={{
            marginRight: "10px",
            alignItems: "center",
            alignSelf: "center",
          }}
        />
      )}
      {loading && (
        <p style={{ alignItems: "center", alignSelf: "center" }}>Sending...</p>
      )}
    </Dialog>
  );
};

export default SendMailDialog;
