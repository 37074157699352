import { gql } from "@apollo/client";

export const UPDATE_USER_ORGANIZATION_ACCESS = gql`
  mutation updateUserOrganizationAccess(
    $input: UpdateUserOrganizationAccessInput!
  ) {
    updateUserOrganizationAccess(input: $input) {
      _id
      user {
        _id
        name
        email
      }
      organization {
        _id
        organizationId
        name
        user
      }
      brands
      created_at
    }
  }
`;

export const UPDATE_USER_ORGANIZATION_INVITE_ACCESS = gql`
  mutation updateMembersList($input: UpdateUserOrganizationIviteAccessInput!) {
    updateMembersList(input: $input) {
      invitedUser {
        name
        email
      }
      organization {
        name
      }
    }
  }
`;
