import React, { useEffect, useRef, useState } from "react";
import {
  CreateButton,
  DeleteModalContainer,
  ModalContainer,
  ModalContainerCreate,
  StyledFormLabel,
  StyledSectionButton,
  StyledSubmitButton,
  TextInputContainer,
} from "./styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Modal from "@mui/material/Modal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import { TextField, Box, Typography, Stack, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { enqueueSnackbar } from "notistack";
import { nanoid } from "nanoid";
import { UPDATE_BRAND_TAGS } from "../../graphql/mutations/brandMutations";
import { useMutation } from "@apollo/client";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";

const tableHead = ["Name", "Description"];
const TagsModal = ({ open, handleClose, brandId, br_id, tagsList: list }) => {
  const [tagsList, setTagsList] = useState();
  const mounted = useRef(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTag, setCurrentTag] = useState({});
  const [modalState, setModalState] = useState("create");
  const toggleCreate = () => setOpenCreate((x) => !x);
  const toggleDelete = () => setOpenDelete((x) => !x);

  useEffect(() => {
    if (mounted.current && list) {
      // console.log("tags mounted")
      setTagsList(list);
      mounted.current = false;
    }
  }, [list]);
  const [updateBrand] = useMutation(UPDATE_BRAND_TAGS);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    let updatedList;
    if (
      tagsList.some(
        ({ name }) => name.toLowerCase() === data.name.toLowerCase()
      )
    ) {
      enqueueSnackbar(`tag name already exist`, {
        variant: "error",
      });
      return;
    }
    try {
      if (modalState === "create") {
        const newFile = {
          id: nanoid(),
          ...data,
        };

        updatedList = [...tagsList, newFile];
      } else {
        updatedList = tagsList.map((tag) =>
          tag.id === currentTag?.id ? { ...tag, ...data } : tag
        );
      }
      let { data: dataUpdate, errors } = await updateBrand({
        variables: {
          input: {
            brandId,
            tags: updatedList,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || modalState === "create"
            ? `Error creating tag`
            : "error updating tag",
          {
            variant: "error",
          }
        );

        return;
      }
      setTagsList(updatedList);
      toggleCreate();
      enqueueSnackbar(`Updating Page successful`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(
        modalState === "create"
          ? `Creating product failed ${e}`
          : `Update product failed ${e}`,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async () => {
    let updatedList = tagsList.filter(({ id }) => id !== currentTag?.id);
    setLoading(true);
    try {
      let { data: dataUpdate, errors } = await updateBrand({
        variables: {
          input: {
            brandId,
            tags: updatedList,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || "Error Deleting tags",
          {
            variant: "error",
          }
        );

        return;
      }
      setTagsList(updatedList);
      setOpenDelete(false);
      enqueueSnackbar(`tag delete successfully`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`delete failed`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={RemoveIcon}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Typography
            sx={{ fontWeight: 700, fontFamily: "Switzer", mt: 2 }}
            id="modal-modal-title"
            variant="h5"
          >
            Tags List
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              py: 1.5,
              borderTop: "1px solid #E5E3E3",
              borderBottom: "1px solid #E5E3E3",
            }}
          >
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row"></Stack>
            <Box>
              <CreateButton
                color="primary"
                onClick={() => {
                  setModalState("create");
                  setOpenCreate(true);
                  reset({
                    name: "",
                    description: "",
                  });
                }}
              >
                create Tag
              </CreateButton>
            </Box>
          </Box>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              sx={{ minWidth: 500 }}
              stickyHeader
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {tableHead.map((title) => (
                    <TableCell
                      sx={{ fontWeight: 700, fontFamily: "Switzer" }}
                      key={title}
                    >
                      {title}
                    </TableCell>
                  ))}
                  <TableCell sx={{ fontWeight: 700, fontFamily: "Switzer" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tagsList instanceof Array &&
                  tagsList?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={{ fontFamily: "Switzer" }}>
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Switzer" }}>
                        {row.description || "---------"}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            setModalState("update");
                            setCurrentTag(row);
                            setOpenCreate(true);
                            reset(row);
                          }}
                        >
                          <BorderColorIcon />
                        </IconButton>
                        &nbsp;&nbsp;
                        <IconButton
                          color="error"
                          onClick={() => {
                            setCurrentTag(row);
                            setOpenDelete(true);
                            reset(row);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalContainer>
      </Modal>
      <>
        <Modal
          open={openCreate}
          onClose={toggleCreate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainerCreate>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                src={RemoveIcon}
                style={{ cursor: "pointer" }}
                onClick={toggleCreate}
              />
            </Box>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Switzer" }}
              id="modal-modal-title"
              variant="h5"
            >
              {modalState === "create" ? "Create New Tag" : "Update Tag"}
            </Typography>
            <Box>
              <TextInputContainer
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1 }}
              >
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Tag Name* (<em>Tag name must be unique</em>)
                  </StyledFormLabel>
                  <TextField
                    error={errors?.["name"]?.message}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label=""
                    placeholder="Enter Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "8px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    helperText={errors?.["name"]?.message}
                    {...register("name", { required: "name is required" })}
                  />
                </TextInputContainer>

                {/* Password Input */}
                <TextInputContainer>
                  <StyledFormLabel>Tag Description</StyledFormLabel>
                  <TextField
                    error={errors?.["description"]?.message}
                    margin="normal"
                    required
                    fullWidth
                    name="description"
                    label=""
                    placeholder="Enter custom header"
                    type="text"
                    id="description"
                    multiline
                    rows={4}
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "8px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    helperText={errors?.["description"]?.message}
                    {...register("description", { required: false })}
                  />
                </TextInputContainer>

                {/* submit Button */}
                <StyledSubmitButton
                  type="submit"
                  //   disabled={loading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isSubmitting
                    ? "loading..."
                    : modalState === "create"
                    ? "Create Tag"
                    : "Edit Tag"}
                </StyledSubmitButton>
              </TextInputContainer>
            </Box>
          </ModalContainerCreate>
        </Modal>
      </>
      <>
        <Modal
          open={openDelete}
          onClose={toggleDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DeleteModalContainer>
            <Box sx={{ py: 1, borderBottom: "1px solid #E5E3E3" }}>
              <Typography
                sx={{ fontWeight: 700, fontFamily: "Switzer", px: "8px" }}
                id="modal-modal-title"
                variant="h6"
              >
                Delete Selected Items
              </Typography>
            </Box>

            <Box sx={{ p: "8px", py: 3 }}>
              <Typography sx={{ fontFamily: "Switzer" }}>
                {" "}
                Are you sure you want to delete{" "}
                <strong>
                  <em>{currentTag?.name}</em>
                </strong>{" "}
                Tag?
              </Typography>
            </Box>
            <Stack
              sx={{ px: "8px", py: 2 }}
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
            >
              <StyledSectionButton onClick={toggleDelete}>
                Cancel
              </StyledSectionButton>
              <Button
                color="error"
                variant="contained"
                disabled={loading}
                sx={{
                  borderRadius: "15px",
                  fontFamily: "Switzer",
                  fontSize: "14px",
                  textTransform: "none",
                  px: 2,
                }}
                onClick={handleDelete}
              >
                {loading ? "loading..." : "Delete"}
              </Button>
            </Stack>
          </DeleteModalContainer>
        </Modal>
      </>
    </>
  );
};

export default TagsModal;
