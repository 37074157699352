import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  AlignContainer,
  CheckLabel,
  ControlDiv,
  DisplayDiv,
  DynamicDiv,
  DynamicSectionDiv,
  ImageDiv,
  IndicatorDiv,
  LeftDiv,
  LeftSubTitleDiv,
  OnButton,
  PreviewButton,
  PreviewText,
  SectionTitle,
  SubTitleDiv,
  SubTitleDiv2,
  TypeButton,
  TypeDiv,
  WidgetButDiv,
  PictureBox,
  PictureContent,
  SocialLinkDiv,
  SocialInputDiv,
  SocialInputTitleDiv,
  SocialInputTitleDiv2,
  SocialInputTitleDiv4,
  SocialInputTitleDiv3,
  IconDiv,
  LinkDiv,
  LinkInputDiv,
  VideoLabel,
  VideoName,
  VideoDate,
  SectionSubTitle,
  SocialOutlineBox,
} from "./styled";
import React from "react";
import AvatarImage from "../../assets/images/Avatar.svg";
import PreviewIcon from "../../assets/images/preview.svg";
import IntroImage from "../../assets/images/light.svg";
import OutroImage from "../../assets/images/Shappattaaa.svg";
import AddImage from "../../assets/icons/AddImage.svg";
import AddIcon from "../../assets/icons/AddIcon.svg";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Twitter from "../../assets/icons/Twitter.svg";
import Linkedin from "../../assets/icons/Linkedin.svg";
import { useRef, useState, useEffect } from "react";
import ColorButton from "../../components/ColorButton";
import Snackbar from "@mui/material/Snackbar";
import ReactCrop from "react-image-crop";
import AWS from "aws-sdk";
import { UPDATE_PROFILE_NO_FILE } from "../../graphql/mutations/user";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import MultipleSelect from "../../components/MultipleSelect";
import CloseIcon from "../../assets/images/Close.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { UserDetailsImage } from "../UserDashboard/styled";
import VideoComp from "../../components/Main/SectionComp/videoComp";
import Select from "react-select";
import {
  CheckBoxText,
  PreviousButton,
  PreviousButtonText,
} from "../OrganizationBrands/styled";
import { CheckBox } from "@mui/icons-material";
import { GET_VIDEOS_BY_BRAND } from "../../graphql/queries/videosQueries";
import moment from "moment";

export default (
  widgetState,
  isMobile,
  handleChange,
  setModalOpen,
  socialLinkArray,
  approvedVideos
) => {
  const profileData = useSelector((state) => state.auth);
  // console.log(profileData, "profileData");
  const [filename, setFilename] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [thumbnail, setThumbnail] = useState(profileData?.thumbnail);
  const [croppedThumbnail, setCroppedThumbnail] = useState(null);
  const [activeLink, setActiveLink] = useState();
  const [selectedChart, setSelectedChart] = useState("ig-post");
  const [isChecked, setIsChecked] = useState(false);
  const [videos, setVideos] = useState([]);
  const [scroll, setScroll] = useState("paper");
  const [selectedVideoCount, setSelectedVideoCount] = useState({});
  const [selectedVideoNumbers, setSelectedVideoNumbers] = useState({});
  const [currentCount, setCurrentCount] = useState(0);
  const [socialLinkContent, setSocialLinkContent] = useState(
    widgetState?.socialLinks ?? {}
  );
  const [inputFieldSocialLink, setInputFieldSocialLink] = useState();
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);
  const [blob, setBlob] = useState(null);
  const [showIntroAndOutro, setShowIntroAndOutro] = useState(true);
  const [showOutro, setShowOutro] = useState(true);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // console.log(videos, "videos");
  let fileUrl = "";
  let nextVideoNumber = 1;
  // console.log(widgetState?.testimonialVideos, "testmonialVideos");

  const {
    loading: userLoading,
    error: userError,
    data: userData,
  } = useQuery(GET_USER_BY_ID, {
    variables: { input: { userId: profileData?.user?._id } },
  });

  const { loading, error, data } = useQuery(GET_VIDEOS_BY_BRAND, {
    variables: { brandId: widgetState?.brandId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    // You can handle the videos data here
  }, [data]);

  const toggleIntroAndOutro = () => {
    setShowIntroAndOutro(!showIntroAndOutro);

    handleChange(!showIntroAndOutro, "showIntroAndOutro");
  };

  //   const toggleVideoSelection = (video) => {
  //     if (selectedVideos.includes(video)) {
  //       // If the video is already selected, remove it from the selectedVideos array
  //       setSelectedVideos(selectedVideos.filter((v) => v !== video));
  //       setVideos(videos.filter((v) => v !== video));

  //       // Remove the count for this video from the selectedVideoCount object
  //       const updatedVideoCount = { ...selectedVideoCount };
  //       delete updatedVideoCount[video._id];
  //       setSelectedVideoCount(updatedVideoCount);

  //     } else if (selectedVideos.length < 8) {
  //       const newSelectedVideos = [...selectedVideos, video];
  //       setSelectedVideos(newSelectedVideos);

  //       // Check if the video is already in the selectedVideoCount object
  //       if (selectedVideoCount.hasOwnProperty(video._id)) {
  //         // If it is, increment the count by 1
  //         setSelectedVideoCount({
  //           ...selectedVideoCount,
  //           [video._id]: selectedVideoCount[video._id] + 1,
  //         });
  //       } else {
  //         // If it's not, set the count to 1
  //         setSelectedVideoCount({
  //           ...selectedVideoCount,
  //           [video._id]: currentCount+1,
  //         });
  //         setCurrentCount((prevCount) => prevCount + 1);
  //       }
  //     }
  //   };
  const toggleVideoSelection = (video) => {
    if (selectedVideos.includes(video)) {
      setSelectedVideos(selectedVideos.filter((v) => v !== video));
      setVideos(videos.filter((v) => v !== video));
      handleChange(
        selectedVideos.filter((v) => v !== video),
        "socialVideos"
      );
      setSelectedVideoCount((prevCount) => {
        const removedVideoCount = prevCount[video._id];
        const restCounts = { ...prevCount };
        // console.log(
        //   prevCount,
        //   "prevCount",
        //   restCounts,
        //   "prevCount",
        //   currentCount
        // );
        if (currentCount > 1) {
          restCounts[video._id] = currentCount - 1;
        } else {
          delete restCounts[video._id];
        }
        return restCounts;
      });
      setCurrentCount((prevCount) => prevCount - 1);
    } else if (selectedVideos.length < 8) {
      const newSelectedVideos = [...selectedVideos, video];
      setSelectedVideos(newSelectedVideos);
      setCurrentCount((prevCount) => prevCount + 1);
      setSelectedVideoCount((prevCount) => ({
        ...prevCount,
        [video._id]: (prevCount[video._id] || currentCount) + 1,
      }));
    }
  };

  //   const toggleVideoSelection = (video) => {

  //     if (selectedVideos.includes(video)) {
  //       setSelectedVideos(selectedVideos.filter((v) => v !== video));
  //       setVideos(videos.filter((v) => v !== video));
  //         setSelectedVideoCount({
  //             ...selectedVideoCount,
  //             [video._id]: currentCount + 1,
  //             });

  //         setCurrentCount((prevCount) => prevCount - 1);
  //     } else if (selectedVideos.length < 8) {
  //         const newSelectedVideos = [...selectedVideos, video];
  //     setSelectedVideos(newSelectedVideos);

  //     // Get the current count for this video

  //     // Increase the count for this video by 1

  //     // Update the selectedVideoCount object with the updated count for this video
  //     setSelectedVideoCount({
  //         ...selectedVideoCount,
  //         [video._id]: currentCount + 1,
  //       });
  //       console.log(currentCount, "currentCountTest");
  //       // Increase the count for this video by 1 and update the state
  //       setCurrentCount((prevCount) => prevCount + 1);

  //     }

  //     // const videosWithNumbers = selectedVideos.map((video, index) => ({
  //     //   ...video,
  //     //   number: index + 1,
  //     // }));
  //   };
  // console.log(selectedVideoCount, "selectedVideoCount");
  // console.log(currentCount, "currentCount");

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleUnselect = () => {
    setSelectedVideos([]);
    setVideos([]);
  };

  const handleVideoAddition = () => {
    // Add selected videos to your list (e.g., state)

    setVideos(selectedVideos);
    handleChange(selectedVideos, "socialVideos");
    // Close the modal
    const videosWithNumbers = selectedVideos.map((video, index) => ({
      ...video,
      number: index + 1,
    }));
    setShowModal(false);
    setSelectedVideoCount({});
    // Reset selected videos
    // setSelectedVideos([]);

    // You can then render them as boxes
  };
  const triggerRef = () => fileInputRef?.current?.click();

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const pictureUrl = () => {
    if (
      thumbnail ||
      widgetState?.picture ||
      userData?.findUserById?.thumbnail
    ) {
      return `${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${
        thumbnail ?? widgetState?.picture ?? userData?.findUserById?.thumbnail
      }.jpeg`;
    }
    return AvatarImage;
  };

  const getVideoLabel = (item) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(item?.createdAt);
    let dateString = date.toLocaleDateString("en-US", options);
    return (
      <VideoLabel>
        <VideoName>{item?.name}</VideoName>
        <VideoDate>Created {dateString}</VideoDate>
      </VideoLabel>
    );
  };

  const getVideoTag = (item) => {
    return item ? (
      <UserDetailsImage>
        <VideoComp item={item} specialIcon />
      </UserDetailsImage>
    ) : null;
  };

  const getVideoValues = () => {
    if (approvedVideos instanceof Array) {
      let result = approvedVideos.map((item) => {
        return {
          label: getVideoLabel(item),
          lbl: item?.name,
          value: item?.videoId,
          image: getVideoTag(item),
          // image: item?.brand?.imageUrl,
          // background: item?.brand?.backgroundColor,
          // color: item?.brand?.color,
        };
      });
      return result;
    }
    return [];
  };
  const chartOptions = [
    // { value: "impression", label: "Impression", image: Impression },
    {
      value: "ig-post",
      //   how to space between instagram post and 1080 x 1080 in label
      label: `Instagram post &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp;1080 x 1080`,
    },

    // { value: "new", label: "New Video ", image: NewVideos },
    // {
    //   value: "duration",
    //   label: "Average View Duration ",
    //   image: Duration,
    // },
    // {
    //   value: "rating",
    //   label: "Average Rating ",
    //   image: AverageRating,
    // },
    {
      value: "ig-story",
      label: "Instagram Story &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;1080 x 1920",
    },

    {
      value: "tw-post",
      label:
        "Twitter Post &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 1200 x 675",
    },
    {
      value: "fb-post",
      label:
        "Facebook Post &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp;1200 x 630",
    },
    {
      value: "li-post",
      label:
        "Linkedin Post &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  1200 x 627",
    },

    // {
    //   value: "averageTime",
    //   label: "Average Time ",

    //   data: averageTimeData?.analyticTimeWebSiteSpent?.results || [],
    //   item: "totalDuration",
    //   color: "#5ACEC7",

    // },
    // {
    //   value: "pageVisited",
    //   label: "Page Visited ",

    //   data: pagesVisitedData?.analyticsNumberofVisitedPages?.results || [],
    //   item: "avgPages",
    //   color: "#FAC60F",

    // },
    // { value: "DisapprovedChart", label: "Disapproved Chart", image: "impression.png" },
    // { value: "ApprovedChart", label: "Approved Chart", image: "impression.png" },
  ];

  const handleChartChange = (selectedOption) => {
    setSelectedChart(selectedOption.value);

    handleChange(selectedOption.value, "socialSize");
    // navigate(`/brands/${menu.brandId}/0/${selectedOption.value}`);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("socials") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={3000}
        />
        <DisplayDiv
          isMobile={isMobile}
          inactive={!widgetState?.sectionVisibility}
          style={{ height: "100%", marginTop: 16 }}
        >
          <DynamicSectionDiv isMobile={isMobile}>
            <SectionTitle style={{ marginTop: 0 }}>Social Media</SectionTitle>
            <div
              style={{
                width: "316px",
              }}
            >
              <Select
                isSearchable={false}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#D4DAE1" : "#D4DAE1",
                    width: "316px",
                    borderRadius: "8px",
                    height: "33px",
                    ":hover": {
                      border: "1px solid #5D6272", // Border color when the Select is being hovered
                      // Add any other styles you want for the hover effect
                    },
                  }),
                }}
                options={chartOptions}
                value={chartOptions.find(
                  (option) => option.value === selectedChart
                )}
                formatOptionLabel={(option) => (
                  <div className="option-option">
                    <span
                      style={{
                        fontFamily: "Dm Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "140%",
                        marginLeft: "10px",
                        color: "#5D6272",
                      }}
                      dangerouslySetInnerHTML={{ __html: option.label }}
                    ></span>
                  </div>
                )}
                onChange={handleChartChange}
              />
            </div>

            <SectionTitle>Select Videos</SectionTitle>

            {/* select videos */}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              {showIntroAndOutro && (
                <div
                  className="box"
                  style={{
                    width: "108px",
                    height: "108px",
                    borderRadius: "8px",
                    position: "relative",
                    backgroundImage: `url(${IntroImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: "3%",
                      right: "5%",

                      backgroundColor: "white",
                      color: " #858995",
                      fontFamily: "Dm Sans",
                      fontSize: "8px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "130%",
                    }}
                  >
                    {" "}
                    Intro{" "}
                  </div>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#000",
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontFamily: "Dm Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "120%",
                    }}
                  >
                    {widgetState?.introHeading}
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#000",
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontFamily: "Dm Sans",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "130%",
                    }}
                  >
                    {widgetState?.introBody}
                  </p>
                </div>
              )}

              {videos.map((video) => (
                <div key={video._id} className="video-box">
                  <div style={{ position: "relative" }}>
                    <img
                      style={{
                        width: "108px",
                        height: "108px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                      src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${video?.url}.jpeg`}
                      alt={video.name}
                    />
                    <input
                      style={{
                        position: "absolute",
                        top: "4%",
                        right: "3%",
                        width: "15px",
                        height: "15px",
                      }}
                      type="checkbox"
                      checked={videos.includes(video)}
                      onChange={() => toggleVideoSelection(video)}
                    />

                    <div
                      style={{
                        position: "absolute",
                        bottom: "4%",
                        right: "3%",
                        borderRadius: "18px",
                        display: "inline-flex",
                        padding: " 2px 8px",
                        alignItems: "center",
                        gap: "4px",
                        background: "rgba(255, 255, 255, 0.70)",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#1A1B24",
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Dm Sans",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "130%",
                        }}
                      >
                        {video.duration}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {videos.length < 10 ? (
                <div
                  style={{
                    width: "108px",
                    height: "108px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px dashed #D4DAE1",
                  }}
                >
                  <div
                    className="add-icon"
                    style={{ cursor: "pointer" }}
                    onClick={handleModalOpen}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#D4DAE1",
                        fontFamily: "Dm Sans",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                      }}
                    >
                      + Add Video
                    </p>
                  </div>
                </div>
              ) : null}

              {showIntroAndOutro && (
                <div
                  className="box"
                  style={{
                    width: "108px",
                    height: "108px",
                    position: "relative",
                    borderRadius: "8px",
                    backgroundImage: `url(${OutroImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: "3%",
                      right: "5%",

                      backgroundColor: "white",
                      color: " #858995",
                      fontFamily: "Dm Sans",
                      fontSize: "8px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "130%",
                    }}
                  >
                    End
                  </div>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#000",
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontFamily: "Dm Sans",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "120%",
                    }}
                  >
                    {widgetState?.outroHeading}
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      color: "#000",
                      leadingTrim: "both",
                      textEdge: "cap",
                      fontFamily: "Dm Sans",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "130%",
                    }}
                  >
                    {widgetState?.outroBody}
                  </p>
                </div>
              )}
            </div>
            {/* Checkbox to toggle Intro and Outro */}
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                style={{ width: "15px", height: "15px" }}
                checked={showIntroAndOutro}
                onChange={toggleIntroAndOutro}
              />
              <p
                style={{
                  marginBottom: "0px",
                  color: "#000",
                  leadingTrim: "both",
                  textEdge: "cap",
                  fontFamily: "Dm Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "130%",
                }}
              >
                Show Intro & Outro in Export
              </p>

              {/* <label>
                Show Outro
                <input
                  type="checkbox"
                  checked={showOutro}
                  onChange={toggleOutro}
                />
              </label> */}
            </div>

            <SectionTitle>Intro Clip Texts</SectionTitle>

            <SectionSubTitle>Heading</SectionSubTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.introHeading}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "introHeading")}
            />

            <SectionSubTitle>Body</SectionSubTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.introBody}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "introBody")}
            />
            <SectionTitle>Outro Clip Texts</SectionTitle>
            <SectionSubTitle>Heading</SectionSubTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.outroHeading}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "outroHeading")}
            />

            <SectionSubTitle>Body</SectionSubTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.outroBody}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "outroBody")}
            />

            {/* Modal */}
            {showModal && (
              <Dialog
                open={showModal}
                onClose={handleModalClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="lg"
              >
                <DialogContent>
                  {/* Render videos from allVideosData */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#1A1B24",
                        fontFamily: "Dm Sans",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        letterSpacing: "-0.48px",
                      }}
                    >
                      Select Videos
                    </p>
                    <img
                      src={RemoveIcon}
                      alt="close"
                      style={{ cursor: "pointer" }}
                      onClick={handleModalClose}
                    />
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      //   gap: "10px",
                      flexWrap: "wrap",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                    }}
                  >
                    {data &&
                      data?.videosByBrandId.map((video, index) => (
                        <SocialOutlineBox
                          checked={selectedVideos.includes(video)}
                          key={video._id}
                          className={`video-selection ${
                            selectedVideos.includes(video) ? "selected" : ""
                          }`}
                        >
                          <div style={{ position: "relative" }}>
                            <img
                              style={{
                                width: "200px",
                                height: "150px",
                                borderRadius: "5px",
                                objectFit: "cover",
                              }}
                              src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${video?.url}.jpeg`}
                              alt={video.name}
                            />
                            <input
                              style={{
                                position: "absolute",
                                top: "4%",
                                left: "3%",
                                width: "15px",
                                height: "15px",
                              }}
                              type="checkbox"
                              checked={selectedVideos.includes(video)}
                              onChange={() => toggleVideoSelection(video)}
                            />

                            {selectedVideos.includes(video) && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "3%",
                                  right: "3%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  backgroundColor: "white",
                                  color: " #000",
                                  fontFamily: "Dm Sans",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                }}
                              >
                                <p style={{ marginBottom: "0px" }}>
                                  {selectedVideoCount[video._id]}
                                </p>
                              </div>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                bottom: "4%",
                                right: "3%",
                                borderRadius: "18px",
                                display: "inline-flex",
                                padding: " 2px 8px",
                                alignItems: "center",
                                gap: "4px",
                                background: "rgba(255, 255, 255, 0.70)",
                              }}
                            >
                              <p
                                style={{
                                  marginBottom: "0px",
                                  color: "#1A1B24",
                                  leadingTrim: "both",
                                  textEdge: "cap",
                                  fontFamily: "Dm Sans",
                                  fontSize: "10px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "130%",
                                }}
                              >
                                {video.duration}
                              </p>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: "0px",
                                color: "#1A1B24",

                                fontFamily: "Dm Sans",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "150%",
                              }}
                            >
                              {video.name}
                            </p>
                            {video.approved ? (
                              <div
                                style={{
                                  display: "flex",
                                  padding: "3px 6px",
                                  alignItems: "center",
                                  gap: "4px",
                                  borderRadius: "6px",
                                  background: " #FFDFEB",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    color: "#1A1B24",

                                    fontFamily: "Dm Sans",
                                    fontSize: "10px",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "130%",
                                  }}
                                >
                                  Approved
                                </p>
                              </div>
                            ) : (
                              <p>{""}</p>
                            )}
                          </div>

                          <p
                            style={{
                              marginBottom: "4px",
                              color: "#5D6272",
                              leadingTrim: "both",
                              textEdge: "cap",
                              fontFamily: "Dm Sans",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "150%",
                            }}
                          >
                            {" "}
                            {moment(video.createdAt).format("DD,MMM,YYYY")}
                          </p>
                        </SocialOutlineBox>
                      ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: "1rem",
                      gap: "15px",
                      position: "sticky",
                      bottom: "-4%",
                      height: "75px",
                      background: "white",
                    }}
                  >
                    <PreviousButton onClick={handleUnselect}>
                      <PreviousButtonText>UnSelect All</PreviousButtonText>
                    </PreviousButton>
                    <ColorButton
                      width={"180px"}
                      background={"#1A1B24"}
                      title={"OK"}
                      onClick={handleVideoAddition}
                    />
                  </div>
                </DialogContent>
              </Dialog>
            )}

            {/* <SectionTitle>Testimonial Videos</SectionTitle>
              <MultipleSelect
                // subSelectData={subSelectData}
                data={getVideoValues()}
                specialIcon
                // data={getBrandsArray}
                // title="Brand Permissions"
                setValues={(val) => handleChange(val, "testimonialVideos")}
                placeholder="Select videos"
                // viewAllInput
                defaultValue={widgetState?.testimonialVideos ?? []}
              /> */}

            {isMobile && (
              <SectionTitle
                onClick={() => setModalOpen(true)}
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                <PreviewButton>
                  <img src={PreviewIcon} />
                  <PreviewText>Preview</PreviewText>
                </PreviewButton>
              </SectionTitle>
            )}
          </DynamicSectionDiv>
        </DisplayDiv>
      </Grid>
    )
  );
};
