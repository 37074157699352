import {
  Box,
  CardContent,
  Checkbox,
  Chip,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  TemplateCard,
  TemplateImage,
  Title,
  SubTitle,
} from "../../pages/Templates/styled";
import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { GET_ALL_TEMPLATES_QUERY } from "../../graphql/mutations/saveTemplateMutation";
import tempreview from "../../pages/Templates/tempreview.jpg";

export default ({ value, setValue }) => {
  const { data: templateData, loading } = useQuery(GET_ALL_TEMPLATES_QUERY);

  const newsletters = useMemo(
    () => templateData?.getAllTemplates ?? [],
    [templateData]
  );

  const addItemValue = (e, item, cardId) => {
    e.stopPropagation();
    let arr = [...value];

    let val = e.target.value;
    let ind = value.findIndex((el) => el.id === cardId);

    if (ind === -1) {
      // arr.push({ id: cardId, [item]: val }); multiple
      arr = [{ id: cardId, [item]: val }]; //single
    } else {
      arr[ind][item] = val;

      if (
        Object.values(arr[ind]).filter((el) => String(el).trim() !== "")
          .length <= 1
      ) {
        arr.splice(ind, 1); //unclick the Item
      }
    }
    setValue(arr);
  };

  const handleClick = (val) => {
    return;
    // let ind = value.indexOf(val);
    // console.log("clicked");
    let ind = value.findIndex((el) => el.id === val);
    let arr = [...value];
    if (ind === -1) {
      arr.push({ id: val });
    } else {
      arr.splice(ind, 1);
    }
    setValue(arr);
  };
  const currentValue = (item, cardId) => {
    let ind = value.findIndex((el) => el.id === cardId);

    if (ind == -1) {
      return "";
    } else {
      return value[ind][item] ?? "";
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            paddingBottom: "90px",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          flexGrow={1}
          direction={"row"}
          spacing={1}
          // useFlexGap
          flexWrap="wrap"
          style={{ paddingBottom: "90px", overflow: "auto", gap: "20px" }}
        >
          {newsletters.map((card) => (
            <Box
              key={card.id}
              sx={{
                borderRadius: "10px",
                marginBottom: "10px",
                border: value.find((el) => el.id === card.id)
                  ? "1px solid #007bf7"
                  : "1px solid #000",
                maxWidth: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
                marginLeft: "0 !important",
                boxShadow: value.find((el) => el.id === card.id)
                  ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"
                  : undefined,
                backgroundColor: value.find((el) => el.id === card.id)
                  ? "#eee"
                  : undefined,
              }}
              onClick={() => handleClick(card.id)}
            >
              {/* <TextField
                // error={errors?.["subject"]}
                label="From:"
                required
                sx={{
                  width: "80%",
                  marginBottom: "5px",
                }}
                value={currentValue("fromEmail", card.id)}
                onChange={(e) => addItemValue(e, "fromEmail", card.id)}
                // helperText={errors?.["subject"]?.message}
              /> */}
              <TextField
                // error={errors?.["subject"]}
                label="Subject"
                required
                sx={{
                  width: "80%",
                  marginBottom: "5px",
                }}
                value={currentValue("subject", card.id)}
                onChange={(e) => addItemValue(e, "subject", card.id)}
                // helperText={errors?.["subject"]?.message}
              />

              <TemplateCard
                style={{
                  cursor: "pointer",
                  margin: "8px",
                  borderWidth: "20px",
                  borderColor: "grey",
                  width: "144px",
                  height: "192px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor:
                    // ? "#101010"
                    // :
                    "#D6DAE0",
                }}
              >
                {/* {console.log("Card image data:", card.imageData)} */}

                <TemplateImage
                  src={card.imageData ? card.imageData : tempreview}
                  alt="Template Preview"
                  style={{
                    padding: "10px",
                    width: "100%",
                    height: "100%", // Set the preview image height to 75% of the card height
                    objectFit: "contain", // Ensure the image covers the container
                  }}
                />

                <CardContent>
                  <Title
                    sx={{
                      color:
                        card.id === value ? "#ffffff !important" : "#000000",
                    }}
                    style={{
                      marginTop: "-5px",
                      marginBottom: "-1px",
                      fontSize: "1rem",
                      lineHeight: "normal",
                    }}
                  >
                    {card.title}
                  </Title>
                  <SubTitle>{card.description}</SubTitle>
                  {/* <SubTitle style={{ fontSize: "10px" }}>
            {format(card?.lastUpdate,"dd MMM, yyyy")}
          </SubTitle> */}
                </CardContent>
              </TemplateCard>
            </Box>
          ))}
        </Stack>
      )}
    </>
  );
};
