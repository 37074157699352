import {
  Modal1,
  ModalContainer,
  HrLine,
  Modal2,
  Modal1_1,
  Modal1_1_1,
  Modal1_1_1Text,
  Modal1_1_2,
  Modal1_1_2_1,
  Modal1_1_2_1_1,
  Modal1_1_2_1_1Text,
  Modal1_2,
  Modal1_2_1Text,
  Modal1_2_2,
  Modal1_2_2_1,
  Modal1_2_2_1Text,
  Modal1_3,
  Modal1_3_1Text,
  Modal1_3_2Text,
  Modal2_1,
  Modal2_1_1,
  Modal2_1_1_2Text,
  Modal2_1_2,
  Modal2_1_2_2Text,
  Modal2_2,
  Modal2_2_1,
  Modal2_2_1_2Text,
  Modal1_1_2_1_2Text,
  Modal1_1_2_2,
  Modal1_1_2_2Text,
} from "./styled";
import DownloadIcon from "../../assets/icons/Download.svg";
import Trash from "../../assets/icons/Trash.svg";
import CloseIcon from "../../assets/icons/RemoveIcon.svg";
import PhoneIcon from "../../assets/icons/phone.svg";
import ReqIcon from "../../assets/icons/reqIcon.svg";
import GIcon from "../../assets/icons/GoogleIcon.svg";
import { NameTagBox } from "./leadsComp";

export default ({
  display,
  setDisplay,
  data,
  triggerReqTestimonial,
  triggerDelete,
  triggerReqGoogleReview,
}) => {
  return (
    <ModalContainer style={{ display: !display ? "none" : undefined }}>
      <Modal1>
        <Modal1_1>
          <Modal1_1_1>
            <Modal1_1_1Text>Contact Details</Modal1_1_1Text>
            <img
              alt="close"
              src={CloseIcon}
              onClick={() => setDisplay(false)}
              style={{ cursor: "pointer", width: "24px", height: "24px" }}
            />
          </Modal1_1_1>
          <Modal1_1_2>
            <Modal1_1_2>
              {data.rname}
              {/* <Modal1_1_2_1>
                <Modal1_1_2_1_1>
                  <Modal1_1_2_1_1Text>BE</Modal1_1_2_1_1Text>
                </Modal1_1_2_1_1>
                <Modal1_1_2_1_2Text>Billie E. Estrada</Modal1_1_2_1_2Text>
              </Modal1_1_2_1> */}
              <Modal1_1_2_2>
                <img alt="phone" src={PhoneIcon} />
                <Modal1_1_2_2Text>{data.rphoneNumber}</Modal1_1_2_2Text>
              </Modal1_1_2_2>
            </Modal1_1_2>
          </Modal1_1_2>
        </Modal1_1>
        <Modal1_2>
          <Modal1_2_1Text>Status</Modal1_2_1Text>
          <Modal1_2_2 bg={data.status === "contacted"}>
            <Modal1_2_2_1>
              <Modal1_2_2_1Text>
                {data.status === "contacted" ? "Contacted" : "Not Contacted"}
              </Modal1_2_2_1Text>
            </Modal1_2_2_1>
          </Modal1_2_2>
        </Modal1_2>
        <Modal1_3>
          <Modal1_3_1Text>Messages</Modal1_3_1Text>
          <Modal1_3_2Text>{data.message}</Modal1_3_2Text>
        </Modal1_3>
      </Modal1>
      <HrLine />
      <Modal2>
        <Modal2_1>
          <Modal2_1_1 onClick={triggerReqTestimonial}>
            <img alt="req " src={ReqIcon} />
            <Modal2_1_1_2Text>Request Testimony</Modal2_1_1_2Text>
          </Modal2_1_1>
          {/* <Modal2_1_2 onClick={triggerReqGoogleReview}>
            <img src={GIcon} alt="" />
            <Modal2_1_2_2Text>Request Google Review</Modal2_1_2_2Text>
          </Modal2_1_2> */}
        </Modal2_1>

        <Modal2_2>
          <Modal2_1_2>
            <img alt="download" src={DownloadIcon} />
            <Modal2_1_2_2Text>Download</Modal2_1_2_2Text>
          </Modal2_1_2>
          <Modal2_2_1 onClick={triggerDelete}>
            <img alt="del" src={Trash} />
            <Modal2_2_1_2Text>Delete</Modal2_2_1_2Text>
          </Modal2_2_1>
        </Modal2_2>
      </Modal2>
    </ModalContainer>
  );
};
