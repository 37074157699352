import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
//pages
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import AuthGuard from "./protected.route";
import RequireAuth from "./auth.route";
import Organization from "../pages/Organizations";
import SingularOrganization from "../pages/Organizations/Organization";
import Widget from "../pages/Widget";
import Video from "../pages/Organizations/Video";
import UserDashboard from "../pages/UserDashboard";
import SingularBrand from "../pages/Organizations/Brand";
import OrganizationBrands from "../pages/OrganizationBrands";
import InvitationRequets from "../pages/InvitationRequest";
import Invitation from "../pages/Invitation";
import TestInvitation from "../pages/Invitation/test";
import TeamMembers from "../pages/TeamMembers";
import Profilepage from "../pages/ProfilePage";
import CreateBrand from "../pages/CreateBrand";
import BrowseAll from "../pages/BrowseAll";
import CreateOrganization from "../pages/CreateOrganization";
import P404 from "../pages/404";
import Testimonial from "../pages/Testimonial";
import SignInvite from "../pages/SignInvite";
import TestUpload from "../pages/TestUpload";
import PricingPlan from "../pages/PricingPlan";
import BillingPlans from "../pages/BillingPlans";
import Pay from "../pages/Pay";
import RecieverTestimonial from "../pages/Testimonial/RecieverTestimonial";
import Completion from "../pages/Completion";

import UpdatePay from "../pages/UpdatePay";
import MainDashboard from "../pages/MainDashboard";
import Analytics from "../pages/Analytics";
import VideoPage from "../pages/VideosPage";
import CustomizationPage from "../pages/CustomizationPage";
import Layout from "../components/Layout";
import WebsiteBuilder from "../pages/WebsiteBuilder";
import CreatePage from "../pages/WebsiteBuilder/pageById";
import PublishedPg from "../pages/WebsiteBuilder/publishedPg";
import WorkflowPage from "../pages/WorkflowPage";
import CheckoutPg from "../pages/WebsiteBuilder/checkoutPg";
export default function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <RequireAuth>
                <OrganizationBrands />
              </RequireAuth>
            }
          />
          {/* Subscriptions */}
          <Route
            exact
            path="/pay"
            element={
              <RequireAuth>
                <Pay />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/update_pay"
            element={
              <RequireAuth>
                <UpdatePay />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/billingplans"
            element={
              <RequireAuth>
                <BillingPlans />
              </RequireAuth>
            }
          />
          {/* Subscriptions ...End */}
          <Route
            exact
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/maindashboard"
            element={
              <RequireAuth>
                <MainDashboard />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/analytics"
            element={
              <RequireAuth>
                <Analytics />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/customizationpage"
            element={
              <RequireAuth>
                <CustomizationPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invitation"
            element={
              <RequireAuth>
                <Invitation />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invitation/:id"
            element={
              <RequireAuth>
                <Invitation />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/testinvitation"
            element={
              <RequireAuth>
                <TestInvitation />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <RequireAuth>
                <Profilepage />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/teammembers"
            element={
              <RequireAuth>
                <TeamMembers />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/invitation__"
            element={
              <RequireAuth>
                <InvitationRequets />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/organization"
            element={
              <RequireAuth>
                <Organization />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/userDashboard"
            element={
              <RequireAuth>
                <UserDashboard />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/organization_brands"
            element={
              <RequireAuth>
                <OrganizationBrands />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/create_brand/:orgId"
            element={
              <RequireAuth>
                <CreateBrand />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/widget"
            element={
              <RequireAuth>
                <Widget />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/organizations/:id"
            element={
              <RequireAuth>
                <SingularOrganization />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/brands/:id/:tabValue/:chart"
            element={
              <RequireAuth>
                <SingularBrand />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/browse_all"
            element={
              <RequireAuth>
                <BrowseAll />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/completion"
            element={
              <RequireAuth>
                <Completion />
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/create_organization"
            element={
              <RequireAuth>
                <CreateOrganization />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/videos/:id"
            element={
              <RequireAuth>
                <Video />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/videopage"
            element={
              <RequireAuth>
                <VideoPage />
              </RequireAuth>
            }
          />
          <Route
            path="*"
            element={
              <RequireAuth>
                <P404 />
              </RequireAuth>
            }
          />
        </Route>

        <Route
          exact
          path="/login"
          element={
            <AuthGuard>
              <Login />
            </AuthGuard>
          }
        />
        {/* <Route
          exact
          path="/pricingplan"
          element={
            <RequireAuth>
              <PricingPlan />
            </RequireAuth>
          }
        /> */}
        <Route
          exact
          path="/signup"
          element={
            <AuthGuard>
              <Signup />
            </AuthGuard>
          }
        />

        <Route
          exact
          path="/forgotpassword"
          element={
            <AuthGuard>
              <ForgotPassword />
            </AuthGuard>
          }
        />
        <Route
          exact
          path="/resetpassword"
          element={
            <AuthGuard>
              <ResetPassword />
            </AuthGuard>
          }
        />
        <Route
          exact
          path="/home"
          element={
            <AuthGuard>
              <Home />
            </AuthGuard>
          }
        />
        <Route
          exact
          path="/send_testimonial/:videoIdentity"
          element={<RecieverTestimonial />}
        />

        <Route
          exact
          path="/request_testimonial/:id"
          element={<Testimonial />}
        />
        {/* <Route
          exact
          path="/watch-video/:videoUrl"
          element={<UserPlayVideo />}
        /> */}
        <Route exact path="/signnewinvite/:idv" element={<SignInvite />} />
        <Route exact path="/signinvite/:userId/:id" element={<SignInvite />} />
        <Route exact path="/page-builder" element={<WebsiteBuilder />} />
        <Route exact path="/page-builder/:slug" element={<CreatePage />} />
        <Route exact path="/page/:slug" element={<PublishedPg />} />
        <Route exact path="/workflow/:id" element={<WorkflowPage />} />
        <Route exact path="/page/:cartId/checkout" element={<CheckoutPg />} />
        <Route path="/testUpload" element={<TestUpload />} />
      </Routes>
    </BrowserRouter>
  );
}
