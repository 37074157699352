import { gql } from '@apollo/client'
 
const ADD_BRAND = gql`
mutation createBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      _id
    name
    brandId
    
    }
}
`;
const DELETE_BRAND = gql`
mutation deleteBrand($input: DeleteBrandInput!) {
  deleteBrand(input: $input) {
    _id
    sector
    brandId
    description
    name
    imageUrl
    
    organization{
      industry
      name
    }
    
  }
}



`
const UPDATE_BRAND_WIDGET = gql`
mutation updateBrandWidget($input: UpdateBrandWidgetInput!) {
  updateBrandWidget(input: $input) {
    _id
    borderSize
    backgroundColor
    color
    width
    position
    name
  }
}
`;
const UPDATE_BRAND = gql`
mutation updateBrand($input: UpdateBrandInput!) {
  updateBrand(input:$input ) {
    brandId
    name
    imageUrl
    description
    sector
    brandUrl
    tags {
      id
      name
      description
    }
  }
}
`;
// const DELETE_ORGANIZATION = gql`
// mutation deleteOrganization($input: DeleteOrganizationInput!) {
//     deleteOrganization(input: $input) {
//       _id
//       name
//       domainUrl
//       industry
//       organizationId
//     }
//   }
  
// `;
// const UPDATE_ORGANIZATION = gql`
// mutation UpdateOrganization($input: UpdateOrganizationInput!) {
//   updateOrganization(input: $input) {
//     name
//     domainUrl
//     industry
//     organizationId
//   }
// }
  
// `;
const UPDATE_BRAND_TAGS = gql`
mutation updateBrand($input: UpdateBrandInput!) {
  updateBrand(input:$input ) {
    brandId
    name
    imageUrl
    description
    sector
    brandUrl
    tags {
      id
      name
      description
    }
  }
}
`;
export {ADD_BRAND,DELETE_BRAND,UPDATE_BRAND_WIDGET,UPDATE_BRAND,UPDATE_BRAND_TAGS }
