import React, { useEffect, useRef, useState } from "react";
import {
  List,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  IconButton,
  ListItemButton,
  Avatar,
} from "@mui/material";

import { ShowDiv, Text } from "./styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/**
 * A dropdown select component that allows the user to choose from a list of options.
 * @component
 * @param {Object} props - The component's props.
 * @param {Array} props.data - An array of objects representing the selectable options.
 * @param {string|number} props.defaultValue - The default selected value.
 * @param {Function} props.handleChange - A callback function called when the selected value changes.
 * @returns {JSX.Element} - The rendered component.
 */
export default function DropdownSelect({ data = [], defaultValue, handleChange }) {
  const [show, setShow] = useState(false);
  const divRef = useRef(null);
  const [val, setVal] = useState(defaultValue);

  /**
   * Toggles the dropdown menu visibility.
   */
  const toggle = () => {
    setShow(!show);
  };

  /**
   * Gets the title of the currently selected item.
   * @returns {string} - The title of the selected item.
   */
  const getName = () => {
    let result;
    if (data instanceof Array) {
      result = data.find((el) => el.id === val)?.title;
    }
    return result;
  };

  /**
   * Handles the selection of an item and closes the dropdown.
   * @param {string|number} value - The selected item's value.
   */
  const changeHandler = (value) => {
    setVal(value);
    setShow(false);
    handleChange && handleChange(value);
  };

  useEffect(() => {
    /**
     * Closes the dropdown when a click occurs outside the component.
     * @param {MouseEvent} e - The click event.
     */
    const closeOutsideDiv = (e) => {
      if (divRef.current && show && !divRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", closeOutsideDiv);

    return () => document.removeEventListener("mousedown", closeOutsideDiv);
  }, [divRef, show]);

  return (
    <>
      <Box
        sx={{
          padding: "4.8px 12px",
          minWidth: "90px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton sx={{ p: "0px" }} aria-label="directions" onClick={toggle}>
          <Text>{getName()}</Text>
          <KeyboardArrowDownIcon sx={{ color: "black", mt: 1 }} />
        </IconButton>
      </Box>
      {show && (
        <ShowDiv ref={divRef}>
          <List component="nav" aria-label="main mailbox folders">
            {data instanceof Array &&
              data.map((el, idx, arr) => {
                return (
                  <div key={`Dropdown${idx}`}>
                    <ListItemButton onClick={() => changeHandler(el?.id)}>
                      <ListItemText primary={el?.title} />
                    </ListItemButton>
                    {idx < arr.length - 1 && <Divider />}
                  </div>
                );
              })}
          </List>
        </ShowDiv>
      )}
    </>
  );
}
