import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export const UContainer = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
  display: flex;

  // justify-content: space-between;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 32px;
`;

export const UContainerInner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const UContText = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
`;

export const UContButton = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 42px;
  width: 100%;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 28px;
`;

export const UFooter = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
`;

export const CreditCardBrands = styled.div`
  left: 480px !important;
  position: absolute !important;
  top: 14px !important;
  background-image: url(https://cdn.animaapp.com/projects/651e72dd9ec05e1c21b1b06c/releases/65425f30dd186255643c2045/img/icon-creditcard-discover-3.svg) !important;
  position: relative !important;
`;

// export const MasterCard = styled.div`
export const CreditCardCvc = styled.div`
  height: 24px !important;
  left: 200px !important;
  position: absolute !important;
  top: 13px !important;
  width: 24px !important;
`;

export const StyledFrame = styled.div`
align-items: center;
display: inline-flex;
flex-direction: column;
gap: 24px;
position: relative;
width: 100%;

& .text-container {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

& .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 52px;
    position: relative;
}

& .card-panel {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 563px;
}

& .field-card-number {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-self: stretch;
    position: relative;
    width: 100%;
}

& .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 100%;
}

& .text-wrapper-2 {
    align-self: stretch;
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20.3px;
    margin-top: -1px;
    position: relative;
}

& .field {
    align-items: center;
    align-self: stretch;
    background-color: #f9f9f9;
    border: 1px solid #ececf2;
    border-radius: 12px;
    display: flex;
    gap: 8px;
    height: 50px;
    overflow: hidden;
    padding: 12px;
    position: relative;
    width: 100%;
}

& .input {
    color: rgba(133, 137, 149, 1);
    flex: 1;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 23.2px;
    position: relative;
    border: none;
    background: transparent;
}

& .credit-card-brands-instance {
    left: 446 !important;
    position: absolute !important;
    top: 14px !important;
}

& .icon-instance-node-2{
    height: 16px !important;
    position relative !important;
    width: 24px !important;
}

& .design-component-instance-node {
    background-image: url();
    position: relative !important;
}

& .tablist {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
    width: 100%;
}

& .div-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}
    & .icon-creditcardcvc {
    height: 24px !important;
    left: 240px !important;
    position: absolute !important;
    top: 13px !important;
    width: 24px !important;
}
    & .input-2 {
    background: transparent;
    border: none;
    color: rgba(133, 137, 149, 1);
    flex: 1;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 23.2px;
    padding: 0;
    position: relative;
}
    & .arrows-diagrams {
    height: 24px;
    position: relative;
    width: 24px;
}
    & .wrap {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 42px;
    position: relative;
    width: 100%;
}
    & .div-wrapper-2 {
    align-items: center;
    background-color: #f1f3ec;
    border-radius: 14px;
    border-color: #f1f3ec;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 54px;
    justify-content: center;
    overflow: hidden;
    padding: 30px 40px;
    position: relative;

    &:hover {
        background-color: #e1e3dd; 
        cursor: pointer;
      }
    
      &:active {
        background-color: #d1d3cd; 
      }
}
    & .text-wrapper-3 {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 10.1px;
    margin-bottom: -77.5px;
    margin-top: -79.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}
    & .div-wrapper-3 {
    align-items: center;
    background-color: rgba(26, 27, 36, 1);
    border-radius: 14px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 10px;
    height: 54px;
    justify-content: center;
    overflow: hidden;
    padding: 30px 40px;
    position: relative;

    &:hover {
        background-color: rgba(50, 50, 50, 1); 
        cursor: pointer;
      }
    
      &:active {
        background-color: rgba(100, 100, 100, 1); 
      }
}
    & .text-wrapper-4 {
    color: #fff;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 10.1px;
    margin-bottom: -77.5px;
    margin-top: -79.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}
    & .this-site-is {
    align-self: stretch;
    color: rgba(93, 98, 114, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    position: relative;
    text-align: center;
    width: 563px;
}
    & .span {
    color: #5d6272;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
}
    & .text-wrapper-5 {
    text-decoration: underline;
}

&.icon-instance-node-2 {
    height: 16px !important;
    position: relative !important;
    width: 24px !important;
  }

   `;
