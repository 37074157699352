import { gql } from '@apollo/client'


const SINGULAR_MARK = gql`
mutation SingularMark($userId: String!,$notificationId:String!) {
    singularMark(userId: $userId,notificationId:$notificationId) {
        _id
        userId
        createdAt
       notificationMessage{
         message
         type
        status
        invitationCode
        videoName
        videoPosition
        videoRating
        recent
          owned
        brandAccessName{
          brand
          access
          brandColor
          id
           brandId
        }
        thumbnail
        createdAt
        notificationId
      
       }
    }
  }
  
`;
const MARK_ALL_AS_READ = gql`
mutation MarkAllAsRead($userId: String!) {
  markAllAsRead(userId: $userId) {
        _id
        userId
        createdAt
       notificationMessage{
         message
         type
        status
        invitationCode
        videoName
        videoPosition
        videoRating
        recent
          owned
        brandAccessName{
          brand
          access
          brandColor
          id
           brandId
        }
        thumbnail
        createdAt
        notificationId
      
       }
    }
  }
  
`;
const MARK_ALL_AS_OLD = gql`
mutation MarkAllNotificationOld($userId: String!) {
    markAllNotificationOld(userId: $userId) {
        _id
        userId
        createdAt
       notificationMessage{
         message
         type
        status
        invitationCode
        videoName
        videoPosition
        videoRating
        recent
          owned
        brandAccessName{
          brand
          access
          brandColor
          id
           brandId
        }
        thumbnail
        createdAt
        notificationId
      
       }
    }
  }
  
`;

export {SINGULAR_MARK,MARK_ALL_AS_OLD,MARK_ALL_AS_READ}