import { gql } from "@apollo/client";

export const USER = gql`
  fragment UserFields on User {
    _id
    email
    name
    created_at
    verified
    isTrial
    subscriptionType
    subExpDate
    lastCardDigits
    cardExpDate
    cardType
    renewal
    endSubscription
    stripeCustomerId
  }
`;

// subscriptionStatus
//     verifiedStatus
