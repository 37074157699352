import { Box } from "@mui/material";
import VideoSectionItem from "./videoSectionItem";
import React from "react";

export default React.memo(
  ({
    picArray,
    setVideoSrc,
    setVideoSrcArray,
    getFirstVideo,
    videoSrcArray,
    videoSrc,
    isMobile,
  }) => {
    return (
      <>
        {picArray.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: isMobile ? "5px" : "22px",
              width: isMobile ? "100%" : "auto", //220px
              height: "100%",
              overflow: "auto",
              padding: isMobile ? "10px" : "10px 0",
            }}
          >
            {picArray.map(
              (el, idx) =>
                videoSrcArray[idx] && (
                  <VideoSectionItem
                    image={el}
                    key={`id${idx}`}
                    item={idx}
                    clickFn={() => {
                      setVideoSrc(videoSrcArray[idx]);
                    }}
                    clickDeleteFn={() => {
                      let vidArray = videoSrcArray;
                      let activeIndex = vidArray[idx] === videoSrc;
                      vidArray[idx] = null;
                      setVideoSrcArray(vidArray);
                      if (activeIndex) setVideoSrc(getFirstVideo(vidArray));
                    }}
                  />
                )
            )}
          </Box>
        )}
      </>
    );
  }
);
