import { gql } from "@apollo/client";

export const CREATE_TIMESLOT = gql`
  mutation createTimeSlot($input: TimeSlotInput!) {
    createTimeSlot(input: $input) {
      _id
      type
      Monday {
        from
        to
        no
        allDay
      }
      Tuesday {
        from
        to
        no
        allDay
      }
      Wednesday {
        from
        to
        no
        allDay
      }
      Thursday {
        from
        to
        no
        allDay
      }
      Friday {
        from
        to
        no
        allDay
      }
      Saturday {
        from
        to
        no
        allDay
      }
      Sunday {
        from
        to
        no
        allDay
      }
    }
  }
`;

export const UPDATE_TIMESLOT = gql`
  mutation updateTimeSlot($id: String!, $input: TimeSlotInput!) {
    updateTimeSlot(id: $id, input: $input) {
      _id
      type
      Monday {
        from
        to
        no
        allDay
      }
      Tuesday {
        from
        to
        no
        allDay
      }
      Wednesday {
        from
        to
        no
        allDay
      }
      Thursday {
        from
        to
        no
        allDay
      }
      Friday {
        from
        to
        no
        allDay
      }
      Saturday {
        from
        to
        no
        allDay
      }
      Sunday {
        from
        to
        no
        allDay
      }
    }
  }
`;
