import styled from "@emotion/styled";
import { Box, ButtonBase } from "@mui/material";

export const UplContainer = styled(Box)`
  display: ${(props) =>
    props?.display === true ? "block" : "none"}; /* Hidden by default */

  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;

  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const InputWrapper = styled.input`
  // background: #f9f9f9;
  background: #fff;
  /* Element */

  // border: 1px solid ${(props) => props?.borderColor ?? "#d4dae1"};

  border-radius: 12px;
  height: 54px;
  width: 100%;
  margin-bottom: 13px;
  padding-left: 1em;
  margin-top: 6px;
  font-size: 14px;
  &:focus {
    outline: none;
    // border: 1px solid #8d6cff;
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  border: ${(props) =>
    props?.borderColor
      ? ` 1px solid  ${props?.borderColor}`
      : props?.mfocus
      ? ` 1px solid  #007bf7`
      : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.mfocus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  @media (max-width: 800px) {
    margin-bottom: 22px;
  }
`;
export const UplContainerMinimized = styled(Box)`
  display: ${(props) =>
    props?.display === true ? "block" : "none"}; /* Hidden by default */

  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */

  right: 0;
  bottom: 5%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // border-radius: 16px;
  // background-color: #fff;
  // box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.14);
  // font-size: 20px;
  // padding: 10px;

  // overflow: auto; /* Enable scroll if needed */
`;

export const UplWrapDiv = styled(Box)`
  display: ${(props) => (props?.hide ? "none" : "flex")};
  justify-content: ${(props) =>
    props?.isMobile ? "flex-start" : "space-between"};
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  height: ${(props) => (props?.isMobile ? "80%" : undefined)};
  width: ${(props) => (props?.isMobile ? "100%" : "100%")};
  margin: ${(props) => (props?.isMobile ? "0" : "0 auto")};
  height: 100%;
  // gap: 48px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const UplContent = styled(Box)`
  //   background-color: #fefefe;
  background-color: transparent;
  // margin: 5% auto; /* 15% from the top and centered */
  // padding: 20px;
  // border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
`;

export const UploadFullWrapper = styled.div`
  width: ${(props) => (props?.isMobile ? "100%" : "80%")};
  // margin: ${(props) => (props?.isMobile ? "1.5rem auto" : "0px")};
  margin: ${(props) => (props?.isMobile ? "0 auto 4rem auto" : "0px")};
  height: ${(props) => (props?.isMobile ? "100%" : undefined)};
  max-width: 1258px;
  border: 2px solid #fff;
  flex-shrink: 0;
  gap: 54.033px;

  color: ${(props) => props?.brandProps?.color ?? "#fff"};

  padding: ${(props) => (props?.isMobile ? "0" : "2% ")};
  border-radius: 16px;
  background: #fff;

  /* modal shad */
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.14);
`;

export const VideoPartUplWrapper = styled.div`
  height: ${(props) => (props?.isMobile ? "100%" : "100%")};

  width: ${(props) => (props?.isMobile ? "100%" : "70%")};
  margin-bottom: ${(props) => (props?.isMobile ? "20px" : undefined)};
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const FormUplWrapper = styled.div`
  width: ${(props) => (props?.isMobile ? "100%" : "30%")};
  min-height: ${(props) => (props?.isMobile ? undefined : "60vh")};
  // margin-top: ${(props) => (props?.isMobile ? "1.5rem" : "0px")};
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const VideoImageCont = styled(Box)`
  width: 133px;
  height: 100px;
  border-radius: 8px;
  box-sizing: border-box;

  &:hover {
    width: 118px;
    height: 84px;
  }

  @media (max-width: 576px) {
    width: 80px;
  }
`;

export const VideoImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;
// export const VideoImage = styled(Box)`
//   width: 133px;
//   height: 100px;
//   border-radius: 8px;
//   background: ${(props) =>
//     props?.image
//       ? `url(${props?.image}), lightgray 50% / cover no-repeat`
//       : undefined};
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   box-sizing: border-box;
//   &:hover {
//     width: 118px;
//     height: 84px;
//   }
// `;

export const VideoImageWrapper = styled(Box)`
  box-sizing: border-box;
  &:hover {
    border-radius: 12px;
    border: 1px solid #007bf7;
    padding: 6px;
    /* Blue shadown for selected state */
    box-shadow: 0px 0px 4px 0px rgba(69, 193, 126, 0.4);
  }
`;

export const VideoImageFullWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;
  cursor: pointer;
`;

export const ImageRounded = styled(Box)`
  background: #fff;
  height: 24px;
  width: 24px;

  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props?.hover &&
    `border-radius: 50%;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);`}
`;

export const ProgressVidContainer = styled(Box)`
  display: flex;
  width: 360px;
  max-width: 90%;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(15px);
`;

export const ProgressVidTop = styled(Box)`
  display: flex;
  padding: 2px 10px 2px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ProgressVidTopText = styled(Box)`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
`;

export const ProgressVidTopSide = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProgressVidBottom = styled(Box)`
  display: flex;
  padding: 0px 10px 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  overflow: auto;
`;
export const ProgressVidBottomItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const ProgressVidBottomItemContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProgressVidBottomItemContentText = styled(Box)`
  color: #1a1b24;
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
`;

export const CancelBox = styled(Box)`
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: #fff;

  /* modal shad */
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.14);
  position: fixed;
  top: 30%;
  left: 30%;
`;

export const CancelBoxTop = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const CancelBoxTopText = styled(Box)`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 38.4px */
`;

export const CancelBoxMiddleText = styled(Box)`
  display: flex;
  width: 493px;
  height: 18.421px;
  flex-direction: column;
  justify-content: center;
  color: #5d6272;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
`;

export const CancelBoxButtonDiv = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 21px;
  align-self: stretch;
`;

export const CancelBoxButton1 = styled(ButtonBase)`
  display: flex;
  padding: 13.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  background: #1a1b24;
  border-radius: 14px;
  background: #1a1b24;
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const CancelBoxButton2 = styled(ButtonBase)`
  display: flex;
  width: 150px;
  padding: 13.5px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  border: 1px solid #d4dae1;
  border-radius: 14px;
  border: 1px solid #d4dae1;
  color: #007bf7;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const RplButton = styled(ButtonBase)`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  height: 54px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  border: 1px solid var(--Element, #d4dae1);
  background: var(--neutral-palette-platinum-platinum-25, #f7f7f8);
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
