import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const Cont = styled(Box)`
  width: 300px; /* Adjust this value based on your container width */
  font-size: 16px; /* Adjust this value based on your font size */
  line-height: 1.5; /* Adjust this value based on your line height */
  //   white-space: nowrap;
  overflow: hidden;
  //   display: flex;
  display: inline-block;
  //   width: 100%;
  padding: 12px 14px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: #fafafa;
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);

  /* Body1/Regular */
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  align-self: stretch;
  line-height: 20px;
`;

export const Cont2 = styled(Box)`
  color: var(--dark-grey-palette-primary-black-black-100, #000);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  cursor: pointer;
`;
