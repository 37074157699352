import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const ActButton = styled(Button)`
  height: 40px;
  border: 1px solid #d4dae1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 9px 24px;
  text-transform: capitalize;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #1a1b24;
`;
