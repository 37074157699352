import { gql } from "@apollo/client";

export const PAGE = gql`
  fragment PageFields on PageBuilder {
    _id
    name
    slug
    header
    html
    css
    isPublished
    asset {
      fileName
      extension
    }
    products {
      id
      name
      price
      description
    }
    brand {
      _id
      brandId
    }
  }
`;
