import React from "react";
import { Container, Text } from "./styled";

/**
 * AvatarLabel is a component for displaying avatars with labels.
 * @component
 * @param {string} background - Background color or image for the avatar.
 * @param {string} image - Image source for the avatar.
 * @param {string} color - Color for the label text.
 * @param {string} title - Title to display on the avatar.
 * @param {boolean} showRoleName - Whether to display the role name.
 * @param {string} role - Role name to display.
 * @param {boolean} placeRight - Whether to place the label on the right.
 * @param {boolean} styleTitle - Whether to apply custom styling to the title.
 * @returns {JSX.Element}
 */
export default function AvatarLabel({
  background,
  image,
  color,
  title = "P",
  showRoleName,
  role = "",
  placeRight,
  styleTitle,
}) {
  const renderTitle = () => title?.toString()?.trim().charAt(0).toUpperCase();

  const renderAllTitle = () => {
    return (
      <>
        {role}{" "}
        {
          <span
            style={{
              color: styleTitle ? "#000" : undefined,
              textTransform: "capitalize",
            }}
          >
            {title}
          </span>
        }
      </>
    );
  };

  return (
    <Container
      placeRight={placeRight}
      showRoleName={showRoleName}
      background={background}
      isNumber={role === "" && showRoleName}
    >
      {showRoleName ? (
        <Text placeRight={placeRight} color={color}>
          {renderAllTitle()}
        </Text>
      ) : (
        <>
          {image && image !== "" ? (
            <img src={image} style={{ objectFit: "cover", height: 10 }}  alt={title}/>
          ) : (
            <Text single color={color}>
              {renderTitle()}
            </Text>
          )}
        </>
      )}
    </Container>
  );
}
