// paymentSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: { subscription: null, status: 'inactive', date: null, amount: null, },
  reducers: {
    updatePaymentStatus: (state, action) => {
      state.subscription = action.payload.subscription;
      state.status = action.payload.status;
      state.date = action.payload.date;
      state.amount = action.payload.amount;
    },
    clearSubscription: (state) => {
        state.subscription = null;
        state.status = 'inactive';
        state.date = null;
        state.amount = null;
      },
  },
});

export const { updatePaymentStatus, clearSubscription } = paymentSlice.actions;

export const paymentReducer =  paymentSlice.reducer;