import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const Container = styled(Box)``;
export const DivText = styled(Box)`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  //   border: 1px solid #ccc;
  width: 100%;
  // padding-bottom: 10px;

  cursor: pointer;
`;

export const OptionDiv = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  right: 30px;
  min-height: 20px;
  padding: 0.3em 0.4em;
  position: absolute;
  z-index: 10000;
  background-color: white;
`;
export const OptionDivTwo = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  right: 0px;
  // min-height: 20px;
  width: 177px;
  // height: 96px;
  padding: 0.3em 0.4em;
  position: absolute;
  // z-index: 10000;
  // top: 3.15rem;
  // top: 72px;
  // top: 60px;
  top: 55px;
  z-index: 10;
  background: #ffffff;
  /* Stroke + path Color */

  border: 1px solid #ececf2;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  // border-radius: 10px;
  border-radius: 15px;
`;

export const OptionItem = styled(Box)`
  cursor: pointer;
  padding: 10px 0em;
  width: 100%;

  &:hover {
    // background-color: #ccc;
    background-color: #f7f7f7;
  }
`;
// border-bottom: ${(props) => !props?.last && "1px solid #ccc"};

export const OptionItemTwo = styled(Box)`
  cursor: pointer;
  padding: 5px 0 5px 10px;
  width: 100%;
  &:hover {
    background-color: ${(props) => !props.isMobile && "#f7f7f7"};
    border-radius: ${(props) => !props.isMobile && "12px"};
  }
`;
