import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Grid,
  Container,
  Box,
  Divider,
  InputBase,
  Paper,
  IconButton,
  ListItemButton,
  Modal,
  TextField,
  Avatar,
  MenuItem,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  DraftsOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutline,
  ReceiptOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../assets/icons/Message.svg";
import RemoveIcon from "../assets/icons/RemoveIcon.svg";
import RemoveIconTwo from "../assets/icons/RemoveIconTwo.svg";
import CopyLink from "../assets/images/CopyLink.svg";
import NonInvite from "../assets/images/NonInvite.svg";
import InviteButton from "../assets/images/InviteButton.svg";
// import { DashBoardContainer, ModalBoxContainer, ModalBoxHeader, ModalBoxSubText, ModalBoxText, ModalButton, ModalButtonText, ModalColumnContainer, ModalImageContainer, ModalImageTitle, ModalTextField, NavContainer, NavDivider, NavHeaderLogoText, NavImage, NavSearch, NavTitleText, ProfileButton, ProfileButtonText, ProfileImage, ProfileImageContainer, ProfileImageContent, ProfileImageContentHeader, ProfileImageContentText, ProfileSnippet, SearchResultsBarBottom, SearchResultsBarText, SearchResultsBarTop, SearchResultsBrandIcon, SearchResultsBrandText, SearchResultsForm } from "../../pages/UserDashboard/styled";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";
import { BannerText } from "../pages/UserDashboard/styled";
import {
  CopyLinkText,
  EmailForm,
  EmailFormContainer,
  EmailFormList,
  EmailItem,
  EmailItemImage,
  EmailItemText,
  RequestContainer,
  RequestSubText,
} from "../pages/Organizations/styled";
import { TESTIMONIAL_INVITE } from "../graphql/mutations/organizationMutation";
import ButtonCust from "./ButtonCust";
import BottomPopup from "./BottomPopup";
import ColorButton from "./ColorButton";

function RequestDialog({ openDialog, onClose, brand, isMobile }) {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.auth);
  const userId = profileData?.user?._id;
  const [showResults, setShowResults] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [openBrand, setBrandOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const id = brand?.brandId;
  // const brandId = brand?._id;
  const brandId = brand?.brandId;
  const navigate = useNavigate();

  const [testimonialInvite] = useMutation(TESTIMONIAL_INVITE);
  // Add function to toggle visibility of search results
  const toggleResults = () => {
    setShowResults(!showResults);
    setShowProfile(false);
  };

  // Add function to toggle visibility of profile
  const toggleProfile = () => {
    setShowProfile(!showProfile);
    setShowResults(false);
  };
  const handleBrandOpen = () => {
    setBrandOpen(true);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  };

  // get organizations for select

  const handleClose = () => {
    setOpen(false);
    setShowResults(!showResults);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleBrandClose = () => setBrandOpen(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateEmails = (emailList) => {
    return emailList.every((email) => isValidEmail(email));
  };

  const handleInputChange = (e) => {
    setCurrentEmail(e.target.value);
    // if (e.target.value.includes(' ') || !isValidEmail(e.target.value)) {
    //   // Disable submit button if current email is invalid or contains spaces
    //   setEmails([...emails]);
    // } else {
    //   setEmails([...emails, e.target.value]);
    // }
  };

  const handleKeyDown = (e) => {
    if (
      (e.key === "Enter" || e.key === " " || e.key === ",") &&
      currentEmail.trim() !== ""
    ) {
      e.preventDefault();
      setEmails([...emails, currentEmail.trim()]);
      setCurrentEmail("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create an array that includes the currentEmail and the emails in the state
    const allEmails = [...emails, currentEmail.trim()].filter(
      (email) => email.trim() !== ""
    );

    // Check if any email is invalid
    if (!validateEmails(allEmails)) {
      setOpenSnackbar(true);
      setSnackbarMessage("Invalid email(s) found.");
      return;
    }

    // Send invites for all valid emails
    for (const email of allEmails) {
      try {
        await testimonialInvite({
          variables: {
            input: {
              invitedUserEmail: email,
              brandId: brandId, // Replace with the actual brand ID
            },
          },
        });
        setOpenSnackbar(true);
        setSnackbarMessage(`Invite sent to ${email}`);
      } catch (error) {
        setOpenSnackbar(true);
        setSnackbarMessage(`Failed to send invite to ${email}`);
      }
    }

    // Clear the state after sending invites
    setEmails([]);
    setCurrentEmail("");
    // Perform additional actions after sending the invites
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };
  const text = `${process.env.REACT_APP_CLIENT_URL}/testimonial/${id}/${userId}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(text);
    setOpenSnackbar(true);
    setSnackbarMessage(`Link Copied to Clipboard`);
  };

  const displayContent = () => (
    <>
      {!isMobile && <BannerText>Request Review</BannerText>}
      <Box sx={{ padding: isMobile && "16px" }}>
        <RequestSubText>
          Send request via{" "}
          <img src={Message} style={{ paddingBottom: "2px" }} alt="inbox" />{" "}
          Email
        </RequestSubText>
        <RequestContainer
          style={{
            width: "100%",
            justifyContent: "space-between",
            marginTop: "0.5rem",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <EmailFormContainer isMobile={isMobile}>
            <EmailForm>
              <EmailFormList>
                {emails.map((email, index) => (
                  <EmailItem key={index} isValid={isValidEmail(email)}>
                    <EmailItemText isValid={isValidEmail(email)}>
                      {email}
                    </EmailItemText>

                    {isValidEmail(email) ? (
                      <EmailItemImage>
                        <img
                          src={RemoveIcon}
                          alt="remove-icon"
                          onClick={() => handleRemoveEmail(index)}
                          className="remove-button"
                        ></img>
                      </EmailItemImage>
                    ) : (
                      <EmailItemImage>
                        <img
                          src={RemoveIconTwo}
                          alt="remove-icon"
                          onClick={() => handleRemoveEmail(index)}
                          className="remove-button"
                        ></img>
                      </EmailItemImage>
                    )}
                  </EmailItem>
                ))}
              </EmailFormList>
              <form>
                <input
                  type="text"
                  value={currentEmail}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={
                    emails.length === 0
                      ? "Enter one or more email addresses"
                      : ""
                  }
                  className="email-input"
                  isValid={isValidEmail(currentEmail)}
                />
              </form>
            </EmailForm>
          </EmailFormContainer>

          {/* <ColorButton
            onClick={handleSubmit}
            title="Send Invite"
            width={isMobile ? "100%" : "201px"}
            background={
              emails.length === 0 ||
              emails.some((email) => !isValidEmail(email.trim())) ||
              (currentEmail.trim() !== "" && !isValidEmail(currentEmail.trim()))
                ? "transparent"
                : "#B8A9FF"
            }
            disabled={
              emails.length === 0 ||
              emails.some((email) => !isValidEmail(email.trim())) ||
              (currentEmail.trim() !== "" && !isValidEmail(currentEmail.trim()))
            }
          /> */}
          <ColorButton
            onClick={handleSubmit}
            title="Send Invite"
            width={isMobile ? "136px" : "136px"}
            background={
              (emails.length === 0 || !validateEmails(emails)) &&
              !isValidEmail(currentEmail.trim())
                ? "#1A1B24"
                : "#1A1B24"
            }
            disabled={
              (emails.length === 0 || !validateEmails(emails)) &&
              !isValidEmail(currentEmail.trim())
            }
          />
          {/* <button
            type="button"
            disabled={
              emails.length === 0 ||
              emails.some((email) => !isValidEmail(email.trim())) ||
              (currentEmail.trim() !== "" && !isValidEmail(currentEmail.trim()))
            }
            onClick={handleSubmit}
            style={{ border: "none", background: "none" }}
          >
            {emails.length === 0 ||
            emails.some((email) => !isValidEmail(email.trim())) ||
            (currentEmail.trim() !== "" &&
              !isValidEmail(currentEmail.trim())) ? (
              <img
                src={NonInvite}
                alt="copy-link"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img
                src={InviteButton}
                alt="copy-link"
                style={{ cursor: "pointer" }}
              />
            )}
          </button> */}
        </RequestContainer>
        {!isMobile && (
          <RequestContainer style={{ marginTop: "1rem" }}>
            <CopyLinkText style={{ marginLeft: "3px" }}>Via link</CopyLinkText>
            <img
              src={CopyLink}
              alt="copy-link"
              style={{ cursor: "pointer", paddingTop: "3px" }}
              onClick={handleCopyLink}
            />
          </RequestContainer>
        )}
      </Box>
    </>
  );
  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />

      {isMobile ? (
        <BottomPopup
          containerStyle={{ height: "99vh", borderRadius: "0" }}
          isOpen={openDialog}
          setIsOpen={onClose}
          title={
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                marginLeft: "5%",
                marginRight: -20,
              }}
            >
              <Box
                sx={{
                  color: "#1A1B24",
                  fontFamily: "Dm Sans",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  marginTop: "-5px",
                }}
              >
                Request Review
              </Box>
              <img
                src={CopyLink}
                alt="copy-link"
                style={{ cursor: "pointer", paddingTop: "3px" }}
                onClick={handleCopyLink}
              />
            </div>
          }
        >
          {displayContent()}
        </BottomPopup>
      ) : (
        <Dialog
          open={openDialog}
          onClose={onClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="md"
          style={{ borderRadius: "15px !important" }}
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent>{displayContent()}</DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default RequestDialog;
