import { gql } from "@apollo/client";

export const GET_SALES_TIMESLOT = gql`
  query {
    getSalesTimeSlot {
      _id
      type
      Monday {
        from
        to
        no
        allDay
      }
      Tuesday {
        from
        to
        no
        allDay
      }
      Wednesday {
        from
        to
        no
        allDay
      }
      Thursday {
        from
        to
        no
        allDay
      }
      Friday {
        from
        to
        no
        allDay
      }
      Saturday {
        from
        to
        no
        allDay
      }
      Sunday {
        from
        to
        no
        allDay
      }
    }
  }
`;
