import React, { useEffect, useState }from 'react'
import {Line} from 'react-chartjs-2'
import {Chart as ChartJS,Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler} from 'chart.js'
import { DISAPPROVED_VIDEOS_DAYS_COUNT, RATING_DAYS_COUNT } from '../graphql/queries/videosQueries';
import { useQuery } from '@apollo/client';
import Spinner from './Spinner';
ChartJS.register(
    Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler
)
function RatingChart({id, startDate,endDate}) {
    
    const { loading: approvedLoading, error: approvedError, data: approvedData } = useQuery(RATING_DAYS_COUNT, {
      variables: { brandId: id, startDate:startDate ,endDate:endDate},
        fetchPolicy: 'cache-and-network',
      });
      // if (approvedLoading || approvedError) {
      //   return <><Spinner/></>;
      // }
       // Extract the relevant data from the query result
    

  
  const [previousData, setPreviousData] = useState( approvedData?.ratingDaysVideoCount );

  useEffect(() => {
    if (approvedData?.ratingDaysVideoCount) {
      setPreviousData(approvedData?.ratingDaysVideoCount);
    }
  }, [approvedData?.ratingDaysVideoCount]);

  if (!previousData) {
    return null; // Or handle the case when no data is available
  }

  const videoCounts = previousData.slice(0, -1).map(({ date, rating,percentChange }) => ({ date, rating,percentChange }));
  
    const newData = {
        labels: videoCounts?.map(({ date }) => date),
        datasets: [
          {
            data: videoCounts?.map(({ rating }) => rating),
            // background:"linear-gradient(262.87deg, rgba(141, 108, 255, 0.1) 0%, rgba(141, 108, 255, 0) 100%);",
            borderColor:"#00D6DD",
            tension:0.5,
            borderWidth:1,
            // fill:true,
            pointStyle:'none',
          },
        ],
      };
    const DISPLAY = false;
const BORDER = true;
const CHART_AREA = true;
const TICKS = true;
    const options = {
        responsive: true,
  maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          }
        },
        scales: {
          x: {
            ticks: {
                display: false, // remove x-axis labels
              },
            border: {
              display: false
            },
            grid: {
              display: DISPLAY,
              drawOnChartArea: CHART_AREA,
              drawTicks: TICKS,
            }
          },
          y: {
            ticks: {
                display: false, // remove y-axis labels
              },
            border: {
              display: false
            },
            grid: {
                display: DISPLAY,
                drawOnChartArea: CHART_AREA,
                drawTicks: TICKS,
              },
            }
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      
       // remove the 'label' property from the dataset
  newData.datasets[0].label = undefined;
  return (
    <div className="chart-container" style={{height:"50px",position:"relative",width:"100%"}} >
        <Line data={newData} options={options}  style={{width:"100%"}}></Line>
    </div>
  )
}

export default RatingChart
