import React, { useRef, useState, useEffect } from "react";
import {
  RestWrapper,
  ImageRest,
  RestFullWrapper,
  PlayButton,
  RestImage,
} from "./styled";
import PointedCircle from "../../assets/images/PointedCircle"; // Import the PointedCircle component

import Draggable from "react-draggable";

/**
 * RestComp component for displaying a resting state with a play button.
 * @param {Object} props - Component properties.
 * @param {string} props.publicKey - The public key.
 * @param {function} props.setClicked - A function to set the clicked state.
 * @param {string} props.image - The URL of the image to display.
 * @param {Object} props.brandProps - Brand-related properties.
 */
const RestComp = ({ publicKey, setClicked, image, brandProps }) => {
  const [pos, setPos] = useState(null);
  const nodeRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // Update window width and height on resize
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleStop = (e) => {
    let objDimensions = nodeRef?.current?.getBoundingClientRect();
    if (objDimensions) {
      // console.log(objDimensions?.x, objDimensions?.y, "dimensions");
      // console.log(window.innerWidth, window.innerHeight, "Window Size");
      // console.log(windowWidth, windowHeight, "React Window Size");

      if (
        objDimensions?.x < 0 ||
        objDimensions?.x > windowWidth ||
        objDimensions?.y < 0 ||
        objDimensions?.y > windowHeight
      ) {
        setPos({ x: 10, y: 10 });
      } else {
        setPos(null);
      }
    }
  };

  return (
    <Draggable position={pos} onStop={handleStop}>
      {/* Resting State */}
      <div ref={nodeRef}>
        <PointedCircle
          clickFn={() => setClicked("play")}
          brandProps={brandProps}
          resting
        />
      </div>
    </Draggable>
  );
};

export default RestComp;
