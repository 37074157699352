import React from "react";
import Invitation from "./index";
import { useState } from "react";

export default function () {
  const [inviteState, setInviteState] = useState({});
  // console.log(inviteState, "inviteState");

  return (
    <Invitation
      inputNumber={2}
      inputOrganization="6454d036cb42daf06afdb6e8"
      description="Inviteeee"
      setInviteFormstate={setInviteState}
    />

    //  submit
  );
}
