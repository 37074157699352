import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import iconcreditcardvisa from "../../assets/icons/iconcreditcardvisa.svg";
import {
  PCont1,
  PCont1_1,
  PCont1_1Inner,
  PCont1_1Text,
  PCont1_1_1,
  PCont1_1_1Box,
  PCont1_1_2,
  PCont1_2,
  PContainer,
  UpdateCardFrame,
} from "./styled";
import Ellipsis4 from "../../assets/images/ellipsis4.svg";
import VisaIcon from "../../assets/images/visa.svg";
import VisaIcon_1 from "../../assets/images/visa_1.svg";
import AmexIcon from "../../assets/images/amex.svg";
import AmexIcon_1 from "../../assets/images/amex_1.svg";
import DiscoverIcon from "../../assets/images/discover.svg";
import DiscoverIcon_1 from "../../assets/images/discover_1.svg";
import MasterIcon from "../../assets/images/mastercard.svg";
import MasterIcon_1 from "../../assets/images/mastercard_1.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import ChangeIcon from "../../assets/images/briefcase.svg";
import ColorButton from "../ColorButton";
import { REMOVE_CARD_DETAILS } from "../../graphql/mutations/payment";
import { useSnackbar } from "notistack";
import { setUser } from "../../redux/auth.slice";

export const imgIcon = (val, formClicked = true) => {
  switch (val) {
    case "mastercard":
      return formClicked ? MasterIcon : MasterIcon_1;
    case "amex":
      return formClicked ? AmexIcon : AmexIcon_1;
    case "discover":
      return formClicked ? DiscoverIcon : DiscoverIcon_1;
    default:
      return formClicked ? VisaIcon : VisaIcon_1;
  }
};

export default function UpdateCardComp({ user, setShowUpdateCard }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [mouseState, setMouseState] = useState({});
  // Retrieve card info from the Redux store
  const { lastFourDigits, expirationDate } = useSelector(
    (state) => state.cardInfo
  );
  const [removeCard] = useMutation(REMOVE_CARD_DETAILS);
  const dispatch = useDispatch();
  let formattedExpirationDate = "";

  // Check if expirationDate is in the correct format (MM/YY)
  const expirationParts = expirationDate.split("/");
  if (expirationParts.length === 2) {
    const [month, year] = expirationParts.map((part) => parseInt(part, 10));

    // Ensure that 'month' and 'year' are integers and within valid ranges
    if (!isNaN(month) && !isNaN(year) && month >= 1 && month <= 12) {
      const fullYear = year < 50 ? 2000 + year : 1900 + year;

      // Create a JavaScript Date object
      const expiration = new Date(fullYear, month - 1, 1);

      // Check if the date is valid
      if (!isNaN(expiration.getTime())) {
        formattedExpirationDate = expiration.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });
      } else {
        // console.log("Invalid date");
      }
    } else {
      // console.log("Invalid month or year in expiration date");
    }
  } else {
    // console.log("Invalid expiration date");
  }

  const handleDelete = async () => {
    try {
      const { data } = await removeCard();
      dispatch(setUser(data.removePaymentCard));
      enqueueSnackbar(`Card successfully removed`, {
        variant: "success",
      });
      setShowUpdateCard(false);
    } catch (err) {
      enqueueSnackbar(`Removal failed: ${err}`, {
        variant: "error",
      });
    }
  };

  return (
    <PContainer
      style={{ justifyContent: !user?.lastCardDigits ? "flex-end" : undefined }}
    >
      {user?.lastCardDigits && (
        <PCont1>
          <PCont1_1>
            <PCont1_1_1>
              <PCont1_1_1Box>
                <img src={imgIcon(user?.cardType)} />
              </PCont1_1_1Box>

              <PCont1_1Inner>
                <PCont1_1_2>Card</PCont1_1_2>
                <img src={Ellipsis4} alt="" />
                <PCont1_1_2>{user?.lastCardDigits}</PCont1_1_2>
              </PCont1_1Inner>
            </PCont1_1_1>
            <PCont1_1Text>
              Expires{" "}
              {user?.cardExpDate &&
                new Date(user?.cardExpDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                })}
            </PCont1_1Text>
          </PCont1_1>
        </PCont1>
      )}
      <PCont1_2>
        {user?.lastCardDigits && (
          <ColorButton
            style={{
              cursor: "pointer",
              height: "48px",
            }}
            background="#F1F3EC"
            title="Remove"
            buttonTextStyle={{
              color: "#1A1B24",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "150%",
            }}
            hoverBackgroundColor="#F1F3EC"
            activeBgColor={"#F1F3EC"}
            activeBorderColor={"#007bf7"}
            image={DeleteIcon}
            innerStyle={{
              border: mouseState?.delete
                ? "1px solid #1a1b24"
                : "1px solid #DAE1DD",
              borderRadius: "12px",
              flexDirection: "row-reverse",
            }}
            onClick={handleDelete}
            onMouseLeave={() => setMouseState({ ...mouseState, delete: false })}
            onMouseEnter={() => setMouseState({ ...mouseState, delete: true })}
          />
        )}
        <ColorButton
          style={{
            cursor: "pointer",
            height: "48px",
          }}
          background="#F1F3EC"
          title={`${user?.lastCardDigits ? "Change" : "Add"} payment method`}
          buttonTextStyle={{
            color: "#1A1B24",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "150%",
          }}
          activeBgColor={"#F1F3EC"}
          activeBorderColor={"#007bf7"}
          innerStyle={{
            border: mouseState?.add ? "1px solid #1a1b24" : "1px solid #DAE1DD",

            borderRadius: "12px",
            flexDirection: "row-reverse",
          }}
          hoverBackgroundColor="#F1F3EC"
          image={ChangeIcon}
          onMouseLeave={() => setMouseState({ ...mouseState, add: false })}
          onMouseEnter={() => setMouseState({ ...mouseState, add: true })}
          onClick={() => navigate("/update_pay")}
          // onClick={handleClick}
        />
      </PCont1_2>
    </PContainer>
  );
}
