import { Box, Checkbox, FormControlLabel } from "@mui/material";
import {
  DisclaimerText,
  LeftTopSubTitle,
  RequestDisclaimer,
  RequestText,
  StepTitle,
} from "../ReviewComp/styled";
import ColorButton from "../../ColorButton";
import Disclaimer from "../../../assets/icons/Disclaimer.svg";
import Plus from "../../../assets/icons/Plus.svg";
import Trash from "../../../assets/icons/Trash.svg";

export default ({
  newStep,
  step,
  isMobile,
  requestMessageText,
  questions,
  openModalQuestion,
  setRequestMessageText,
  isCheckedMessage,
  handleCheckboxChangeMessage,
  deleteQuestion,
}) => {
  return (
    <>
      {(newStep === 3 || step === 3) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            position: "relative",
            marginBottom: "102px",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "100%",
              // display: "flex",

              // alignItems: "center",
              position: "relative",
            }}
          >
            <StepTitle style={{ fontSize: "24px" }}>
              Enter request details
            </StepTitle>

            <LeftTopSubTitle style={{ marginBottom: "32px" }}>
              {" "}
              Specify what you expect from the receiver.
            </LeftTopSubTitle>
            <RequestText>Add Request Message</RequestText>
            <div
              style={{
                height: "157px",
                background: "white",

                verticalAlign: "top",
                width: "100%",

                border: "1px solid #D1D3E4",
                borderRadius: "14px",
              }}
            >
              <textarea
                style={{
                  height: "100%",
                  background: "white",
                  color: "#5D6272",
                  leadingTrim: "both",

                  textEdge: "cap",
                  fontFamily: " Dm Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%" /* 21px */,
                  letterSpacing: "0.14px",
                  verticalAlign: "top",
                  width: "100%",
                  padding: "24px",
                  border: "none",
                  borderRadius: "14px",
                }}
                rows="4"
                cols="50"
                value={requestMessageText}
                onChange={(e) => setRequestMessageText(e.target.value)}

                // value={formstate["requestMessage"]}
                // borderColor={errorstate["requestMessage"] && "#D93047"}
                // onChange={(e) =>
                //   handleChange(e.target.value, "requestMessage")
                // }
              />
            </div>
            <LeftTopSubTitle
              isMobile={isMobile}
              style={{ marginBottom: isMobile ? "30px" : "1rem" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedMessage}
                    onChange={handleCheckboxChangeMessage}
                    name="testimonialOwnership"
                  />
                }
                sx={{ marginRight: "0px" }}
              />
              <div
                style={{
                  marginBottom: "2px",
                }}
              >
                Save Message for next time
              </div>
            </LeftTopSubTitle>
            <div style={{ color: "black" }}>
              {questions.length === 0 ? "" : <h3>Questions:</h3>}
              <RequestDisclaimer>
                <img style={{}} src={Disclaimer} alt="Disclaimer" />
                <DisclaimerText>
                  Adding question will help you get reviews that are in-line
                  with your objectives for the testimonial
                </DisclaimerText>
              </RequestDisclaimer>
              <ul
                style={{
                  paddingLeft: "0px",
                  marginTop: "16px",
                }}
              >
                {questions.map((question) => (
                  <li
                    key={question.no}
                    style={{
                      marginBottom: "10px",
                      listStyleType: "none",
                      display: "flex",
                      width: "100%",
                      height: "54px",
                      padding: "12px 12px 12px 16px",
                      gap: "15px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      background: "#F1F3EC",
                      borderRadius: "14px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",

                        gap: "10px",
                      }}
                    >
                      {" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Q{question.no}{" "}
                      </span>
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          color: "#5D6272",
                          fontWeight: "400",
                          marginBottom: "0px",
                        }}
                      >
                        {question.text}
                      </p>
                    </div>

                    <div
                      style={{
                        borderLeft: "1px solid #AEB0B8",
                        paddingLeft: "6px",
                      }}
                    >
                      <img
                        src={Trash}
                        alt="trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteQuestion(question.no)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ColorButton
              title={"Add Questions"}
              background={"#F1F3EC"}
              greyBg={"greyBg"}
              width={"200px"}
              image={Plus}
              order={true}
              onClick={openModalQuestion}
              buttonTextStyle={{ fontSize: "14px" }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
