import ColorButton from "../ColorButton";
import LoadingComp from "../Send-Testimonial/Loading";
import { Step2SubDiv } from "../Send-Testimonial/ReviewComp/styled";

export default ({
  setStep,
  setNewStep,
  setTrail,
  step,
  loading,
  recordingData,
  handleOpenModal,
  VideoNext,
  newStep,
  formstate,
  videoData,
}) => {
  let disabled =
    (step === 3 && (!formstate.name || String(formstate.name).trim() === "")) ||
    (step === 2 && videoData.length === 0);
  return (
    <Step2SubDiv
      style={{
        marginBottom: "5rem",
        marginTop: "80px",
      }}
    >
      <ColorButton
        title={"Back"}
        background={"#F1F3EC"}
        width={"48%"}
        greyBg={"greyBg"}
        buttonTextStyle={{ fontSize: "18px", fontWeight: "bold" }}
        onClick={() => {
          setStep(step - 1);
          setNewStep(newStep - 1);
          setTrail(recordingData);
        }}
      />
      <ColorButton
        pointerEvents="auto"
        disabled={disabled}
        // pointerEvents={
        //   recordingData.videoUrl ? "auto" : "none"
        // }
        buttonTextStyle={{ fontSize: "18px", fontWeight: "bold" }}
        title={
          step === 3 && loading ? (
            <LoadingComp loading={loading} />
          ) : step === 3 && !loading ? (
            "Submit"
          ) : (
            "Preview"
          )
        }
        background={"#1A1B24"}
        width={"48%"}
        // onClick={() => uploadVideo()}
        onClick={step === 3 ? handleOpenModal : VideoNext}
        // onClick={step=== 3 ? uploadVideo:VideoNext}
      />
    </Step2SubDiv>
  );
};
