import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  AnalyticsBoxHeader,
  AnalyticsBoxSummmary,
  AnalyticsContainerTwo,
  AnanlyticsBoxDiagram,
  AnanlyticsBoxNumbers,
  AnanlyticsBoxNumbersText,
  AnanlyticsBoxNumbersTextDate,
  MobileCarouselDiv,
} from "../../pages/UserDashboard/styled";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Info from "../../assets/icons/Info.svg";
import NumberBox from "../NumberBox";
import CustomTooltip from "../CustomTootip";
import { newsletterAnalyticsItems } from "../../utils/pageStore";

function NewsletterSummaryAnalytics({ date, data, loading }) {
  const menu = useSelector((state) => state.menu);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const numSelectedDays = dayjs(date.endDate).diff(date.startDate, "day") + 1;
  const [dateText, setDateText] = useState("From Yesterday");

  //////////
  const getRate = (data, item) => {
    try {
      let num = data?.[item]?.reduce(
        (total, item) => total + parseInt(item.count),
        0
      );
      let den = data?.["delivered"]?.reduce(
        (total, item) => total + parseInt(item.count),
        0
      );
      console.log(num, den, "ratee");
      let res = (num / den) * 100;
      return `(${Math.round(isNaN(res) ? 0 : res, 2)}%)`;
    } catch (e) {
      console.error(e);

      return `(0%)`;
    }
  };

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      // dateText = "From Yesterday";
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);

  return (
    <>
      <AnalyticsContainerTwo sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {Object.keys(newsletterAnalyticsItems).map((item, idx) => (
            <Grid key={item} item xs={6} md={4} lg={2}>
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText={newsletterAnalyticsItems[item].title}
                  bodyText={newsletterAnalyticsItems[item].description}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Impression}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div> */}

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      {newsletterAnalyticsItems[item].title}
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {loading ? (
                      <Box sx={{ color: "grey.500" }}>
                        <CircularProgress color="inherit" />
                      </Box>
                    ) : (
                      <>
                        <NumberBox
                          count={
                            data?.[item]?.reduce(
                              (total, item) => total + parseInt(item.count),
                              0
                            ) ?? 0
                          }
                          duration={4000}
                        />
                        <div style={{ fontSize: "1rem" }}>
                          {["open", "click"].includes(item) &&
                            getRate(data, item)}
                        </div>
                      </>
                    )}
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <Box
                      sx={{
                        width: "70%",
                        height: "2px",
                        bgcolor: newsletterAnalyticsItems[item].color,
                        my: "auto",
                        ml: "auto",
                      }}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Grid>
          ))}
          <Grid item xs={6} md={4} lg={2}>
            <AnalyticsBoxSummmary isMobile={isMobile}>
              <CustomTooltip
                headerText={"Total Mails"}
                bodyText={"total emails sent including test bounce"}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AnalyticsBoxHeader isMobile={isMobile}>
                    Total Mails
                  </AnalyticsBoxHeader>
                  <div
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    <img src={Info} alt="info" />
                  </div>
                </Box>
              </CustomTooltip>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <AnanlyticsBoxNumbersText>
                  {loading ? (
                    <Box sx={{ color: "grey.500" }}>
                      <CircularProgress color="inherit" />
                    </Box>
                  ) : (
                    <>
                      <NumberBox
                        count={
                          (data?.["bounce"]?.reduce(
                            (total, item) => total + parseInt(item.count),
                            0
                          ) ?? 0) +
                          (data?.["systemBounce"]?.reduce(
                            (total, item) => total + parseInt(item.count),
                            0
                          ) ?? 0) +
                          (data?.["delivered"]?.reduce(
                            (total, item) => total + parseInt(item.count),
                            0
                          ) ?? 0)
                        }
                        duration={4000}
                      />
                      <div style={{ fontSize: "1rem" }}>
                        {/* {["open", "click"].includes(item) &&
                            getRate(data, item)} */}
                      </div>
                    </>
                  )}
                </AnanlyticsBoxNumbersText>
                <AnanlyticsBoxDiagram>
                  <Box
                    sx={{
                      width: "70%",
                      height: "2px",
                      bgcolor: "#000",
                      my: "auto",
                      ml: "auto",
                    }}
                  />
                </AnanlyticsBoxDiagram>
              </Box>
              <AnanlyticsBoxNumbers>
                <AnanlyticsBoxNumbersTextDate>
                  {dateText}
                </AnanlyticsBoxNumbersTextDate>
              </AnanlyticsBoxNumbers>
            </AnalyticsBoxSummmary>
          </Grid>
        </Grid>
      </AnalyticsContainerTwo>
    </>
  );
}

export default NewsletterSummaryAnalytics;
