import styled from "@emotion/styled";
import { Box, Button, ButtonBase, Divider, Typography } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;
`;

export const Text = styled(Box)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #1a1b24;
  margin-bottom: 47px;
`;
