import React, { useState, useEffect } from "react";
import WebsiteCard from "../../components/WebsiteCard";
import CopyIcon from "../../assets/icons/Copy.svg";
import MarkUpIcon from "../../assets/icons/MarkUp.svg";

import {
  CreateButton,
  ModalContainer,
  StyledAuthButton,
  StyledFormLabel,
  TextInputContainer,
  TitleText,
} from "./styled";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_NEW_PAGE } from "../../graphql/mutations/pageBuilder";

import {
  FETCH_MARKETING_PAGES,
  FETCH_CAMPAIGNS,
} from "../../graphql/queries/marketingCampaigns";
import {
  ADD_NEW_CAMPAIGN_PAGE,
  ADD_NEW_MARKETING_PAGE,
} from "../../graphql/mutations/marketingPages";
import { MenuItem } from "@mui/material";
import Table from "../Table";
import DateRange from "../DateRange";
import Report from "./report";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";

const CampaignList = ({ br_id, brandId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createMarketingPage] = useMutation(ADD_NEW_MARKETING_PAGE);
  const [addNewCampaignPage] = useMutation(ADD_NEW_CAMPAIGN_PAGE);

  const {
    data: dataPages,
    loading: loadingDataPages,
    refetch: refetchPages,
  } = useQuery(FETCH_MARKETING_PAGES, {
    variables: {
      br_id,
    },
  });

  const {
    data,
    loading: loadingData,
    refetch,
  } = useQuery(FETCH_CAMPAIGNS, {
    variables: {
      br_id,
    },
  });

  const [open, setOpen] = useState(false);
  const [switchPage, setSwitchPage] = useState(false);
  const [openPage, setOpenPage] = useState(false);
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formstate, setFormstate] = useState({});
  const [pgformstate, setPgFormstate] = useState({});
  // console.log(formstate, "formstate");
  const [list, setList] = useState([]);
  // const list = getPagesFromStorage();
  const handleOpen = () => {
    setOpen(true);
    setFormstate({});
    setPgFormstate({});
  };
  const handleClose = () => setOpen(false);
  const {
    handleSubmit: handle,
    register,

    formState: { errors },
  } = useForm();

  // console.log({ formState }, "camp Values");

  const tableHeadCells = [
    {
      id: "campaignName",
      numeric: false,
      disablePadding: true,
      label: "Campaign",
      width: "20%",
    },
    {
      id: "medium",
      numeric: false,
      disablePadding: true,
      label: "Medium",
      width: "20%",
    },
    {
      id: "source",
      numeric: false,
      disablePadding: true,
      label: "Source",
      width: "20%",
    },
    {
      id: "url",
      numeric: false,
      disablePadding: true,
      label: "Url",
      width: "20%",
    },
  ];

  const getRows = () => {
    let rows = data?.fetchCampaignPages?.campaignUnits ?? [];
    rows = rows.map((row) => {
      let obj = { ...row };
      obj.pageId = row?.page?._id;
      let url = formatLink(obj);
      obj.url = (
        <>
          {url}
          <img
            src={CopyIcon}
            onClick={() => {
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  // console.log("Text copied to clipboard successfully:", textToCopy);
                  // alert("Text copied to clipboard successfully!");
                })
                .catch((err) => {
                  console.error("Unable to copy text to clipboard:", err);
                  // alert("Unable to copy text to clipboard. Please try again.");
                });
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      );
      return obj;
    });
    return rows;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let values = { ...formstate };
      if (
        !formstate.pageId ||
        !formstate.campaignName ||
        !formstate.medium ||
        !formstate.source
      ) {
        enqueueSnackbar(`Page Creation failed...Please fill the empty fields`, {
          variant: "error",
        });
        return;
      }
      console.log(values);
      if (!values instanceof Object) {
        enqueueSnackbar(`Page Creation failed...`, {
          variant: "error",
        });
        return;
      }

      let { data, errors } = await addNewCampaignPage({
        variables: { input: { ...values, br_id } },
      });
      if (!data && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error creating Campaign Page`,
          {
            variant: "error",
          }
        );

        return;
      }
      enqueueSnackbar("Campaign successfully created", {
        variant: "success",
      });
      refetch();
      handleClose();
      // navigate("/page-builder/" + values.slug);
    } catch (err) {
      enqueueSnackbar(`Page Creation failed ${err}`, {
        variant: "error",
      });
    }
  };

  const handleSubmitPage = async (e) => {
    try {
      e.preventDefault();
      if (!pgformstate.link || !pgformstate.title) {
        enqueueSnackbar(`Link/Title not supplied...`, {
          variant: "error",
        });
        return;
      }
      let { data, errors } = await createMarketingPage({
        variables: { input: { ...pgformstate, br_id } },
      });
      if (!data && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error creating Marketing Page`,
          {
            variant: "error",
          }
        );

        return;
      }
      enqueueSnackbar("Page created successfully", {
        variant: "success",
      });

      data?.addNewMarketingPage?.pages instanceof Array &&
        setPages(data?.addNewMarketingPage?.pages);

      setOpenPage(false);
    } catch (err) {
      enqueueSnackbar(`Page Creation failed ${err}`, {
        variant: "error",
      });
    }
  };

  const formatLink = (myState = formstate) => {
    let link = pages.find((page) => page._id === myState.pageId)?.link;
    let obj = {
      utm_campaign: myState.campaignName,
      utm_medium: myState.medium,
      utm_source: myState.source,
    };
    // console.log(formstate);

    if (link) {
      let arr = [];
      for (let el in obj) {
        if (obj[el]) arr.push(`${el}=${encodeURIComponent(obj[el])}`);
      }
      let url = `${link}?${arr.join("&")}`;
      return `${process.env.REACT_APP_GOOGLE_CAMPAIGN_URL}${url}`;
    }
    // return `${formstate.link}?${}`
  };

  useEffect(() => {
    if (data && data?.fetchCampaignPages?.campaignUnits instanceof Array) {
      setList(data?.fetchCampaignPages?.campaignUnits);
    }
  }, [data]);

  useEffect(() => {
    if (dataPages && dataPages?.fetchPages?.pages instanceof Array) {
      setPages(dataPages?.fetchPages?.pages);
    }
  }, [dataPages]);

  return switchPage ? (
    <Report setSwitchPage={setSwitchPage} />
  ) : (
    <Box sx={{ minHeight: "400px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between",alignItems: "center",borderBottom: "2px solid #ECECF2", mb:3 }}>
        <Box sx={{ display: "flex",   }}>
        <TitleText
          onClick={() => setSwitchPage(true)}
          sx={{ my: 3, cursor: "pointer",p:2,mb:"-2px" }}
        >
          Campaign Report
        </TitleText>        
        <TitleText sx={{ my: 3,borderBottom: "2px solid #000000",p:2,mb:"-2px" }}>Campaign List</TitleText>
        </Box>



        <CreateButton hasBorder onClick={handleOpen} startIcon={<AddIcon />}>
          Create New Campaign
        </CreateButton>
      </Box>

      {loadingData ? (
        "Loading ..."
      ) : list.length ? (
        <Table
          headCells={tableHeadCells}
          // rowClick={rowClick}
          rows={getRows()}
          stylehead
        />
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            align="center"
            id="modal-modal-title"
            variant="h5"
          >
            You have Not Created Any Campaign Yet
          </Typography>
          <CreateButton hasBorder onClick={handleOpen} startIcon={<AddIcon />}>
            Create Campaign
          </CreateButton>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={RemoveIcon}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            Create New {openPage ? "Marketing Page" : "Campaign"}
          </Typography>
          {openPage && (
            <Typography
              sx={{ fontWeight: 400, cursor: "pointer", color: "#007bf7" }}
              id="modal-modal-title"
              variant="h6"
              onClick={() => setOpenPage(false)}
            >
              Back
            </Typography>
          )}
          {openPage ? (
            <Box>
              <TextInputContainer component="form" sx={{ mt: 1 }}>
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Title*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    key="titleX"
                    id="title"
                    label=""
                    placeholder="Enter Title"
                    name="title"
                    autoComplete="name"
                    autoFocus
                    onChange={(e) =>
                      setPgFormstate({
                        ...pgformstate,
                        title: e.target.value,
                      })
                    }
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                  />
                </TextInputContainer>

                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Link*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="link"
                    key="linkX"
                    label=""
                    placeholder="Enter link"
                    name="link"
                    autoComplete="name"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    onChange={(e) =>
                      setPgFormstate({ ...pgformstate, link: e.target.value })
                    }
                  />
                </TextInputContainer>

                <StyledAuthButton
                  type="submit"
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSubmitPage}
                >
                  {loading ? "Loading..." : "   Create Marketing Page"}
                </StyledAuthButton>
              </TextInputContainer>
            </Box>
          ) : (
            <Box>
              <TextInputContainer
                component="form"
                noValidate
                // onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1 }}
              >
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Campaign Name*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="campaignName"
                    label=""
                    placeholder="Enter Name"
                    name="campaignName"
                    autoComplete="name"
                    autoFocus
                    value={formstate?.campaignName ?? ""}
                    onChange={(e) =>
                      setFormstate({
                        ...formstate,
                        campaignName: e.target.value,
                      })
                    }
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                  />
                </TextInputContainer>
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Medium*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="medium"
                    label=""
                    placeholder="Enter Medium"
                    name="medium"
                    autoComplete="name"
                    autoFocus
                    value={formstate?.medium ?? ""}
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    onChange={(e) =>
                      setFormstate({ ...formstate, medium: e.target.value })
                    }
                  />
                </TextInputContainer>
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Source*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="source"
                    label=""
                    placeholder="Enter Source"
                    name="source"
                    autoComplete="name"
                    autoFocus
                    value={formstate?.source ?? ""}
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    onChange={(e) =>
                      setFormstate({ ...formstate, source: e.target.value })
                    }
                  />
                </TextInputContainer>
                {/* Name Input */}
                <TextInputContainer>
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <StyledFormLabel sx={{ marginTop: 0.65 }}>
                      Page*
                    </StyledFormLabel>
                    <StyledFormLabel
                      style={{
                        color: "#007bf7",
                        fontStyle: "italic",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenPage(true)}
                    >
                      Add new page
                    </StyledFormLabel>
                  </Box>
                  <TextField
                    margin="normal"
                    select
                    required
                    fullWidth
                    id="pageId"
                    label=""
                    placeholder="Enter page"
                    name="pageId"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    value={formstate?.pageId ?? ""}
                    onChange={(e) =>
                      setFormstate({ ...formstate, pageId: e.target.value })
                    }
                  >
                    {pages.map((page, idx) => (
                      <MenuItem value={page?._id} key={`P${idx}`}>
                        {page?.title} --- {page?.link}
                      </MenuItem>
                    ))}
                  </TextField>
                </TextInputContainer>
                <Box
                  style={{
                    borderWidth: "1px !important",
                    boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                    borderColor: "#1a1b24 !important",
                    borderRadius: "15px",
                    padding: "10px",
                  }}
                >
                  {formatLink()}
                </Box>
                {/* Sign Up Button */}
                <StyledAuthButton
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? "Loading..." : "   Create Campaign"}
                </StyledAuthButton>
              </TextInputContainer>
            </Box>
          )}
        </ModalContainer>
      </Modal>
    </Box>
  );
};

export default CampaignList;
