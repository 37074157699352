import styled from "styled-components";

export const VideoFrame = styled.div`{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
    padding 30px 60px 0px
}`

export const VideoSubFrame = styled.div`{
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
}`

export const BackImage = styled.img`{
    height: 24px;
    position: relative;
    width: 24px;
}`

export const NavWrapper = styled.div`{
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}`

export const NavTextWrapper = styled.div`{
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    height: 19px;
    padding: 0px 0px 2px;
    position: relative;
}`
export const HomeNav = styled.p`{
    color: rgba(26, 27, 36, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.5px;
    position: relative;
    width: fit-content;
    margin-bottom: 0px;
}`

export const BackHomeNavWrapper = styled.div`{
    height: 16px;
    position: relative;
    transform: rotate(90deg);
    width: 16px;
}`

export const NavDown = styled.img`{
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-90deg);
    width: 16px;
}`

export const SmallTextWrap = styled.div`{
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    height: 19px;
    padding: 0px 0px 2px;
    position: relative
}`
export const VideosSmallText = styled.div`{
    color: rgba(133, 137, 149, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.5px;
    position: relative;
    width: fit-content;
}`

export const VideosWrapper = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    padding: 0px 2px;
    position: relative;
    width: 100 %;
}`

export const VideosHeadingSubWrapper = styled.div`{
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
}`

export const VideosHeading = styled.h2`{
    color: #000000;
    font-family: "DM Sans", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}`
export const VideosSubHeading = styled.div`{
    color: #171517;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}`
export const SearchComponentWrapper = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
}`

export const SearchSubWrapper = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
}`

export const SearchWrap = styled.div`{
    align-items: flex-start;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 54px;
    overflow: hidden;
    position: relative;
    width: 751px;
}`

export const SearchHighlight = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    border-radius: 7px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    padding: 1px 0px;
    position: relative;
    width: 100%;
}`

export const SearchTab = styled.div`{
    align-items: center;
    align-self: stretch;
    background-color: #f1f3ec;
    border-radius: 15px;
    display: flex;
    gap: 10px;
    height: 54px;
    margin-bottom: -2px;
    padding: 15.5px 12px;
    position: relative;
    width: 100%;
}`

//        .frame.search - loupe {
//     height: 24px!important;
//     margin - bottom: -0.5px!important;
//     margin - top: -0.5px!important;
//     position: relative!important;
//     width: 24px!important;
// }
export const SearchText = styled.div`{
    color: rgba(64, 64, 64, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}`

export const SearchwrapTwo = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    border-radius: 7px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    overflow: hidden;
    position: relative;
   }`

  export const SearchTabWrapper = styled.div`{
    align-items: flex-start;
    border-radius: 7px;
    display: inline-flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
    position: relative;
   }`

  export const SearchTabTwo = styled.div`{
    align-items: center;
    background-color: #f1f3ec;
    border-radius: 15px;
    display: inline-flex;
    gap: 16px;
    height: 54px;
    padding: 10.5px 16px;
    position: relative;
   }`

   export const SortWrapper = styled.div`{
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
   }`

   export const SortText = styled.p`{
    color: rgba(26, 27, 36, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    margin-bottom: 0px;
   }`

   export const DateCreated = styled.p`{
    color: rgba(93, 98, 114, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    margin-bottom: 0px;
   }`

//   export const  {
//     height: 16px !important;
//     position: relative !important;
//     width: 16px !important;
//    }
export const FilterWrapper = styled.div`{
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
   }`

  export const FilterIcon = styled.div`{
    align-items: center;
    background-color: #dae1dd;
    border-radius: 16px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    padding: 15px;
    position: relative;
   }`

   export const  SecondFilterIcon = styled.div`{
    align-items: flex-start;
    background-color: #f1f3ec;
    border-radius: 16px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    padding: 15px;
    position: relative;
   }`

   export const VideoCard = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
   }`

   export const VideoSubCard = styled.div`{
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
   }`

//    .frame .video-card-instance {
//     align-self: stretch !important;
//     background-image: url(./rev-video.png) !important;
//     width: 100% !important;
//    }
//    .frame .design-component-instance-node {
//     height: 34px !important;
//     left: 10px !important;
//     top: 10px !important;
//     width: 34px !important;
//    }