import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const HeaderContainer = styled(Box)`
  width: 100%;
  margin-top: 1rem;
`;

export const FlexHeadContainer = styled(Box)`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem auto;
`;

export const LeftHeaderText = styled(Box)`
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  color: #5d6272;
  align-items: center;
`;

export const LeftText = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
`;

export const Item = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  cursor: pointer;
`;

export const LastItem = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
`;
