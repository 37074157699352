import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { GET_USER_ORGANIZATION } from "../../graphql/queries/organizationQueries";
import CompanyDetails from "../../components/companyDetails";
import UserInvitationsAndManagement from "../../components/UserInvitationsAndManagement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [age, setAge] = React.useState("");
  const [selectedOrganization, setSeletedOrganization] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const { data, loading } = useQuery(GET_USER_ORGANIZATION);

  React.useEffect(() => {
    if (data?.userOrganizations) {
      setCompanies(data?.userOrganizations);
    }
  }, [data?.userOrganizations]);

  //   console.log(companies, "companies");
  //   console.log(selectedOrganization, "selectedOrganization");
  const handleChangeAge = (event) => {
    setAge(event.target.value);
    if (companies?.length) {
      setSeletedOrganization(
        companies?.filter(
          ({ organization }) => organization?._id === event.target.value
        )
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
              <InputLabel id="demo-simple-select-label">Org.</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChangeAge}
              >
                {companies?.map(({ organization }) => (
                  <MenuItem value={organization?._id} key={organization?._id}>
                    {organization?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {loading && <Typography>Loading...</Typography>}
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" endIcon={<AddCircle />}>
              New Organization
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Company Information" {...a11yProps(0)} />
            <Tab label="Brands" {...a11yProps(1)} />
            <Tab label="User Management" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CompanyDetails organization={selectedOrganization[0]} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Brands
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserInvitationsAndManagement
            organization={selectedOrganization[0]}
          />
        </TabPanel>
      </Box>
    </>
  );
}
