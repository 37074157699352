import React, { useEffect, useState } from "react";
import {
  CreateButton,
  DeleteModalContainer,
  ModalContainer,
  ModalContainerCreate,
  StyledFormLabel,
  StyledSectionButton,
  StyledSubmitButton,
  TextInputContainer,
} from "./styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Modal from "@mui/material/Modal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Box, Typography, Stack, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { UPDATE_PAGE } from "../../graphql/mutations/pageBuilder";
import { enqueueSnackbar } from "notistack";
import { nanoid } from "nanoid";

import { useMutation } from "@apollo/client";
const tableHead = ["Name", "Description", "Price"];

const ProductModal = ({
  editor,
  details,
  open,
  productList,
  setProductList,
  handleClose,
  productRef,
}) => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState("create");
  const [currentProduct, setCurrentProduct] = useState();
  const [updatePage] = useMutation(UPDATE_PAGE);
  if (!productList instanceof Array) {
    productList = [];
  }
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm();

  //handle create OR update
  const onSubmit = async (data) => {
    let updatedList;
    try {
      if (modalState === "create") {
        updatedList = [
          ...(productList?.length ? productList : []),
          { ...data, price: Number(data.price), id: nanoid() },
        ].map(({ id, name, price, description }) => ({
          id,
          name,
          price,
          description,
        }));
      } else {
        updatedList =
          productList instanceof Array &&
          productList
            .map((prod) =>
              prod.id === currentProduct.id
                ? { ...prod, ...data, price: Number(data.price) }
                : prod
            )
            .map(({ id, name, price, description }) => ({
              id,
              name,
              price,
              description,
            }));
      }
      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input: {
            _id: details?.getPageBySlug?._id,
            products: updatedList,
          },
        },
      });
      // console.log("data updated", dataUpdate);
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || modalState === "create"
            ? `Error creating product`
            : "error updating product",
          {
            variant: "error",
          }
        );

        return;
      }
      setOpenCreate(false);
      reset({
        name: "",
        price: "",
        description: "",
      });
      setProductList(
        dataUpdate?.updatePage?.products.map((prod) => ({
          ...prod,
          select: false,
        }))
      );

      productRef.current =
        dataUpdate?.updatePage?.products instanceof Array &&
        dataUpdate?.updatePage?.products.map((prod) => ({
          id: prod.id,
          label: prod.name,
        }));

      enqueueSnackbar(`Updating Page successful`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(
        modalState === "create"
          ? `Creating product failed ${e}`
          : `Update product failed ${e}`,
        {
          variant: "error",
        }
      );
    }
  };

  //fetch attached products
  const getAttachedProductsIds = (htmlString) => {
    const pattern = /data-product="([\w-]+)"/g; // Updated regular expression pattern to match product IDs with alphanumeric characters and dashes
    const matches = [];
    // Using matchAll to find all matches
    const matchIterator = htmlString?.matchAll(pattern);
    if (!matchIterator) {
      return [];
    }
    // Iterating through matches
    for (const match of matchIterator) {
      matches.push(match[1]); // Add the matched product ID to the array
    }
    return matches;
  };
  // console.log("this is attached", getAttachedProductsIds(editor?.getHtml()));
  //delete selected
  const handleDelete = async () => {
    let updatedList = productList
      .filter(({ select }) => !select)
      .map(({ id, name, price, description }) => ({
        id,
        name,
        price,
        description,
      }));
    setLoading(true);
    try {
      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input: {
            _id: details?.getPageBySlug?._id,
            products: updatedList,
          },
        },
      });
      // console.log("data updated", dataUpdate);

      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error deleting product(s)`,
          {
            variant: "error",
          }
        );

        return;
      }
      setProductList(
        dataUpdate?.updatePage?.products.map((prod) => ({
          ...prod,
          select: false,
        }))
      );
      setOpenDelete(false);
      enqueueSnackbar(`product(s) delete successfully`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`Update product failed ${e}`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleItemSelect = (index) => (e) => {
    const checked = e.target.checked;
    let prodList =
      productList instanceof Array
        ? [...productList].map((prod, idx) => {
            if (!prod instanceof Object) return prod;
            return {
              ...prod,
              select: idx === index ? checked : prod.select,
            };
          })
        : productList;
    setProductList(prodList);
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    let prodList =
      productList instanceof Array
        ? [...productList].map((prod) => {
            if (!prod instanceof Object) return prod;
            return {
              ...prod,
              select: getAttachedProductsIds(editor?.getHtml()).includes(
                prod.id
              )
                ? false
                : checked,
            };
          })
        : productList;

    setProductList(prodList);
  };

  useEffect(() => {
    if (open) {
      let prodList =
        productList instanceof Array
          ? [...productList].map((prod) => {
              if (!prod instanceof Object) return prod;
              return {
                ...prod,
                select: false,
              };
            })
          : productList;
      setProductList(prodList);
    }
  }, [open]);

  const toggleCreate = () => setOpenCreate((x) => !x);
  const toggleDelete = () => setOpenDelete((x) => !x);
  const cellColor = (id) =>
    getAttachedProductsIds(editor?.getHtml()).includes(id)
      ? { color: "#8e8c8cde" }
      : {};
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Typography
            sx={{ fontWeight: 700, fontFamily: "Switzer", mt: 2 }}
            id="modal-modal-title"
            variant="h5"
          >
            Product List
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              py: 1.5,
              borderTop: "1px solid #E5E3E3",
              borderBottom: "1px solid #E5E3E3",
            }}
          >
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row">
              <StyledSectionButton
                disabled={
                  productList instanceof Array &&
                  !productList?.some(({ select }) => select)
                }
                onClick={toggleDelete}
                startIcon={<DeleteOutlineIcon />}
              >
                Delete
              </StyledSectionButton>
            </Stack>
            <Box>
              <CreateButton
                color="primary"
                onClick={() => {
                  setModalState("create");
                  setOpenCreate(true);
                  reset({
                    name: "",
                    price: "",
                    description: "",
                  });
                }}
              >
                create Product
              </CreateButton>
            </Box>
          </Box>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              sx={{ minWidth: 500 }}
              stickyHeader
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {" "}
                    <Checkbox
                      disabled={
                        getAttachedProductsIds(editor?.getHtml()).length
                      }
                      onChange={handleSelectAll}
                      inputProps={{ "aria-label": "Checkbox demo" }}
                    />
                  </TableCell>
                  {tableHead.map((title) => (
                    <TableCell
                      sx={{ fontWeight: 700, fontFamily: "Switzer" }}
                      key={title}
                    >
                      {title}
                    </TableCell>
                  ))}
                  <TableCell sx={{ fontWeight: 700, fontFamily: "Switzer" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList instanceof Array &&
                  productList?.map((row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Checkbox
                          disabled={getAttachedProductsIds(
                            editor?.getHtml()
                          ).includes(row.id)}
                          onChange={handleItemSelect(index)}
                          checked={row.select}
                          inputProps={{ "aria-label": "Checkbox demo" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Switzer", ...cellColor(row.id) }}
                      >
                        {" "}
                        {row.name}
                      </TableCell>
                      <TableCell
                        sx={{ fontFamily: "Switzer", ...cellColor(row.id) }}
                      >
                        {row.description}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontFamily: "Switzer", ...cellColor(row.id) }}
                      >
                        ${row.price}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => {
                            setModalState("update");
                            setCurrentProduct(row);
                            setOpenCreate(true);
                            reset(row);
                          }}
                        >
                          <BorderColorIcon />
                        </IconButton>{" "}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalContainer>
      </Modal>
      <>
        <Modal
          open={openCreate}
          onClose={toggleCreate}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainerCreate>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Switzer" }}
              id="modal-modal-title"
              variant="h5"
            >
              {modalState === "create"
                ? "Create New Product"
                : "Update Product"}
            </Typography>
            <Box>
              <TextInputContainer
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1 }}
              >
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Name*
                  </StyledFormLabel>
                  <TextField
                    error={errors?.["name"]?.message}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label=""
                    placeholder="Enter Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "8px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    helperText={errors?.["name"]?.message}
                    {...register("name", { required: "name is required" })}
                  />
                </TextInputContainer>
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Price*
                  </StyledFormLabel>
                  <TextField
                    error={errors?.["price"]?.message}
                    margin="normal"
                    required
                    fullWidth
                    id="price"
                    label=""
                    type="number"
                    placeholder="Enter price"
                    name="price"
                    autoComplete="price"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "8px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    helperText={errors?.["price"]?.message}
                    {...register("price", {
                      required: "price is required",
                      min: {
                        value: 0,
                        valueAsNumber: true,
                        message: "price cannot be negative",
                      },
                    })}
                  />
                </TextInputContainer>

                {/* Password Input */}
                <TextInputContainer>
                  <StyledFormLabel>Description*</StyledFormLabel>
                  <TextField
                    error={errors?.["description"]?.message}
                    margin="normal"
                    required
                    fullWidth
                    name="description"
                    label=""
                    placeholder="Enter custom header"
                    type="text"
                    id="description"
                    multiline
                    rows={4}
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "8px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    helperText={errors?.["description"]?.message}
                    {...register("description", {
                      required: "description is required",
                      minLength: {
                        value: 5,
                        message: "text is too short", // JS only: <p>error message</p> TS only support string
                      },
                    })}
                  />
                </TextInputContainer>

                {/* submit Button */}
                <StyledSubmitButton
                  type="submit"
                  //   disabled={loading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {isSubmitting
                    ? "loading..."
                    : modalState === "create"
                    ? "Create product"
                    : "Edit Product"}
                </StyledSubmitButton>
              </TextInputContainer>
            </Box>
          </ModalContainerCreate>
        </Modal>
      </>
      <>
        <Modal
          open={openDelete}
          onClose={toggleDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DeleteModalContainer>
            <Box sx={{ py: 1, borderBottom: "1px solid #E5E3E3" }}>
              <Typography
                sx={{ fontWeight: 700, fontFamily: "Switzer", px: "8px" }}
                id="modal-modal-title"
                variant="h6"
              >
                Delete Selected Items
              </Typography>
            </Box>

            <Box sx={{ p: "8px", py: 3 }}>
              <Typography sx={{ fontFamily: "Switzer" }}>
                {" "}
                Are you sure you want to delete{" "}
                {productList instanceof Array &&
                  productList?.filter(({ select }) => select)?.length}{" "}
                item(s)?{" "}
              </Typography>
            </Box>
            <Stack
              sx={{ px: "8px", py: 2 }}
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
            >
              <StyledSectionButton onClick={toggleDelete}>
                Cancel
              </StyledSectionButton>
              <Button
                color="error"
                variant="contained"
                disabled={loading}
                sx={{
                  borderRadius: "15px",
                  fontFamily: "Switzer",
                  fontSize: "14px",
                  textTransform: "none",
                  px: 2,
                }}
                onClick={handleDelete}
              >
                {loading ? "loading..." : "Delete"}
              </Button>
            </Stack>
          </DeleteModalContainer>
        </Modal>
      </>
    </>
  );
};

export default ProductModal;
