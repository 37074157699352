import React, { Component } from "react";

/**
 * A higher-order component that captures errors and displays a fallback UI.
 * @component
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Static method that gets called when an error is thrown in a child component.
   * @param {Error} error - The error that was thrown.
   * @returns {Object} - An object with the updated state to show the fallback UI.
   */
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * Method that logs the error and error info.
   * @param {Error} error - The error that was caught.
   * @param {Object} errorInfo - Additional information about the error.
   */
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Check if the error is related to the user's subscription status
      if (this.props.user && this.props.user.subscriptionStatus !== "active" && this.props.user.subscriptionStatus === "past-due" && this.props.user.verifyStatus !== true) {
        return <h1>You do not have an active subscription.</h1>;
      }

      // Display a generic fallback UI for all other errors
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
