import React, { useState } from "react";
import {
  StyledColor,
  StyledCover,
  StyledPopover,
  StyledSwatch,
  StyledTextField,
} from "./styled";
import { SketchPicker } from "react-color";

/**
 * ColorSwatches component represents a set of color swatches with a color picker.
 * @param {Object} props - The component's props.
 * @param {string} props.color - The current selected color value.
 * @param {function} props.setColor - The function to set the selected color.
 * @returns {JSX.Element} - Rendered ColorSwatches component.
 */
const ColorSwatches = ({ color, setColor }) => {
  const [state, setState] = useState({
    displayColorPicker: false,
  });

  /**
   * Handle click event to toggle the color picker display.
   */
  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  /**
   * Handle close event to hide the color picker.
   */
  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  /**
   * Handle color change event.
   * @param {string} color - The selected color in hexadecimal format.
   */
  const handleChange = (color) => {
    setColor(color.hex);
  };

  return (
    <div>
      <StyledSwatch>
        <StyledColor selectedcolor={color} onClick={() => handleClick()} />
        <StyledTextField
          value={color}
          onChange={(e) => setColor(e.target.value)}
          variant="standard"
        />
      </StyledSwatch>
      {state.displayColorPicker ? (
        <StyledPopover>
          <StyledCover onClick={() => handleClose()} />
          <SketchPicker color={color} onChange={handleChange} />
        </StyledPopover>
      ) : null}
    </div>
  );
};

export default ColorSwatches;
