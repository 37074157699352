import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Avatar, Grid, Typography } from "@mui/material";
import { StyledButtonContainer } from "./styled";
import { useMutation } from "@apollo/client";
import { ACCEPT_INVITATION } from "../../graphql/mutations/invitation";
import moment from "moment";
import { useSnackbar } from "notistack";
import ColorButton from "../ColorButton";

/**
 * InvitationsTable is a component for displaying a list of pending invitations.
 * @component
 * @param {Array} invitations - An array of invitation objects.
 * @returns {JSX.Element}
 */
export default function InvitationsTable({ invitations }) {
  const { enqueueSnackbar } = useSnackbar();
  const [pendingInvitation, setPendingInvitation] = React.useState([]);
  const [acceptInvitation, { data, loading }] = useMutation(ACCEPT_INVITATION);

  // Display a snackbar with a specific message and variant.
  const handleClickVariant = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  // Handle accepting an invitation.
  const handleAcceptInvitation = (invitationCode) => {
    acceptInvitation({
      variables: { input: { invitationCode } },
    });
  };

  React.useEffect(() => {
    if (invitations?.length) {
      setPendingInvitation(invitations);
    }
  }, [invitations]);

  React.useEffect(() => {
    const handleClickVariant = (variant, message) => {
      enqueueSnackbar(message, { variant });
    };

    if (data?.acceptInvitation) {
      const otherInvitations = pendingInvitation?.filter(
        (invitation) => invitation?._id !== data?.acceptInvitation?._id
      );
      setPendingInvitation(otherInvitations);
      handleClickVariant("success", "Invitation accepted!");
      // Redirect the user to the dashboard or perform any necessary action.
    }
  }, [data?.acceptInvitation, enqueueSnackbar, pendingInvitation]);

  return (
    <React.Fragment>
      {pendingInvitation?.map((invitation) => (
  <div key={invitation?._id}>
  <Avatar
                          alt="Avatar image"
                          src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${invitation?.primaryUser?.thumbnail}.jpeg`}
                        />
    <p>{invitation?.primaryUser?.name} has invite you to the following brands(s) {""}
    {invitation?.brands.map((brand, index) => (
      <React.Fragment key={brand?._id}>
        <span>{brand?.brand?.name}</span>
        {index < invitation.brands.length - 1 && <span>&nbsp;&amp;&nbsp;</span>}
      </React.Fragment>
    ))}</p>

          <StyledButtonContainer>
            <ColorButton
              width="134px"
              title="Accept"
              background="#1A1B24"
              onClick={() => handleAcceptInvitation(invitation?.invitationCode)}
            >
              {loading ? "Loading.." : "Accept"}
            </ColorButton>
            <Button variant="outlined">Decline</Button>
          </StyledButtonContainer>
        </div>
      ))}

      {/* 
      Replace the following commented code with your desired table rendering:
      
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Details</TableCell>
                  <TableCell align="center">Sent</TableCell>
                  <TableCell align="center">Brand(s)</TableCell>
                  <TableCell align="center">Expires</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingInvitation?.map((invitation) => (
                  <TableRow
                    key={invitation?._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {invitation?.organization?.name}
                    </TableCell>
                    <TableCell align="center">
                      {invitation?.primaryUser?.name}
                    </TableCell>
                    <TableCell align="center">
                      {invitation?.brands.map((brand, index) => (
                        <React.Fragment key={brand?._id}>
                          {brand?.brand?.name}
                          {index < invitation.brands.length - 1 && <span>&nbsp;&amp;&nbsp;</span>}
                        </React.Fragment>
                      ))}
                    </TableCell>
                    <TableCell align="center">
                      {moment(invitation?.expiredOn).format("LLL")}
                    </TableCell>
                    <TableCell align="center">
                      <StyledButtonContainer>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          onClick={() =>
                            handleAcceptInvitation(invitation?.invitationCode)
                          }
                        >
                          {loading ? "Loading.." : "Accept"}
                        </Button>
                        <Button variant="outlined">Decline</Button>
                      </StyledButtonContainer>
                    </TableCell>
                  </TableRow>
                ))}
                {!pendingInvitation?.length && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={4}
                      align="center"
                    >
                      <Typography>You don't have any invitations.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> 
      */}
    </React.Fragment>
  );
}
