import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const Container = styled(Box)`
  background-color: ${(props) => props?.background ?? "purple"};
  flex-direction: ${(props) => (props?.showRoleName ? "row" : "column")};
  justify-content: ${(props) =>
    props?.showRoleName ? "flex-start" : "center"};
  align-items: ${(props) => (props?.showRoleName ? "flex-start" : "center")};
  padding: 5px 10px;
  display: flex;

  width: ${(props) => (props?.showRoleName ? undefined : "1.3em")};
  height: 1.2em;
  border-radius: 6px;
  margin-right: ${(props) => (props?.showRoleName ? "8px" : "5px")};
  margin-left: ${(props) => (props?.showRoleName ? undefined : "5px")};
  margin-top: ${(props) => (props?.showRoleName ? "2px" : undefined)};
  padding: 4px 8px;

  ${(props) =>
    props?.isNumber &&
    `background: #EEE9FF;
    height:1.1em;
  border: 1px solid #000000;
  border-radius: 10px;`}
  ${(props) =>
    !props?.showRoleName &&
    `border-radius: 10px;width: 32px;
    height: 32px;padding: 5px 10px;`}
`;

// export const ImageContainer = styled(Image)`
//   object-fit: contain;
// `;

export const Text = styled(Typography)`
  font-size: 0.8em;
  color: ${(props) => props?.color ?? "#000"};
  font-family: ${(props) =>
    props?.placeRight ? "Dm Sans" : "Dm Sans"};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  ${(props) =>
    props?.single &&
    `font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #1A1B24;`}
`;
