import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const ProfileBoxContainer = styled(Box)`
  width: ${(props) => (props?.isMobile ? "95%" : "70%")};
  margin: ${(props) => (props?.isMobile ? undefined : "0 auto")};
  padding-left: ${(props) => (props?.isMobile ? "16px" : undefined)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ProfileNameContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
export const ProfileImage = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 100px;
  height: 100px;

  border-radius: 100px;

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const ProfileName = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 154%;

  margin-left: 1.5rem;
  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const InputBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
  isolation: isolate;

  width: ${(props) => (props?.isMobile ? "100%" : "448px")};
  height: 57px;
  margin-left: ${(props) => (props?.isMobile ? "auto" : "1.5rem")};
  /* White Color */

  background: #ffffff;
  /* Primary/French Blue/French Blue 100% */

  border: 1px solid #d4dae1;
  // outline-color: #0075CE;
  //     outline-width: 1px;
  //     outline-style: solid;
  border-radius: 12px;
     &:focus-within {
    border-color: blue;
  }

  &:hover {
    border-color: black;
  }
`;
export const ProfileEmailContainer = styled(Box)`
  width: 100%;
  border-bottom: 1px solid #ececf2;
  padding-bottom: 1rem;

  margin-top: 2.5rem;
`;
export const ProfileEmail = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 50% */

  color: #8c8d91;

  margin-top: 12px;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const ProfileEmailText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-left: 5px;
  /* Dark+Grey Palette/Rasin Black/Rasin Black 75% */
  margin-bottom: 3px;
  color: #53545b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const PasswordChange = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* Primary/French Blue/French Blue 100% */
  cursor: pointer;
  color: #0075ce;
`;
export const HidePasswordBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 10px;
  gap: 10px;

  width: 347px;
  height: 44px;
  margin-top: 12px;
  /* Neutral palette/Seasalt/Seassalt 75% */

  background: #f9f9f9;
  border-radius: 12px;

  /* Inside auto layout */
`;
export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
`;
export const ErrorMessage = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const FormLabel = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
// export const NavContainerContent = styled(Box)`
//   display: flex;
//   width: 95%;
//   height: 100%;
//   margin: 0 auto;
//   background: #f6f6fa;
// `;
