import styled from "@emotion/styled";
import { Button, Typography,Box } from "@mui/material";


export const StyledAuthButton = styled(Button)`
  background: #070b0d;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  // border-radius: 7px;
  border-radius: 15px;
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  height: 46px;
  font-family: Dm Sans !important;
  &:hover {
    background: #1a1b24;
  }
`;

export const TextInputContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
`;

export const StyledFormLabel = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
  }
`;

export const ModalContainer = styled(Box)`
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  padding:1rem;
  transform: translate(-50%, -50%);
`;

export const CreateButton = styled(Button)`
  height: 40px;
  ${({color})=>color==="primary"?"background-color: #0c97d1;":`background-color: white;`}
  border-radius: 5px;
  box-sizing: border-box;
  padding: 9px 24px;
  text-transform: capitalize;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${(props)=>props.hasBorder&&" border: #101010 .125rem solid;"}
  ${({color})=>color==="primary"?"color: white;":color==="dark"?"color: white;":`color: #101010;`}
  &:hover {
    ${({color})=>color==="primary"?"background-color: #0c97d1;":color==="dark"?`background-color: #0c97d1;`:`background-color: white;`}
    ${({color})=>color==="primary"?"color: white;":color==="dark"?"color: white;":`color: #101010;`}
  }
`;