import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";

export const ColorButtonOverall = styled(Box)`

width:79px;
height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:   white;
    // border: 10px solid rgba(255, 255, 255, 0.5) ;
cursor:pointer;
`
export const ColorButtonInner = styled(Box)`

width:70px;
height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:   rgba(252, 0, 63, 1);
  border:2px solid black;
cursor:pointer;
`
export const ColorButtonStop = styled(Box)`

width:20px;
height: 20px;

    border-radius: 3px;
    background-color:   white;
  
cursor:pointer;
`
export const ColorButtonStart = styled(Box)`

width:100%;
height: 100%;

border-radius: 50%;
    background-color:    rgba(252, 0, 63, 1);
  
cursor:pointer;
`

export const ColorDuration = styled(Box)`
height:15px
width:15px;

border-radius:50%;
background:${(props) => props.pointerEvents === 'none' ? "rgba(93, 98, 114, 0.21)":props.background};

display:flex;
justify-content:center;
align-items:center;
font-family: 'Dm Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;

/* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

color: #FFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
gap:5px;
/* Add hover effect when background is #1A1B24 */
  // &:hover {
  //   background: ${(props) => (props.background === "#1A1B24" ? "#A694FF" : props.background)};
  //   // color: ${(props) => (props.background === "#1A1B24" ? "#FFFFFF" : "#1A1B24")};
  //   pointer-events:auto;
  //   cursor: pointer;
  // }

`
export const ColorButtonText = styled(Typography)`
font-family: Dm Sans;
font-size:${(props) => (props.isMobile ? "10px" : "16px")};
font-weight: 600;
line-height: ${(props) => (props.isMobile ? "15px" : "20px")};
letter-spacing: 0em;
// text-align: left;
order:${(props) => props.order? "1":"0"};
color:${(props) => props.pointerEvents === 'none' || props.greyBg==='greyBg'   ? "#1A1B24":"#FFF"};
opacity:${(props) => props.pointerEvents === 'none'? "0.5":"1"};

`

