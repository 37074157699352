import { gql } from "@apollo/client";

export const ADD_NEW_SALESWORKFLOW = gql`
  mutation createNewSalesAppWorkFlow($input: SalesAppWorkFlowInput!) {
    createNewSalesAppWorkFlow(input: $input) {
      _id
    }
  }
`;

export const UPDATE_SALESWORKFLOW = gql`
  mutation updateNewSalesAppWorkFlow($input: SalesAppWorkFlowInput!) {
    updateNewSalesAppWorkFlow(input: $input) {
      _id
    }
  }
`;

export const DELETE_SALESWORKFLOW = gql`
  mutation deleteSalesAppWorkFlow($_id: String!) {
    deleteSalesAppWorkFlow(_id: $_id) {
      _id
    }
  }
`;

export const SEND_TEST_SALES_MAIL = gql`
  mutation sendSalesAppTestEmail(
    $templateID: String!
    $leadString: String!
    $subject: String!
  ) {
    sendSalesAppTestEmail(
      templateID: $templateID
      leadString: $leadString
      subject: $subject
    ) {
      data
    }
  }
`;
