import React, { useMemo } from "react";
import {
  Modal,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  CircularProgress,
  Stack,
  CardContent,
  IconButton,
} from "@mui/material";
import { ModalContainer, StyledAuthButton } from "../CampaignList/styled";
import {
  SubTitle,
  TemplateCard,
  TemplateImage,
  Title,
} from "../../pages/Templates/styled";
import tempreview from "../../pages/Templates/tempreview.jpg";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { enqueueSnackbar, useSnackbar } from "notistack";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import { GET_ALL_TEMPLATES_QUERY } from "../../graphql/mutations/saveTemplateMutation";
import { SEND_TEST_SALES_MAIL } from "../../graphql/mutations/salesApp";

const baseInputStyles = {
  margin: "8px 0px 8px",
  ".MuiInputBase-root": {
    // height: "50px",
    borderRadius: "8px",
  },
  ":hover": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#1a1b24 !important",
      borderWidth: "1px !important",
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#007bf7",
      borderWidth: "1px !important",
      boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
    },
  },
};

const TestMailModal = ({ leadsFieldData, open, setOpen }) => {
  const { data: templateData, loading } = useQuery(GET_ALL_TEMPLATES_QUERY);
  const [sendSalesAppTestEmail] = useMutation(SEND_TEST_SALES_MAIL);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      value: "",
      subject: "",
      salesFields: [
        {
          field: "first_name",
          value: "",
        },
      ],
    },
  });
  const salesFields = watch("salesFields");
  const {
    fields: salesFieldsList,
    append: salesFieldsAppend,
    remove: salesFieldsRemove,
  } = useFieldArray({
    control,
    name: "salesFields",
  });

  const newsletters = useMemo(
    () => templateData?.getAllTemplates ?? [],
    [templateData]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    console.log({ data });
    const leadFormat = data.salesFields.length
      ? data.salesFields.reduce((obj, fieldObj, index, array) => {
          if (index === array.length - 1) {
            return {
              ...obj,
              [leadsFieldData?.[fieldObj.field]?.dbValue]: fieldObj.value,
              [leadsFieldData?.email_address?.dbValue]: data.email,
            };
          } else {
            return {
              ...obj,
              [leadsFieldData?.[fieldObj.field]?.dbValue]: fieldObj.value,
            };
          }
        }, {})
      : { [leadsFieldData?.email_address?.dbValue]: data.email };

    try {
      let input = {
        templateID: data.value,
        leadString: JSON.stringify(leadFormat),
        subject: data.subject,
      };

      let { data: dataUpdate, errors } = await sendSalesAppTestEmail({
        variables: {
          ...input,
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || `Error sending mail`,
          {
            variant: "error",
          }
        );

        return;
      }
      reset();
      handleClose();
      enqueueSnackbar(`sent successfully`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`send sales mail failed ${e}`, {
        variant: "error",
      });
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        style={{
          // width: "800px",
          width: "680px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "90%",
          overflow: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            Test Sales Mail
          </Typography>
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>
        <Stack direction={"row"} spacing={1} sx={{ mb: 2, mt: 3 }}>
          <TextField
            error={errors?.["email"]}
            label="Email"
            type="email"
            size="small"
            required
            sx={{
              width: "55%",
              marginBottom: "10px",
              ...baseInputStyles,
            }}
            {...register("email", {
              required: "from is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            helperText={errors?.["email"]?.message}
          />
          <TextField
            error={errors?.["subject"]}
            label="Subject"
            size="small"
            required
            sx={{
              width: "35%",
              marginBottom: "10px",
              ...baseInputStyles,
            }}
            {...register("subject", { required: true })}
            helperText={errors?.["subject"]?.message}
          />
        </Stack>
        {!salesFieldsList?.length && (
          <Stack direction={"row"} spacing={1} mb={2}>
            <StyledAuthButton
              type="button"
              disableElevation
              onClick={() =>
                salesFieldsAppend({
                  field: "",
                  value: "",
                })
              }
              variant="contained"
              size="small"
              sx={{
                boxShadow: "none !important",
                py: "0 !important",
                borderRadius: "8px !important",
              }}
            >
              Add
            </StyledAuthButton>
          </Stack>
        )}
        {salesFieldsList.map((field, index) => (
          <Stack key={field.id} direction={"row"} spacing={1} mb={2}>
            <Controller
              name={`salesFields.${index}.field`}
              control={control}
              rules={{ required: "Country is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  error={errors?.["salesFields"]?.[index]?.["field"]}
                  required
                  select
                  size="small"
                  label="field"
                  sx={{
                    width: "35%",
                    marginBottom: "10px",
                    ...baseInputStyles,
                  }}
                  helperText={
                    errors?.["salesFields"]?.[index]?.["field"]?.message
                  }
                >
                  <MenuItem sx={{ fontFamily: "Switzer" }} value={""}>
                    {"---"}
                  </MenuItem>
                  {!!field.value && (
                    <MenuItem
                      sx={{ fontFamily: "Switzer" }}
                      value={field.value}
                    >
                      {leadsFieldData?.[field?.value]?.name}
                    </MenuItem>
                  )}
                  {(leadsFieldData?.email_address
                    ? Object.keys(leadsFieldData).reduce((prevValue, key) => {
                        const exemptionFields = [
                          "email_address",
                          ...(salesFields?.map(({ field }) => field) ?? []),
                        ];
                        const leadField = leadsFieldData[key];
                        if (exemptionFields.includes(key)) {
                          return prevValue;
                        } else {
                          return [
                            ...prevValue,
                            {
                              value: key,
                              label: leadField.name,
                            },
                          ];
                        }
                      }, [])
                    : []
                  ).map(({ value, label }) => (
                    <MenuItem
                      key={value}
                      sx={{ fontFamily: "Switzer" }}
                      value={value}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <TextField
              error={errors?.["salesFields"]?.[index]?.["value"]}
              label="value"
              required
              size="small"
              sx={{
                width: "35%",
                marginBottom: "10px",
                ...baseInputStyles,
              }}
              {...register(`salesFields.${index}.value`, {
                required: "value is required",
              })}
              helperText={errors?.["salesFields"]?.[index]?.["value"]?.message}
            />
            <Box>
              <IconButton
                onClick={() => salesFieldsRemove(index)}
                sx={{
                  color: "red",
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Box>

            {index === salesFieldsList.length - 1 && (
              <StyledAuthButton
                disableElevation
                onClick={() =>
                  salesFieldsAppend({
                    field: "",
                    value: "",
                  })
                }
                variant="contained"
                size="small"
                sx={{
                  boxShadow: "none !important",
                  py: "0 !important",
                  borderRadius: "8px !important",
                }}
              >
                Add
              </StyledAuthButton>
            )}
          </Stack>
        ))}
        {loading ? (
          <Box
            sx={{
              paddingBottom: "90px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Controller
              rules={{
                required: "Select a Newsletter",
              }}
              render={({ field: { onChange, value } }) => (
                <Stack
                  direction={"row"}
                  spacing={1}
                  // useFlexGap
                  flexWrap="wrap"
                  style={{
                    border: `1px solid ${!errors?.["value"] ? "grey" : "red"}`,
                    borderRadius: "8px",
                    backgroundColor: `${
                      !errors?.["value"] ? "#fff" : "#feefef"
                    }`,
                    paddingBottom: "90px",
                    overflow: "auto",
                    gap: "20px",
                  }}
                >
                  {newsletters.map((card) => (
                    <TemplateCard
                      key={card.id}
                      onClick={() => onChange(card.id)}
                      style={{
                        cursor: "pointer",
                        margin: "8px",
                        borderWidth: "20px",
                        borderColor: "grey",
                        width: "144px",
                        height: "192px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        backgroundColor:
                          card.id === value ? "#101010" : "#D6DAE0",
                      }}
                    >
                      <TemplateImage
                        src={card.imageData ? card.imageData : tempreview}
                        alt="Template Preview"
                        style={{
                          padding: "10px",
                          width: "100%",
                          height: "100%", // Set the preview image height to 75% of the card height
                          objectFit: "contain", // Ensure the image covers the container
                        }}
                      />

                      <CardContent>
                        <Title
                          sx={{
                            color:
                              card.id === value
                                ? "#ffffff !important"
                                : "#000000",
                          }}
                          style={{
                            marginTop: "-5px",
                            marginBottom: "-1px",
                            fontSize: "1rem",
                            lineHeight: "normal",
                          }}
                        >
                          {card.title}
                        </Title>
                        <SubTitle>{card.description}</SubTitle>
                        {/* <SubTitle style={{ fontSize: "10px" }}>
                        {format(card?.lastUpdate,"dd MMM, yyyy")}
                      </SubTitle> */}
                      </CardContent>
                    </TemplateCard>
                  ))}
                </Stack>
              )}
              control={control}
              name={"value"}
            />
            {errors?.["value"] && (
              <Typography color={"red"} variant="caption" display="block">
                {errors?.["value"]?.message}
              </Typography>
            )}
          </>
        )}
        <Box
          sx={{
            position: "absolute",
            background: "white",
            px: 2,
            bottom: 0,
            right: 0,
            width: "100%",
          }}
        >
          <StyledAuthButton
            type="submit"
            disabled={isSubmitting}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send Test Mail
          </StyledAuthButton>
        </Box>
        {/* <Interpolation /> */}
      </ModalContainer>
    </Modal>
  );
};

export default TestMailModal;
