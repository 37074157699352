import { Box } from "@mui/material";
import { TitleText } from "./styled";
import Table from "../Table";
import DateRange from "../DateRange";
import { useLazyQuery } from "@apollo/client";
import { FETCH_CAMPAIGN_REPORTS } from "../../graphql/queries/marketingCampaigns";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default ({ setSwitchPage }) => {
  const date = useSelector((state) => state.date);
  const [getData, { data, loading: loadingData, refetch }] = useLazyQuery(
    FETCH_CAMPAIGN_REPORTS,
    {
      variables: {
        input: {
          startDate: date.startDate,
          endDate: date.endDate,
        },
      },
    }
  );

  const tableHeadCells = [
    {
      id: "sourceMedium",
      numeric: false,
      disablePadding: true,
      label: "Source/Medium",
      width: "20%",
    },
    {
      id: "pagePath",
      numeric: false,
      disablePadding: true,
      label: "Page path",
      width: "20%",
    },
    {
      id: "activeUsers",
      numeric: false,
      disablePadding: true,
      label: "Users",
      width: "10%",
    },
    {
      id: "sessions",
      numeric: false,
      disablePadding: true,
      label: "Sessions",
      width: "10%",
    },
    {
      id: "engagedSessions",
      numeric: false,
      disablePadding: true,
      label: "Engaged Session",
      width: "10%",
    },
    {
      id: "userEngagementDuration",
      numeric: false,
      disablePadding: true,
      label: "User Engagement Duration",
      width: "15%",
    },
    {
      id: "eventsPerSession",
      numeric: false,
      disablePadding: true,
      label: "Events Per Session",
      width: "20%",
    },
    {
      id: "engagementRate",
      numeric: false,
      disablePadding: true,
      label: "Engagement Rate",
      width: "20%",
    },
    {
      id: "avgEngagementTimePerSession",
      numeric: false,
      disablePadding: true,
      label: "Avg. Enagagement Time/Session",
      width: "20%",
    },
    {
      id: "engagedSessionPerUser",
      numeric: false,
      disablePadding: true,
      label: "Engagement Session Per User",
      width: "20%",
    },
  ];

  const getRows = () => {
    let rows = data?.fetchCampaignReports ?? [];
    rows = rows.map((row) => {
      let obj = { ...row };
      obj.sourceMedium = row?.dimensions?.sessionManualSourceMedium;
      obj.pagePath = row?.dimensions?.pagePath;
      let metrics = row?.metrics ?? {};

      return { ...obj, ...metrics };
    });
    return rows;
  };

  useEffect(() => {
    getData();
  }, [date]);

  return (
    <Box sx={{ minHeight: "400px" }}>
      <Box sx={{ display: "flex", borderBottom: "2px solid #ECECF2", mb: 3 }}>
        <TitleText
          sx={{ my: 3, borderBottom: "2px solid #000000", p: 2, mb: "-2px" }}
        >
          Campaign Report
        </TitleText>
        <TitleText
          sx={{ my: 3, cursor: "pointer", p: 2, mb: "-2px" }}
          onClick={() => setSwitchPage(false)}
        >
          Campaign List
        </TitleText>
      </Box>
      <DateRange style={{ width: "100%" }} />
      {loadingData ? (
        "Loading ..."
      ) : (
        <Table
          headCells={tableHeadCells}
          // rowClick={rowClick}
          rows={getRows()}
          stylehead
        />
      )}
    </Box>
  );
};
