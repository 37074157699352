import { MiniDiv, PContainer, PLabel, PSect, PTextField } from "./payStyled";
import iconcreditcardcvc from "../../assets/icons/iconcreditcardcvc.svg";
import iconcreditcardamex from "../../assets/icons/iconcreditcardamex.svg";
import iconcreditcardvisa from "../../assets/icons/iconcreditcardvisa.svg";
import iconcreditcardmastercard from "../../assets/icons/iconcreditcardmastercard.svg";
import MasterVisaDocu from "../../assets/icons/mastervisadocu.svg";
import { InputAdornment, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import axios from "axios";
import VisaIcon from "../../assets/images/visa.svg";
import AmexIcon from "../../assets/images/amex.svg";
import DiscoverIcon from "../../assets/images/discover.svg";
import MasterIcon from "../../assets/images/mastercard.svg";
import { validateInput } from "../../utils/format";

export default ({
  setError,
  cardData,
  setCardData,
  cardHolderName,
  setCardHolderName,
  expiration,
  setExpiration,
  country,
  setCountry,
  addContact,
  contact,
  setContact,
  errorState,
  setErrorState,
  noCurve,
  fontToUse,
}) => {
  const [countries, setCountries] = useState([]);
  const [crdNum, setCrdNum] = useState("");
  const [focusState, setFocusState] = useState({});

  const handleCardNumberChange = (event) => {
    let cardNumber = DOMPurify.sanitize(event.target.value);
    cardNumber = cardNumber.replace(/\D/g, ""); // Remove non-numeric characters
    cardNumber = cardNumber.substring(0, 16); // Limit to 16 digits

    const formattedCardNumber = cardNumber.replace(/(\d{4})/g, "$1 ").trim(); // Add a space after every four numbers
    event.target.value = formattedCardNumber;

    const isValidCardNumber = validateCardNumber(cardNumber);
    setCrdNum(cardNumber);
    if (isValidCardNumber) {
      setCardData((prevData) => ({ ...prevData, number: cardNumber }));
      setError("");
    } else {
      setError("Please enter a valid credit card number.");
      setCardData((prevData) => ({ ...prevData, number: "" }));
    }
  };

  const validateCardNumber = (cardNumber) => {
    const reversedCardNumber = cardNumber.split("").reverse().join("");
    const sum = reversedCardNumber.split("").reduce((acc, digit, index) => {
      let value = parseInt(digit, 10);
      if (index % 2 === 1) {
        value *= 2;
        if (value > 9) {
          value -= 9;
        }
      }
      return acc + value;
    }, 0);
    return sum % 10 === 0;
  };

  const handleCardNumberInput = (event) => {
    const input = event.target;
    const cardNumber = DOMPurify.sanitize(input.value)
      .replace(/\D/g, "")
      .substring(0, 16);

    if (cardNumber.length > 16) {
      setError("Credit card number is too long");
      // Clear the card number in the cardData state
      setCardData((prevData) => ({ ...prevData, number: "" }));
      return;
    }

    const formattedCardNumber = cardNumber.replace(/(\d{4})/g, "$1 ").trim();
    input.value = formattedCardNumber;
    handleCardNumberChange(event);
  };

  const handleCardHolderNameChange = (event) => {
    const value = DOMPurify.sanitize(event.target.value);

    if (/(\.com|\.org)/i.test(value)) {
      alert("Enter a valid card holder name");
    } else {
      try {
        setCardHolderName(value);
      } catch (error) {
        setError("An error occurred while updating the card holder name");
      }
    }
  };

  const handleCVCChange = (event) => {
    if (event.target && event.target.value) {
      let value = DOMPurify.sanitize(
        event.target.value.replace(/\D/g, "").substring(0, 3)
      );
      setCardData((prevData) => ({ ...prevData, cvc: value }));
      event.target.value = value;
    }
  };

  const handleCVCBlur = () => {
    const cvcRegex = /^\d{3}$/;

    if (!cvcRegex.test(cardData.cvc)) {
      setError("Enter a valid 3-digit CVC");
      setCardData((prevData) => ({ ...prevData, cvc: "" }));
    } else {
      setError("");
    }
  };

  const handleExpirationChange = (event) => {
    let value = DOMPurify.sanitize(event.target.value);
    value = value.replace(/[^0-9/]/g, ""); // Remove non-numeric characters
    if (value.length === 2 && !value.includes("/")) {
      value += "/"; // Add a slash after the month
    }
    if (value.length > 5) {
      value = value.slice(0, 5); // Limit the length to 5 characters
    }
    setExpiration(value);
    const expirationRegex = /^(0[1-9]|1[0-2])\/(\d{2})$/; // Update the regex to allow YY format
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth() + 1;

    if (expirationRegex.test(value)) {
      const [expirationMonth, expirationYear] = value.split("/").map(Number);

      if (
        expirationYear >= currentYear ||
        (expirationYear === currentYear && expirationMonth >= currentMonth)
      ) {
        setCardData((prevData) => ({
          ...prevData,
          exp_month: expirationMonth,
          exp_year: expirationYear,
        }));
        event.target.blur(); // Remove focus from the input field
      } else {
        setError("Enter a valid future expiration date.");
      }
    } else {
      setError('Enter expiration date in this format "mm/yy".');
    }
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  // console.log(cardData, "carddataa", crdNum);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all?fields=name")
      .then((response) => {
        const sortedCountries = response.data.sort((a, b) => {
          const nameA = a.name.common.toUpperCase();
          const nameB = b.name.common.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setCountries(sortedCountries);
      })
      .catch((error) => {
        setError("Error fetching countries. Please try again later.");
        console.error("Error fetching countries:", error);
      });
  }, []);
  return (
    <PContainer>
      {addContact && (
        <>
          <PSect>
            <PLabel
              style={{
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
            >
              Website
            </PLabel>
            <PTextField
              placeholder="https://www.punch.cool"
              onChange={(e) =>
                setContact({ ...contact, website: e.target.value })
              }
              value={contact.website ?? ""}
              onBlur={(e) =>
                setErrorState({
                  ...errorState,
                  website: validateInput(e.target.value, "url"),
                })
              }
              style={{
                border: errorState.website ? "0.4px solid red" : undefined,
              }}
              rootStyle={{
                borderRadius: noCurve ? "5px" : undefined,
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
              otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
              outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
              focusedFielsetStyle={{
                borderColor: noCurve ? "#419b6c" : undefined,
              }}
            />
          </PSect>
          <PSect>
            <PLabel
              style={{
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
            >
              Name
            </PLabel>
            <PTextField
              placeholder="Frank Edwin"
              onChange={(e) => setContact({ ...contact, name: e.target.value })}
              value={contact.name ?? ""}
              onBlur={(e) =>
                setErrorState({
                  ...errorState,
                  name: validateInput(e.target.value, "text"),
                })
              }
              style={{
                border: errorState.name ? "0.4px solid red" : undefined,
              }}
              rootStyle={{
                borderRadius: noCurve ? "5px" : undefined,
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
              otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
              outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
              focusedFielsetStyle={{
                borderColor: noCurve ? "#419b6c" : undefined,
              }}
            />
          </PSect>
          <PSect>
            <PLabel
              style={{
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
            >
              Email Address
            </PLabel>
            <PTextField
              placeholder="test@test.com"
              onChange={(e) =>
                setContact({ ...contact, email: e.target.value })
              }
              value={contact.email ?? ""}
              onBlur={(e) =>
                setErrorState({
                  ...errorState,
                  email: validateInput(e.target.value, "email"),
                })
              }
              style={{
                border: errorState.email ? "0.4px solid red" : undefined,
              }}
              rootStyle={{
                borderRadius: noCurve ? "5px" : undefined,
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
              otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
              outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
              focusedFielsetStyle={{
                borderColor: noCurve ? "#419b6c" : undefined,
              }}
            />
          </PSect>
          <PSect>
            <PLabel
              style={{
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
            >
              Phone Number
            </PLabel>
            <PTextField
              placeholder="234 8999 000"
              onChange={(e) =>
                setContact({ ...contact, phoneNumber: e.target.value })
              }
              value={contact.phoneNumber ?? ""}
              onBlur={(e) =>
                setErrorState({
                  ...errorState,
                  phoneNumber:
                    e.target.value && validateInput(e.target.value, "tel"),
                })
              }
              style={{
                border: errorState.phoneNumber ? "0.4px solid red" : undefined,
              }}
              rootStyle={{
                borderRadius: noCurve ? "5px" : undefined,
                fontFamily: fontToUse ? fontToUse : undefined,
              }}
              otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
              outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
              focusedFielsetStyle={{
                borderColor: noCurve ? "#419b6c" : undefined,
              }}
            />
          </PSect>
        </>
      )}
      <PSect>
        <PLabel
          style={{
            fontFamily: fontToUse ? fontToUse : undefined,
          }}
        >
          Card number
        </PLabel>
        <PTextField
          placeholder="1234 1234 1234 1234"
          onChange={handleCardNumberChange}
          onBlur={handleCardNumberInput}
          InputProps={{
            sx: { border: "none" },
            endAdornment: (
              <InputAdornment position="end">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "2px",
                    width: "105px",
                    // opacity: crdNum && String(crdNum).length > 0 ? 1 : 0.5,
                  }}
                >
                  {(!crdNum || String(crdNum).length === 0) && (
                    <img src={MasterVisaDocu} style={{ width: "150%" }} />
                  )}
                  {String(crdNum)[0] === "5" && <img src={MasterIcon} />}
                  {String(crdNum)[0] === "4" && <img src={VisaIcon} />}
                  {String(crdNum)[0] === "3" && <img src={AmexIcon} />}
                  {String(crdNum)[0] === "6" && <img src={DiscoverIcon} />}
                </div>

                {/* <img
                  className="icon-instance-node-2"
                  alt="mastercard"
                  src={iconcreditcardmastercard}
                />
                <img
                  className="icon-instance-node-2"
                  alt="amex"
                  src={iconcreditcardamex}
                />
                <img
                  className="icon-instance-node-2"
                  alt="visa"
                  src={iconcreditcardvisa}
                /> */}
              </InputAdornment>
            ),
          }}
          rootStyle={{
            borderRadius: noCurve ? "5px" : undefined,
            fontFamily: fontToUse ? fontToUse : undefined,
          }}
          otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
          outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
          focusedFielsetStyle={{ borderColor: noCurve ? "#419b6c" : undefined }}
        />
      </PSect>
      <MiniDiv>
        <PSect>
          <PLabel
            style={{
              fontFamily: fontToUse ? fontToUse : undefined,
            }}
          >
            Expiration
          </PLabel>
          <PTextField
            placeholder="MM/YY"
            value={expiration}
            required
            onChange={handleExpirationChange}
            rootStyle={{
              borderRadius: noCurve ? "5px" : undefined,
              fontFamily: fontToUse ? fontToUse : undefined,
            }}
            otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
            outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
            focusedFielsetStyle={{
              borderColor: noCurve ? "#419b6c" : undefined,
            }}
          />
        </PSect>
        <PSect>
          <PLabel
            style={{
              fontFamily: fontToUse ? fontToUse : undefined,
            }}
          >
            CVC
          </PLabel>
          <PTextField
            placeholder="CVC"
            required
            onChange={handleCVCChange}
            onBlur={handleCVCBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    className="icon-instance-node-2"
                    src={iconcreditcardcvc}
                  />
                </InputAdornment>
              ),
            }}
            rootStyle={{
              borderRadius: noCurve ? "5px" : undefined,
              fontFamily: fontToUse ? fontToUse : undefined,
            }}
            otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
            outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
            focusedFielsetStyle={{
              borderColor: noCurve ? "#419b6c" : undefined,
            }}
          />
        </PSect>
      </MiniDiv>

      <PSect>
        <PLabel
          style={{
            fontFamily: fontToUse ? fontToUse : undefined,
          }}
        >
          Cardholder Name
        </PLabel>
        <PTextField
          required
          value={cardHolderName}
          onChange={handleCardHolderNameChange}
          rootStyle={{
            borderRadius: noCurve ? "5px" : undefined,
            fontFamily: fontToUse ? fontToUse : undefined,
          }}
          otherSx={{ borderRadius: noCurve ? "5px" : undefined }}
          outlineStyle={{ borderRadius: noCurve ? "5px" : undefined }}
          focusedFielsetStyle={{ borderColor: noCurve ? "#419b6c" : undefined }}
        />
      </PSect>
      {/* <PSect>
        <PLabel style={{
          fontFamily: fontToUse ? fontToUse : undefined,
        }}>Country</PLabel>
        <PTextField
          select
          required
          value={country}
          onChange={handleCountryChange}
        >
          {countries.map((country, index) => (
            <MenuItem key={index} value={country.name.common}>
              {country.name.common}
            </MenuItem>
          ))}
        </PTextField>
      </PSect> */}
    </PContainer>
  );
};
