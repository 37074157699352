import React, { useEffect, useState, useRef } from "react";
import { Grid, Box, Divider, Select, MenuItem } from "@mui/material";
import {
  Overview,
  OverviewBox,
  DetailsContainer,
} from "../../pages/Organizations/styled";
import {
  BannerButton,
  BannerButtonText,
  BannerCalender,
  FlexBannerButtonFilter,
  FlexBannerButtonFilterTwo,
  FullAnalyticsHeaderBox,
  FullAnalyticsTwo,
  SearchResultsFormDateTwo,
} from "../../pages/UserDashboard/styled";
import Calender from "../../assets/icons/Calender.svg";
// import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import moment from "moment";
import dayjs from "dayjs";
import "antd/dist/antd.js";
import { DatePicker } from "antd";
import styled from "styled-components";
import NewsletterSummaryAnalytics from "./NewsletterSummaryAnalytics";
import { FETCH_DAILY_EVENT_COUNTS } from "../../graphql/queries/newsletter";
import NewsletterChartCard from "./NewsletterChartCard";
import { getDateRange, newsletterAnalyticsItems } from "../../utils/pageStore";
import { FETCH_JOB_CATEGORIES } from "../../graphql/queries/salesApp";

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker-range {
    visibility: hidden;
  }

  & .ant-picker-dropdown {
    top: 135px;
    left: 1339.27px;
  }
`;

function NewsletterAnalytics() {
  const menu = useSelector((state) => state.menu);
  const { data: dataJobCategories } = useQuery(FETCH_JOB_CATEGORIES);
  const rangeRef = useRef(null);
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(6, "day").format("YYYY-MM-DD")
  );
  const [dailyData, setDailyData] = useState();
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedAnalytics, setSelectedAnalytics] = useState("all");
  const numSelectedDays = dayjs(endDate).diff(startDate, "day") + 1;
  const [dateText, setDateText] = useState("Yesterday");
  const [display, setDisplay] = useState(false);
  const [topCalendar, setTopCalendar] = useState(0);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);

  const { data, loading } = useQuery(FETCH_DAILY_EVENT_COUNTS, {
    variables: {
      input: {
        ...(selectedAnalytics === "all" ? {} : { category: selectedAnalytics }),
        startDate,
        endDate,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.fetchDailyEventCounts) {
      setDailyData(data?.fetchDailyEventCounts);
    }
  }, [data]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (rangeRef.current && !rangeRef.current.contains(event.target)) {
        // Check if the clicked target is within the Dropdown's internal elements
        const dropdownInternal = document.querySelector(".ant-picker-dropdown");
        if (!dropdownInternal || !dropdownInternal.contains(event.target)) {
          setDisplay(false);
        } else {
          setDisplay(true);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (rangeRef.current) {
      const rect = rangeRef.current.getBoundingClientRect();
      // console.log("RectSecond:", rect);

      setTopCalendar(rect.bottom);
      // Do something with the top position
    }
  }, [rangeRef]);

  const datasets = [
    {
      value: "bounce",
      label: newsletterAnalyticsItems["bounce"].title,
      data:
        (data?.fetchDailyEventCounts?.["bounce"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["bounce"].color,
      backgroundColor: newsletterAnalyticsItems["bounce"].color,
    },
    {
      value: "open",
      label: newsletterAnalyticsItems["open"].title,
      data:
        (data?.fetchDailyEventCounts?.["open"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["open"].color,
      backgroundColor: newsletterAnalyticsItems["open"].color,
    },
    {
      value: "click",
      label: newsletterAnalyticsItems["click"].title,
      data:
        (data?.fetchDailyEventCounts?.["click"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["click"].color,
      backgroundColor: newsletterAnalyticsItems["click"].color,
    },
    {
      value: "delivered",
      label: newsletterAnalyticsItems["delivered"].title,
      data:
        (data?.fetchDailyEventCounts?.["delivered"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["delivered"].color,
      backgroundColor: newsletterAnalyticsItems["delivered"].color,
    },
    {
      value: "unsubscribe",
      label: newsletterAnalyticsItems["unsubscribe"].title,
      data:
        (data?.fetchDailyEventCounts?.["unsubscribe"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["unsubscribe"].color,
      backgroundColor: newsletterAnalyticsItems["unsubscribe"].color,
    },
    {
      value: "spamreport",
      label: newsletterAnalyticsItems["spamreport"].title,
      data:
        (data?.fetchDailyEventCounts?.["spamreport"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["spamreport"].color,
      backgroundColor: newsletterAnalyticsItems["spamreport"].color,
    },
    {
      value: "systemBounce",
      label: newsletterAnalyticsItems["systemBounce"].title,
      data:
        (data?.fetchDailyEventCounts?.["systemBounce"] ?? []).map(
          ({ date, count }) => ({
            x: moment(date),
            y: Number(count),
          })
        ) || [],
      fill: false,
      borderColor: newsletterAnalyticsItems["systemBounce"].color,
      backgroundColor: newsletterAnalyticsItems["systemBounce"].color,
    },
  ];

  // const handleRangeChangeTwo = (dates, dateStrings) => {
  //   console.log("change is called")
  //   const newStartDate = dateStrings[0];
  //   const newEndDate = dateStrings[1];

  //   setStartDate(newStartDate);
  //   setEndDate(newEndDate);
  //   const isCustomRangeSelected =
  //     dateStrings[0] === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
  //     dateStrings[1] === dayjs().format("YYYY-MM-DD");

  //   setCustomRangeSelected(isCustomRangeSelected);
  // };

  const handleRangeChangeTwo = (dates, dateStrings) => {
    // console.log("change is called");
    if (dateStrings.length === 2 && dateStrings[0] && dateStrings[1]) {
      const [newStartDate, newEndDate] = dateStrings;
      setStartDate(newStartDate);
      setEndDate(newEndDate);

      const isCustomRangeSelected =
        newStartDate === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
        newEndDate === dayjs().format("YYYY-MM-DD");
      setCustomRangeSelected(isCustomRangeSelected);
    } else {
      console.error("Invalid date range selected.");
    }
  };

  const presetRanges = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "day"), dayjs()],
    "Last 7 Days": [dayjs().subtract(6, "day"), dayjs()],
    "Last 14 Days": [dayjs().subtract(13, "day"), dayjs()],
    "Last 21 Days": [dayjs().subtract(20, "day"), dayjs()],
    "Last 30 Days": [dayjs().subtract(29, "day"), dayjs()],
    "Last 60 Days": [dayjs().subtract(59, "day"), dayjs()],
    "Last 90 Days": [dayjs().subtract(89, "day"), dayjs()],
  };

  function getCalendarContainer(trigger) {
    return trigger.parentNode;
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // changing date format
  const formattedStartDate = moment(startDate).format("MMM D, YYYY");
  const formattedEndDate = moment(endDate).format("MMM D, YYYY");

  return (
    <>
      <OverviewBox>
        <DetailsContainer>
          <Overview>Analytics</Overview>
          <Box sx={{ display: "flex" }}>
            <Select
              onChange={(e) => setSelectedAnalytics(e.target.value)}
              value={selectedAnalytics}
              sx={{
                width: "250px",
                fontFamily: "Switzer",
                borderRadius: "12px",
                mr: 10,
                ".MuiSelect-select": {
                  py: "9px !important",
                },
              }}
            >
              <MenuItem sx={{ fontFamily: "Switzer" }} value={"all"}>
                All
              </MenuItem>
              {(dataJobCategories?.fetchJobCategories instanceof Array
                ? dataJobCategories?.fetchJobCategories
                : []
              ).map((elm) => (
                <MenuItem
                  key={elm.name}
                  sx={{ fontFamily: "Switzer" }}
                  value={elm.name}
                >
                  {elm.name}
                </MenuItem>
              ))}
            </Select>

            <FlexBannerButtonFilterTwo ref={rangeRef}>
              <BannerButton
                style={{ marginRight: "0rem" }}
                data-date-picker-trigger="true"
                onClick={() => {
                  setDisplay((prevDisplay) => !prevDisplay);
                }}
                className="navigation-img"
                tabIndex="0"
                autofocus
              >
                <BannerButtonText sx={{ marginLeft: "10px" }}>
                  {dateText}
                </BannerButtonText>

                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                {!isMobile && (
                  <BannerButtonText>
                    {formattedStartDate} - {formattedEndDate}
                  </BannerButtonText>
                )}

                <BannerCalender>
                  <img src={Calender}></img>
                </BannerCalender>
              </BannerButton>
              <StyledRangePicker
                id={{
                  start: startDate,
                  end: endDate,
                }}
                value={[dayjs(startDate), dayjs(endDate)]}
                onChange={handleRangeChangeTwo}
                ranges={presetRanges}
                disabledDate={disabledDate}
                open={display}
                showToday={false}
                getCalendarContainer={getCalendarContainer}
                style={{
                  width: 0,
                  position: "absolute",
                  zIndex: -1,
                  // visibility: "hidden",
                  overflow: "hidden",
                }}
                popupStyle={
                  isMobile
                    ? {
                        width: "90%",
                        top: "450px",
                        left: "0",
                      }
                    : {
                        // padding: "1.5% 0",
                        padding: "0",
                        paddingTop: "20px",
                        position: "absolute",
                        width: "auto",
                        left: "auto",
                        right: "2.5%",
                        // top: `calc(${topCalendar + 10}px`,
                      }
                }
              />
              {/* {display && (
              <SearchResultsFormDateTwo
                isMobile={isMobile}
              ></SearchResultsFormDateTwo>
            )} */}
            </FlexBannerButtonFilterTwo>
          </Box>
        </DetailsContainer>
      </OverviewBox>
      <NewsletterSummaryAnalytics
        data={dailyData}
        loading={loading}
        date={{ startDate, endDate }}
      />
      <FullAnalyticsTwo>
        <Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                border: "1px solid #D4DAE1",
                borderRadius: "12px",
                padding: "50px 20px",
              }}
            >
              <div>
                <NewsletterChartCard
                  labels={getDateRange(startDate, endDate).map((date) =>
                    moment(date)
                  )}
                  datasets={datasets}
                  isMobile={isMobile}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </FullAnalyticsTwo>
    </>
  );
}

export default NewsletterAnalytics;
