import React from "react";
import Modal from "react-modal";
import ModalTrash from "../assets/icons/ModalTrash.svg";
import ReqIcon from "../assets/icons/reqIcon.svg";
import RemoveIcon from "../assets/icons/RemoveIcon.svg";
import {
  DeleteButton,
  DeleteButtonText,
  DeleteHeader,
  DeleteText,
} from "../pages/Organizations/styled";

export default ({
  openModal,
  handleCloseModal,
  handleModalConfirm,
  isMobile,
  headerText = "Request a testimonial?",
  subHeaderText = "You are about to request for a testimonial",
  btnText = "Request",
}) => {
  const customStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.4)",
    },
    content: {
      display: "flex",
      boxSizing: " border-box",

      flexDirection: "column",
      alignItems: "center",
      // alignItems: "flex-start",
      padding: "16px",
      // padding: "16px 16px 24px 24px",

      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "507px",
      //   height: !isMobile && "312px",

      background: "#FFFFFF",

      border: "1px solid #ECECF2",
      borderRadius: "15px",
    },
  };
  return (
    <Modal
      isOpen={openModal}
      onRequest
      Close={handleCloseModal}
      ariaHideApp={false}
      style={customStyles}
    >
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <img
          src={RemoveIcon}
          style={{ cursor: "pointer" }}
          alt="modal-delete"
          onClick={handleCloseModal}
        />
      </div>

      <img
        src={ReqIcon}
        style={{ marginTop: "20px", height: 50 }}
        alt="modal-delete"
      />

      <DeleteHeader>{headerText}</DeleteHeader>
      <DeleteText>{subHeaderText}</DeleteText>
      <div className="d-flex" style={{ marginTop: "26px" }}>
        <DeleteButton onClick={handleCloseModal}>
          <DeleteButtonText sx={{ color: "#28262C" }}> Cancel</DeleteButtonText>
        </DeleteButton>
        <DeleteButton
          sx={{ background: "#D93047", marginLeft: "1rem" }}
          onClick={handleModalConfirm}
        >
          <DeleteButtonText sx={{ color: "white" }}>
            {" "}
            {btnText}
          </DeleteButtonText>
        </DeleteButton>
      </div>
    </Modal>
  );
};
