const hexToRgb = (hex) => {
  // Remove the hash if it exists
  hex = String(hex).replace(/^#/, "");

  // Parse the hex values for red, green, and blue
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Return the RGB values as a string
  return `${r}, ${g}, ${b}`;
};

const colorArray = (str) => {
  return hexToRgb(str).split(",");
};

////////////////////////////////////

function rgbToHex(rgb) {
  // Ensure that each component is within the valid range [0, 255]
  const clamp = (value) => Math.min(255, Math.max(0, Math.round(value)));
  const clampAndToHex = (value) => clamp(value).toString(16).padStart(2, "0");

  // Destructure the RGB components
  const [red, green, blue] = rgb;

  // Convert each component to its hexadecimal representation
  const hexRed = clampAndToHex(red);
  const hexGreen = clampAndToHex(green);
  const hexBlue = clampAndToHex(blue);

  // Combine the hexadecimal components to form the color code
  return `#${hexRed}${hexGreen}${hexBlue}`;
}

export function addValueToColor(color, valueToAdd = [67, 71, 78]) {
  // Assuming color is an array of [R, G, B] values in the range [0, 255]
  let colorArr = colorArray(color);
  return rgbToHex(
    colorArr.map((component, idx) =>
      Math.min(255, Number(component) + valueToAdd[idx])
    )
  );
}

// Example usage
// let originalColor = [100, 150, 200]; // Example color
// let valueToAdd = 30; // Example value to add

// let originalColor = colorArray("#007BF7"); // Example color
// // let originalColor = colorArray("#1A1B24"); // Example color
// // let valueToAdd = [67, 71, 78]; // Example value to add

// let modifiedColor = addValueToColor(originalColor);
// console.log(modifiedColor);
// modifiedColor = rgbToHex(modifiedColor);
// console.log("Original Color:", originalColor);
// console.log("Modified Color:", modifiedColor);
