import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GET_SINGULAR_SENDER_VIDEO_BY_IDENTITY } from "../../graphql/queries/videosQueries";
import { useQuery } from "@apollo/client";
import SendTestimonial from "../../components/SendTestimonial/SendTestimonial";
import SpinnerBig from "../../components/SpinnerBig";

function RecieverTestimonial() {
  const { videoIdentity } = useParams();
  const videoIdentityNumber = videoIdentity;

  // Use state to store the data
  const [videoDetails, setVideoDetails] = useState(null);

  const { loading, error, data } = useQuery(
    GET_SINGULAR_SENDER_VIDEO_BY_IDENTITY,
    {
      variables: { input: { videoIdentityNumber: videoIdentityNumber } },
    }
  );

  // useEffect to update the state when data changes
  useEffect(() => {
    if (data?.singleSenderVideo) {
      setVideoDetails(data.singleSenderVideo);
    }
  }, [data]);

  // console.log(videoDetails, data, "idden");
  if (loading) {
    return <SpinnerBig />;
  }

  return (
    <>
      <SendTestimonial videoDetails={videoDetails} />
    </>
  );
}

export default RecieverTestimonial;
