import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentStatus } from "../../redux/payment.slice";
import axios from "axios";
// import { useElements } from "@stripe/react-stripe-js";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
// import Stripe from "stripe";
import {
  StyledFrameScreen,
  FrameContainer,
  CardPanel,
  Field,
  Input,
  FieldCardNumber,
  Div2,
  TextWrapper,
  Field2,
  Input2,
  Tablist,
  DivWrapper,
  Div3,
  Select,
  DivWrapper2,
  TextWrapper2,
  WrapContainer,
  FooterS1_1,
  FooterS,
  FooterS1_1L,
} from "./styled";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { useMutation, useQuery } from "@apollo/client";
import { queryParams } from "../../utils/Helpers";
import { CreditCardBrands, CreditCardCvc } from "./styled";
import iconcreditcardcvc from "../../assets/icons/iconcreditcardcvc.svg";
import iconcreditcardamex from "../../assets/icons/iconcreditcardamex.svg";
import iconcreditcardvisa from "../../assets/icons/iconcreditcardvisa.svg";
import iconcreditcardmastercard from "../../assets/icons/iconcreditcardmastercard.svg";
import DOMPurify from "dompurify";
import {
  PROCESS_CUSTOMIZED_PAYMENT,
  PROCESS_PAYMENT,
} from "../../graphql/mutations/payment";
import { encryptObject } from "../../utils/encrDecr";
import PayComp from "../../pages/Pay/payComp";
import ColorButton from "../../components/ColorButton";
import { useSnackbar } from "notistack";
import { setUser } from "../../redux/auth.slice";
import { identifyCardType } from "../../utils/format";

export default function PaymentComp({
  selectedSubscription,
  setEmailUser,
  existingCard,
  buttonTitle,
  productInformation,
  productAmount,
  clearAllItems,
  addContact,
  paymentOwner = "Recrowdly",
  noCurve,
  fontToUse,
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const cardNumberInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [contact, setContact] = useState({});
  const [errorState, setErrorState] = useState({});

  const prices = {
    monthly: process.env.REACT_APP_MONTHLY_PRICE,
    yearly: process.env.REACT_APP_YEARLY_PRICE,
  };
  const [cvc, setCVC] = useState("");
  const user = useSelector((state) => state.user);
  const [cardData, setCardData] = useState({
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const [cardHolderName, setCardHolderName] = useState("");
  const [expiration, setExpiration] = useState("");
  const [alert, setAlert] = useState(null);
  const profileData = useSelector((state) => state.auth);
  const inputRef = useRef(null);

  const [email, setEmail] = useState(undefined);
  // const stripe = Stripe(process.env.REACT_APP_PUBLISHABLE_KEY ?? "");
  const [country, setCountry] = useState("");

  const [processPay] = useMutation(PROCESS_PAYMENT);
  const [processCustomizedPay] = useMutation(PROCESS_CUSTOMIZED_PAYMENT);

  const dispatch = useDispatch();
  // const elements = useElements();
  const navigate = useNavigate();

  const handlePaymentResult = (result) => {
    if (result.error) {
      // setAlert({
      //   message: `Payment failed: ${result.error.message}`,
      //   severity: "error",
      // });
      enqueueSnackbar(`Payment failed: ${result.error.message}`, {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Payment was successful", {
        variant: "success",
      });
      // setAlert({ message: "Payment was successful", severity: "success" });
    }
  };

  const handlePaymentError = (error) => {
    enqueueSnackbar(`An error occurred: ${error.message}`, {
      variant: "error",
    });
    // setAlert({
    //   message: `An error occurred: ${error.message}`,
    //   severity: "error",
    // });
  };

  // console.log(cardData, "carddd");
  const handleSubmit = async (e) => {
    e?.preventDefault();
    setIsLoading(true);

    if (!selectedSubscription && !productAmount) {
      enqueueSnackbar(`Please select a subscription`, {
        variant: "error",
      });
      // setAlert({ message: "Please select a subscription", severity: "error" });
      return;
    }

    try {
      if (addContact) {
        ///valid
      }

      let amount = productAmount
        ? productAmount
        : selectedSubscription === "monthly"
        ? prices.monthly
        : prices.yearly * 12;
      let paymentData = existingCard
        ? {
            customerId: profileData?.user?.stripeCustomerId,
            amount,
            email,
            currency: "usd",
            subscriptionType: selectedSubscription,
            // productInformation not really necessary
          }
        : {
            cardNumber: Number(cardData?.number),
            cardMonth: Number(cardData?.exp_month),
            cardExpYear: Number(cardData?.exp_year),
            cardCVC: Number(cardData?.cvc),
            amount,
            cardHolderName,
            email,
            currency: "usd",
            subscriptionType: selectedSubscription
              ? selectedSubscription
              : productAmount
              ? "customized"
              : undefined,
            productInformation:
              productInformation instanceof Array &&
              productInformation.map(({ name, price, description, id }) => ({
                name,
                price,
                description,
                id,
              })),
            contact: addContact ? contact : undefined,
          };
      // console.log(paymentData, "paymentData");
      let { data, errors } = !productAmount
        ? await processPay({
            variables: { input: encryptObject(paymentData) },
          })
        : await processCustomizedPay({
            variables: { input: encryptObject(paymentData) },
          });

      if (!data && errors) {
        enqueueSnackbar(`Error making Payment`, {
          variant: "error",
        });

        return;
      }
      let res = data?.processPayment ?? data?.processCustomizedPayment;
      // console.log(data, "datapay", res.flag, res);

      if ((productAmount && !res.flag) || (!productAmount && !res._id)) {
        enqueueSnackbar(`Payment failed`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Payment was successful", {
          variant: "success",
        });

        if (productAmount && clearAllItems) clearAllItems();
        if (!productInformation && !productAmount) {
          dispatch(setUser(data.processPayment));

          setTimeout(() => {
            navigate("/billingplans", { replace: true });
          }, 2000);
        }
      }
    } catch (error) {
      enqueueSnackbar(`An error occurred: ${error.message}`, {
        variant: "error",
      });
      // setAlert({
      //   message: `An error occurred: ${error.message}`,
      //   severity: "error",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!productAmount) {
      setEmail(profileData?.user?.email);
      setEmailUser && setEmailUser(profileData?.user?.email);
    }
  }, [profileData]);

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <div
        style={{
          pointerEvents: existingCard ? "none" : undefined,
          opacity: existingCard ? 0.5 : 1,
        }}
      >
        <PayComp
          setError={setError}
          cardData={cardData}
          setCardData={setCardData}
          cardHolderName={cardHolderName}
          setCardHolderName={setCardHolderName}
          expiration={expiration}
          setExpiration={setExpiration}
          country={country}
          setCountry={setCountry}
          contact={contact}
          setContact={setContact}
          addContact={addContact}
          setErrorState={setErrorState}
          errorState={errorState}
          noCurve={noCurve}
          fontToUse={fontToUse}
        />
      </div>
      <ColorButton
        innerStyle={{
          borderRadius: noCurve ? "5px" : undefined,
          fontFamily: fontToUse ? fontToUse : undefined,
        }}
        title={
          isLoading ? (
            <CircularProgress size={24} />
          ) : (
            buttonTitle ?? "Subscribe"
          )
        }
        background={noCurve ? "#419b6c" : "#1A1B24"}
        width={"100%"}
        onClick={handleSubmit}
        style={{
          marginTop: "32px",
          fontFamily: fontToUse ? fontToUse : undefined,
        }}
        buttonTextStyle={{
          fontFamily: fontToUse ? fontToUse : undefined,
          color: fontToUse ? "white" : undefined,
        }}
        disabled={
          isLoading ||
          (!selectedSubscription && !productInformation) ||
          (addContact && Object.values(errorState).some((val) => val))
        }
      />
      <FooterS>
        <FooterS1_1>
          By placing your order, you agree to our{" "}
          <FooterS1_1L>terms of service</FooterS1_1L> and{" "}
          <FooterS1_1L>privacy policy</FooterS1_1L>.
        </FooterS1_1>
        <FooterS1_1>
          By confirming your subscription, you allow {paymentOwner} to charge
          your card for this payment and future payments in accordance with
          their terms. You can always cancel your subscription.
        </FooterS1_1>
      </FooterS>
    </form>
  );
}
