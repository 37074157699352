import React, { useEffect, useState } from "react";
import SubscriptionComp from "../../components/SubscriptionComp";
import PaymentComp from "../../components/PaymentComp";
import Layout from "../../components/Layout";

import SwipeableViews from "react-swipeable-views";
import useMediaQuery from "@mui/material/useMediaQuery";
import MarkIcon from "../../assets/images/mark.svg";

import {
  StyledElement,
  HeadDiv,
  StyledFrameScreen,
  PCContainer,
  PCC1,
  PCC1_1,
  PCC1_1_1,
  PCC1_T1,
  PCC1_T2,
  PCC1_2,
  PCC1_2_1,
  PCC1_2_1BT1,
  PCC1_2_1BT2,
  PCC1_2_1B,
  PCC1_2_1BT2A,
  PCC1_2_1BT2_2,
  PCC1_3,
  PCC1_3_1,
  PCC1_3_2,
  PCC1_3_2Sect,
  PCC1_3_2Sect1,
  PCC1_3_2Sect2,
  PCCHr,
  PCC2_1,
  PCC2,
  PCCardPanel_1,
  PCCardP1,
  PCCardPanel,
  PCCardP2,
  PImage,
  PChkBox,
} from "./styled";
import SubHeader from "../../components/SubHeader";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useSelector } from "react-redux";
import { PLabel, PSect } from "./payStyled";
import { imgIcon } from "../../components/UpdateCardComp";

// Subscription and payment
// Define and export the Pay component
export default function Pay() {
  // Define a state variable to manage the selected subscription
  const [selectedSubscription, setSelectedSubscription] = useState("monthly");
  const [activeStep, setActiveStep] = useState(0);
  const [existingCard, setExistingCard] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const [dueDate, setDueDate] = useState("");
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [focusState, setFocusState] = useState({});
  const [emailUser, setEmailUser] = useState("");
  const { user } = useSelector((state) => state.auth);

  const prices = {
    monthly: process.env.REACT_APP_MONTHLY_PRICE,
    yearly: process.env.REACT_APP_YEARLY_PRICE,
  };

  const selected = prices[selectedSubscription];
  // Update the total amount based on the selected subscription
  const totalAmount = selectedSubscription ? selected : 0;

  const handleSubscriptionSelect = (subscriptionType) => {
    setSelectedSubscription(subscriptionType);
    handleSubscribeSelect(subscriptionType);
    // setAmount(prices[subscriptionType]);
  };

  // Define a function to handle the selection of a subscription type
  const handleSubscribeSelect = (subscriptionType) => {
    // Update the selectedSubscription state with the chosen subscription type
    setSelectedSubscription(subscriptionType);
    // setAmount(prices[subscriptionType]);
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const totalCost = () => {
    if (selectedSubscription === "monthly") {
      return prices.monthly;
    }
    if (selectedSubscription === "yearly") {
      return prices.yearly * 12;
    }
  };

  const subInvoice = () => {
    if (selectedSubscription === "monthly") {
      return (
        <PCC1_3_2Sect>
          <PCC1_3_2Sect1>Due in 1 month</PCC1_3_2Sect1>
          <PCC1_3_2Sect2>${selected}</PCC1_3_2Sect2>
        </PCC1_3_2Sect>
      );
    }
    if (selectedSubscription === "yearly") {
      return (
        <>
          <PCC1_3_2Sect>
            <PCC1_3_2Sect1>Per month</PCC1_3_2Sect1>
            <PCC1_3_2Sect2>${selected}</PCC1_3_2Sect2>
          </PCC1_3_2Sect>
          <PCC1_3_2Sect>
            <PCC1_3_2Sect1>Duration</PCC1_3_2Sect1>
            <PCC1_3_2Sect2>12 Months</PCC1_3_2Sect2>
          </PCC1_3_2Sect>
        </>
      );
    }
  };

  const currentStatus = () => {
    return user?.isTrial
      ? `${process.env.REACT_APP_TRIAL_DAYS} days free trial`
      : user?.subscriptionType === "monthly"
      ? "Monthly Subscription"
      : user?.subscriptionType === "yearly"
      ? "Yearly Subscription"
      : "";
  };

  const billingDate = () => {
    if (
      user?.isTrial ||
      !user?.subExpDate ||
      new Date(user?.subExpDate).getTime() < new Date().getTime()
    )
      return new Date().toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
        day: "2-digit",
      });
    return new Date(user?.subExpDate).toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
      day: "2-digit",
    });
  };

  return (
    // Render the Layout component and other components
    <>
      <SubHeader
        btnTitle=""
        noLinkToLast={false}
        arrLink={[{ title: "Back", link: "/billingplans" }]}
      />
      <PCContainer>
        <PCC1>
          <PCC1_1>
            <PCC1_1_1>
              <PCC1_T1>Simple pricing for everyone</PCC1_T1>
              <PCC1_T2>Current Status : {currentStatus()}</PCC1_T2>
            </PCC1_1_1>
            <PCC1_2>
              <PCC1_2_1
                onClick={() => handleSubscriptionSelect("monthly")}
                active={selectedSubscription === "monthly"}
              >
                <PImage
                  src={MarkIcon}
                  alt=""
                  active={selectedSubscription === "monthly"}
                />
                <PCC1_2_1B>
                  <PCC1_2_1BT1>Pay monthly</PCC1_2_1BT1>
                  <PCC1_2_1BT2>${prices.monthly}/month</PCC1_2_1BT2>
                </PCC1_2_1B>
              </PCC1_2_1>
              {/* <PCC1_2_1
                onClick={() => handleSubscriptionSelect("yearly")}
                active={selectedSubscription === "yearly"}
              >
                <PImage
                  src={MarkIcon}
                  alt=""
                  active={selectedSubscription === "yearly"}
                />
                <PCC1_2_1B>
                  <PCC1_2_1BT1>Pay yearly</PCC1_2_1BT1>
                  <PCC1_2_1BT2A>
                    <PCC1_2_1BT2>${prices.yearly}/month</PCC1_2_1BT2>
                    <PCC1_2_1BT2_2>Save 33%</PCC1_2_1BT2_2>
                  </PCC1_2_1BT2A>
                </PCC1_2_1B>
              </PCC1_2_1> */}
            </PCC1_2>
            <PCC1_3>
              <PCC1_3_1>Plan Summary</PCC1_3_1>
              <PCC1_3_2>
                <PCC1_3_2Sect>
                  <PCC1_3_2Sect1>Start billing date</PCC1_3_2Sect1>
                  <PCC1_3_2Sect2>{billingDate()}</PCC1_3_2Sect2>
                </PCC1_3_2Sect>
                <PCC1_3_2Sect>
                  <PCCHr />
                </PCC1_3_2Sect>
                {subInvoice()}

                <PCC1_3_2Sect>
                  <PCCHr />
                </PCC1_3_2Sect>
                <PCC1_3_2Sect>
                  <PCC1_3_2Sect1 style={{ fontWeight: 500 }}>
                    Total
                  </PCC1_3_2Sect1>
                  <PCC1_3_2Sect2>${totalCost()}</PCC1_3_2Sect2>
                </PCC1_3_2Sect>
              </PCC1_3_2>
            </PCC1_3>
          </PCC1_1>
        </PCC1>
        <PCC2>
          <PCC2_1>Payment details</PCC2_1>
          {user?.lastCardDigits && (
            <PSect>
              <PLabel>Use the existing Payment Method</PLabel>
              <PCCardPanel>
                <PCCardPanel_1
                  sx={{
                    boxShadow: focusState.cardPanel
                      ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"
                      : undefined,
                    border: focusState.cardPanel
                      ? `1px solid #007bf7 !important`
                      : "1px solid #1a1b24",
                    ":hover": {
                      border: focusState.cardPanel
                        ? `1px solid #007bf7 !important`
                        : "1px solid #1a1b24",
                    },
                  }}
                  onMouseEnter={() =>
                    setFocusState({ ...focusState, cardPanel: true })
                  }
                  onMouseLeave={() =>
                    setFocusState({ ...focusState, cardPanel: false })
                  }
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => setExistingCard(!existingCard)}
                        />
                      }
                      label={
                        <PChkBox>
                          <img
                            src={imgIcon(user?.cardType, existingCard)}
                            style={{ height: "31px", width: "45px" }}
                            alt=""
                          />
                          <div>**** **** **** {user?.lastCardDigits}</div>
                        </PChkBox>
                      }
                    />
                  </FormGroup>
                </PCCardPanel_1>
              </PCCardPanel>
            </PSect>
          )}

          <PCCardPanel>
            <PCCardPanel_1>
              <PCCardP1>Email</PCCardP1>
              <PCCardP2>{emailUser}</PCCardP2>
            </PCCardPanel_1>
          </PCCardPanel>
          <Box style={{ width: "483px" }}>
            <PaymentComp
              selectedSubscription={selectedSubscription}
              setEmailUser={setEmailUser}
              existingCard={existingCard}
            />
          </Box>
        </PCC2>
      </PCContainer>
    </>
  );
}
