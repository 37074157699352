import React from "react";
import {
  ColorButtonInner,
  ColorButtonOverall,
  ColorButtonStart,
  ColorButtonStop,
} from "./styled";

function RecordButton({
  startStop,
  onClickStart,
  onClickStop,
  stRecord,
  counterStart,
  onClickPause,
  onClickResume,
  currentQuestionIndex,
  videoInfo,
}) {
  // console.log(stRecord, counterStart, videoInfo?.questions.length , currentQuestionIndex,"helooo",isNaN(stRecord));
  return (
    <div>
      <ColorButtonOverall>
        <ColorButtonInner>
          {stRecord === true && counterStart === 0 && (
            <ColorButtonStop onClick={onClickPause}></ColorButtonStop>
          )}
          {stRecord === "pause" && (
            <ColorButtonStart onClick={onClickResume}></ColorButtonStart>
          )}
          {stRecord === "resume" &&
            currentQuestionIndex < videoInfo?.questions.length - 1 && (
              <ColorButtonStop onClick={onClickPause}></ColorButtonStop>
            )}
          {stRecord === "resume" &&
            currentQuestionIndex === videoInfo?.questions.length - 1 && (
              <ColorButtonStop onClick={onClickPause}></ColorButtonStop>
            )}
          {stRecord !== "pause" &&
            stRecord !== "resume" &&
            stRecord !== true &&
            currentQuestionIndex === 0 && (
              <ColorButtonStart onClick={onClickStart}></ColorButtonStart>
            )}
        </ColorButtonInner>
      </ColorButtonOverall>
    </div>
  );
}

export default RecordButton;
