import React from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  FContainer,
  ItemDiv,
  NameDiv,
  ProfileDiv,
  SubItemDiv,
  SubItemDivText,
  TopContainer,
  TopLink,
  TopName,
  TopTitle,
  BottomName,
} from "./styled";
import { ProfileButton, ProfileButtonText } from "../../pages/UserDashboard/styled"
import TeamMembers from "../../assets/icons/TeamMembers.svg";
import LogoutIcon from "../../assets/icons/Logout.svg";
import ArrowRightIcon from "../../assets/icons/ArrowRight.svg";
import SecurityIcon from "../../assets/icons/Lock.svg";
import AvatarImage from "../../assets/images/Avatar.svg";
import Stars from "../../assets/icons/Stars.svg"

/**
 * User profile component for displaying user information and actions.
 *
 * @param {object} userData - User data including name, email, and avatar.
 * @param {function} handleLogout - Function to handle user logout.
 * @returns {JSX.Element} - Rendered user profile component.
 */
const UserProfile = ({ userData, handleLogout }) => {
  const navigate = useNavigate();
  const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;

  /**
   * Render the user's avatar.
   *
   * @returns {JSX.Element} - Rendered user avatar.
   */
  const renderAvatar = () => {
    if (userData?.findUserById?.thumbnail) {
      return (
        <Avatar
          alt="Avatar image"
          src={`https://covete.s3.amazonaws.com/Image${userData?.findUserById?.thumbnail}.jpeg`}
          sx={{ height: "80px", width: "80px" }}
        />
      );
    } else {
      return (
        <Avatar
          alt="Avatar image"
          src={AvatarImage}
          sx={{ height: "80px", width: "80px" }}
        />
      );
    }
  };

  /**
   * Navigate to a specified route.
   *
   * @param {string} path - The route to navigate to.
   */
  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <FContainer>
      <TopContainer>
        <TopTitle>Profile</TopTitle>
        <TopLink onClick={() => navigateTo("/profile?type=editProfile")}>
          Edit profile
        </TopLink>
      </TopContainer>
      <ProfileDiv>
        {userData?.findUserById?.thumbnail ? (
          <Avatar
            alt="Avatar image"
            src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${userData?.findUserById?.thumbnail}.jpeg`}
            sx={{ height: "80px", width: "80px" }}
          />
        ) : (
          <Avatar
            alt="Avatar image"
            src={AvatarImage}
            sx={{ height: "80px", width: "80px" }}
          />
        )}

        <NameDiv>
          <TopName>{userData?.findUserById?.name}</TopName>
          <BottomName>{userData?.findUserById?.email}</BottomName>
        </NameDiv>
      </ProfileDiv>
      <ItemDiv onClick={() => navigateTo("/teammembers")}>
        <SubItemDiv>
          <img src={TeamMembers} alt="profile-icon" />
          <SubItemDivText>Team members</SubItemDivText>
        </SubItemDiv>
        <img src={ArrowRightIcon} alt="profile-icon" />
      </ItemDiv>
      <ItemDiv onClick={() => navigateTo("/profile?type=security")}>
        <SubItemDiv>
          <img src={SecurityIcon} alt="profile-icon" />
          <SubItemDivText>Security</SubItemDivText>
        </SubItemDiv>
        <img src={ArrowRightIcon} alt="profile-icon" />
      </ItemDiv>
      <ItemDiv onClick={handleLogout}>
        <SubItemDiv>
          <img src={LogoutIcon} alt="profile-icon" />
          <SubItemDivText>Log out</SubItemDivText>
        </SubItemDiv>
        <img src={ArrowRightIcon} alt="profile-icon" />
      </ItemDiv>
      <ItemDiv onClick={() => navigate("/billingplans")}>
        <SubItemDiv>
          <img src={Stars} alt="subscription-icon" />
          <subItemDivText>Manage Subscription</subItemDivText>
        </SubItemDiv>
        <img src={ArrowRightIcon} alt="profile-icon" />
      </ItemDiv>
      {/* <ProfileButton onClick={() => navigate("/billingplans")}>
              <img
                src={Stars}
                alt="stars"
                style={{ height: 20, width: 20 }}

              />
              <ProfileButtonText>Manage Subscription</ProfileButtonText>
            </ProfileButton> */}
    </FContainer>
  );
};

export default UserProfile;
