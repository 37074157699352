import { gql } from "@apollo/client";

export const SAVE_TEMPLATE_MUTATION = gql`
  mutation SaveTemplate($input: SaveTemplateInput!) {
    saveTemplate(input: $input) {
      id
      title
      layoutType
      designBody
      designHTML
      imageData 
    }
  }
`;

export const UPDATE_TEMPLATE_MUTATION = gql`
  mutation UpdateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      id
      title
      layoutType
      designBody
      designHTML
      imageData 
    }
  }
`;

export const DELETE_TEMPLATE_MUTATION = gql`
  mutation DeleteTemplate($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input)
  }
`;

export const GET_TEMPLATE_BY_ID_QUERY = gql`
  query GetTemplateById($id: String!) {
    getTemplateById(input: { id: $id }) {
      id
      title
      layoutType
      designBody
      designHTML
      imageData 
    }
  }
`;

export const GET_ALL_TEMPLATES_QUERY = gql`
  query GetAllTemplates {
    getAllTemplates {
      id
      title
      layoutType
      designBody
      designHTML
      imageData 
    }
  }
`;