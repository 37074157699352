import { Grid, Box } from "@mui/material";
import React from "react";
import {
  AnalyticsBoxHeader,
  AnalyticsBoxSummmary,
  AnalyticsContainer,
  AnanlyticsBoxBadge,
  AnanlyticsBoxBadgeText,
  AnanlyticsBoxDiagram,
  AnanlyticsBoxNumbers,
  AnanlyticsBoxNumbersText,
  AnanlyticsBoxNumbersTextDate,
  MobileCarouselDiv,
} from "../pages/UserDashboard/styled";
import VectorOne from "../assets/icons/VectorOne.svg";
import VectorTwo from "../assets/icons/VectorTwo.svg";
import VectorThree from "../assets/icons/VectorThree.svg";
import VectorFour from "../assets/icons/VectoeFour.svg";
import Info from "../assets/icons/Info.svg";
import Duration from "../assets/icons/Duration.svg";
import Impression from "../assets/icons/Impression.svg";
import AverageRating from "../assets/icons/AverageRating.svg";
import NewVideos from "../assets/icons/NewVideos.svg";
import Engagements from "../assets/icons/Engagements.svg";
import PageVisited from "../assets/icons/PageVisited.svg";
import AverageTime from "../assets/icons/AverageTime.svg";

import PercentageUp from "../assets/icons/PercentageUp.svg";
import PercentageDown from "../assets/icons/PercentageDown.svg";
import { useQuery } from "@apollo/client";
import {
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  NEW_VIDEOS_COUNT,
  NEW_VIDEOS_DAYS_COUNT,
  RATING_DAYS_COUNT,
} from "../graphql/queries/videosQueries";
import Spinner from "./Spinner";
import DisapproveChart from "./DisapproveChart";
import ApproveChart from "./ApproveChart";
import NewVideoChart from "./NewVideoChart";
import VideosWatchedChart from "./VideosWatchedChart";
import { useSelector } from "react-redux";
import RatingChart from "./RatingChart";
import DurationChart from "./DurationChart";
import {
  GET_ANALYTICS_IMPRESSION,
  GET_TOTAL_ANALYTICSVIDEOS_WATCHED,
  VIDEOS_WATCHED_DAYS_COUNT,
} from "../graphql/queries/analytics";
import PercentageBox from "./PercentageBox";
import NumberBox from "./NumberBox";
import NumberBoxDuration from "./NumberBoxDuration";
import NumberBoxRating from "./NumberBoxRating";
import { Link } from "react-router-dom";
import GenericChart from "./GenericChart";
import CustomTooltip from "./CustomTootip";

function SummaryAnalytics({
  id,
  brandId,
  days,
  startDate,
  endDate,
  publishDate,
  unpublishDate,
  publishPercent,
  unpublishPercent,
  newVideos,
  videosWatched,
  dateRange,
  finalNum,
  totalVideosDuration,
  formattedTotalOverallDuration,
  engagementData,
  averageTimeData,
  pagesVisitedData,
}) {
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const menu = useSelector((state) => state.menu);
  const {
    loading: durationLoading,
    error: durationError,
    data: durationData,
  } = useQuery(GET_TOTAL_ANALYTICSVIDEOS_WATCHED, {
    variables: { brandId: brandId, startDate: startDate, endDate: endDate },

    fetchPolicy: "cache-and-network",
  });

  const {
    loading: impressionLoading,
    error: impressionError,
    data: impressionData,
  } = useQuery(VIDEOS_WATCHED_DAYS_COUNT, {
    variables: { brandId: brandId, startDate: startDate, endDate: endDate },

    fetchPolicy: "cache-and-network",
  });

  const {
    loading: ratingLoading,
    error: ratingError,
    data: ratingData,
  } = useQuery(RATING_DAYS_COUNT, {
    variables: { brandId: id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: newLoading,
    error: newError,
    data: newData,
  } = useQuery(NEW_VIDEOS_DAYS_COUNT, {
    variables: { brandId: id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  const { data: impression_Data } = useQuery(GET_ANALYTICS_IMPRESSION, {
    variables: { brandId: id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  // console.log(newData?.newVideoCount);

  // if (newLoading) return <Spinner />;
  if (newError) return <p>Something Went Wrong</p>;
  let GridDiv = isMobile ? MobileCarouselDiv : Grid;
  return (
    <>
      {" "}
      <AnalyticsContainer sx={{ flexGrow: 1 }}>
        <GridDiv
          container
          spacing={2}
          // sx={{ flexWrap: !isMobile ? "nowrap" : undefined }}
        >
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/duration`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
              <CustomTooltip 
 headerText="Dynamic Header Text"
 bodyText="Dynamic Body Text">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={Duration}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    />
                  </div>

                  <AnalyticsBoxHeader isMobile={isMobile}>
                    Duration
                  </AnalyticsBoxHeader>
                </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBoxDuration
                      durationStringOverall={totalVideosDuration}
                      duration={formattedTotalOverallDuration || 0}
                    />
                  </AnanlyticsBoxNumbersText>

                  <AnanlyticsBoxDiagram>
                    <DurationChart
                      id={brandId}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      Math.round(
                        durationData?.analyticsDaysVideoDuration[0]
                          .percentChange
                      ) || 0
                    }
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          {/* Old IMpression */}
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/impression`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Impressions"
                  bodyText="Impressions represent video views, counted each time a user clicks or watches."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Impression}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Impressions
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <script
                    id="covete_script"
                    value="brand_8rrx5s1puu"
                    src="./index.js"
                  ></script>
                  <AnanlyticsBoxNumbersText>
                    <NumberBox count={videosWatched || 0} duration={4000} />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <VideosWatchedChart
                      id={brandId}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      impressionData?.analyticsDaysVideoCount[0]
                        .percentChange || 0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          {/* New Impression */}
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/impression`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Impressions"
                  bodyText="Impressions represent video views, counted each time a user clicks or watches."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Impression}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Impressions
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <script
                    id="covete_script"
                    value="brand_8rrx5s1puu"
                    src="./index.js"
                  ></script>
                  <AnanlyticsBoxNumbersText>
                    <NumberBox
                      count={
                        impression_Data?.analyticsDaysImpression?.total || 0
                      }
                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={
                        impression_Data?.analyticsDaysImpression?.results
                      }
                      item="count"
                      color="#8D6CFF"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      impression_Data?.analyticsDaysImpression?.percentChange ||
                      0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          {/* New Impression --end */}
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/rating`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={AverageRating}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    ></img>
                  </div>
                  <AnalyticsBoxHeader isMobile={isMobile}>
                    Average Rating
                  </AnalyticsBoxHeader>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBoxRating count={finalNum || 0} duration={4000} />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <RatingChart
                      id={menu.id}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      ratingData?.ratingDaysVideoCount[0].percentChange || 0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/new`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={NewVideos}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    ></img>
                  </div>
                  <AnalyticsBoxHeader isMobile={isMobile}>
                    New Videos
                  </AnalyticsBoxHeader>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {" "}
                    <NumberBox count={newVideos || 0} duration={4000} />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <NewVideoChart
                      id={id}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={newData?.newDaysVideoCount[0].percentChange || 0}
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/engagement`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Engagement"
                  bodyText="Engagement measures the daily website traffic and assesses the extent of interaction with the widget."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Engagements}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>
                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Engagement
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {" "}
                    {/* <NumberBox
                      count={engagementData?.average || 0}
                      duration={4000}
                    /> */}
                    {`${engagementData?.totalWidgetTraffic || 0}/${
                      engagementData?.totalWebsiteTraffic || 0
                    }`}
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={engagementData?.results}
                      item="dailyTraffic"
                      color="#BF6DFF"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox number={engagementData?.percentChange || 0} />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/averageTime`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Average Time"
                  bodyText="Average Time signifies the typical duration visitors spend on the website."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={AverageTime}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>
                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Average Time
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {" "}
                    <NumberBoxDuration
                      durationStringOverall={averageTimeData?.total || 0}
                      duration={
                        averageTimeData?.total
                          ? Math.round(averageTimeData?.total)
                          : 0
                      }
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={averageTimeData?.results}
                      item="totalDuration"
                      color="#5ACEC7"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox number={averageTimeData?.percentChange || 0} />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/pageVisited`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Pages Visited"
                  bodyText="Pages Visited indicates the average number of pages viewed by visitors."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={PageVisited}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>
                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Pages Visited
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {" "}
                    <NumberBox
                      count={pagesVisitedData?.total || 0}
                      // pagesVisitedData?.average
                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={pagesVisitedData?.results}
                      item="avgPages"
                      color="#FAC60F"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={pagesVisitedData?.percentChange || 0}
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateRange}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
        </GridDiv>
      </AnalyticsContainer>
    </>
  );
}

export default SummaryAnalytics;
