import React from "react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/auth.slice";
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Grid,
  Container,
  Box,
  Divider,
  InputBase,
  Paper,
  IconButton,
  ListItemButton,
  Modal,
  TextField,
  Avatar,
  MenuItem,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  DraftsOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutline,
  ReceiptOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Covete from "../../assets/icons/Covete.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import {
  DashBoardContainer,
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
  NavContainer,
  NavDivider,
  NavHeaderLogoText,
  NavImage,
  NavSearch,
  NavTitleText,
  ProfileButton,
  ProfileButtonText,
  ProfileImage,
  ProfileImageContainer,
  ProfileImageContent,
  ProfileImageContentHeader,
  ProfileImageContentText,
  ProfileSnippet,
  SearchResultsBarBottom,
  SearchResultsBarText,
  SearchResultsBarTop,
  SearchResultsBrandIcon,
  SearchResultsBrandText,
  SearchResultsForm,
} from "../../pages/UserDashboard/styled";
import NavMenuButton from "../NavMenu";
import AvatarImage from "../../assets/images/Avatar.svg";
import AddImage from "../../assets/icons/AddImage.svg";
import EditProfileButton from "../../assets/images/EditProfileButton.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";
import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
} from "../../graphql/queries/organizationQueries";
import { ADD_BRAND } from "../../graphql/mutations/brandMutations";
import { GET_BRANDS } from "../../graphql/queries/brandQueries";
import AWS from "aws-sdk";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import ColorButton from "../ColorButton";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3();

function AddBrandDialog({ openDialog, onClose, orgId }) {
  const dispatch = useDispatch();
  const [showResults, setShowResults] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [openBrand, setBrandOpen] = React.useState(false);

  const navigate = useNavigate();

  // Add function to toggle visibility of search results
  const toggleResults = () => {
    setShowResults(!showResults);
    setShowProfile(false);
  };

  // Add function to toggle visibility of profile
  const toggleProfile = () => {
    setShowProfile(!showProfile);
    setShowResults(false);
  };
  const handleBrandOpen = () => {
    setBrandOpen(true);
  };
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sector, setSector] = useState("");
  const organization = orgId;
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [showText, setShowText] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);

  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  let fileUrl = "";

  const [createBrand] = useMutation(ADD_BRAND, {
    variables: { input: { name, description, sector, organization, imageUrl } },
    update(cache, { data: { createBrand } }) {
      const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
      const { userOrganizations = [] } = existingData || {};

      const updatedUserOrganizations = userOrganizations.map(
        (userOrganization) => {
          if (userOrganization.organization._id === organization) {
            const updatedBrands = [...userOrganization.brands, createBrand];

            return {
              ...userOrganization,
              brands: updatedBrands,
            };
          }
          return userOrganization;
        }
      );

      cache.writeQuery({
        query: GET_USER_ORGANIZATION,
        data: { userOrganizations: updatedUserOrganizations },
      });
    },
    refetchQueries: [{ query: GET_USER_ORGANIZATION }],
  });

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);

      const reader = new FileReader();

      reader.addEventListener("load", () => setSrc(reader.result));
      // reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const uploadToS3 = async () => {
    if (croppedImageUrl) {
      // Initialize S3 object
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      let addParams = Date.now();
      // Upload image to S3 bucket
      const key = `Image${addParams}.${blob.type.split("/")[1]}`;
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: key,
        Body: blob,
      };
      try {
        const data = await s3.upload(params).promise();

        setOpenSnackbar(true);
        setSnackbarMessage("Image Added Successfuly");

        setImageUrl(addParams.toString());
        setShowCrop(false);
        // setCroppedImageUrl(null);
      } catch (err) {
        console.error("Upload Error", err);
        setOpenSnackbar(true);
        setSnackbarMessage("Upload Error");
      }
    }
  };
  const handleCropCancel = () => {
    setSrc(null);
    setCroppedImageUrl(null);
    setShowCrop(false);
  };
  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

    return pattern.test(url);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (name === "" || sector === "" || description === "") {
      setOpenSnackbar(true);
      return setSnackbarMessage("Please return all fields");
    }

    try {
      await createBrand({
        variables: {
          input: { name, sector, organization, imageUrl, description },
        },
      });
      setOpenSnackbar(true);
      setSnackbarMessage("Brand added successfully");
      setName("");

      setDescription("");
      setSector("");
      setImageUrl("");
      setShowCrop(false);
      onClose();
    } catch (error) {
      console.error("Error creating brand:", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Error creating brand");
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // get organizations for select
  // const { error, data } = useQuery(GET_ORGANIZATIONS_FOR_USER);

  const handleClose = () => {
    setOpen(false);
    setShowResults(!showResults);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleBrandClose = () => setBrandOpen(false);

  // const getList = () => (
  //   <div style={{ width: 250 }} onClick={() => setOpen(false)}>
  //     {dataList?.map((item, index) => (
  //       <ListItem key={index} onClick={() => navigate(item?.link)}>
  //         <ListItemIcon>{item?.icon}</ListItemIcon>
  //         <ListItemText primary={item?.name} />
  //       </ListItem>
  //     ))}
  //     <ListItem
  //       onClick={() => {
  //         dispatch(logOutUser());
  //       }}
  //     >
  //       <ListItemIcon>
  //         <ReceiptOutlined />
  //       </ListItemIcon>
  //       <ListItemText primary="Log Out" />
  //     </ListItem>
  //   </div>
  // );

  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />

      <Dialog
        open={openDialog}
        onClose={onClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
        <DialogContent>
          <ModalBoxContainer>
            <ModalBoxHeader>
              <ModalBoxText>Time to personalize</ModalBoxText>
              <ModalBoxSubText>
                Fill the relevant information to create a brand under this
                organization
              </ModalBoxSubText>
            </ModalBoxHeader>
            <Divider sx={{ marginTop: "0.5rem" }} />

            <ModalColumnContainer>
              <ModalImageTitle>Add Image</ModalImageTitle>
              <div>
                <div className="upload-container" style={{ display: "flex" }}>
                  <div
                    className="upload-box"
                    style={{
                      width: "100px",
                      height: "100px",
                      background: "#F7F7F7",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "7px",
                      marginTop: "0.5rem",
                    }}
                    onMouseEnter={() => {
                      setShowText(true);
                      setShowImage(false);
                    }}
                    onMouseLeave={() => {
                      setShowText(false);
                      setShowImage(true);
                    }}
                    onClick={() =>
                      document.getElementById("image-upload").click()
                    }
                  >
                    {!croppedImageUrl && (
                      <>
                        {showImage && <img src={AddImage} />}
                        {showText && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={EditProfileButton}
                              style={{ width: "20px" }}
                              alt="edit-profile-button"
                            />
                            <p
                              className="upload-text"
                              style={{
                                fontSize: "10px",
                                lineHeight: "12px",
                                fontFamily: "Dm Sans",
                                // color: "#FFFFFF",
                                color: "#1a1b24",

                                paddingLeft: "0.5rem",
                                paddingRight: "0.5rem",
                                marginTop: "10px",
                                textAlign: "center",
                                borderRadius: "5px",
                                textShadow: "0px 1px 4px rgba(0, 0, 0, 0.35)",
                              }}
                            >
                              Add Image
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {croppedImageUrl && (
                      <img
                        src={croppedImageUrl}
                        alt="Cropped image"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "100px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {showCrop && (
                    <div
                      className="crop-container"
                      style={{
                        padding: "10px",
                        position: "absolute",
                        marginLeft: "7rem",
                        zIndex: "5",
                        background: "#FFFFFF",
                        border: "1px solid #ECECF2",
                        boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                      }}
                    >
                      <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        ruleOfThirds
                        // style={{ width: 200, height: 200 }}
                      />
                      <div
                        className="crop-buttons"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ColorButton
                          onClick={uploadToS3}
                          title="Save"
                          width="45%"
                          background="#1A1B24"
                        ></ColorButton>
                        <ColorButton
                          onClick={handleCropCancel}
                          title="Cancel"
                          width="45%"
                        >
                          Cancel
                        </ColorButton>
                      </div>
                    </div>
                  )}
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              <form onSubmit={onSubmit}>
                <ModalTextField>
                  <ModalImageTitle>Brand Name</ModalImageTitle>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label=""
                    placeholder="Enter your brand name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    sx={{ width: "95%", height: "46px" }}
                    size="small"
                    onChange={(e) => setName(e.target.value)}
                  />
                </ModalTextField>
                <ModalTextField>
                  <ModalImageTitle>Sector</ModalImageTitle>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="url"
                    label=""
                    placeholder="Add Sector"
                    name="url"
                    autoComplete="url"
                    autoFocus
                    sx={{ width: "95%", height: "46px" }}
                    size="small"
                    onChange={(e) => setSector(e.target.value)}
                  />
                </ModalTextField>
                {/* <ModalTextField>
                    <ModalImageTitle>Organization</ModalImageTitle>
                    <TextField
                        id='organizationId'
                        select
                        defaultValue=""
                        onChange={(e) => setOrganization(e.target.value)}
                        sx={{ width: "95%", height: "46px" ,marginTop:"0.5rem"}}
                      size="small"
                      >
                        
                        {data && data.organizationsForUser.map((organization) => (
                          <MenuItem key={organization._id} value={organization._id}>
                          {organization.name}
                        </MenuItem>
                        ))}
                      </TextField>
                  </ModalTextField> */}
                <ModalTextField>
                  <ModalImageTitle>Description</ModalImageTitle>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="description"
                    label=""
                    placeholder="Add description to your brand"
                    name="description"
                    multiline
                    rows={4}
                    sx={{ width: "95%" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </ModalTextField>
                <ModalButton type="submit">
                  <ModalButtonText>Create Brand</ModalButtonText>
                </ModalButton>
              </form>
            </ModalColumnContainer>
            <div></div>
          </ModalBoxContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AddBrandDialog;
