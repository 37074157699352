import {
  CancelBox,
  CancelBoxButton1,
  CancelBoxButton2,
  CancelBoxButtonDiv,
  CancelBoxMiddleText,
  CancelBoxTop,
  CancelBoxTopText,
  ProgressVidBottom,
  ProgressVidBottomItem,
  ProgressVidBottomItemContent,
  ProgressVidBottomItemContentText,
  ProgressVidContainer,
  ProgressVidTop,
  ProgressVidTopSide,
  ProgressVidTopText,
} from "./styled";
import CloseIcon from "../../assets/icons/RemoveIcon.svg";
import ArrowDownIcon from "../../assets/icons/ArrowDown.svg";
import ArrowUpIcon from "../../assets/icons/ArrowUp.svg";
import MarkSuccessIcon from "../../assets/icons/successMark.svg";
import VideoSvg from "../../assets/images/video.svg";
import { Box, CircularProgress } from "@mui/material";
import { ellipsisFileName } from "../../utils/format";
import { useState } from "react";

export default ({
  selFileAr = [],
  percentUploadedArr = [],
  successArr = [],
  resetFn,
  picAr = [],
  currentIndex,
}) => {
  const [show, setShow] = useState(true);
  const [cancel, setCancel] = useState(false);
  let remainingUpload =
    selFileAr.length - successArr.filter((el) => el === true).length;

  const displayProgress = () => {
    return selFileAr.map((el, idx) => (
      <ProgressVidBottomItem key={`P${idx}`}>
        <ProgressVidBottomItemContent>
          <img
            src={VideoSvg}
            alt="close"
            style={{
              cursor: "pointer",
              height: 25,
            }}
          />
          <ProgressVidBottomItemContentText>
            {ellipsisFileName(el.path)}
          </ProgressVidBottomItemContentText>
        </ProgressVidBottomItemContent>
        {successArr[idx] ? (
          <img src={MarkSuccessIcon} alt="" style={{ height: 24 }} />
        ) : (
          <Box sx={{ position: "relative" }}>
            <CircularProgress
              variant="determinate"
              value={percentUploadedArr[idx] ?? 0}
              size={24}
            />
            <CircularProgress
              variant="determinate"
              size={24}
              sx={{ position: "absolute", color: "#eee", left: 0, zIndex: -1 }}
              value={100}
            />
          </Box>
        )}
      </ProgressVidBottomItem>
    ));
  };

  const closeAll = () => {
    //Empty an array
    while (picAr.length > 0) picAr.pop();
    resetFn();
  };

  const handleClose = () => {
    if (remainingUpload === 0) {
      closeAll();
    } else {
      setCancel(true);
    }
  };

  let textLabel =
    remainingUpload === 0
      ? `${successArr.length} uploads complete`
      : `Uploading ${remainingUpload} videos`;

  return cancel ? (
    <CancelBox>
      <CancelBoxTop>
        <CancelBoxTopText>Cancel Upload?</CancelBoxTopText>
        <img
          src={CloseIcon}
          alt="close"
          onClick={() => closeAll()}
          style={{
            cursor: "pointer",
          }}
        />
      </CancelBoxTop>
      <CancelBoxMiddleText>
        Your up not is still in progress. would you like to cancel the upload?
      </CancelBoxMiddleText>
      <CancelBoxButtonDiv>
        <CancelBoxButton1 onClick={() => setCancel(false)}>
          Continue Upload
        </CancelBoxButton1>
        <CancelBoxButton2 onClick={() => closeAll()}>
          Cancel Upload
        </CancelBoxButton2>
      </CancelBoxButtonDiv>
    </CancelBox>
  ) : (
    <ProgressVidContainer>
      <ProgressVidTop>
        <ProgressVidTopText>{textLabel}</ProgressVidTopText>
        <ProgressVidTopSide>
          <img
            src={show ? ArrowDownIcon : ArrowUpIcon}
            alt="close"
            style={{
              cursor: "pointer",
            }}
            onClick={() => setShow(!show)}
          />
          <img
            src={CloseIcon}
            alt="close"
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </ProgressVidTopSide>
      </ProgressVidTop>
      {show && <ProgressVidBottom>{displayProgress()}</ProgressVidBottom>}
    </ProgressVidContainer>
  );
};
