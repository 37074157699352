import { gql } from "@apollo/client";

export const FETCH_REVIEWS = gql`
  query fetchReviews($input: GoogleReviewBrandInput!) {
    fetchReviews(input: $input) {
      placeID
      lastTimeChecked
      reviews
    }
  }
`;
