import React, { useEffect, useRef, useState } from "react";
import {
  Wrapper,
  MajorWrapper,
  LeftTopWrapper,
  LeftTopTitle,
  LeftTopSubTitle,
  MinimizeWrapper,
  RightTopWrapper,
  TopWrapper,
  CloseWrapper,
  InputWrapper,
  FormWrapper,
  LabelWrapper,
  LabelWrapperExt,
  SwitchWrapper,
  VideoPartWrapper,
  VideoWrapper,
  ContentWrapper,
  ButtonWrapper,
  BrandRating,
  ImageRate,
  BottomWrapper,
  LRButtonWrapper,
  LabelDiv,
  ImagePlay,
  StopRecordButton,
  StopRecordButtonWrapper,
  TestimonialUSer,
  TestimonialUSerText,
  TestimonialUSerImage,
  VectorFour,
  VectorFive,
  VectorTwo,
  VectorOne,
  VectorThree,
  ErrorWrapper,
  StepWrapper,
  StepTitle,
  Step2SubDiv,
  StepWrapperSpan,
  RequestText,
  RequestDisclaimer,
  DisclaimerText,
  QuestionTextarea,
  ViaSmsText,
  ViaEmail,
  ViaSms,
  LabelWrapperTwo,
} from "./styled";
import MinimizeIcon from "../../../assets/images/minimize.svg";
import PlayButton from "../../../assets/images/playbutton.svg";
import Play from "../../../assets/images/play.svg";
import Camera from "../../../assets/icons/Camera.svg";
import Upload from "../../../assets/icons/Upload.svg";
import Message from "../../../assets/icons/Message.svg";
import Comment from "../../../assets/icons/Comment.svg";
import Plus from "../../../assets/icons/Plus.svg";
import Disclaimer from "../../../assets/icons/Disclaimer.svg";
import Trash from "../../../assets/icons/Trash.svg";
import RecordUpload from "../../../assets/images/teenage-female.jpg";
import TestimonialVectorFour from "../../../assets/icons/TestimonialVectorFour.svg";
import TestimonialVectorSix from "../../../assets/icons/TestimonialVectorSix.svg";
import TestimonialVectorTwo from "../../../assets/icons/TestimonialVectorTwo.svg";
import TestimonialVectorOne from "../../../assets/icons/TestimonialVectorOne.svg";
import TestimonialVectorThree from "../../../assets/icons/TestimonialVectorThree.svg";
import TestimonialVectorFive from "../../../assets/icons/TestimonialVectorFive.svg";
import RemoveIcon from "../../../assets/icons/RemoveIcon.svg";
import AvatarImage from "../../../assets/images/Avatar.svg";
import StepActive from "../../../assets/images/FullStepIcon.svg";
import StepInActive from "../../../assets/images/EmptyStepIcon.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import { customAlphabet } from "nanoid";
// import Switch from "react-switch";
import Switch from "@mui/material/Switch";

import RecordStart from "../RecordComp";
import {
  fetchResourceFromURI,
  formatTime,
  onChangeTextHandler,
} from "../../../utils/format";
import { useMutation, useQuery } from "@apollo/client";
import { SAVE_SENDER_VIDEO } from "../../../graphql/mutations/videoMutation";
import AWS from "aws-sdk";
import Rating from "../Rating";
import SuccessComp from "../SuccessComp";
import LoadingComp from "../Loading";
import { useReactMediaRecorder } from "react-media-recorder";
import PlayRecord from "../PlayRecord";
import ReviewRecord from "./reviewRecord";
import { Avatar, Box, Checkbox, Container, Typography } from "@mui/material";
import ButtonCust from "../../ButtonCust";
import ColorButton from "../../ColorButton";
import { useSelector } from "react-redux";
import {
  PreviousButton,
  PreviousButtonText,
} from "../../../pages/OrganizationBrands/styled";
import Control from "../../Control";
import ReactPlayer from "react-player";
import { useSnackbar } from "notistack";
import Modal from "react-modal";
import ViaPreview from "../../ViaPreview";
import RequestSuccessPage from "../../RequestSuccessPage";
import { styled } from "@mui/material/styles";
import VideoSnapshot from "video-snapshot";
import RStep1 from "../RequestTestimonial/RStep1";
import RStep2 from "../RequestTestimonial/RStep2";
import RStep3 from "../RequestTestimonial/RStep3";
import RStep4 from "../RequestTestimonial/RStep4";
import RStep5 from "../RequestTestimonial/RStep5";
import RStep2345 from "../RequestTestimonial/RStep2345";
import { useLocation } from "react-router-dom";
import axios from "axios";
import countryCodes from "../../../utils/countryCodes";
import PhoneNumberInput from "../../PhoneNumberInput";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#007BF7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "white",
    width: "59%",
    padding: "15px",
    border: "none",
    overflow: "none",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    inset: "unset",
    borderRadius: "15px",
  },
};
const nanoid = customAlphabet("abcdefeghijklmnopqrstuvwxyz123456789", 10);
const ReviewComp = ({ setClicked, publicKey, brandProps, userData }) => {
  const [vidRate, setVidRate] = useState();
  const [checked, setChecked] = useState(true);
  const [step, setStep] = useState(1);
  const [newStep, setNewStep] = useState(1);
  // const [stRecord, setStRecord] = useState();
  // const [videoUrl, setVideoUrl] = useState();
  // const [picUrl, setPicUrl] = useState();
  const [success, setSuccess] = useState(false);
  const [formstate, setFormstate] = useState({});
  const [errorstate, setErrorstate] = useState({});
  const [showForm, setSetShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [trail, setTrail] = useState({});
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedMessage, setIsCheckedMessage] = useState(false);
  const [isCheckedSms, setIsCheckedSms] = useState(false);
  const [isCheckedEmail, setIsCheckedEmail] = useState(false);
  const [viaText, setViaText] = useState("");
  const [getPermission, setGetPermission] = useState(false);
  const [getPermissions, setGetPermissions] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [currentQuestionText, setCurrentQuestionText] = useState("");
  const [requestMessageText, setRequestMessageText] = useState(
    "Hi {{name}},\nWe had a blast working together, and we're thrilled to hear about your experience! We'd love to hear your thoughts and feedback."
  );
  const [recordedVideoUrlSections, setRecordedVideoUrlSections] = useState([]);
  const [recordedPicUrlSections, setRecordedPicUrlSections] = useState([]);
  const [recordedDurationSections, setRecordedDurationSections] = useState([]);
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newPicUrl, setNewPicUrl] = useState("");
  const [newDurationUrl, setNewDurationUrl] = useState(0);
  const [openCam, setOpenCam] = useState(false);
  const [fileType, setFileType] = useState();
  const [cntryCde, setCntryCde] = useState("");
  const [runPlayHandle, setRunPlayHandle] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [videoData, setVideoData] = useState([]); // Initialize with an empty array
  const [videoIdentity, setVideoIdentity] = useState(""); // Initialize with an empty array
  const [focusState, setFocusState] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // console.log(newPicUrl, "newpicccurl");
  // Callback function to add video data to the array
  const addVideoData = (url, duration) => {
    // console.log("videoData", duration);
    const randomString = Date.now().toString();
    const newData = [...videoData, { url, randomString, duration }];
    setVideoData(newData);
    // setRecordedVideoUrlSections(newData)

    if (duration === Infinity) {
      deleteVideoUrl(randomString);
      enqueueSnackbar("Video file metadata is  corrupt", {
        variant: "error",
      });
    }
  };
  const addVideoType = (val) => {
    const newData = [...fileType, val];
    setFileType(newData);
    // setRecordedVideoUrlSections(newData)
  };
  const { enqueueSnackbar } = useSnackbar();
  // console.log(videoData, "videoData");
  // const [counterStart, setCounterStart] = useState();
  // const [duration, setDuration] = useState();  //......
  const isMobile = useSelector((state) => state.responsive.isMobile);
  // console.log("openCam", openCam, getPermission);
  function onRequestAudioVideoPermissionsModal() {
    // if (getPermission) {
    //   setOpenModal(false);

    // }
    if (getPermissions) {
      setOpenCam(true);
    } else {
      setOpenModal(true);
    }
  }
  // console.log(newStep, "newStep");
  const videoOff = async () => {
    setIsVideoOff(true);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY;
  const s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
  });

  const [saveSenderVideo] = useMutation(SAVE_SENDER_VIDEO, {
    onCompleted(data) {
      // console.log(data, "success");
    },
  });

  const handleChange = (val, name) => {
    let cond =
      val &&
      val.trim() !== "" &&
      name === "phoneNumber" &&
      !/^[+]?\d+$/.test(val) &&
      val !== "+";
    // /^-?\d+$/
    // console.log(val, "Valuee", /^-?\d+$/.test(val), cond);
    if (!cond) {
      onChangeTextHandler(val, name, formstate, setFormstate);
      validateForm(name, val);
    } else {
      onChangeTextHandler(formstate[name] ?? "", name, formstate, setFormstate);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const handleCheckboxChangeMessage = (event) => {
    setIsCheckedMessage(event.target.checked);
  };

  const handleCheckboxChangeSms = (event) => {
    setIsCheckedSms(event.target.checked);
  };
  const handleCheckboxChangeEmail = (event) => {
    setIsCheckedEmail(event.target.checked);
  };

  useEffect(() => {
    if (isCheckedSms && isCheckedEmail) {
      setViaText("SmsAndEmail");
    } else if (!isCheckedSms && !isCheckedEmail) {
      setViaText("");
    } else if (isCheckedSms && !isCheckedEmail) {
      setViaText("Sms");
    } else if (!isCheckedSms && isCheckedEmail) {
      setViaText("Email");
    }
  }, [isCheckedSms, isCheckedEmail]);
  // console.log(isChecked, "isChecked");
  let arrForm = [
    {
      title: "Name",
      name: "name",
      required: true,
      type: "text",
      error: "Name field is blank",
      placeholder: "Grey Shulman",
    },

    {
      title: "Email",
      name: "email",
      neccessary: true,
      required:
        formstate?.phoneNumber && formstate?.phoneNumber?.trim() !== ""
          ? false
          : true,
      type: "email",
      error: "Email field is blank",
      errorEmail: "Email is invalid",
      placeholder: "abcd@gmail.com",
    },
    {
      title: "Phone Number",
      name: "phoneNumber",
      neccessary: true,
      required:
        formstate?.email && formstate?.email?.trim() !== "" ? false : true,
      type: "tel",
      error: "Mobile Number field is blank",
      errorPhone: "Mobile Number is invalid",
      placeholder: "5219460",
    },
    {
      title: (
        <LeftTopSubTitle
          isMobile={isMobile}
          style={{ marginBottom: isMobile ? "30px" : "1rem" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                name="testimonialOwnership"
              />
            }
            sx={{ marginRight: "0px" }}
          />
          <div
            style={{
              marginBottom: "2px",
            }}
          >
            Open to record a testimonial in return
          </div>
        </LeftTopSubTitle>
      ),
      type: "openRecord",
    },
    {
      title: (
        <LabelWrapperExt style={{ marginBottom: -5 }}>
          <div style={{ marginBottom: "5px" }}>
            {checked ? "Company's Name" : " "}
          </div>
          <FormControlLabel
            value="start"
            control={
              <IOSSwitch
                onChange={() => setChecked(!checked)}
                checked={checked}
                color="warning"
                labelPlacement="left"
              />
            }
            style={{
              marginTop: -10,
              justifyContent: "space-between",
              marginLeft: "0px",
              marginBottom: "14px",
              width: "100%",
            }}
            label={<LabelWrapperTwo>{"Work information"}</LabelWrapperTwo>}
            labelPlacement="start"
          />
        </LabelWrapperExt>
      ),
      type: "logical",
      name: "organization",
      placeholder: "Microsoft",
    },
    {
      title: checked && (
        <>
          Job's Title {/* <span style={{ color: "#666666" }}>i.e-Ceo</span> */}
        </>
      ),
      type: "logical",
      name: "position",
      placeholder: "CEO",
    },
  ];

  const displayForm = () => {
    return arrForm.map((el, idx) => {
      return (
        <div key={idx}>
          <LabelWrapper>{el?.title}</LabelWrapper>
          {(el?.type !== "logical" || (el?.type === "logical" && checked)) &&
            el?.type !== "openRecord" &&
            (el.name === "phoneNumber" ? (
              <PhoneNumberInput
                el={el}
                focusState={focusState}
                formstate={formstate}
                handleChange={handleChange}
                setFocusState={setFocusState}
                errorstate={errorstate}
                setCntryCde={setCntryCde}
                cntryCde={cntryCde}
                inputStyle={{ background: "#f9f9f9" }}
              />
            ) : (
              <InputWrapper
                mfocus={
                  focusState[el?.name] ||
                  (formstate[el?.name] && formstate[el?.name] !== "")
                }
                required={el?.required ?? false}
                type={el?.type ?? "text"}
                placeholder={el?.placeholder ?? ""}
                value={formstate[el?.name]}
                borderColor={errorstate[el?.name] && "#D93047"}
                onFocus={() =>
                  setFocusState({ ...focusState, [el?.name]: true })
                }
                onBlur={() =>
                  setFocusState({ ...focusState, [el?.name]: false })
                }
                onChange={(e) => handleChange(e.target.value, el?.name)}
              />
            ))}
          {errorstate[el?.name] && (
            <ErrorWrapper>{errorstate[el?.name] && "required"}</ErrorWrapper>
          )}
        </div>
      );
    });
  };

  const validateForm = (field = "", val = "") => {
    let truthValue = true;
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let phoneReg =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    let objError = { ...errorstate };

    if (field === "") {
      objError = {};
    } else {
      if (objError[field]) {
        delete objError[field];
        // console.log(objError, "ErrrorObj", field);
      }
    }
    setErrorstate(objError);

    let uArrForm = [...arrForm];
    let uformstate = field !== "" ? { [field]: val } : { ...formstate };
    if (field !== "") {
      uArrForm = arrForm.filter((el) => el.name === field);
    }
    for (let el of uArrForm) {
      if (
        el?.required &&
        (!uformstate[el?.name] || uformstate[el?.name].toString().trim() === "")
      ) {
        //add error.
        // alert(el?.error);
        objError[el?.name] = el?.error;
        truthValue = false;
      }
      if (
        el?.neccessary &&
        el?.type === "email" &&
        uformstate[el?.name] &&
        !emailReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorEmail;
        truthValue = false;
      }

      if (
        el?.neccessary &&
        el?.type === "tel" &&
        uformstate[el?.name] &&
        !phoneReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorPhone;
        truthValue = false;
      }
    }

    if (!truthValue) setErrorstate(objError);
    return truthValue;
  };
  const openModalQuestion = () => {
    setModalIsOpen(true);
  };

  const closeModalQuestion = () => {
    setModalIsOpen(false);
  };

  const addQuestion = () => {
    const newQuestion = {
      no: currentQuestionNo,
      text: currentQuestionText,
    };

    setQuestions([...questions, newQuestion]);
    setQuestionsArray([...questionsArray, currentQuestionText]);
    setCurrentQuestionNo(currentQuestionNo + 1);
    setCurrentQuestionText("");
    closeModalQuestion();
  };

  const deleteQuestion = (questionNo) => {
    const updatedQuestions = questions.filter(
      (question) => question.no !== questionNo
    );
    const updatedQuestionsArray = questionsArray.filter(
      (_, index) => index !== questionNo - 1
    );

    // Update question numbers
    const updatedQuestionsWithNewNumbers = updatedQuestions.map(
      (question, index) => ({
        ...question,
        no: index + 1,
      })
    );

    setQuestions(updatedQuestionsWithNewNumbers);
    setQuestionsArray(updatedQuestionsArray);

    const highestQuestionNo =
      updatedQuestionsWithNewNumbers.length > 0
        ? Math.max(
            ...updatedQuestionsWithNewNumbers.map((question) => question.no + 1)
          )
        : 1;

    setCurrentQuestionNo(highestQuestionNo);
  };

  // console.log(questionsArray, "questionsArray");
  const openForm = () => {
    // setIsVideoOff(true);
    setTimeout(() => setSetShowForm(true), 1000);
  };
  const closeForm = () => {
    setSetShowForm(false);
    // setIsVideoOff(false);
  };
  const handlePrevious = () => {
    setNewStep(newStep - 1);
    setStep(step - 1);
    setTrail(recordingData);
    // setIsVideoOff(false); // Uncomment this line if you want to toggle isVideoOff
  };
  const handleNext = () => {
    setNewStep(newStep + 1);
    setStep(step + 1);
    // setTrail(recordingData);
    // setIsVideoOff(false); // Uncomment this line if you want to toggle isVideoOff
  };

  // console.log("recordingData", recordingData);
  // console.log("trail", trail);
  const durationString = recordingData?.duration;
  const durationInSeconds = parseFloat(durationString);
  const durationInMilliseconds = durationInSeconds * 1000;

  // console.log(durationInSeconds, "duration in milliseconds");
  // console.log(durationInMilliseconds, "duration in milliseconds");
  const uploadVideo = async () => {
    if (!validateForm()) return false;

    setLoading(true);

    try {
      const addParamsArray = [];
      const videoIdentityNumber = `videoIdentity_${nanoid()}`;
      setVideoIdentity(videoIdentityNumber);

      let { videoUrl, duration } = recordingData;
      if (!videoUrl || !duration) return false;
      let addParams = Date.now();
      // console.log("addParams: ", addParams);
      const vid = await fetchResourceFromURI(videoUrl);
      const snapshoter = new VideoSnapshot(vid);
      const previewSrc = await snapshoter.takeSnapshot();
      setFileType(vid.type);
      const picUrlUpload = async (blob) => {
        const pixKey = `Image${addParams}.${blob.type.split("/")[1]}`;
        const pixResp = await s3
          .upload({
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            Key: pixKey,
            Body: blob,
          })
          .promise();
        // console.log("Image uploaded successfully", pixResp);
      };

      // Determine the file type
      const fileType = "image/jpeg";

      // Convert the image to JPEG format if it's not already
      if (fileType !== "image/jpeg") {
        const jpegBlob = await convertToJPEG(previewSrc);
        // console.log("jpegBlob", jpegBlob);
        await picUrlUpload(jpegBlob);
      } else {
        const response = await fetch(previewSrc);
        const blob = await response.blob();
        await picUrlUpload(blob);
      }

      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(vid);

      // Upload the video to S3
      const vidKey = `OriginalVideo${addParams}.${vid.type.split("/")[1]}`;
      const vidResp = await s3
        .upload({
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: vidKey,
          Body: vid,
        })
        .promise();
      // console.log("Video uploaded successfully", vidResp);

      // Create an object with addParams and duration and push it to addParamsArray
      // addParamsArray.push({
      //   url: addParams.toString() ,
      //   duration: parseInt(parseFloat(duration) * 1000), // Convert to milliseconds and then parse as an integer
      // });

      // Now, the addParamsArray contains objects with addParams and video duration.
      // console.log("V÷ideo Durations and addParams:", addParamsArray);

      // Save in Server...
      const objFormstate = { ...formstate };
      if (objFormstate.phoneNumber && objFormstate.phoneNumber.trim() === "") {
        objFormstate.phoneNumber = undefined;
      } else {
        objFormstate.phoneCode = cntryCde;
        objFormstate.phoneNumber = `${Number.parseInt(
          objFormstate.phoneNumber
        )}`;
      }
      if (objFormstate.email && objFormstate.email.trim() === "") {
        objFormstate.email = undefined;
      }
      // console.log(duration, "duration");
      let lead_id = "";
      if (queryParams instanceof Object) {
        const lid = queryParams.get("lid");
        lead_id = lid;
      }
      const resp3 = await saveSenderVideo({
        variables: {
          input: {
            senderUrl: addParams.toString(),
            senderDuration: parseFloat(duration),
            brandId: publicKey,
            ...objFormstate,
            questions: questionsArray,
            savedMessage: requestMessageText,
            openToRecord: isChecked,
            videoIdentityNumber: videoIdentityNumber,
            sendRequestVia: viaText,
            lead_id,
            originalVideoExtension: vid.type.split("/")[1],
          },
        },
      });
      // console.log("resp3", resp3);

      // Reset states and perform other actions
      resetState();
    } catch (err) {
      // console.log("Error:", err);
      enqueueSnackbar(err, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const convertToJPEG = async (previewSrc) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const img = new Image();
      img.src = previewSrc;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (jpegBlob) => {
            resolve(jpegBlob);
          },
          "image/jpeg",
          1
        );
      };
    });
  };

  const getVideoDuration = (videoElement) => {
    return new Promise((resolve) => {
      videoElement.addEventListener("loadedmetadata", () => {
        resolve(videoElement.duration);
      });
    });
  };

  const resetState = () => {
    setIsVideoOff(true);
    setRecordingData({});
    setSuccess(true);
    setFormstate({});
    setTrail({});
    // setTimeout(() => setSuccess(false), 9000);
    setIsVideoOff(false);
    setVidRate(0);
    setNewStep(1);
    setStep(1);
    setIsChecked(false);
    setIsCheckedSms(false);
    setIsCheckedEmail(false);
    // alert("Video Saved");
  };

  const mobileFormValidation = () => {
    if (validateForm()) {
      setStep(2);
      setNewStep(2);
      const formStateJson = JSON.stringify(formstate);

      ///replace the name
      setRequestMessageText(
        requestMessageText.replace("{{name}}", formstate.name)
      );
      // Save the form state in localStorage
      localStorage.setItem("formState", formStateJson);
    }
  };
  const runPlayHandler = () => {
    setRunPlayHandle(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log("formstate", formstate, "valueee");
  // console.log("newStep", newStep, step);
  // return <SuccessComp setClicked={setClicked} brandProps={brandProps} />;
  const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;
  const deleteVideoUrl = (randomString) => {
    const newData = videoData.filter(
      (data) => data.randomString !== randomString
    );
    setVideoData(newData);
    // const newData = [...videoData];
    // newData.splice(index, 1); // Remove the object at the given index
    // setVideoData(newData);
    // Update the current video URL to the last URL in recordedVideoUrlSections
    let lastVideoUrl = "";
    if (newData.length > 0) {
      // console.log("videoData", newData[newData.length - 1]);
      lastVideoUrl = newData[newData.length - 1].url;
    }

    // console.log("videoData", lastVideoUrl);
    // Update the current video URL to the last video URL
    setNewVideoUrl(newData.length > 0 ? lastVideoUrl : null);
  };
  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log(file, "filee");
      // addVideoType(file.type.split("/")[1])
      setFileType(file.type);
      // Convert the selected video to a Blob
      const blob = new Blob([file], { type: file.type });
      // Use video-snapshot to capture a frame from the video
      const snapshoter = new VideoSnapshot(file);
      const previewSrc = await snapshoter.takeSnapshot();
      if (file.type !== "image/jpeg") {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.src = previewSrc;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (jpegBlob) => {
              // Use the converted JPEG blob
              // console.log("jpegBlob", jpegBlob);
              setRecordedPicUrlSections([
                ...recordedPicUrlSections,
                URL.createObjectURL(blob),
              ]);

              setNewPicUrl(URL.createObjectURL(blob));
            },
            "image/jpeg",
            1
          ); // 1 indicates maximum quality
        };
      } else {
        // The image is already in JPEG format, so no conversion is needed
        const response = await fetch(previewSrc);
        const blob = await response.blob();
        setRecordedPicUrlSections([
          ...recordedPicUrlSections,
          URL.createObjectURL(blob),
        ]);
        setNewPicUrl(URL.createObjectURL(blob));
      }
      // Add the Blob to the recordedVideoUrlSections
      // Get video    duration
      const video = document.createElement("video");
      video.preload = "metadata";
      let vidDuration;
      video.onloadedmetadata = () => {
        // setVideoDuration(video.duration);
        vidDuration = video.duration;
        // console.log("Video duration: " + video.duration + " seconds");
        addVideoData(URL.createObjectURL(blob), vidDuration);

        setRecordingData({
          videoUrl: URL.createObjectURL(blob),
          duration: vidDuration,
        });
      };
      video.src = URL.createObjectURL(file);

      setNewVideoUrl(URL.createObjectURL(blob)); // Clear the selected video
      setRecordedVideoUrlSections([
        ...recordedVideoUrlSections,
        URL.createObjectURL(blob),
      ]);
    }
  };
  // console.log(
  //   recordedVideoUrlSections,
  //   "recordedVideoUrlSections",
  //   recordedPicUrlSections
  // );
  const getCountryFlag = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      let resObj = res.data instanceof Object ? res.data : {};
      let countryCode = resObj.country_code;
      let ctryCode = countryCodes.find(
        (el) =>
          String(el.country).trim().toLowerCase() ===
          String(countryCode).trim().toLowerCase()
      )?.code;
      if (ctryCode) setCntryCde(ctryCode);
    } catch {}
  };
  useEffect(() => {
    getCountryFlag();
  }, []);
  useEffect(() => {
    if (queryParams instanceof Object) {
      // console.log(queryParams, "query Params");
      const name = queryParams.get("name");
      const phoneNumber = queryParams.get("phone");
      const email = queryParams.get("email");

      setFormstate({ ...formstate, name, phoneNumber, email });
    }
  }, []);

  return (
    <>
      {success ? (
        // <SuccessComp setClicked={setClicked} brandProps={brandProps} />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "95%" : "92%",
              // display: "flex",
              margin: "0 auto",
              // alignItems: "center",
              position: "relative",
            }}
          >
            <RequestSuccessPage videoIdentity={videoIdentity} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "95%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: "0 auto",
            // height: isMobile ? "100%" : "100%",
          }}
        >
          <Wrapper
            style={{ margin: "0px", height: "100%" }}
            brandProps={brandProps}
            isMobile={isMobile}
          >
            {/* <TopWrapper></TopWrapper> */}
            <>
              <ContentWrapper isMobile={isMobile}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",

                    alignItems: "flex-start",
                    height: isMobile ? "100%" : "100%",
                  }}
                >
                  {step < 6 && (
                    <StepWrapper>
                      STEP <StepWrapperSpan>{step} </StepWrapperSpan>OF{" "}
                      <StepWrapperSpan>5 </StepWrapperSpan>
                    </StepWrapper>
                  )}

                  <RStep2
                    newStep={newStep}
                    step={step}
                    brandProps={brandProps}
                    setRecordingData={setRecordingData}
                    trail={trail}
                    isVideoOff={isVideoOff}
                    setNewStep={setNewStep}
                    isMobile={isMobile}
                    setGetPermission={setGetPermission}
                    setGetPermissions={setGetPermissions}
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    newVideoUrl={newVideoUrl}
                    newPicUrl={newPicUrl}
                    setNewVideoUrl={setNewVideoUrl}
                    setNewPicUrl={setNewPicUrl}
                    setRecordedVideoUrlSections={setRecordedVideoUrlSections}
                    setRecordedPicUrlSections={setRecordedPicUrlSections}
                    recordedPicUrlSections={recordedPicUrlSections}
                    recordedVideoUrlSections={recordedVideoUrlSections}
                    openCam={openCam}
                    setOpenCam={setOpenCam}
                    setRunPlayHandle={setRunPlayHandle}
                    runPlayHandle={runPlayHandle}
                    addVideoData={addVideoData}
                    videoData={videoData}
                    runPlayHandler={runPlayHandler}
                    onRequestAudioVideoPermissionsModal={
                      onRequestAudioVideoPermissionsModal
                    }
                    loading={loading}
                    handleVideoUpload={handleVideoUpload}
                  />

                  <RStep1
                    newStep={newStep}
                    step={step}
                    isMobile={isMobile}
                    displayForm={displayForm}
                    mobileFormValidation={mobileFormValidation}
                    loading={loading}
                  />
                  <RStep3
                    newStep={newStep}
                    step={step}
                    isMobile={isMobile}
                    requestMessageText={requestMessageText}
                    questions={questions}
                    formstate={formstate}
                    openModalQuestion={openModalQuestion}
                    setRequestMessageText={setRequestMessageText}
                    isCheckedMessage={isCheckedMessage}
                    handleCheckboxChangeMessage={handleCheckboxChangeMessage}
                    deleteQuestion={deleteQuestion}
                  />
                  <RStep4
                    newStep={newStep}
                    step={step}
                    isMobile={isMobile}
                    isCheckedSms={isCheckedSms}
                    handleCheckboxChangeSms={handleCheckboxChangeSms}
                    isCheckedEmail={isCheckedEmail}
                    handleCheckboxChangeEmail={handleCheckboxChangeEmail}
                  />
                  <RStep5
                    step={step}
                    newStep={newStep}
                    viaText={viaText}
                    requestMessageText={requestMessageText}
                    isMobile={isMobile}
                    formstate={formstate}
                    recordingData={recordingData}
                  />
                </div>

                <RStep2345
                  step={step}
                  newStep={newStep}
                  recordingData={recordingData}
                  loading={loading}
                  uploadVideo={uploadVideo}
                  handleNext={handleNext}
                  setStep={setStep}
                  setNewStep={setNewStep}
                  setTrail={setTrail}
                  isCheckedSms={isCheckedSms}
                  isCheckedEmail={isCheckedEmail}
                  videoData={videoData}
                />

                {/* modals */}
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModalQuestion}
                  contentLabel="Add Question Modal"
                  style={customStyles}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <RequestText
                      style={{
                        fontSize: "32px",
                        fontWeight: " 700",
                        lineHeight: "160%",
                        marginTop: "0px",
                        marginBottom: "0px",
                      }}
                    >
                      Enter Question {currentQuestionNo}
                    </RequestText>
                    <img
                      src={RemoveIcon}
                      style={{ cursor: "pointer", marginRight: "24px" }}
                      alt="modal-delete"
                      onClick={closeModalQuestion}
                    />
                  </div>

                  <QuestionTextarea
                    value={currentQuestionText}
                    onChange={(e) => setCurrentQuestionText(e.target.value)}
                  />
                  <div style={{ margin: "1rem auto", width: "100%" }}>
                    <ColorButton
                      title={"Add Question"}
                      width="100%"
                      background={"#1A1B24"}
                      onClick={addQuestion}
                    />
                  </div>
                  {/* <button onClick={closeModalQuestion}>Close</button> */}
                </Modal>
              </ContentWrapper>
            </>
          </Wrapper>
        </Box>
      )}
    </>
  );
};

export default ReviewComp;
