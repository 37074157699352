import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";

export const CopyReviewOverall = styled(Box)`
  display: flex;
  height: 54px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
export const CopyReview = styled(Box)`
  display: flex;
  height: 100%;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  background: #f1f3ec;
  width: 75%;
`;
export const CopyReviewText = styled(Typography)`
  color: #5d6272;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.15px; /* 72.497% */
`;
export const CopyBox = styled(Box)`
  cursor: pointer;
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 14px;
  background: white;
  width: 25%;
`;
export const CopyBoxText = styled(Typography)`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 10.15px; /* 72.497% */
`;
