import React from "react";
import Layout from "../../components/Layout";
import { Container, Text } from "./styled";
import P404SVG from "../../assets/images/4042.svg";
import ButtonCust from "../../components/ButtonCust";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SubHeader from "../../components/SubHeader";
import { TabTitle } from "../../utils/HeaderTitle";

export default function () {
  TabTitle(`Are you Lost?`);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const navigate = useNavigate();
  return (
    <>
      {isMobile && <SubHeader arrLink={[{ title: "Dashboard", link: "/" }]} />}
      <Container style={{ marginTop: isMobile && "-80px" }}>
        <img src={P404SVG} style={{ width: isMobile && "80%" }} />
        <Text isMobile={isMobile}>Looks like we’re in a tangled situation</Text>
        <ButtonCust title="Back to Dashboard" onClick={() => navigate("/")} />
      </Container>
    </>
  );
}
