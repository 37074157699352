import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import React, { useEffect, useState } from "react";

// import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.svg";
// import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.svg";

import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.js";
import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.js";
import EmptyWidget from "../../assets/widgetIcon/EmptyStateWidget.svg";
import EmptySection from "../../assets/widgetIcon/EmptyStateSection.svg";
import {
  AlignContainer,
  ButtonContainer,
  CheckLabel,
  Container,
  ControlDiv,
  DisplayDiv,
  DisplayDivTitle,
  DynamicDiv,
  DynamicSectionDiv,
  EmptyDiv,
  ImageDiv,
  IndicatorDiv,
  LeftDescription,
  LeftDiv,
  LeftSubTitle,
  LeftSubTitleDiv,
  LeftTitle,
  OnButton,
  PreviewButton,
  PreviewText,
  RadioItem,
  ReviewContainer,
  ReviewDiv,
  SectionDiv,
  SectionDivType,
  SectionHeadContainer,
  SectionHeader,
  SectionTitle,
  SubSectionHeader,
  SubTitle,
  SubTitleDiv,
  SubTitleDiv2,
  TabButton,
  TabDiv,
  TabText,
  Title,
  TopHead,
  TypeButton,
  TypeDiv,
  VideoDiv,
  WidgetButDiv,
} from "./styled";
import ButtonCust from "../../components/ButtonCust";
import ButtonReset from "../../components/Button";
import CopyIcon from "../../assets/icons/Copy.svg";
import MarkUpIcon from "../../assets/icons/MarkUp.svg";
import Layout from "../../components/Layout";
import { useSnackbar } from "notistack";
import { UPDATE_BRAND_WIDGET } from "../../graphql/mutations/brandMutations";
import { useMutation, useQuery } from "@apollo/client";
import ImageTransition from "../../components/TransitionImage";
import widgetComponent from "./widgetComponent";
import sectionComponent from "./sectionComponent";
import sectionDisplay from "./sectionDisplay";
import widgetDisplay from "./widgetDisplay";
import emailBadgeComponent from "./emailBadgeComponent";
import {
  APPROVED_VIDEOS_COUNT,
  GET_VIDEOS,
} from "../../graphql/queries/videosQueries";
import emailBadgeDisplay from "./emailBadgeDisplay.js";
import {
  parseObjectToString,
  parseStringToObject,
} from "../../utils/format.js";
import { GET_USER_BY_ID } from "../../graphql/queries/user.js";
import { useSelector } from "react-redux";
import { emailBadgeHtml } from "./emailBadgeHtml.js";
import { CopyButton, CopyText } from "../Installation/styled.js";
import socialsComponent from "./socialsComponent.js";
import socialsDisplay from "./socialsDisplay.js";
import ColorButton from "../../components/ColorButton/index.js";

export default function Customization({
  brandId,
  data,
  handleRefetch,
  isMobile,
  _id,
}) {
  const defaultObject = {
    position: data?.position ?? "bottom-left",
    visibility: data?.visibility ?? true,
    sectionHeader: data?.sectionHeader ?? "Review Section Header",
    paragraph:
      data?.paragraph ??
      "Paragraph associated to the review section can come here",
    sectionStyle: data?.sectionStyle ?? "classic",
    textAlignment: data?.textAlignment ?? "center",
    option:
      data?.option === "widgetsection" ? "widget" : data?.option ?? "widget", ///now to set it to the last option chosen
    widgetVisibility: data?.widgetVisibility ?? true,
    sectionVisibility: data?.sectionVisibility ?? true,
    widgetIcon: data?.widgetIcon ?? "4",
    widgetLeaveReview: data?.widgetLeaveReview ?? true,
    sectionLeaveReview: data?.sectionLeaveReview ?? true,
    sectionHeaderFontSize: data?.sectionHeaderFontSize ?? 1,
    sectionHeaderColor: data?.sectionHeaderColor ?? "#000000",
    sectionParaFontSize: data?.sectionParaFontSize ?? 1,
    sectionParaLineHeight: data?.sectionParaLineHeight ?? 1,
    sectionHeaderLineHeight: data?.sectionHeaderLineHeight ?? 1,
    sectionParaColor: data?.sectionParaColor ?? "#000000",
    widgetLeaveReviewText: data?.widgetLeaveReviewText ?? "Leave review",
    sectionLeaveReviewText: data?.sectionLeaveReviewText ?? "Leave review",
    sectionLeaveReviewFontSize: data?.sectionLeaveReviewFontSize ?? 1,
    widgetRating: data?.widgetRating ?? true,
    sectionRating: data?.sectionRating ?? true,

    widgetMeetSalesText: data?.widgetMeetSalesText ?? "Meet sales",
    buttonColor: data?.buttonColor ?? "#1A1B24",

    text1: data?.text1 ?? "",
    text2: data?.text2 ?? "",
    picture: data?.picture ?? undefined,
    telephone: data?.telephone ?? "",
    email: data?.email ?? "",
    text3: data?.text3 ?? "",
    socialLinks: parseStringToObject(data?.socialLinks, {}),
    testimonialVideos: parseStringToObject(data?.testimonialVideos, []),
    // introHeading: "",
    // introBody: "",
    // outroHeading: "",
    // outroBody: "",
    // socialVideos: [],
    // showIntroAndOutro: true,
    // socialSize: "ig-post",
    // brandName: data?.name ?? "brand",
    // brandColor: data?.brandColor ?? "#000000",

    brandId,
  };
  const [widgetState, setWidgetState] = useState(defaultObject);
  const [touched, setTouched] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [copiedVar, setCopiedVar] = useState(false);
  const [mouseState, setMouseState] = useState({});
  const profileData = useSelector((state) => state.auth);

  const {
    loading,
    error,
    data: userData,
  } = useQuery(GET_USER_BY_ID, {
    variables: { input: { userId: profileData?.user?._id } },
  });

  let socialLinkArray = [
    {
      type: "facebook",
      url: "https://asset.emailbadge.com/customize-image?icon=facebook&amp;color=%23858995&amp;size=md",
    },
    {
      type: "instagram",
      url: "https://asset.emailbadge.com/customize-image?icon=instagram&amp;color=%23858995&amp;size=md",
    },
    {
      type: "twitter",
      url: "https://asset.emailbadge.com/customize-image?icon=twitter&amp;color=%23858995&amp;size=md",
    },
    {
      type: "tiktok",
      url: "https://asset.emailbadge.com/customize-image?icon=tiktok&amp;color=%23858995&amp;size=md",
    },
    {
      type: "youtube",
      url: "https://asset.emailbadge.com/customize-image?icon=youtube&amp;color=%23858995&amp;size=md",
    },
    {
      type: "linkedin",
      url: "https://asset.emailbadge.com/customize-image?icon=linkedin&amp;color=%23858995&amp;size=md",
    },
    {
      type: "weixin",
      url: "https://asset.emailbadge.com/customize-image?icon=weixin&amp;color=%23858995&amp;size=md",
    },
  ];

  const {
    loading: approvedLoading,
    error: approvedError,
    data: approvedData,
  } = useQuery(GET_VIDEOS, {
    variables: { brandId: widgetState?.brandId },

    fetchPolicy: "cache-and-network",
  });

  // const [active, setActive] = useState("widget");
  const { enqueueSnackbar } = useSnackbar();

  const [updateBrand, { loading: loadingUpdate }] =
    useMutation(UPDATE_BRAND_WIDGET);

  const handleChange = (val, key) => {
    // if (key === "option") {
    //   setWidgetState({ ...defaultObject, [key]: val });
    // } else {
    //   setWidgetState({ ...widgetState, [key]: val });
    // }
    setWidgetState({ ...widgetState, [key]: val });
    setTouched(true);
  };

  const handleReset = () => {
    setWidgetState({ ...defaultObject, option: widgetState?.option });
    setTouched(false);
  };

  const bodyTagObject = isMobile
    ? {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        left: "0",
        top: "0",
        background: "rgba(0, 0, 0, 0.4)",
        zIndex: 9999999,
        display: modalOpen ? "block" : "none",
      }
    : {};

  const displayContent = () => {
    switch (widgetState?.option) {
      case "section":
        return sectionComponent();
      default:
        return widgetComponent();
    }
  };

  const displayRightContent = () => {
    switch (widgetState?.option) {
      case "section":
        return sectionDisplay();
      default:
        return widgetDisplay();
    }
  };

  const handleSubmit = async () => {
    try {
      let sentObject = { ...widgetState };
      Object.keys(widgetState).forEach((el) => {
        if (el.includes("FontSize") || el.includes("LineHeight")) {
          sentObject[el] = Number.parseFloat(sentObject[el]);
        }
        if (el.includes("socialLinks")) {
          sentObject[el] = parseObjectToString(sentObject[el]);
        }
        if (el.includes("testimonialVideos")) {
          sentObject[el] = parseObjectToString(sentObject[el], []);
        }
      });
      const { data } = await updateBrand({
        variables: {
          input: sentObject,
        },
      });

      enqueueSnackbar(`Brand successfully updated `, {
        variant: "success",
      });
      handleRefetch();
      setTouched(false);
    } catch (e) {
      // console.log(e);
    }
  };

  const copyHandleChange = () => {
    setCopiedVar(true);
    setTimeout(() => setCopiedVar(false), 2000);
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{ mb: 0 }}>
        <Grid item xs={12} md={12}>
          <Title isMobile={isMobile}>Customization</Title>
          <SubTitle>
            Customize how the Convert widget form and section form will look on
            your website/platform
          </SubTitle>
          {/* <SubTitleDiv>
            <LeftDiv>
              <LeftSubTitleDiv>Visibility on your platform</LeftSubTitleDiv>
              <IndicatorDiv active={widgetState?.visibility}>
                {widgetState?.visibility ? "ON" : "OFF"}
              </IndicatorDiv>
            </LeftDiv>
            <OnButton
              onClick={() =>
                handleChange(!widgetState?.visibility, "visibility")
              }
            >
              {" "}
              {widgetState?.visibility ? "Turn off" : "Turn on"}
            </OnButton>
          </SubTitleDiv> */}

          <LeftTitle>Layout form</LeftTitle>
          <TabDiv>
            <TabButton
              onClick={() => {
                handleChange("widget", "option");
              }}
              active={widgetState?.option === "widget"}
              className="button-custom"
              tabIndex="2"
            >
              <TabText>Web</TabText>
            </TabButton>
            {/* <TabButton
              onClick={() => handleChange("section", "option")}
              active={widgetState?.option === "section"}
            >
              <TabText>Section</TabText>
            </TabButton> */}
            {/* <TabButton
              onClick={() => handleChange("emailBadge", "option")}
              active={widgetState?.option === "emailBadge"}
            >
              <TabText>Email Badge</TabText>
            </TabButton> */}
            {/* <TabButton
              onClick={() => handleChange("socials", "option")}
              active={widgetState?.option === "socials"}
            >
              <TabText>Socials</TabText>
            </TabButton> */}
            {/* <TabButton
              onClick={() => handleChange("widgetsection", "option")}
              active={widgetState?.option === "widgetsection"}
            >
              <TabText>Widget & Section</TabText>
            </TabButton> */}
          </TabDiv>
        </Grid>
        {widgetComponent(widgetState, isMobile, handleChange, setModalOpen)}
        {widgetDisplay(widgetState, isMobile, bodyTagObject, setModalOpen)}
        {sectionComponent(widgetState, isMobile, handleChange, setModalOpen)}
        {sectionDisplay(widgetState, isMobile, bodyTagObject, setModalOpen)}
        {emailBadgeComponent(
          widgetState,
          isMobile,
          handleChange,
          setModalOpen,
          socialLinkArray,
          approvedData?.approvedVideosByBrandIdAndTrue ?? []
        )}

        {emailBadgeDisplay(
          widgetState,
          isMobile,
          bodyTagObject,
          setModalOpen,
          socialLinkArray,
          approvedData?.approvedVideosByBrandIdAndTrue ?? [],
          userData
        )}
        {socialsComponent(
          widgetState,
          isMobile,
          handleChange,
          setModalOpen,
          socialLinkArray,
          approvedData?.approvedVideosByBrandIdAndTrue ?? []
        )}
        {socialsDisplay(
          widgetState,
          isMobile,
          bodyTagObject,
          setModalOpen,
          socialLinkArray,
          approvedData?.approvedVideosByBrandIdAndTrue ?? [],
          userData
        )}

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            marginTop: "32px",
            paddingTop: 0,
            marginBottom: isMobile && "50px",
          }}
        >
          <ButtonContainer>
            {widgetState?.option !== "emailBadge" && (
              // <ButtonReset
              //   sx={{
              //     background: "#FFFFFF",
              //     border: touched ? "1px solid #1a1b24" : "1px solid #ECECF2",
              //     borderRadius: "10px",
              //     fontFamily: "Dm Sans",
              //     fontStyle: "normal",
              //     fontWeight: 600,
              //     fontSize: "16px",
              //     lineHeight: "20px",
              //     color: touched ? "#1a1b24" : "#8C8D91",
              //     // height: "52px",
              //     // width: "20%",
              //     // maxWidth: "183px",
              //     marginRight: 24,
              //     cursor: "pointer",
              //     ":hover": {
              //       color: "white",
              //       background: "#1a1b24",
              //     },
              //   }}
              //   onClick={handleReset}
              //   disabled={!touched}
              // >
              //   Reset
              // </ButtonReset>

              <ColorButton
                background={"white"}
                title="Reset"
                onClick={handleReset}
                disabled={!touched}
                hoverColor={"white"}
                innerStyle={{
                  border: "1px solid #1A1B24",
                  color: mouseState?.reset
                    ? "white"
                    : touched
                    ? "#1a1b24"
                    : "#8C8D91",

                  ":hover": {
                    background: !touched ? "white" : undefined,
                  },
                }}
                style={{ marginRight: "24px" }}
                buttonTextStyle={{
                  color: mouseState?.reset
                    ? "white"
                    : touched
                    ? "#1a1b24"
                    : "#8C8D91",
                  ":hover": {
                    background: !touched ? "white" : undefined,
                  },
                }}
                onMouseEnter={() =>
                  setMouseState({ ...mouseState, reset: true })
                }
                onMouseLeave={() =>
                  setMouseState({ ...mouseState, reset: false })
                }
              />
            )}
            {widgetState?.option === "emailBadge" && (
              <CopyToClipboard
                text={emailBadgeHtml(
                  widgetState,
                  userData,
                  socialLinkArray,
                  approvedData?.approvedVideosByBrandIdAndTrue ?? []
                )}
                onCopy={() => copyHandleChange()}
              >
                <ButtonCust
                  backgroundStyle={{
                    fill: copiedVar ? "#B8A9FF" : "#fff",
                  }}
                  title={copiedVar ? "Copied" : "Copy Badge"}
                  addedSvg={copiedVar ? MarkUpIcon : CopyIcon}
                />
              </CopyToClipboard>
            )}
            {widgetState?.option === "socials" && (
              <ButtonCust
                backgroundStyle={{
                  fill: !touched ? "white" : undefined,
                }}
                disabled={!touched}
                title="Download"
                onClick={handleSubmit}
              />
            )}
            {!["socials", "emailBadge"].includes(
              String(widgetState?.option)
            ) && (
              <ColorButton
                background={!touched ? "white" : "#1A1B24"}
                title="Save Changes"
                onClick={handleSubmit}
                innerStyle={{
                  border: "1px solid #1A1B24",
                  color: mouseState?.save ? "white" : undefined,
                }}
                buttonTextStyle={{
                  color: mouseState?.save ? "white" : undefined,
                }}
                disabled={!touched}
                hoverColor={"white"}
                onMouseEnter={() =>
                  setMouseState({ ...mouseState, save: true })
                }
                onMouseLeave={() =>
                  setMouseState({ ...mouseState, save: false })
                }
              />
              // <ButtonCust

              //   backgroundStyle={{
              //     fill: !touched ? "white" : undefined,
              //   }}
              //   txtStyle={{ fill: !touched ? "#1a1b24" : "white" }}
              //   disabled={!touched}
              //   title="Save Changes"
              //   onClick={handleSubmit}
              // />
            )}
          </ButtonContainer>
        </Grid>
      </Grid>
    </Container>
  );
}
