import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const InvitationContainer = styled(Box)`
  background: white;
  width: 100%;
  margin-bottom '50px'
`;

export const ContentContainer = styled(Box)`
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  display: flex;
`;

export const ContentSubContainer = styled(Box)`
  width: ${(props) => (props?.isMobile ? "90%" : "50%")};
  margin-bottom: ${(props) => (props?.isMobile ? "80px" : undefined)};
`;
export const JustDiv = styled(Box)``;

export const ContentTitle = styled(Typography)`
  // font-family: "Dm Sans";
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  color: #1a1b24;
`;

export const SelectTitle = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  opacity: 0.6;
`;
export const Description = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d6272;
  margin-bottom: 30px;
`;

export const SubTitle = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
`;

export const MobileViewDiv = styled(Box)`
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 15px;
  border: 1px solid #d4dae1;
`;

export const MobileViewInnerDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;
export const MobileViewTitle = styled(Box)`
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: capitalize;
  margin-top: 20px;
`;

export const MobileViewEmail = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const PlainText = styled(Box)`
  color: #858995;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
