import React from "react";
import { ArrowBackIos } from "@mui/icons-material";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import ArrowLongLeft from "../../assets/icons/ArrowLongLeft.svg";
import { useSelector } from "react-redux";
import {
  FlexHeadContainer,
  HeaderContainer,
  Item,
  LastItem,
  LeftHeaderText,
} from "./styled";

/**
 * Header component for displaying a header with back navigation and links.
 *
 * @param {Object} props - Component props.
 * @param {string} props.btnTitle - Title for a button, if present.
 * @param {string} props.btnLink - Link to navigate when the button is clicked.
 * @param {string} props.menuLink - Link to navigate when the menu button is clicked.
 * @param {Array} props.arrLink - Array of link objects with 'title' and 'link' properties.
 */
export default function Header({
  btnTitle,
  btnLink,
  menuLink,
  noLinkToLast = true,
  arrLink = [{ title: "Dashboard", link: "/" }],
}) {
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state.responsive.isMobile);

  /**
   * Click handler for the arrow button. Navigates to the provided 'menuLink' or goes back.
   */
  const handleArrowClick = () => {
    if (menuLink) {
      navigate(`/?menu=${menuLink}`);
    } else {
      navigate(-1);
    }
  };

  /**
   * Render links based on the 'arrLink' prop.
   *
   * @returns {JSX.Element} An array of link elements.
   */
  const renderLinks = () => {
    return arrLink.map((el, idx, arr) => {
      if (isMobile && idx > 0) return null;

      const isLastItem = arr.length - 1 === idx;

      return (
        <div
          key={`C${idx}`}
          onClick={() =>
            !(isLastItem && noLinkToLast) && el.link && navigate(el.link)
          }
          style={{ display: "flex", flexDirection: "row" }}
        >
          {idx > 0 && (
            <ArrowBackIos
              fontSize="sm"
              style={{
                // marginTop: 2,
                marginLeft: 9.72,
                marginRight: 10,
              }}
            />
          )}
          {!isMobile && (
            <>
              {isLastItem && noLinkToLast ? (
                <LastItem>{el.title}</LastItem>
              ) : (
                <Item>{el.title}</Item>
              )}
            </>
          )}
        </div>
      );
    });
  };

  return (
    <HeaderContainer>
      <FlexHeadContainer>
        <LeftHeaderText>
          <img
            src={ArrowLongLeft}
            onClick={handleArrowClick}
            style={{
              marginRight: 16,
              //  marginTop: -2,
              cursor: "pointer",
            }}
            alt="Back"
          />
          {renderLinks()}
        </LeftHeaderText>
        {btnTitle && (
          <Button
            onClick={() => navigate(btnLink)}
            sx={{
              ":hover": {
                border: "1px solid #1a1b24",
              },
            }}
          >
            {btnTitle}
          </Button>
        )}
      </FlexHeadContainer>
    </HeaderContainer>
  );
}
