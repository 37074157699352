import { gql } from "@apollo/client";

export const WORKFLOW = gql`
  fragment WorkflowFields on Workflow {
    _id
    name
    edges
    nodes
    isPublished
    brand {
      _id
      brandId
    }
  }
`;