import styled from "styled-components";

export const CounterWrapper = styled.div`
  color: #8d6cff;
  font-size: 4em;
  font-weight: bold;
  text-align: center;
  margin-left :1rem;
`;

export const LabelWrapper = styled.div`
  color: black;
  font-size: 3em;
  font-weight: bold;
  text-align: center;
`;
