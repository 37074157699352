import React, { useState } from "react";
import { VideoCont } from "./styled";
import {
  imgFormat,
  vidFormat,
  ellipsis,
  getTitle,
} from "../../../utils/format";
import VideoPlay from "../../../assets/icons/VideoPlay.svg";
import { Box, IconButton } from "@mui/material";
import Description from "./Description";

export default function ({
  item,
  top,
  angle,
  setPlayVid,
  style = {},
  height,
  width,
  backgroundColor,
}) {
  const { url, rating, position, name } = item;
  const picUrl = imgFormat(url, "", item);
  const videoUrl = vidFormat(url, "", item);
  // generate thumbnail image to use as background image from the video url which is a link like this ${process.env.REACT_APP_AWS_S3_URL_SERVING}Video1695218675492.mp4

  // console.log(name)
  return (
    <>
      <IconButton
        style={{
          padding: "0",
          cursor: "pointer",
          ...style,
        }}
      >
        <Box
          style={{
            width: width ?? "70px",
            height: height ?? "50px",
            // backgroundImage: `url(${picUrl})`,
            backgroundColor: backgroundColor ?? "#D4DAE1",
            borderRadius: "7px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflow: "hidden",
            ...style,
          }}
        >
          <Box
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              borderRadius: "7px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              //  backgroundColor:"rgba(0,0,0,0.4)"
            }}
            onClick={() => setPlayVid(videoUrl)}
          >
            <img
              src={VideoPlay}
              style={{
                // width: "70%",
                height: "50%",
                // paddingTop: "2px",
                position: "absolute",
                zIndex: 20,
              }}
              alt="video img"
            />
            <img
              src={picUrl}
              style={{ position: "absolute", width: width ?? "50px" }}
              alt=""
            />
          </Box>
        </Box>
      </IconButton>
    </>
  );
}
