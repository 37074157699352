import React from "react";
import { DisplayDiv, DisplayDivTitle, EmptyDiv } from "./styled";
import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.js";
import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.js";
import EmptyWidget from "../../assets/widgetIcon/EmptyStateWidget.svg";
import CloseIcon from "../../assets/images/Close.svg";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { Box, Grid } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import IntroImage from "../../assets/images/light.svg";
import OutroImage from "../../assets/images/Shappattaaa.svg";
import Prev from "../../assets/images/prev.svg";
import Next from "../../assets/images/next.svg";
import Heart from "../../assets/icons/Heart.svg";
import BookmarkSimple from "../../assets/icons/BookmarkSimple.svg";
import ChatCircle from "../../assets/icons/ChatCircle.svg";
import PaperPlaneTilt from "../../assets/icons/PaperPlaneTilt.svg";
import { useSelector } from "react-redux";
import {
  BannerBox,
  BannerBoxText,
  UserDetailsImage,
} from "../UserDashboard/styled";
import VideoComp from "../../components/Main/SectionComp/videoComp";
import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
export default (
  widgetState,
  isMobile,
  bodyTagObject,
  setModalOpen,
  socialLinkArray,
  approvedVideos,
  userData
) => {
  const [slideVideos, setSlideVideos] = useState(widgetState?.socialVideos);
  const [socialSize, setSocialSize] = useState(widgetState?.socialSize);
  useEffect(() => {
    setSlideVideos(widgetState?.socialVideos);
    setSocialSize(widgetState?.socialSize);
  }, [widgetState?.socialVideos, widgetState?.socialSize]);

  const pictureUrl = () => {
    if (widgetState?.picture || userData?.findUserById?.thumbnail) {
      return `${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${
        widgetState?.picture ?? userData?.findUserById?.thumbnail
      }.jpeg`;
    }
    return null;
  };
  const justifyPosition = () => {
    switch (widgetState?.position) {
      case "bottom-right":
        return "flex-end";
      default:
        return "flex-start";
    }
  };
  // console.log("socialVideos", widgetState?.socialVideos, slideVideos);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    prevArrow: (
      <img
        src={Prev}
        alt="user"
        style={{
          width: "48px !important",
          height: "48px   !important",
          // borderRadius: "50%",
        }}
      />
    ),

    nextArrow: (
      <img
        src={Next}
        alt="user"
        style={{
          width: "48px !important",
          height: "48p !important",
          // borderRadius: "50%",
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Define CSS classes or inline styles for different social sizes
  const containerStyles = {
    "ig-post": {
      aspectRatio: 1 / 1,
      backgroundColor: "black",
      width: "100%",
      // Add any other styles for 'ig-post' size
    },
    "ig-story": {
      aspectRatio: 9 / 16,
      backgroundColor: "black",
      width: "100%", // Update height for 'ig-story'
      // Add any other styles for 'ig-story' size
    },
    "tw-post": {
      maxWidth: "1200px", // Update maxWidth for 'tw-post'
      width: "100%",
      // Add any other styles for 'tw-post' size
    },
    "fb-post": {
      maxWidth: "1200px",
      width: "100%",
      // Add any other styles for 'fb-post' size
    },
    "li-post": {
      maxWidth: "1200px",
      width: "100%",
      // Add any other styles for 'li-post' size
    },
  };

  // Update the social size and apply styles when it changes
  const handleSocialSizeChange = (newSize) => {
    setSocialSize(newSize);
  };
  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("socials") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0, ...bodyTagObject }}>
        <DisplayDiv
          right
          isMobile={isMobile}
          preview
          // style={{ padding: "32px" }}
        >
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <DisplayDivTitle
              isMobile={isMobile}
              style={{ position: "relative" }}
              //   style={{ position: "relative", zIndex: 9999 }}
            >
              <div>Preview</div>
              {isMobile && (
                <div>
                  <img src={CloseIcon} onClick={() => setModalOpen(false)} />
                </div>
              )}
            </DisplayDivTitle>
            <div
              style={{
                //   height:"542px",
                width: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                  width: "100%",
                  gap: "10px",
                  height: "72.2px",
                  backgroundColor: "white",
                  borderRadius: "19.449px 19.449px 0px 0px",
                }}
              >
                <Box>
                  <BannerBox
                    style={{
                      width: "38px",
                      height: "38px",
                      padding: "2px 8px",
                      borderRadius: "6px",
                    }}
                    backgroundColor={widgetState?.brandColor}
                  >
                    <BannerBoxText
                      style={{
                        height: "17px",
                        fontSize: "18px",
                        lineHeight: "17px",
                      }}
                    >
                      {widgetState?.brandName.charAt(0) || ""}
                    </BannerBoxText>
                  </BannerBox>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    // paddingLeft: "8px",
                    flexDirection: "column",
                  }}
                >
                  <p
                    style={{
                      lineHeight: "normal",
                      margin: 0,

                      fontWeight: "700",
                      fontStyle: "normal",
                      fontFamily: "Dm Sans",
                      fontSize: "13.8px",
                      color: "#2D2936",
                    }}
                  >
                    {widgetState?.brandName}
                  </p>
                  <p
                    style={{
                      lineHeight: "120%",
                      margin: 0,

                      fontWeight: "500",
                      fontStyle: "normal",
                      fontFamily: "Dm Sans",
                      fontSize: "12.267px",
                      color: "#2D2936",
                    }}
                  >
                    Recrowdly
                  </p>
                </Box>
              </div>
              <Slider
                {...settings}
                style={{
                  width: "100%",
                }}
              >
                {widgetState?.showIntroAndOutro && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      width: "400px",
                      // height: "542px",
                      boxShadow:
                        "0px 5.55699px 19.44947px 0px rgba(182, 182, 182, 0.25)",
                      //   padding: "32px",
                      backgroundColor: "white",
                      borderRadius: "19.449px",
                    }}
                  >
                    <div
                      className="box"
                      style={{
                        width: "100%",
                        height: "413.996px",
                        borderRadius: "8px",
                        position: "relative",
                        backgroundImage: `url(${IntroImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: "32px",
                      }}
                    >
                      <p
                        style={{
                          //   marginBottom: "0px",
                          color: "#000",
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Dm Sans",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "105%",
                        }}
                      >
                        {widgetState?.introHeading}
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#000",
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Dm Sans",
                          fontSize: "28px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "105%",
                        }}
                      >
                        {widgetState?.introBody}
                      </p>
                    </div>
                  </div>
                )}

                {slideVideos?.map((video, index) => {
                  return (
                    <div key={index} style={{ width: "100%" }}>
                      <div
                        key={index}
                        style={{ ...containerStyles[socialSize] }}
                      >
                        {/* <img
                          style={{
                            width: "100%",
                              height: "100%",
                            // borderRadius: "8px",
                            objectFit: "cover",
                          }}
                          src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${video?.url}.jpeg`}
                          alt={video.name}  /> */}
                        <video
                          style={{ width: "100%", height: "100%" }}
                          controls
                        >
                          <source
                            src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${video?.url}.mp4`}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </div>
                  );
                })}

                {widgetState?.showIntroAndOutro && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      width: "400px",
                      // height: "542px",
                      boxShadow:
                        "0px 5.55699px 19.44947px 0px rgba(182, 182, 182, 0.25)",
                      //   padding: "32px",
                      backgroundColor: "white",
                      borderRadius: "19.449px",
                    }}
                  >
                    <div
                      className="box"
                      style={{
                        width: "100%",
                        height: "413.996px",
                        borderRadius: "8px",
                        position: "relative",
                        backgroundImage: `url(${OutroImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display: "flex",
                        // alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: "32px",
                      }}
                    >
                      <p
                        style={{
                          //   marginBottom: "0px",
                          color: "#000",
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Dm Sans",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "105%",
                        }}
                      >
                        {widgetState?.outroHeading}
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#000",
                          leadingTrim: "both",
                          textEdge: "cap",
                          fontFamily: "Dm Sans",
                          fontSize: "28px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "105%",
                        }}
                      >
                        {widgetState?.outroBody}
                      </p>
                    </div>
                  </div>
                )}
              </Slider>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15.61px 16px 13.234px 16px",
                  width: "100%",
                  justifyContent: "space-between",
                  height: "50px",
                  backgroundColor: "white",
                  borderRadius: "0px 0px 19.449px 19.449px",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={Heart}
                    alt="user"
                    style={
                      {
                        // width: "38px",
                        // height: "38px",
                        // borderRadius: "50%",
                      }
                    }
                  />
                  <img
                    src={ChatCircle}
                    alt="user"
                    style={
                      {
                        // width: "38px",
                        // height: "38px",
                        // borderRadius: "50%",
                      }
                    }
                  />
                  <img
                    src={PaperPlaneTilt}
                    alt="user"
                    style={
                      {
                        // width: "38px",
                        // height: "38px",
                        // borderRadius: "50%",
                      }
                    }
                  />
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={BookmarkSimple}
                    alt="user"
                    style={
                      {
                        // width: "38px",
                        // height: "38px",
                        // borderRadius: "50%",
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </DisplayDiv>
      </Grid>
    )
  );
};
