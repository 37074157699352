import React from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../components/Spinner";
import { GET_SINGULAR_VIDEO } from "../../graphql/queries/videosQueries";
import BrandInfo from "../../components/BrandInfo";
import { APPROVE_VIDEO } from "../../graphql/mutations/videoMutation";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function Video() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_SINGULAR_VIDEO, {
    variables: { input: { videoId: id } },
  });

  const [approveVideo] = useMutation(APPROVE_VIDEO, {
    variables: { input: { videoId: data?.video?._id } },
    refetchQueries: [
      { query: GET_SINGULAR_VIDEO, variables: { input: { videoId: id } } },
    ],
  });

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleWatchVideo = () => {
    setIsModalOpen(true);
  };

  const handleAfterOpenModal = () => {
    const videoPlayer = document.getElementById("videoPlayer");
    if (videoPlayer) {
      videoPlayer.play();
    }
  };
  if (error) return <p>Something Went Wrong</p>;

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mx-auto w-100 card p-5  mt-5">
          <Link to="/" className="btn btn-light btn-sm w-25 d-inline ms-auto">
            Back
          </Link>
          <h1>Video Information</h1>
          <h5>Url : {data.video.url}</h5>
          <p> Title : {data.video.title}</p>
          <p className="lead">Description : {data.video.description}</p>
          <p className="lead">
            Approved : {data.video.approved ? "Approved" : "Not Approved"}
          </p>
          <div className="d-flex">
            <button
              onClick={approveVideo}
              className=" w-25 card p-2  mt-5 d-flex justify-content-center align-items-center"
            >
              {data.video.disapproved ? "Disapprove" : "Approve"} Video
            </button>
            <button
              onClick={handleWatchVideo}
              className="bg-danger text-white w-25 card p-2 mt-5 d-flex justify-content-center align-items-center"
            >
              Watch Video
            </button>
          </div>
          <BrandInfo brand={data.video.brand} />
          <Modal isOpen={isModalOpen} onAfterOpen={handleAfterOpenModal}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{data.video.title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </button>
              </div>
              <div className="modal-body">
                <video id="videoPlayer" width="100%" height="auto" controls>
                  <source
                    src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${data.video.url}.mp4`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
