import React, { useState } from "react";
import { LeftTopSubTitle, StepTitle } from "../Testimonial/ReviewComp/styled";
import ReviewSuccess from "../../assets/icons/ReviewSuccess.svg";
import Copy from "../../assets/icons/Copy.svg";
import Attachment from "../../assets/icons/Attachment.svg";
import Snackbar from "@mui/material/Snackbar";
import {
  CopyBox,
  CopyBoxText,
  CopyReview,
  CopyReviewOverall,
  CopyReviewText,
} from "./styled";
function RequestSuccessPage({ videoIdentity, dontShow }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_CLIENT_URL}/send_testimonial/${videoIdentity}`
    );
    setOpenSnackbar(true);
    setSnackbarMessage(`Link Copied to Clipboard`);
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        alignItems: "center",
        flexDirection: "column",
        // marginBottom:"5rem"
        maxWidth: "700px",
        margin: "0 auto",
      }}
    >
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      <img src={ReviewSuccess} alt="review-success" />
      <StepTitle style={{ fontSize: "24px" }}>
        Request sent Successfully
      </StepTitle>
      {!dontShow && (
        <>
          <CopyReviewOverall>
            <CopyReview>
              <img src={Attachment} alt="Attachment" />
              <CopyReviewText>
                {process.env.REACT_APP_CLIENT_URL}/send_testimonial/
                {videoIdentity}
              </CopyReviewText>
            </CopyReview>
            <CopyBox onClick={handleCopyLink}>
              <CopyBoxText>Copy</CopyBoxText>
              <img src={Copy} alt="Copy" />
            </CopyBox>
          </CopyReviewOverall>

          <LeftTopSubTitle style={{ fontSize: "13px" }}>
            {" "}
            This link will redirect the receiver to the review page.
          </LeftTopSubTitle>
        </>
      )}
    </div>
  );
}

export default RequestSuccessPage;
