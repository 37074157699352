import React from "react";
import {
  Wrapper,
  TopWrapper,
  LeftTopTitle,
  LeftTopWrapper,
  LeftTopSubTitle,
  RightTopWrapper,
  MinimizeWrapper,
  CloseWrapper,
  SuccessWrapper,
} from "../ReviewComp/styled";
import { ContentWrapper, MessageWrapper } from "./styled";
import Lottie from "react-lottie";
import animationData2 from "../../../lotties/success.json"; // Assuming you've imported the correct animation data file
import animationData from "../../../lotties/HighFive.json";
import ReactPlayer from "react-player";
import SpinnerBig from "../../SpinnerBig";

/**
 * Component to display a success message.
 * @param {Object} props - Component properties.
 */
const SuccessComp = ({ setClicked, brandProps }) => {
  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} style={{ height: "100%" }} />;

  return (
    <SuccessWrapper brandProps={brandProps}>
      <TopWrapper>
        <LeftTopWrapper>{/* LeftTopContent goes here */}</LeftTopWrapper>
        <RightTopWrapper>
          <CloseWrapper onClick={() => setClicked()}>Close</CloseWrapper>
        </RightTopWrapper>
      </TopWrapper>

      <ContentWrapper>
        <Lottie
          options={defaultOptions}
          style={{ width: "400px", height: "400px" }}
        />

        <MessageWrapper>Review sent successfully</MessageWrapper>
      </ContentWrapper>
    </SuccessWrapper>
  );
};

export default SuccessComp;
