import {
  Modal,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@mui/material";
import { ModalContainer, StyledAuthButton } from "../CampaignList/styled";

import MailList from "./mailList";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_NEW_SALESWORKFLOW,
  UPDATE_SALESWORKFLOW,
} from "../../graphql/mutations/salesApp";
import { useSnackbar } from "notistack";
import { FETCH_JOB_CATEGORIES } from "../../graphql/queries/salesApp";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import { EmailContainer, Tag, RemoveButton, Input } from "./styled";

const defaultsTimes = {
  from: "00:00",
  to: "23:59",
};
export default ({ open, setOpen, formstate, setFormstate, refetch }) => {
  const { data: dataJobCategories } = useQuery(FETCH_JOB_CATEGORIES);
  const { enqueueSnackbar } = useSnackbar();
  const [addNewSalesWorkflow] = useMutation(ADD_NEW_SALESWORKFLOW);
  const [updateSalesWorkflow] = useMutation(UPDATE_SALESWORKFLOW);
  const [loading, setLoading] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [timeValues, setTimeValues] = useState({
    from: null,
    to: null,
  });
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      setTimeValues({
        from: null,
        to: null,
      });
    }
  }, [open]);
  // console.log(timeValues, "timeValues");

  useEffect(() => {
    let timeout;
    if (showEmailError) {
      timeout = setTimeout(() => {
        console.log("timeout");
        setShowEmailError(false);
      }, 2000);
    }

    return clearTimeout(timeout);
  }, [showEmailError]);

  const setMailId = (val) => {
    setFormstate({ ...formstate, mailId: val });
  };

  const handleSubmit = async () => {
    try {
      if (!formstate?.fromEmails || !formstate?.fromEmails?.length) {
        enqueueSnackbar("enter atleast one sender email", {
          variant: "error",
        });
        return;
      }

      let mailContent = formstate.mailId.filter(
        (content) =>
          content.id && content.subject && String(content.subject).trim() !== ""
      );
      if (mailContent.length === 0) {
        enqueueSnackbar("No Content", {
          variant: "error",
        });
        return;
      }

      if (!formstate.jobCategory) {
        enqueueSnackbar("No Job Category", {
          variant: "error",
        });
        return;
      }

      if (!formstate.step) {
        enqueueSnackbar("No Step", {
          variant: "error",
        });
        return;
      }

      // if (
      //   !validateTime(
      //     timeValues.from || (formstate?.from ?? defaultsTimes.from),
      //     timeValues.to || (formstate?.to ?? defaultsTimes.to)
      //   )
      // ) {
      //   enqueueSnackbar("invalid time range", {
      //     variant: "error",
      //   });
      //   return;
      // }

      let arrSubject = [],
        arrFromEmail = [],
        arrCardId = [];
      // console.log(mailContent);
      for (let content of mailContent) {
        arrSubject.push(String(content.subject).trim());
        arrFromEmail.push(String(content.fromEmail).trim());
        arrCardId.push(content.id);
      }
      ///server
      const { step, jobCategory, fromEmails } = formstate;

      if (formstate._id) {
        const { data, errors } = await updateSalesWorkflow({
          variables: {
            input: {
              fromEmails,
              subjects: arrSubject,
              templates: arrCardId,
              step: parseInt(step),
              jobCategory,
              from: timeValues.from || defaultsTimes.from,
              to: timeValues.to || defaultsTimes.to,
              _id: formstate._id,
            },
          },
        });
        if (!data && errors?.[0]?.message) {
          enqueueSnackbar(errors[0].message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Updated Successfully", {
            variant: "success",
          });
        }
      } else {
        const { data, errors } = await addNewSalesWorkflow({
          variables: {
            input: {
              fromEmails,
              subjects: arrSubject,
              templates: arrCardId,
              from: timeValues.from || defaultsTimes.from,
              to: timeValues.to || defaultsTimes.to,
              step: parseInt(step),
              jobCategory,
            },
          },
        });
        if (!data && errors?.[0]?.message) {
          enqueueSnackbar(errors[0].message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("Added Successfully", {
            variant: "success",
          });
        }
      }

      setFormstate({ mailId: [] });
      handleClose();
      refetch();
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.includes(",") || value.includes(" ") || value.includes(";")) {
      return;
    }
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (
      (e.key === "Enter" || e.key === "," || e.key === " " || e.key === ";") &&
      inputValue.trim()
    ) {
      let emailReg =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailReg.test(inputValue.trim())) {
        setShowEmailError(true);
        return;
      }
      setFormstate({
        ...formstate,
        fromEmails: [...(formstate?.fromEmails ?? []), inputValue.trim()],
      });
      setShowEmailError(false);
      setInputValue("");
    }
  };

  const removeEmail = (index) => {
    setFormstate({
      ...formstate,
      fromEmails: formstate?.fromEmails?.filter((_, i) => i !== index),
    });
  };
  // console.log(dataJobCategories, "FFstateCategories");
  // console.log(formstate, "FFstateCategories");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        style={{
          // width: "800px",
          width: "680px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            {formstate?._id ? "Edit Step" : "Create New Step"}
          </Typography>
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            display: "flex",
            gap: "20px",
          }}
        >
          <TextField
            // error={errors?.["subject"]}
            label="Step"
            type="number"
            required
            sx={{
              minWidth: 300,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setFormstate({ ...formstate, step: e.target.value })
            }
            value={formstate.step}

            // helperText={errors?.["subject"]?.message}
          />

          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">Job Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Job Category"
              onChange={(e) =>
                setFormstate({ ...formstate, jobCategory: e.target.value })
              }
              value={formstate.jobCategory}
            >
              <MenuItem></MenuItem>
              {(dataJobCategories?.fetchJobCategories instanceof Array
                ? dataJobCategories?.fetchJobCategories
                : []
              ).map((elm) => (
                <MenuItem key={elm.name} value={elm.name}>
                  {elm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ pb: 2 }}>
          <EmailContainer sx={{ py: "8px !important" }}>
            {formstate?.fromEmails?.map((email, index) => (
              <Tag key={index} isInvalid={!email.includes("@")}>
                {email}
                <RemoveButton onClick={() => removeEmail(index)}>
                  ×
                </RemoveButton>
              </Tag>
            ))}
            <Input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Add from emails..."
            />
          </EmailContainer>
        </Box>

        {showEmailError && (
          <Typography color={"red"} sx={{ fontSize: "12px" }}>
            Email format is not valid
          </Typography>
        )}
        <MailList value={formstate.mailId} setValue={setMailId} />
        <Box
          sx={{
            position: "absolute",
            background: "white",
            px: 2,
            bottom: 0,
            right: 0,
            width: "100%",
          }}
        >
          <StyledAuthButton
            //   type="submit"
            //   disabled={loading}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "Loading..." : formstate?._id ? "Update" : " Submit"}
          </StyledAuthButton>
        </Box>

        {/* <Interpolation /> */}
      </ModalContainer>
    </Modal>
  );
};
