import React from "react";
import {
  ProgressBarContainer,
  StepContainer,
  StepLabel,
  StepNumber,
} from "../pages/OrganizationBrands/styled";
import { StepWrapper } from "./Testimonial/ReviewComp/styled";
import StepActive from "../assets/images/FullStepIcon.svg";
import StepInActive from "../assets/images/EmptyStepIcon.svg";

function ProgressBar({ steps, activeStep, isMobile }) {
  let WrapperDiv = isMobile ? StepWrapper : ProgressBarContainer;
  return (
    <WrapperDiv>
      {steps.map((step, index) =>
        isMobile ? (
          <img src={index <= activeStep ? StepActive : StepInActive} />
        ) : (
          <StepContainer key={index}>
            <StepNumber isActive={index === activeStep}>{index + 1}</StepNumber>
            <StepLabel>{step}</StepLabel>
            {/* <StepLabel isActive={index === activeStep}>{step}</StepLabel> */}
          </StepContainer>
        )
      )}
    </WrapperDiv>
  );
}

export default ProgressBar;
