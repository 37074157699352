// src/store/menu.js
import { createSlice } from "@reduxjs/toolkit";

const checkSlice = createSlice({
  name: "menu",
  initialState: {
    randomNumber: 0,
    openDialog: false,
  },
  reducers: {
    setRand: (state, action) => {
      state.randomNumber = action.payload.randomNumber;
    },
    openStatus: (state, action) => {
      state.openDialog = action.payload.openDialog;
    },
  },
});

export const { setRand, openStatus } = checkSlice.actions;
export const checkReducer = checkSlice.reducer;
