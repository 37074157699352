import styled from 'styled-components';

export const DashboardMainFrame = styled.div`{
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}`

export const DashboardSubFrame = styled.div`{
  background-color: #fafafa;
  // height: 1009px;
  // position: relative;
  // width: 1440px;
  @media (max-width: 765px) {
    overflow: hidden;
    width: 100%;
    padding: 20px;
  }
}`

export const FrameWrapper = styled.div`{
  align-items: center;
  display: flex;
  gap: 550px;
  justify-content: center;
  left: 0;
  // padding: 0px 100px;
  position: absolute;
  top: 90px;
  width: 100%;
}`

export const TopBodyWrap = styled.div`{
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  position: relative;
  gap: 338px;
   @media (max-width: 765px) {
    flex-direction: column;
    gap: 0px;
  }
}`

export const TextFrameOne = styled.div`{
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}`

export const TextWrapperTwo = styled.h4`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}`

export const HeadingParagraph = styled.p`{
  color: rgba(93, 98, 114, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}`

export const SubBodyWrap = styled.div`{
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 19px;
  position: relative;
}`

export const UploadWrapper = styled.button`{
  align-items: center;
  background-color: #dae1dd;
  border-radius: 14px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 54px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  border: none;
  @media (max-width: 765px) {
    padding: 0px 96px;
  }
}`

export const UploadText = styled.p`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -81.5px;
  margin-top: -85.5px;
  position: relative;
  width: fit-content;
}`

export const MainRequestWrap = styled.div`{
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}`

export const RequestButton = styled.div`{
  align-items: center;
  background-color: rgba(26, 27, 36, 1);
  border-radius: 14px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  height: 54px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  border: none;
  @media (max-width: 765px) {
    padding: 0px 96px;
  }
}`

export const RequestText = styled.p`{
  color: rgba(255, 255, 255, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  // font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -82.5px;
  margin-top: -84.5px;
  position: relative;
  width: fit-content;
  @media (max-width: 765px) {
    font-weight: 0;
  }
}`

export const Cards = styled.div`{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  // left: 100px;
  position: relative;
  top: 165px;
  width: 100%;
}`

export const CardOneWrapper = styled.div`{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 28px;
  position: relative;
  width: 100%;
  @media (max-width: 765px) {
    flex-direction: column;
    top: -140px;
  }
}`

export const VideoCard = styled.div`{
  align-items: flex-start;
  background-color: #1a1b24;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 328px;
  justify-content: space-between;
  padding: 32px;
  position: relative;
  width: 0%;
   @media (max-width: 765px) {
    width: 100%;
    padding: 20px;
    gap: 32px;
  }
}`

export const TotalDiv = styled.div`{
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}`

export const TotalWrapper = styled.div`{
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}`

export const TotalTextWrapper = styled.div`{
  align-items: center;
  background-color: #f1f3ec33;
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px;
  position: relative;
}`

export const TotalText = styled.p`{
  color: #f1f3ec;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 0px;
}`

export const VideosText = styled.p`{
  color: #fafafa;
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const ImageEllipse = styled.div`{
  align-items: baseline;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}`

export const FirstImage = styled.img`{
  height: 60px;
  position: relative;
  width: 60px;
  z-index: 3;
}`

// .dahboard-new .ellipse-2 {
//   height: 60px;
//   margin-left: -12px;
//   position: relative;
//   width: 60px;
//   z-index: 2;
// }

// .dahboard-new .ellipse-3 {
//   height: 60px;
//   margin-left: -12px;
//   position: relative;
//   width: 60px;
//   z-index: 1;
// }

// .dahboard-new .ellipse-4 {
//   height: 60px;
//   margin-left: -12px;
//   position: relative;
//   width: 60px;
//   z-index: 0;
// }

export const VideoCardContent = styled.div`{
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}`

export const VideoCardSubFrame = styled.div`{
  // align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}`

export const NewVideoWrapper = styled.div`{
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}`

export const NewText = styled.p`{
  color: #f1f3ec;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 0px;
}`

export const NewTextNumber = styled.p`{
  color: #3bd8bc;
  font-family: "DM Sans", Helvetica;
  font-size: 27.649999618530273px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const PublishedNumber = styled.p`{
  color: #f1f3ec;
  font-family: "DM Sans", Helvetica;
  font-size: 27.649999618530273px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const UnpublishedVideoWrapper = styled.div`{
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  position: relative;
}`

export const UnpublishedText = styled.p`{
  color: rgba(255, 255, 255, 1);
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  margin-bottom: 0px;
}`

export const UnpublishedNumber = styled.p`{
  color: #f24058;
  font-family: "DM Sans";
  font-size: 27.649999618530273px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const ExploreIconWrap = styled.div`{
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}`

export const ExploreText = styled.p`{
  color: rgba(255, 255, 255, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const ExploreImage = styled.img`{
  position: relative;
 width: 28px;
 background: #007BF7;
 margin-bottom: auto;
 border-radius: 50%;
 pointer: cursor;
}`

export const StoryCard = styled.div`{
  align-items: flex-start;
  background-color: #ffe7a8;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 328px;
  justify-content: space-between;
  padding: 32px;
  position: relative;
  width: 0%;
   @media (max-width: 765px) {
    width: 100%;
    padding: 20px;
    gap: 16px;
  }
}`

export const StoryHeading = styled.p`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -3.45px;
  margin-top: -1px;
  position: relative;
  width: 545.45px;
  @media (max-width: 765px) {
    font-size: 28px;
  }
}`

export const StoryImage = styled.div`{
  flex: 0 0 auto;
  position: relative;
}`

export const ShareButton = styled.button`{
  align-items: center;
  background-color: rgba(26, 27, 36, 1);
  border-radius: 14px;
  display: flex;
  gap: 10px;
  height: 54px;
  justify-content: center;
  overflow: hidden;
  padding: 0px 24px;
  position: relative;
  width: 252px;
}`

export const ShareText = styled.p`{
  color: rgba(255, 255, 255, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -83.5px;
  margin-top: -85.5px;
  position: relative;
  width: fit-content;
  @media (max-width: 765px) {
    font-weight: 0;
  }
}`

export const CardContainer = styled.div`{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 765px) {
    flex-direction: column;
    top: -140px;
  }
}`

export const CustomizeCard = styled.div`{
  align-items: flex-start;
  background-color: #dae1dd;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 50px;
  height: 328px;
  justify-content: center;
  padding: 32px;
  position: relative;
   @media (max-width: 765px) {
    width: 100%;
  }
}`

export const CustomizeWrapper = styled.div`{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 215px;
  justify-content: center;
  margin-top: -14.5px;
  position: relative;
  width: 100%;
}`

export const CustomizeHeading = styled.div`{
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  margin-right: -6.67px;
  position: relative;
}`

export const CustomizeText = styled.p`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}`

export const CustomizeSubText = styled.p`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}`

export const ExploreIconWrapTwo = styled.div`{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  margin-bottom: -14.5px;
  position: relative;
  width: 100%;
}`

export const ExploreTextTwo = styled.p`{
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
  margin-bottom: 0px;
}`

export const CardSubDiv = styled.div`{
  align-items: flex-start;
  background-color: #f1f3ec;
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 328px;
  justify-content: space-between;
  padding: 32px;
  position: relative;
}`

export const AnalyticsCard = styled.div`{
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 102px;
  height: 244px;
  justify-content: center;
  position: relative;
}`

export const AnalyticsTextWrapper = styled.div`{
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}`

export const AnalyticsText = styled.p`{
  color: #1a1b24;
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}`

export const AnalyticsImage = styled.img`{
  height: 87.02px;
  margin-bottom: -1.57px;
  margin-left: -1.25px;
  margin-right: -1.23px;
  position: relative;
  width: 262.48px;
}`

export const AnalyticsImageTwo = styled.img`{
  height: 87.02px;
  margin-bottom: -1.57px;
  margin-left: -1.25px;
  margin-right: -1.23px;
  position: relative;
  width: 262.48px;
}`

export const ExploreIconWrapThree = styled.div`{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  margin-top: -8px;
  position: relative;
  width: 100%;
}`

export const LeadsCard = styled.div`{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 215px;
  justify-content: center;
  position: relative;
  width: 100%;
}`

export const LeadsTextWrapper = styled.div`{
  color: rgba(26, 27, 36, 1);;
  font-family: "DM Sans", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}`

export const LeadsText = styled.p`{
  align-self: stretch;
  color: rgba(26, 27, 36, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  // width: fit-content;
}`

export const ExploreIconWrapFour = styled.div`{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}`

export const SmallScreenCard = styled.div`{
  align-items: flex-start;
  background-color: #f1f4fc;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 262px;
  justify-content: center;
  padding: 20.22px;
  position: relative;
}`

export const SmallScreenText = styled.p`{
  color: #1a1b24;
  font-family: "DM Sans", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}`

export const SmallHeadingFrame = styled.div`{
  align-items: flex-stare;
  display: inline-flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}`


export const SmallTextWrapper = styled.div`{
  color: #000000;
  font-family: "DM Sans";
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}`

export const ButtonWrapper = styled.div`{
  align-items: center;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}`


// .card .div-wrapper {
//   align-items: center;
//   align-self: stretch;
//   background-color: var(--darkgrey-paletterasin-blackrasin-black-100);
//   border-radius: 14px;
//   display: flex;
//   flex: 0 0 auto;
//   gap: 6.32px;
//   justify-content: center;
//   overflow: hidden;
//   padding: 15px 16px;
//   position: relative;
//   width: 100%;
// }

// .card .text-wrapper {
//   color: var(--white-color);
//   font-family: "DM Sans-SemiBold", Helvetica;
//   font-size: 14px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: normal;
//   margin-top: -0.63px;
//   position: relative;
//   width: fit-content;
// }

// .card .div {
//   align-items: center;
//   align-self: stretch;
//   background-color: #dae1dd;
//   border-radius: 14px;
//   display: flex;
//   flex: 0 0 auto;
//   gap: 6.32px;
//   justify-content: center;
//   overflow: hidden;
//   padding: 15px 16px;
//   position: relative;
//   width: 100%;
// }

// .card .text-wrapper-2 {
//   color: var(--darkgrey-paletterasin-blackrasin-black-100);
//   font-family: "DM Sans-SemiBold", Helvetica;
//   font-size: 14px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: normal;
//   margin-top: -1.26px;
//   position: relative;
//   width: fit-content;
// }


// .dahboard-new .wrap-instance {
//   left: 0 !important;
//   position: absolute !important;
//   top: 0 !important;
// }

// .dahboard-new .design-component-instance-node {
//   background-image: url(./image.png) !important;
// }