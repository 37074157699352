import React from 'react'

function BrandInfo({brand}) {
  return (
    <>
      <h1 className='mt-5'>Brand Information</h1>
      <ul className='list-group'>
        <li className='list-group-item'>
          name : {brand.name}
        </li>
        <li className='list-group-item'>
           description : {brand.description}
        </li>
        <li className='list-group-item'>
         Sector : {brand.sector}
        </li>
      </ul>
    </>
  )
}

export default BrandInfo