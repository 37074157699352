import React, { useState } from "react";
import { RequestText } from "../Testimonial/ReviewComp/styled";
import Message from "../../assets/icons/Message.svg";
import Comment from "../../assets/icons/Comment.svg";
import AfricanHipster from "../../assets/images/african-hipster.png";
import Logo from "../../assets/images/Logo.svg";
import Avatar from "../../assets/images/Avatar.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Twitter from "../../assets/icons/Twitter.svg";
import {
  EmailName,
  EmailText,
  PreviewWrapper,
  ProfileName,
  ProfileText,
  RequestMessageText,
  RequestMessageTextWrraper,
  RightsText,
  ShareThoughts,
  ShareThoughtsText,
} from "./styled";
import ColorButton from "../ColorButton";
function ViaPreview({
  viaType,
  requestMessageText,
  formstate,
  picUrl,
  isMobile,
}) {
  const [active, setActive] = useState(
    viaType === "SmsAndEmail" ? "Sms" : viaType
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: "32px",
        }}
      >
        {" "}
        {(viaType === "Sms" || viaType === "SmsAndEmail") && (
          <img
            src={Comment}
            onClick={() => setActive("Sms")}
            alt="testimonial-logo"
            style={{
              width: "26px",
              height: "26px",
              cursor: "pointer",
              opacity:
                viaType === "Sms" || viaType === "SmsAndEmail" ? "1" : "0.5",
            }}
          />
        )}
        {(viaType === "Email" || viaType === "SmsAndEmail") && (
          <img
            src={Message}
            onClick={() => setActive("Email")}
            alt="testimonial-logo"
            style={{
              width: "26px",
              height: "26px",
              cursor: "pointer",
              opacity:
                viaType === "Email" || viaType === "SmsAndEmail" ? "1" : "0.5",
            }}
          />
        )}
      </div>
      <PreviewWrapper>
        {active === "Sms" ? (
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                alignItems: "center",
                marginBottom: "80px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={Avatar}
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                  alt="profile-image"
                />
                <ProfileName>{formstate.name}</ProfileName>
              </div>
              <ProfileText>
                Text Message <br /> Today{" "}
                {new Date().toLocaleTimeString("en-US", {
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </ProfileText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  width: "201px",
                  heigth: "171px",
                  borderRadius: "15px",
                }}
              >
                <img
                  // src={AfricanHipster}
                  src={picUrl}
                  alt="testimonial-logo"
                  style={{
                    width: "100%",
                    // height: "100%",
                    // width: "296px",
                    background: "#F1F3EC",

                    borderRadius: "15px",
                  }}
                />
              </div>
              <RequestMessageTextWrraper>
                <RequestMessageText>
                  {requestMessageText} <br />
                  Use to link below to record your tesimonial:
                  <br />
                  {process.env.REACT_APP_CLIENT_URL}/testimonial
                </RequestMessageText>
              </RequestMessageTextWrraper>
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img src={Logo} style={{}} alt="profile-image" />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  //   width: "296px",
                  height: "237px",
                  background: "#F1F3EC",
                  borderRadius: "15px",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <img
                  // src={AfricanHipster}
                  src={picUrl}
                  style={{
                    width: isMobile && "100%",
                    // width: "207px",
                    height: "100%",
                    // width: "296px",
                    // borderRadius: "15px",
                  }}
                  alt="profile-image"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <EmailName>Hi {formstate.name}</EmailName>
                <EmailText style={{ fontSize: "14px" }}>
                  {requestMessageText ? requestMessageText : "Dummy Text"}
                </EmailText>
              </div>
              <div
                style={{
                  display: "flex",
                  // width:"50%",
                  margin: "0 auto",
                }}
              >
                <ShareThoughts>
                  <ShareThoughtsText>Share your thoughts</ShareThoughtsText>
                </ShareThoughts>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <RightsText>
                  © {new Date().getFullYear()} Recrowdly . All rights reserved.
                </RightsText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "44.001px",
                  height: "13.204px",
                }}
              >
                <img
                  src={Logo}
                  style={{
                    width: "100%",
                  }}
                  alt="profile-image"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <RightsText>
                  Recrowdly - The new face of testimonial.
                </RightsText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Instagram}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="profile-image"
                />
                <img
                  src={Facebook}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="profile-image"
                />
                <img
                  src={Twitter}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="profile-image"
                />
              </div>
            </div>
          </div>
        )}
      </PreviewWrapper>
    </>
  );
}

export default ViaPreview;
