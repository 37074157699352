import { gql } from "@apollo/client";

export const GET_PENDING_INVITATION = gql`
  query {
    pendingInvites {
      _id
      invitationCode
      primaryUser {
        name
        thumbnail
      }
      organization {
        _id
        name
        industry
      }
      brands {
        access
        brand {
          name
        }
      }
      createdAt
      expiredOn
    }
  }
`;

export const GET_MEMBERS_LIST = gql`
  query {
    membersList {
      organization {
        _id
        name
      }
      invitedUser {
        _id
        name
        email
        thumbnail
        lastActive
      }
      brandAccess {
        access
        brand {
          _id
          name
        }
      }
      owned
      inviteId
      invitedUserEmail
      accepted
    }
  }
`;
