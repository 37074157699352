import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  padding-top: 8px;
  padding-left: 6px;
  padding-bottom: 8px;
  &:hover {
    background: #e9e9f2;
    border-radius: 12px;
  }
`;

export const PicContainer = styled(Box)`
  width: ${(props) => (props.vid ? "42.185px" : "35px")};
  height: ${(props) => (props.vid ? "37.498px" : "35px")};
`;

export const NameContainer = styled(Box)`
  margin-left: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
export const TopName = styled(Box)`
  font-size: 14px;
  font-family: Dm Sans;
  font-weight: 500;
  line-height: 18px;
  color: #1a1b24;
`;
export const BottomName = styled(Box)`
  font-size: 12px;
  font-family: Dm Sans;
  line-height: 18px;
  color: #5d6272;
`;
export const SearchBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 87%;
`;

export const Title = styled(Box)`
  color: #000;
  font-size: 14px;
  font-family: Dm Sans;
  line-height: 150%;
  margin-bottom: 6px;
  padding-left: 8px;
  ${(props) =>
    props?.divider &&
    `
    border-top: 1px solid #ECECF2;
    padding-top: 16px;
    margin-top: 8px;
  `};
`;
