import * as React from "react";
import Spinner from "./Spinner";

import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../redux/menu.slice";

import {
  Collapse,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { Divider, TextField, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";

import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
} from "../graphql/queries/organizationQueries";
import { ADD_ORGANIZATION } from "../graphql/mutations/organizationMutation";
import { GET_INDUSTRIES } from "../graphql/queries/industryQueries";
import AWS from "aws-sdk";
import AddImage from "../assets/icons/AddImage.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../App.css";
import {
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
  NavMenuButtonPickerSearch,
  NavMenuButtonPicker,
  NavMenuButtonIcon,
  NavMenuButtonText,
  NavMenuButtonBar,
  SearchResultsBarText,
  SearchResultsBarBottom,
  SearchResultsOrgnBottom,
  SearchResultsOrgnText,
  BannerBox,
  BannerBoxText,
  SearchResultsOrgnBottomTwo,
} from "../pages/UserDashboard/styled";
import ArrowDownward from "../assets/icons/ArrowDown.svg";
import Plus from "../assets/icons/Plus.svg";
import Browse from "../assets/icons/Browse.svg";
import OrgnIcon from "../assets/icons/OrgnIcon.svg";
import {
  AddCircleOutline,
  ExpandLess,
  ExpandMore,
  PlusOneRounded,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { GET_BRANDS } from "../graphql/queries/brandQueries";
import ReusableModal from "./AddModal";
import AddBrandDialog from "./AddBrandDialog";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import BottomPopup from "./BottomPopup";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3();
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    width: "93px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Dm Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: " 15px",
  },
}));
export default function NavMenuButton() {
  const [showBrand, setShowBrand] = useState(false);

  const [openOrgs, setOpenOrgs] = useState([]);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [name, setName] = useState("");
  const [domainUrl, setDomainUrl] = useState("");
  const [industry, setIndustry] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [brandName, setBrandName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenBrand, setDialogOpenBrand] = useState(false);
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const btnRef = useRef();
  let fileUrl = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const menu = useSelector((state) => state.menu);
  // const {
  //   loading: brandsLoading,
  //   error: brandsError,
  //   data: brandsData,
  // } = useQuery(G);
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_USER_ORGANIZATION
  );

  const handleChange = (event) => {
    const selectedMenu = event.target.value;
    dispatch(setMenu(selectedMenu));
  };

  // Add function to toggle visibility of search results
  // const toggleResults = () => {
  //   setShowBrand(!showBrand);
  // };
  const handleBodyClick = (event) => {
    const path = event.composedPath(); // Use composedPath() instead of event.path

    // Check if the clicked element is not the button or custom component
    if (!path.includes(btnRef.current)) {
      setShowBrand(false); // Set showBrand to false when clicked outside of button or custom component
    }
  };

  useEffect(() => {
    // Add event listener to body element
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const toggleResults = () => {
    setShowBrand(!showBrand);
  };

  const handleClick = (index) => {
    setOpenOrgs((prevOpen) => {
      const isOpen = prevOpen[index];
      const updatedOpenOrgs = [...prevOpen]; // Create a copy of the array
      updatedOpenOrgs[index] = !isOpen; // Update the value at the specified index
      return updatedOpenOrgs; // Return the updated array
    });
  };
  // const organizations = organizationData?.userOrganizations;
  const organizations = searchQuery
    ? searchResults
    : organizationData?.userOrganizations;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // const { error, data } = useQuery(GET_INDUSTRIES);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const handleItemClick = (
    name,
    brandId,
    id,
    access = "view",
    owned = false
  ) => {
    // console.log(name, brandId, id, access, owned, "BrandItem");
    setBrandName(name);
    toggleResults();

    dispatch(setMenu({ name, brandId, id, access, owned }));
    if (location.pathname.includes("/brands/")) {
      navigate(`/brands/${brandId}/0/impression`);
    }
    // if (
    //   !(location.pathname.includes("/brands/") && location.pathname === "/")
    // ) {
    //   navigate("/");
    // }
  };
  const handleSearch = (event) => {
    event.stopPropagation();
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredOrgs =
        organizationData?.userOrganizations instanceof Array
          ? organizationData?.userOrganizations.filter(
              (org) =>
                org.organization.name
                  .toLowerCase()
                  .indexOf(query.toLowerCase()) !== -1
            )
          : [];
      setSearchResults(filteredOrgs);
    } else {
      setSearchResults([]);
    }
  };
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleOpenBrandDialog = (orgId, orgName) => {
    // Navigate to the create brand page with orgId and orgName as props
    navigate(`/create_brand/${orgId}`, { state: { orgName } });
  };
  const menuName = useSelector((state) => state.menu);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  let Dropdown = isMobile ? BottomPopup : NavMenuButtonPicker;
  // console.log(menuName, "menuName");
  // console.log(organizations, "menuNameOrg");
  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      <NavMenuButtonBar
        isMobile={isMobile}
        ref={btnRef}
        onClick={toggleResults}
        // className="navigation-img"
        tabIndex="0"
        autoFocus
        focus={showBrand}
      >
        <NavMenuButtonText
        sx={{ opacity: menuName.name ? 1 : 0.5 }}
        >
          {menuName.name
            ? menuName.name.charAt(0).toUpperCase() + menuName.name.slice(1)
            : "Choose Brand"}
        </NavMenuButtonText>

        <NavMenuButtonIcon>
          <img src={ArrowDownward} />
        </NavMenuButtonIcon>
        {showBrand && (
          <Dropdown
            isOpen={showBrand}
            setIsOpen={setShowBrand}
            isMobile={isMobile}
            title="Organizations & Brands"
            bodyContainerStyle={{ zIndex: 9 * 10e10 }}
          >
            <NavMenuButtonPickerSearch
              focus={searchQuery?.length > 0}
              isMobile={isMobile}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, marginTop: "4px" }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onChange={handleSearch}
                onClick={(e) => e.stopPropagation()}
              />
            </NavMenuButtonPickerSearch>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                marginTop: "3.5rem",
                bgcolor: "background.paper",
                "::-webkit-scrollbar": { width: 0, height: 0 },
                overflowY: "scroll",
                whiteSpace: "no-wrap",
                height: "250px",
              }}
            >
              {organizations &&
                organizations instanceof Array &&
                organizations
                  .filter((el) => !!el?.organization)
                  .map((org, index) => {
                    return (
                      <>
                        <Box key={org?.organization?._id + "KY"}>
                          <ListItemButton>
                            <img src={OrgnIcon} alt="orgn icon" />
                            <ListItemText
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "14px",
                                lineHeight: "18px",
                                color: "#5D6272",
                                marginLeft: "10px",
                              }}
                              primary={org?.organization?.name}
                            />
                            {org.owned && (
                              <BootstrapTooltip title="Add Brand">
                                <Box
                                  onClick={() =>
                                    handleOpenBrandDialog(
                                      org?.organization?._id,
                                      org?.organization?.name
                                    )
                                  }
                                >
                                  <img src={Plus} alt="add brand" />
                                </Box>
                              </BootstrapTooltip>
                            )}
                            {/* {openOrgs[index] ? (
                          <ExpandLess onClick={() => handleClick(index)} />
                        ) : (
                          <ExpandMore onClick={() => handleClick(index)} />
                        )} */}
                          </ListItemButton>

                          <List>
                            {org.brands
                              .filter((el) => !!el.brand)
                              .map((item) => (
                                <ListItemButton
                                  key={item?.brand?._id}
                                  sx={{ mx: 4, borderRadius: "14px" }}
                                  onClick={() =>
                                    handleItemClick(
                                      item.brand?.name,
                                      item.brand?.brandId,
                                      item.brand?._id,
                                      item?.access,
                                      org.owned
                                    )
                                  }
                                >
                                  <Box>
                                    <BannerBox
                                      style={{
                                        width: "26px",
                                        height: "26px",
                                        padding: "2px 8px",
                                        borderRadius: "6px",
                                      }}
                                      backgroundColor={item.brand?.brandColor}
                                    >
                                      <BannerBoxText
                                        style={{
                                          height: "17px",
                                          fontSize: "14px",
                                          lineHeight: "17px",
                                        }}
                                      >
                                        {item.brand?.name.charAt(0) || ""}
                                      </BannerBoxText>
                                    </BannerBox>
                                  </Box>

                                  <ListItemText
                                    sx={{
                                      fontFamily: "DM Sans",
                                      fontWeight: 400,
                                      fontStyle: "normal",
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                      marginLeft: "10px",
                                    }}
                                    primary={item.brand?.name}
                                  />
                                </ListItemButton>
                              ))}
                          </List>
                        </Box>
                      </>
                    );
                  })}
              {searchQuery && organizations.length === 0 && (
                <Box sx={{ mt: 2, ml: 2 }}>
                  No results found for "{searchQuery}"
                </Box>
              )}
            </List>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 0 16px 0",
                width: "100%",
              }}
            >
              <SearchResultsOrgnBottom
                className="navigation-img"
                style={{ width: "55%" }}
              >
                <Link
                  to="/create_organization"
                  style={{ textDecoration: "none", color: "#858995" }}
                >
                  <img
                    src={Plus}
                    alt="plus icon"
                    // style={{ paddingLeft: "1rem" }}
                  />
                  <SearchResultsOrgnText style={{ width: "50px" }}>
                    Add Organization{" "}
                  </SearchResultsOrgnText>{" "}
                </Link>
              </SearchResultsOrgnBottom>{" "}
              <SearchResultsOrgnBottomTwo className="navigation-img">
                <Link
                  to="/browse_all"
                  style={{ textDecoration: "none", color: "#858995" }}
                >
                  <img
                    src={Browse}
                    alt="plus icon"
                    // style={{ paddingLeft: "1rem" }}
                  />
                  <SearchResultsOrgnText>Browse All</SearchResultsOrgnText>
                </Link>
              </SearchResultsOrgnBottomTwo>{" "}
            </Box>
          </Dropdown>
        )}
      </NavMenuButtonBar>
      <ReusableModal
        openDialog={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <AddBrandDialog
        openDialog={dialogOpenBrand}
        orgId={selectedOrgName}
        onClose={() => setDialogOpenBrand(false)}
      />
    </>
  );
}
