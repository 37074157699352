import dayjs from "dayjs";

export const roundNumbers = (val, dp = 2) =>
  Math.round(val * 10 ** dp) / 10 ** dp;

export const dateDifference = (startDate, endDate) => {
  try {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    // Calculate the difference in days
    const daysDifference = end.diff(start, "day");

    if (daysDifference <= 31) {
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""}`;
    }

    // Calculate the difference in months and remaining days
    const monthsDifference = end.diff(start, "month");
    const remainingDays = end.diff(start.add(monthsDifference, "month"), "day");

    return `${monthsDifference} month${
      monthsDifference > 1 ? "s" : ""
    }  ${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
  } catch {
    return "";
  }
};
