import { Box, IconButton } from "@mui/material";
import React from "react";
import AvatarImage from "../../assets/images/Avatar.svg";
import {
  BlankImage,
  BottomText,
  Container,
  NoActiveDiv,
  PendDiv,
  TextContainer,
  TopText,
} from "./styled";
import { BannerBox, BannerBoxText } from "../../pages/UserDashboard/styled";

/**
 * NameTagBrowse component displays a user's avatar along with upper text and lower text.
 * @param {string} upperText - The upper text to display.
 * @param {string} lowerText - The lower text to display.
 * @param {string} image - The URL of the user's avatar image.
 * @param {boolean} active - Indicates whether the user is active.
 * @param {string} brandColor - The background color for the brand banner.
 */
export default function NameTagBrowse({ upperText, lowerText, image, active = true, brandColor }) {
  // Ensure a default image if not provided.
  image = !image || image === "" ? AvatarImage : image;

  return (
    <Container>
      {active ? (
        <Box sx={{ marginRight: "10px" }}>
          <BannerBox
            style={{
              width: "26px",
              height: "26px",
              padding: "2px 8px",
              borderRadius: "6px",
            }}
            backgroundColor={brandColor}
          >
            <BannerBoxText
              style={{
                height: "17px",
                fontSize: "14px",
                lineHeight: "17px",
              }}
            >
              {upperText.charAt(0) || ""}
            </BannerBoxText>
          </BannerBox>
        </Box>
      ) : (
        <BlankImage />
      )}

      <TextContainer>
        {active ? (
          <>
            <TopText>{upperText}</TopText>
            <BottomText>{lowerText}</BottomText>
          </>
        ) : (
          <NoActiveDiv>
            <TopText>{lowerText} </TopText>
            <PendDiv>Pending</PendDiv>
          </NoActiveDiv>
        )}
      </TextContainer>
    </Container>
  );
}
