import { gql } from "@apollo/client";

export const FETCH_GROUP_EMAIL_TAG = gql`
  query {
    fetchGroupEmailTags {
      _id
      emailTag
      createdAt
    }
  }
`;
export const FETCH_MAIL_RECORDS = gql`
  query fetchMailRecords($category: String!) {
    fetchMailRecords(category: $category) {
      email
      sgMessageId
      bounce
      open
      click
      delivered
      unsubscribe
      spamreport
      dropped
      deferred
      systemBounce
      createdAt
      timezone
    }
  }
`;

export const FETCH_ALL_MAIL_RECORDS_COUNT = gql`
  query fetchAllMailRecordsCount {
    fetchAllMailRecordsCount {
      bounce
      open
      click
      delivered
      unsubscribe
      spamreport
      dropped
      deferred
      systemBounce
    }
  }
`;

export const FETCH_DAILY_EVENT_COUNTS = gql`
  query fetchDailyEventCounts($input: SendGridDailyEventInput!) {
    fetchDailyEventCounts(input: $input) {
      bounce {
        date
        count
      }
      open {
        date
        count
      }
      click {
        date
        count
      }
      delivered {
        date
        count
      }
      unsubscribe {
        date
        count
      }
      spamreport {
        date
        count
      }
      dropped {
        date
        count
      }
      deferred {
        date
        count
      }
      systemBounce {
        date
        count
      }
    }
  }
`;
