import React, { useEffect, useRef, useState } from "react";
import {
  LabelWrapper,
  LabelWrapperExt,
  BottomWrapper,
  ErrorWrapper,
  StepTitle,
  Step2SubDiv,
} from "../Testimonial/ReviewComp/styled";
import VideoSvg from "../../assets/images/video.svg";
import PlayButton from "../../assets/images/playbutton.svg";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import Upload from "../../assets/icons/Upload.svg";

import FormControlLabel from "@mui/material/FormControlLabel";

import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client";

// import RecordStart from "../RecordComp";
import {
  exportToJPeg,
  formatTime,
  onChangeTextHandler,
  vidFormat,
} from "../../utils/format";
import { useMutation, useQuery } from "@apollo/client";

import AWS from "aws-sdk";

import SuccessComp from "../Testimonial/SuccessComp";
import LoadingComp from "../Testimonial/Loading";

import { Box, InputAdornment } from "@mui/material";
// import ButtonCust from "../../ButtonCust";

import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";

import {
  SAVE_VIDEO,
  SAVE_MULTIPLE_VIDEO,
  UPDATE_VIDEO_DETAILS,
} from "../../graphql/mutations/videoMutation";

import ColorButton from "../ColorButton";

import { useDropzone } from "react-dropzone";
import VideoSnapshot from "video-snapshot";
import portalComponent from "../PortalComponent";
import {
  FormUplWrapper,
  UplContainer,
  UplContainerMinimized,
  UplWrapDiv,
  UploadFullWrapper,
  VideoPartUplWrapper,
  InputWrapper,
  RplButton,
} from "../UploadDialog/styled";
import axios from "axios";

import { IOSSwitch } from "../Testimonial/ReviewComp";
import VideoEditSection from "../UploadDialog/videoEditSection";
import PhoneNumberInput from "../PhoneNumberInput";
import countryCodes from "../../utils/countryCodes";
import detectEyes from "../../utils/detectEyes";

function EditVideoDialog({
  openDialog,
  onClose,
  brand,
  isMobile,
  itemData,
  reload,
}) {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.auth);
  const userId = profileData?.user?._id;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [scroll, setScroll] = useState("body");
  const [vidRate, setVidRate] = useState(0);
  const [itemVideo, setItemVideo] = useState(null);
  const [checked, setChecked] = useState(false);
  const [step, setStep] = useState(1);

  const [snapshotUrl, setSnapshotUrl] = useState(null);
  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [formstate, setFormstate] = useState({});
  const [errorstate, setErrorstate] = useState({});
  const [showForm, setSetShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [trail, setTrail] = useState({});
  const [newStep, setNewStep] = useState(1);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [focusState, setFocusState] = useState({});

  const [videoSrc, setVideoSrc] = useState(null);
  const [pic, setPic] = useState();
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileTypeArray, setFileTypeArray] = useState([]);
  const [eyeDistArray, setEyeDistArray] = useState([]);
  const [selectedFileArray, setSelectedFileArray] = useState([]);
  const [videoDurationArray, setVideoDurationArray] = useState([]);
  const [cntryCde, setCntryCde] = useState("");

  const [vidChanged, setVidChanged] = useState(false);
  const [videoSrcArray, setVideoSrcArray] = useState([]);
  const [picArray, setPicArray] = useState([]);
  // const [uploadProgress, setUploadProgress] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const [miniuploadProgress, setminiUploadProgress] = useState();

  const [triggerOpen, setTriggerOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  // const [counterStart, setCounterStart] = useState();
  // const [duration, setDuration] = useState();  //......

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY;
  const s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
  });

  const [updateVideoDetails] = useMutation(UPDATE_VIDEO_DETAILS, {
    onCompleted(data) {
      // console.log(data, "success");
    },
  });

  const getCountryFlag = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      let resObj = res.data instanceof Object ? res.data : {};
      let countryCode = resObj.country_code;
      let ctryCode = countryCodes.find(
        (el) =>
          String(el.country).trim().toLowerCase() ===
          String(countryCode).trim().toLowerCase()
      )?.code;
      if (ctryCode) setCntryCde(ctryCode);
    } catch {}
  };

  const getValues = async () => {
    if (itemData && openDialog) {
      setItemVideo(null);
      const { name, phoneNumber, email, position, organization } = itemData;
      setFormstate({ name, phoneNumber, email, position, organization });
      setItemVideo(vidFormat(itemData.url, "", itemData));
      setVideoSrc(null);
      setVidChanged(false);
      if (itemData.phoneCode) {
        let ctryCode = countryCodes.find(
          (el) => el.code === itemData.phoneCode
        )?.code;
        if (ctryCode) {
          setCntryCde(itemData.phoneCode);
          return;
        }
      }
      await getCountryFlag();
    }
  };

  const handleChange = (val, name) => {
    let cond =
      val &&
      val.trim() !== "" &&
      name === "phoneNumber" &&
      !/^-?\d+$/.test(val);
    // console.log(val, "Valuee", /^-?\d+$/.test(val), cond);
    if (!cond) {
      onChangeTextHandler(val, name, formstate, setFormstate);
      validateForm(name, val);
    } else {
      onChangeTextHandler(formstate[name] ?? "", name, formstate, setFormstate);
    }
  };

  // console.log(isChecked, "isChecked");
  let arrForm = [
    {
      title: "Name",
      name: "name",
      required: true,
      type: "text",
      error: "Name field is blank",
      placeholder: "John Doe",
    },
    {
      title: (
        <LabelWrapperExt>
          <div>{checked ? "Company" : " "}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "4px 0 7px 0",
              width: "100%",
            }}
          >
            <div style={{ fontSize: "18px", fontWeight: 500 }}>
              Work Information
            </div>
            <FormControlLabel
              value="start"
              control={
                // <Switch
                //   onChange={() => setChecked(!checked)}
                //   checked={checked}
                //   color="warning"
                // />
                <IOSSwitch
                  onChange={() => setChecked(!checked)}
                  checked={checked}
                  color="warning"
                  labelPlacement="left"
                />
              }
              style={{ right: 0, margin: 0 }}
            />
          </div>
        </LabelWrapperExt>
      ),
      type: "logical",
      name: "organization",
      placeholder: "Microsoft",
    },
    {
      title: checked && (
        <>
          Designation {/* <span style={{ color: "#666666" }}>i.e-Ceo</span> */}
        </>
      ),
      type: "logical",
      name: "position",
      placeholder: "CEO",
    },
    {
      title: "Email",
      name: "email",
      neccessary: true,
      required:
        formstate?.phoneNumber && formstate?.phoneNumber?.trim() !== ""
          ? false
          : true,
      type: "email",
      error: "Email field is blank",
      errorEmail: "Email is invalid",
      placeholder: "abcd@gmail.com",
    },
    {
      title: "Mobile Number",
      name: "phoneNumber",
      neccessary: true,
      required:
        formstate?.email && formstate?.email?.trim() !== "" ? false : true,
      type: "tel",
      error: "Mobile Number field is blank",
      errorPhone: "Mobile Number is invalid",
      placeholder: "700 550 553",
    },
  ];

  const displayForm = () => {
    return arrForm.map((el, idx) => {
      return (
        <div key={idx}>
          <LabelWrapper>{el?.title}</LabelWrapper>
          {(el?.type !== "logical" || (el?.type === "logical" && checked)) &&
            (el.name === "phoneNumber" ? (
              <PhoneNumberInput
                el={el}
                focusState={focusState}
                formstate={formstate}
                handleChange={handleChange}
                setFocusState={setFocusState}
                errorstate={errorstate}
                setCntryCde={setCntryCde}
                cntryCde={cntryCde}
                openDialog={openDialog}
              />
            ) : (
              <InputWrapper
                required={el?.required ?? false}
                type={el?.type ?? "text"}
                placeholder={el?.placeholder ?? ""}
                value={formstate[el?.name] || ""}
                defaultValue={formstate[el?.name] || ""} // add defaultValue prop
                borderColor={errorstate[el?.name] && "#D93047"}
                mfocus={
                  focusState[el?.name] ||
                  (formstate[el?.name] && formstate[el?.name] !== "")
                }
                onChange={(e) => handleChange(e.target.value, el?.name)}
                onFocus={() =>
                  setFocusState({ ...focusState, [el?.name]: true })
                }
                onBlur={() =>
                  setFocusState({ ...focusState, [el?.name]: false })
                }
              />
            ))}
          {errorstate[el?.name] && (
            <ErrorWrapper>{errorstate[el?.name] && "Required"}</ErrorWrapper>
          )}
        </div>
      );
    });
  };

  const validateForm = (field = "", val = "") => {
    let truthValue = true;
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let phoneReg =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    let objError = { ...errorstate };

    if (field === "") {
      objError = {};
    } else {
      if (objError[field]) {
        delete objError[field];
        // console.log(objError, "ErrrorObj", field);
      }
    }
    setErrorstate(objError);

    let uArrForm = [...arrForm];
    let uformstate = field !== "" ? { [field]: val } : { ...formstate };
    if (field !== "") {
      uArrForm = arrForm.filter((el) => el.name === field);
    }
    for (let el of uArrForm) {
      if (
        el?.required &&
        (!uformstate[el?.name] || uformstate[el?.name].toString().trim() === "")
      ) {
        //add error.
        // alert(el?.error);
        objError[el?.name] = el?.error;
        truthValue = false;
      }
      if (
        el?.neccessary &&
        el?.type === "email" &&
        uformstate[el?.name] &&
        !emailReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorEmail;
        truthValue = false;
      }

      if (
        el?.neccessary &&
        el?.type === "tel" &&
        uformstate[el?.name] &&
        !phoneReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorPhone;
        truthValue = false;
      }
    }

    if (!truthValue) setErrorstate(objError);
    return truthValue;
  };

  const handleCloseDialog = () => {
    setItemVideo(null);
    setVidRate(0);
    setFormstate({});
    setIsVideoOff(true);
    setRecordingData({});
    onClose();
    setTrail({});
    setIsVideoOff(false);
    setVideoSrc(null);
    setNewStep(1);
    setIsChecked(false);
    setPicArray([]);
    setVideoSrcArray([]);
    setVideoDurationArray([]);
    setSelectedFileArray([]);
    setFileTypeArray([]);
    setEyeDistArray([]);
    setUploadProgress();
  };

  //////////////////////////////////////////////////////
  // Handle the drop event

  const onDrop = async (acceptedFiles) => {
    let selFileArray = [],
      selFileTypeArray = [],
      errorFlag = false,
      picDropArray = [],
      vidDropArray = [],
      vidDurDropArray = [],
      eyeDistDropArray = [];
    let ind = 0;
    // for (let ind = 0; ind < acceptedFiles.length; ind++) {
    let file = acceptedFiles[ind];
    let fileTypeVal = file.type;

    if (fileTypeVal.startsWith("video/")) {
      selFileArray.push(file);
      selFileTypeArray.push(fileTypeVal);

      // Use video-snapshot to capture a frame from the video
      let snapshoter = new VideoSnapshot(file);
      let previewSrc = await snapshoter.takeSnapshot();

      // Convert the image to JPEG format if it's not already

      // The image is already in JPEG format, so no conversion is needed
      if (fileType !== "image/jpeg") {
        let blob = await exportToJPeg(previewSrc);
        picDropArray.push(blob);
      } else {
        let response = await fetch(previewSrc);
        let blob = await response.blob();
        // setPic(blob);
        // setPicArray([...picArray, blob]);
        picDropArray.push(blob);
      }

      /////Find Eye Detection...
      let eyeDistance = await detectEyes(previewSrc);
      eyeDistDropArray.push(eyeDistance);
      // console.log(eyeDistance, "eyeDistance");
      //////////////////

      // set video source
      let videoSource = URL.createObjectURL(file);
      // setVideoSrc(videoSource);
      // setVideoSrcArray([...videoSrcArray, videoSource]);
      vidDropArray.push(videoSource);
      // Get video    duration
      let video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      let dur;
      video.onloadedmetadata = () => {
        dur = video.duration;
        // console.log(dur, "fndur");
        let vidArray = videoDurationArray;
        vidArray[vidArray.length] = dur;
        setVideoDurationArray(vidArray);
        // vidDurDropArray.push(video.duration);
        // setVideoDuration(video.duration);
      };

      vidDurDropArray.push(dur);
    } else {
      enqueueSnackbar("Video File only.", {
        variant: "error",
      });
      errorFlag = true;
      // break;
    }
    //  }

    if (!errorFlag) {
      setSelectedFileArray([...selFileArray]);
      setFileTypeArray([...selFileTypeArray]);
      setPicArray([...picDropArray]);
      setVideoSrcArray([...vidDropArray]);
      setEyeDistArray([...eyeDistDropArray]);

      setVideoSrc(vidDropArray[0]);
      setVidChanged(true);
    }
  };

  // Use the useDropzone hook to enable drag-and-drop functionality
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*", // Accept any video file type
    maxFiles: 1, // Allow only one file
  });
  const removeVideo = () => {
    setVideoSrc(null);
  };

  const resetFn = () => {
    handleCloseDialog();
  };

  const uploadVideo = async () => {
    try {
      if (!validateForm()) return false;

      let objFormstate = { ...formstate };
      if (objFormstate.phoneNumber && objFormstate.phoneNumber.trim() === "") {
        objFormstate.phoneNumber = undefined;
      } else {
        objFormstate.phoneCode = cntryCde;
        objFormstate.phoneNumber = `${Number.parseInt(
          objFormstate.phoneNumber
        )}`;
      }
      if (objFormstate.email && objFormstate.email.trim() === "") {
        objFormstate.email = undefined;
      }

      let sumFileSize = 0,
        vidDur = [],
        picAr = [],
        selFileAr = [],
        urlAr = [],
        eyeAr = [],
        flTypAr = [],
        origImageExt = [],
        origVideoExt = [],
        percentUploadedArr = [],
        successArr = [];

      setLoading(true);

      let addParams = Date.now();

      ///if video changed...//

      ///Delete the previous video...

      //Upload to S3 buckets
      if (vidChanged) {
        for (let index = 0; index < videoSrcArray.length; index++) {
          if (videoSrcArray[index] && videoDurationArray[index]) {
            if (!selectedFileArray[index]) {
              enqueueSnackbar("Please choose a video.", {
                variant: "error",
              });
              return;
            }
            if (selectedFileArray[index].size > 50 * 1024 * 1024) {
              enqueueSnackbar(
                "Single Video size exceeds the maximum limit of 50MB.",
                {
                  variant: "error",
                }
              );
              return;
            }
            sumFileSize += selectedFileArray[index].size;

            if (sumFileSize > 100 * 1024 * 1024) {
              enqueueSnackbar(
                "Video size exceeds the maximum limit of 100MB.",
                {
                  variant: "error",
                }
              );
              return;
            }
            vidDur.push(videoDurationArray[index]);
            picAr.push(picArray[index]);
            selFileAr.push(selectedFileArray[index]);
            flTypAr.push(fileTypeArray[index]);
            eyeAr.push(eyeDistArray[index]);
          }
        }
        for (let index = 0; index < picAr.length; index++) {
          if (!picAr[index]) continue;
          let urlIndex = `${addParams}_${index}`;
          urlAr[index] = urlIndex;

          origVideoExt[index] = fileTypeArray[index].split("/")[1];
          let uploadConfigVid = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            Key: `OriginalVideo${urlIndex}.${origVideoExt[index]}`,
            Body: selFileAr[index],
          };

          await s3
            .upload(uploadConfigVid)
            .on("httpUploadProgress", (progressEvent) => {
              let { loaded, total } = progressEvent;
              let percentUploaded = Math.round((loaded / total) * 100);
              // console.log(uploadProgress, "upppload");
              setUploadProgress(`${percentUploaded}`);
            })
            .promise();
          if (!picAr[index]) continue;

          origImageExt[index] = picAr[index].type.split("/")[1];
          let uploadConfigImg = {
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            Key: `Image${urlIndex}.${origImageExt[index]}`,
            Body: picAr[index],
          };

          await s3.upload(uploadConfigImg).promise();

          await updateVideoDetails({
            variables: {
              input: {
                url: urlAr[index],
                brandId: brand?.brandId,
                ...objFormstate,
                // rating: vidRate ?? 0,
                duration: vidDur[index],
                originalVideoExtension: origVideoExt[index],
                originalImageExtension: origImageExt[index],
                _id: itemData._id,
                eyeDistance: eyeAr[index],
              },
            },
          });
        }
      } else {
        // update without the urls
        //   brandId: brand?.brandId,
        //         ...objFormstate,

        await updateVideoDetails({
          variables: {
            input: {
              _id: itemData._id,
              ...objFormstate,
            },
          },
        });
      }
      enqueueSnackbar("Video details updated successfully.", {
        variant: "success",
      });
      resetFn();
      reload && reload();
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getValues();
  }, [itemData, openDialog]);
  // console.log(itemData, "ItemData");

  return (
    <>
      <UplContainer display={openDialog}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: isMobile ? "auto" : "100%",
          }}
        >
          <UploadFullWrapper brand={brand} isMobile={isMobile}>
            {success ? (
              <SuccessComp setClicked={setClicked} brand={brand} />
            ) : (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1.5%",
                    "@media (max-width: 576px)": {
                      marginBottom: "9.5%", // Adjust padding for smaller screens
                    },
                  }}
                >
                  <StepTitle style={{ marginBottom: "0px" }}></StepTitle>
                  <img
                    src={RemoveIcon}
                    alt="close"
                    style={{
                      cursor: "pointer",
                      height: 28,
                    }}
                    onClick={handleCloseDialog}
                  />
                </Box>

                <UplWrapDiv isMobile={isMobile}>
                  {/* {newStep === 2 && step === 2 && ( */}
                  <VideoPartUplWrapper isMobile={isMobile}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile ? "100%" : "97%",
                          // display: "flex",
                          // alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            backgroundColor: "white",
                            // height: isMobile ? "40vh" : "60vh",
                            // height: isMobile ? "auto" : "60vh",
                            height: isMobile ? "calc(40vh + 200px)" : "60vh",

                            borderRadius: "18px",
                            border: "1.5px dashed #D4DAE1",
                            color: "#1A1B24",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: videoSrc ? "flex-start" : "center",
                            flexDirection: isMobile ? "column" : "row",
                            // gap: "47px",
                            padding: "32px 24px",
                            "@media (max-width: 576px)": {
                              padding: "0px", // Adjust padding for smaller screens
                            },
                          }}
                        >
                          <VideoEditSection
                            getRootProps={getRootProps}
                            videoSrc={videoSrc ?? itemVideo}
                            removeVideo={removeVideo}
                            isDragActive={isDragActive}
                            VideoSvg={VideoSvg}
                            getInputProps={getInputProps}
                            fullWidth={
                              picArray.filter((el) => !!el).length === 0
                            }
                            key={videoSrc ?? "EmptyVidKey"}
                            isMobile={isMobile}
                            triggerOpen={triggerOpen}
                            setTriggerOpen={setTriggerOpen}
                          />
                        </Box>

                        {step == 2 && (
                          <Step2SubDiv>
                            <ColorButton
                              title={"Previous"}
                              background={"#1A1B24"}
                              width={"48%"}
                              onClick={() => {
                                setStep(1);
                                setNewStep(1);
                                setTrail(recordingData);
                              }}
                            />
                            <ColorButton
                              pointerEvents={
                                recordingData.videoUrl ? "auto" : "none"
                              }
                              title={
                                loading ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      position: "relative",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LoadingComp loading={loading} />
                                    <div
                                      style={{
                                        // width: "100%",
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        top: "60%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "#1A1B2",
                                        fontSize: "8px",
                                        fontWeight: "800",
                                        fontFamily: "DM Sans",
                                      }}
                                    >
                                      <p>{uploadProgress || 0}%</p>
                                    </div>
                                  </div>
                                ) : (
                                  "Submit"
                                )
                              }
                              background={"#B8A9FF"}
                              width={"48%"}
                              onClick={() => uploadVideo()}
                            />
                          </Step2SubDiv>
                        )}
                      </Box>
                    </Box>
                  </VideoPartUplWrapper>

                  {/* {(newStep === 1 || step === 1) && ( */}
                  <FormUplWrapper isMobile={isMobile}>
                    <Box>
                      {displayForm()}
                      <RplButton
                        onClick={() => {
                          setTriggerOpen(true);
                        }}
                      >
                        <>
                          <img src={Upload} />
                          <>Replace Video</>
                        </>
                      </RplButton>
                    </Box>

                    <BottomWrapper
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        margin: isMobile ? "1rem auto" : "0px",
                        marginBottom: isMobile ? "0px" : "0",
                        top: !isMobile && "0",
                        // marginTop:
                        //   !isMobile && !checked ? "5rem" : undefined,
                      }}
                    >
                      <ColorButton
                        pointerEvents={"auto"}
                        innerStyle={{ width: "100%" }}
                        disabled={loading}
                        title={
                          loading ? (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                position: "relative",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <LoadingComp loading={loading} />
                              <div
                                style={{
                                  // width: "100%",
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  top: "60%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "#1A1B2",
                                  fontSize: "8px",
                                  fontWeight: "800",
                                  fontFamily: "DM Sans",
                                }}
                              >
                                <p>{uploadProgress || 0}%</p>
                              </div>
                            </div>
                          ) : (
                            "Save Changes"
                          )
                        }
                        background={"#1A1B24"}
                        width={"100%"}
                        // onClick={mobileFormValidation}
                        onClick={() => uploadVideo()}
                      ></ColorButton>
                    </BottomWrapper>
                  </FormUplWrapper>
                </UplWrapDiv>
              </>
            )}
          </UploadFullWrapper>
        </Box>

        {/* </UplContent> */}
      </UplContainer>
    </>
  );
}

export default React.memo(EditVideoDialog);
