import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  AlignContainer,
  CheckLabel,
  ControlDiv,
  DisplayDiv,
  DynamicDiv,
  DynamicSectionDiv,
  ImageDiv,
  IndicatorDiv,
  LeftDiv,
  LeftSubTitleDiv,
  OnButton,
  PreviewButton,
  PreviewText,
  SectionTitle,
  SubTitleDiv,
  SubTitleDiv2,
  TypeButton,
  TypeDiv,
  WidgetButDiv,
} from "./styled";

import DecIncrControl from "../../components/DecIncrControl";
import ColorControl from "../../components/ColorControl";
import LeftAlign from "../../assets/icons/LeftAlign";
import CenterAlign from "../../assets/icons/CenterAlign";
import PreviewIcon from "../../assets/images/preview.svg";

export default (widgetState, isMobile, handleChange, setModalOpen) =>
  typeof widgetState?.option === "string" &&
  widgetState?.option.includes("section") && (
    <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
      <DisplayDiv
        isMobile={isMobile}
        widsec
        style={{
          height: "110px",
          display: "flex",
          justifyContent: "space-between",

          flexDirection: "column",
        }}
      >
        <SubTitleDiv isMobile={isMobile}>
          <LeftDiv>
            <LeftSubTitleDiv>Section Layout</LeftSubTitleDiv>
            <IndicatorDiv
              active={widgetState?.sectionVisibility}
              style={{ marginLeft: isMobile && "3px" }}
            >
              {widgetState?.sectionVisibility ? "ON" : "OFF"}
            </IndicatorDiv>
          </LeftDiv>
          <OnButton
            onClick={() =>
              handleChange(!widgetState?.sectionVisibility, "sectionVisibility")
            }
          >
            {" "}
            {widgetState?.sectionVisibility ? "Turn off" : "Turn on"}
          </OnButton>
        </SubTitleDiv>
        <SubTitleDiv2>
          Section Layout form will be displayed on your site
        </SubTitleDiv2>
      </DisplayDiv>
      <DisplayDiv
        isMobile={isMobile}
        inactive={!widgetState?.sectionVisibility}
        style={{ height: "calc(100%  - 127px)", marginTop: 16 }}
      >
        {/* <LeftDescription>
        Customize how the section will look on your website when users want
        to view or leave reviews
      </LeftDescription> */}
        <DynamicSectionDiv isMobile={isMobile}>
          <SectionTitle style={{ marginTop: 0 }}>Header</SectionTitle>
          <TextField
            sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
            value={widgetState?.sectionHeader}
            inputProps={{
              style: { height: "16px" },
            }}
            fullWidth
            onChange={(e) => handleChange(e.target.value, "sectionHeader")}
          />
          <ControlDiv style={{ width: "100%" }}>
            <DecIncrControl
              val={widgetState?.sectionHeaderFontSize}
              setVal={(val) => handleChange(val, "sectionHeaderFontSize")}
            />
            <DecIncrControl
              val={widgetState?.sectionHeaderLineHeight}
              setVal={(val) => handleChange(val, "sectionHeaderLineHeight")}
              type="height"
            />
            <ColorControl
              color={widgetState?.sectionHeaderColor}
              setColor={(val) => handleChange(val, "sectionHeaderColor")}
            />
          </ControlDiv>

          <SectionTitle>Paragraph</SectionTitle>
          <TextField
            value={widgetState?.paragraph}
            sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
            inputProps={{ style: { height: "16px" } }}
            fullWidth
            onChange={(e) => handleChange(e.target.value, "paragraph")}
          />
          <ControlDiv style={{ width: "100%" }}>
            <DecIncrControl
              val={widgetState?.sectionParaFontSize}
              setVal={(val) => handleChange(val, "sectionParaFontSize")}
            />
            <DecIncrControl
              val={widgetState?.sectionParaLineHeight}
              setVal={(val) => handleChange(val, "sectionParaLineHeight")}
              type="height"
            />
            <ColorControl
              color={widgetState?.sectionParaColor}
              setColor={(val) => handleChange(val, "sectionParaColor")}
            />
          </ControlDiv>

          <SectionTitle style={{ marginBottom: 6 }}>Review Button</SectionTitle>
          <FormGroup
          // style={{ marginTop: "24px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={widgetState?.sectionLeaveReview}
                  onClick={() =>
                    handleChange(
                      !widgetState?.sectionLeaveReview,
                      "sectionLeaveReview"
                    )
                  }
                  sx={{
                    color: "#D9EAF8",
                    "&.Mui-checked": {
                      color: "#4097DA",
                    },
                  }}
                />
              }
              label={
                <CheckLabel>
                  Enable{" "}
                  <span style={{ color: "#000" }}>Leave Review Button</span>
                </CheckLabel>
              }
            />
          </FormGroup>
          <TextField
            value={widgetState?.sectionLeaveReviewText}
            disabled={!widgetState?.sectionLeaveReview}
            sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
            inputProps={{ style: { height: "16px" } }}
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "sectionLeaveReviewText")
            }
          />
          <ControlDiv style={{ width: isMobile && "100%" }}>
            <DecIncrControl
              disabled={!widgetState?.sectionLeaveReview}
              val={widgetState?.sectionLeaveReviewFontSize}
              setVal={(val) => handleChange(val, "sectionLeaveReviewFontSize")}
            />
          </ControlDiv>

          <SectionTitle>Text Alignment</SectionTitle>
          <AlignContainer>
            <LeftAlign
              active={widgetState?.textAlignment === "left"}
              style={{ cursor: "pointer", marginRight: 12 }}
              changeHandler={(val) => handleChange(val, "textAlignment")}
            />
            <CenterAlign
              active={widgetState?.textAlignment === "center"}
              style={{ cursor: "pointer" }}
              changeHandler={(val) => handleChange(val, "textAlignment")}
            />
          </AlignContainer>

          <SectionTitle>Style type</SectionTitle>
          <TypeDiv>
            <TypeButton
              onClick={() => handleChange("classic", "sectionStyle")}
              active={widgetState?.sectionStyle === "classic"}
            >
              Classic
            </TypeButton>
            <TypeButton
              onClick={() => handleChange("standard", "sectionStyle")}
              active={widgetState?.sectionStyle === "standard"}
            >
              Standard
            </TypeButton>
            <TypeButton
              onClick={() => handleChange("flex", "sectionStyle")}
              active={widgetState?.sectionStyle === "flex"}
            >
              Flex
            </TypeButton>
          </TypeDiv>
          <FormGroup style={{ marginTop: "24px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={widgetState?.sectionRating}
                  onClick={() =>
                    handleChange(!widgetState?.sectionRating, "sectionRating")
                  }
                  sx={{
                    color: "#D9EAF8",
                    "&.Mui-checked": {
                      color: "#4097DA",
                    },
                  }}
                />
              }
              label={
                <CheckLabel>
                  Enable <span style={{ color: "#000" }}> Rating</span>
                </CheckLabel>
              }
            />
          </FormGroup>
          {isMobile && (
            <SectionTitle
              onClick={() => setModalOpen(true)}
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              <PreviewButton>
                <img src={PreviewIcon} />
                <PreviewText>Preview</PreviewText>
              </PreviewButton>
            </SectionTitle>
          )}
        </DynamicSectionDiv>
      </DisplayDiv>
    </Grid>
  );
