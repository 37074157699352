import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

const nodeVariantMap = {
  selected: "#0d0d0d",
  error: "#f02828",
  unselected: "#a6a6a6",
};

export const NodeItemStyle = styled(Box)`
  font-family: "Dm Sans";
  background: ${({ variant }) => (variant === "white" ? "white" : "#232323")};
  color: ${({ borderVariant }) =>
    borderVariant === "selected" ? nodeVariantMap.selected : "#a2a2a2"};
  width: ${({ variant }) => (variant === "white" ? "350px" : "100%")};
  border-radius: 14px;
  border: 2px solid
    ${({ variant, borderVariant }) =>
      nodeVariantMap[borderVariant?.trim()]
        ? nodeVariantMap[borderVariant?.trim()]
        : variant === "white"
        ? "black"
        : "#3c3c3c"};
  padding: 10px;
  display: flex;
  cursor: grab;
`;

export const StarterNodeItemStyle = styled(Box)`
  font-family: "Dm Sans";
  background: ${({ variant }) => (variant === "white" ? "white" : "#232323")};
  color: #a2a2a2;
  width: ${({ variant }) => (variant === "white" ? "300px" : "100%")};
  border-radius: 10px;
  border: 2px solid
    ${({ variant }) => (variant === "white" ? "black" : "#3c3c3c")};
  padding: 5px;
  display: flex;
  cursor: grab;
`;

export const CanvasContainerStyle = styled(Box)`
  position: relative;
  height: 100vh;
  .edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    &.disable {
      cursor: not-allowed;
      &:hover {
        background: #eee;
        color: black;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
      }
    }
    &:hover {
      background: #e15454;
      color: white;
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    }
  }
  .react-flow__edge-path,
  .react-flow__connection-path {
    stroke: #8a8a8a;
    stroke-width: 2;
    fill: none;
  }
  .handle {
    height: 8px;
    width: 8px;
    border-radius: 2px;
    border: none;
    &.top {
      top: -7px;
    }
    &.bottom {
      bottom: -7px;
    }
  }
`;
export const SideContentBox = styled(Box)`
  font-family: "Dm Sans";
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;
  .title_nav {
    padding: 10px;
  }
  .title {
    display: flex;
    align-items: center;
    font-family: "Dm Sans";
    font-style: normal;
    color: white;
    font-size: 0.875rem;
    margin-bottom: 0.65rem;
  }
  .main_content {
    flex: 1;
    padding: 5px;
    overflow-y: auto;
    /* padding: 10px; */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #474747;
    }
  }
`;

export const NodeModalContainerStyle = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  /* min-height:30rem; */
  background: white;
  border-radius: 0.5rem;
  .ql-editor {
    height: 200px;
  }
`;

const color = {
  orange: "#BE4040",
  blue: "#004D99",
};

export const DatePickerContainerStyles = styled(Box)`
  .react-datepicker {
    /* position: unset !important; */
  }
  .react-datepicker__input-time-container {
    position: absolute;
    left: -250px;
    top: 40%;
    font-family: Switzer;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    .time-input {
      border-radius: 0.375rem; /* Equivalent to rounded-md */
      border: none; /* Equivalent to border-0 */
      padding-top: 0.375rem; /* Equivalent to py-1.5 */
      padding-bottom: 0.375rem; /* Equivalent to py-1.5 */
      padding-left: 1.75rem; /* Equivalent to pl-7 */
      padding-right: 1.75rem; /* Equivalent to pr-20 */
      color: #000000; /* Equivalent to text-gray-900 */
      border-width: 1px; /* Equivalent to ring-1 */
      border-style: inset; /* Equivalent to ring-inset */
      border-color: #000000; /* Equivalent to ring-gray-300 */
      ::placeholder {
        color: #000000;
      }
      :focus {
        outline-width: 2px; /* Equivalent to focus:ring-2 */
        outline-style: inset; /* Equivalent to focus:ring-inset */
        outline-color: #000000; /* Equivalent to focus:ring-indigo-600 */
      }
    }
  }
`;

export const StyledTagButton = styled(Button)`
  background: none;
  box-shadow: none;
  // border-radius: 7px;
  border-radius: 15px;
  color: ${(props) => (props?.cusColor ? color[props.cusColor] : "0d0d0d")};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  padding-left: 1.48rem;
  padding-right: 1.48rem;
  margin-left: 0.48rem;
  margin-right: 0.48rem;
  border: 1px solid
    ${(props) => (props?.cusColor ? color[props.cusColor] : "0d0d0d")} !important;
  font-family: Switzer;
  &:hover {
    background: none;
    color: ${(props) => (props?.cusColor ? color[props.cusColor] : "0d0d0d")};
  }
`;

export const WorkflowNav = styled(Box)`
  background-color: #101010;
  height: 60px;
  padding: 0 1.25rem;
  display: flex;
  font-family: "Dm Sans";
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
`;

export const StyledLabel = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
  }
`;
