import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width:50%;
  flex-direction: column;
  margin:0 auto;
`;

export const MessageWrapper = styled.div`
font-family: 'Dm Sans';
font-style: normal;
font-weight: 400;
font-size: 34px;
line-height: 39px;
/* identical to box height */
margin:top:2rem;
letter-spacing: -0.02em;

/* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

color: #1A1B24;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
`;
