import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import TestimonialRecord from './Testimonial';

function WidgetDialog({openDialog, onClose,}) {
    const [scroll, setScroll] = useState("body");
  return (

    < >
    
      <Dialog
          open={openDialog}
          onClose={onClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="lg"
        >
          {/* <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent>
    <TestimonialRecord brandId={"brand_bxmqcazvn7"}/>
          </DialogContent>
        </Dialog>
      
    </ >
  )
}

export default WidgetDialog