import { Box, CardContent, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NodeModalContainerStyle } from "./index.styled";
import { CreateButton } from "../../components/TagsModal/styled";
import { useNodes } from "reactflow";
import { Controller, useForm } from "react-hook-form";
import {
  SubTitle,
  TemplateCard,
  TemplateImage,
  Title,
} from "../Templates/styled";
import tempreview from "../Templates/tempreview.jpg";

const NewsletterModalContent = ({
  selected,
  newsletters,
  saveNode,
  closeModals,
}) => {
  const nodes = useNodes();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      from: "",
      value: "",
      tag: "",
      subject: "",
    },
  });

  useEffect(() => {
    if (selected) {
      const currentNode = nodes.find(({ id }) => id === selected?.id);
      const tagPattern = currentNode?.data?.metadata?.tag?.[0] ?? "";
      const regex = /^([a-zA-Z0-9]+)-wf-(\d+)$/;
      const match = tagPattern.match(regex);
      const tag = match?.[1] ?? "";
      reset({
        from: currentNode?.data?.metadata?.from ?? "",
        value: currentNode?.data?.metadata?.value ?? "",
        subject: currentNode?.data?.metadata?.subject ?? "",
        tag: tag,
      });
    }

    return () => {};
  }, [selected]);

  const onSubmit = ({ value, tag, subject, from }) => {
    closeModals();
    saveNode({
      ...selected,
      data: {
        ...selected?.data,
        metadata: {
          ...selected?.data?.metadata,
          value,
          subject,
          from,
          tag: [`${tag}-wf-${Date.now()}`],
        },
      },
    });
  };
  return (
    <NodeModalContainerStyle
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        minHeight: "300px",
        maxHeight: "calc(100vh - 150px)",
        width: "900px !important",
      }}
    >
      <Typography
        sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
        id="modal-modal-title"
        variant="h5"
        required
      >
        Select New Letter
      </Typography>
      <Box sx={{ px: 2, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            mt: 2,

            py: 1.5,
            pb: 2,
            borderTop: "1px solid #E5E3E3",
          }}
        ></Box>
        <Box>
          <TextField
            error={errors?.["from"]}
            label="From"
            variant="standard"
            type="email"
            required
            sx={{
              width: "65%",
              marginBottom: "20px",
            }}
            {...register("from", {
              required: "from is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            helperText={errors?.["from"]?.message}
          />
          <TextField
            error={errors?.["subject"]}
            label="Subject"
            variant="standard"
            required
            sx={{
              width: "80%",
              marginBottom: "20px",
            }}
            {...register("subject", { required: true })}
            helperText={errors?.["subject"]?.message}
          />
          <TextField
            error={errors?.["tag"]}
            label="tag"
            variant="standard"
            required
            sx={{
              width: "40%",
              marginBottom: "20px",
            }}
            {...register("tag", {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9]*$/,
                message: "allowed characters are a-z, A-Z, 0-9",
              },
            })}
            helperText={errors?.["tag"]?.message}
          />
        </Box>
        <Box sx={{ width: "100%", overflowY: "scroll", flex: "1 1 0%", px: 1 }}>
          <Controller
            rules={{
              required: "Select a Newsletter",
            }}
            render={({ field: { onChange, value } }) => (
              <Stack
                direction={"row"}
                spacing={0}
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  height: "300px",
                  flex: "1 1 0%",
                }}
                useFlexGap
                flexWrap="wrap"
              >
                {newsletters.map((card) => (
                  <TemplateCard
                    key={card.id}
                    onClick={() => onChange(card.id)}
                    style={{
                      cursor: "pointer",
                      margin: "8px",
                      borderWidth: "20px",
                      borderColor: "grey",
                      width: "144px",
                      height: "192px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor:
                        card.id === value ? "#101010" : "#D6DAE0",
                    }}
                  >
                    {/* {console.log("Card image data:", card.imageData)} */}

                    <TemplateImage
                      src={card.imageData ? card.imageData : tempreview}
                      alt="Template Preview"
                      style={{
                        padding: "10px",
                        width: "100%",
                        height: "100%", // Set the preview image height to 75% of the card height
                        objectFit: "contain", // Ensure the image covers the container
                      }}
                    />

                    <CardContent>
                      <Title
                        sx={{
                          color:
                            card.id === value
                              ? "#ffffff !important"
                              : "#000000",
                        }}
                        style={{
                          marginTop: "-5px",
                          marginBottom: "-1px",
                          fontSize: "1rem",
                          lineHeight: "normal",
                        }}
                      >
                        {card.title}
                      </Title>
                      <SubTitle>{card.description}</SubTitle>
                      {/* <SubTitle style={{ fontSize: "10px" }}>
                        {format(card?.lastUpdate,"dd MMM, yyyy")}
                      </SubTitle> */}
                    </CardContent>
                  </TemplateCard>
                ))}
              </Stack>
            )}
            control={control}
            name={"value"}
          />
          {errors?.["value"] && (
            <Typography color={"red"} variant="caption" display="block">
              {errors?.["value"]?.message}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            px: 2,
            py: 2,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <CreateButton type="submit" color="primary">
            Save
          </CreateButton>
        </Box>
      </Box>
    </NodeModalContainerStyle>
  );
};

export default NewsletterModalContent;
