import React from "react";
import PercentageUp from "../../assets/icons/PercentageUp.svg";
import PercentageDown from "../../assets/icons/PercentageDown.svg";
import PercentageZero from "../../assets/icons/PercentageZero.svg";
import {
  AnanlyticsBoxBadge,
  AnanlyticsBoxBadgeText,
} from "../../pages/UserDashboard/styled";
import { Box } from "@mui/material";

function PercentageBox({ number }) {
  return (
    <>
      {" "}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {number === 0 && (
          <AnanlyticsBoxBadge
            style={{
              background: "#F7F7F7",
              border: "1px solid #D4DAE1",
              width: "auto",
            }}
          >
            <AnanlyticsBoxBadgeText style={{ color: "#1A1B24" }}>
              {Math.floor(number)}%
            </AnanlyticsBoxBadgeText>
            <img
              src={PercentageZero}
              alt="PercentageZero"
              style={{ marginLeft: "2px" }}
            />
          </AnanlyticsBoxBadge>
        )}
        {number > 0 && (
          <AnanlyticsBoxBadge
            style={{
              background: "#E4FFF1",
              border: "1px solid #45C17",
              width: "auto",
            }}
          >
            <AnanlyticsBoxBadgeText style={{ color: "#009177" }}>
              +{Math.floor(number)}%
            </AnanlyticsBoxBadgeText>
            <img
              src={PercentageUp}
              alt="PercentageUp"
              style={{ marginLeft: "2px" }}
            />
          </AnanlyticsBoxBadge>
        )}
        {number < 0 && (
          <AnanlyticsBoxBadge
            style={{
              background: "#FDEDEE",
              border: "1px solid #DC3A50",
              width: "auto",
            }}
          >
            <AnanlyticsBoxBadgeText style={{ color: "#DC3A50" }}>
              {Math.floor(number)}%
            </AnanlyticsBoxBadgeText>
            <img
              src={PercentageDown}
              alt="PercentageDown"
              style={{ marginLeft: "2px" }}
            />
          </AnanlyticsBoxBadge>
        )}
      </Box>
    </>
  );
}

export default PercentageBox;
