import React, { useEffect, useState } from "react";
import { CreateButton, ModalContainerCreate } from "./styled";
import Modal from "@mui/material/Modal";
import {
  Box,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { validateTime } from "../../utils/pageStore";
import { useMutation } from "@apollo/client";
import {
  CREATE_TIMESLOT,
  UPDATE_TIMESLOT,
} from "../../graphql/mutations/timesheetMutation";
import { enqueueSnackbar } from "notistack";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const initialState = daysOfWeek.reduce((acc, day) => {
  acc[day] = {
    from: "00:00",
    to: "23:59",
    allDay: true,
    no: false,
  };
  return acc;
}, {});

const TimeSlotModal = ({
  open,
  handleClose,
  type = "workflow",
  timeSlotData,
}) => {
  const [timeSlots, setTimeSlots] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [createTimeSlot] = useMutation(CREATE_TIMESLOT);
  const [updateTimeSlot] = useMutation(UPDATE_TIMESLOT);
  console.log("timeslots", timeSlots);
  const handleInputChange = (day, field, value) => {
    setTimeSlots({
      ...timeSlots,
      [day]: {
        ...timeSlots[day],
        [field]: value,
      },
    });
  };
  useEffect(() => {
    if (open && timeSlotData) {
      const { id, type, ...restTimeSlots } = timeSlotData;
      const fetchedTimeSlot = daysOfWeek.reduce((acc, day) => {
        const { from, to, allDay, no } = restTimeSlots[day];
        acc[day] = { from, to, allDay, no };
        return acc;
      }, {});
      setTimeSlots(fetchedTimeSlot);
    }
  }, [timeSlotData, open]);

  const handleCheckboxChange = (day, field) => {
    const updatedFieldValue = !timeSlots[day][field];

    setTimeSlots({
      ...timeSlots,
      [day]: {
        ...timeSlots[day],
        [field]: updatedFieldValue,
        ...(field === "no" && { allDay: false }), // Uncheck allDay if 'no' is checked
      },
    });
  };

  const handleSubmit = async () => {
    let hasErrors = false;
    const newErrors = {};

    daysOfWeek.forEach((day) => {
      if (!timeSlots[day].allDay && !timeSlots[day].no) {
        if (!validateTime(timeSlots[day].from, timeSlots[day].to)) {
          newErrors[day] = "Invalid time range";
          hasErrors = true;
        }
      }
    });

    if (!hasErrors) {
      // Proceed with form submission
      setLoading(true);
      try {
        console.log("Form submitted successfully", timeSlots);

        let { data: dataUpdate, errors } = await (!timeSlotData?._id
          ? createTimeSlot({
              variables: {
                input: { type, ...timeSlots },
              },
            })
          : updateTimeSlot({
              variables: {
                id: timeSlotData?._id,
                input: { type, ...timeSlots },
              },
            }));
        if (!dataUpdate && errors) {
          enqueueSnackbar(
            (errors && errors[0] && errors[0]?.message) ||
              `Error Updating TimeSlot`,
            {
              variant: "error",
            }
          );
          return;
        } else {
          handleClose(true);
          enqueueSnackbar(`Updating Time slot successful`, {
            variant: "success",
          });
        }
      } catch (e) {
        enqueueSnackbar(`Time slot Update failed ${e}`, {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainerCreate>
          <Typography
            sx={{ fontWeight: 700, fontFamily: "Switzer", my: 1 }}
            id="modal-modal-title"
            variant="h6"
          >
            {type === "pipeline" ? "Sales" : ""} Weekly Time Slot
          </Typography>
          <Box
            sx={{
              borderTop: "1px solid #a09d9d",
              borderBottom: "1px solid ",
              py: 2,
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
            ></Box>
            {daysOfWeek.map((day, index) => (
              <Stack
                key={day}
                direction="row"
                spacing={1.5}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Switzer",
                    width: "90px",
                  }}
                >
                  {daysOfWeek[index]}
                </Typography>
                <input
                  className="time-input"
                  value={timeSlots[day].from}
                  disabled={timeSlots[day].allDay || timeSlots[day].no}
                  onChange={(e) =>
                    handleInputChange(day, "from", e.target.value)
                  }
                  type="time"
                />
                <Typography>to</Typography>
                <input
                  className="time-input"
                  value={timeSlots[day].to}
                  disabled={timeSlots[day].allDay || timeSlots[day].no}
                  onChange={(e) => handleInputChange(day, "to", e.target.value)}
                  type="time"
                />
                <FormControlLabel
                  required
                  control={<Checkbox title={`${day} take mails`} />}
                  disabled={timeSlots[day].no}
                  checked={timeSlots[day].allDay}
                  label="All day"
                  onChange={() => handleCheckboxChange(day, "allDay")}
                />
                <FormControlLabel
                  color="error"
                  required
                  control={<Checkbox title={`${day} no mails`} />}
                  checked={timeSlots[day].no}
                  label="No"
                  onChange={() => handleCheckboxChange(day, "no")}
                />
                {!timeSlots[day].allDay &&
                  !timeSlots[day].no &&
                  !validateTime(timeSlots[day].from, timeSlots[day].to) && (
                    <span style={{ color: "red" }}>Invalid time range</span>
                  )}
              </Stack>
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1 }}>
            <CreateButton
              disabled={loading}
              sx={{
                display: "inline-flex",
                "&.Mui-disabled": { color: "#fff", opacity: 0.7 },
              }}
              color="primary"
              onClick={handleSubmit}
            >
              {loading && (
                <CircularProgress
                  size={25}
                  sx={{ "svg circle": { stroke: "#ffffff" }, mr: 2 }}
                />
              )}
              Update Time Slot
            </CreateButton>
          </Box>
        </ModalContainerCreate>
      </Modal>
    </>
  );
};

export default TimeSlotModal;
