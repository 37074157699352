import React from 'react';
import { useQuery } from "@apollo/client";
import { height } from "@mui/system";
import {Link,useParams} from "react-router-dom"
import OrganizationBrands from "../../components/OrganizationBrands";
import Spinner from "../../components/Spinner";
import UpdateOrganizationModal from "../../components/UpdateOrganizationModal";
import { GET_ORGANIZATION } from "../../graphql/queries/organizationQueries";

function SingularOrganization() {
    const { id } = useParams();
    const { loading, error, data } = useQuery(GET_ORGANIZATION, { variables: { input: { organizationId: id } } });

    // if (loading) return <Spinner />;
    if (error) return <p>Something Went Wrong</p>;
  return (
    <>
    
    {!loading && !error && (
        
      <div className='mx-auto w-75 card p-5  mt-5'>
        <Link to='/organization' className='btn btn-light btn-sm w-25 d-inline ms-auto'>
          Back
        </Link>

        <h1>{data.organization.name}</h1>
        <p>{data.organization.industry}</p>
        {/* <p>{data.organization.imageUrl}</p> */}
        <img src={data.organization.imageUrl} style={{width:"150px", height:"150px" , objectFit:"cover"}}/>
        <p className='lead'>{data.organization.domainUrl}</p>

        <UpdateOrganizationModal organization={data.organization}/>

        <OrganizationBrands organization={data.organization}/>

      </div>
    )}
  </>
  )
}

export default SingularOrganization