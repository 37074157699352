const countryCodes = [
  { country: "AF", code: "+93", timezone: ["Asia/Kabul"] },
  { country: "AL", code: "+355", timezone: ["Europe/Tirane"] },
  { country: "DZ", code: "+213", timezone: ["Africa/Algiers"] },
  { country: "AS", code: "+1-684", timezone: ["Pacific/Pago_Pago"] },
  { country: "AD", code: "+376", timezone: ["Europe/Andorra"] },
  { country: "AO", code: "+244", timezone: ["Africa/Luanda"] },
  { country: "AI", code: "+1-264", timezone: ["America/Anguilla"] },
  { country: "AQ", code: "+672", timezone: ["Antarctica/McMurdo"] },
  { country: "AG", code: "+1-268", timezone: ["America/Antigua"] },
  {
    country: "AR",
    code: "+54",
    timezone: ["America/Argentina/Buenos_Aires"],
  },
  { country: "AM", code: "+374", timezone: ["Asia/Yerevan"] },
  { country: "AW", code: "+297", timezone: ["America/Aruba"] },
  { country: "AU", code: "+61", timezone: ["Australia/Sydney"] },
  { country: "AT", code: "+43", timezone: ["Europe/Vienna"] },
  { country: "AZ", code: "+994", timezone: ["Asia/Baku"] },
  { country: "BS", code: "+1-242", timezone: ["America/Nassau"] },
  { country: "BH", code: "+973", timezone: ["Asia/Bahrain"] },
  { country: "BD", code: "+880", timezone: ["Asia/Dhaka"] },
  { country: "BB", code: "+1-246", timezone: ["America/Barbados"] },
  { country: "BY", code: "+375", timezone: ["Europe/Minsk"] },
  { country: "BE", code: "+32", timezone: ["Europe/Brussels"] },
  { country: "BZ", code: "+501", timezone: ["America/Belize"] },
  { country: "BJ", code: "+229", timezone: ["Africa/Porto-Novo"] },
  { country: "BM", code: "+1-441", timezone: ["Atlantic/Bermuda"] },
  { country: "BT", code: "+975", timezone: ["Asia/Thimphu"] },
  { country: "BO", code: "+591", timezone: ["America/La_Paz"] },
  { country: "BA", code: "+387", timezone: ["Europe/Sarajevo"] },
  { country: "BW", code: "+267", timezone: ["Africa/Gaborone"] },
  { country: "BV", code: "", timezone: ["Antarctica/Bouvet_Island"] },
  { country: "BR", code: "+55", timezone: ["America/Sao_Paulo"] },
  { country: "IO", code: "", timezone: ["Indian/Chagos"] },
  { country: "BN", code: "+673", timezone: ["Asia/Brunei"] },
  { country: "BG", code: "+359", timezone: ["Europe/Sofia"] },
  { country: "BF", code: "+226", timezone: ["Africa/Ouagadougou"] },
  { country: "BI", code: "+257", timezone: ["Africa/Bujumbura"] },
  { country: "KH", code: "+855", timezone: ["Asia/Phnom_Penh"] },
  { country: "CM", code: "+237", timezone: ["Africa/Douala"] },
  { country: "CA", code: "+1", timezone: ["America/Toronto"] },
  { country: "CV", code: "+238", timezone: ["Atlantic/Cape_Verde"] },
  { country: "KY", code: "+1-345", timezone: ["America/Cayman"] },
  { country: "CF", code: "+236", timezone: ["Africa/Bangui"] },
  { country: "TD", code: "+235", timezone: ["Africa/Ndjamena"] },
  { country: "CL", code: "+56", timezone: ["America/Santiago"] },
  { country: "CN", code: "+86", timezone: ["Asia/Shanghai"] },
  { country: "CX", code: "+53", timezone: ["Indian/Christmas"] },
  { country: "CC", code: "+61", timezone: ["Indian/Cocos"] },
  { country: "CO", code: "+57", timezone: ["America/Bogota"] },
  { country: "KM", code: "+269", timezone: ["Indian/Comoro"] },
  { country: "CD", code: "+243", timezone: ["Africa/Kinshasa"] },
  { country: "CG", code: "+242", timezone: ["Africa/Brazzaville"] },
  { country: "CK", code: "+682", timezone: ["Pacific/Rarotonga"] },
  { country: "CR", code: "+506", timezone: ["America/Costa_Rica"] },
  { country: "CI", code: "+225", timezone: ["Africa/Abidjan"] },
  { country: "HR", code: "+385", timezone: ["Europe/Zagreb"] },
  { country: "CU", code: "+53", timezone: ["America/Havana"] },
  { country: "CY", code: "+357", timezone: ["Asia/Nicosia"] },
  { country: "CZ", code: "+420", timezone: ["Europe/Prague"] },
  { country: "DK", code: "+45", timezone: ["Europe/Copenhagen"] },
  { country: "DJ", code: "+253", timezone: ["Africa/Djibouti"] },
  { country: "DM", code: "+1-767", timezone: ["America/Dominica"] },
  {
    country: "DO",
    code: "+1-809",
    timezone: ["America/Santo_Domingo"],
  },
  { country: "TP", code: "+670", timezone: ["Asia/Dili"] },
  { country: "EC", code: "+593", timezone: ["America/Guayaquil"] },
  { country: "EG", code: "+20", timezone: ["Africa/Cairo"] },
  { country: "SV", code: "+503", timezone: ["America/El_Salvador"] },
  { country: "GQ", code: "+240", timezone: ["Africa/Malabo"] },
  { country: "ER", code: "+291", timezone: ["Africa/Asmara"] },
  { country: "EE", code: "+372", timezone: ["Europe/Tallinn"] },
  { country: "ET", code: "+251", timezone: ["Africa/Addis_Ababa"] },
  { country: "FK", code: "+500", timezone: ["Atlantic/Stanley"] },
  { country: "FO", code: "+298", timezone: ["Atlantic/Faroe"] },
  { country: "FJ", code: "+679", timezone: ["Pacific/Fiji"] },
  { country: "FI", code: "+358", timezone: ["Europe/Helsinki"] },
  { country: "FR", code: "+33", timezone: ["Europe/Paris"] },
  { country: "GF", code: "+594", timezone: ["America/Cayenne"] },
  { country: "PF", code: "+689", timezone: ["Pacific/Tahiti"] },
  { country: "GA", code: "+241", timezone: ["Africa/Libreville"] },
  { country: "GM", code: "+220", timezone: ["Africa/Banjul"] },
  { country: "GE", code: "+995", timezone: ["Asia/Tbilisi"] },
  {
    country: "DE",
    code: "+49",
    timezone: ["Europe/Berlin", "Europe/Busingen"],
  },
  { country: "GH", code: "+233", timezone: ["Africa/Accra"] },
  { country: "GI", code: "+350", timezone: ["Europe/Gibraltar"] },
  { country: "GR", code: "+30", timezone: ["Europe/Athens"] },
  {
    country: "GL",
    code: "+299",
    timezone: [
      "America/Godthab",
      "America/Danmarkshavn",
      "America/Scoresbysund",
      "America/Thule",
    ],
  },
  { country: "GD", code: "+1-473", timezone: ["America/Grenada"] },
  { country: "GP", code: "+590", timezone: ["America/Guadeloupe"] },
  { country: "GU", code: "+1-671", timezone: ["Pacific/Guam"] },
  { country: "GT", code: "+502", timezone: ["America/Guatemala"] },
  { country: "GN", code: "+224", timezone: ["Africa/Conakry"] },
  { country: "GW", code: "+245", timezone: ["Africa/Bissau"] },
  { country: "GY", code: "+592", timezone: ["America/Guyana"] },
  { country: "HT", code: "+509", timezone: ["America/Port-au-Prince"] },
  { country: "HN", code: "+504", timezone: ["America/Tegucigalpa"] },
  { country: "HK", code: "+852", timezone: ["Asia/Hong_Kong"] },
  { country: "HU", code: "+36", timezone: ["Europe/Budapest"] },
  { country: "IS", code: "+354", timezone: ["Atlantic/Reykjavik"] },
  { country: "IN", code: "+91", timezone: ["Asia/Kolkata"] },
  {
    country: "ID",
    code: "+62",
    timezone: [
      "Asia/Jakarta",
      "Asia/Pontianak",
      "Asia/Makassar",
      "Asia/Jayapura",
    ],
  },
  { country: "IR", code: "+98", timezone: ["Asia/Tehran"] },
  { country: "IQ", code: "+964", timezone: ["Asia/Baghdad"] },
  { country: "IE", code: "+353", timezone: ["Europe/Dublin"] },
  { country: "IL", code: "+972", timezone: ["Asia/Jerusalem"] },
  { country: "IT", code: "+39", timezone: ["Europe/Rome"] },
  { country: "JM", code: "+1-876", timezone: ["America/Jamaica"] },
  { country: "JP", code: "+81", timezone: ["Asia/Tokyo"] },
  { country: "JO", code: "+962", timezone: ["Asia/Amman"] },
  {
    country: "KZ",
    code: "+7",
    timezone: [
      "Asia/Almaty",
      "Asia/Qyzylorda",
      "Asia/Aqtobe",
      "Asia/Aqtau",
      "Asia/Oral",
    ],
  },
  { country: "KE", code: "+254", timezone: ["Africa/Nairobi"] },
  {
    country: "KI",
    code: "+686",
    timezone: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
  },
  { country: "KP", code: "+850", timezone: ["Asia/Pyongyang"] },
  { country: "KR", code: "+82", timezone: ["Asia/Seoul"] },
  { country: "KW", code: "+965", timezone: ["Asia/Kuwait"] },
  { country: "KG", code: "+996", timezone: ["Asia/Bishkek"] },
  { country: "LA", code: "+856", timezone: ["Asia/Vientiane"] },
  { country: "LV", code: "+371", timezone: ["Europe/Riga"] },
  { country: "LB", code: "+961", timezone: ["Asia/Beirut"] },
  { country: "LS", code: "+266", timezone: ["Africa/Maseru"] },
  { country: "LR", code: "+231", timezone: ["Africa/Monrovia"] },
  { country: "LY", code: "+218", timezone: ["Africa/Tripoli"] },
  { country: "LI", code: "+423", timezone: ["Europe/Vaduz"] },
  { country: "LT", code: "+370", timezone: ["Europe/Vilnius"] },
  { country: "LU", code: "+352", timezone: ["Europe/Luxembourg"] },
  { country: "MO", code: "+853", timezone: ["Asia/Macau"] },
  { country: "MK", code: "+389", timezone: ["Europe/Skopje"] },
  { country: "MG", code: "+261", timezone: ["Indian/Antananarivo"] },
  { country: "MW", code: "+265", timezone: ["Africa/Blantyre"] },
  {
    country: "MY",
    code: "+60",
    timezone: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
  },
  { country: "MV", code: "+960", timezone: ["Indian/Maldives"] },
  { country: "ML", code: "+223", timezone: ["Africa/Bamako"] },
  { country: "MT", code: "+356", timezone: ["Europe/Malta"] },
  {
    country: "MH",
    code: "+692",
    timezone: ["Pacific/Majuro", "Pacific/Kwajalein"],
  },
  { country: "MQ", code: "+596", timezone: ["America/Martinique"] },
  { country: "MR", code: "+222", timezone: ["Africa/Nouakchott"] },
  { country: "MU", code: "+230", timezone: ["Indian/Mauritius"] },
  { country: "YT", code: "+269", timezone: ["Indian/Mayotte"] },
  {
    country: "MX",
    code: "+52",
    timezone: [
      "America/Mexico_City",
      "America/Cancun",
      "America/Merida",
      "America/Monterrey",
      "America/Matamoros",
      "America/Mazatlan",
      "America/Chihuahua",
      "America/Hermosillo",
      "America/Tijuana",
      "America/Bahia_Banderas",
    ],
  },
  {
    country: "FM",
    code: "+691",
    timezone: ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"],
  },
  { country: "MD", code: "+373", timezone: ["Europe/Chisinau"] },
  { country: "MC", code: "+377", timezone: ["Europe/Monaco"] },
  {
    country: "MN",
    code: "+976",
    timezone: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
  },
  { country: "MS", code: "+1-664", timezone: ["America/Montserrat"] },
  { country: "MA", code: "+212", timezone: ["Africa/Casablanca"] },
  { country: "MZ", code: "+258", timezone: ["Africa/Maputo"] },
  { country: "MM", code: "+95", timezone: ["Asia/Yangon"] },
  { country: "NA", code: "+264", timezone: ["Africa/Windhoek"] },
  { country: "NR", code: "+674", timezone: ["Pacific/Nauru"] },
  { country: "NP", code: "+977", timezone: ["Asia/Kathmandu"] },
  { country: "NL", code: "+31", timezone: ["Europe/Amsterdam"] },
  {
    country: "AN",
    code: "+599",
    timezone: [
      "America/Curacao",
      "America/Kralendijk",
      "America/Oranjestad",
      "America/Lower_Princes",
    ],
  },
  { country: "NC", code: "+687", timezone: ["Pacific/Noumea"] },
  {
    country: "NZ",
    code: "+64",
    timezone: ["Pacific/Auckland", "Pacific/Chatham"],
  },
  { country: "NI", code: "+505", timezone: ["America/Managua"] },
  { country: "NE", code: "+227", timezone: ["Africa/Niamey"] },
  { country: "NG", code: "+234", timezone: ["Africa/Lagos"] },
  { country: "NU", code: "+683", timezone: ["Pacific/Niue"] },
  { country: "NF", code: "+672", timezone: ["Pacific/Norfolk"] },
  { country: "MP", code: "+1-670", timezone: ["Pacific/Saipan"] },
  { country: "NO", code: "+47", timezone: ["Europe/Oslo"] },
  { country: "OM", code: "+968", timezone: ["Asia/Muscat"] },
  { country: "PK", code: "+92", timezone: ["Asia/Karachi"] },
  { country: "PS", code: "+970", timezone: ["Asia/Gaza", "Asia/Hebron"] },
  { country: "PA", code: "+507", timezone: ["America/Panama"] },
  { country: "PG", code: "+675", timezone: ["Pacific/Port_Moresby"] },
  { country: "PY", code: "+595", timezone: ["America/Asuncion"] },
  { country: "PE", code: "+51", timezone: ["America/Lima"] },
  { country: "PH", code: "+63", timezone: ["Asia/Manila"] },
  { country: "PL", code: "+48", timezone: ["Europe/Warsaw"] },
  {
    country: "PT",
    code: "+351",
    timezone: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
  },
  { country: "PR", code: "+1-787", timezone: ["America/Puerto_Rico"] },
  { country: "PR", code: "+1-939", timezone: ["America/Puerto_Rico"] },
  { country: "QA", code: "+974", timezone: ["Asia/Qatar"] },
  { country: "RE", code: "+262", timezone: ["Indian/Reunion"] },
  { country: "RO", code: "+40", timezone: ["Europe/Bucharest"] },
  {
    country: "RU",
    code: "+7",
    timezone: [
      "Europe/Moscow",
      "Asia/Kamchatka",
      "Asia/Irkutsk",
      "Asia/Yekaterinburg",
      "Asia/Omsk",
      "Asia/Novosibirsk",
      "Asia/Krasnoyarsk",
      "Asia/Barnaul",
      "Asia/Tomsk",
      "Asia/Sakhalin",
    ],
  },
  { country: "RW", code: "+250", timezone: ["Africa/Kigali"] },
  { country: "SH", code: "+290", timezone: ["Atlantic/St_Helena"] },
  { country: "KN", code: "+1-869", timezone: ["America/St_Kitts"] },
  { country: "LC", code: "+1-758", timezone: ["America/St_Lucia"] },
  { country: "PM", code: "+508", timezone: ["America/Marigot"] },
  { country: "VC", code: "+1-784", timezone: ["America/St_Vincent"] },
  { country: "WS", code: "+685", timezone: ["Pacific/Apia"] },
  { country: "SM", code: "+378", timezone: ["Europe/San_Marino"] },
  { country: "ST", code: "+239", timezone: ["Africa/Sao_Tome"] },
  { country: "SA", code: "+966", timezone: ["Asia/Riyadh"] },
  { country: "SN", code: "+221", timezone: ["Africa/Dakar"] },
  { country: "SC", code: "+248", timezone: ["Indian/Mayotte"] },
  { country: "SL", code: "+232", timezone: ["Africa/Freetown"] },
  { country: "SG", code: "+65", timezone: ["Asia/Singapore"] },
  { country: "SK", code: "+421", timezone: ["Europe/Bratislava"] },
  { country: "SI", code: "+386", timezone: ["Europe/Ljubljana"] },
  { country: "SB", code: "+677", timezone: ["Pacific/Guadalcanal"] },
  { country: "SO", code: "+252", timezone: ["Africa/Mogadishu"] },
  { country: "ZA", code: "+27", timezone: ["Africa/Johannesburg"] },
  {
    country: "ES",
    code: "+34",
    timezone: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
  },
  { country: "LK", code: "+94", timezone: ["Asia/Colombo"] },
  { country: "SD", code: "+249", timezone: ["Africa/Khartoum"] },
  { country: "SR", code: "+597", timezone: ["America/Paramaribo"] },
  { country: "SZ", code: "+268", timezone: ["Africa/Mbabane"] },
  { country: "SE", code: "+46", timezone: ["Europe/Stockholm"] },
  { country: "CH", code: "+41", timezone: ["Europe/Zurich"] },
  { country: "SY", code: "+963", timezone: ["Asia/Damascus"] },
  { country: "TW", code: "+886", timezone: ["Asia/Taipei"] },
  { country: "TJ", code: "+992", timezone: ["Asia/Dushanbe"] },
  { country: "TZ", code: "+255", timezone: ["Africa/Dar_es_Salaam"] },
  { country: "TH", code: "+66", timezone: ["Asia/Bangkok"] },
  { country: "TK", code: "+690", timezone: ["Pacific/Tokelau"] },
  { country: "TO", code: "+676", timezone: ["Pacific/Tongatapu"] },
  { country: "TT", code: "+1-868", timezone: ["America/Port_of_Spain"] },
  { country: "TN", code: "+216", timezone: ["Africa/Tunis"] },
  { country: "TR", code: "+90", timezone: ["Europe/Istanbul"] },
  { country: "TM", code: "+993", timezone: ["Asia/Ashgabat"] },
  { country: "TC", code: "+1-649", timezone: ["America/Grand_Turk"] },
  { country: "TV", code: "+688", timezone: ["Pacific/Tongatapu"] },
  { country: "UG", code: "+256", timezone: ["Africa/Kampala"] },
  {
    country: "UA",
    code: "+380",
    timezone: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Lviv"],
  },
  { country: "AE", code: "+971", timezone: ["Asia/Dubai"] },
  { country: "GB", code: "+44", timezone: ["Europe/London"] },
  {
    country: "US",
    code: "+1",
    timezone: [
      "America/New_York",
      "America/Los_Angeles",
      "America/Chicago",
      "America/Denver",
      "America/Anchorage",
      "Pacific/Honolulu",
      "America/Adak",
    ],
  },
  { country: "UY", code: "+598", timezone: ["America/Montevideo"] },
  { country: "UZ", code: "+998", timezone: ["Asia/Tashkent"] },
  { country: "VU", code: "+678", timezone: ["Pacific/Efate"] },
  { country: "VA", code: "+418", timezone: ["Europe/Vatican"] },
  { country: "VE", code: "+58", timezone: ["America/Caracas"] },
  { country: "VN", code: "+84", timezone: ["Asia/Ho_Chi_Minh"] },
  { country: "VI", code: "+1-284", timezone: ["America/Tortola"] },
  {
    country: "VQ",
    code: "+1-340",
    timezone: ["America/St_Thomas", "America/St_John"],
  },
  { country: "WF", code: "+681", timezone: ["Pacific/Wallis"] },
  { country: "YE", code: "+967", timezone: ["Asia/Aden"] },
  { country: "ZM", code: "+260", timezone: ["Africa/Lusaka"] },
  { country: "ZW", code: "+263", timezone: ["Africa/Harare"] },
];

export default countryCodes;
