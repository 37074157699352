import ColorButton from "../../ColorButton";
import LoadingComp from "../Loading";
import { Step2SubDiv } from "../ReviewComp/styled";

export default ({
  step,
  newStep,
  recordingData,
  loading,
  uploadVideo,
  handleNext,
  setStep,
  setNewStep,
  setTrail,
  isCheckedSms,
  isCheckedEmail,
  videoData,
}) => {
  let disabled =
    (step === 4 && !isCheckedSms && !isCheckedEmail) ||
    (step === 2 && videoData.length === 0);
  return (
    <>
      {(step === 2 || step === 3 || step === 4 || step === 5) && (
        <Step2SubDiv
          style={{
            marginBottom: "2rem",
          }}
        >
          <ColorButton
            title={"Back"}
            background={"#F1F3EC"}
            width={"48%"}
            greyBg={"greyBg"}
            buttonTextStyle={{ fontSize: "18px" }}
            onClick={() => {
              setStep(step - 1);
              setNewStep(newStep - 1);
              setTrail(recordingData);
            }}
          />
          <ColorButton
            pointerEvents="auto"
            // pointerEvents={
            //   recordingData.videoUrl ? "auto" : "none"
            // }
            buttonTextStyle={{ fontSize: "18px" }}
            title={
              step === 5 && loading ? (
                <LoadingComp loading={loading} />
              ) : step === 5 && !loading ? (
                "Submit"
              ) : (
                "Next"
              )
            }
            disabled={disabled}
            background={"#1A1B24"}
            width={"48%"}
            // onClick={() => uploadVideo()}
            onClick={step === 5 ? uploadVideo : handleNext}
          />
        </Step2SubDiv>
      )}
    </>
  );
};
