import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const BoxHeader = styled(Box)`
  width: 40%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BoxHeaderTitle = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1a1b24;
`;
export const BoxHeaderSubText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
`;
export const BoxSection = styled(Box)`
  width: 40%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BoxImage = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;
export const BoxImageButton = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 26px;
  width: 30%;
  height: 40px;
  background: #8d6cff;
  border: 1px solid #8d6cff;
  border-radius: 7px;
`;
export const BoxImageButtonBrand = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 23px;
  width: 22%;
  margin-top: 1rem;
  height: 40px;
  background: #8d6cff;
  border: 1px solid #8d6cff;
  border-radius: 7px;
`;
export const BoxImageText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1a1b24;
  margin: 2rem 0;
`;
export const BoxImageButtonText = styled(Typography)`
  // width: 84px;
  height: 17px;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ProgressBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 599px;
`;

export const StepContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: 2rem;
`;

export const StepNumber = styled(Box)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-family: "Dm Sans";
  background-color: #ffffff;
  border: ${(props) =>
    props.isActive ? "2px solid #8D6CFF" : "2px solid #D4DAE1"};
  color: ${(props) => (props.isActive ? "#1A1B24" : "#5D6272")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  //   border: ${(props) => (props.isActive ? "2px solid #2196f3" : "none")};
`;

export const StepLabel = styled(Box)`
  margin-left: 10px;
  font-family: "Dm Sans";
  color: #5d6272;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;
// export const StepLabel = styled(Box)`
//   margin-left: 10px;
//   color: ${(props) => (props.isActive ? '#2196f3' : '#000')};
//   font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
// `;
export const SignUpContainer = styled(Box)`
  display: flex;
  align-items: flex-start;
  width: ${(props) => (props?.isMobile ? "90%" : "70%")};
  justify-content: center;
  flex-direction: column;
  max-width: 769px;
  margin: 1rem auto;
`;
export const SignUpHeader = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;
  text-transform: capitalize;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 1rem;
`;
export const SignUpSubHeader = styled(Typography)`
  // width: 130px;
  height: 25px;

  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const PreviousButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  gap: 10px;
  text-transform: capitalize;
  // width: 180px;
  height: 48px;

  /* White */

  background: #ffffff;
  /* Element */

  // border: 1px solid #d4dae1;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border: ${(props) =>
    props?.focus ? " 1px solid #007bf7" : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.focus ? " 1px solid #007bf7" : "1px solid #1a1b24"};
  }
`;
export const SkipButton = styled(Button)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  border: none;
  /* identical to box height, or 24px */
  text-transform: none;
  text-align: center;

  /* Primary/French Blue/French Blue 100% */

  color: #0075ce;
`;
export const PreviousButtonText = styled(Typography)`
  // width: 66px;
  // height: 24px;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;

  /* Secondary Main */

  color: #28262c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const CheckBoxText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  // margin-left:0.5rem;
`;
