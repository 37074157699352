import MarketingLayout from "../../components/MarketingLayout";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MiniMap,
  Controls,
} from "reactflow";
import "reactflow/dist/style.css";
import {
  CanvasContainerStyle,
  SideContentBox,
  WorkflowNav,
} from "./index.styled";
import { nodeTypesDataList } from "../../utils/pageStore";
import CustomNodeItem from "./NodeItem";
import { nanoid } from "nanoid";
import { useSnackbar } from "notistack";
import CustomNodeItemCard from "./CustomNode";
import StarterNode from "./StarterNode";
import NodeOptionModal from "./NodeOptionModal";
import { CreateButton } from "../WebsiteBuilder/styled";
import {
  RUN_WORKFLOW,
  UPDATE_WORKFLOW,
} from "../../graphql/mutations/workflowMutations";
import { GET_SINGLE_WORKFLOW } from "../../graphql/queries/workflowQueries";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
  DeleteModalContainer,
  StyledSectionButton,
} from "../../components/TagsModal/styled";
import DeleteNodeModal from "./deleteNodeModal";
import TimeSlotModals from "../../components/TimeSlotsModal";
import { useWorkflowContext } from "./workflowContext";
import CustomEdge from "./customEdge";
import StarterModal from "./StarterModal";

const initialNode = [
  {
    id: "starter-id",
    type: "starter",
    position: { x: 390, y: 97.6 },
    data: {
      title: nodeTypesDataList["starter"].name,
      description: nodeTypesDataList["starter"].description,
      dragHandle: ".custom-drag-handle",
      completed: false,
      current: false,
      metadata: {},
      type: "starter",
    },
  },
];

const { starter, ...otherDataList } = nodeTypesDataList;

const edgeTypes = {
  default: CustomEdge,
};

const WorkflowById = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    setUnAttached,
    setEmptyData,
    emptyData,
    isPublished,
    setIsPublished,
  } = useWorkflowContext();
  const [openTimeSlot, setOpenTimeSlot] = useState(false);
  const [updateWorkflow] = useMutation(UPDATE_WORKFLOW); //update workflow
  const [runWorkflow] = useMutation(RUN_WORKFLOW); //run workflow mutation
  const [openPublish, setOpenPublish] = useState(); //publish prompt modal
  const firstMount = useRef(true); //mount once to set workflow data
  const [nodes, setNodes, onNodesChange] = useNodesState(); //nodes
  const [edges, setEdges, onEdgesChange] = useEdgesState([]); //edges
  const [selectedNode, setSelectedNode] = useState(); //selected node
  const [reactFlowInstance, setReactFlowInstance] = useState(); //react flow instance state
  const instanceRef = useRef(); //react flow instance ref
  const intervalRef = useRef(); //auto update interval id as ref
  const { id } = useParams(); //workflow id

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const { data } = useQuery(GET_SINGLE_WORKFLOW, {
    variables: {
      id,
    },
  });
  // const [isPublished, setIsPublished] = useState(data?.workflow?.isPublished);
  // console.log({ selectedNode });
  // console.log({ nodes, edges });

  const saveNode = useCallback(
    (updatedNode) => {
      if (emptyData.find(({ id }) => id === updatedNode.id)) {
        setEmptyData(emptyData?.filter(({ id }) => id !== updatedNode?.id));
      }
      setNodes((nodes) =>
        nodes?.map((node) => (node.id === updatedNode?.id ? updatedNode : node))
      );
      // setSelectedNode(updatedNode);
    },
    [emptyData]
  );

  useEffect(() => {
    if (data?.workflow instanceof Object && firstMount.current) {
      setNodes(
        data?.workflow.nodes
          ? JSON.parse(data?.workflow?.nodes ?? "[]")
          : initialNode
      );
      setEdges(JSON.parse(data?.workflow?.edges ?? "[]"));
      setIsPublished(data?.workflow?.isPublished);
      firstMount.current = false;
    }
  }, [data]);

  const handleSave = async (opts) => {
    const options = opts ?? {};
    try {
      let input = {
        edges: JSON.stringify(instanceRef.current?.getEdges()),
        nodes: JSON.stringify(instanceRef.current?.getNodes()),
        nodesLogic: instanceRef.current
          ?.getNodes()
          .map(({ id, type, data: { metadata } }) => {
            const checkPublish = opts?.isPublished ?? false;
            const mailType = type === "email" || type === "newsletter";
            let newMetaData = metadata;
            if (mailType && checkPublish) {
              const tagPattern = metadata?.tag?.[0] ?? "";
              const regex = /^([a-zA-Z0-9]+)-wf-(\d+)$/;
              const match = tagPattern.match(regex);
              const tag = match?.[1] ?? "";
              newMetaData = { ...metadata, tag: [`${tag}-wf-${Date.now()}`] };
            }
            return {
              id,
              type,
              metadata: newMetaData,
            };
          }),
        edgesLogic: instanceRef.current
          ?.getEdges()
          .map(({ sourceHandle, source, target }) => ({
            sourceHandle,
            source,
            target,
          })),
        ...options,
        _id: id,
      };

      let { data: dataUpdate, errors } = await updateWorkflow({
        variables: {
          input,
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error ${
              opts
                ? opts.isPublished
                  ? "publishing"
                  : "unpublishing"
                : "updating"
            } workflow`,
          {
            variant: "error",
          }
        );

        return { error: errors };
      }
      enqueueSnackbar(
        `${
          opts ? (opts.isPublished ? "publishing" : "unpublishing") : "updating"
        } workflow successful`,
        {
          variant: "success",
        }
      );

      if (dataUpdate) {
        if (opts) {
          setIsPublished(dataUpdate.updateWorkflow.isPublished);
        }
        return { success: dataUpdate };
      }
    } catch (e) {
      enqueueSnackbar(`Workflow Update failed ${e}`, {
        variant: "error",
      });
      return { error: e };
    }
  };

  const runWorkflowClick = async () => {
    try {
      let { data: dataUpdate, errors } = await runWorkflow({
        variables: {
          _id: id,
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error updating workflow`,
          {
            variant: "error",
          }
        );
        return;
      }
      enqueueSnackbar(`Updating workflow successful`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`Workflow Update failed ${e}`, {
        variant: "error",
      });
    }
  };

  // useEffect(() => {
  //   if (!reactFlowInstance) return;
  //   const { setCenter } = reactFlowInstance;

  //   if (nodes.length > 0) {
  //     const node = nodes[0];

  //     const x = node.position.x + node.width / 2;
  //     const y = node.position.y + node.height / 2;
  //     const zoom = 1;

  //     setCenter(x, y, { zoom, duration: 1000 });
  //   }

  // }, [reactFlowInstance])

  const scrollToNode = useCallback(
    (node) => {
      if (!reactFlowInstance) return;
      const { setCenter } = reactFlowInstance;
      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1.1;

      setCenter(x, y, { zoom, duration: 1000 });
    },
    [reactFlowInstance]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const nodeTypes = useMemo(
    () => ({
      starter: StarterNode,
      tagged: CustomNodeItemCard,
      trigger: CustomNodeItemCard, //not making senses
      delay: CustomNodeItemCard,
      time: CustomNodeItemCard,
      ifElseCondition: CustomNodeItemCard,
      email: CustomNodeItemCard,
      newsletter: CustomNodeItemCard,
    }),
    []
  );

  useEffect(() => {
    if (isPublished) {
      clearInterval(intervalRef.current);
      return;
    }
    const intervalId = setInterval(() => {
      handleSave();
    }, 30 * 1000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, [isPublished]);

  //handling drop event on workflow canvas
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      // console.log("this is drop");
      const type = event.dataTransfer.getData("application/reactflow");
      // console.log("this is type", { type });
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }
      // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
      // and you don't need to subtract the reactFlowBounds.left/top anymore
      // details: https://reactflow.dev/whats-new/2023-11-10
      if (!reactFlowInstance) return;
      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const newNode = {
        id: nanoid(),
        type,
        position,
        data: {
          title: nodeTypesDataList[type].name,
          description: nodeTypesDataList[type].description,
          completed: false,
          current: false,
          metadata: {},
          type: type,
        },
      };
      //@ts-ignore
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);
          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );
          // console.log({ connectedEdges, remainingEdges, incomers, outgoers });
          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const findUnattachedNodes = (nodes, edges) => {
    const attachedNodes = new Set();

    edges.forEach((edge) => {
      attachedNodes.add(edge.target);
    });

    const unattachedNodes = nodes.filter((node) => {
      // Exclude "starter-id" from the unattached nodes
      if (node.id === "starter-id") {
        return false;
      }
      return !attachedNodes.has(node.id);
    });

    return unattachedNodes;
  };

  return (
    <MarketingLayout
      sxSidebar={{ bgcolor: "#101010" }}
      sxMain={{ overflow: "hidden" }}
      sideBarContent={
        <SideContentBox>
          <CreateButton
            sx={{
              margin: 2,
            }}
            onClick={() => {
              setOpenTimeSlot(true);
            }}
          >
            Time Slots
          </CreateButton>
          <div className="main_content">
            <Box className="title" sx={{ pl: 1 }}>
              {/* <InsertDriveFileRoundedIcon sx={{ width: "15px", mr: 1 }} /> */}
              <h6>Actions</h6>
            </Box>
            <Stack spacing={2} sx={{ pb: 18 }}>
              {Object.keys(otherDataList).map((key) => (
                <CustomNodeItem key={key} data={nodeTypesDataList[key]} />
              ))}
            </Stack>
          </div>
        </SideContentBox>
      }
    >
      <CanvasContainerStyle>
        <WorkflowNav id={"basic-actions"}>
          <div>
            {" "}
            <Link to={"/"}>
              <CreateButton>Back</CreateButton>
            </Link>
          </div>
          <div>
            <Box component={"span"} sx={{ color: "white", px: 2 }}>
              {isPublished ? "View Only" : ""}
            </Box>
            <CreateButton
              sx={{ mr: 2 }}
              color="primary"
              onClick={() => handleSave()}
            >
              Save
            </CreateButton>
            <CreateButton
              onClick={() => {
                setOpenPublish(true);
              }}
            >
              {isPublished ? "Unpublish" : "Publish"}
            </CreateButton>
          </div>
        </WorkflowNav>
        <ReactFlow
          onDrop={!isPublished ? onDrop : undefined}
          onDragOver={!isPublished ? onDragOver : undefined}
          nodes={nodes}
          edges={edges}
          elementsSelectable={!isPublished}
          nodesDraggable={!isPublished}
          selectedNode={selectedNode}
          onSelectionChange={(selection) => {
            setSelectedNode(selection.nodes[selection.nodes.length - 1]);
          }}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onInit={(instance) => {
            instanceRef.current = instance;
            setReactFlowInstance(instance);
          }}
          // fitView  x: 390, y: 97.6
          defaultViewport={{
            x: nodes?.[0]?.position?.x ?? 390,
            y: nodes?.[0]?.position?.y ?? 97.6,
            zoom: 0.7,
          }}
          edgeTypes={edgeTypes}
          onNodesDelete={onNodesDelete}
          deleteKeyCode={[]}
          onConnect={onConnect}
        >
          <NodeOptionModal selected={selectedNode} saveNode={saveNode} />
          <DeleteNodeModal selected={selectedNode} />
          <Background variant="dots" gap={12} size={1} />
          <TimeSlotModals
            open={openTimeSlot}
            handleClose={() => setOpenTimeSlot(false)}
          />
          <MiniMap />
          <Controls />
          <StarterModal saveNode={saveNode} />
        </ReactFlow>
      </CanvasContainerStyle>
      <Modal
        open={openPublish}
        onClose={() => setOpenPublish(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteModalContainer>
          <Box sx={{ py: 1, borderBottom: "1px solid #E5E3E3" }}>
            <Typography
              sx={{ fontWeight: 700, fontFamily: "Switzer", px: "8px" }}
              id="modal-modal-title"
              variant="h6"
            >
              {isPublished ? "Unpublish" : "Publish"} Prompt
            </Typography>
          </Box>

          <Box sx={{ p: "8px", py: 3 }}>
            <Typography sx={{ fontFamily: "Switzer" }}>
              Are you sure you want to{" "}
              {isPublished
                ? "Unpublish this workflow, the current running instance of this workflow will be erased."
                : "Publish this workflow?"}
            </Typography>
          </Box>
          <Stack
            sx={{ px: "8px", py: 2 }}
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
          >
            <StyledSectionButton onClick={() => setOpenPublish(false)}>
              Cancel
            </StyledSectionButton>
            <Button
              variant="contained"
              onCl
              sx={{
                borderRadius: "15px",
                fontFamily: "Switzer",
                fontSize: "14px",
                background: "#101010",
                textTransform: "none",
                px: 2,
              }}
              onClick={async () => {
                function isObjectEmpty(obj) {
                  for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                      return false;
                    }
                  }
                  return true;
                }

                //detecting unattached nodes
                const unattached = findUnattachedNodes(nodes, edges);
                if (unattached?.length) {
                  setUnAttached(unattached);
                  enqueueSnackbar(`unattached nodes detected.`, {
                    variant: "error",
                  });
                  setOpenPublish(false);
                  scrollToNode(unattached[0]);
                  return;
                }

                //detect empty node
                const emptyNode = nodes.find((node) => {
                  return (
                    node.id !== "starter-id" &&
                    isObjectEmpty(node.data.metadata)
                  );
                });
                if (emptyNode) {
                  const emptyNodes = nodes.filter((node) => {
                    return (
                      node.id !== "starter-id" &&
                      isObjectEmpty(node.data.metadata)
                    );
                  });
                  // console.log("empty data", emptyNodes);
                  setEmptyData(emptyNodes);
                  enqueueSnackbar("no data in action.", {
                    variant: "error",
                  });
                  setOpenPublish(false);
                  scrollToNode(emptyNode);
                  return;
                }

                //publish toggle and save
                const saved = await handleSave({ isPublished: !isPublished });
                //run workflow if save is successful and isPublished is true
                if (saved?.success && !isPublished) {
                  clearInterval(intervalRef.current);
                  runWorkflowClick();
                }
                //close modal if save is successful
                if (saved) {
                  setOpenPublish(false);
                }
              }}
            >
              {isPublished ? "Unpublish Journey" : "Publish Journey"}
            </Button>
          </Stack>
        </DeleteModalContainer>
      </Modal>
    </MarketingLayout>
  );
};

export default WorkflowById;
