import {
  Box,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect } from "react";
import { NodeModalContainerStyle } from "./index.styled";
import { CreateButton } from "../../components/TagsModal/styled";
import { useNodes } from "reactflow";
import "react-quill/dist/quill.snow.css";
import { Controller, useForm } from "react-hook-form";

const TimeDelayModalContent = ({ selected, saveNode, closeModals }) => {
  const nodes = useNodes();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      value: "",
      filter: "",
    },
  });

  const onSubmit = ({ value, filter }) => {
    closeModals();
    saveNode({
      ...selected,
      data: {
        ...selected?.data,
        metadata: {
          ...selected?.data?.metadata,
          value,
          filter,
        },
      },
    });
  };

  useEffect(() => {
    const currentNode = nodes.find(({ id }) => id === selected?.id);
    reset({
      value: currentNode?.data?.metadata?.value ?? "",
      filter: currentNode?.data?.metadata?.filter ?? "",
    });
  }, [selected]);

  return (
    <NodeModalContainerStyle
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ minHeight: "250px", width: "750px" }}
    >
      <Typography
        sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
        id="modal-modal-title"
        variant="h5"
        required
      >
        Create Mail
      </Typography>
      <Box>
        <Box
          sx={{
            mt: 2,
            px: 2,
            py: 1.5,
            pb: 6,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography
              variant="h6"
              sx={{
                textWrap: "nowrap",
                fontWeight: 700,
                px: 2,
                fontFamily: "Switzer",
              }}
            >
              Delay for
            </Typography>
            <TextField
              error={errors?.["value"]}
              label=""
              type="number"
              placeholder="Enter value"
              required
              sx={{
                width: "100%",
                margin: "0px 0px 0px",
                ".MuiInputBase-root": {
                  // height: "50px",
                  borderRadius: "15px",
                },
                ":hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1a1b24 !important",
                    borderWidth: "1px !important",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#007bf7",
                    borderWidth: "1px !important",
                    boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                  },
                },
              }}
              {...register("value", {
                required: true,
                validate: {
                  positive: (v) =>
                    parseInt(v) > 0 || "should be greater than 0",
                },
              })}
              helperText={errors?.["value"]?.message}
            />
            <Controller
              rules={{
                required: "select time",
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  error={errors?.["filter"]?.message}
                  onChange={onChange}
                  value={value}
                  sx={{
                    width: "100%",
                    fontFamily: "Switzer",
                    borderRadius: "12px",
                    mb: 2,
                  }}
                  helperText={errors?.["filter"]?.message}
                >
                  <MenuItem value={""} hidden={false}>
                    <em>Select time</em>
                  </MenuItem>
                  {["Hours", "Days", "Minutes"].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              )}
              control={control}
              name={"filter"}
            />
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            px: 2,
            py: 2,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <CreateButton type="submit" color="primary">
            Save
          </CreateButton>
        </Box>
      </Box>
    </NodeModalContainerStyle>
  );
};

export default TimeDelayModalContent;
