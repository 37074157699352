// import styled from "styled-components";
import styled from "@emotion/styled";

export const Wrapper = styled.div`
  width: ${(props) => (props?.isMobile ? "100%" : "90%")};
  margin: 1.5rem auto;
  max-width: 1258px;
  // max-height: 826px;
  // height:80%;
  // height: 80%;
  border: 2px solid #fff;

  background: white;
  // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25),
  //   0px 15px 25px rgba(0, 0, 0, 0.15);
  // border-radius: 15px;
  color: ${(props) => props?.brandProps?.color ?? "#fff"};

  // margin-bottom: 15px;
  // margin-left: 15px;
  // margin: 50px;
  padding: ${(props) => (props?.isMobile ? "0" : "20px")};

  // overflow: hidden;
  // @media (max-width: 500px) {
  //   width: 80%;
  //   margin: auto;
  // }
`;
export const UploadOverallWrapper = styled.div`
  width: 100%;
  margin: ${(props) => (props?.isMobile ? "1.5rem auto" : "0px")};
  // max-width: 1258px;
  // max-height: 826px;
  // height:80%;
  // height: 80%;
  border: 2px solid #fff;

  background: white;
  // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25),
  //   0px 15px 25px rgba(0, 0, 0, 0.15);
  // border-radius: 15px;
  color: ${(props) => props?.brandProps?.color ?? "#fff"};

  // margin-bottom: 15px;
  // margin-left: 15px;
  // margin: 50px;
  padding: ${(props) => (props?.isMobile ? "0" : "0px")};

  // overflow: hidden;
  // @media (max-width: 500px) {
  //   width: 80%;
  //   margin: auto;
  // }
`;
export const SuccessWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1258px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #fff;
  height: 100%;

  background: white;
  // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25),
  //   0px 15px 25px rgba(0, 0, 0, 0.15);
  // border-radius: 15px;
  color: ${(props) => props?.brandProps?.color ?? "#fff"};

  // margin-bottom: 15px;
  // margin-left: 15px;
  // margin: 50px;
  padding: 20px;
  // overflow: hidden;
  // @media (max-width: 500px) {
  //   width: 80%;
  //   margin: auto;
  // }
`;

export const MajorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: transparent;
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const LeftTopWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
`;

export const LeftTopTitle = styled.div`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  letter-spacing: -0.02em;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 10px;
  ${(props) =>
    props.isMobile &&
    `color:  #1A1B24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  `}
  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`;
export const TestimonialUSer = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  gap: 10px;

  position: absolute;
  // width: 127px;
  height: 32px;
  left: 45.8px;
  top: 2.61px;

  /* Primary/French Blue/French Blue 25% */

  background: #d9eaf8;
  /* Black */

  border: 1.5px solid #000000;
  border-radius: 25px;
  transform: rotate(1.44deg);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;
export const TestimonialUSerText = styled.div`
  // height: 14px;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 19.2874px;
  line-height: 23px;
  leading-trim: both;
  text-edge: cap;
  text-align: center;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  transform: rotate(1.44deg);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const TestimonialUSerImage = styled.div`
  width: 35px;
  height: 35px;

  border: 1px solid #ececf2;
  border-radius: 50%;
  transform: rotate(-2.34deg);

  position: relative;
  flex: none;
  order: 1;
  flex-grow: 0;
  // z-index: 1;
  &img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const LeftTopSubTitle = styled.div`
  font-family: Dm Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;

  /* Inside auto layout */
  display: flex;
  align-items: center;
  flex: none;
  // order: 1;
  flex-grow: 0;
  // font-family: "Switzer";
  // font-style: normal;
  // font-weight: 400;
  // font-size: 1em;
  // line-height: 24px;
  // letter-spacing: -0.02em;
  // width: ${(props) => (props?.isMobile ? "100%" : "50%")};
  // color: #ffffff;

  margin-bottom: 0px;
  // min-width: ${(props) => (props?.isMobile ? undefined : "350px")};

  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`;
export const GenericText = styled.div`
  font-family: Dm Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */
  color: ${(props) => (props?.color ? props?.color : "#5d6272")};

  /* Inside auto layout */
  display: flex;
  align-items: center;
  flex: none;
  // order: 1;
  flex-grow: 0;

  // width: ${(props) => (props?.isMobile ? "100%" : "50%")};
  // color: #ffffff;

  margin-bottom: 2em;
  // min-width: ${(props) => (props?.isMobile ? undefined : "350px")};

  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`;
export const RequestText = styled.p`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const RightTopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  // color: #ffffff;
`;
export const RequestDisclaimer = styled.div`
  display: flex;
  width: 100%;
  // height: 52px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #f1f4fc;
  margin-top: 1em;
`;
export const DisclaimerText = styled.p`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin-bottom: 0px;
`;
export const MinimizeWrapper = styled.img`
  margin-top: -5px;
  margin-right: 30px;
  cursor: pointer;
`;
export const CloseWrapper = styled.div`
  cursor: pointer;
`;

export const InputWrapper = styled.input`
  background: #f9f9f9;
  // background: #fff;
  /* Element */

  //border: 1px solid ${(props) => props?.borderColor ?? "#d4dae1"};

  border-radius: 12px;
  height: 54px;
  width: 100%;
  margin-bottom: 13px;
  padding-left: 1em;
  margin-top: 6px;
  font-size: 14px;
  &:focus {
    outline: none;
    // border: 1px solid #8d6cff;
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  border: ${(props) =>
    props?.borderColor
      ? ` 1px solid  ${props?.borderColor}`
      : props?.mfocus
      ? ` 1px solid  #007bf7`
      : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.mfocus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.mfocus ? ` 1px solid  #007bf7` : "1px solid #1a1b24"};
  }
  @media (max-width: 800px) {
    margin-bottom: 22px;
  }
`;

export const ErrorWrapper = styled.div`
  color: #d93047;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -12px;
  margin-bottom: 13px;
`;

export const FormWrapper = styled.div`
  width: ${(props) => (props?.isMobile ? "100%" : "100%")};
  // margin-top: ${(props) => (props?.isMobile ? "1.5rem" : "0px")};
  position: relative;
`;
export const LabelDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  // font-size: 14px;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */

  // text-transform: capitalize;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const LabelWrapperTwo = styled.div`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height */

  // text-transform: capitalize;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const LabelWrapperExt = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column-reverse;
`;
export const ViaSmsText = styled.div`
  display: flex;
  alignitems: center;
  gap: 10px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StopRecordButton = styled.button`
  background: #e14644;
  backdrop-filter: blur(2px);
  color: #fff;
  // width: 9%;
  height: 3.1em;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  position: absolute;
  margin-top: ${(props) => (props?.isMobile ? "20%" : "10%")};
  &:active {
    transform: translateY(1px);
  }
`;

export const StopRecordButtonWrapper = styled.button`
  margin-top: -10px;
  margin-right: 0px;
  background-color: inherit;

  padding: 0;
`;

export const VideoPartWrapper = styled.div`
  height: ${(props) => (props?.isMobile ? "100%" : "100%")};

  width: ${(props) => (props?.isMobile ? "100%" : "100%")};
  position: relative;
`;
export const VideoWrapper = styled.div`
  // height: ${(props) => (props?.isMobile ? "100%" : "100%")};
  width: ${(props) => (props?.isMobile ? "100%" : "100%")};
  // background: ${(props) => (props?.isMobile ? "#ECE8FF" : "#ECE8FF")};
  // aspect-ratio: 16/9;
  border: none;

  border-radius: ${(props) => (props?.isMobile ? "15px" : "15px")};

  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-repeat: no-repeat;
`;

// background-image: ${(props) =>
//   props?.permissions
//     ? `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${props?.showBackground && props?.url})`
//     : 'none'};

export const ContentWrapper = styled.div`
  display: ${(props) => (props?.hide ? "none" : "flex")};
  justify-content: ${(props) =>
    props?.isMobile ? "flex-start" : "space-between"};
  flex-direction: ${(props) => (props?.isMobile ? "column" : "column")};
  height: ${(props) => (props?.isMobile ? "80%" : undefined)};
  width: ${(props) => (props?.isMobile ? "100%" : "70%")};
  margin: ${(props) => (props?.isMobile ? "0" : "0 auto")};
  height: 100%;
`;
export const UploadWrapper = styled.div`
  display: ${(props) => (props?.hide ? "none" : "flex")};
  justify-content: ${(props) =>
    props?.isMobile ? "flex-start" : "space-between"};
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  height: ${(props) => (props?.isMobile ? "80%" : undefined)};
  width: ${(props) => (props?.isMobile ? "100%" : "100%")};
  margin: ${(props) => (props?.isMobile ? "0" : "0 auto")};
  height: 100%;
  // gap: 48px;
`;

export const ButtonWrapper = styled.button`
  background: ${(props) => (props?.url ? "#F3B02F" : "#8d6cff")};
  backdrop-filter: blur(2px);
  color: #fff;
  width: 70%;
  height: 3.1em;
  border-radius: 8px;
  cursor: pointer;
  border-width: 0px;
  margin-left: 5px;

  &:active {
    transform: translateY(1px);
  }
`;
export const QuestionTextarea = styled.textarea`
  display: flex;
  height: 191px;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 14px;
  border: 1px solid #dae1dd;
  background: #fff;
  font-family: Dm Sans;
  leading-trim: both;

  text-edge: cap;
  color: #1a1b24;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.14px;
`;

export const ViaSms = styled.div`
  height: 98px;
  display: flex;

  padding: 35px 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0px;
  border-radius: 16px;
  border: ${(props) =>
    props?.isCheckedSms ? "1.5px solid #007BF7" : " 1px solid #DAE1DD"};
`;
export const ViaEmail = styled.div`
  height: 98px;
  display: flex;

  padding: 35px 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0px;
  border-radius: 16px;
  border: ${(props) =>
    props?.isCheckedEmail ? "1.5px solid #007BF7" : " 1px solid #DAE1DD"};
`;
export const VectorFour = styled.div`
  position: absolute;
  left: 0.79%;

  top: 125.5%;
  bottom: 6.26%;

  width: 100%;
`;
export const VectorFive = styled.div`
  position: absolute;
  left: 53%;

  top: 130.5%;
  bottom: 4.06%;
  // width: 100%;
  &img {
    transform: rotate(32.71deg);
  }
`;
export const VectorTwo = styled.div`
position: relative;
width: 90%;
    max-width: 1258px;
    margin: 0 auto;
    top: -2%;
    z-index: 2;
}
    
    

&img{

  transform: matrix(-0.99, -0.17, -0.17, 0.99, 0, 0);
}
`;
export const VectorOne = styled.div`
  position: relative;
  // left: 6.7%;
  // right: 100.69%;
  // top: 150px;
  // bottom: 81.74%;
  width: 50px;

  &:img {
    transform: rotate(-9.68deg);
  }
`;
export const VectorThree = styled.div`
  position: absolute;
  // left: 6.7%;
  right: 0%;
  top: 100%;
  // bottom: 81.74%;
  // width:100%;

  &img {
    transform: matrix(-0.96, -0.27, -0.27, 0.96, 0, 0);
  }
`;
export const BrandRating = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImageRate = styled.img`
  height: 1.8em;
`;

export const ImagePlay = styled.img`
  margin-top: 0;
  height: ${(props) => (props?.isMobile ? "40px" : "57px")};
  width: ${(props) => (props?.isMobile ? "40px" : "57px")};
  cursor: pointer;
  margin-right: 1rem;
`;

export const BottomWrapper = styled.div`
  position: relative;
  top: 2.5%;
  // width: 100%;
`;

export const LRButtonWrapper = styled.button`
  width: 100%;
  height: 4em;
  background: linear-gradient(
    181deg,
    rgba(37, 37, 37, 0.79) 0.21%,
    rgba(37, 37, 37, 0.69) 100%
  );
  backdrop-filter: blur(2px);
  border-radius: 8px;
  color: ${(props) => props?.brandProps?.color ?? "#fff"};

  // color: white;
  cursor: pointer;
  &:active {
    transform: translateY(1px);
  }
`;

export const StepWrapper = styled.div`
  // color: #53545b;
  color: black;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  gap: 3px;
  // margin-top: 50px;
`;
export const StepWrapperSpan = styled.p`
  color: #53545b;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const StepTitle = styled.div`
  color: #000;
  text-align: left;

  text-transform: capitalize;
  // margin-bottom: 16px;

  color: #1a1b24;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  // font-weight: 700;
  font-weight: bold;
  line-height: 150%; /* 33px */
`;

export const Step2SubDiv = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;
