import React from 'react';
import Modal from "react-modal";
import ModalTrash from "../assets/icons/ModalTrash.svg";
import RemoveIcon from "../assets/icons/RemoveIcon.svg";
import Twitter from "../assets/images/Twitter.svg";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Messenger from "../assets/images/Messenger.svg";
import Gmail from "../assets/images/Gmail.svg";
import CopySocialLink from "../assets/images/CopySocialLink.svg";
import {
  DeleteButton,
  DeleteButtonText,
  DeleteHeader,
  DeleteText,
  ShareHeader,
  ShareText,
} from "../pages/Organizations/styled";
import { Box } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useState } from "react";
export default ({
  openModal,
  handleCloseModal,
  videoUrl,
  isMobile,
  headerText = "Delete this Video?",
  subHeaderText = "You are about to delete this video",
  btnText = "Delete",
}) => {
  const customStyles = {
    overlay: {
      background: "rgba(0, 0, 0, 0.4)",
    },
    content: {
      display: "flex",
      boxSizing: " border-box",

      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px 16px 24px 24px",

      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "90%" : "507px",
      //   height: !isMobile && "312px",

      background: "#FFFFFF",

      border: "1px solid #ECECF2",
      borderRadius: "15px",
    },
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const shareTwitter = () => {
    // Replace "YOUR_TEXT_TO_SHARE" and "YOUR_URL_TO_SHARE" with your desired content
    const textToShare = "A Must Watch Video";
    const urlToShare =
      "http://dev.covete.com/testimonial/brand_bxmqcazvn7/6492dabd78624c22ae1cf7e6";

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      textToShare
    )}&url=${encodeURIComponent(urlToShare)}`;
    window.open(twitterUrl);
  };

  const shareWhatsapp = () => {
    // Replace "A Must Watch Video" and "YOUR_URL_TO_SHARE" with your desired content
    window.open(
      `https://api.whatsapp.com/send?text=A Must Watch Video%20http://dev.covete.com/testimonial/brand_bxmqcazvn7/6492dabd78624c22ae1cf7e6`
    );
  };

  const shareMessenger = () => {
    // Replace "YOUR_URL_TO_SHARE" with your desired content (Messenger doesn't support pre-filled messages)
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=http://dev.covete.com/testimonial/brand_bxmqcazvn7/6492dabd78624c22ae1cf7e6`
    );
  };

  const shareGmail = () => {
    // Replace "YOUR_SUBJECT" and "YOUR_BODY" with your desired email subject and body
    const emailSubject = "A Must Watch Video";
    const emailBody = `Watch this video: http://dev.covete.com/testimonial/brand_bxmqcazvn7/6492dabd78624c22ae1cf7e6`;

    window.location.href = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
  };
  const text = `${process.env.REACT_APP_CLIENT_URL}/watch-video/${videoUrl}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(text);
    setOpenSnackbar(true);
    setSnackbarMessage(`Link Copied to Clipboard`);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      <Modal
        isOpen={openModal}
        onRequest
        Close={handleCloseModal}
        ariaHideApp={false}
        style={customStyles}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ShareHeader>Share this video via</ShareHeader>
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            alt="modal-delete"
            onClick={handleCloseModal}
          />
        </div>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "15px",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              width: "18%",
            }}
            onClick={shareTwitter}
          >
            <Box
              sx={{
                width: "100%",
                height: "80px",
                marginBottom: "5px",
                backgroundColor: "#0A93E2",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Twitter} alt="Twitter" />
            </Box>
            <ShareText>Twitter</ShareText>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              width: "18%",
            }}
            onClick={shareWhatsapp}
          >
            <Box
              sx={{
                width: "100%",
                height: "80px",
                marginBottom: "5px",
                backgroundColor: "#00D95F",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Whatsapp} alt="Whatsapp" />
            </Box>
            <ShareText>Whatsapp</ShareText>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              width: "18%",
            }}
            onClick={shareMessenger}
          >
            <Box
              sx={{
                width: "100%",
                height: "80px",
                marginBottom: "5px",
                backgroundColor: "white",
                border: "1px solid  #ECECF2",
                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.04)",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Messenger} alt="Messenger" />
            </Box>
            <ShareText>Messenger</ShareText>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              width: "18%",
            }}
            onClick={shareGmail}
          >
            <Box
              sx={{
                width: "100%",
                height: "80px",
                marginBottom: "5px",
                backgroundColor: "white",
                border: "1px solid  #ECECF2",
                boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.04)",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Gmail} alt="Gmail" />
            </Box>
            <ShareText>Gmail</ShareText>
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              width: "18%",
            }}
            onClick={handleCopyLink}
          >
            <Box
              sx={{
                width: "100%",
                height: "80px",
                marginBottom: "5px",
                backgroundColor: "#E2EEF8",
                borderRadius: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={CopySocialLink} alt="CopySocialLink" />
            </Box>
            <ShareText>Copy Link</ShareText>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
