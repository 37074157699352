import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";

export const HeaderText = styled(Box)`
  display: flex;
  width: 538.667px;
  align-items: center;
  gap: 8px;
  color: #1a1b24;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubHeaderCont = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const SubHeaderCont1 = styled(Box)`
  display: flex;
  //   align-items: flex-start;
  align-items: center;
  gap: 16px;
`;

export const SubHeaderCont1_1 = styled(Box)`
  color: #53545b;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubHeaderCont1_1x = styled(Box)`
  display: flex;
  //   align-items: flex-start;
  align-items: center;
  gap: 6px;
`;

export const SubHeaderCont1_1_1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SubHeaderCont1_1_1Text = styled(Box)`
  color: #53545b;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubHeaderCont1_2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const SubHeaderCont1_2Text = styled(Box)`
  color: #5d6272;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
`;

export const SubHeaderCont2 = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;
export const SubHeaderCont2Box = styled(Box)`
  width: 48%;
`;

export const EmailCont = styled(Box)`
  display: flex;
  height: 292px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const ContHeader = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;

export const EmailContBdy = styled(Box)`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  // flex: 1 0 0;
  align-self: stretch;
  overflow: auto;
`;

export const TextCont = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const BatchBx = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const BatchBxT1 = styled(Box)`
  color: var(--dark-grey-palette-primary-french-blue-french-blue-100, #007bf7);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const BatchBxT2 = styled(Box)`
  display: inline-block;
  text-wrap: nowrap;
  cursor: pointer;
  color: var(--dark-grey-palette-rasin-black-rasin-black-75, #53545b);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const BatchItemBox = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const BatchItem = styled(Box)`
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: rgba(217, 234, 248, 0.5);
`;

export const BatchItemText = styled(Box)`
  color: var(--dark-grey-palette-primary-black-black-75, #404040);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
