import { gql } from "@apollo/client";
import { USER } from "../fragments/user";

export const SIGNUP = gql`
	${USER}
	mutation createUser($input: CreateUserInput!) {
		createUser(input: $input) {
			...UserFields
		}
	}
`;

export const GOOGLE_LOGIN = gql`
	${USER}
	mutation googleAuth($input: GoogleAuthUserInput!) {
		googleAuth(input: $input) {
			...UserFields
		}
	}
`;

export const LOGOUT = gql`
	mutation logout {
		logout
	}
`;

export const LOGIN = gql`
	${USER}
	mutation login($input: LoginInput!) {
		login(input: $input) {
			...UserFields
		}
	}
`;

export const FORGOT_PASSWORD = gql`
	mutation forgotPassword($input: ForgotPasswordInput!) {
		forgotPassword(input: $input) {
			token
		}
	}
`;

export const CHANGE_PASSWORD = gql`
	${USER}
	mutation resetPassword($input: ResetPasswordInput!) {
		resetPassword(input: $input) {
			...UserFields
		}
	}
`;
