import { gql } from "@apollo/client";

const APPROVE_VIDEO = gql`
  mutation ApproveVideo($input: ApproveVideoInput!) {
    approveVideo(input: $input) {
      _id
      url
      videoId
      approved
      disapproved
      brand {
        _id
        name
        brandId
      }
    }
  }
`;
const DISAPPROVE_VIDEO = gql`
  mutation DisapproveVideo($input: ApproveVideoInput!) {
    disapproveVideo(input: $input) {
      _id
      url
      videoId
      approved
      disapproved
      brand {
        _id
        name
        brandId
      }
    }
  }
`;

const DELETE_VIDEO = gql`
  mutation deleteVideo($videoId: String!) {
    deleteVideo(videoId: $videoId) {
      _id
      name
      email
      rating
      duration
      position
      createdAt
      url
      videoId
      brand {
        _id
        name
        description
        sector
        brandId
      }
      approved
      disapproved
    }
  }
`;

const SAVE_VIDEO = gql`
  mutation createVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      _id
    }
  }
`;

const UPDATE_VIDEO_DETAILS = gql`
  mutation updateVideoDetails($input: UpdateVideoDetailsInput!) {
    updateVideoDetails(input: $input) {
      _id
    }
  }
`;

const SAVE_SENDER_VIDEO = gql`
  mutation createSenderVideo($input: CreateSenderVideoInput!) {
    createSenderVideo(input: $input) {
      _id
      senderUrl
      senderDuration
      email
      name
      savedMessage
      questions
      openToRecord
      position
      videoId
      approved
      originalVideoExtension
      videoModified
      brand {
        _id
        name
        description
        sector
        brandId
      }
      disapproved
      duration
      rating
      createdAt
      url
      duration

      videoIdentityNumber
    }
  }
`;

const SAVE_MULTIPLE_VIDEO = gql`
  mutation createMultipleVideo($input: CreateMultipleVideoInput!) {
    createMultipleVideo(input: $input) {
      _id
      videoIdentityNumber
    }
  }
`;

const ADD_RATINGS = gql`
  mutation addRating($videoId: String!, $rating: Float!) {
    addRating(videoId: $videoId, rating: $rating) {
      _id
      ratings
    }
  }
`;
const SAVE_ANALYTIC = gql`
  mutation createAnalytics($input: CreateAnalyticInput!) {
    createAnalytics(input: $input) {
      _id
    }
  }
`;

export const UPL_VIDEO = gql`
  mutation uplVideo($input: Upload!) {
    uplVideo(input: $input) {
      test
    }
  }
`;
const UPDATE_VIDEO = gql`
  mutation updateVideo($input: UpdateVideoInput!) {
    updateVideo(input: $input) {
      _id
      name
    }
  }
`;
export {
  APPROVE_VIDEO,
  DISAPPROVE_VIDEO,
  DELETE_VIDEO,
  SAVE_VIDEO,
  ADD_RATINGS,
  SAVE_ANALYTIC,
  UPDATE_VIDEO,
  SAVE_MULTIPLE_VIDEO,
  SAVE_SENDER_VIDEO,
  UPDATE_VIDEO_DETAILS,
};
