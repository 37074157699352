import { useEffect, useMemo, useState } from "react";
import {
  Handle,
  Position,
  useNodeId,
  useOnSelectionChange,
  useReactFlow,
  useStore,
} from "reactflow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NodeItemStyle } from "./index.styled";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { NodeIconType } from "./NodeItem";
import { format } from "date-fns";
import { useWorkflowContext } from "./workflowContext";
import ConditionHandle from "./conditionHandle";

const CustomNodeItemCard = ({ data, isConnectable }) => {
  const instance = useReactFlow();
  const {
    tagsList,
    openAModal,
    openNodeDeleteModal,
    newsletters,
    unAttached,
    setUnAttached,
    emptyData,
  } = useWorkflowContext();
  const nodeId = useNodeId();
  const [selectedNode, setSelectedNode] = useState();
  const isSourceConnected = useStore((s) => {
    return s.edges.some((edge) => {
      return edge.source === nodeId;
    });
  });

  const isSourceConnectedTwice = useStore((s) => {
    const noOfSource =
      s.edges.filter((edge) => {
        return edge.source === nodeId;
      })?.length ?? 0;
    if (noOfSource > 1) {
      return true;
    } else {
      return false;
    }
  });

  const isTargetConnected = useStore((s) =>
    s.edges.some((edge) => edge.target === nodeId)
  );
  // if( data.type==="trigger"){
  //   console.log("isSourceConnectedTwiceDifferById",isSourceConnectedTwiceDifferById, data.type);
  // }
  function isObjEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  useEffect(() => {
    if (isTargetConnected) {
      if (unAttached.find(({ id }) => nodeId === id)) {
        setUnAttached(unAttached.filter(({ id }) => nodeId !== id));
      }
    }
  }, [isTargetConnected]);

  const getOthersSourceLogic = () => {
    if (isSourceConnected && isTargetConnected) {
      return false;
    } else if (!isSourceConnected && isTargetConnected) {
      return true;
    }
    return false;
  };

  const getOthersTargetLogic = () => {
    if (isSourceConnected && isTargetConnected) {
      return false;
    } else if (isSourceConnected && !isTargetConnected) {
      return true;
    }
    return false;
  };

  if (data.type === "trigger") {
    // console.log(
    //   "isSourceConnected",
    //   isSourceConnected,
    //   "isTargetConnected",
    //   isTargetConnected,
    //   data.type,
    //   "!isSourceConnected || isTargetConnected",
    //   !isSourceConnected || isTargetConnected
    // );
  }

  useOnSelectionChange({
    onChange: ({ nodes }) => {
      setSelectedNode(nodes[nodes.length - 1]);
    },
  });

  const hasError = useMemo(
    () =>
      !!(
        unAttached.find(({ id }) => nodeId === id) ||
        emptyData.find(({ id }) => nodeId === id)
      ),
    [unAttached, emptyData]
  );
  return (
    <>
      <Handle
        type="target"
        className="handle top"
        isConnectableEnd={!isTargetConnected}
        isConnectableStart={getOthersTargetLogic()}
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <NodeItemStyle
        borderVariant={
          hasError
            ? "error"
            : selectedNode?.id === nodeId
            ? "selected"
            : "unselected"
        }
        variant={"white"}
        onClick={(e) => {
          e.stopPropagation();
          // const val = nodes.find((n) => n.id === nodeId)
          // if (val){
          //   setSelected(val)
          // }
        }}
      >
        {selectedNode?.id === nodeId && !isSourceConnectedTwice && (
          <IconButton
            onClick={() => {
              // if (data.type==="ifElseCondition"){

              // }
              if (isObjEmpty(data?.metadata)) {
                instance?.deleteElements({ nodes: [selectedNode] });
              } else {
                openNodeDeleteModal();
              }
            }}
            size="large"
            sx={{ position: "absolute", top: "-10px", left: "-10px" }}
            color="error"
          >
            <CancelRoundedIcon />
          </IconButton>
        )}
        <Box sx={{ px: 1 }}>
          <NodeIconType type={data.type} />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <Typography
                color={"black"}
                sx={{ fontFamily: "Dm Sans", fontWeight: 700 }}
              >
                {data.title}
              </Typography>
            </div>

            <div>
              <IconButton
                onClick={() => {
                  const clickableActions = [
                    "tagged",
                    "ifElseCondition",
                    "email",
                    "time",
                    "newsletter",
                    "delay",
                  ];
                  if (clickableActions.includes(data?.type)) {
                    openAModal(data?.type);
                  }
                }}
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </Stack>
          <Typography
            variant="caption"
            sx={{ fontFamily: "Dm Sans" }}
            color={"#555555"}
          >
            id: <em>{nodeId} </em>
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Dm Sans" }}
            color={"#555555"}
          >
            {data?.metadata?.value && data.type === "time" ? (
              <>
                <Typography
                  component={"span"}
                  sx={{ fontWeight: 700 }}
                  variant="body2"
                >
                  Time :
                </Typography>
                {format(data?.metadata?.value, "yyyy-MM-dd HH:mm aa")}
              </>
            ) : data?.metadata?.action && data.type === "tagged" ? (
              <>
                <Typography
                  component={"span"}
                  sx={{ fontWeight: 700 }}
                  variant="body2"
                >
                  {data?.metadata?.action} :
                </Typography>
                {tagsList
                  ?.filter(({ id }) => data?.metadata?.tag?.includes(id))
                  ?.reduce(
                    (acc, { name }, index) =>
                      `${acc}${!!index ? ", " : ""}${name}`,
                    ""
                  )}
              </>
            ) : data?.metadata?.filter && data.type === "ifElseCondition" ? (
              <>
                <br />
                <Box
                  component={"span"}
                  sx={{
                    p: 0.5,
                    border: "1px solid #262626",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{ fontWeight: 700 }}
                    variant="body2"
                  >
                    {data?.metadata?.filter} -
                  </Typography>
                  &nbsp;
                  <>{data?.metadata?.condition}</>&nbsp;-
                  {data?.metadata?.filter !== "tags"
                    ? data?.metadata?.value
                    : tagsList?.find(({ id }) =>
                        data?.metadata?.value?.includes(id)
                      )?.name}
                </Box>
              </>
            ) : data?.metadata?.subject && data.type === "email" ? (
              <>
                <br />
                <Box
                  component={"span"}
                  sx={{
                    p: 0.5,
                    border: "1px solid #262626",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{ fontWeight: 700 }}
                    variant="body2"
                  >
                    subject:
                  </Typography>
                  &nbsp;
                  <>{data?.metadata?.subject}</>
                </Box>
              </>
            ) : data?.metadata?.value && data.type === "newsletter" ? (
              <>
                {/* <br /> */}
                <Box
                  component={"span"}
                  sx={{
                    p: 0.5,
                    // border: "1px solid #262626",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{ fontWeight: 700 }}
                    variant="body2"
                  >
                    Newsletter:
                  </Typography>
                  &nbsp;
                  <>
                    {
                      newsletters?.find(
                        ({ id }) => data?.metadata?.value === id
                      )?.title
                    }
                  </>
                </Box>
              </>
            ) : data?.metadata?.value && data.type === "delay" ? (
              <>
                {/* <br /> */}
                <Box
                  component={"span"}
                  sx={{
                    p: 0.5,
                    // border: "1px solid #262626",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    component={"span"}
                    sx={{ fontWeight: 700 }}
                    variant="body2"
                  >
                    Delay:
                  </Typography>
                  &nbsp;
                  <>
                    {data?.metadata?.value} {data?.metadata?.filter}
                  </>
                </Box>
              </>
            ) : (
              data.description
            )}
            {}
          </Typography>
        </Box>
      </NodeItemStyle>
      {data.type === "ifElseCondition" && (
        <>
          <ConditionHandle
            data={data}
            nodeId={nodeId}
            type="source"
            position={Position.Left}
            id="yes"
            isConnectable={isConnectable}
          />
          <ConditionHandle
            data={data}
            nodeId={nodeId}
            type="source"
            position={Position.Right}
            id="no"
            isConnectable={isConnectable}
          />
        </>
      )}
      {!(data.type === "ifElseCondition") && (
        <Handle
          type="source"
          className="handle bottom"
          isConnectableStart={getOthersSourceLogic()}
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      )}
    </>
  );
};

export default CustomNodeItemCard;
