import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { UserDetailsHeader, UserDetailsText, StyledTableCell } from "./styled";

/**
 * A component that displays a table with customizable head cells and rows.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.headCells - An array of objects defining table head cells.
 * @param {Array} props.rows - An array of objects representing the table rows.
 * @param {boolean} props.stylehead - Whether to apply custom styles to the table head.
 * @param {boolean} props.isMobile - Whether the component is being displayed on a mobile device.
 */
function CustomTable({
  headCells = [],
  rows = [],
  stylehead,
  isMobile,
  rowClick,
  withCheckbox,
  handleCheckAll,
  ind = "name",
  setCheckedIds,
  shouldRefresh = false,
}) {
  let custRows = [...rows];
  // console.log(custRows, "CustRows");
  let custHeadCells = [...headCells];
  const [allChecked, setAllChecked] = useState(false);

  const [checkedObj, setCheckedObj] = useState({});

  const CustomizedTableHeadCell = stylehead ? StyledTableCell : TableCell;
  if (withCheckbox) {
    custHeadCells.unshift({
      label: (
        <Checkbox
          sx={{ alignSelf: "center", position: "absolute", top: "-100%" }}
          checked={!!allChecked}
          onClick={() => {
            let flag = !allChecked;
            setAllChecked(flag);
            let newObj = {};
            let arr = [];
            for (let elm in checkedObj) {
              newObj[elm] = flag;
              if (setCheckedIds && flag) arr.push(elm);
            }
            setCheckedObj(newObj);
            if (setCheckedIds) {
              setCheckedIds(arr);
            }
          }}
        />
      ),
      id: "checkId",
    });
    custRows = custRows.map((elm) => {
      let obj = { ...elm };
      obj.checkId = (
        <Checkbox
          checked={!!checkedObj[elm?._id]}
          sx={{ alignSelf: "center", position: "absolute", top: "-70%" }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            setAllChecked(false);
            let arr = [];
            let newObj = { ...checkedObj, [elm?._id]: !checkedObj[elm?._id] };
            setCheckedObj(newObj);
            if (setCheckedIds) {
              for (let elmO in newObj) {
                if (newObj[elmO]) arr.push(elmO);
              }
              setCheckedIds(arr);
            }
          }}
        />
      );
      return obj;
    });
  }

  useEffect(() => {
    if (withCheckbox) {
      let objChecked = {};
      // alert();
      for (let elm of custRows) {
        objChecked[elm?._id] = false;
      }
      setCheckedObj(objChecked);
      setAllChecked(false);
      if (setCheckedIds) setCheckedIds([]);
    }
  }, [shouldRefresh]);



  useEffect(() => {
    if (withCheckbox) {
    handleCheckAll &&  handleCheckAll(allChecked);
    }
  }, [allChecked]);

  return (
    <Table sx={{ minWidth: !isMobile && 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          {custHeadCells.map((el, idx) => (
            <CustomizedTableHeadCell
              key={`Header${idx}`}
              style={{ padding: isMobile && "0" }}
            >
              <UserDetailsHeader stylehead={stylehead}>
                {el?.label}
              </UserDetailsHeader>{" "}
            </CustomizedTableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {custRows.map((row, idx) => (
          <TableRow
            style={{ cursor: rowClick ? "pointer" : undefined }}
            key={`KR${idx}`}
            onClick={() => rowClick && rowClick(row)}
            className="hoverable-cell"
          >
            {custHeadCells.map((el, idx) => (
              <TableCell
                key={`Row${row[ind]}${idx}`}
                style={{
                  width: el?.width,
                  borderTop: stylehead && "1px solid #ECECF2",
                  borderBottom: stylehead && "1px solid #ECECF2",
                  height: stylehead && "72px",
                  padding: isMobile && "0",
                }}
              >
                <UserDetailsText>{row[el?.id]}</UserDetailsText>{" "}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

// CustomTable.propTypes = {
//   headCells: PropTypes.array,
//   rows: PropTypes.array,
//   stylehead: PropTypes.bool,
//   isMobile: PropTypes.bool,
// };
export default React.memo(CustomTable);
