import { useEffect, useRef, useState } from "react";
import {
  BannerButton,
  BannerButtonText,
  BannerCalender,
  FlexBannerButtonFilterTwo,
  SearchResultsFormDateTwo,
} from "../../pages/UserDashboard/styled";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Calender from "../../assets/icons/Calender.svg";
import styled from "styled-components";
import { DatePicker } from "antd";
import { setDate } from "../../redux/date.slice";

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker-range {
    visibility: hidden;
  }

  & ant-picker-dropdown {
    top: 135px;
    left: 1339.27px;
  }
`;

const customText = (dateObj) => {
  let text = "Yesterday";
  if (dateObj instanceof Object) {
    const { startDate, endDate } = dateObj;

    let numSelectedDays = dayjs(endDate).diff(startDate, "day") + 1;
    if (numSelectedDays == "1") {
      text = "Today";
    } else if (numSelectedDays == "2") {
      text = "Yesterday";
    } else {
      text = `Last ${numSelectedDays} days`;
    }
  }
  return text;
};

export default ({ isMobile, position, style = {} }) => {
  const rangeRef = useRef(null);
  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const date = useSelector((state) => state.date);
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const [dateText, setDateText] = useState(customText(date));

  const [startDate, setStartDate] = useState(date.startDate);

  const [endDate, setEndDate] = useState(date.endDate);
  function getCalendarContainer(trigger) {
    return trigger.parentNode;
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handleRangeChangeTwo = (dates, dateStrings) => {
    try {
      if (dateStrings.length === 2 && dateStrings[0] && dateStrings[1]) {
        const newStartDate = dateStrings[0];
        const newEndDate = dateStrings[1];

        setStartDate(newStartDate);
        setEndDate(newEndDate);
        dispatch(setDate({ startDate: newStartDate, endDate: newEndDate }));
        const isCustomRangeSelected =
          dateStrings[0] === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
          dateStrings[1] === dayjs().format("YYYY-MM-DD");

        setCustomRangeSelected(isCustomRangeSelected);
        let text = customText({ startDate: newStartDate, endDate: newEndDate });

        setDateText(text);
        // console.log(text, "Textii");
      } else {
        console.error("Invalid date range selected.");
      }
    } catch (err) {
      // console.log(err, "Textiiii");
    }
  };

  const presetRanges = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "day"), dayjs()],
    "Last 7 Days": [dayjs().subtract(6, "day"), dayjs()],
    "Last 14 Days": [dayjs().subtract(13, "day"), dayjs()],
    "Last 21 Days": [dayjs().subtract(20, "day"), dayjs()],
    "Last 30 Days": [dayjs().subtract(29, "day"), dayjs()],
    "Last 60 Days": [dayjs().subtract(59, "day"), dayjs()],
    "Last 90 Days": [dayjs().subtract(89, "day"), dayjs()],
    "Custom Range": () => [dayjs().subtract(2, "day"), dayjs()],
  };
  // changing date format
  const formattedStartDate = moment(startDate).format("MMM D, YYYY");
  const formattedEndDate = moment(endDate).format("MMM D, YYYY");

  useEffect(() => {
    function handleClickOutside(event) {
      if (rangeRef.current && !rangeRef.current.contains(event.target)) {
        // Check if the clicked target is within the Dropdown's internal elements
        const dropdownInternal = document.querySelector(".ant-picker-dropdown");
        if (!dropdownInternal || !dropdownInternal.contains(event.target)) {
          setDisplay(false);
        } else {
          setDisplay(true);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <FlexBannerButtonFilterTwo style={style} ref={rangeRef}>
      <BannerButton
        style={{ marginRight: "0rem" }}
        data-date-picker-trigger="true"
        onClick={() => {
          setDisplay((prevDisplay) => !prevDisplay);
        }}
        className="navigation-img"
        tabIndex="0"
        autofocus
      >
        <BannerButtonText sx={{ marginLeft: "10px" }}>
          {dateText}
        </BannerButtonText>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

        {!isMobile && (
          <BannerButtonText>
            {formattedStartDate} - {formattedEndDate}
          </BannerButtonText>
        )}

        <BannerCalender>
          <img src={Calender}></img>
        </BannerCalender>
      </BannerButton>

      {display && (
        // <SearchResultsFormDateTwo isMobile={isMobile}>
        <StyledRangePicker
          id={{
            start: startDate,
            end: endDate,
          }}
          value={[dayjs(startDate), dayjs(endDate)]}
          onChange={handleRangeChangeTwo}
          ranges={presetRanges}
          disabledDate={disabledDate}
          open={display}
          showToday={false}
          getCalendarContainer={getCalendarContainer}
          // style={{ width: 200 }}

          style={{
            width: 0,
            position: "absolute",
            zIndex: -1,
            // visibility: "hidden",
            overflow: "hidden",
          }}
          popupStyle={
            isMobile
              ? {
                  width: "90%",
                  top: "450px",
                  left: "0",
                }
              : {
                  // padding: "1.5% 0",
                  padding: "0",
                  paddingTop: "20px",
                  position: "absolute",
                  width: "auto",
                  left: "auto",
                  [position ?? "right"]: "2.5%",
                  // top: `calc(${topCalendar + 10}px`,
                }
          }
        />
        // </SearchResultsFormDateTwo>
      )}
    </FlexBannerButtonFilterTwo>
  );
};
