import { useState } from "react";
import {  useLazyQuery } from "@apollo/client";
import { SEARCH_ORGANIZATIONS_AND_BRANDS } from "../graphql/queries/organizationQueries";
import React from 'react';

function Search() {
  const [query, setQuery] = useState("");
  const [search, { loading, data }] = useLazyQuery(
    SEARCH_ORGANIZATIONS_AND_BRANDS
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (query !== "") {
      search({ variables: { query } });
    }
  };


  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      {loading && <p>Loading...</p>}
      {data && (
        <div>
          <h2>Organizations</h2>
          <ul>
            {data.searchOrganizationsAndBrands.organizations.map(
              (organization) => (
                <li key={organization._id}>
                  {organization.name} ({organization.industry})
                </li>
              )
            )}
          </ul>
          <h2>Brands</h2>
          <ul>
            {data.searchOrganizationsAndBrands.brands.map((brand) => (
              <li key={brand._id}>{brand.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
export default Search