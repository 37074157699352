import React from "react";
import MarketingSideBar from "../MarketingSidebar";
import { LayoutWrapper } from "./styled";

const MarketingLayout = ({ children,sideBarContent,sxSidebar,sxMain }) => {
  return (
    <>
      <MarketingSideBar sxStyle={sxSidebar}>{sideBarContent}</MarketingSideBar>
      <LayoutWrapper sx={sxMain}>{children}</LayoutWrapper>
    </>
  );
};

export default MarketingLayout;

