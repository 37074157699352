import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NodeModalContainerStyle, StyledLabel } from "./index.styled";
import { CreateButton } from "../../components/TagsModal/styled";
import { useNodes } from "reactflow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TagModalContent = ({ selected, tagsList, saveNode, closeModals }) => {
  const [selectedTags, setSelectedTags] = useState({ tag: [], untag: [] });
  const [action, setAction] = useState(
    selected?.data?.metadata?.action ?? "tag"
  );
  // console.log({ selectedTags });
  const nodes = useNodes();
  // const nodes = useStore((s) =>
  // Array.from(s.nodeInternals.values()))
  useEffect(() => {
    if (selectedTags?.tag) {
      setSelectedTags({
        [action]: tagsList
          ?.filter(({ id }) => selectedTags?.tag?.includes(id))
          ?.map(({ id }) => id),
      });
    }
  }, [action]);
  useEffect(() => {
    if (selected) {
      const tag =
        nodes.find(({ id }) => id === selected?.id)?.data?.metadata?.tag ?? [];
      setSelectedTags({
        [action]: tagsList
          ?.filter(({ id }) => tag?.includes(id))
          ?.map(({ id }) => id),
      });
    }
  }, [selected, nodes]);

  return (
    <NodeModalContainerStyle>
      <Typography
        sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
        id="modal-modal-title"
        variant="h5"
      >
        Tags/UnTag
      </Typography>
      <Box
        sx={{
          mt: 2,
          px: 2,
          py: 1.5,
          pb: 6,
          borderTop: "1px solid #E5E3E3",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledLabel>Action</StyledLabel>
          <Select
            placeholder="select tag"
            value={action}
            sx={{
              width: "100%",
              fontFamily: "Switzer",
              borderRadius: "12px",
              mb: 2,
            }}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setAction(value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {["tag", "untag"].map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledLabel>Select Tags</StyledLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            placeholder="select tags"
            multiple
            sx={{
              width: "100%",
              fontFamily: "Switzer",
              borderRadius: "12px",
              mb: 2,
            }}
            value={selectedTags?.[action] ?? []}
            name="selectedTags"
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setSelectedTags({
                [action]: typeof value === "string" ? value.split(",") : value,
              });
            }}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {tagsList
                  ?.filter(({ id }) => selected?.includes(id))
                  .map(({ id, name }) => (
                    <Chip key={id} label={name} />
                  ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {tagsList.map(({ id, name }) => {
              // console.log(selectedTags?.[action]?.indexOf(id) > -1)
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={selectedTags?.[action]?.indexOf(id) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          px: 2,
          py: 2,
          borderTop: "1px solid #E5E3E3",
        }}
      >
        <CreateButton
          color="primary"
          onClick={() => {
            closeModals();
            saveNode({
              ...selected,
              data: {
                ...selected?.data,
                metadata: {
                  ...selected?.data?.metadata,
                  action,
                  tag: selectedTags?.[action],
                },
              },
            });
          }}
        >
          Save Attached Tag
        </CreateButton>
      </Box>
    </NodeModalContainerStyle>
  );
};

export default TagModalContent;
