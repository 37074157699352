import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import {
  APPROVED_VIDEOS_DAYS_COUNT,
  NEW_VIDEOS_DAYS_COUNT,
} from "../graphql/queries/videosQueries";
import { useQuery } from "@apollo/client";
import Spinner from "./Spinner";
ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);
function GenericChart({ genData, item, color }) {
  if (!genData || !(genData instanceof Array)) {
    genData = [
      { [item]: 0, date: "" },
      { [item]: 0, date: "" },
    ];
    // return null; // Or handle the case when no data is available
  }
  genData = genData.map((el) => ({ [item]: el[item], date: el["date"] }));
  while (genData.length < 2) {
    genData.push({ [item]: 0, date: "" });
  }

  //   const videoCounts = previousData
  //     .slice(0, -1)
  //     .map(({ date, count }) => ({ date, count }));

  // console.log(genData, "genDataaa");
  const newData = {
    labels: genData?.map(({ date }) => date),
    datasets: [
      {
        data: genData?.map((el) => el[item]),
        // background:"linear-gradient(262.87deg, rgba(141, 108, 255, 0.1) 0%, rgba(141, 108, 255, 0) 100%);",
        borderColor: color ?? "#4097DA",
        tension: 0.5,
        borderWidth: 1,
        // fill:true,
        pointStyle: "none",
      },
    ],
  };
  const DISPLAY = false;
  const BORDER = true;
  const CHART_AREA = true;
  const TICKS = true;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // remove x-axis labels
        },
        border: {
          display: false,
        },
        grid: {
          display: DISPLAY,
          drawOnChartArea: CHART_AREA,
          drawTicks: TICKS,
        },
      },
      y: {
        ticks: {
          display: false, // remove y-axis labels
        },
        border: {
          display: false,
        },
        grid: {
          display: DISPLAY,
          drawOnChartArea: CHART_AREA,
          drawTicks: TICKS,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  // remove the 'label' property from the dataset
  newData.datasets[0].label = undefined;
  return (
    <div
      className="chart-container"
      style={{ height: "50px", position: "relative", width: "100%" }}
    >
      <Line data={newData} options={options} style={{ width: "100%" }}></Line>
    </div>
  );
}

export default GenericChart;
