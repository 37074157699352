import React from "react";
import {
  BackHomeNavWrapper,
  BackImage,
  DateCreated,
  FilterIcon,
  FilterWrapper,
  HomeNav,
  NavDown,
  NavTextWrapper,
  NavWrapper,
  SearchComponentWrapper,
  SearchHighlight,
  SearchSubWrapper,
  SearchTab,
  SearchTabTwo,
  SearchTabWrapper,
  SearchText,
  SearchWrap,
  SearchwrapTwo,
  SecondFilterIcon,
  SmallTextWrap,
  SortText,
  SortWrapper,
  VideoCard,
  VideoFrame,
  VideoSubCard,
  VideoSubFrame,
  VideosHeading,
  VideosHeadingSubWrapper,
  VideosHeadingWrapper,
  VideosNav,
  VideosSmallText,
  VideosSubHeading,
  VideosWrapper,
} from "./styled";
import Layout from "../../components/Layout";
import MainArrow from "../../assets/icons/MainArrow.svg";
import SmallArrow from "../../assets/icons/SmallArrow.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import MarkUp from "../../assets/icons/ArrowDown.svg";
import { useMutation, useQuery } from "@apollo/client";
import {
  APPROVED_VIDEOS_COUNT,
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  GET_SINGULAR_VIDEO,
  GET_VIDEOS,
  GET_VIDEOS_BY_BRAND,
  NEW_VIDEOS_COUNT,
} from "../../graphql/queries/videosQueries";
import { GET_WIDGET_BRAND } from "../../graphql/queries/brandQueries";

export default function VideoPage({ id }) {
  const {
    loading: videosLoading,
    error: videosError,
    data: videosData,
  } = useQuery(GET_VIDEOS, {
    variables: { brandId: id },
  });

  const { data: dataBrand, loading: brandLoading } = useQuery(
    GET_WIDGET_BRAND,
    {
      variables: { input: { brandId: id } },
    }
  );

  let brandProps = {}; //{ position: "top-right", backgroundColor: "grey" };
  let arr =
    videosData?.approvedVideosByBrandIdAndTrue instanceof Array
      ? videosData?.approvedVideosByBrandIdAndTrue.map((el) => {
          return {
            url: el?.url,
            videoId: el?.videoId,
            brand: el?.brand,
            position: el?.position,
            name: el?.name,
            rating: el?.rating,
            duration: el?.duration,
          };
        })
      : [];

  brandProps =
    dataBrand?.widgetBrand instanceof Object
      ? dataBrand?.widgetBrand
      : brandProps;

  let tabHeaders = [
    "Video",
    "Name",
    "Email",
    "Created at",
    "Duration",
    "Rating",
    "Actions",
    "Status",
  ];

  const Card = ({ children }) => (
    <div
      style={{
        display: "flex",
        width: "400px",
        padding: "12px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "12px",
        borderRadius: "28px",
        background: "#F1F3EC",
      }}
    >
      {children}
    </div>
  );
  return (
    <>
      <VideoFrame>
        <VideoSubFrame>
          <img alt="Vuesax twotone arrow" src={MainArrow} />
          <HomeNav>Home</HomeNav>
          <NavWrapper>
            <NavTextWrapper></NavTextWrapper>
            <BackHomeNavWrapper>
              <NavDown alt="Vuesax twotone arrow" src={SmallArrow} />
            </BackHomeNavWrapper>
            <SmallTextWrap>
              <VideosSmallText>Videos</VideosSmallText>
            </SmallTextWrap>
          </NavWrapper>
        </VideoSubFrame>
        <VideosWrapper>
          <VideosHeadingSubWrapper>
            <VideosHeading>Videos</VideosHeading>
            <VideosSubHeading>
              Powering success with customer stories.
            </VideosSubHeading>
          </VideosHeadingSubWrapper>
          <SearchComponentWrapper>
            <SearchSubWrapper>
              <SearchWrap>
                <SearchHighlight>
                  <SearchTab>
                    <img alt="Vuesax twotone arrow" src={SearchIcon} />
                    <SearchText>Search</SearchText>
                  </SearchTab>
                </SearchHighlight>
              </SearchWrap>
              <SearchwrapTwo>
                <SearchTabWrapper>
                  <SearchTabTwo>
                    <SortWrapper>
                      <SortText>Sort:</SortText>
                      <DateCreated> Date created</DateCreated>
                      <img src={MarkUp} alt="mark-up" />
                    </SortWrapper>
                    {/* <ArrowDown className="arrow-down" /> */}
                  </SearchTabTwo>
                </SearchTabWrapper>
              </SearchwrapTwo>
              <FilterWrapper>
                <FilterIcon>
                  {/* <GridLayout className="grid-layout" /> */}
                </FilterIcon>
                <SecondFilterIcon>
                  {/* <IconComponentNode className="grid-layout" /> */}
                </SecondFilterIcon>
              </FilterWrapper>
            </SearchSubWrapper>
            <VideoCard>
              <VideoSubCard>
                {tabHeaders.map((header, idx) => (
                  <Card key={`Card${idx}`}></Card>
                ))}
              </VideoSubCard>
            </VideoCard>
          </SearchComponentWrapper>
        </VideosWrapper>
      </VideoFrame>
    </>
  );
}
