import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

// let measurementID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID;
// // measurementID && ReactGA.initialize(process.env.REACT_APP_GOOGLE_MEASUREMENT_ID ?? "");
// const initializeReactGA = () => {
//   if (measurementID) {
//     ReactGA.initialize(measurementID); // Replace 'YOUR_TRACKING_ID' with your actual Tracking ID
//     console.log("ReactGA initialization");
//   }
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// initializeReactGA();
