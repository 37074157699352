import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import {
  ActionContainer,
  ActionContainer2,
  ImagePlay,
  ProgressBar,
  Progress,
  RightDiv,
  PlayerWrapper,
  ActionOverall,
} from "./styled";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import { useSelector } from "react-redux";
import RightArrow from "../../../assets/images/right-arrow.svg";
import EmptyStar from "../../../assets/images/empty-star.svg";
import FullStar from "../../../assets/images/full-star.svg";
import PauseIcon from "../../../assets/images/PauseImage.svg";
import PrevIcon from "../../../assets/images/prev.svg";
import VolumeIcon from "../../../assets/images/volume.svg";
import ExpandIcon from "../../../assets/images/expand.svg";
import NextIcon from "../../../assets/images/next.svg";
import PlayIcon from "../../../assets/images/play.svg";
import MuteIcon from "../../../assets/images/mute.svg";
import Description from "./Description";
import SpinnerBig from "../../SpinnerBig";

/**
 * A component for displaying video content.
 *
 * @param {object} props - Component properties.
 * @param {string} props.url - The URL of the video.
 * @param {function} props.chgVideo - A function to change the currently displayed video.
 * @param {object} props.item - Video item data.
 * @param {number} props.videoPausedStartTime - The time when video playback was paused.
 * @param {function} props.setVideoPausedTime - A function to set the time when video playback was paused.
 * @param {number} props.videoPausedTime - The time when video playback was paused.
 * @param {function} props.setVideoPausedStartTime - A function to set the time when video playback was paused.
 * @param {function} props.setVideoPlayedStartTime - A function to set the time when video playback started.
 * @param {boolean} props.showOnlyPlay - A flag to control the visibility of video playback controls.
 */
export default function VideoPlayer({
  url,
  chgVideo,
  item,
  videoPausedStartTime,
  setVideoPausedTime,
  videoPausedTime,
  setVideoPausedStartTime,
  setVideoPlayedStartTime,
  showOnlyPlay,
}) {
  const videoRef = useRef(null);
  const progressRef = useRef(null);
  const pDivRef = useRef(null);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const [mute, setMute] = useState(true);
  const [play, setPlay] = useState(true);
  const [pWidth, setPWidth] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedWidth, setDraggedWidth] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);

  const [cnt, setCnt] = useState(0);

  // Dummy data for video item while loading
  const dummyItem = {
    name: "Loading Video...",
    angle: "Loading...",
    rating: "Loading...",
    position: "Loading...",
  };

  // Dummy URL while loading
  const dummyUrl = "https://example.com/dummy-video.mp4";

  const videoPlay = () => {
    if (cnt === 0) setVideoPausedStartTime(new Date().getTime());
    if (play === false && cnt > 1) {
      let pauseTime =
        videoPausedTime + new Date().getTime() - videoPausedStartTime;

      setVideoPausedTime(pauseTime);
    }
    setPlay(!play);
    setCnt(cnt + 1);
  };

  const enterFullScreen = () => {
    const element = videoRef.current;

    if (element && element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element && element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element && element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element && element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  let duratn =
    typeof item?.duration !== "number"
      ? Number.parseFloat(item?.duration)
      : item?.duration;
  duratn = isNaN(duratn) ? 1 : duratn;

  useEffect(() => {
    const interval = setInterval(() => {
      const player = videoRef.current;
      if (player && player.getDuration) {
        setVideoDuration(player.getDuration());
      }
      const currentTm = player ? player.getCurrentTime() : 0;
      setPWidth((currentTm / videoDuration) * 100);
      setDraggedWidth((currentTm / videoDuration) * 100);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [videoDuration]);

  useEffect(() => {
    setPWidth(0);
    setDraggedWidth(0);
    // Start loading when the URL changes
  }, [url]);

  useEffect(() => {
    // Attach event listeners to the document when the component mounts
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleDragEnd);

    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, []);

  const handleDragStart = () => {
    setIsDragging(true);
    videoRef.current.pause();
  };

  const handleDragEnd = () => {
    if (isDragging) {
      const newTime = (draggedWidth / 100) * videoDuration;
      videoRef.current.seekTo(newTime);
      // videoRef.current.play();
    }
    setIsDragging(false);
  };

  const handleDrag = (event) => {
    if (isDragging) {
      const progressDiv = progressRef.current;
      const dragPosition = event.clientX - progressDiv.offsetLeft;
      const progressBarWidth = progressDiv.offsetWidth;
      const dragPercentage = (dragPosition / progressBarWidth) * 100;

      setDraggedWidth(dragPercentage > 100 ? 100 : dragPercentage);
      setPWidth(dragPercentage > 100 ? 100 : dragPercentage);
    }
  };

  const handleClick = (event) => {
    const progressDiv = progressRef.current;
    const clickPosition =
      event.clientX - progressDiv.offsetLeft - pDivRef.current.offsetLeft;
    const progressBarWidth = progressDiv.offsetWidth;
    const clickPercentage = (clickPosition / progressBarWidth) * 100;

    const playTime = (clickPercentage / 100) * videoDuration;

    videoRef.current.seekTo(playTime);
    // videoRef.current.play();
    setPWidth(clickPercentage > 100 ? 100 : clickPercentage);
    setDraggedWidth(clickPercentage > 100 ? 100 : clickPercentage);
  };

  console.log("isMobile", isMobile);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={pDivRef}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <ReactPlayer
        url={url || dummyUrl}
        ref={videoRef}
        playing={play}
        onClick={videoPlay}
        muted={mute}
        width={isMobile ? "99.8%" : "100%"}
        height={isMobile ? "100%" : "100%"}
        style={{
          background: "black",
          borderRadius: "16px",
          cursor: "pointer",
        }}
        onStart={() => {
          setVideoPlayedStartTime(new Date().getTime());
          setVideoPausedTime(0);
          setVideoPausedStartTime(0);
        }}
        onEnded={() => {
          setPWidth(100);
          setDraggedWidth(100);
          chgVideo(1);
        }}
      />
      <ActionContainer
        style={{
          justifyContent: showOnlyPlay && "center",
        }}
      >
        {!showOnlyPlay && (
          <img
            src={PrevIcon}
            style={{ cursor: "pointer" }}
            onClick={() => chgVideo(-1)}
          />
        )}
        <ImagePlay>
          <img
            src={play === true ? PauseIcon : PlayIcon}
            onClick={videoPlay}
            style={{
              cursor: "pointer",
              width: isMobile ? "43px" : "70px",
              height: isMobile ? "43px" : "70px",
            }}
          />
        </ImagePlay>
        {!showOnlyPlay && (
          <img
            src={NextIcon}
            style={{ cursor: "pointer" }}
            onClick={() => chgVideo(1)}
          />
        )}
      </ActionContainer>
      {hovered && (
        <ActionOverall>
          <ActionContainer2>
            <div style={{ width: "40%" }}>
              <Description
                style={{ marginBottom: 0 }}
                name={item?.name}
                angle={item?.angle}
                rating={item?.rating}
                position={item?.position}
                isMobile={isMobile}
              />
            </div>
            <RightDiv isMobile={isMobile}>
              <img
                src={mute ? MuteIcon : VolumeIcon}
                style={{ cursor: "pointer" }}
                onClick={() => setMute(!mute)}
              />
            </RightDiv>
          </ActionContainer2>

          <div
            style={{ position: "absolute", width: isMobile ? "95%" : "100%" }}
          >
            <ProgressBar
              onClick={handleClick}
              ref={progressRef}
              isMobile={isMobile}
            >
              <Progress width={isDragging ? draggedWidth : pWidth} />
              <button
                className="drag-button"
                onMouseDown={handleDragStart}
                style={{
                  position: "absolute",
                  left: `${pWidth}%`,
                  top: "-6px",
                  transform: "translateX(-50%)",
                  zIndex: 6,
                  width: "15px",
                  height: "15px",
                  borderRadius: "50%",
                  border: "none",
                  backgroundColor: "#ee1b37",
                }}
              ></button>
            </ProgressBar>
          </div>
        </ActionOverall>
      )}
    </div>
  );
}
