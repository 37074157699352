import styled from 'styled-components';

export const AnalyticsFrame = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 32px;
    left: 100px;
    position: absolute;
    top: 116px;
    width: 100%;
`