import React, { useState } from "react";
import MarketingLayout from "../../components/MarketingLayout";
import WebsiteCard from "../../components/WebsiteCard";
import {
  BuilderContainer,
  CreateButton,
  ModalContainer,
  StyledAuthButton,
  StyledFormLabel,
  TextInputContainer,
} from "./styled";
import { Link, useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { getPagesFromStorage, savePagesToStorage } from "../../utils/pageStore";
import { useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";

const WebsiteBuilder = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const list = getPagesFromStorage();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  // console.log({errors})

  const onSubmit = (data) => {
    // console.log(data)
    const storageArray = getPagesFromStorage();
    const newFile = {
      id: nanoid(),
      name: data.name,
      domain: data.domain,
      header: data.header,
    };
    savePagesToStorage([...storageArray, newFile]);
    navigate(newFile.id);
  };
  return (
    <MarketingLayout
      sxMain={{ p: 0.4 }}
      sxSidebar={{ p: 0.4 }}
      sideBarContent={
        <BuilderContainer>
          <CreateButton onClick={handleOpen}>Create Website</CreateButton>
        </BuilderContainer>
      }
    >
      <Box sx={{ padding: "5px", height: "100%" }}>
        {list.length ? (
          list.map((x) => <WebsiteCard key={x.id} website={x} />)
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{ fontWeight: 700 }}
                align="center"
                id="modal-modal-title"
                variant="h5"
              >
                You have Not Created Any Page Yet
              </Typography>
              <CreateButton
                hasBorder
                onClick={handleOpen}
                startIcon={<AddIcon />}
              >
                Create Page
              </CreateButton>
            </Box>
          </Box>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalContainer>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                src={RemoveIcon}
                style={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
            <Typography
              sx={{ fontWeight: 700 }}
              id="modal-modal-title"
              variant="h5"
            >
              Create New Website
            </Typography>
            <Box>
              <TextInputContainer
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 1 }}
              >
                {/* Name Input */}
                <TextInputContainer>
                  <StyledFormLabel sx={{ marginTop: 0.65 }}>
                    Name*
                  </StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label=""
                    placeholder="Enter your name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    {...register("name", { required: true })}
                  />
                </TextInputContainer>

                {/* Email Input */}
                <TextInputContainer>
                  <StyledFormLabel>Domain*</StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="domain"
                    label=""
                    placeholder="Enter Domain"
                    name="domain"
                    autoComplete="domain"
                    autoFocus
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    {...register("domain", {
                      required: "URL is required",
                      pattern: {
                        value: /^(ftp|http|https):\/\/[^ "]+$/,
                        message: "Please enter a valid URL",
                      },
                    })}
                  />
                </TextInputContainer>

                {/* Password Input */}
                <TextInputContainer>
                  <StyledFormLabel>Custom Header</StyledFormLabel>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="header"
                    label=""
                    placeholder="Enter custom header"
                    type="text"
                    id="header"
                    multiline
                    rows={4}
                    autoComplete="current-password"
                    sx={{
                      margin: "8px 0px 8px",
                      ".MuiInputBase-root": {
                        // height: "50px",
                        borderRadius: "15px",
                      },
                      ":hover": {
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#1a1b24 !important",
                          borderWidth: "1px !important",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#007bf7",
                          borderWidth: "1px !important",
                          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        },
                      },
                    }}
                    {...register("header")}
                  />
                </TextInputContainer>

                {/* Sign Up Button */}
                <StyledAuthButton
                  type="submit"
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {loading ? "Loading..." : "   Create Page"}
                </StyledAuthButton>
              </TextInputContainer>
            </Box>
          </ModalContainer>
        </Modal>
      </Box>
    </MarketingLayout>
  );
};

export default WebsiteBuilder;
