import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Layout from "../../components/Layout";
import {
  CustomSelect,
  FlexContainer,
  InviteMemberMainText,
  InviteMemberText,
  LeftTitle,
  MembersContainer,
  OptionItem,
  OptionText,
  OrganizationText,
  SubTitle,
  SubTitleContainer,
  TimeText,
  TitleHeader,
} from "./styled";
import Button from "../../components/Button";
import SubHeader from "../../components/SubHeader";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Grid,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Search from "../../components/Search";
import Table from "../../components/Table";
import Filter from "../../components/Filter";
import NameTag from "../../components/NameTag";
import { GET_MEMBERS_LIST } from "../../graphql/queries/invitation";
import {
  GET_USER_OWNED_ORGANIZATION,
  GET_USER_ORGANIZATION,
} from "../../graphql/queries/organizationQueries";
import { useSnackbar } from "notistack";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MultipleSelect from "../../components/MultipleSelect";
import SpinnerBig from "../../components/SpinnerBig";
import { UPDATE_USER_ORGANIZATION_INVITE_ACCESS } from "../../graphql/mutations/userOrganization";
import {
  RESEND_USER_OGRANIZATION_INVITE,
  DELETE_USER_OGRANIZATION_INVITE,
} from "../../graphql/mutations/organizationMutation";
import moment from "moment/moment";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropdown from "../../components/Dropdown";
import EllipsisLink from "../../components/EllipsisLink";
import ButtonCust from "../../components/ButtonCust";
import InviteMemberIcon from "../../assets/icons/InviteMembersW.svg";
import Modal from "../../components/Modal";
import { styled } from "@mui/material/styles";
import OrgnIcon from "../../assets/icons/OrgnIcon.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import Trash from "../../assets/icons/Trash.svg";
import PermissionIcon from "../../assets/images/permission.svg";
import CloseIcon from "../../assets/images/Close.svg";
import Plus from "../../assets/icons/Plus.svg";
import ColorButton from "../../components/ColorButton";
import { NavSearch, TableDiv } from "../UserDashboard/styled";
import { socket } from "../../App";
import { useSelector } from "react-redux";
import EllipsisLinkBrand from "../../components/EllipsisLink/EllipsisLink";
import { OptionsText } from "../Organizations/styled";
import DeleteModal from "../../components/DeleteModal";
import { TabTitle } from "../../utils/HeaderTitle";

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({})
);

export default function TeamMembers() {
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const menu = useSelector((state) => state.menu);

  TabTitle(`Team Members`);
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [isOpenSelect, setIsOpenSelect] = useState(undefined);
  const [modalContent, setModalContent] = useState("");
  const [pitem, setPitem] = useState();
  const [modalArray, setModalArray] = useState([]);
  const [members, setMembers] = useState([]);
  const [expanded, setExpanded] = useState(1);
  const [org, setOrg] = useState("*");
  const navigate = useNavigate();
  const [getList] = useLazyQuery(GET_MEMBERS_LIST);
  const { data: dataOrganization, loading: loadingOrganization } = useQuery(
    GET_USER_ORGANIZATION
  );

  const [updateList] = useMutation(UPDATE_USER_ORGANIZATION_INVITE_ACCESS);
  const [resendInvite] = useMutation(RESEND_USER_OGRANIZATION_INVITE);
  const [deleteInvite] = useMutation(DELETE_USER_OGRANIZATION_INVITE);
  const { enqueueSnackbar } = useSnackbar();

  socket.on("invitedUser", (data) => {
    // Display the notification in the user's dashboard
    console.log("Permission Upgraded", data);
    // console.log(data.message); // or update your state to show the notification
  });
  const tableHeadCells = isMobile
    ? [
        {
          id: "brandName",
          numeric: false,
          disablePadding: true,
          label: "Name",
          width: "100%",
        },
      ]
    : [
        {
          id: "brandName",
          numeric: false,
          disablePadding: true,
          label: "Name",
          width: "40%",
        },
        {
          id: "permission",
          numeric: false,
          disablePadding: true,
          label: "Assigned Permissions",
          width: "35%",
        },

        {
          id: "lastActive",
          numeric: false,
          disablePadding: true,
          label: "Last Active",
          width: "20%",
        },
        {
          id: "others",
          numeric: false,
          disablePadding: true,
          label: "",
        },
      ];
  const subSelectData = [
    { label: "Can view", value: "view" },
    { label: "Can edit", value: "edit" },
  ];

  const getOrganizations = () => {
    if (dataOrganization?.userOrganizations instanceof Array) {
      let result = dataOrganization.userOrganizations.map((item) => {
        return {
          title: item?.organization?.name,
          id: item?.organization?._id,
        };
      });
      result.unshift({ title: "All", id: "*" });
      return result;
    }
    return [];
  };

  const getBrands = (item) => {
    let organization = item?.organization?._id;

    let owned = item?.owned;

    if (dataOrganization?.userOrganizations instanceof Array) {
      let organizationObj = dataOrganization.userOrganizations.find(
        (item) => item?.organization?._id === organization
      );

      let brandAccess = owned ? organizationObj?.brands : item?.brandAccess;
      if (organizationObj && brandAccess instanceof Array) {
        let result = brandAccess.map((item, index) => {
          return {
            label: item?.brand?.name,
            value: item?.brand?._id,
            image: item?.brand?.imageUrl,
            background: item?.brand?.backgroundColor,
            color: item?.brand?.color,
          };
        });
        // console.log(result, "result");
        return result;
      }
    }

    return [];
  };

  const updateRole = async (item, val) => {
    try {
      // console.log(val, item?.inviteId, "Role to update");
      let brands = val.map((el) => ({
        access: el?.sub ?? "view",
        brand: el?.value,
      }));
      let resp = await updateList({
        variables: { input: { inviteId: item?.inviteId, brands } },
      });
      if (resp?.data) {
        enqueueSnackbar(`${item?.invitedUserEmail}'s brand(s) updated `, {
          variant: "success",
        });
        await getData();
      } else {
        enqueueSnackbar(
          `Update on ${item?.invitedUserEmail}'s brand(s) was not successful `,
          { variant: "error" }
        );
      }
    } catch (err) {
      // console.log(err);
      enqueueSnackbar(
        `${item?.invitedUserEmail}'s brand(s) was not successfully updated `,
        { variant: "error" }
      );
    }
    setOpenSelect(false);
  };

  const resendInviteMail = async (item) => {
    try {
      // console.log(item, "item");
      let resp = await resendInvite({
        variables: {
          input: {
            _id: item?.inviteId,
          },
        },
      });
      if (resp?.data) {
        enqueueSnackbar(
          `${item?.invitedUserEmail} was successfully reinvited `,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          `Error: ${item?.invitedUserEmail} re-invite request was not successful `,
          { variant: "error" }
        );
      }
    } catch (e) {
      // console.log(e);
      enqueueSnackbar(
        `Error: Unable to send mail to ${item?.invitedUserEmail}: ${e} `,
        { variant: "error" }
      );
    }
  };

  const removeMember = async (item) => {
    setOpen(true);
    setPitem(item);
    // setModalContent(
    //   `Are you sure you want to remove this member  ${item?.invitedUserEmail} from organization ${item?.organization?.name} ?`
    // );
    setModalContent(
      `You are about to remove this member  ${item?.invitedUserEmail} from  ${item?.organization?.name}.`
    );
    setModalArray([
      {
        title: "Yes",
        variant: "contained",
        color: "error",
        actFunction: () => removeMemberFn(item),
        size: "large",
        style: { width: "40%" },
      },

      {
        title: "No",
        color: "success",
        variant: "contained",
        actFunction: () => setOpen(false),
        size: "large",
        style: { width: "40%" },
      },
    ]);
  };

  const removeMemberFn = async (item = pitem) => {
    // console.log(item, "item");
    try {
      // console.log(item, "item");
      let resp = await deleteInvite({
        variables: {
          input: {
            _id: item?.inviteId,
          },
        },
      });
      if (resp?.data) {
        enqueueSnackbar(`${item?.invitedUserEmail} was successfully removed `, {
          variant: "success",
        });
        await getData();
      } else {
        enqueueSnackbar(
          `Error: ${item?.invitedUserEmail} removal request was not successful `,
          { variant: "error" }
        );
      }
    } catch (e) {
      // console.log(e);
      enqueueSnackbar(
        `Error: Unable to remove member ${item?.invitedUserEmail}: ${e} `,
        { variant: "error" }
      );
    } finally {
      setOpen(false);
    }
  };

  const getBrandAccess = (data) => {
    return data.map((el) => ({ sub: el?.access, value: el?.brand?._id }));
  };

  const launchMultipleSelect = (item) => {
    setOpenSelect(item);
    setIsOpenSelect(true);
  };

  const handleMobileClose = () => {
    setOpenSelect(false);
    setIsOpenSelect(undefined);
  };

  // console.log(isOpenSelect, "isOpenSelect");

  const displayMobileSelectTag = () => {
    if (!isMobile) return null;
    let item = openSelect;

    let mobileDivTitle = (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          padding: "10px",
          zIndex: 99999,
        }}
      >
        <NameTag
          upperText={item?.invitedUser?.name}
          lowerText={item?.invitedUserEmail}
          image={item?.invitedUser?.thumbnail}
          active={item?.accepted}
        />
        <img src={CloseIcon} onClick={handleMobileClose} />
      </div>
    );
    return openSelect ? (
      <MultipleSelect
        subSelectData={subSelectData}
        data={getBrands(item)}
        defaultValue={getBrandAccess(item?.brandAccess)}
        title="Brand Permissions"
        placeholder={item.inviteId === "owned" ? "Owner" : "View permission"}
        // setValues={(val) => setBrandSelect(val, index)}
        applyFn={(val) => updateRole(item, val)}
        dkey={item?.inviteId}
        viewAllInput
        disabled={item.inviteId === "owned"}
        apply={item.owned}
        display={item.owned}
        widthForm="300px"
        menuMarginLeft="50px"
        isOpen={isOpenSelect}
        setIsOpen={setIsOpenSelect}
        mobileDivTitle={mobileDivTitle}
      />
    ) : null;
  };

  const options = (item) => {
    let optionArr = isMobile
      ? [
          {
            title: (
              <div
                style={{
                  minWidth: "105px",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0",
                }}
                onClick={() =>
                  item.inviteId !== "owned" && launchMultipleSelect(item)
                }
              >
                <img src={PermissionIcon} alt="permission-icon" />
                <OptionsText style={{ marginLeft: "10px" }}>
                  {item.inviteId === "owned" ? "Owner" : "Assigned Permission"}
                </OptionsText>
              </div>
            ),
            link: () => console.log(),
          },
          item?.owned && item.inviteId !== "owned" && !item.accepted
            ? {
                title: (
                  <div
                    style={{
                      minWidth: "105px",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    <img src={Plus} alt="invite-icon" />
                    <OptionsText style={{ marginLeft: "20px" }}>
                      Resend Invite
                    </OptionsText>
                  </div>
                ),
                link: () => resendInviteMail(item),
              }
            : null,
          item?.owned && item.inviteId !== "owned"
            ? {
                title: (
                  <div
                    style={{
                      minWidth: "105px",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    <img src={Trash} alt="remove-icon" />
                    <OptionsText style={{ marginLeft: "13px" }}>
                      Remove Member
                    </OptionsText>
                  </div>
                ),
                link: () => removeMember(item),
              }
            : null,
        ]
      : [
          item.accepted
            ? {
                title: (
                  <OptionItem>
                    <OptionText>Resend Invite</OptionText>
                  </OptionItem>
                ),
                link: () => resendInviteMail(item),
              }
            : null,
          {
            title: (
              <OptionItem>
                <OptionText>Remove Member</OptionText>
              </OptionItem>
            ),
            link: () => removeMember(item),
          },
        ];
    // if (item.accepted) optionArr.shift();
    return optionArr;
  };

  const getRows = (data) => {
    return data.map((item) => {
      let newObj = {
        organizationId: item?.organization?._id,
        name: `${item?.invitedUser?.name}${item?.organization?._id}`,
      };
      for (let el of tableHeadCells) {
        switch (el.id) {
          case "brandName":
            newObj[el.id] = isMobile ? (
              <TableDiv>
                <div
                  style={{
                    width: "80%",
                    overflow: "hidden",
                    // border: "1px solid #ccc",
                  }}
                >
                  <NameTag
                    upperText={item?.invitedUser?.name}
                    lowerText={item?.invitedUserEmail}
                    image={item?.invitedUser?.thumbnail}
                    active={item?.accepted}
                  />
                </div>

                <EllipsisLinkBrand
                  style={{
                    width: "5%",
                    height: "5%",
                    padding: "5px",
                    border: "none",
                  }}
                  content={options(item)}
                />
              </TableDiv>
            ) : (
              <NameTag
                upperText={item?.invitedUser?.name}
                lowerText={item?.invitedUserEmail}
                image={item?.invitedUser?.thumbnail}
                active={item?.accepted}
              />
            );

            break;

          case "permission":
            newObj[el.id] = (
              <MultipleSelect
                subSelectData={subSelectData}
                data={getBrands(item)}
                defaultValue={getBrandAccess(item?.brandAccess)}
                title="Brand Permissions"
                placeholder={
                  item.inviteId === "owned" ? "Owner" : "View permission"
                }
                // setValues={(val) => setBrandSelect(val, index)}
                applyFn={(val) => updateRole(item, val)}
                dkey={item?.inviteId}
                viewAllInput
                disabled={item.inviteId === "owned"}
                apply={item.owned}
                display={item.owned}
                widthForm="300px"
                menuMarginLeft="50px"
              />
            );
            break;

          case "lastActive":
            newObj[el.id] = (
              <TimeText>
                {item?.invitedUser?.lastActive &&
                  moment(item?.invitedUser?.lastActive).fromNow()}
              </TimeText>
            );
            break;

          case "others":
            newObj[el.id] = item?.owned && item.inviteId !== "owned" && (
              <EllipsisLink content={options(item)} />
            );
            break;

          default:
            newObj[el.id] = "";
        }
      }

      return newObj;
    });
  };

  const getData = async () => {
    try {
      let { data } = await getList();
      if (data?.membersList instanceof Array) {
        setMembers(getRows(data?.membersList));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleOrgChange = (val) => setOrg(val);

  const handleAccordionChange = (panel) => {
    // console.log(panel === expanded ? false : panel, "Panel", panel);
    setExpanded(panel === expanded ? false : panel);
  };
  // console.log(members, "members");

  let organizationArray = [];

  const displayContent = () => {
    if (dataOrganization?.userOrganizations instanceof Array) {
      organizationArray = dataOrganization.userOrganizations.map((item) => ({
        _id: item?.organization?._id,
        name: item?.organization?.name,
      }));
    }

    let selectedArr =
      org === "*"
        ? organizationArray
        : organizationArray.filter((elm) => elm._id === org);

    // console.log(selectedArr, "selectedArr");

    let result = selectedArr.map((el, ind) => {
      let orgMembers = members.filter((mem) => mem?.organizationId === el?._id);

      // console.log(orgMembers, "orgMembers", members);

      return (
        <div key={`Accordion${el?._id}`} style={{ marginBottom: 44 }}>
          <Accordion
            defaultExpanded={true}
            // onChange={() => handleAccordionChange(ind + 1)}
            sx={{
              boxShadow: "none",
              border: 0,
              position: "static",
              minHeight: "20px",
            }}
          >
            <AccordionSummary
              sx={{
                border: 0,
                borderBottom: "1px solid #ECECF2",
                padding: 0,
                height: "10px",
                minHeight: "40px !important",
              }}
              expandIcon={<ExpandMoreIcon sx={{ minHeight: "20px" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                <OrganizationText>
                  <img src={OrgnIcon} alt="orgn icon" />{" "}
                  <div style={{ marginLeft: 8 }}>{el?.name}</div>
                </OrganizationText>
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Grid container spacing={0} sx={{ padding: 0 }}>
                <Grid item xs={12} md={12}>
                  <Table
                    headCells={tableHeadCells}
                    rows={orgMembers}
                    stylehead
                    isMobile={isMobile}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });

    return result;
  };

  useEffect(() => {
    getData();
  }, [dataOrganization, isMobile]);

  if (loadingOrganization) return <SpinnerBig />;

  return (
    <>
      <MembersContainer style={{ marginBottom: isMobile && "80px" }}>
        <SubHeader
          arrLink={[
            { title: "Dashboard", link: "/" },
            { title: "Invite Member", link: "/invitation" },
            { title: "Team Members" },
          ]}
        />
        <FlexContainer>
          <TitleHeader isMobile={isMobile}>
            <LeftTitle>Team Members</LeftTitle>

            <ColorButton
              image={InviteMemberIcon}
              title="Invite Members"
              background="#1A1B24"
              buttonTextStyle={{ color: "white" }}
              width="250px"
              onClick={() => navigate("/invitation")}
              order={true}
            />
          </TitleHeader>
          <Grid
            container
            spacing={2}
            sx={{
              marginBottom: "16px",
            }}
          >
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <SubTitle>Organization:</SubTitle>
              <Dropdown
                data={getOrganizations()}
                defaultValue="*"
                handleChange={handleOrgChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <NavSearch
                style={{ marginTop: "0rem", width: isMobile && "100%" }}
                className="navigation-img"
                tabIndex="0"
              >
                <img
                  src={SearchIcon}
                  alt="share-icon"
                  style={{ height: 20, marginLeft: "10px" }}
                />
                <Search />
              </NavSearch>
            </Grid>
            {/* <Grid
              item
              xs={12}
              md={2}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Filter />
            </Grid> */}
          </Grid>
          {displayContent()}
          {displayMobileSelectTag()}
        </FlexContainer>
      </MembersContainer>
      {/* <Modal
        open={open}
        setOpen={setOpen}
        content={modalContent}
        actions={modalArray}
      /> */}
      <DeleteModal
        openModal={open}
        handleCloseModal={() => setOpen(false)}
        handleModalConfirm={removeMemberFn}
        isMobile={isMobile}
        headerText="Remove this Member ?"
        subHeaderText={modalContent}
        btnText="Remove"
      />
    </>
  );
}
