import React from "react";
import { ActButton } from "./styled";

/**
 * A reusable button component that extends the `ActButton` component.
 *
 * @param {Object} props - The component's props.
 * @returns {JSX.Element} The button component.
 */
export default function CustomButton({ ...props }) {
  return <ActButton {...props} />;
}

