import { createSlice } from "@reduxjs/toolkit";

const dateSlice = createSlice({
  name: "date",
  initialState: {
    startDate: "",
    endDate: "",

  },
  reducers: {
    setDate: (state, action) => {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
     
    },
    logOutDate: (state) => {
      state.startDate = "";
      state.endDate = "";

    },
  },
});

export const { setDate, logOutDate } = dateSlice.actions;
export const dateReducer = dateSlice.reducer;
