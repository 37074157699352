import React, { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify"; // Install this package using npm or yarn
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PAGE_BY_SLUG } from "../../graphql/queries/pageBuilder";

const PageFromHTML = () => {
  const { slug } = useParams();
  const { data, loading } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });
  const [htmlContent, setHtmlContent] = useState("");
  const [counter, setCounter] = useState(0);
  const [productArray, setProductArray] = useState([]);

  const [jsOpen, setJsOpen] = useState(false);
  const divRef = useRef(null);
  // const productArray = [
  //   //will use useref instead ...
  //   { id: "1", item: "Basic", value: 300 },
  //   { id: "2", item: "Basic", value: 200 },
  // ];

  const cartFn = () => {
    // Select all elements with the class name "exampleClass"
    if (htmlContent) {
      const elements = document.querySelectorAll(".cart-icon");
      const handleClick = (product, elem) => {
        let storedItem;
        try {
          storedItem = localStorage.getItem(`${elem.id}`)
            ? JSON.parse(localStorage.getItem(`${elem.id}`))
            : [];
        } catch {
          storedItem = [];
        }

        if (!divRef.current) divRef.current = storedItem;
        if (product) {
          //search it from database / from the products array
          let foundObj = productArray.find((el) => el.id === product);
          if (foundObj) {
            divRef.current = [...divRef.current, foundObj];
            localStorage.setItem(`${elem.id}`, JSON.stringify(divRef.current));
          }
        }
      };

      const elementClicked = (product, elem) => {
        handleClick(product, elem);
        let parentElement = elem.parentNode;
        if (parentElement)
          parentElement.innerHTML = `
        <svg class="cart-icon"  height="30" width="30" style="cursor:pointer;"
          onClick="let elemId = 'cart-icon${elem.id}';
          if (document.getElementById(elemId)) {
            let computedStyles = window.getComputedStyle(document.getElementById(elemId));
            if (computedStyles.display === 'none') {
              document.getElementById(elemId).style.display = 'block';
            } else {
              document.getElementById(elemId).style.display = 'none';
            }
          }"
        stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
        xmlns="http://www.w3.org/2000/svg"><circle cx="176" cy="416" r="32"/><circle cx="400" cy="416" r="32"/><path d="M167.78 304h261.34l38.4-192H133.89l-8.47-48H32v32h66.58l48 272H432v-32H173.42l-5.64-32z"/></svg>
        <div id="cart-icon${elem.id}" style="display:none">
        <button onClick="window.open('/page/${elem.id}/checkout','_blank')" >Check Out</button>
        </div>
        `;
        // elem.textContent = "Clicked";
      };

      // console.log(elements, "elements");
      setJsOpen(true);

      // Iterate through each element using forEach
      elements.forEach((element) => {
        let elementId = element.id;
        // alert(elementId);
        let elementsWithDataCardTarget = document.querySelectorAll(
          `[data-cart_target="${elementId}"]`
        );
        elementsWithDataCardTarget.forEach((crdTrgt) =>
          crdTrgt.addEventListener("click", () =>
            elementClicked(crdTrgt.getAttribute("data-product"), element)
          )
        );
      });

      return () => {
        elements.forEach((element) => {
          let elementId = element.id;
          // alert(elementId);
          let elementsWithDataCardTarget = document.querySelectorAll(
            `[data-cart_target="${elementId}"]`
          );
          elementsWithDataCardTarget.forEach((crdTrgt) =>
            crdTrgt.removeEventListener("click", () =>
              elementClicked(crdTrgt.getAttribute("data-product"), element)
            )
          );
        });
      };
    }
  };

  const deleteItem = (id) => {
    divRef.current =
      divRef.current instanceof Array
        ? divRef.current.filter((el, index) => index !== id)
        : [];
    setCounter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the counter every second
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    // Return a cleanup function to clear the interval when the component unmounts or when the dependencies change
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // data is fetched with the css and html files
    ///css
    // console.log(data, "dattta");
    if (data && data?.getPageBySlug instanceof Object) {
      ////////////////css////////////////////////
      let cssContent = data?.getPageBySlug?.css;
      // Create a new Blob containing the CSS content
      let blob = new Blob([cssContent], { type: "text/css" });
      // Create a URL for the Blob
      let cssUrl = URL.createObjectURL(blob);
      // Create a new link element
      let linkElement = document.createElement("link");
      // Set the attributes of the link element
      linkElement.rel = "stylesheet";
      linkElement.href = cssUrl;
      // Append the link element to the head of the document
      document.head.appendChild(linkElement);
      ///////////////////////////////////////////////////
      ///html///////////////////////
      if (data?.getPageBySlug?.isPublished) {
        let html = data?.getPageBySlug?.html;
        const sanitizedHtml = DOMPurify.sanitize(html);

        setHtmlContent(sanitizedHtml);
        setProductArray(data?.getPageBySlug?.products ?? []);
      } else {
        const sanitizedHtml = DOMPurify.sanitize(`<h2>Not Published</h2>`);
        setHtmlContent(sanitizedHtml);
      }

      ///////////////////////////////
      return () => {
        document.head.removeChild(linkElement);
      };
    }
  }, [data]);

  useEffect(() => {
    if (!jsOpen) cartFn();
  }, [htmlContent]);
  let sum = 0;

  return loading ? (
    <>Loading</>
  ) : (
    <>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      {/* <table style={{ height: "min-content" }}>
        {divRef.current instanceof Array &&
          divRef.current.map((el, idx) => {
            sum += el.price;
            return (
              <tr key={idx}>
                <td>{el.name}</td>
                <td>{el.price}</td>
                <td>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteItem(idx)}
                  >
                    delete
                  </span>
                </td>
              </tr>
            );
          })}
        {sum > 0 && (
          <tr>
            <td>TOTAL</td>
            <td>{sum}</td>
          </tr>
        )}
      </table> */}
    </>
  );
};

export default PageFromHTML;
