import React from "react";
import { useSelector } from "react-redux";
import { Typography, IconButton } from "@mui/material";
import {
  FullItem,
  StyledButtonContainer,
  StyledControlContainer,
  StyledIconButton,
  StyledTextField,
  SubItem,
} from "./styled";
import AddIcon from "../../assets/icons/Add.svg";
import SubtractIcon from "../../assets/icons/Minus.svg";
import TextSizeIcon from "../../assets/icons/TextSize.svg";
import FontSizeIcon from "../../assets/icons/FontSize.svg";
import DividerIcon from "../../assets/icons/Divider.svg";

/**
 * A reusable control for incrementing or decrementing a numerical value.
 * @component
 * @param {Object} props - The component's props.
 * @param {number} props.val - The current numerical value.
 * @param {Function} props.setVal - A function to update the value.
 * @param {string} props.type - The type of control (either "height" or "text").
 * @param {boolean} props.disabled - Whether the control is disabled.
 * @returns {JSX.Element} - The rendered component.
 */
const DecIncrControl = ({ val, setVal, type, disabled }) => {
  const isMobile = useSelector((state) => state.responsive.isMobile);

  /**
   * Increments the value by 0.1 (to one decimal place).
   */
  const increment = () => {
    setVal(Number.parseFloat((parseFloat(val) + 0.1).toFixed(1)).toFixed(1));
  };

  /**
   * Decrements the value by 0.1 (to one decimal place) if greater than 0.
   */
  const decrement = () => {
    if (val !== 0) {
      setVal(Number.parseFloat((parseFloat(val) - 0.1).toFixed(1)).toFixed(1));
    }
  };

  /**
   * Handles changes in the input field, validating and updating the value.
   * @param {string} value - The input field value.
   */
  const handleChange = (value) => {
    let pattern = /^\d+(\.\d*)?$/;
    if (pattern.test(value)) {
      setVal(value);
    }
    if (value === "") {
      setVal(0);
    }
  };

  // Determine the appropriate icon based on the control type.
  let labelIcon = type === "height" ? FontSizeIcon : TextSizeIcon;

  return (
    <StyledButtonContainer isMobile={isMobile}>
      <img src={labelIcon} alt="icon" />
      <img src={DividerIcon} alt="divider-icon" />
      <StyledControlContainer isMobile={isMobile}>
        <StyledIconButton onClick={() => decrement()} disabled={disabled}>
          <img src={SubtractIcon} alt="subtract" />
        </StyledIconButton>
        <FullItem>
          <StyledTextField
            className="inputFocus"
            sx={{ input: { textAlign: "center" } }}
            value={val}
            onChange={(e) => handleChange(e.target.value)}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: { width: "30px" },
              disabled: disabled,
            }}
          />
          <SubItem>em</SubItem>
        </FullItem>
        <StyledIconButton
          style={{ marginRight: "10px" }}
          onClick={() => increment()}
          disabled={disabled}
        >
          <img src={AddIcon} alt="Add" />
        </StyledIconButton>
      </StyledControlContainer>
    </StyledButtonContainer>
  );
};

export default DecIncrControl;
