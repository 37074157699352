import React, { useEffect, useRef, useState } from "react";
import "grapesjs/dist/css/grapes.min.css";
import "./base.css";

import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Fade,
  ListItemIcon,
} from "@mui/material";
import MarketingLayout from "../../components/MarketingLayout";
import { CreateButton } from "./styled";
import {
  AntTab,
  AntTabs,
  BuilderNav,
  CustomTabPanel,
  SliderStyle,
  SideBarContentBox,
} from "./styled";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Link, useParams } from "react-router-dom";

import { GET_PAGE_BY_SLUG } from "../../graphql/queries/pageBuilder";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { UPDATE_PAGE } from "../../graphql/mutations/pageBuilder";
import { assetsFileFormat } from "./builderUtils";
import ProductModal from "../../components/ProductsModal";
import grapesjsSetup from "./editorSetup";
import { TroubleshootSharp } from "@mui/icons-material";
import { punchFontString } from "../../utils/pageStore";

const PageBuilder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [editor, setEditor] = useState();
  const [open, setOpen] = useState(true);
  const [openProducts, setOpenProducts] = useState(false);
  const [showMenu, setShowMenu] = useState(null);

  const [value, setValue] = useState(0);
  const [assets, setAssets] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const { slug } = useParams();
  const updatedOnce = useRef(true);
  const dataRef = useRef(null);
  const productRef = useRef([]);
  const pgContent = useRef({});
  const editorRef = useRef({});
  editorRef.current = editor;
  const [updatePage] = useMutation(UPDATE_PAGE);

  const { data } = useQuery(GET_PAGE_BY_SLUG, {
    variables: {
      slug,
    },
  });

  const firstMount = useRef(true);
  // console.log({ data });
  //handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const previewToggle = () => {
    setIsPreviewActive((x) => !x);
  };

  // `data-product="${productId}"`
  const handleSave = async (auto = false) => {
    try {
      let baseEditor = auto ? editorRef?.current : editor;
      let cssString = baseEditor?.getCss();
      if (!cssString?.includes("@font-face")) {
        cssString += punchFontString;
      }
      let input = {
        css: cssString,
        html: baseEditor?.getHtml(),
        // asset:[{fileName:'',extension:''}]
        _id: auto
          ? dataRef?.current?.getPageBySlug?._id
          : data?.getPageBySlug?._id,
      };

      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input,
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || `Error updating Page`,
          {
            variant: "error",
          }
        );

        return;
      }
      enqueueSnackbar(`Updating Page successful`, {
        variant: "success",
      });
      pgContent.current = {
        css: baseEditor?.getCss(),
        html: baseEditor?.getHtml(),
      };
    } catch (e) {
      enqueueSnackbar(`Page Update failed ${e}`, {
        variant: "error",
      });
    }
  };

  const handlePublishToggle = async () => {
    try {
      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input: {
            css: editor.getCss(),
            html: editor.getHtml(),
            isPublished: !data?.getPageBySlug?.isPublished,
            _id: data?.getPageBySlug?._id,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error updating publish`,
          {
            variant: "error",
          }
        );
        return;
      }
      enqueueSnackbar(
        `${
          dataUpdate?.updatePage?.isPublished ? "Published" : "Unpublished"
        } Page`,
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(`Publish Update failed ${e}`, {
        variant: "error",
      });
    }
  };

  //asset update
  const updateAssets = async ({ Key }) => {
    const asset = [
      ...(dataRef?.current?.getPageBySlug?.asset?.map(
        ({ fileName, extension }) => ({ fileName, extension })
      ) ?? []),
      { fileName: Key, extension: Key.split(".")[1] },
    ];
    try {
      // console.log(dataRef.current, "dataReference");
      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input: {
            _id: dataRef?.current?.getPageBySlug?._id,
            asset,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || `Error Adding Asset`,
          {
            variant: "error",
          }
        );

        return;
      }
      if (dataUpdate?.getPageBySlug?.asset) {
        setAssets(dataUpdate?.getPageBySlug?.asset);
      }

      enqueueSnackbar(`${Key} loaded successful`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`asset update failed ${e}`, {
        variant: "error",
      });
    }
  };

  //load data by fetching from url
  const loadHtmlAndStyleToCanvas = (html, css) => {
    // Load HTML content into GrapesJS
    if (html) editor.setComponents(html);
    // Load CSS content into GrapesJS
    if (css) editor.setStyle(css);
  };

  const autoSave = async () => {
    if (
      dataRef?.current?.getPageBySlug?._id &&
      pgContent?.current &&
      editorRef?.current &&
      editorRef?.current?.getHtml &&
      editorRef?.current?.getCss &&
      (pgContent?.current?.html !== editorRef?.current?.getHtml() ||
        pgContent?.current?.css !== editorRef?.current?.getCss())
    ) {
      await handleSave(true);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      autoSave();
      // console.log("Timer", pgContent?.current, editorRef?.current);
    }, 30 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (editor) {
      if (isPreviewActive) {
        // Start the preview
        setOpen(false);
        editor.runCommand("preview");
      } else {
        // Stop the preview
        editor.stopCommand("preview");
      }
    }
  }, [isPreviewActive]);

  useEffect(() => {
    if (firstMount.current) {
      grapesjsSetup(
        setEditor,
        productRef,

        updateAssets,
        setAssets,
        setOpen
      );
    }
    firstMount.current = false;

    return () => {
      editor?.select();
      setTimeout(() => {
        editor?.destroy();
      }, 0);
    };
  }, []);

  useEffect(() => {
    if (data && data?.getPageBySlug instanceof Object) {
      if (updatedOnce.current) {
        setAssets(data?.getPageBySlug?.asset);
        editor?.AssetManager &&
          editor.AssetManager.add(
            data?.getPageBySlug?.asset.map(({ fileName }) => ({
              src: assetsFileFormat({ name: fileName }),
              name: fileName,
            }))
          );

        setProductList(
          data?.getPageBySlug?.products instanceof Array &&
            data?.getPageBySlug?.products.map((prod) => ({
              ...prod,
              select: false,
            }))
        );

        productRef.current =
          data?.getPageBySlug?.products instanceof Array
            ? data?.getPageBySlug?.products.map((prod) => ({
                label: prod.name,
                id: prod.id,
              }))
            : [];

        updatedOnce.current = false;
        loadHtmlAndStyleToCanvas(
          data?.getPageBySlug?.html,
          data?.getPageBySlug?.css
        );
      }
      dataRef.current = data;
    }
  }, [data]);

  return (
    <MarketingLayout
      sxSidebar={{ bgcolor: "#101010" }}
      sxMain={{ overflow: "hidden" }}
      sideBarContent={
        <SideBarContentBox>
          <div className="title_nav">
            <CreateButton fullWidth onClick={() => setOpenProducts(true)}>
              View Products
            </CreateButton>
          </div>

          <div className="main_content">
            <Box sx={{ px: 1 }}>
              <AntTabs value={value} onChange={handleChange} aria-label="tab">
                <AntTab label="Elements" />
                <AntTab label="Layouts" />
              </AntTabs>
              <CustomTabPanel hidden={value !== 0}>
                <div id="blocks"></div>
              </CustomTabPanel>
              <CustomTabPanel hidden={value !== 1}>
                <div id="layers-container"></div>
              </CustomTabPanel>
            </Box>
            <Box sx={{ height: 150 }}></Box>
          </div>
        </SideBarContentBox>
      }
    >
      <Box sx={{ position: "relative", height: "100vh" }}>
        <BuilderNav id={"basic-actions"}>
          <div>
            <Link to={"/"}>
              <CreateButton>Back</CreateButton>
            </Link>
          </div>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{ display: "flex", bgcolor: "#101010" }}
              className="panel__devices"
            ></Box>
            <Box
              sx={{ display: "flex", bgcolor: "#101010" }}
              className="panel__basic-actions"
            ></Box>{" "}
          </Box>
          <div>
            <CreateButton
              sx={{ mr: 2 }}
              color="primary"
              onClick={previewToggle}
            >
              Preview
            </CreateButton>
            <CreateButton
              aria-controls={Boolean(showMenu) ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(showMenu) ? "true" : undefined}
              onClick={(event) => {
                setShowMenu(event.currentTarget);
              }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Update
            </CreateButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              anchorEl={showMenu}
              open={Boolean(showMenu)}
              onClose={() => {
                setShowMenu(null);
              }}
              TransitionComponent={Fade}
            >
              <MenuItem sx={{ fontFamily: "Dm Sans" }} onClick={handleSave}>
                <ListItemIcon>
                  <SaveAltRoundedIcon fontSize="small" />
                </ListItemIcon>
                <Typography sx={{ fontFamily: "Dm Sans" }} variant="inherit">
                  Save
                </Typography>
              </MenuItem>

              <MenuItem
                sx={{ fontFamily: "Dm Sans" }}
                onClick={handlePublishToggle}
              >
                <ListItemIcon>
                  {data?.getPageBySlug?.isPublished ? (
                    <PublicOffRoundedIcon fontSize="small" />
                  ) : (
                    <PublicRoundedIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <Typography sx={{ fontFamily: "Dm Sans" }} variant="inherit">
                  {data?.getPageBySlug?.isPublished ? "UnPublish" : "Publish"}
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </BuilderNav>

        <SliderStyle show={open} id="side-slide">
          <div className="close_btn_area">
            <Box
              sx={{
                p: 0.2,
                bgcolor: "#101010",
                position: "absolute",
                transform: "translateX(-100%)",
              }}
            >
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => {
                  setOpen(false);
                  editor.selectToggle(editor.getSelected());
                }}
                color="white"
                size="medium"
              >
                <CloseRoundedIcon
                  sx={{
                    fill: "white",
                  }}
                />
              </IconButton>
            </Box>
          </div>
          <div id="myslider" className="slider_content">
            <Box>
              <div id="trait-container"></div>
              <div id="styles-container"></div>
            </Box>
          </div>
        </SliderStyle>
        <Box sx={{ pt: "60px" }}>
          <Box id="editor"></Box>
        </Box>
      </Box>
      <ProductModal
        editor={editor}
        details={dataRef.current}
        open={openProducts}
        productList={productList}
        setProductList={setProductList}
        handleClose={() => setOpenProducts(false)}
        productRef={productRef}
      />
    </MarketingLayout>
  );
};

export default PageBuilder;
