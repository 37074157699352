import { gql } from "@apollo/client";

export const FETCH_PAYMENT_DETAILS = gql`
  query {
    getPaymentDetails {
      paymentId
      subscriptionType
      amount
      currency
      status
      updatedAt
    }
  }
`;
