import RecordRTC from "recordrtc";
import React, { useState, useRef, useEffect } from "react";
import { invokeSaveAsDialog } from "recordrtc";
import { CounterWrapper, LabelWrapper } from "./styled";
const RecordStart = ({
  stream,
  setVideoUrl,
  setPicUrl,
  stRecord,
  setStRecord,
  setCounterStart,
  isMobile,
  brandProps,
  setDuration,
  counterStart,
  permissions,
  startViewing,
  setRecordedPicUrls,
  recordedPicUrls,
  setCountdownTimer,
  questions,
  setQuestionDisplay,
  setRecordingQuestions,
  setCurrentQuestionIndex,
  setDisplay,
}) => {
  const videoRef = useRef(null);
  const recorderRef = useRef(null);

  const [recording, setRecording] = useState(false);

  const [recordingBlob, setRecordingBlob] = useState(null);
  const [paused, setPaused] = useState(false);
  const [remainingTime, setRemainingTime] = useState(300); // 5 minutes in seconds
  const [seconds, setSeconds] = useState(300);
  const [isActive, setIsActive] = useState(false);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const [timer, setTimer] = useState(formatTime(seconds));

  useEffect(() => {
    let interval;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((prevTime) => {
          const newTime = prevTime - 1;
          setTimer(formatTime(newTime));
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  useEffect(() => {
    if (counterStart > 0) {
      const timer = setTimeout(() => {
        setCounterStart(counterStart - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counterStart]); //

  useEffect(() => {
    setCountdownTimer(timer);
    const timesSpent = remainingTime - seconds;
    const [minutesStr, secondsStr] = formatTime(timesSpent).split(":");
    const parsedMinutes = parseInt(minutesStr, 10) || 0;
    const parsedSeconds = parseInt(secondsStr, 10) || 0;
    const durationInSeconds = parsedMinutes * 60 + parsedSeconds;

    setDuration(durationInSeconds);
  }, [formatTime, remainingTime, seconds, setDuration]);

  const padCounter = (val) => val.toString().padStart(2, "0");

  useEffect(() => {
    if (seconds === 0 && recording) {
      stopRecording();
    }
  }, [seconds]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.muted = true;
      videoRef.current.volume = 0;
    }
  }, [stream]);

  const startRecording = () => {
    if (!recording && permissions) {
      if (navigator.mediaDevices) {
        // Video source is available, start recording
        videoRef.current.muted = true;
        videoRef.current.volume = 0;
        videoRef.current.srcObject = stream;
        recorderRef.current = new RecordRTC(stream, {
          type: "video",
        });

        recorderRef.current.startRecording();
        recorderRef.current.stream = stream;

        setRecording(true);
        setSeconds(300);
        setIsActive(true);
        setDisplay(false);
        setRecordingQuestions(true);
        setCurrentQuestionIndex(0);
      }
    }
  };

  const pauseRecording = () => {
    if (recording && !paused) {
      recorderRef.current.pauseRecording();
      // console.log("abdul",recorderRef.current)
      // console.log('restartt')
      videoRef.current.pause();
      // console.log("abdul",videoRef.current)
      setPaused(true);
      setIsActive(false);
      setRecordingQuestions(false);
      setQuestionDisplay(true);
    }
  };

  const resumeRecording = () => {
    if (recording && paused) {
      recorderRef.current.resumeRecording();
      videoRef.current.play();
      setPaused(false);
      setRecordingQuestions(true);
      setQuestionDisplay(false);
      setIsActive(true);
    }
  };

  const stopRecording = () => {
    if (recording) {
      if (recorderRef.current) {
        recorderRef.current.stopRecording(stopRecordingCallback);
      }
    }
  };

  const stopRecordingCallback = () => {
    setIsActive(false);
    // setSeconds(15);
    // videoRef.current.muted = false;
    // videoRef.current.volume = 1;
    // videoRef.current.src = videoRef.current.srcObject = null;

    if (recorderRef.current) {
      const recordedBlob = recorderRef.current.getBlob();
      // videoRef.current.src = URL.createObjectURL(recordedBlob);
      setVideoUrl(URL.createObjectURL(recordedBlob));

      recorderRef.current.stream.stop();
      recorderRef.current = null;
    }

    setRecording(false);
    setRecordingQuestions(false);
    setCurrentQuestionIndex(0);
    setQuestionDisplay(false);
    setSeconds(300);
  };
  useEffect(() => {
    if (stRecord === true && counterStart === 0) {
      startRecording();
    }
  }, [stRecord, counterStart]);

  useEffect(() => {
    if (stRecord === false) {
      stopRecording();
    }
  }, [stRecord]);
  useEffect(() => {
    if (stRecord === "pause") {
      pauseRecording();
      // console.log("restart paused");
    }
  }, [stRecord]);
  useEffect(() => {
    if (stRecord === "resume") {
      resumeRecording();
      // console.log("restart resumed");
    }
  }, [stRecord]);
  // console.log("recorderRef", recorderRef.current, videoRef.current, stream);
  const handleGetVideoDuration = () => {
    if (recordingBlob) {
      const videoElement = videoRef.current;
      if (videoElement.readyState >= 2) {
        const duration = videoElement.duration;
        alert(`Video Duration: ${duration} seconds`);
      } else {
        videoElement.addEventListener("loadedmetadata", () => {
          const duration = videoElement.duration;
          alert(`Video Duration: ${duration} seconds`);
        });
      }
    }
  };

  // return (
  //   <div>
  //     <p style={{color:"black",fontSize:"2rem"}}>Remaining Time: {seconds} seconds</p>

  //     <button onClick={startRecording} disabled={recording}>
  //       Start Recording
  //     </button>
  //     <button onClick={pauseRecording} disabled={!recording || paused}>
  //       Pause Recording
  //     </button>
  //     <button onClick={stopRecording} disabled={!recording}>
  //       Stop Recording
  //     </button>
  //     <button onClick={handleGetVideoDuration} disabled={!recordingBlob}>
  //       Get Video Duration
  //     </button>
  //     <button onClick={resumeRecording} disabled={!recording || !paused}>
  //       Resume Recording
  //     </button>

  //     <hr />
  //     <video ref={videoRef} controls autoPlay playsInline width={'100%'}></video>
  //     {/* <video controls autoPlay playsInline width={'100%'}>
  //       {recordingBlob && <source src={recordingBlob} type="video/webm" />}
  //     </video> */}
  //   </div>
  // );

  return (
    <div
      style={{
        color: "white",
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        // width: isMobile ?? "100%",
      }}
    >
      {/* Counter for starting the recording */}
      {counterStart > 0 && (
        <div
          style={{
            display: "flex",
            // width: "100%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 2,
          }}
        >
          {!isMobile && <LabelWrapper>Starting in</LabelWrapper>}
          <CounterWrapper>{padCounter(counterStart)}</CounterWrapper>
        </div>
      )}

      <div
        style={{
          display: "block",
          width: "100%",
          height: "100%",
          // position: "relative",
        }}
      >
        {/* Render the webcam component when viewing has started */}

        <>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "cover", transform: "scaleX(-1)" }}
          ></video>
        </>
      </div>
    </div>
  );
};

export default RecordStart;
