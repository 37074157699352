import {
	Alert,
	Box,
	Button,
	Container,
	Grid,
	Paper,
	Typography,
	InputBase,
	styled,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { StyledFormLabel, StyledWidget } from './styled';
import ColorSwatches from '../../components/ColorSwatches';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_BRAND } from '../../graphql/queries/brandQueries';
import { UPDATE_BRAND_WIDGET } from '../../graphql/mutations/brandMutations';
import { useSnackbar } from 'notistack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Widget01 from '../../assets/widget/Widget01.svg';
import Widget02 from '../../assets/widget/Widget02.svg';
import Widget03 from '../../assets/widget/Widget03.svg';
import Widget04 from '../../assets/widget/Widget04.svg';
import Widget05 from '../../assets/widget/Widget05.svg';
import OptionOne from '../../assets/widget/OptionOne.svg';
import OptionTwo from '../../assets/widget/OptionTwo.svg';
import OptionThree from '../../assets/widget/OptionThree.svg';
import OptionFour from '../../assets/widget/OptionFour.svg';
import BorderTicknessControl from '../../components/BorderTicknessControl';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	'label + &': {
		marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}));

function Widget({ brandId,id }) {
	const { enqueueSnackbar } = useSnackbar();
	const [getBrand, { data, loading }] = useLazyQuery(GET_BRAND);
	const [updateBrand, { data: updatedBrand, loading: loadingUpdate, error }] =
		useMutation(UPDATE_BRAND_WIDGET);
	const [selectedWidgetIcon, setSelectedWidgetIcon] = useState(null);
	const [state, setState] = useState({
		height: 200,
		width: 200,
		horizontal: 'flex-start',
		vertical: 'flex-start',
		position: 'top-left',
		borderSize: 0,
		visibility: true,
		color: '#ffffff',
		backgroundColor: '#000000',
		borderColor: '#000000',
		widgetIcon: '5',
	});
	const {
		height,
		width,
		horizontal,
		vertical,
		borderSize,
		borderColor,
		color,
		backgroundColor,
		position,
		widgetIcon,
		visibility,
	} = state;

	const setSelectedColor = (color) => {
		setState({ ...state, color });
	};

	const setSelectedBackgroundColor = (backgroundColor) => {
		setState({ ...state, backgroundColor });
	};

	const setSelectedBorderColor = (borderColor) => {
		setState({ ...state, borderColor });
	};

	const setBorderWidth = (borderSize) => {
		setState({ ...state, borderSize });
	};

	const handleIconUpdate = (widgetIcon) => {
		setState({ ...state, widgetIcon });
	};

	const handleChangeVisibility = (event) => {
		setState({ ...state, visibility: event.target.value });
	};

	const handleUpdateBrand = () => {
		updateBrand({
			variables: {
				input: {
					brandId,
					width: `${width}`,
					backgroundColor,
					borderSize: `${borderSize}`,
					position,
					color,
					visibility: JSON.parse(visibility),
					borderColor,
					widgetIcon,
				},
			},
		});
	};

	useEffect(() => {
		if (id) {
			getBrand({
				variables: { input: { id } },
			});
		}
	}, [id, getBrand]);

	const handleChange = (event) => {
		switch (event.target.value) {
			case 'top-left':
				setState({
					...state,
					horizontal: 'flex-start',
					vertical: 'flex-start',
					position: event.target.value,
				});
				break;
			case 'top-center':
				setState({
					...state,
					horizontal: 'center',
					vertical: 'flex-start',
					position: event.target.value,
				});
				break;
			case 'top-right':
				setState({
					...state,
					horizontal: 'flex-end',
					vertical: 'flex-start',
					position: event.target.value,
				});
				break;
			case 'center-left':
				setState({
					...state,
					horizontal: 'flex-start',
					vertical: 'center',
					position: event.target.value,
				});
				break;
			case 'center-center':
				setState({
					...state,
					horizontal: 'center',
					vertical: 'center',
					position: event.target.value,
				});
				break;
			case 'center-right':
				setState({
					...state,
					horizontal: 'flex-end',
					vertical: 'center',
					position: event.target.value,
				});
				break;
			case 'bottom-left':
				setState({
					...state,
					horizontal: 'flex-start',
					vertical: 'flex-end',
					position: event.target.value,
				});
				break;
			case 'bottom-center':
				setState({
					...state,
					horizontal: 'center',
					vertical: 'flex-end',
					position: event.target.value,
				});
				break;
			case 'bottom-right':
				setState({
					...state,
					horizontal: 'flex-end',
					vertical: 'flex-end',
					position: event.target.value,
				});
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (data?.brand) {
			setState((prevState) => ({ ...prevState, ...data?.brand }));
		}
	}, [data?.brand]);

	useEffect(() => {
		if (updatedBrand) {
			enqueueSnackbar('Widget updated!', { variant: 'success' });
		}
	}, [updatedBrand, enqueueSnackbar]);

	useEffect(() => {
		switch (widgetIcon) {
			case '1':
				setSelectedWidgetIcon(OptionOne);
				break;
			case '2':
				setSelectedWidgetIcon(OptionFour);
				break;
			case '3':
				setSelectedWidgetIcon(OptionTwo);
				break;
			case '4':
				setSelectedWidgetIcon(OptionThree);
				break;
			case '5':
				setSelectedWidgetIcon(null);
				break;

			default:
				setSelectedWidgetIcon(null);
				break;
		}
	}, [widgetIcon]);

	return (
		<>
			{loading ? (
				<Typography>Loading....</Typography>
			) : (
				<Container maxWidth='xl' sx={{ mt: 2 }}>
					<Grid container spacing={2}>
						<Grid item md={12}>
							<Typography variant='h6'>Desktop Widget</Typography>
							{error && <Alert severity='error'>{error?.message}</Alert>}
						</Grid>
						<Grid item container md={12} sm={12} xs={12} spacing={2}>
							<Grid item container md={6} sm={6} xs={12}>
								<Paper sx={{ padding: 2 }}>
									<Grid container spacing={2}>
										<Grid item md={12}>
											<FormControl>
												<FormLabel id='demo-row-radio-buttons-group-label'>
													<StyledFormLabel>
														Visibility on platform
													</StyledFormLabel>
												</FormLabel>
												<RadioGroup
													row
													aria-labelledby='demo-row-radio-buttons-group-label'
													name='row-radio-buttons-group'
													value={state?.visibility}
													onChange={handleChangeVisibility}>
													<FormControlLabel
														value={true}
														control={<Radio />}
														label='Set Visible'
													/>
													<FormControlLabel
														value={false}
														control={<Radio />}
														label='Not Visible'
													/>
												</RadioGroup>
											</FormControl>
										</Grid>
										<Grid item md={12} sm={12} xs={12}>
											<StyledFormLabel>Widget Button</StyledFormLabel>
											<Stack direction='row' spacing={1}>
												<IconButton
													aria-label='delete'
													onClick={() => handleIconUpdate('1')}>
													<img src={Widget01} alt='widget' width='50' />
												</IconButton>
												<IconButton
													aria-label='delete'
													onClick={() => handleIconUpdate('2')}>
													<img src={Widget02} alt='widget' width='50' />
												</IconButton>
												<IconButton
													aria-label='delete'
													onClick={() => handleIconUpdate('3')}>
													<img src={Widget05} alt='widget' width='50' />
												</IconButton>
												<IconButton
													aria-label='delete'
													onClick={() => handleIconUpdate('4')}>
													<img src={Widget03} alt='widget' width='50' />
												</IconButton>
												<IconButton
													aria-label='delete'
													onClick={() => handleIconUpdate('5')}>
													<img src={Widget04} alt='widget' width='50' />
												</IconButton>
											</Stack>
										</Grid>
										<Grid item md={6}>
											<StyledFormLabel>Widget Position</StyledFormLabel>
											<Box sx={{ minWidth: 120 }}>
												<FormControl fullWidth size='small' variant='standard'>
													<InputLabel id='demo-simple-select-label'></InputLabel>
													<Select
														labelId='demo-simple-select-label'
														id='demo-simple-select'
														value={position}
														label='Age'
														onChange={handleChange}
														input={<BootstrapInput />}>
														<MenuItem value='top-left'>Top Left</MenuItem>
														{/* <MenuItem value='top-center'>Top Center</MenuItem> */}
														<MenuItem value='top-right'>Top Right</MenuItem>
														{/* <MenuItem value='center-left'>Center Left</MenuItem>
														<MenuItem value='center-center'>
															Center Center
														</MenuItem>
														<MenuItem value='center-right'>
															Center Right
														</MenuItem> */}
														<MenuItem value='bottom-left'>Bottom Left</MenuItem>
														{/* <MenuItem value='bottom-center'>
															Bottom Center
														</MenuItem> */}
														<MenuItem value='bottom-right'>
															Bottom Right
														</MenuItem>
													</Select>
												</FormControl>
											</Box>
										</Grid>
										<Grid item md={6}>
											<StyledFormLabel>Widget Border Thickness</StyledFormLabel>
											<BorderTicknessControl
												border={borderSize}
												setBorderWidth={setBorderWidth}
											/>
										</Grid>
										<Grid item md={4}>
											<StyledFormLabel>Widget Border</StyledFormLabel>
											<ColorSwatches
												color={borderColor}
												setColor={setSelectedBorderColor}
											/>
										</Grid>
										<Grid item md={4}>
											<StyledFormLabel>Widget Background</StyledFormLabel>
											<ColorSwatches
												color={backgroundColor}
												setColor={setSelectedBackgroundColor}
											/>
										</Grid>
										<Grid item md={4}>
											<StyledFormLabel>Text Color</StyledFormLabel>
											<ColorSwatches
												color={color}
												setColor={setSelectedColor}
											/>
										</Grid>
										<Grid item md={12}>
											<Button
												variant='contained'
												onClick={() => handleUpdateBrand()}
												sx={{ mt: 3 }}>
												{loadingUpdate ? 'Loading...' : 'Update'}
											</Button>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
							<Grid item container md={6} sm={6} xs={12}>
								<Paper
									sx={{
										padding: 2,
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
									}}>
									<Typography variant='h6'>
										Desktop Widget Appearance
									</Typography>

									<Box
										sx={{
											minHeight: '100vh',
											display: 'flex',
											justifyContent: horizontal,
											alignItems: vertical,
										}}>
										{JSON.parse(visibility) === true && (
											<StyledWidget
												height={height}
												width={width}
												border={borderSize}
												bordercolor={borderColor}
												backgroundcolor={backgroundColor}>
												{selectedWidgetIcon && (
													<img
														src={selectedWidgetIcon}
														alt='option'
														width='20'
													/>
												)}
											</StyledWidget>
										)}
									</Box>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	);
}

export default Widget;
