import { useLazyQuery, useQuery } from "@apollo/client";
import {
  FETCH_GROUP_EMAIL_TAG,
  FETCH_MAIL_RECORDS,
  FETCH_ALL_MAIL_RECORDS_COUNT,
} from "../../graphql/queries/newsletter";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Select,
  TextField,
  MenuItem,
  Box,
  Divider,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import Table from "../../components/Table";
import { BootstrapTooltip } from "../../components/DashboardTable";
import BasicDatePicker from "../../components/DatePicker";
import dayjs from "dayjs";
import "antd/dist/antd.js";
import { DatePicker } from "antd";
import styled from "styled-components";
import { getDateRange, newsletterAnalyticsItems } from "../../utils/pageStore";
import CustomTooltip from "../../components/CustomTootip";
import Info from "../../assets/icons/Info.svg";
import {
  AnalyticsBoxHeader,
  BannerButton,
  BannerButtonText,
  BannerCalender,
  FlexBannerButtonFilterTwo,
} from "../UserDashboard/styled";
import Calender from "../../assets/icons/Calender.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRange from "../../components/DateRange";
const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker-range {
    visibility: hidden;
  }

  & .ant-picker-dropdown {
    top: 135px;
    left: 1339.27px;
  }
`;

const tableHeadCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    width: "20%",
  },
  {
    id: "unsubscribe",
    numeric: false,
    disablePadding: true,
    label: "Unsubscribe",
    width: "10%",
  },
  {
    id: "bounce",
    numeric: false,
    disablePadding: true,
    label: "Bounce",
    width: "10%",
  },
  {
    id: "open",
    numeric: false,
    disablePadding: true,
    label: "Open",
    width: "10%",
  },
  {
    id: "click",
    numeric: false,
    disablePadding: true,
    label: "Click",
    width: "10%",
  },
  {
    id: "delivered",
    numeric: false,
    disablePadding: true,
    label: "Delivered",
    width: "10%",
  },
  {
    id: "spamreport",
    numeric: false,
    disablePadding: true,
    label: "Spam",
    width: "10%",
  },
  {
    id: "deferred",
    numeric: false,
    disablePadding: true,
    label: "Deferred",
    width: "10%",
  },
  {
    id: "dropped",
    numeric: false,
    disablePadding: true,
    label: "Dropped",
    width: "10%",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Sent time",
    width: "10%",
  },
  {
    id: "timezone",
    numeric: false,
    disablePadding: true,
    label: "Timezone",
    width: "10%",
  },
];

let items = [
  "bounce",
  "open",
  "click",
  "delivered",
  "unsubscribe",
  "spamreport",
  "dropped",
  "deferred",
];
export default ({ isMobile }) => {
  const date = useSelector((state) => state.date);
  const [dailyData, setDailyData] = useState();
  const [dateText, setDateText] = useState("Yesterday");
  const [display, setDisplay] = useState(false);
  const { data, loading } = useQuery(FETCH_GROUP_EMAIL_TAG);
  // const { data: dataAll, loading: loadingAll } = useQuery(
  //   FETCH_ALL_MAIL_RECORDS_COUNT
  // );
  const [findRecords, { data: dataResult, loading: loadingResult }] =
    useLazyQuery(FETCH_MAIL_RECORDS);

  // const [mailTag, setMailTag] = useState("__all__");
  const [mailTag, setMailTag] = useState("");

  const tableHeadCellsTop = [
    ...tableHeadCells,
    // {
    //   id: "systemBounce",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "System Bounce",
    //   width: "10%",
    // },
  ];

  tableHeadCellsTop.pop();
  tableHeadCellsTop.pop();

  const infoAnalytics = {
    total: {
      title: "Total",
      description: "Total number of emails.",
    },
    ...newsletterAnalyticsItems,
    dropped: {
      title: "Dropped",
      description: "The email dropped by Sendgrid.",
    },
    deferred: {
      title: "Deferred",
      description:
        "The email deferred due to some reasons described in SendGrid.",
    },
  };

  const getRows = () => {
    let rows = dataResult?.fetchMailRecords ?? [];
    rows = rows.filter((row) => {
      for (let elm of items) {
        if (row[elm]) return true;
      }
      return false;
    });
    rows = rows.map((row) => {
      let obj = { ...row };

      for (let item of items) {
        let label = tableHeadCells.find((el) => el.id === item)?.label;
        obj[item] = obj[item] ? (
          <BootstrapTooltip title={label}>
            <CheckIcon />
          </BootstrapTooltip>
        ) : null;
      }
      obj.createdAt = new Date(row.createdAt).toLocaleString();
      obj.timezone = row.timezone;

      return obj;
    });
    return rows;
  };
  // const fetchAllComp = () => {
  //   if (loadingAll) return <>Loading...</>;
  //   let obj = dataAll?.fetchAllMailRecordsCount ?? {};
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "space-between",
  //         padding: "10px",
  //         // border: "1px solid",
  //         borderColor: "divider",
  //         // paddingLeft: "30px",
  //         // paddingRight: "30px",
  //         // borderRadius: 2,
  //         bgcolor: "background.paper",
  //         color: "text.secondary",
  //         "& svg": {
  //           m: 1,
  //         },
  //       }}
  //     >
  //       {tableHeadCellsTop.map((el, idx, arr) => {
  //         if (idx === 0) return null;
  //         return (
  //           <Card
  //             variant="outlined"
  //             key={`${el}${idx}Card`}
  //             sx={{ width: "15%" }}
  //           >
  //             <CardContent>
  //               <Typography
  //                 sx={{ fontSize: 20, fontWeight: "bold" }}
  //                 color="text.secondary"
  //                 gutterBottom
  //               >
  //                 {el.label}
  //               </Typography>
  //               <Typography variant="h5" component="div">
  //                 {obj[el.id]}
  //               </Typography>
  //             </CardContent>
  //           </Card>
  //         );
  //       })}
  //     </Box>
  //   );
  // };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const getRate = (rows, item) => {
    try {
      let num = rows.filter((row) => row[item]).length;
      let den = rows.filter((row) => row["delivered"]).length;
      console.log(num, den, "ratee");
      let res = (num / den) * 100;
      return `(${Math.round(isNaN(res) ? 0 : res, 2)}%)`;
    } catch (e) {
      console.error(e);

      return `(0%)`;
    }
  };

  const testBounce = () => {
    let rows = dataResult?.fetchMailRecords ?? [];
    return rows.filter((row) => row["systemBounce"]).length;
  };

  const displayTestBounce = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          border: "1px solid",
          borderColor: "divider",
          paddingLeft: "30px",
          paddingRight: "30px",
          borderRadius: 2,
          bgcolor: "background.paper",
          color: "text.secondary",
          minWidth: "200px",
          "& svg": {
            m: 1,
          },
        }}
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
          }}
        >
          Test Bounce
        </Box>
        <Box>{testBounce()}</Box>
      </Box>
    );
  };

  const displayTopRow = () => {
    let rows = dataResult?.fetchMailRecords ?? [];
    rows = rows.filter((row) => {
      for (let elm of items) {
        if (row[elm]) return true;
      }
      return false;
    });

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          border: "1px solid",
          borderColor: "divider",
          paddingLeft: "30px",
          paddingRight: "30px",
          borderRadius: 2,
          bgcolor: "background.paper",
          color: "text.secondary",
          "& svg": {
            m: 1,
          },
        }}
      >
        {tableHeadCellsTop.map((el, idx, arr) => {
          return (
            <>
              <Box key={`${el}${idx}`}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      display: "flex",

                      justifyContent: "space-around",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AnalyticsBoxHeader isMobile={isMobile}>
                        {idx === 0 ? "Total" : el.label}
                      </AnalyticsBoxHeader>

                      <CustomTooltip
                        headerText={
                          idx === 0
                            ? infoAnalytics["total"]?.title
                            : infoAnalytics[el.id]?.title
                        }
                        bodyText={
                          idx === 0
                            ? infoAnalytics["total"]?.description
                            : infoAnalytics[el.id]?.description
                        }
                        noLeft
                      >
                        <img
                          src={Info}
                          alt="info"
                          style={{ cursor: "pointer", marginLeft: 5 }}
                        />
                      </CustomTooltip>
                    </Box>
                  </Box>
                  <Box sx={{ fontWeight: "300", fontSize: "14px" }}>
                    {idx === 0
                      ? rows.length
                      : rows.filter((row) => row[el.id]).length}
                    {["open", "click"].includes(el.id) && getRate(rows, el.id)}
                  </Box>
                </Box>
              </Box>
              {idx < arr.length - 1 && (
                <Divider orientation="vertical" variant="middle" flexItem />
              )}
            </>
          );
        })}
      </Box>
    );
  };

  const mailTagFiltered = () => {
    // let dt = new Date(selectedDate).toISOString().split("T")[0];
    const start = new Date(date?.startDate);
    const end = new Date(date?.endDate);
    const range = getDateRange(start, end);
    return (data?.fetchGroupEmailTags ?? []).filter((tag) =>
      range.some((dt) => String(tag?.createdAt).includes(dt))
    );
  };

  const setFnMailTagFiltered = () => {
    let arr = mailTagFiltered();
    if (arr[0]) {
      setMailTag(arr[0].emailTag);
    } else {
      setMailTag("");
    }
    //}
  };

  useEffect(() => {
    findRecords({ variables: { category: mailTag } });
  }, [mailTag]);

  useEffect(() => {
    setFnMailTagFiltered();
  }, [date, data?.fetchGroupEmailTags]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "50px" }}>
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <DateRange position={"left"} style={{ width: "fit-content" }} />

          {mailTagFiltered().length ? (
            <FormControl>
              <Select
                value={mailTag}
                // defaultValue={mailTag}
                // label="Mail Tags"
                onChange={(e) => setMailTag(e.target.value)}
                sx={{
                  fontFamily: "Switzer",
                  borderRadius: "12px",
                  mr: 2,
                  ".MuiSelect-select": {
                    py: "9px !important",
                  },
                  maxWidth: "300px",
                  paddingTop: "8px",
                }}
              >
                {/* <MenuItem value="__all__">All</MenuItem> */}
                {mailTagFiltered().map((el) => (
                  <MenuItem key={el.emailTag} value={el.emailTag}>
                    {el.emailTag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </Box>
        {displayTestBounce()}
      </Box>

      {
        // mailTag === "__all__" ? (
        //   <>{fetchAllComp()}</>
        // ) :
        <>
          {loadingResult ? (
            "loading..."
          ) : mailTagFiltered().length ? (
            <>
              {displayTopRow()}
              <Table
                headCells={tableHeadCells}
                // rowClick={rowClick}
                rows={getRows()}
                stylehead
              />
            </>
          ) : null}
        </>
      }
    </Box>
  );
};
