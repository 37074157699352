import React, { useEffect, useRef, useState } from "react";
import {
  Wrapper,
  MajorWrapper,
  LeftTopWrapper,
  LeftTopTitle,
  LeftTopSubTitle,
  MinimizeWrapper,
  RightTopWrapper,
  TopWrapper,
  CloseWrapper,
  InputWrapper,
  FormWrapper,
  LabelWrapper,
  LabelWrapperExt,
  SwitchWrapper,
  VideoPartWrapper,
  VideoWrapper,
  ContentWrapper,
  ButtonWrapper,
  BrandRating,
  ImageRate,
  BottomWrapper,
  LRButtonWrapper,
  LabelDiv,
  ImagePlay,
  StopRecordButton,
  StopRecordButtonWrapper,
  TestimonialUSer,
  TestimonialUSerText,
  TestimonialUSerImage,
  VectorFour,
  VectorFive,
  VectorTwo,
  VectorOne,
  VectorThree,
  ErrorWrapper,
  StepWrapper,
  StepTitle,
  Step2SubDiv,
  StepWrapperSpan,
  RequestText,
  RequestDisclaimer,
  DisclaimerText,
  QuestionTextarea,
  ViaSmsText,
  ViaEmail,
  ViaSms,
  LabelWrapperTwo,
} from "./styled";
import MinimizeIcon from "../../../assets/images/minimize.svg";
import PlayButton from "../../../assets/images/playbutton.svg";
import Play from "../../../assets/images/play.svg";
import PauseIcon from "../../../assets/images/PauseImage.svg";
import Logo from "../../../assets/images/Logo.svg";
import LeftArrow from "../../../assets/images/prev.svg";
import RightArrow from "../../../assets/images/next.svg";
import PlayIcon from "../../../assets/images/play.svg";
import Camera from "../../../assets/icons/Camera.svg";
import Upload from "../../../assets/icons/Upload.svg";
import Message from "../../../assets/icons/Message.svg";
import Comment from "../../../assets/icons/Comment.svg";
import Plus from "../../../assets/icons/Plus.svg";
import Disclaimer from "../../../assets/icons/Disclaimer.svg";
import Trash from "../../../assets/icons/Trash.svg";
import RecordUpload from "../../../assets/images/teenage-female.jpg";
import TestimonialVectorFour from "../../../assets/icons/TestimonialVectorFour.svg";
import TestimonialVectorSix from "../../../assets/icons/TestimonialVectorSix.svg";
import TestimonialVectorTwo from "../../../assets/icons/TestimonialVectorTwo.svg";
import TestimonialVectorOne from "../../../assets/icons/TestimonialVectorOne.svg";
import TestimonialVectorThree from "../../../assets/icons/TestimonialVectorThree.svg";
import TestimonialVectorFive from "../../../assets/icons/TestimonialVectorFive.svg";
import AvatarImage from "../../../assets/images/Avatar.svg";
import StepActive from "../../../assets/images/FullStepIcon.svg";
import StepInActive from "../../../assets/images/EmptyStepIcon.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import { customAlphabet } from "nanoid";
// import Switch from "react-switch";
import Switch from "@mui/material/Switch";

import RecordStart from "../RecordComp";
import {
  fetchResourceFromURI,
  formatTime,
  onChangeTextHandler,
} from "../../../utils/format";
import { useMutation, useQuery } from "@apollo/client";
import {
  SAVE_MULTIPLE_VIDEO,
  SAVE_VIDEO,
  UPDATE_VIDEO,
} from "../../../graphql/mutations/videoMutation";
import AWS from "aws-sdk";
import Rating from "../Rating";
import SuccessComp from "../SuccessComp";
import LoadingComp from "../Loading";
import { useReactMediaRecorder } from "react-media-recorder";
import PlayRecord from "../PlayRecord";
import ReviewRecord from "./reviewRecord";
import { Avatar, Box, Checkbox, Container, Typography } from "@mui/material";
import ButtonCust from "../../ButtonCust";
import ColorButton from "../../ColorButton";
import { useSelector } from "react-redux";
import {
  PreviousButton,
  PreviousButtonText,
} from "../../../pages/OrganizationBrands/styled";
import Control from "../../Control";
import ReactPlayer from "react-player";
import { useSnackbar } from "notistack";
import Modal from "react-modal";
import ViaPreview from "../../ViaPreview";
import RequestSuccessPage from "../../RequestSuccessPage";
import { styled } from "@mui/material/styles";
import VideoSnapshot from "video-snapshot";
import SendStepOne from "../../SendTestimonial/SendStepOne";
import RemoveIcon from "../../../assets/icons/RemoveIcon.svg";
import { ProgressBar } from "../../SendTestimonial/styled";
import SendStep2 from "../../SendTestimonial/SendStep2";
import SendStep3 from "../../SendTestimonial/SendStep3";
import SendStep2345 from "../../SendTestimonial/SendStep2345";
import detectEyes from "../../../utils/detectEyes";
const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "white",
    width: "65%",
    padding: "15px",
    border: "none",
    overflow: "none",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    inset: "unset",
    borderRadius: "15px",
  },
};
const customStylesTwo = {
  overlay: {
    background: "rgba(0, 0, 0,0.4)",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "none",
    width: "50%",
    height: "100vh",
    padding: "0px",
    border: "none",
    overflow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
};
const nanoid = customAlphabet("abcdefeghijklmnopqrstuvwxyz123456789", 10);
export const VideoSlider = ({ videoData, currentIndex, onNext, onPrev }) => {
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const playerRef = useRef(null);
  const handlePlay = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleSeekMouseDown = (e) => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "-10px",
          zIndex: "20",
          width: "101%",
        }}
      >
        <ProgressBar
          type="range"
          min={0}
          max={1}
          step="any"
          value={played}
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
        ></ProgressBar>
      </div>
      <ReactPlayer
        className="custom-player-play-modal"
        width={"100%"}
        height={"100%"}
        playing={playing}
        onProgress={handleProgress}
        url={videoData[currentIndex].url}
        ref={playerRef}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <button
          style={{ background: "none", border: "none" }}
          onClick={onPrev}
          disabled={currentIndex === 0}
        >
          <img
            src={LeftArrow}
            alt="Left Arrow"
            style={{ opacity: currentIndex === 0 ? "0.5" : "1" }}
          />
        </button>
        <button
          style={{ background: "none", border: "none" }}
          onClick={handlePlay}
        >
          <img
            src={playing ? PauseIcon : Play}
            style={{ width: "58px", height: "58px" }}
          />
        </button>
        <button
          style={{ background: "none", border: "none" }}
          onClick={onNext}
          disabled={currentIndex === videoData.length - 1}
        >
          <img
            src={RightArrow}
            alt="Right Arrow"
            style={{ opacity: currentIndex === videoData.length - 1 ? 0.5 : 1 }}
          />
        </button>
      </div>
    </div>
  );
};
const ReviewComp = ({ setClicked, publicKey, videoInfo, userData }) => {
  // console.log(videoInfo, "setting");
  const videoDetails = videoInfo;
  const [vidRate, setVidRate] = useState();
  const [checked, setChecked] = useState(true);
  const [step, setStep] = useState(1);
  const [newStep, setNewStep] = useState(1);

  // const [stRecord, setStRecord] = useState();
  // const [videoUrl, setVideoUrl] = useState();
  // const [picUrl, setPicUrl] = useState();
  const [success, setSuccess] = useState(false);

  const [formstate, setFormstate] = useState({
    name: videoInfo?.name || null,

    organization: videoInfo?.organization || null, // Set the default value to videoInfo.organization or an empty string
    position: videoInfo?.position || null, // Set the default value to position or an empty string
  });
  const [errorstate, setErrorstate] = useState({});
  const [showForm, setSetShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [trail, setTrail] = useState({});
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedMessage, setIsCheckedMessage] = useState(false);
  const [isCheckedSms, setIsCheckedSms] = useState(false);
  const [isCheckedEmail, setIsCheckedEmail] = useState(false);
  const [viaText, setViaText] = useState("");
  const [getPermission, setGetPermission] = useState(false);
  const [getPermissions, setGetPermissions] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [questions, setQuestions] = useState([]);
  const [questionsArray, setQuestionsArray] = useState([]);
  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [currentQuestionText, setCurrentQuestionText] = useState("");
  const [requestMessageText, setRequestMessageText] = useState(
    "Hi Grey,\nWe had a blast working together, and we're thrilled to hear about your experience! We'd love to hear your thoughts and feedback."
  );
  const [recordedVideoUrlSections, setRecordedVideoUrlSections] = useState([]);
  const [recordedPicUrlSections, setRecordedPicUrlSections] = useState([]);
  const [recordedDurationSections, setRecordedDurationSections] = useState([]);
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [newPicUrl, setNewPicUrl] = useState("");
  const [newDurationUrl, setNewDurationUrl] = useState(0);
  const [openCam, setOpenCam] = useState(false);
  const [runPlayHandle, setRunPlayHandle] = useState(false);
  const [requestReview, setRequestReview] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [openSkipModal, setSkipModal] = useState(false);
  const [openSubmitModal, setSubmitModal] = useState(false);
  const [videoData, setVideoData] = useState([]); // Initialize with an empty array
  const [videoDataUrl, setVideoDataUrl] = useState([]); // Initialize with an empty array
  const [videoDataDuration, setVideoDataDuration] = useState([]); // Initialize with an empty array
  const [videoIdentity, setVideoIdentity] = useState(""); // Initialize with an empty array
  const [currentIndex, setCurrentIndex] = useState(0);
  // Callback function to add video data to the array
  const addVideoData = (url, duration) => {
    // console.log("videoData", duration);
    const randomString = Date.now().toString();

    // Assuming duration is a string, use parseFloat only if needed
    const newDataDuration = [...videoDataDuration, duration];

    const newData = [...videoData, { url, randomString, duration }];
    const newDataUrl = [...videoDataUrl, { url }];

    setVideoData(newData);
    setVideoDataUrl(newDataUrl);
    setVideoDataDuration(newDataDuration);
    // setRecordedVideoUrlSections(newData)

    if (duration === Infinity) {
      deleteVideoUrl(randomString);
      enqueueSnackbar("Video file metadata is  corrupt", {
        variant: "error",
      });
    }
  };

  // console.log(videoData, "1....", videoDataUrl, "2...", videoDataDuration);

  const { enqueueSnackbar } = useSnackbar();
  // console.log(videoData, "videoData");
  // const [counterStart, setCounterStart] = useState();
  // const [duration, setDuration] = useState();  //......
  const isMobile = useSelector((state) => state.responsive.isMobile);
  // console.log("openCam", openCam, getPermission);
  function onRequestAudioVideoPermissionsModal() {
    // if (getPermission) {
    //   setOpenModal(false);

    // }
    if (getPermissions) {
      setOpenCam(true);
    } else {
      setOpenModal(true);
    }
  }
  // console.log(newStep, "newStep");
  const videoOff = async () => {
    setIsVideoOff(true);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const accessKeyId = process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY;
  const s3 = new AWS.S3({
    accessKeyId,
    secretAccessKey,
  });

  const [saveVideo] = useMutation(SAVE_VIDEO, {
    onCompleted(data) {
      // console.log(data, "success");
    },
  });

  const [saveMulVideo] = useMutation(SAVE_MULTIPLE_VIDEO, {
    onCompleted(data) {
      // console.log(data, "success");
    },
  });

  const handleChange = (val, name) => {
    let cond =
      val &&
      val.trim() !== "" &&
      name === "phoneNumber" &&
      !/^-?\d+$/.test(val);
    // console.log(val, "Valuee", /^-?\d+$/.test(val), cond);
    if (!cond) {
      onChangeTextHandler(val, name, formstate, setFormstate);
      validateForm(name, val);
    } else {
      onChangeTextHandler(formstate[name] ?? "", name, formstate, setFormstate);
    }
  };

  let arrForm = [
    {
      title: "Name",
      name: "name",
      required: true,
      type: "text",
      error: "Name field is blank",
      placeholder: "Grey Shulman",
    },
    {
      title: "Organization",
      name: "organization",
      required: true,
      type: "text",
      error: "Organization field is blank",
      placeholder: "Microsoft",
      display: videoInfo?.organization,
    },
    {
      title: "Position",
      name: "position",
      required: true,
      type: "text",
      error: "Position field is blank",
      placeholder: "CEO",
      display: videoInfo?.position,
    },
  ];

  const displayForm = () => {
    return arrForm.map((el, idx) => {
      return (
        <div key={idx}>
          {/* {console.log(el.display, "org")} */}
          {el.display !== null && (
            <>
              <LabelWrapper>{el?.title}</LabelWrapper>
              {(el?.type !== "logical" ||
                (el?.type === "logical" && checked)) && (
                <InputWrapper
                  required={el?.required ?? false}
                  type={el?.type ?? "text"}
                  placeholder={el?.placeholder ?? ""}
                  value={formstate[el?.name]}
                  borderColor={errorstate[el?.name] && "#D93047"}
                  onChange={(e) => handleChange(e.target.value, el?.name)}
                />
              )}
              {errorstate[el?.name] && (
                <ErrorWrapper>
                  {errorstate[el?.name] && "Required"}
                </ErrorWrapper>
              )}
            </>
          )}
        </div>
      );
    });
  };

  const validateForm = (field = "", val = "") => {
    let truthValue = true;
    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let phoneReg =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    let objError = { ...errorstate };

    if (field === "") {
      objError = {};
    } else {
      if (objError[field]) {
        delete objError[field];
        // console.log(objError, "ErrrorObj", field);
      }
    }
    setErrorstate(objError);

    let uArrForm = [...arrForm];
    let uformstate = field !== "" ? { [field]: val } : { ...formstate };
    if (field !== "") {
      uArrForm = arrForm.filter((el) => el.name === field);
    }
    for (let el of uArrForm) {
      if (
        el?.required &&
        (!uformstate[el?.name] || uformstate[el?.name].toString().trim() === "")
      ) {
        //add error.
        // alert(el?.error);
        objError[el?.name] = el?.error;
        truthValue = false;
      }
      if (
        el?.neccessary &&
        el?.type === "email" &&
        uformstate[el?.name] &&
        !emailReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorEmail;
        truthValue = false;
      }

      if (
        el?.neccessary &&
        el?.type === "tel" &&
        uformstate[el?.name] &&
        !phoneReg.test(uformstate[el?.name].toString().trim())
      ) {
        //add error.
        // alert(el?.errorEmail);
        objError[el?.name] = el?.errorPhone;
        truthValue = false;
      }
    }

    if (!truthValue) setErrorstate(objError);
    return truthValue;
  };

  const VideoNext = () => {
    setNewStep(newStep + 1);
    setStep(step + 1);
    // setTrail(recordingData);
    // setIsVideoOff(false); // Uncomment this line if you want to toggle isVideoOff
  };
  // console.log(videoDataUrl, "videoDataUrl", newVideoUrl.type);
  const uploadVideo = async (requestFromContacts = false) => {
    // console.log("rider");
    // if (!validateForm()) return false;

    setLoading(true);

    try {
      const addParamsArray = [];
      const fileTypeArray = [];
      const eyeAr = [];
      for (let i = 0; i < videoDataUrl.length; i++) {
        const { url } = videoDataUrl[i];
        // console.log(url, "videoDataUrl");
        let addParams = Date.now();
        // console.log("addParams: ", addParams);
        const vid = await fetchResourceFromURI(url);
        // console.log("vid: ", vid);
        const snapshoter = new VideoSnapshot(vid);
        const previewSrc = await snapshoter.takeSnapshot();

        const picUrlUpload = async (blob) => {
          // console.log(blob, "blobs", blob?.type);
          const pixKey = `Image${addParams}.${blob.type.split("/")[1]}`;
          const pixResp = await s3
            .upload({
              Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
              Key: pixKey,
              Body: blob,
            })
            .promise();
          // console.log("Image uploaded successfully", pixResp);
        };

        // Determine the file type
        const fileType = "image/jpeg";

        // Convert the image to JPEG format if it's    not already
        // if (fileType !== "image/jpeg") {
        //   const jpegBlob = await convertToJPEG(previewSrc);
        //   console.log("jpegBlob", jpegBlob);
        //   await picUrlUpload(jpegBlob);
        // } else {
        //   const response = await fetch(previewSrc);
        //   const blob = await response.blob();
        //   await picUrlUpload(blob);
        // }
        const jpegBlob = await convertToJPEG(previewSrc);
        // console.log("jpegBlob", jpegBlob);
        await picUrlUpload(jpegBlob);

        /////Find Eye Detection...
        let eyeDistance = await detectEyes(previewSrc);
        eyeAr.push(eyeDistance);
        // console.log(eyeDistance, "eyeDistance");
        //////////////////

        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(vid);

        // Check if video is loaded correctly
        videoElement.addEventListener("loadeddata", () => {
          // console.log("Video loaded successfully");
        });

        videoElement.addEventListener("error", (error) => {
          // console.error("Error loading video:", error);
        });

        // Upload the video to S3
        const vidKey = `OriginalVideo${addParams}.${vid.type.split("/")[1]}`;
        const vidResp = await s3
          .upload({
            Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
            Key: vidKey,
            Body: vid,
          })
          .promise();
        // console.log("Video uploaded successfully", vidResp);

        // Create an object with addParams and duration and push it to addParamsArray
        addParamsArray.push(
          addParams.toString()
          // Convert to milliseconds and then parse as an integer
        );
        fileTypeArray.push(
          vid.type.split("/")[1]
          // Convert to milliseconds and then parse as an integer
        );
      }

      // Now, the addParamsArray contains objects with addParams and video duration.
      // console.log("Video Durations and addParams:", addParamsArray);

      // Save in Server...
      const objFormstate = {
        ...formstate,
        requestFromContacts,
        createNewUser: true,
      };
      if (objFormstate.phoneNumber && objFormstate.phoneNumber.trim() === "") {
        objFormstate.phoneNumber = undefined;
      }
      if (objFormstate.email && objFormstate.email.trim() === "") {
        objFormstate.email = undefined;
      }

      // const resp3 = await saveVideo({
      const resp3 = await saveMulVideo({
        variables: {
          input: {
            url: addParamsArray,
            duration: videoDataDuration,
            ...objFormstate,
            videoIdentityNumber: videoInfo?.videoIdentityNumber,
            originalVideoExtension: fileTypeArray,
            eyeDistance: eyeAr,
            email: videoInfo?.email,
            phoneNumber: videoInfo?.phoneNumber,
            brandId: publicKey,
            senderId: videoInfo?.createdBy?._id,
          },
        },
      });
      // console.log("resp3", resp3);
      setVideoIdentity(resp3?.data?.createMultipleVideo?.videoIdentityNumber);
      // Reset states and perform other actions
      resetState();
    } catch (err) {
      // console.log("Error:", err);
      enqueueSnackbar(err, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const convertToJPEG = async (previewSrc) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const img = new Image();
      img.src = previewSrc;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (jpegBlob) => {
            resolve(jpegBlob);
          },
          "image/jpeg",
          1
        );
      };
    });
  };

  const resetState = () => {
    setIsVideoOff(true);
    setRecordingData({});
    setSuccess(true);
    setFormstate({});
    setTrail({});
    // setTimeout(() => setSuccess(false), 9000);
    setIsVideoOff(false);
    setVidRate(0);
    setNewStep(1);
    setStep(1);
    setIsChecked(false);
    setIsCheckedSms(false);
    setIsCheckedEmail(false);
    // alert("Video Saved");
  };

  const runPlayHandler = () => {
    setRunPlayHandle(true);
  };

  const addToFormState = () => {
    let obj = {};
    for (let el of arrForm) {
      if (videoInfo && el?.name && videoInfo[el.name]) {
        obj[el.name] = videoInfo[el.name];
      }
    }
    setFormstate({ ...formstate, ...obj });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    addToFormState();
  }, [videoInfo]);
  // console.log("formstate", formstate, "valueee", videoInfo?.name);
  // console.log("newStep", newStep, step);
  // return <SuccessComp setClicked={setClicked} videoInfo={videoInfo} />;

  const deleteVideoUrl = (randomString) => {
    const newData = videoData.filter(
      (data) => data.randomString !== randomString
    );
    const activeUrlArr = newData.map((el) => el.url);
    const activeUrlIndexArr = videoData.map((el, idx) =>
      activeUrlArr.includes(el.url) ? idx : null
    );
    const newDataVidUrlArr = videoDataUrl.filter((el) =>
      activeUrlArr.includes(el?.url)
    );
    const newDataDurationArr = videoDataDuration.filter((el, idx) =>
      activeUrlIndexArr.includes(idx)
    );

    setVideoData(newData);
    setVideoDataUrl(newDataVidUrlArr);
    setVideoDataDuration(newDataDurationArr);
    // const newData = [...videoData];
    // newData.splice(index, 1); // Remove the object at the given index
    // setVideoData(newData);
    // Update the current video URL to the last URL in recordedVideoUrlSections
    let lastVideoUrl = "";
    if (newData.length > 0) {
      // console.log("videoData", newData[newData.length - 1]);
      lastVideoUrl = newData[newData.length - 1].url;
    }

    // console.log("videoData", lastVideoUrl);
    // Update the current video URL to the last video URL
    setNewVideoUrl(newData.length > 0 ? lastVideoUrl : "");
  };
  const handleVideoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // console.log(file, "filee");
      // Convert the selected video to a Blob
      const blob = new Blob([file], { type: file.type });
      // Use video-snapshot to capture a frame from the video
      const snapshoter = new VideoSnapshot(file);
      const previewSrc = await snapshoter.takeSnapshot();
      if (file.type !== "image/jpeg") {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.src = previewSrc;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (jpegBlob) => {
              // Use the converted JPEG blob
              // console.log("jpegBlob", jpegBlob);
              setRecordedPicUrlSections([
                ...recordedPicUrlSections,
                URL.createObjectURL(blob),
              ]);
              setNewPicUrl(URL.createObjectURL(blob));
            },
            "image/jpeg",
            1
          ); // 1 indicates maximum quality
        };
      } else {
        // The image is already in JPEG format, so no conversion is needed
        const response = await fetch(previewSrc);
        const blob = await response.blob();
        setRecordedPicUrlSections([
          ...recordedPicUrlSections,
          URL.createObjectURL(blob),
        ]);
        setNewPicUrl(URL.createObjectURL(blob));
      }
      // Add the Blob to the recordedVideoUrlSections
      // Get video    duration
      const video = document.createElement("video");
      video.preload = "metadata";
      let vidDuration;
      video.onloadedmetadata = () => {
        // setVideoDuration(video.duration);
        vidDuration = video.duration;
        // console.log("Video duration: " + video.duration + " seconds");
        addVideoData(URL.createObjectURL(blob), vidDuration);
      };
      video.src = URL.createObjectURL(file);

      setNewVideoUrl(URL.createObjectURL(blob)); // Clear the selected video
      setRecordedVideoUrlSections([
        ...recordedVideoUrlSections,
        URL.createObjectURL(blob),
      ]);
    }
  };
  const handleNextVideo = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, videoData.length - 1)
    );
  };
  const handleCloseModal = () => {
    setSkipModal(false);
  };
  const handleOpenModal = () => {
    setSkipModal(true);
  };
  const handleSubmitModal = () => {
    setSubmitModal(false);
  };
  const handleOpenSubmitModal = () => {
    setSubmitModal(true);
  };
  const handlePrevVideo = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  let sender = videoInfo?.createdBy?.name ?? "Greg";

  return (
    <>
      {success ? (
        // <SuccessComp setClicked={setClicked} videoInfo={videoInfo} />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: "relative",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "95%" : "92%",
              // display: "flex",
              margin: "0 auto",
              // alignItems: "center",
              position: "relative",
            }}
          >
            <RequestSuccessPage
              videoIdentity={videoIdentity}
              dontShow={
                !(
                  requestReview?.requestBackReview ||
                  requestReview?.requestFromContacts
                )
              }
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            // height: isMobile ? "100%" : "100%",
          }}
        >
          <Wrapper
            style={{ margin: "0px", height: "100%" }}
            videoInfo={videoInfo}
            isMobile={isMobile}
          >
            {/* <TopWrapper></TopWrapper> */}
            <>
              <ContentWrapper isMobile={isMobile}>
                <div
                  style={{
                    width: isMobile ? "95%" : "75%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    margin: "0 auto",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ width: "100%", margin: "40px 0px" }}>
                    <img src={Logo} alt="image" />
                  </div>

                  {newStep === 2 && step === 2 && (
                    <SendStep2
                      videoInfo={videoInfo}
                      setRecordingData={setRecordingData}
                      trail={trail}
                      isVideoOff={isVideoOff}
                      setNewStep={setNewStep}
                      isMobile={isMobile}
                      setGetPermission={setGetPermission}
                      setGetPermissions={setGetPermissions}
                      setOpenModal={setOpenModal}
                      openModal={openModal}
                      newVideoUrl={newVideoUrl}
                      newPicUrl={newPicUrl}
                      setNewVideoUrl={setNewVideoUrl}
                      setNewPicUrl={setNewPicUrl}
                      setRecordedVideoUrlSections={setRecordedVideoUrlSections}
                      setRecordedPicUrlSections={setRecordedPicUrlSections}
                      recordedPicUrlSections={recordedPicUrlSections}
                      recordedVideoUrlSections={recordedVideoUrlSections}
                      openCam={openCam}
                      setOpenCam={setOpenCam}
                      setRunPlayHandle={setRunPlayHandle}
                      runPlayHandle={runPlayHandle}
                      addVideoData={addVideoData}
                      videoData={videoData}
                      onRequestAudioVideoPermissionsModal={
                        onRequestAudioVideoPermissionsModal
                      }
                      handleVideoUpload={handleVideoUpload}
                      loading={loading}
                      deleteVideoUrl={deleteVideoUrl}
                      step={step}
                      runPlayHandler={runPlayHandler}
                    />
                  )}

                  {(newStep === 1 || step === 1) && (
                    <SendStepOne
                      videoInfo={videoInfo}
                      setNewStep={setNewStep}
                      setStep={setStep}
                      isMobile={isMobile}
                      sender={sender}
                    />
                  )}
                  {(newStep === 3 || step === 3) && (
                    <SendStep3
                      isMobile={isMobile}
                      videoData={videoData}
                      currentIndex={currentIndex}
                      handleNextVideo={handleNextVideo}
                      handlePrevVideo={handlePrevVideo}
                      displayForm={displayForm}
                    />
                  )}
                </div>

                {(step === 2 || step === 3 || step === 4 || step === 5) && (
                  <SendStep2345
                    setStep={setStep}
                    setNewStep={setNewStep}
                    setTrail={setTrail}
                    step={step}
                    loading={loading}
                    recordingData={recordingData}
                    handleOpenModal={handleOpenModal}
                    VideoNext={VideoNext}
                    newStep={newStep}
                    formstate={formstate}
                    videoData={videoData}
                  />
                )}

                <Modal
                  isOpen={openSkipModal}
                  Close={handleCloseModal}
                  style={customStylesTwo}
                >
                  <Box
                    sx={{
                      // position: "absolute",
                      // top: isMobile ? "30%" : "10px",
                      // left: isMobile ? "0" : "10px",
                      // transform: "translate(-50%, -50%)",
                      width: isMobile ? "100%" : "100",
                      // bgcolor: "background.paper",
                      // border: "none",
                      // boxShadow: 24,
                      // p: 4,
                      // position: "absolute",
                      // top: "0%",
                      // left: "119px",
                    }}
                    onClick={() => setSkipModal(false)}
                  >
                    {/* <SpinnerBig mode="TUT" /> */}

                    <div
                      style={{
                        display: "flex",
                        boxSizing: " border-box",

                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "16px 16px 24px 24px",

                        // top: "50%",
                        // left: "50%",
                        // right: "auto",
                        // bottom: "auto",
                        // marginRight: "-50%",
                        // transform: "translate(-50%, -50%)",
                        // width: "507px",
                        // height: "300px",

                        background: "#FFFFFF",

                        // border: "1px solid #ECECF2",
                        borderRadius: "15px",
                        // marginLeft: "108px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={RemoveIcon}
                          style={{ cursor: "pointer", marginRight: "24px" }}
                          alt="modal-delete"
                          onClick={handleCloseModal}
                        />
                      </div>
                      <div
                        style={{
                          width: "52%",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "70px",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <StepTitle
                          style={{
                            marginTop: "16px !important",
                            marginBottom: "24px !important",
                            fontSize: "24px",
                          }}
                        >
                          {videoInfo?.openToRecord
                            ? `${sender} has offered to leave a Testimonial for your business`
                            : `Do you want ${sender} to leave a Testimonial for you?`}
                        </StepTitle>
                        <LeftTopSubTitle
                          sx={{ marginTop: "0px !important", fontSize: "16px" }}
                        >
                          A Recrowdly account will be magically created for you
                          if you don't have one
                        </LeftTopSubTitle>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "0 auto",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "100px",
                          marginTop: "25px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "53%",
                        }}
                      >
                        <ColorButton
                          title={videoInfo?.openToRecord ? "Accept" : "Yes"}
                          background={"#1A1B24"}
                          width={"45%"}
                          onClick={() => {
                            //request back Review state
                            setFormstate({
                              ...formstate,
                              requestBackReview: true,
                            });
                            setRequestReview({
                              ...requestReview,
                              requestBackReview: true,
                            });
                            //////
                            handleCloseModal();
                            handleOpenSubmitModal();
                          }}
                          buttonTextStyle={{ fontSize: "18px" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#007BF7",
                            width: "45%",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleCloseModal();
                            handleOpenSubmitModal();
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            Skip
                          </p>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  isOpen={openSubmitModal}
                  Close={handleSubmitModal}
                  style={customStylesTwo}
                >
                  <Box
                    sx={{
                      // position: "absolute",
                      // top: isMobile ? "30%" : "10px",
                      // left: isMobile ? "0" : "10px",
                      // transform: "translate(-50%, -50%)",
                      width: isMobile ? "100%" : "100",
                      // bgcolor: "background.paper",
                      // border: "none",
                      // boxShadow: 24,
                      // p: 4,
                      // position: "absolute",
                      // top: "0%",
                      // left: "119px",
                    }}
                    onClick={() => setSubmitModal(false)}
                  >
                    {/* <SpinnerBig mode="TUT" /> */}

                    <div
                      style={{
                        display: "flex",
                        boxSizing: " border-box",

                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "16px 16px 24px 24px",

                        // top: "50%",
                        // left: "50%",
                        // right: "auto",
                        // bottom: "auto",
                        // marginRight: "-50%",
                        // transform: "translate(-50%, -50%)",
                        // width: "507px",
                        // height: "300px",

                        background: "#FFFFFF",

                        // border: "1px solid #ECECF2",
                        borderRadius: "15px",
                        // marginLeft: "108px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={RemoveIcon}
                          style={{ cursor: "pointer", marginRight: "24px" }}
                          alt="modal-delete"
                          onClick={handleSubmitModal}
                        />
                      </div>
                      <div
                        style={{
                          width: "52%",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "70px",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <StepTitle
                          style={{
                            marginTop: "16px !important",
                            marginBottom: "24px !important",
                            fontSize: "24px",
                          }}
                        >
                          Want to request for testimonial from contacts?
                        </StepTitle>
                        <LeftTopSubTitle
                          sx={{ marginTop: "0px !important", fontSize: "16px" }}
                        >
                          A Recrowdly account will be magically created for you
                          if you don't have one
                        </LeftTopSubTitle>
                      </div>
                      <div
                        style={{
                          width: "33%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "0 auto",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "100px",
                          marginTop: "25px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "53%",
                        }}
                      >
                        <ColorButton
                          title={"Yes"}
                          background={"#1A1B24"}
                          width={"45%"}
                          onClick={() => {
                            setRequestReview({
                              ...requestReview,
                              requestFromContacts: true,
                            });
                            uploadVideo(true);
                          }}
                          buttonTextStyle={{ fontSize: "18px" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#007BF7",
                            width: "45%",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            uploadVideo();
                          }}
                        >
                          <p
                            style={{
                              marginBottom: "0px",
                            }}
                          >
                            Skip
                          </p>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </ContentWrapper>
            </>
          </Wrapper>
        </Box>
      )}
    </>
  );
};

export default ReviewComp;
