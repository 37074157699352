import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";



export const HeaderBox = styled(Box)`

height: 10vh;
display: flex;
align-items: center;
gap:15px;
  
`;