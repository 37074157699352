import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  AlignContainer,
  CheckLabel,
  ControlDiv,
  DisplayDiv,
  DynamicDiv,
  DynamicSectionDiv,
  ImageDiv,
  IndicatorDiv,
  LeftDiv,
  LeftSubTitleDiv,
  OnButton,
  PreviewButton,
  PreviewText,
  SectionTitle,
  SubTitleDiv,
  SubTitleDiv2,
  TypeButton,
  TypeDiv,
  WidgetButDiv,
  PictureBox,
  PictureContent,
  SocialLinkDiv,
  SocialInputDiv,
  SocialInputTitleDiv,
  SocialInputTitleDiv2,
  SocialInputTitleDiv4,
  SocialInputTitleDiv3,
  IconDiv,
  LinkDiv,
  LinkInputDiv,
  VideoLabel,
  VideoName,
  VideoDate,
} from "./styled";
import React from "react";
import AvatarImage from "../../assets/images/Avatar.svg";
import PreviewIcon from "../../assets/images/preview.svg";
import AddImage from "../../assets/icons/AddImage.svg";
import AddIcon from "../../assets/icons/AddIcon.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Twitter from "../../assets/icons/Twitter.svg";
import Linkedin from "../../assets/icons/Linkedin.svg";
import { useRef, useState, useEffect } from "react";
import ColorButton from "../../components/ColorButton";
import Snackbar from "@mui/material/Snackbar";
import ReactCrop from "react-image-crop";
import AWS from "aws-sdk";
import { UPDATE_PROFILE_NO_FILE } from "../../graphql/mutations/user";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import MultipleSelect from "../../components/MultipleSelect";
import CloseIcon from "../../assets/images/Close.svg";

import { UserDetailsImage } from "../UserDashboard/styled";
import VideoComp from "../../components/Main/SectionComp/videoComp";

export default (
  widgetState,
  isMobile,
  handleChange,
  setModalOpen,
  socialLinkArray,
  approvedVideos
) => {
  const profileData = useSelector((state) => state.auth);
  // console.log(profileData, "profileData");
  const [filename, setFilename] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [thumbnail, setThumbnail] = useState(profileData?.thumbnail);
  const [croppedThumbnail, setCroppedThumbnail] = useState(null);
  const [activeLink, setActiveLink] = useState();
  const [videos, setVideos] = useState([]);
  const [socialLinkContent, setSocialLinkContent] = useState(
    widgetState?.socialLinks ?? {}
  );
  const [inputFieldSocialLink, setInputFieldSocialLink] = useState();
  const imageRef = useRef(null);
  const fileInputRef = useRef(null);
  const [blob, setBlob] = useState(null);
  // console.log(videos, "videos");
  let fileUrl = "";
  // console.log(widgetState?.testimonialVideos, "testmonialVideos");

  const {
    loading,
    error,
    data: userData,
  } = useQuery(GET_USER_BY_ID, {
    variables: { input: { userId: profileData?.user?._id } },
  });

  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedThumbnail = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedThumbnail(croppedThumbnail);
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => setFilename(reader.result));
      // reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };
  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const handleCropCancel = () => {
    setFilename(null);
    setCroppedThumbnail(null);
    setShowCrop(false);
  };
  const triggerRef = () => fileInputRef?.current?.click();
  const uploadBox = () => {
    return (
      showCrop && (
        <div
          className="crop-container"
          style={{
            padding: "10px",
            position: "absolute",
            marginLeft: !isMobile && "7rem",
            width: isMobile && "80vw",
            zIndex: "5",
            background: "#FFFFFF",
            border: "1px solid #ECECF2",
            boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
          }}
        >
          <ReactCrop
            src={filename}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
            ruleOfThirds
            // style={{ width: 200, height: 200 }}
          />
          <div
            className="crop-buttons"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ColorButton
              onClick={uploadToS3}
              title="Save"
              width="45%"
              background="#1A1B24"
            ></ColorButton>
            <ColorButton onClick={handleCropCancel} title="Cancel" width="45%">
              Cancel
            </ColorButton>
          </div>
        </div>
      )
    );
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const uploadToS3 = async () => {
    try {
      if (croppedThumbnail) {
        // Initialize S3 object
        const s3 = new AWS.S3({
          accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
          region: "us-east-1",
        });
        let addParams = Date.now();
        // Upload image to S3 bucket
        const key = `Image${addParams}.${blob.type.split("/")[1]}`;
        const params = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: key,
          Body: blob,
        };

        const data = await s3.upload(params).promise();

        setThumbnail(addParams.toString());

        handleChange(addParams.toString(), "picture");

        setOpenSnackbar(true);
        setSnackbarMessage("Profile Image Changed");
        setShowCrop(false);
        setCroppedThumbnail(null);
      }
    } catch (err) {
      console.error("Upload Error", err);
      setOpenSnackbar(true);
      setSnackbarMessage("Upload Error");
    }
  };

  const pictureUrl = () => {
    if (
      thumbnail ||
      widgetState?.picture ||
      userData?.findUserById?.thumbnail
    ) {
      return `${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${
        thumbnail ?? widgetState?.picture ?? userData?.findUserById?.thumbnail
      }.jpeg`;
    }
    return AvatarImage;
  };

  const getVideoLabel = (item) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(item?.createdAt);
    let dateString = date.toLocaleDateString("en-US", options);
    return (
      <VideoLabel>
        <VideoName>{item?.name}</VideoName>
        <VideoDate>Created {dateString}</VideoDate>
      </VideoLabel>
    );
  };

  const getVideoTag = (item) => {
    return item ? (
      <UserDetailsImage>
        <VideoComp height="40px" width="40px" item={item} specialIcon />
      </UserDetailsImage>
    ) : null;
  };

  const getVideoValues = () => {
    if (approvedVideos instanceof Array) {
      let result = approvedVideos.map((item) => {
        return {
          label: getVideoLabel(item),
          lbl: item?.name,
          value: item?.videoId,
          image: getVideoTag(item),
          // image: item?.brand?.imageUrl,
          // background: item?.brand?.backgroundColor,
          // color: item?.brand?.color,
        };
      });
      return result;
    }
    return [];
  };

  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("emailBadge") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={3000}
        />
        <DisplayDiv
          isMobile={isMobile}
          inactive={!widgetState?.sectionVisibility}
          style={{ height: "100%", marginTop: 16 }}
        >
          <DynamicSectionDiv isMobile={isMobile}>
            <SectionTitle style={{ marginTop: 0 }}>Upload Image</SectionTitle>
            <PictureBox
              onMouseLeave={() => setShowImage(false)}
              onMouseEnter={() => setShowImage(true)}
              onClick={triggerRef}
            >
              <input
                type="file"
                id="image-upload"
                accept="image/*"
                onChange={onSelectFile}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              {showImage ? (
                <>
                  <img src={AddImage} style={{ zIndex: 15 }} />
                  <PictureContent>Change Profile Picture</PictureContent>
                </>
              ) : (
                <Avatar
                  alt="Avatar image"
                  src={pictureUrl()}
                  sx={{
                    height: "100px",
                    width: "100px",
                    position: "absolute",
                    zIndex: 10,
                  }}
                />
              )}
            </PictureBox>
            {uploadBox()}

            <SectionTitle>Name</SectionTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.text1}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "text1")}
            />

            <SectionTitle>Designation</SectionTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.text2}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "text2")}
            />

            <SectionTitle>Telephone</SectionTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.telephone}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "telephone")}
            />

            <SectionTitle>Email</SectionTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.email}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "email")}
            />

            <SectionTitle>Testimonial Title</SectionTitle>
            <TextField
              sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
              value={widgetState?.text3}
              inputProps={{
                style: { height: "16px" },
              }}
              fullWidth
              onChange={(e) => handleChange(e.target.value, "text3")}
            />

            <SectionTitle>
              <SocialLinkDiv>
                <Box>Social Links</Box>
                <img
                  style={{ cursor: "pointer" }}
                  src={AddIcon}
                  onClick={() => setShowLink(true)}
                />
              </SocialLinkDiv>
            </SectionTitle>
            <SocialLinkDiv>
              {socialLinkArray.map((el) =>
                socialLinkContent[el?.type] ? (
                  <img
                    style={{ cursor: "pointer", width: "20px" }}
                    src={el?.url}
                  />
                ) : null
              )}

              {showLink && (
                <SocialInputDiv>
                  <SocialInputTitleDiv>
                    <Box>Social Link</Box>
                    <img
                      style={{ cursor: "pointer" }}
                      src={CloseIcon}
                      onClick={() => setShowLink(false)}
                    />
                  </SocialInputTitleDiv>
                  <SocialInputTitleDiv2>
                    <SocialInputTitleDiv3>
                      {socialLinkArray.map((el) => (
                        <IconDiv active={activeLink === el?.type}>
                          <img
                            src={el?.url}
                            onClick={() => {
                              setActiveLink(el?.type);
                              setInputFieldSocialLink();
                            }}
                            style={{ width: "60%" }}
                          />
                        </IconDiv>
                      ))}
                    </SocialInputTitleDiv3>
                    {activeLink && (
                      <SocialInputTitleDiv4>
                        <LinkDiv>Link</LinkDiv>
                        <LinkInputDiv>
                          <TextField
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "15px" },
                            }}
                            // value={widgetState?.link}
                            inputProps={{
                              style: { height: "16px" },
                            }}
                            placeholder="https://"
                            value={
                              inputFieldSocialLink ??
                              socialLinkContent[activeLink] ??
                              ""
                            }
                            fullWidth
                            onChange={
                              (e) => {
                                setInputFieldSocialLink(e.target.value);
                              }

                              // handleChange(e.target.value, "link")
                            }
                          />
                          <ColorButton
                            onClick={() => {
                              let obj = {
                                ...socialLinkContent,
                                [activeLink]: inputFieldSocialLink,
                              };
                              setSocialLinkContent(obj);
                              handleChange(obj, "socialLinks");
                              setInputFieldSocialLink();
                              setShowLink(false);
                            }}
                            title="Save"
                            width="45%"
                            background="#1A1B24"
                          />
                        </LinkInputDiv>
                      </SocialInputTitleDiv4>
                    )}
                  </SocialInputTitleDiv2>
                </SocialInputDiv>
              )}
            </SocialLinkDiv>

            <SectionTitle>Testimonial Videos</SectionTitle>
            <MultipleSelect
              // subSelectData={subSelectData}
              data={getVideoValues()}
              specialIcon
              // data={getBrandsArray}
              // title="Brand Permissions"
              setValues={(val) => handleChange(val, "testimonialVideos")}
              placeholder="Select videos"
              // viewAllInput
              defaultValue={widgetState?.testimonialVideos ?? []}
            />

            {isMobile && (
              <SectionTitle
                onClick={() => setModalOpen(true)}
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                <PreviewButton>
                  <img src={PreviewIcon} />
                  <PreviewText>Preview</PreviewText>
                </PreviewButton>
              </SectionTitle>
            )}
          </DynamicSectionDiv>
        </DisplayDiv>
      </Grid>
    )
  );
};
