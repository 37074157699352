import { Box } from "@mui/system";
import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
// import CropImage from '../../components/CropImage'
import {
  BoxHeader,
  BoxHeaderSubText,
  BoxHeaderTitle,
  BoxImage,
  BoxImageButton,
  BoxImageButtonBrand,
  BoxImageButtonText,
  BoxImageText,
  BoxSection,
  PreviousButton,
  PreviousButtonText,
  SkipButton,
} from "./styled";
import Tangled from "../../assets/images/Tangled.svg";
import SpaceShip from "../../assets/images/SpaceShip.svg";
import { Divider, TextField, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";

import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
} from "../../graphql/queries/organizationQueries";
import {
  ADD_ORGANIZATION,
  USER_OGRANIZATION_INVITE,
} from "../../graphql/mutations/organizationMutation";
import { GET_INDUSTRIES } from "../../graphql/queries/industryQueries";
import AWS from "aws-sdk";
import AddImage from "../../assets/icons/AddImage.svg";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import {
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
} from "../UserDashboard/styled";
import UserDashboard from "../UserDashboard";
import AddBrandDialog from "../../components/AddBrandDialog";
// import SpinnerBig from "../../components/SpinnerBig";
import ReusableModal from "../../components/AddModal";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../../redux/menu.slice";
import { TabTitle } from "../../utils/HeaderTitle";
import { setDate } from "../../redux/date.slice";
import moment from "moment";
import StepTwoBrand from "../../components/AddBrandModal";
import StepOneOrganization from "../../components/AddOrganizationModal";
import SignUpInvitation from "../Invitation/SignUpInvitation";
import { Navigate, useNavigate } from "react-router-dom";
import { ADD_BRAND } from "../../graphql/mutations/brandMutations";
import Invitation from "../Invitation";
import { useSnackbar } from "notistack";
import ProgressBar from "../../components/ProgressBar";
import ButtonCust from "../../components/ButtonCust";
import SpinnerBig from "../../components/SpinnerBig";
import Brand from "../Organizations/Brand";

function OrganizationBrands() {
  const menu = useSelector((state) => state.menu);
  TabTitle(`${menu.name}`);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");

  const [isLoading, setIsLoading] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [step, setStep] = useState(1);

  const [inviteState, setInviteState] = useState({});
  const [numberOfInput, setNumberOfInput] = useState(2);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  // console.log(inviteState, "inviteState");
  const [formDataOrganization, setFormDataOrganization] = useState({
    name: "",
    industry: "",
    // other organization fields
  });
  const [formDataBrand, setFormDataBrand] = useState({
    name: "",
    description: "",
    sector: "",
    organization: "",
    imageUrl: "",
    brandUrl: "",

    // other brand fields
  });
  const [organizationDataOne, setOrganizationData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createOrganization] = useMutation(
    ADD_ORGANIZATION
    // , {
    // update(cache, { data: { createOrganization } }) {
    //   const { organizationsForUser } = cache.readQuery({
    //     query: GET_ORGANIZATIONS_FOR_USER,
    //   });

    //   cache.writeQuery({
    //     query: GET_ORGANIZATIONS_FOR_USER,
    //     data: {
    //       organizationsForUser: [...organizationsForUser, createOrganization],
    //     },
    //   });
    // },}
  );
  const [createBrand] = useMutation(
    ADD_BRAND
    // ,
    // {
    // update(cache, { data: { createBrand } }) {
    //   const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
    //   const { userOrganizations = [] } = existingData || {};

    //   const updatedUserOrganizations = userOrganizations.map(
    //     (userOrganization) => {
    //       if (
    //         userOrganization.organization._id === formDataBrand.organization
    //       ) {
    //         const updatedBrands = [...userOrganization.brands, createBrand];

    //         return {
    //           ...userOrganization,
    //           brands: updatedBrands,
    //         };
    //       }
    //       return userOrganization;
    //     }
    //   );

    //   cache.writeQuery({
    //     query: GET_USER_ORGANIZATION,
    //     data: { userOrganizations: updatedUserOrganizations },
    //   });
    //   },
    //   refetchQueries: [{ query: GET_USER_ORGANIZATION }],
    // }
  );
  const [inviteUser] = useMutation(USER_OGRANIZATION_INVITE);
  const { error, data } = useQuery(GET_INDUSTRIES);
  const {
    data: organizationData,
    loading: organizationLoading,
    error: errorLoading,
    refetch: refetchData,
  } = useQuery(GET_USER_ORGANIZATION);
  console.log("this is org data", organizationData);
  // dialog modal
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  let emailReq =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // const handleOpenDialog = () => {
  //   setDialogOpen(true);
  // };
  // const handleOpenOrganizationDialog = () => {
  //   setDialogOrganizationOpen(true);
  // };

  const hasOrganizationData =
    organizationData?.userOrganizations &&
    organizationData.userOrganizations.some((org) => org.organization);

  const hasBrandData =
    organizationData?.userOrganizations &&
    organizationData.userOrganizations.some(
      (org) => org.brands && org.brands.length > 0
    );

  const tempOrgs = hasOrganizationData
    ? organizationData.userOrganizations.filter((org) => org.organization)
    : [];
  const tempLastOrgCreated = tempOrgs.length
    ? tempOrgs[tempOrgs.length - 1]
    : null;
  // if (!hasBrandData) {
  //   return <h5 >Error Loading Organizations</h5>;
  // }
  if (organizationData?.userOrganizations?.length > 0) {
    // console.log(organizationData?.userOrganizations, "orggg");
    let firstBrand;
    if (organizationData?.userOrganizations instanceof Array) {
      //find organization with brand
      let foundNotNUllOrganizations = organizationData?.userOrganizations.find(
        (el) => !!el.organization && !!el?.brands?.length
      );
       console.log(foundNotNUllOrganizations, "orggg2");
      if (foundNotNUllOrganizations) {
        firstBrand = foundNotNUllOrganizations?.brands[0];
      }
    }
    console.log("out-----log", {
      tempLastOrgCreated,
      formDataBrand: formDataBrand?.organization,
      firstBrand,
    });
    if (firstBrand && firstBrand?.brand) {
      const {
        brand: { name: name },
        brand: { _id: id },
        brand: { brandId: brandId },
      } = firstBrand;
      if (!menu.id && !menu.name && !menu.brandId) {
        dispatch(
          setMenu({
            name,
            brandId,
            id,
            access: firstBrand?.access,
            owned: organizationData?.userOrganizations[0]?.owned,
          })
        );
      }

      dispatch(setDate({ startDate: startDate, endDate: endDate }));
    } else {
      // Handle the case when brands[0] is undefined or empty
      // Add appropriate logic or error handling here
      // console.log("no -brands");
      dispatch(
        setMenu({ name: "", brandId: "", id: "", access: "", owned: false })
      );
      dispatch(setDate({ startDate: "", endDate: "" }));
    }
  }

  // console.log(organizationData);

  const handleNext = async (e) => {
    e?.preventDefault();

    try {
      // Submit organization data
      // console.log(formDataOrganization, "formDataOrganization");
      const { data } = await createOrganization({
        variables: {
          input: {
            name: formDataOrganization.name,
            industry: formDataOrganization.industry,
          },
          // other organization fields
        },
      });
      // console.log(data, "createdOrganization");

      const createdOrganization = data.createOrganization;
      // console.log(createdOrganization, "createdOrganization");
      setOrganizationData(createdOrganization);
      setOpenSnackbar(true);
      setSnackbarMessage("Organization added successfully");
      // Reset the form data to empty strings

      // Set the organization data in formDataBrand
      setFormDataBrand((prevFormData) => ({
        ...prevFormData,
        organization: createdOrganization._id, // Update with the response from createOrganization
      }));
      setFormDataOrganization({
        name: "",
        industry: "",
        // other organization fields
      });
      refetchData();

      // Handle success or display appropriate feedback
    } catch (error) {
      // Handle error or display appropriate feedback
      // console.log("errorLog", error);
    }
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const steps = ["Create Organization", "Create Brand", "Invite Members"];
  const getBrandAccess = (brandInput = []) => {
    return brandInput.map((item) => ({
      access: item?.sub ?? "view",
      brand: item?.value,
    }));
  };

  const handleFormSubmit = async () => {
    // Perform final submission of organization and brand data
    try {
      // Submit brand data
      // await createBrand({
      //   variables: {
      //     input: {
      //       name: formDataBrand.name,
      //       sector: formDataBrand.sector,
      //       organization: formDataBrand.organization,
      //       brandUrl: formDataBrand.brandUrl,
      //       imageUrl: formDataBrand.imageUrl,
      //       description: formDataBrand.description,
      //     },
      //   },
      // });
      // setOpenSnackbar(true);
      // setSnackbarMessage("brand added successfully");
      let messageArr = [];

      try {
        setBtnLoading(true);
        let errorFlag = false;
        for (let i = 0; i < numberOfInput; i++) {
          if (inviteState[`error${i}`]) {
            messageArr.push({
              type: "error",
              description: `Error: Please make sure all the email addresses are valid`,
            });
            return;
          }
          if (
            inviteState[`email${i}`] &&
            inviteState[`email${i}`].trim() !== "" &&
            !inviteState[`brand${i}`]
          ) {
            messageArr.push({
              type: "error",
              description: `Error: Empty brand assigned to ${inviteState[
                `email${i}`
              ]?.trim()}  `,
            });
            errorFlag = true;
          }
        }
        if (errorFlag) return;

        for (let i = 0; i < numberOfInput; i++) {
          let emailInput = `email${i}`;
          let brandInputValue = inviteState[`brand${i}`]; //use later
          let emailInputValue = inviteState[emailInput]?.toString().trim();

          if (emailReq.test(emailInputValue)) {
            try {
              //send to server
              let { data: dataInvite, errors: errorInvite } = await inviteUser({
                variables: {
                  input: {
                    invitedUserEmail: emailInputValue,
                    organization: organizationDataOne._id,
                    brands: getBrandAccess(brandInputValue),
                  },
                },
              });
              if (dataInvite) {
                messageArr.push({
                  type: "success",
                  description: `${emailInputValue} successfully invited `,
                });
              } else {
                if (
                  errorInvite &&
                  errorInvite instanceof Array &&
                  errorInvite[0]?.message
                ) {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue} : ${errorInvite[0]?.message}   `,
                  });
                } else {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue}   `,
                  });
                }
              }
            } catch (err) {
              messageArr.push({
                type: "error",
                description: `${emailInputValue} not invited: ${err}  `,
              });
            }
          }
        }
      } catch (e) {
        // console.log(e);
        messageArr.push({
          type: "error",
          description: `Error occurred... ${e} `,
        });
      } finally {
        setBtnLoading(false);
        showMessage(messageArr);
      }
      // Handle success or display appropriate feedback
    } catch (error) {
      // Handle error or display appropriate feedback
      // console.log("error", error);
    }
    setStep(step + 1);
  };
  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

    return pattern.test(url);
  };
  const handleBrandSubmit = async () => {
    // Perform final submission of organization and brand data

    const newErrorMessages = {};

    if (formDataBrand.name.trim() === "") {
      newErrorMessages.name = "Name is required";
    }
    if (!formDataBrand.sector || formDataBrand.sector.trim() === "") {
      newErrorMessages.sector = "Industry is required";
    }
    if (!formDataBrand.description || formDataBrand.description.trim() === "") {
      newErrorMessages.description = "Description is required";
    }

    if (
      formDataBrand.brandUrl.trim() !== "" &&
      !validateUrl(formDataBrand.brandUrl)
    ) {
      newErrorMessages.brandUrl = "Please add https://";
    }
    setErrorMessage(newErrorMessages);

    if (Object.keys(newErrorMessages).length === 0) {
      try {
        // Submit brand data
        await createBrand({
          variables: {
            input: {
              name: formDataBrand.name,
              sector: formDataBrand.sector,
              organization: formDataBrand.organization,
              brandUrl: formDataBrand.brandUrl,
              imageUrl: formDataBrand.imageUrl,
              description: formDataBrand.description,
            },
          },
        });
        setOpenSnackbar(true);
        setSnackbarMessage("Brand added successfully");
        refetchData();
        let messageArr = [];
        return true;
        // Handle success or display appropriate feedback
      } catch (error) {
        // Handle error or display appropriate feedback
        // console.log("error", error);
        return false;
      }
    }
  };
  const handleInputChange = (field, value) => {
    setFormDataBrand({ ...formDataBrand, [field]: value });

    // Clear the error message for the specific field
    setErrorMessage((prevErrorMessages) => ({
      ...prevErrorMessages,
      [field]: "",
    }));
  };
  const showMessage = (messageArr) => {
    if (messageArr.length > 0) {
      for (let item of messageArr) {
        enqueueSnackbar(item?.description, { variant: item?.type });
      }
    }
    return;
  };
  const handleBrandNext = async () => {
    const isSubmitSuccessful = await handleBrandSubmit();

    if (isSubmitSuccessful) {
      setStep(step + 1);
    }
  };

  if (organizationLoading) {
    return <SpinnerBig big />;
  }

  return (
    <>
      {organizationLoading && !organizationData ? (
        <></>
      ) : (
        <>
          {hasBrandData && (step > 3 || step === 1) ? (
            // <UserDashboard />
            <Brand />
          ) : organizationData && tempLastOrgCreated && step === 1 ? (
            <>
              <Navigate
                to={`/create_brand/${tempLastOrgCreated?.organization?._id}`}
                state={{ orgName: tempLastOrgCreated?.organization?.name }}
              />
            </>
          ) : organizationData && !organizationLoading && !errorLoading ? (
            <>
              <div>
                <ProgressBar steps={steps} activeStep={step - 1} />
                {step === 1 && (
                  <StepOneOrganization
                    formData={formDataOrganization}
                    setFormData={setFormDataOrganization}
                    onNext={handleNext}
                  />
                )}
                {step === 2 && (
                  <StepTwoBrand
                    formData={formDataBrand}
                    onPrevious={handlePrevious}
                    onNext={handleBrandNext}
                    orgName={
                      organizationDataOne?.name ?? tempLastOrgCreated?.name
                    }
                    errorMessages={errorMessage}
                    handleInputChange={handleInputChange}
                  />
                )}
                {step === 3 && (
                  <Invitation
                    inputNumber={2}
                    inputOrganization={organizationDataOne._id}
                    description="You can update user permissions on the team page after setting up."
                    setInviteFormstate={setInviteState}
                    onPrevious={handlePrevious}
                    removeLayout={true}
                  />
                )}
                {/* {step === 5 && <UserDashboard />} */}
                {step === 5 && <Brand />}

                {step === 3 && (
                  <>
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "70%",
                          maxWidth: "769px",
                          margin: "1.5rem auto",
                        }}
                      >
                        <PreviousButton onClick={handlePrevious}>
                          <PreviousButtonText>Previous</PreviousButtonText>
                        </PreviousButton>
                        <ButtonCust
                          onClick={handleFormSubmit}
                          title={"Finish"}
                        ></ButtonCust>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: "769px",
                        margin: "2rem auto",
                      }}
                    >
                      <SkipButton onClick={() => setStep(5)}>
                        Skip for now
                      </SkipButton>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <h5>
              {organizationLoading
                ? "Loading..."
                : "No Data, Check internet connection and retry"}
            </h5>
          )}
        </>
      )}
    </>
  );
}

export default OrganizationBrands;
