import React from "react";
import countryCodes from "./countryCodes";
// import { MenuItemC } from "../../pages/Customization/styled";
import { MenuItemC } from "../pages/Customization/styled";

import { Box } from "@mui/material";
import imgCountry from "./countryAssets";

// Create a function to import files dynamically
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

// Use require.context to import all files in the assets directory
// const assets = importAll(
//   require.context("../assets/countries", false, /\.(png|jpe?g|svg)$/)
// );

// console.log(imgCountry["ad.png"], "imgCountry111");
// Define your React component
const dynamicFileComponent = (fileName) => {
  // Get the file dynamically based on the fileName prop
  // console.log(imgCountry, "imgCountry", fileName);
  const file = imgCountry[fileName];

  // Check if the file exists
  if (!file) {
    return null;
  }

  // Render the file
  return file;
};

export const getCountryFlag = (val) => {
  let ctryItem = countryCodes.find((el) => el.code === val);
  if (ctryItem) {
    let str = String(ctryItem.country).toLowerCase();
    let comp = dynamicFileComponent(`${str}.png`);
    return comp;
  }
  return null;
};

export const getCountryItems = (code) => {
  let ctryItem = countryCodes.find((el) => el.code === code);
  if (ctryItem) {
    let str = String(ctryItem.country).toLowerCase();
    let comp = dynamicFileComponent(`${str}.png`);
    if (comp && ctryItem.code) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <img
            src={comp}
            alt={ctryItem.country}
            style={{ width: "23px", height: "16px" }}
          />
          <Box
            sx={{
              color: "#282833",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            ({ctryItem.code})
          </Box>
        </Box>
      );
    }
  }
  return null;
};

const getCountryTags = () => {
  return countryCodes.map((el, idx) => {
    let str = String(el.country).toLowerCase();
    let comp = dynamicFileComponent(`${str}.png`);
    if (comp && el.code) {
      return (
        <MenuItemC key={`${idx}CO`} value={el.code}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <img
              src={comp}
              alt={el.country}
              style={{ width: "23px", height: "16px" }}
            />
            <Box
              sx={{
                color: "#282833",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              ({el.code})
            </Box>
          </Box>
        </MenuItemC>
      );
    }
    return null;
  });
};

export default getCountryTags;
