import React, { useState, useEffect } from "react";

const NumberBox = ({ durationStringOverall, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let animationFrameId;
    let startTimestamp;

    const startAnimation = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / 1000, 1); // Adjust the duration as needed
      const currentCount = Math.floor(progress * duration);

      setCount(currentCount);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(startAnimation);
      }
    };

    animationFrameId = requestAnimationFrame(startAnimation);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [duration]);

  function formatTime(totalSeconds) {
    if (totalSeconds === 0) {
      return "0";
    }
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  function Timer({ totalSeconds }) {
    const timeString = formatTime(totalSeconds);
    return <>{timeString}</>;
  }

  return (
    <div>
      <Timer totalSeconds={count} />
    </div>
  );
};

export default NumberBox;
