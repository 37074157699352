import styled from "@emotion/styled";
import { Image } from "@mui/icons-material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export const Title = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #5d6272;
  padding-left: ${(props) => props?.pad && "2em"};
`;

export const UserDetailsHeader = styled(Typography)`
  min-width: 30px;
  height: 14px;
  position: relative;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: #1a1b24;
  ${(props) =>
    props.stylehead &&
    ` font-family: 'Dm Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
    color: #5D6272;`}
`;
export const UserDetailsHeaderTwo = styled(Typography)`
  height: 14px;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  color: #1a1b24;
  ${(props) =>
    props.stylehead &&
    ` font-family: 'Dm Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
    color: #5D6272;`}
`;

export const UserDetailsText = styled(Typography)`
  position: relative;
  min-height: 18px;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
`;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: "72px",
    // borderTop: "1px solid #ccc",
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  // },
}));
