import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const ShowDiv = styled(Box)`
  position: absolute;
  min-width: 100px;
  // height: 390px;
  // left: 1050px;
  // top: 68px;
  top: 15rem;
  left: 10rem;

  z-index: 5;
  background: #ffffff;
  border: 1px solid #ececf2;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
`;

export const Text = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
`;
