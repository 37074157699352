import React, { useCallback, useMemo, useState } from "react";
import WorkflowProvider from "./workflowContext";
import WorkflowById from "./workflowById";

const WorkflowPage = () => {
  return (
    <WorkflowProvider>
      <WorkflowById />
    </WorkflowProvider>
  );
};

export default WorkflowPage;
