import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOutUser, setUser } from "../../redux/auth.slice";
import { logOutMenu, setMenu } from "../../redux/menu.slice";
import { setWindowFlag } from "../../redux/responsive.slice";
import { logOutDate } from "../../redux/date.slice";
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Grid,
  Container,
  Box,
  Divider,
  InputBase,
  Paper,
  IconButton,
  ListItemButton,
  Modal,
  TextField,
  Avatar,
  MenuItem,
  Badge,
} from "@mui/material";
import {
  CheckBoxOutlineBlankOutlined,
  DraftsOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutline,
  ReceiptOutlined,
  TroubleshootRounded,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState, useRef } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import RecrowdlyLogo from "../../assets/images/Logo.svg";

import SearchIcon from "../../assets/icons/SearchIcon.svg";
import {
  BannerBox,
  BannerBoxText,
  DashBoardContainer,
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxSubText,
  ModalBoxText,
  ModalButton,
  ModalButtonText,
  ModalColumnContainer,
  ModalImageContainer,
  ModalImageTitle,
  ModalTextField,
  NavContainer,
  NavContainerContent,
  NavContainerContentOne,
  NavContainerContentTwo,
  NavDivider,
  NavHeaderLogoText,
  NavImage,
  NavSearch,
  NavTitleText,
  ProfileButton,
  ProfileButtonText,
  ProfileDateBrand,
  ProfileImage,
  ProfileImageContainer,
  ProfileImageContent,
  ProfileImageContentHeader,
  ProfileImageContentText,
  ProfileNotificationBox,
  ProfileNotificationBoxColumn,
  ProfileNotificationContainer,
  ProfileNotificationHead,
  ProfileNotificationHeadMark,
  ProfileNotificationText,
  ProfileNotificationTextTwo,
  ProfileSnippet,
  ProfileSnippetNotification,
  SearchResultsBarBottom,
  SearchResultsBarText,
  SearchResultsBarTop,
  SearchResultsBrandIcon,
  SearchResultsBrandText,
  SearchResultsForm,
  SListItemButton,
  SListItemIcon,
} from "../../pages/UserDashboard/styled";
import NavMenuButton from "../NavMenu";
import AvatarImage from "../../assets/images/Avatar.svg";
import AddImage from "../../assets/icons/AddImage.svg";
import Dot from "../../assets/icons/Dot.svg";
import Video from "../../assets/images/video.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Trash from "../../assets/icons/Trash.svg";
import LogoutIcon from "../../assets/icons/Logout.svg";
import UserIcon from "../../assets/icons/User.svg";
import Stars from "../../assets/icons/WhiteStar.svg";
import TeamMembers from "../../assets/icons/TeamMembers.svg";
import Profile from "../../assets/icons/Profile.svg";
import InviteMembers from "../../assets/icons/InviteMembers.svg";
import ShareIcon from "../../assets/icons/Share.svg";
import Notification from "../../assets/icons/Notification.svg";
import CloseIcon from "../../assets/images/Close.svg";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";
import {
  GET_ORGANIZATIONS_FOR_USER,
  GET_USER_ORGANIZATION,
  SEARCH_ORGANIZATIONS_AND_BRANDS,
  SEARCH_VALUES,
  RECENT_SEARCH_VALUES,
} from "../../graphql/queries/organizationQueries";
import { ADD_BRAND } from "../../graphql/mutations/brandMutations";
import { GET_BRANDS } from "../../graphql/queries/brandQueries";
import AWS from "aws-sdk";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import AddBrandDialog from "../AddBrandDialog";
import SearchList from "../SearchList";
import VideoModal from "../VideoModal";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { socket } from "../../App";
import { GET_NOTIFICATION_BY_USER } from "../../graphql/queries/notificationQueries";
import BottomMenu from "../BottomMenu";

import { GET_PENDING_INVITATION } from "../../graphql/queries/invitation";
import InvitationsTable from "../InvitationsTable";
import {
  ACCEPT_INVITATION,
  DECLINE_INVITE,
} from "../../graphql/mutations/invitation";
import ColorButton from "../ColorButton";
import { StyledButtonContainer } from "../InvitationsTable/styled";
import {
  PreviousButton,
  PreviousButtonText,
} from "../../pages/OrganizationBrands/styled";
import {
  MARK_ALL_AS_OLD,
  MARK_ALL_AS_READ,
  SINGULAR_MARK,
} from "../../graphql/mutations/notificationMutation";
import { useSnackbar } from "notistack";
import moment from "moment";
import { SeeMore, SeeMoreText } from "../../pages/Organizations/styled";
import BottomPopup from "../BottomPopup";
import MobileProfile from "../MobileProfile";
import { queryParams } from "../../utils/Helpers";
import { useLocation } from "react-router-dom";

import ReactGA from "react-ga4";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  region: "us-east-1",
});

const s3 = new AWS.S3();

function Layout({ children, openDialog, onClose }) {
  const dispatch = useDispatch();
  const queryData = queryParams();
  const location = useLocation();

  // Access the pathname
  const currentPathname = location.pathname;
  // console.log(currentPathname, "currentPathname");

  const profileData = useSelector((state) => state.auth);
  const menu = useSelector((state) => state.menu);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const [showSearch, setShowSearch] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [markedAllAsRead, setMarkedAllAsRead] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [openBrand, setBrandOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clickedMenu, setClickedMenu] = useState(queryData?.menu);

  const [openVideoModal, setOpenVideoModal] = React.useState(false);
  const [videoToPlay, setVideoToPlay] = React.useState("");
  const [searchInput, setSearchInput] = useState();
  const [displayedUnreadNotifications, setDisplayedUnreadNotifications] =
    useState([]);
  const [displayedReadNotifications, setDisplayedReadNotifications] = useState(
    []
  );
  const [socketNotifications, setSocketNotifications] = useState([]);
  const navigate = useNavigate();
  const resultsBtnRef = useRef();
  const profileBtnRef = useRef();
  const notificationBtnRef = useRef();
  // Add function to toggle visibility of search results

  // Define event handler function
  const handleBodyClick = (event) => {
    const path = event.composedPath(); // Use composedPath() instead of event.path

    // Check if the clicked element is not the results or profile button
    if (
      !path.includes(resultsBtnRef.current) &&
      !path.includes(profileBtnRef.current) &&
      !path.includes(notificationBtnRef.current)
    ) {
      setShowResults(false);
      setShowProfile(false);
      setShowNotification(false);
      setShowSearch(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);
  const toggleResults = () => {
    setShowResults(true);
    setShowProfile(false);
    setShowNotification(false);
  };

  const closeToggle = () => {
    setShowResults(false);
    setShowProfile(false);
  };

  const handleBoxClick = (event) => {
    event.stopPropagation();
  };
  // Add function to toggle visibility of profile
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (notificationBtnRef.current && !notificationBtnRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [ref]);
  const handleBrandOpen = () => {
    setBrandOpen(true);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sector, setSector] = useState("");
  const [organization, setOrganization] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  // const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [showText, setShowText] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);

  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  let fileUrl = "";
  const [invitations, setInvitations] = React.useState(null);
  const [invitationCode, setInvitationCode] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [brandAccessName, setBrandAccessName] = React.useState([]);
  const [thumbnail, setThumbnail] = React.useState("");
  const [createdAt, setCreatedAt] = React.useState("");
  const [notificationId, setNotificationId] = React.useState("");
  const [videoName, setVideoName] = React.useState("");
  const [videoPosition, setVideoPosition] = React.useState("");
  const [videoRating, setVideoRating] = React.useState("");
  const [recent, setRecent] = React.useState("");
  const [totalNewNotifications, setTotalNewNotifications] = useState(0);
  const [totalOldNotifications, setTotalOldNotifications] = useState(0);
  const [focusState, setFocusState] = useState({});
  const { data: pendingData } = useQuery(GET_PENDING_INVITATION);
  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_USER_ORGANIZATION
  );
  const {
    loading: userLoading,
    error: userError,
    data: userData,
    refetch: userRefetch,
  } = useQuery(GET_USER_BY_ID, {
    variables: { input: { userId: profileData?.user?._id } },
  });
  const {
    loading: notificationLoading,
    error: notificationError,
    data: notificationData,
    refetch: refetchNotification,
  } = useQuery(GET_NOTIFICATION_BY_USER, {
    variables: { userId: profileData?.user?._id },
    fetchPolicy: "cache-and-network",
  });
  const [markAllAsOld] = useMutation(MARK_ALL_AS_OLD, {
    // update(cache, { data: { markAllNotificationOld } }) {
    //   const existingData = cache.readQuery({
    //     query: GET_NOTIFICATION_BY_USER,
    //     variables: { userId: profileData?.user?._id },
    //   });
    //   console.log("existingData", existingData);
    //   console.log("existingData", markAllNotificationOld);
    //   const updatedNotificationMessage =
    //     markAllNotificationOld[0]?.notificationMessage || [];
    //   console.log("updatedNotificationMessage", updatedNotificationMessage);
    //   console.log("updatedNotificationMessage", profileData?.user?._id);
    //   cache.writeQuery({
    //     query: GET_NOTIFICATION_BY_USER,
    //     variables: { userId: profileData?.user?._id },
    //     data: {
    //       ...existingData,
    //       notificationByUserId: {
    //         ...existingData.notificationByUserId,
    //         _id: existingData.notificationByUserId._id,
    //         userId: existingData.notificationByUserId.userId,
    //         createdAt: existingData.notificationByUserId.createdAt,
    //         notificationMessage: updatedNotificationMessage,
    //       },
    //     },
    //   });
    // },
    // refetchQueries: [{ query: GET_NOTIFICATION_BY_USER }],
  });

  // check for organizations
  const hasOrganizationData =
    organizationData?.userOrganizations &&
    organizationData.userOrganizations.some((org) => org.organization);

//incase we check for brands
  // const hasBrandData =
  //   organizationData?.userOrganizations &&
  //   organizationData.userOrganizations.some(
  //     (org) => org.brands && org.brands.length > 0
  //   );

  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ, {
    update(cache, { data: { markAllAsRead } }) {
      const existingData = cache.readQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
      });

      const updatedNotificationMessage =
        markAllAsRead[0]?.notificationMessage || [];

      cache.writeQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
        data: {
          ...existingData,
          notificationByUserId: {
            ...existingData.notificationByUserId,
            _id: existingData.notificationByUserId._id,
            userId: existingData.notificationByUserId.userId,
            createdAt: existingData.notificationByUserId.createdAt,
            notificationMessage: updatedNotificationMessage,
          },
        },
      });
    },
    refetchQueries: [{ query: GET_NOTIFICATION_BY_USER }],
  });
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION, {
    update(cache, { data: { acceptInvitation } }) {
      const existingData = cache.readQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
      });

      const updatedNotificationMessage =
        acceptInvitation[0]?.notificationMessage || [];

      cache.writeQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
        data: {
          ...existingData,
          notificationByUserId: {
            ...existingData.notificationByUserId,
            _id: existingData.notificationByUserId._id,
            userId: existingData.notificationByUserId.userId,
            createdAt: existingData.notificationByUserId.createdAt,
            notificationMessage: updatedNotificationMessage,
          },
        },
      });
    },
    refetchQueries: [{ query: GET_NOTIFICATION_BY_USER }],
  });
  const [singularMark] = useMutation(SINGULAR_MARK, {
    update(cache, { data: { singularMark } }) {
      const existingData = cache.readQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
      });

      const updatedNotificationMessage =
        singularMark[0]?.notificationMessage || [];

      cache.writeQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
        data: {
          ...existingData,
          notificationByUserId: {
            ...existingData.notificationByUserId,
            _id: existingData.notificationByUserId._id,
            userId: existingData.notificationByUserId.userId,
            createdAt: existingData.notificationByUserId.createdAt,
            notificationMessage: updatedNotificationMessage,
          },
        },
      });
    },
    refetchQueries: [{ query: GET_NOTIFICATION_BY_USER }],
  });
  const [deleteInvite] = useMutation(DECLINE_INVITE, {
    update(cache, { data: { declineInvite } }) {
      const existingData = cache.readQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
      });

      const updatedNotificationMessage =
        declineInvite[0]?.notificationMessage || [];

      cache.writeQuery({
        query: GET_NOTIFICATION_BY_USER,
        variables: { userId: profileData?.user?._id },
        data: {
          ...existingData,
          notificationByUserId: {
            ...existingData.notificationByUserId,
            _id: existingData.notificationByUserId._id,
            userId: existingData.notificationByUserId.userId,
            createdAt: existingData.notificationByUserId.createdAt,
            notificationMessage: updatedNotificationMessage,
          },
        },
      });
    },
    refetchQueries: [{ query: GET_NOTIFICATION_BY_USER }],
  });

  // search query
  const [query, setQuery] = useState("");
  const [input, setInput] = useState("");
  // const [search, { loading, data: searchedData }] = useLazyQuery(
  //   SEARCH_ORGANIZATIONS_AND_BRANDS
  // );

  // SEARCH_VALUES
  const [search, { loading, data: searchedData }] = useLazyQuery(SEARCH_VALUES);
  const [recentSearch, { data: recentSearchedData }] =
    useLazyQuery(RECENT_SEARCH_VALUES);

  // console.log(searchedData);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (input !== "") {
      search({ variables: { query: input } });
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);

      const reader = new FileReader();

      reader.addEventListener("load", () => setSrc(reader.result));
      reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  // const handlePaymentClick = () => {
  //   navigate("/billingplans");
  // };

  const uploadToS3 = async () => {
    if (croppedImageUrl) {
      // Initialize S3 object
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
        region: "us-east-1",
      });
      let addParams = Date.now();
      // Upload image to S3 bucket
      const key = `Image${addParams}.${blob.type.split("/")[1]}`;
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: key,
        Body: blob,
      };
      try {
        const data = await s3.upload(params).promise();

        setOpenSnackbar(true);
        setSnackbarMessage("Image Added Successfuly");

        setImageUrl(addParams.toString());
      } catch (err) {
        console.error("Upload Error", err);
        setOpenSnackbar(true);
        setSnackbarMessage("Upload Error");
      }
    }
  };
  const handleCropCancel = () => {
    setSrc(null);
    setCroppedImageUrl(null);
    setShowCrop(false);
  };
  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

    return pattern.test(url);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // get organizations for select
  // const { error, data } = useQuery(GET_ORGANIZATIONS_FOR_USER);

  const handleClose = () => {
    setOpen(false);
    setShowResults(!showResults);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  const handleBrandClose = () => setBrandOpen(false);
  const handleLogout = () => {
    dispatch(logOutUser());
    dispatch(logOutMenu());
    dispatch(logOutDate());
    navigate("/");
  };
  const handleShare = () => navigate("/invitation");

  // const getList = () => (
  //   <div style={{ width: 250 }} onClick={() => setOpen(false)}>
  //     {dataList?.map((item, index) => (
  //       <ListItem key={index} onClick={() => navigate(item?.link)}>
  //         <ListItemIcon>{item?.icon}</ListItemIcon>
  //         <ListItemText primary={item?.name} />
  //       </ListItem>
  //     ))}
  //     <ListItem
  //       onClick={() => {
  //         dispatch(logOutUser());
  //       }}
  //     >
  //       <ListItemIcon>
  //         <ReceiptOutlined />
  //       </ListItemIcon>
  //       <ListItemText primary="Log Out" />
  //     </ListItem>
  //   </div>
  // );

  const handleStateSet = (name, brandId, id) => {
    dispatch(setMenu({ name, brandId, id }));
  };
  const handleSetMenu = (
    brandAccessNames,
    owned /* add other required parameters here */
  ) => {
    // Assuming brandAccessNames is an array of objects with properties like Id and name
    // console.log("Brand Access Name:", brandAccessNames);
    // Access elements and properties of brandAccessNames array
    for (const brandAccess of brandAccessNames) {
      if (owned) {
        dispatch(
          setMenu({
            name: brandAccess.brand,
            brandId: brandAccess.brandId,
            id: brandAccess.id,
            access: "edit",
            owned: owned,
          })
        );
      } else {
        dispatch(
          setMenu({
            name: brandAccess.brand,
            brandId: brandAccess.brandId,
            id: brandAccess.id,
            access: brandAccess.access,
            owned: owned,
          })
        );
      }
    }

    // Rest of your logic here...
    navigate(`/brands/${menu.brandId}/0/impression`);
  };

  useEffect(() => {
    setTimeout(() => {
      let inputValue = searchInput?.toString()?.trim();

      if (inputValue && inputValue !== "") {
        search({ variables: { input: { inputValue } } });
        recentSearch();
      }
    }, 0); //500
  }, [searchInput]);

  React.useEffect(() => {
    if (pendingData?.pendingInvites) {
      setInvitations(pendingData?.pendingInvites);
    }
  }, [pendingData?.pendingInvites]);

  useEffect(() => {
    recentSearch();
  }, [showSearch]);
  useEffect(() => {
    // Join the socket room
    const userId = profileData?.user?._id;
    const socketRoom = userId;
    // console.log("socketRoom",socketRoom)
    // console.log(`Joining room: ${socketRoom}`);
    socket.emit("joinRoom", socketRoom);

    const handleEventData = ({
      message,
      type,
      status,
      invitationCode,
      brandAccessName,
      thumbnail,
      notificationId,
      createdAt,
      videoName,
      videoPosition,
      videoRating,
      recent,
    }) => {
      // Handle the event data
      // Set the states accordingly
      setType(type);
      setMessage(message);
      setInvitationCode(invitationCode);
      setStatus(status);
      setBrandAccessName(brandAccessName);
      setThumbnail(thumbnail);
      setNotificationId(notificationId);
      setCreatedAt(createdAt);
      setVideoName(videoName);
      setVideoPosition(videoPosition);
      setVideoRating(videoRating);
      setRecent(recent);
    };

    // Listen for "Videos" event
    socket.on("Videos", handleEventData);

    // Listen for "UserInvite" event
    socket.on("UserInvite", handleEventData);

    // Listen for "Member" event
    socket.on("Member", handleEventData);
    // Listen for "Accept" event
    socket.on("Accept", handleEventData);

    // Clean up the event listener and leave the socket room when component unmounts
    return () => {
      socket.off("Videos");
      socket.emit("leaveRoom", socketRoom);
    };
  }, []);
  const handleClickVariant = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  // Handle accepting the invitation
  // const handleAcceptInvitation = () => {
  //   // Emit a socket event to notify the backend about the accepted invitation
  //   socket.emit("AcceptInvitation", { invitationCode, invitedUser:profileData?.user?._id });
  // };
  const handleAcceptInvitation = (invitationCode, notificationId) => {
    acceptInvitation({
      variables: { input: { invitationCode, notificationId } },
    });
    handleClickVariant("success", "invitation accepted!");
  };
  const createVideoRead = (
    notificationId,
    invitationCode,
    videoName,
    videoPosition,
    videoRating
  ) => {
    singularMark({
      variables: {
        userId: profileData?.user._id,
        notificationId: notificationId,
      },
    });
    const videoUrl = `${invitationCode}****${videoName}****${videoName}****${videoPosition}****${videoRating}`;

    setOpenVideoModal(true);
    setVideoToPlay(videoUrl);
  };

  const markAllNotificationAsRead = async () => {
    try {
      await markAllAsRead({
        variables: {
          userId: profileData?.user._id,
        },
      });
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error("Error marking notifications as read:", error);
    }
  };

  const memberJoined = (notificationId) => {
    singularMark({
      variables: {
        userId: profileData?.user._id,
        notificationId: notificationId,
      },
    });

    // navigate("/teammembers");
  };
  const toggleProfile = () => {
    setShowProfile(!showProfile);
    setShowResults(false);
    setShowNotification(false);
    setShowSearch(false);
  };


  // ...

  useEffect(() => {
    // Check if totalNewNotifications is equal to 1
    if (totalNewNotifications === 1) {
      // If true, trigger the refetch
      refetchNotification();
    }
  }, [totalNewNotifications, refetchNotification]);

  useEffect(() => {
    if (message) {
      const newNotification = {
        _id: Math.random().toString(36).substr(2, 9),
        notificationMessage: [
          {
            message,
            type,
            status,
            invitationCode,
            brandAccessName,
            thumbnail,
            createdAt,
            videoName,
            videoPosition,
            videoRating,
            recent,
            notificationId,
          },
        ],
        source: "socket",
        type,
      };

      setSocketNotifications((prevNotifications) => [
        newNotification,
        ...prevNotifications,
      ]);
    }
  }, [
    message,
    type,
    status,
    invitationCode,
    brandAccessName,
    thumbnail,
    createdAt,
    videoName,
    videoPosition,
    videoRating,
    recent,
    notificationId,
  ]);
  const markAllNotificationAsOld = async () => {
    try {
      await markAllAsOld({
        variables: {
          userId: profileData?.user._id,
        },
      });
      markAllSocketAsOld();
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error("Error marking notifications as old:", error);
    }
  };
  const allNotifications = useMemo(() => {  
    const notifications = notificationData?.notificationByUserId || [];
    return [...notifications];
  }, [notificationData]);

  const allSocketNotifications = useMemo(() => {
    const notifications = notificationData?.notificationByUserId || [];
    return [...socketNotifications, ...notifications];
  }, [notificationData, socketNotifications]);

  // ...

  // console.log("socketNotifications", socketNotifications);
  // console.log("allSocketNotifications", allSocketNotifications);

  // console.log("allNotifications", allNotifications);

  // Merge the socketNotification with the existing notifications array
  // const allNotifications = useMemo(() => {
  //   if (socketNotification) {
  //     // Check if the socketNotification is not null
  //     const existingNotifications = [...notifications];
  //     existingNotifications.push(socketNotification);
  //     return existingNotifications;
  //   }
  //   return notifications;
  // }, [notifications, socketNotification]);
  const sortedNotifications = useMemo(() => {
    return allNotifications.map((notification) => {
      const notificationMessage = notification.notificationMessage || [];
      const sortedMessages = [...notificationMessage].sort((a, b) => {
        // const aStatus = a.status || "read";
        // const bStatus = b.status || "read";

        // if (aStatus === "unread" && bStatus !== "unread") {
        //   return -1; // a is unread, b is read or undefined
        // } else if (aStatus !== "unread" && bStatus === "unread") {
        //   return 1; // a is read or undefined, b is unread
        // }
        const aTimestamp = new Date(a.createdAt).getTime();
        const bTimestamp = new Date(b.createdAt).getTime();
        return bTimestamp - aTimestamp;
        return 0; // both are read or both are undefined
      });

      return {
        ...notification,
        notificationMessage: sortedMessages,
      };
    });
  }, [allNotifications]);
  // const sortedNotifications = useMemo(() => {
  //   return [...allNotifications].sort((a, b) => {
  //     const aStatus = a.notificationMessage.some((msg) => msg.status === 'unread');
  //     const bStatus = b.notificationMessage.some((msg) => msg.status === 'unread');

  //     if (aStatus && !bStatus) {
  //       return -1;
  //     } else if (!aStatus && bStatus) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }, [allNotifications]);
  const markAllSocketAsOld = () => {
    // console.log("socketNotifications:", socketNotifications);
    // Map through all notifications and update the notificationMessage array
    const updatedNotifications = socketNotifications.map((notification) => {
      const updatedNotificationMessage = notification.notificationMessage.map(
        (msg) => {
          if (msg.recent === "new") {
            // Set msg.recent to "old" if it is currently "new"
            return { ...msg, recent: "old" };
          }
          return msg; // Return the original msg if it's not "new"
        }
      );

      return {
        ...notification,
        notificationMessage: updatedNotificationMessage,
      };
    });
    setSocketNotifications(updatedNotifications);
    // console.log("recent", updatedNotifications);
    return updatedNotifications;
  };
  const markNotificationAsRead = (notificationId) => {
    // Map through all notifications and update the notificationMessage array
    const updatedNotifications = socketNotifications.map((notification) => {
      const updatedNotificationMessage = notification.notificationMessage.map(
        (msg) => {
          if (
            msg.notificationId === notificationId &&
            msg.recent === "unread"
          ) {
            // Set msg.recent to "read" if it matches the notificationId and is currently "unread"
            return { ...msg, recent: "read" };
          }
          return msg; // Return the original msg for other cases
        }
      );

      return {
        ...notification,
        notificationMessage: updatedNotificationMessage,
      };
    });

    // Update the socketNotifications state with the new updatedNotifications
    setSocketNotifications(updatedNotifications);
  };

  const toggleNotification = async () => {
    if (markedAllAsRead) {
      await markAllNotificationAsRead();
    }

    setMarkedAllAsRead(!markedAllAsRead);
    setShowNotification(!showNotification);
    setShowProfile(false);
    setShowResults(false);
    setShowSearch(false);

    // Execute both mutations simultaneously
    markAllNotificationAsOld();
  };
  // const loadMoreNotifications = () => {
  //   const currentlyDisplayed = displayedNotifications.reduce(
  //     (count, notification) => count + notification.notificationMessage.length,
  //     0
  //   );
  //   const remainingNotifications = sortedNotifications.map((notification) => ({
  //     ...notification,
  //     notificationMessage: notification.notificationMessage.slice(
  //       currentlyDisplayed,
  //       currentlyDisplayed + 8
  //     ),
  //   }));
  //   setDisplayedNotifications([
  //     ...displayedNotifications,
  //     ...remainingNotifications,
  //   ]);
  // };

  useEffect(() => {
    const unreadNotifications = sortedNotifications.map((notification) => {
      const unreadMessages = notification.notificationMessage.filter(
        (msg) => msg.status === "unread"
      );

      return {
        ...notification,
        notificationMessage: unreadMessages,
      };
    });

    setDisplayedUnreadNotifications(unreadNotifications);
  }, [sortedNotifications]);

  useEffect(() => {
    const readNotifications = sortedNotifications
      .slice(0, 5)
      .map((notification) => {
        const readMessages = notification.notificationMessage.filter(
          (msg) => msg.status === "read"
        );

        return {
          ...notification,
          notificationMessage: readMessages.slice(0, 5),
        };
      });

    // Limit the result to the first 5 notifications
    const limitedReadNotifications = readNotifications.slice(0, 5);

    setDisplayedReadNotifications(limitedReadNotifications);
  }, [sortedNotifications]);

  // useEffect(() => {
  //   const initialNotifications = sortedNotifications
  //     .slice(0, 5)
  //     .map((notification) => ({
  //       ...notification,
  //       notificationMessage: notification.notificationMessage.slice(0, 5),
  //     }));
  //   setDisplayedReadNotifications(initialNotifications);
  // }, [sortedNotifications]);
  useEffect(() => {
    let newNotifications = 0;
    allSocketNotifications.forEach((notification) => {
      notification.notificationMessage.forEach((msg) => {
        if (msg.recent === "new") {
          newNotifications += 1;
        }
      });
    });

    // Update totalNewNotifications after the loop has finished counting
    setTotalNewNotifications(newNotifications);
  }, [allSocketNotifications]);

  // useEffect(() => {
  //   let newNotifications = 0;
  //   const notificationMap = new Map();

  //   allSocketNotifications.forEach((notification) => {
  //     const newNotificationMessages = notification.notificationMessage.filter(
  //       (msg) => msg.recent === "new"
  //     );
  //     console.log("newNotificationMessages",newNotificationMessages)
  //     if (newNotificationMessages.length === 1) {
  //       // When there is only one new message, add it to the count directly
  //       newNotifications += 1;
  //     } else if (newNotificationMessages.length > 1) {
  //       // When there are multiple new messages with the same notificationId
  //       const uniqueNewNotification = newNotificationMessages.find((msg) => {
  //         const key = `${msg.notificationId}_${msg.source}`;
  //         if (!notificationMap.has(key)) {
  //           notificationMap.set(key, msg);
  //           return true;
  //         }
  //         return false;
  //       });

  //       if (uniqueNewNotification && uniqueNewNotification.source === "socket") {
  //         newNotifications += 1;
  //       }
  //     }
  //   });

  //   setTotalNewNotifications(newNotifications);
  // }, [allSocketNotifications]);

  // console.log("totalNewNotifications", totalNewNotifications);
  const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;
  useEffect(() => {
    const handleWindowResize = () => {
      // console.log(window.innerWidth);
      dispatch(setWindowFlag({ isMobile: window.innerWidth <= 450 }));
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     // console.log("userRefetch__5");
  //     userRefetch();
  //   }, 5 * 3600 * 1000); // after 6hours

  //   return () => clearInterval(timer);
  // }, []);

  // useEffect(() => {
  //   // console.log("userRefetch");
  //   userData?.findUserById instanceof Object &&
  //     dispatch(setUser(userData?.findUserById));
  // }, [userData]);

  // useEffect(() => {
  //   if (
  //     userData?.findUserById instanceof Object &&
  //     !userData?.findUserById?.endSubscription &&
  //     !["/pay", "/billingplans", "/update_pay"].includes(currentPathname)
  //   )
  //     navigate("/billingplans");
  // }, [userData, currentPathname]);

  const declineInvite = async (inviteId, notificationId) => {
    // console.log(notificationId, "item");
    try {
      // console.log(inviteId, "item");
      let resp = await deleteInvite({
        variables: {
          input: {
            _id: inviteId,
            notificationId: notificationId,
          },
        },
      });
      if (resp?.data) {
        enqueueSnackbar(`Declined Invite Successful `, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`Error: Decline request was not successful `, {
          variant: "error",
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error: Unable to decline Invite `, { variant: "error" });
    }
  };

  const searchComponent = () => (
    <Grid
      container
      spacing={2}
      justifyContent={isMobile ? "center" : "flex-end"}
      alignItems="center"
      sx={{
        justifyContent: isMobile ? "center" : "flex-end",
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      {(!isMobile || (isMobile && clickedMenu === "Search")) && (
        <Grid item style={{ paddingLeft: isMobile && "0" }}>
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "80vw",
                  }
                : {}
            }
          >
            <NavSearch
              ref={resultsBtnRef}
              onClick={toggleResults}
              className="navigation-img"
              tabIndex="0"
              autoFocus
              style={{ width: isMobile && "95%" }}
              focus={
                (showSearch &&
                  recentSearchedData?.recentValues?.search instanceof Array) ||
                (searchInput && showResults) ||
                focusState?.srch
              }
            >
              <img
                src={SearchIcon}
                alt="share-icon"
                style={{ height: 20, margin: "0 10px" }}
              />
              <InputBase
                sx={{ flex: 1 }}
                // style={{ border: "none", outline: "0" }}
                placeholder="Search"
                onFocus={() => {
                  setFocusState({ ...focusState, srch: true });
                }}
                onBlur={() => {
                  setFocusState({ ...focusState, srch: false });
                }}
                value={searchInput}
                inputProps={{ "aria-label": "search" }}
                onClick={() => setShowSearch(!showSearch)}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  setShowResults(e.target.value.trim() !== ""); // Update showResults based on input value
                }}
              />
            </NavSearch>
            {isMobile && (
              <img src={CloseIcon} onClick={() => setClickedMenu()} />
            )}
          </div>

          {((showSearch &&
            recentSearchedData?.recentValues?.search instanceof Array) ||
            (searchInput && showResults) ||
            isMobile) && (
            <SearchResultsForm
              sx={{
                "::-webkit-scrollbar": { width: 0, height: 0 },
                overflowY: "scroll",
                zIndex: 9999,
              }}
              isMobile={isMobile}
            >
              {isMobile ||
              (showSearch &&
                recentSearchedData?.recentValues?.search instanceof Array) ||
              (searchInput &&
                searchInput?.toString()?.trim() !== "" &&
                searchedData) ? (
                <SearchList
                  arrList={searchedData?.findValues}
                  addToKey="S"
                  setOpen={setOpenVideoModal}
                  setVideoToPlay={setVideoToPlay}
                  recentArrList={recentSearchedData?.recentValues?.search}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  loading={loading}
                  isMobile={isMobile}
                  setClickedMenu={setClickedMenu}
                />
              ) : (
                <ul></ul>
              )}
              {/* </Box> */}

              {/* <SearchResultsBarBottom>
          <SearchResultsBarText onClick={handleOpenDialog}>Add Brand +</SearchResultsBarText>
        </SearchResultsBarBottom> */}
            </SearchResultsForm>
          )}
        </Grid>
      )}

      {
        <>
          {!isMobile && (
            <>
              {" "}
              <Grid item>
                <Paper
                  elevation={0}
                  sx={{
                    p: "2px 0px",
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    gap: "16px",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    {" "}
                    <IconButton
                      type="button"
                      className="navigation-img"
                      sx={{
                        p: "10px 0",
                        boxSizing: "border-box",
                        border: showNotification
                          ? "1px solid #007bf7"
                          : "1px solid #D4DAE1",
                        borderRadius: "15px",

                        boxShadow:
                          showNotification &&
                          "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                        height: "48px",
                        width: "48px",
                        ":hover": {
                          background: "transparent",
                          color: "white",
                        },
                      }}
                      aria-label="search"
                    >
                      <Badge badgeContent={totalNewNotifications} color="error">
                        <img
                          src={Notification}
                          alt="share-icon"
                          style={{
                            height: 24,
                          }}
                          ref={notificationBtnRef}
                          onClick={toggleNotification}
                          tabIndex="0"
                          autoFocus
                        />
                      </Badge>
                    </IconButton>
                    {((!isMobile && showNotification) ||
                      (isMobile && clickedMenu === "Notification")) && (
                      <>
                        {/* <h1>Notifications</h1> */}
                        <ProfileSnippetNotification
                          isMobile={isMobile}
                          onClick={handleBoxClick}
                        >
                          <Box
                            sx={{
                              borderBottom: "1px solid #ECECF2",
                              width: "100%",
                              // position: !isMobile ? "sticky" : undefined,
                            }}
                          >
                            <ProfileNotificationContainer>
                              <ProfileNotificationHead>
                                Notifications
                              </ProfileNotificationHead>
                              <ProfileNotificationHeadMark
                                onClick={markAllNotificationAsRead}
                              >
                                Mark all as read
                              </ProfileNotificationHeadMark>
                            </ProfileNotificationContainer>
                          </Box>
                          <Box
                            sx={{
                              "::-webkit-scrollbar": { width: 0, height: 0 },
                              overflowY: "scroll",
                              height: "400px",
                            }}
                          >
                            {displayedUnreadNotifications.map(
                              (notification) => (
                                <Box
                                  key={notification._id}
                                  sx={{
                                    width: "100%",
                                    marginTop: "1rem",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {Array.isArray(
                                    notification.notificationMessage
                                  ) &&
                                    notification.notificationMessage.map(
                                      (single, index) => (
                                        <React.Fragment key={single._id}>
                                          <Box
                                            sx={{
                                              backgroundColor: "#F7F7F7",
                                              width: "95%",
                                              margin: "auto",
                                            }}
                                          >
                                            {single.type === "create-video" && (
                                              <ProfileNotificationBox
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  createVideoRead(
                                                    single?.notificationId,
                                                    single?.invitationCode,
                                                    single?.videoName,
                                                    single?.videoPosition,
                                                    single?.videoRating
                                                  );
                                                  markNotificationAsRead(
                                                    single?.notificationId
                                                  );
                                                }}
                                              >
                                                <img
                                                  alt="Video"
                                                  src={Video}
                                                  style={{ height: 24 }}
                                                />

                                                <ProfileNotificationBoxColumn>
                                                  <ProfileNotificationTextTwo>
                                                    {single.message.includes(
                                                      "left a review video on"
                                                    ) ? (
                                                      <>
                                                        <ProfileNotificationText>
                                                          {
                                                            single.message.split(
                                                              " left a review video on "
                                                            )[0]
                                                          }
                                                        </ProfileNotificationText>
                                                        <ProfileNotificationTextTwo>
                                                          {" "}
                                                          left a review video on{" "}
                                                        </ProfileNotificationTextTwo>
                                                        <ProfileNotificationText>
                                                          {
                                                            single.message.split(
                                                              " left a review video on "
                                                            )[1]
                                                          }
                                                        </ProfileNotificationText>
                                                      </>
                                                    ) : (
                                                      <ProfileNotificationText>
                                                        {single.message}
                                                      </ProfileNotificationText>
                                                    )}
                                                  </ProfileNotificationTextTwo>
                                                  <ProfileDateBrand>
                                                    {/* {single.brandAccessName.map(
                                 (nameSingle, index) => (
                                   <React.Fragment>
                                     <Box
                                       sx={{
                                         display: "flex",
                                         alignItems: "center",
                                       }}
                                     >
                                       <BannerBox
                                         style={{
                                           width: "24px",
                                           height: "24px",
                                           padding: "10",
                                           borderRadius: "8px",
                                         }}
                                         backgroundColor={
                                           nameSingle.brandColor ||
                                           "#0075CE"
                                         }
                                       >
                                         <BannerBoxText
                                           style={{
                                             // height: "7px",
                                             fontSize: "12px",
                                             lineHeight: "normal",
                                           }}
                                         >
                                           {nameSingle.brand.charAt(
                                             0
                                           ) || "B"}
                                         </BannerBoxText>
                                       </BannerBox>
                                       <Box
                                         sx={{ marginLeft: "5px" }}
                                       >
                                         <ProfileNotificationTextTwo>
                                           {nameSingle.brand ||
                                             "Brand"}
                                         </ProfileNotificationTextTwo>
                                       </Box>
                                     </Box>
                                   </React.Fragment>
                                 )
                               )} */}

                                                    <Box
                                                      sx={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <img
                                                        src={Dot}
                                                        alt="dot-divider"
                                                      />
                                                    </Box>
                                                    <ProfileNotificationTextTwo>
                                                      {moment(
                                                        single.createdAt
                                                      ).fromNow()}{" "}
                                                    </ProfileNotificationTextTwo>
                                                  </ProfileDateBrand>
                                                </ProfileNotificationBoxColumn>
                                              </ProfileNotificationBox>
                                            )}
                                            {single.type ===
                                              "member-joined" && (
                                              <ProfileNotificationBox
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  const brandAccessNames =
                                                    single?.brandAccessName ||
                                                    [];

                                                  handleSetMenu(
                                                    brandAccessNames,
                                                    single.owned
                                                  );
                                                  memberJoined(
                                                    single?.notificationId
                                                  );
                                                  markNotificationAsRead(
                                                    single?.notificationId
                                                  );
                                                }}
                                              >
                                                <Avatar
                                                  alt="Avatar image"
                                                  src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${single?.thumbnail}.jpeg`}
                                                />

                                                <ProfileNotificationBoxColumn>
                                                  <ProfileNotificationTextTwo>
                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.message}
                                                    </ProfileNotificationText>{" "}
                                                    was added to{" "}
                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.brandAccessName.map(
                                                        (nameSingle, index) => (
                                                          <React.Fragment>
                                                            {nameSingle.brand}
                                                            {index <
                                                              single
                                                                .brandAccessName
                                                                .length -
                                                                1 && (
                                                              <span>
                                                                &nbsp;&amp;&nbsp;
                                                              </span>
                                                            )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                    </ProfileNotificationText>
                                                  </ProfileNotificationTextTwo>
                                                  <ProfileDateBrand>
                                                    {/* {single.brandAccessName.map(
                               (nameSingle, index) => (
                                 <React.Fragment>
                                   <Box
                                     sx={{
                                       display: "flex",
                                       alignItems: "center",
                                     }}
                                   >
                                     <BannerBox
                                       style={{
                                         width: "24px",
                                         height: "24px",
                                         padding: "10",
                                         borderRadius: "8px",
                                       }}
                                       backgroundColor={
                                         nameSingle.brandColor ||
                                         "#0075CE"
                                       }
                                     >
                                       <BannerBoxText
                                         style={{
                                           // height: "7px",
                                           fontSize: "12px",
                                           lineHeight: "normal",
                                         }}
                                       >
                                         {nameSingle.brand.charAt(
                                           0
                                         ) || "B"}
                                       </BannerBoxText>
                                     </BannerBox>
                                     <Box sx={{ marginLeft: "5px" }}>
                                       <ProfileNotificationTextTwo>
                                         {nameSingle.brand ||
                                           "Brand"}
                                       </ProfileNotificationTextTwo>
                                     </Box>
                                   </Box>
                                 </React.Fragment>
                               )
                             )} */}

                                                    <Box
                                                      sx={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <img
                                                        src={Dot}
                                                        alt="dot-divider"
                                                      />
                                                    </Box>
                                                    <ProfileNotificationTextTwo>
                                                      {moment(
                                                        single.createdAt
                                                      ).fromNow()}{" "}
                                                    </ProfileNotificationTextTwo>
                                                  </ProfileDateBrand>
                                                </ProfileNotificationBoxColumn>
                                              </ProfileNotificationBox>
                                            )}
                                            {single.type === "invite" && (
                                              <ProfileNotificationBox>
                                                <Avatar
                                                  alt="Avatar image"
                                                  src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${single?.thumbnail}.jpeg`}
                                                />

                                                <ProfileNotificationBoxColumn>
                                                  <ProfileNotificationTextTwo>
                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.message}
                                                    </ProfileNotificationText>{" "}
                                                    has invited you the
                                                    following brand(s){" "}
                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.brandAccessName.map(
                                                        (nameSingle, index) => (
                                                          <React.Fragment>
                                                            {nameSingle.brand}
                                                            {index <
                                                              single
                                                                .brandAccessName
                                                                .length -
                                                                1 && (
                                                              <span>
                                                                &nbsp;&amp;&nbsp;
                                                              </span>
                                                            )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                    </ProfileNotificationText>
                                                  </ProfileNotificationTextTwo>
                                                  <ProfileDateBrand>
                                                    {/* {single.brandAccessName.map(
                               (nameSingle, index) => (
                                 <React.Fragment>
                                   <Box
                                     sx={{
                                       display: "flex",
                                       alignItems: "center",
                                     }}
                                   >
                                     <BannerBox
                                       style={{
                                         width: "24px",
                                         height: "24px",
                                         padding: "10",
                                         borderRadius: "8px",
                                       }}
                                       backgroundColor={
                                         nameSingle.brandColor ||
                                         "#0075CE"
                                       }
                                     >
                                       <BannerBoxText
                                         style={{
                                           // height: "7px",
                                           fontSize: "12px",
                                           lineHeight: "normal",
                                         }}
                                       >
                                         {nameSingle.brand.charAt(
                                           0
                                         ) || "B"}
                                       </BannerBoxText>
                                     </BannerBox>
                                     <Box sx={{ marginLeft: "5px" }}>
                                       <ProfileNotificationTextTwo>
                                         {nameSingle.brand ||
                                           "Brand"}
                                       </ProfileNotificationTextTwo>
                                     </Box>
                                   </Box>
                                 </React.Fragment>
                               )
                             )} */}

                                                    <Box
                                                      sx={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <img
                                                        src={Dot}
                                                        alt="dot-divider"
                                                      />
                                                    </Box>
                                                    <ProfileNotificationTextTwo>
                                                      {moment(
                                                        single.createdAt
                                                      ).fromNow()}{" "}
                                                    </ProfileNotificationTextTwo>
                                                  </ProfileDateBrand>
                                                  <StyledButtonContainer>
                                                    <PreviousButton
                                                      style={{
                                                        marginRight: "1rem",
                                                      }}
                                                      onClick={() => {
                                                        declineInvite(
                                                          single?.videoName,
                                                          single?.notificationId
                                                        );
                                                        markNotificationAsRead(
                                                          single?.notificationId
                                                        );
                                                      }}
                                                    >
                                                      <PreviousButtonText>
                                                        Decline
                                                      </PreviousButtonText>
                                                    </PreviousButton>
                                                    <ColorButton
                                                      width="134px"
                                                      title="Accept"
                                                      background="##1A1B24"
                                                      onClick={() => {
                                                        handleAcceptInvitation(
                                                          single?.invitationCode,
                                                          single?.notificationId
                                                        );
                                                        markNotificationAsRead(
                                                          single?.notificationId
                                                        );
                                                      }}
                                                    >
                                                      {loading
                                                        ? "Loading.."
                                                        : "Accept"}
                                                    </ColorButton>
                                                  </StyledButtonContainer>
                                                </ProfileNotificationBoxColumn>
                                              </ProfileNotificationBox>
                                            )}
                                            {single.type === "accept" && (
                                              <ProfileNotificationBox>
                                                <Avatar
                                                  alt="Avatar image"
                                                  src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${single?.thumbnail}.jpeg`}
                                                />

                                                <ProfileNotificationBoxColumn>
                                                  <ProfileNotificationTextTwo>
                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.message}{" "}
                                                    </ProfileNotificationText>

                                                    <ProfileNotificationText
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {single.brandAccessName.map(
                                                        (nameSingle, index) => (
                                                          <React.Fragment>
                                                            {nameSingle.brand}
                                                            {index <
                                                              single
                                                                .brandAccessName
                                                                .length -
                                                                1 && (
                                                              <span>
                                                                &nbsp;&amp;&nbsp;
                                                              </span>
                                                            )}
                                                          </React.Fragment>
                                                        )
                                                      )}
                                                    </ProfileNotificationText>
                                                  </ProfileNotificationTextTwo>
                                                  <ProfileDateBrand>
                                                    {/* {single.brandAccessName.map(
                                         (nameSingle, index) => (
                                           <React.Fragment>
                                             <Box
                                               sx={{
                                                 display: "flex",
                                                 alignItems: "center",
                                               }}
                                             >
                                               <BannerBox
                                                 style={{
                                                   width: "24px",
                                                   height: "24px",
                                                   padding: "10",
                                                   borderRadius: "8px",
                                                 }}
                                                 backgroundColor={
                                                   nameSingle.brandColor ||
                                                   "#0075CE"
                                                 }
                                               >
                                                 <BannerBoxText
                                                   style={{
                                                     // height: "7px",
                                                     fontSize: "12px",
                                                     lineHeight: "normal",
                                                   }}
                                                 >
                                                   {nameSingle.brand.charAt(
                                                     0
                                                   ) || "B"}
                                                 </BannerBoxText>
                                               </BannerBox>
                                               <Box sx={{ marginLeft: "5px" }}>
                                                 <ProfileNotificationTextTwo>
                                                   {nameSingle.brand ||
                                                     "Brand"}
                                                 </ProfileNotificationTextTwo>
                                               </Box>
                                             </Box>
                                           </React.Fragment>
                                         )
                                       )} */}

                                                    <Box
                                                      sx={{
                                                        marginRight: "15px",
                                                      }}
                                                    >
                                                      <img
                                                        src={Dot}
                                                        alt="dot-divider"
                                                      />
                                                    </Box>
                                                    <ProfileNotificationTextTwo>
                                                      {moment(
                                                        single.createdAt
                                                      ).fromNow()}{" "}
                                                    </ProfileNotificationTextTwo>
                                                  </ProfileDateBrand>
                                                </ProfileNotificationBoxColumn>
                                              </ProfileNotificationBox>
                                            )}
                                          </Box>
                                        </React.Fragment>
                                      )
                                    )}
                                  {/* {notification.type === "invite" && (
                         <InvitationsTable
                           invitations={invitations ? invitations : []}
                         />
                       )}

             {console.log(invitations?.type)}
             {invitations?.type === "invite" && (
               <ColorButton
                 width="134px"
                 title="Accept"
                 background="#BAA9FE"
                 onClick={() =>
                   handleAcceptInvitation(
                     single?.invitationCode,
                                   single?.notificationId
                   )
                 }
               >
                 {loading ? "Loading.." : "Accept"}
               </ColorButton>
             )} */}
                                </Box>
                              )
                            )}
                            {displayedReadNotifications.map((notification) => (
                              <Box
                                key={notification._id}
                                sx={{
                                  width: "100%",
                                  marginTop: "1rem",
                                  backgroundColor: "white",
                                }}
                              >
                                {Array.isArray(
                                  notification.notificationMessage
                                ) &&
                                  notification.notificationMessage.map(
                                    (single, index) => (
                                      <React.Fragment key={single._id}>
                                        <Box
                                          sx={{
                                            backgroundColor: "white",
                                            width: "95%",
                                            margin: "auto",
                                          }}
                                        >
                                          {single.type === "create-video" && (
                                            <ProfileNotificationBox
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                createVideoRead(
                                                  single?.notificationId,
                                                  single?.invitationCode,
                                                  single?.videoName,
                                                  single?.videoPosition,
                                                  single?.videoRating
                                                );
                                              }}
                                            >
                                              <img
                                                alt="Video"
                                                src={Video}
                                                style={{ height: 24 }}
                                              />

                                              <ProfileNotificationBoxColumn>
                                                <ProfileNotificationTextTwo>
                                                  {single.message.includes(
                                                    "left a review video on"
                                                  ) ? (
                                                    <>
                                                      <ProfileNotificationText>
                                                        {
                                                          single.message.split(
                                                            " left a review video on "
                                                          )[0]
                                                        }
                                                      </ProfileNotificationText>
                                                      <ProfileNotificationTextTwo>
                                                        {" "}
                                                        left a review video on{" "}
                                                      </ProfileNotificationTextTwo>
                                                      <ProfileNotificationText>
                                                        {
                                                          single.message.split(
                                                            " left a review video on "
                                                          )[1]
                                                        }
                                                      </ProfileNotificationText>
                                                    </>
                                                  ) : (
                                                    <ProfileNotificationText>
                                                      {single.message}
                                                    </ProfileNotificationText>
                                                  )}
                                                </ProfileNotificationTextTwo>
                                                <ProfileDateBrand>
                                                  {/* {single.brandAccessName.map(
                                 (nameSingle, index) => (
                                   <React.Fragment>
                                     <Box
                                       sx={{
                                         display: "flex",
                                         alignItems: "center",
                                       }}
                                     >
                                       <BannerBox
                                         style={{
                                           width: "24px",
                                           height: "24px",
                                           padding: "10",
                                           borderRadius: "8px",
                                         }}
                                         backgroundColor={
                                           nameSingle.brandColor ||
                                           "#0075CE"
                                         }
                                       >
                                         <BannerBoxText
                                           style={{
                                             // height: "7px",
                                             fontSize: "12px",
                                             lineHeight: "normal",
                                           }}
                                         >
                                           {nameSingle.brand.charAt(
                                             0
                                           ) || "B"}
                                         </BannerBoxText>
                                       </BannerBox>
                                       <Box
                                         sx={{ marginLeft: "5px" }}
                                       >
                                         <ProfileNotificationTextTwo>
                                           {nameSingle.brand ||
                                             "Brand"}
                                         </ProfileNotificationTextTwo>
                                       </Box>
                                     </Box>
                                   </React.Fragment>
                                 )
                               )} */}

                                                  <Box
                                                    sx={{
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <img
                                                      src={Dot}
                                                      alt="dot-divider"
                                                    />
                                                  </Box>
                                                  <ProfileNotificationTextTwo>
                                                    {moment(
                                                      single.createdAt
                                                    ).fromNow()}{" "}
                                                  </ProfileNotificationTextTwo>
                                                </ProfileDateBrand>
                                              </ProfileNotificationBoxColumn>
                                            </ProfileNotificationBox>
                                          )}
                                          {single.type === "member-joined" && (
                                            <ProfileNotificationBox
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                const brandAccessNames =
                                                  single?.brandAccessName || [];

                                                handleSetMenu(
                                                  brandAccessNames,
                                                  single.owned
                                                );
                                              }}
                                            >
                                              <Avatar
                                                alt="Avatar image"
                                                src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${single?.thumbnail}.jpeg`}
                                              />

                                              <ProfileNotificationBoxColumn>
                                                <ProfileNotificationTextTwo>
                                                  <ProfileNotificationText
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {single.message}
                                                  </ProfileNotificationText>{" "}
                                                  was added to{" "}
                                                  <ProfileNotificationText
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {single.brandAccessName.map(
                                                      (nameSingle, index) => (
                                                        <React.Fragment>
                                                          {nameSingle.brand}
                                                          {index <
                                                            single
                                                              .brandAccessName
                                                              .length -
                                                              1 && (
                                                            <span>
                                                              &nbsp;&amp;&nbsp;
                                                            </span>
                                                          )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                  </ProfileNotificationText>
                                                </ProfileNotificationTextTwo>
                                                <ProfileDateBrand>
                                                  {/* {single.brandAccessName.map(
                               (nameSingle, index) => (
                                 <React.Fragment>
                                   <Box
                                     sx={{
                                       display: "flex",
                                       alignItems: "center",
                                     }}
                                   >
                                     <BannerBox
                                       style={{
                                         width: "24px",
                                         height: "24px",
                                         padding: "10",
                                         borderRadius: "8px",
                                       }}
                                       backgroundColor={
                                         nameSingle.brandColor ||
                                         "#0075CE"
                                       }
                                     >
                                       <BannerBoxText
                                         style={{
                                           // height: "7px",
                                           fontSize: "12px",
                                           lineHeight: "normal",
                                         }}
                                       >
                                         {nameSingle.brand.charAt(
                                           0
                                         ) || "B"}
                                       </BannerBoxText>
                                     </BannerBox>
                                     <Box sx={{ marginLeft: "5px" }}>
                                       <ProfileNotificationTextTwo>
                                         {nameSingle.brand ||
                                           "Brand"}
                                       </ProfileNotificationTextTwo>
                                     </Box>
                                   </Box>
                                 </React.Fragment>
                               )
                             )} */}

                                                  <Box
                                                    sx={{
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <img
                                                      src={Dot}
                                                      alt="dot-divider"
                                                    />
                                                  </Box>
                                                  <ProfileNotificationTextTwo>
                                                    {moment(
                                                      single.createdAt
                                                    ).fromNow()}{" "}
                                                  </ProfileNotificationTextTwo>
                                                </ProfileDateBrand>
                                              </ProfileNotificationBoxColumn>
                                            </ProfileNotificationBox>
                                          )}

                                          {single.type === "accept" && (
                                            <ProfileNotificationBox>
                                              <Avatar
                                                alt="Avatar image"
                                                src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${single?.thumbnail}.jpeg`}
                                              />

                                              <ProfileNotificationBoxColumn>
                                                <ProfileNotificationTextTwo>
                                                  <ProfileNotificationText
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {single.message}{" "}
                                                  </ProfileNotificationText>

                                                  <ProfileNotificationText
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {single.brandAccessName.map(
                                                      (nameSingle, index) => (
                                                        <React.Fragment>
                                                          {nameSingle.brand}
                                                          {index <
                                                            single
                                                              .brandAccessName
                                                              .length -
                                                              1 && (
                                                            <span>
                                                              &nbsp;&amp;&nbsp;
                                                            </span>
                                                          )}
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                  </ProfileNotificationText>
                                                </ProfileNotificationTextTwo>
                                                <ProfileDateBrand>
                                                  {/* {single.brandAccessName.map(
                                         (nameSingle, index) => (
                                           <React.Fragment>
                                             <Box
                                               sx={{
                                                 display: "flex",
                                                 alignItems: "center",
                                               }}
                                             >
                                               <BannerBox
                                                 style={{
                                                   width: "24px",
                                                   height: "24px",
                                                   padding: "10",
                                                   borderRadius: "8px",
                                                 }}
                                                 backgroundColor={
                                                   nameSingle.brandColor ||
                                                   "#0075CE"
                                                 }
                                               >
                                                 <BannerBoxText
                                                   style={{
                                                     // height: "7px",
                                                     fontSize: "12px",
                                                     lineHeight: "normal",
                                                   }}
                                                 >
                                                   {nameSingle.brand.charAt(
                                                     0
                                                   ) || "B"}
                                                 </BannerBoxText>
                                               </BannerBox>
                                               <Box sx={{ marginLeft: "5px" }}>
                                                 <ProfileNotificationTextTwo>
                                                   {nameSingle.brand ||
                                                     "Brand"}
                                                 </ProfileNotificationTextTwo>
                                               </Box>
                                             </Box>
                                           </React.Fragment>
                                         )
                                       )} */}

                                                  <Box
                                                    sx={{
                                                      marginRight: "15px",
                                                    }}
                                                  >
                                                    <img
                                                      src={Dot}
                                                      alt="dot-divider"
                                                    />
                                                  </Box>
                                                  <ProfileNotificationTextTwo>
                                                    {moment(
                                                      single.createdAt
                                                    ).fromNow()}{" "}
                                                  </ProfileNotificationTextTwo>
                                                </ProfileDateBrand>
                                                <StyledButtonContainer>
                                                  <PreviousButton
                                                    style={{
                                                      marginRight: "1rem",
                                                    }}
                                                    onClick={() =>
                                                      declineInvite(
                                                        single?.videoName,
                                                        single?.notificationId
                                                      )
                                                    }
                                                  >
                                                    <PreviousButtonText>
                                                      Decline
                                                    </PreviousButtonText>
                                                  </PreviousButton>
                                                  <ColorButton
                                                    width="134px"
                                                    title="Accept"
                                                    background="#1A1B24"
                                                    onClick={() =>
                                                      handleAcceptInvitation(
                                                        single?.invitationCode,
                                                        single?.notificationId
                                                      )
                                                    }
                                                  >
                                                    {loading
                                                      ? "Loading.."
                                                      : "Accept"}
                                                  </ColorButton>
                                                </StyledButtonContainer>
                                              </ProfileNotificationBoxColumn>
                                            </ProfileNotificationBox>
                                          )}
                                        </Box>
                                      </React.Fragment>
                                    )
                                  )}
                                {/* {notification.type === "invite" && (
               <InvitationsTable
                 invitations={invitations ? invitations : []}
               />
             )}

             {console.log(invitations?.type)}
             {invitations?.type === "invite" && (
               <ColorButton
                 width="134px"
                 title="Accept"
                 background="#BAA9FE"
                 onClick={() =>
                   handleAcceptInvitation(
                     single?.invitationCode,
                                   single?.notificationId
                   )
                 }
               >
                 {loading ? "Loading.." : "Accept"}
               </ColorButton>
             )} */}
                              </Box>
                            ))}
                            {/* {displayedNotifications.reduce(
                                  (count, notification) =>
                                    count + notification.notificationMessage.length,
                                  0
                                ) <
                                  sortedNotifications.reduce(
                                    (count, notification) =>
                                      count + notification.notificationMessage.length,
                                    0
                                  ) && (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      paddingBottom: "1rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <SeeMore
                                      style={{ top: "0%" }}
                                      onClick={loadMoreNotifications}
                                    >
                                      <SeeMoreText>Load More</SeeMoreText>
                                    </SeeMore>
                                  </Box>
                                )} */}
                          </Box>
                        </ProfileSnippetNotification>
                      </>
                    )}
                  </div>

                  {hasOrganizationData && <IconButton
                    type="button"
                    sx={{
                      p: "10px 0",
                      boxSizing: "border-box",
                      borderRadius: "15px",
                      border: "1px solid  #D4DAE1 ",
                      cursor: "pointer",
                      height: "48px",
                      width: "48px",
                      ":hover": {
                        background: "transparent",
                        color: "white",
                      },
                    }}
                    aria-label="share"
                    onClick={handleShare}
                    className="navigation-img"
                  >
                    <img
                      src={ShareIcon}
                      alt="share-icon"
                      style={{
                        height: 24,
                      }}
                      tabIndex="0"
                      autoFocus
                    />
                  </IconButton>}

                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                  <Box
                    sx={{
                      // border: "1px solid #D4DAE1",
                      // borderRadius: "15px",
                      // padding: "4.8px 12px",
                      // width: "90px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "16px",
                      border: showProfile
                        ? "1px solid #007bf7"
                        : "1px solid  #D4DAE1",
                      padding: "6.5px 12px",
                      borderRadius: "12px",
                      boxShadow:
                        showProfile &&
                        "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      boxSizing: "border-box",
                      height: "48px",
                      ":hover": {
                        border: showProfile
                          ? "1px solid #007bf7"
                          : " 1px solid #1A1B24",
                        borderRadius: "12px",
                        //15px
                      },
                      ":focus": {
                        border: " 1px solid #007bf7",
                        borderRadius: "12px",
                      },
                    }}
                    ref={profileBtnRef}
                    onClick={toggleProfile}
                  >
                    <IconButton
                      size="small"
                      sx={{
                        padding: "0px",
                        height: "30px",
                        width: "30px",
                        ":hover": {
                          background: "transparent",
                          color: "white",
                        },
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        {userData?.findUserById?.thumbnail ? (
                          <Avatar
                            alt="Avatar image"
                            src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${userData?.findUserById?.thumbnail}.jpeg`}
                            sx={{ height: "30px", width: "30px" }}
                          />
                        ) : (
                          <Avatar
                            alt="Avatar image"
                            src={AvatarImage}
                            sx={{ height: "30px", width: "30px" }}
                          />
                        )}
                        {userData?.findUserById && (
                          <div
                            style={{
                              backgroundColor: "#4eb708",
                              border: "1px solid",
                              borderColor: "#f8fafb",
                              borderRadius: "4.12px",
                              height: "8px",
                              left: "21px",
                              position: "absolute",
                              top: "-1px",
                              width: "8px",
                            }}
                          />
                        )}
                      </div>
                    </IconButton>

                    <IconButton
                      color="primary"
                      sx={{
                        p: "0px",
                        ":hover": {
                          background: "transparent",
                          color: "white",
                        },
                      }}
                      aria-label="directions"
                    >
                      <KeyboardArrowDownIcon sx={{ color: "black" }} />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
              {showProfile && (
                <ProfileSnippet>
                  <ProfileImageContainer>
                    {userData?.findUserById?.thumbnail ? (
                      <ProfileImage>
                        <Avatar
                          alt="Avatar image"
                          src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${userData?.findUserById?.thumbnail}.jpeg`}
                        />
                      </ProfileImage>
                    ) : (
                      <ProfileImage>
                        <Avatar alt="Avatar image" src={AvatarImage} />
                      </ProfileImage>
                    )}

                    <ProfileImageContent>
                      <ProfileImageContentHeader>
                        {userData?.findUserById?.name}
                      </ProfileImageContentHeader>
                      <ProfileImageContentText>
                        {userData?.findUserById?.email}
                      </ProfileImageContentText>
                    </ProfileImageContent>
                  </ProfileImageContainer>
                  <Divider sx={{ marginTop: "1rem" }} />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "24px",
                      padding: "0 16px 16px 16px",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                    }}
                  >
                    <List
                      component="nav"
                      sx={{ width: "100%" }}
                      aria-label="main mailbox folders"
                    >
                      <SListItemButton onClick={() => navigate("/profile")}>
                        <SListItemIcon>
                          <img src={Profile} alt="profile-icon" />
                        </SListItemIcon>
                        <ListItemText primary="Profile" />
                      </SListItemButton>
                      <SListItemButton onClick={() => navigate("/teammembers")}>
                        <SListItemIcon>
                          <img src={TeamMembers} alt="profile-icon" />
                        </SListItemIcon>
                        <ListItemText primary="Team Members" />
                      </SListItemButton>

                     {hasOrganizationData && <SListItemButton onClick={handleShare}>
                        <SListItemIcon>
                          <img src={InviteMembers} alt="profile-icon" />
                        </SListItemIcon>
                        <ListItemText primary="Invite Members" />
                      </SListItemButton>}

                      {/* <SListItemButton>
            <SListItemIcon>
              <img src={Trash} alt="profile-icon" />
            </SListItemIcon>
            <ListItemText primary="Trash" />
          </SListItemButton> */}

                      <SListItemButton onClick={handleLogout}>
                        <SListItemIcon>
                          <img src={LogoutIcon} alt="profile-icon" />
                        </SListItemIcon>
                        <ListItemText primary="Log out" />
                      </SListItemButton>
                    </List>
                    <ProfileButton onClick={() => navigate("/billingplans")}>
                      <img
                        src={Stars}
                        alt="stars"
                        style={{ height: 20, width: 20 }}
                      />
                      <ProfileButtonText>Manage Subscription</ProfileButtonText>
                    </ProfileButton>
                  </Box>
                </ProfileSnippet>
              )}
            </>
          )}
          {isMobile && clickedMenu === "Profile" && (
            <MobileProfile userData={userData} handleLogout={handleLogout} />
          )}
        </>
      }
    </Grid>
  );

  // useEffect(() => {
  //   if (process.env.REACT_APP_GOOGLE_MEASUREMENT_ID) {
  //     const unlisten = () => {
  //       // Set UTM parameters
  //       const utmParams = {
  //         utm_source: "billboard", //"newsletter", // replace with your source
  //         utm_medium: "banner", //"email", // replace with your medium
  //         utm_campaign: "recrowdly_sales", // replace with your campaign
  //         // utm_term: "termGB", // optional: replace with your term
  //         // utm_content: "contentofrecrowd---ly", // optional: replace with your content
  //       };

  //       // ReactGA.set(utmParams); // Set UTM parameters

  //       // ReactGA.event({
  //       //   name: "page_view",
  //       //   params: utmParams,
  //       // });

  //       let arr = [];
  //       for (let elm in utmParams) {
  //         arr.push(`${elm}=${utmParams[elm]}`);
  //       }
  //       let strPath = `${location.pathname}/?${arr.join("&")}`;
  //       console.log(location, "Location", strPath);
  //       // Record a pageview for the new page
  //       ReactGA.send({
  //         hitType: "pageview",
  //         // page: location.pathname,
  //         page: strPath,
  //         title: "Custom Recrowdly LocationTesting__",
  //       });
  //     };

  //     return () => {
  //       unlisten();
  //     };
  //   }
  // }, [location]);

  return (
    <DashBoardContainer>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      {/* <Box
        sx={{
          height: "8px",

          background: "linear-gradient(90deg, #B8A9FF 0.28%, #ECE7FE 97.67%)",
          flex: "none",
          order: "0",
          flexGrow: "0",
        }}
      ></Box> */}
      <NavContainer>
        <NavContainerContent>
          {/* <NavContainerContentOne isMobile={isMobile}> */}
          {/* </NavContainerContentOne> */}
          <NavHeaderLogoText isMobile={isMobile}>
            <NavImage isMobile={isMobile}>
              <Link to="/" style={{ textDecoration: "none" }}>
                {" "}
                <img src={RecrowdlyLogo} style={{ width: "100%" }}></img>{" "}
              </Link>
            </NavImage>

            <Box sx={{ display: "flex" }}>
              <NavDivider></NavDivider>
              {/* <NavTitleText href={`/`}>Dashboard</NavTitleText> */}
            </Box>
            <NavMenuButton />
          </NavHeaderLogoText>

          {isMobile ? (
            <BottomPopup
              containerStyle={{
                height: "calc(100vh - 66px)",
                top: 0,
                borderRadius: "0",
              }}
              bodyContainerStyle={{ height: "calc(100vh - 66px)" }}
              isOpen={["Search", "Notification", "Profile"].includes(
                clickedMenu
              )}
              setIsOpen={() => setClickedMenu()}
            >
              {searchComponent()}
            </BottomPopup>
          ) : (
            <NavContainerContentTwo>{searchComponent()}</NavContainerContentTwo>
          )}
        </NavContainerContent>
      </NavContainer>

      {/* <AddBrandDialog  openDialog={dialogOpen} onClose={() => setDialogOpen(false)} /> */}

      <DashBoardContainer>
        {/* {children} */}
        <Outlet />
      </DashBoardContainer>
      <VideoModal
        open={openVideoModal}
        setOpen={setOpenVideoModal}
        videoUrl={videoToPlay}
        showOnlyPlay
      />
      <BottomMenu
        isMobile={isMobile}
        clickedMenu={clickedMenu}
        setClickedMenu={setClickedMenu}
      />
    </DashBoardContainer>
  );
}

export default Layout;
