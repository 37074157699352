import styled from "@emotion/styled";
import { Button, Typography,Box } from "@mui/material";


export const ModalContainer = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    width: 40rem;
    min-height:30rem;
    background: white;
    border-radius: 0.5rem;
`;

export const StyledFormLabel = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
  }
`;

export const ModalContainerCreate = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px;
    width:46rem;
    background: white;
    border-radius: 0.5rem;
`;
export const DeleteModalContainer = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25rem;
    background: white;
    border-radius: 0.5rem;
`;


export const StyledSubmitButton = styled(Button)`
  background: #070b0d;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  // border-radius: 7px;
  border-radius: 15px;
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  height: 46px;
  font-family: Dm Sans !important;
  &:hover {
    background: #1a1b24;
  }
`;
export const StyledSectionButton = styled(Button)`
  background: none;
  box-shadow: none;
  // border-radius: 7px;
  border-radius: 15px;
  color: ${(props)=>props.disabled?"#E5E3E3":"#1a1b24"};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  padding-left:1.48rem;
  padding-right:1.48rem;
  border:1px solid ${(props)=>props.disabled?"#E5E3E3":"#1a1b24"};
  font-family: Switzer;
  &:hover {
    background: none;
  }
`;

export const FormContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CreateButton = styled(Button)`
  height: 40px;
  ${({color})=>color==="primary"?"background-color: #0c97d1;":`background-color: white;`}
  border-radius: 5px;
  box-sizing: border-box;
  padding: 9px 24px;
  text-transform: capitalize;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${(props)=>props.hasBorder&&" border: #101010 .125rem solid;"}
  ${({color})=>color==="primary"?"color: white;":`color: #101010;`}
  &:hover {
    ${({color})=>color==="primary"?"background-color: #0c97d1;":`background-color: white;`}
    ${({color})=>color==="primary"?"color: white;":`color: #101010;`}
  }
`;