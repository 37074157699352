import React from 'react'
import Lottie from "react-lottie"
import animationData from "../../src/lotties/Loader Animation1.json"
import animationDataHighFive from "../../src/lotties/HighFive.json"
import animationDataTut from "../../src/lotties/tutorial.json"

export default function  SpinnerBig({mode,big}) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: mode === "H5" ? animationDataHighFive : mode === "TUT"? animationDataTut : animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

return (
  <div style={{width:'100%',minHeight:big?'90vh':undefined, height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Lottie 
    options={defaultOptions}
      height={!mode && 400}
      width={!mode && 400}
      
    />
  </div>
);
}
