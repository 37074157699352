import {
  Box,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  BottomText,
  FullAnalyticsHeader,
  FullAnalyticsHeaderTwo,
  InsightButton,
  NavSearch,
  SortBoxItem,
  SortContainer,
  SortText,
  SubTableDiv,
  SubTableText,
  TableAnalytics,
  TableAnalyticsDirection,
  TableAnalyticsOne,
  TableAnalyticsOnesSubText,
  TableAnalyticsOneText,
  TableAnalyticsSort,
  TableAnalyticsTwo,
  TableDiv,
  TopText,
  UserDetailsButtonGroup,
  UserDetailsButtonText,
  UserDetailsHeader,
  UserDetailsImage,
  UserDetailsStar,
  UserDetailsStarRating,
  UserDetailsText,
  UserTableDetails,
} from "../pages/UserDashboard/styled";

import Ascending from "../assets/icons/Ascending.svg";
import MarkUp from "../assets/icons/MarkUp.svg";
import ArrowDown from "../assets/icons/ArrowDown.svg";
import SearchIcon from "../assets/icons/SearchIcon.svg";
import Trash from "../assets/icons/Trash.svg";
import ShareIcon from "../assets/icons/ShareIcon.svg";
import Download from "../assets/icons/Download.svg";
import CloseIcon from "../assets/icons/SectionClose.svg";
import EditIcon from "../assets/icons/EditIcon.svg";
import Descending from "../assets/icons/Descending.svg";
import StarRating from "../assets/icons/StarRating.svg";
import EmptySearch from "../assets/images/EmptySearch.svg";
import { GET_BRAND, GET_WIDGET_BRAND } from "../graphql/queries/brandQueries";
import Spinner from "./Spinner";

import { useMutation, useQuery } from "@apollo/client";
import {
  APPROVED_VIDEOS_COUNT,
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  GET_SINGULAR_VIDEO,
  GET_VIDEOS,
  GET_VIDEOS_BY_BRAND,
  NEW_VIDEOS_COUNT,
} from "../graphql/queries/videosQueries";
import {
  APPROVE_VIDEO,
  DELETE_VIDEO,
  DISAPPROVE_VIDEO,
  SAVE_ANALYTIC,
} from "../graphql/mutations/videoMutation";
import { VideoSettingsSharp } from "@mui/icons-material";
import {
  DeleteButton,
  DeleteButtonText,
  DeleteHeader,
  DeleteText,
  OptionsText,
  SeeMore,
  SeeMoreText,
} from "../pages/Organizations/styled";
import Modal from "react-modal";
import SectionComp from "./Main/SectionComp";
import axios from "axios";
import { ModalContent, ModalCust, Wrapper } from "./Main/SectionComp/styled";
import PlayVideo from "./Main/SectionComp/PlayVideo";
import { vidFormat } from "../utils/format";
import VideoComp from "./Main/SectionComp/videoComp";
import { SearchBox } from "./SearchList/styled";
import io from "socket.io-client";
import EllipsisLinkBrand from "./EllipsisLink/EllipsisLink";
import { socket } from "../App";
import { Snackbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DeleteModal from "./DeleteModal";
import ShareModal from "./ShareModal";
import EditVideo from "./EditVideo";
export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    width: "93px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Dm Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: " 15px",
  },
}));

function DashboardTable({
  id,
  name,
  brandID,
  startDate,
  endDate,
  itemsRemoval,
  isMobile,
}) {
  // const navigate = useNavigate();
  // Get videos by brand ids
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoId, setVideoId] = useState("");
  const [sortDirection, setSortDirection] = useState("descending");
  const [open, setOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [showSort, setShowSort] = useState(false);
  const [fullCapacity, setFullCapacity] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [selectedSortText, setSelectedSortText] = useState("Date Created");
  const [clicked, setClicked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [locationData, setLocationData] = useState({});
  const [timeClickedStart, setTimeClickedStart] = useState(0);
  const [focusState, setFocusState] = useState({});
  const [timeClickedEnd, setTimeClickedEnd] = useState(0);
  // const [open, setOpen] = useState(false);
  const [playVid, setPlayVid] = useState(false);
  const [vidIndex, setVidIndex] = useState(-1);
  const [videoPlayedStartTime, setVideoPlayedStartTime] = useState(0);
  const [videoPausedTime, setVideoPausedTime] = useState(0);
  const [videoPausedStartTime, setVideoPausedStartTime] = useState(0);

  const sortRef = useRef();
  const handleOpen = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setOpen(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const {
    loading: approvedLoading,
    error: approvedError,
    data: approvedData,
    refetch: refetchApprovedVideoCount,
  } = useQuery(APPROVED_VIDEOS_COUNT, {
    variables: { brandId: brandID },

    fetchPolicy: "cache-and-network",
  });

  const {
    loading: disapprovedLoading,
    error: disapprovedError,
    data: disapprovedData,
    refetch: refetchDisApprovedVideoCount,
  } = useQuery(DISAPPROVED_VIDEOS_COUNT, {
    variables: { brandId: brandID },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: newLoading,
    error: newError,
    data: newData,
  } = useQuery(NEW_VIDEOS_COUNT, {
    variables: { brandId: brandID, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading,
    error,
    data,
    refetch: refetchVideoByBrand,
  } = useQuery(GET_VIDEOS_BY_BRAND, {
    variables: { brandId: id },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: videosLoading,
    error: videosError,
    data: videosData,
    refetch: refetchVideos,
  } = useQuery(GET_VIDEOS, {
    variables: { brandId: id },
  });

  const {
    data: dataBrand,
    loading: brandLoading,
    refetch: refetchWidgetBrand,
  } = useQuery(GET_WIDGET_BRAND, {
    variables: { input: { brandId: id } },
  });
  const [deleteVideo] = useMutation(DELETE_VIDEO);

  // const handleDeleteVideo = () => {
  //   deleteVideo({
  //     variables: { videoId:videoId },
  //     update(cache) {
  //       console.log("exixtingVideos",id)
  //       const existingData = cache.readQuery({
  //         query: GET_VIDEOS_BY_BRAND,
  //         variables: { brandId: id },
  //       });
  //       console.log("exixtingVideos",existingData)
  //       const newData = existingData.videosByBrandId.filter(
  //         (video) => video.videoId !== videoId
  //       );
  //       console.log("newData",newData)
  //       cache.writeQuery({
  //         query: GET_VIDEOS_BY_BRAND,
  //         variables: { brandId: id },
  //         data: { videosByBrandId: newData },
  //       });
  //     },
  //   });
  // };
  const reload = async () => {
    await refetchWidgetBrand();
    await refetchVideos();
    await refetchVideoByBrand();
    await refetchDisApprovedVideoCount();
    await refetchApprovedVideoCount();
  };
  const handleDeleteVideo = async () => {
    try {
      await deleteVideo({ variables: { videoId: videoId } });
      await reload();
    } catch {}
  };
  const handleDelete = async () => {
    try {
      await handleDeleteVideo();
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpenModal = (videoId) => {
    setOpenModal(true);
    setVideoId(videoId);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenShareModal = (videoUrl) => {
    setOpenShareModal(true);
    setVideoUrl(videoUrl);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };
  const handleModalConfirm = async () => {
    await handleDelete();
    handleCloseModal();
    setOpenSnackbar(true);
    setSnackbarMessage("Video deleted successfully");
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  // const [saveAnalytic] = useMutation(SAVE_ANALYTIC, {
  //   onCompleted(videosData) {
  //     console.log(videosData, "success");
  //   },
  // });
  function downloadVideo(source) {
    fetch(source)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");

        // Extract the filename from the URL
        const filename = source.substring(source.lastIndexOf("/") + 1);
        a.href = url;
        a.download = filename; // Use the extracted filename
        a.click();
      });
  }

  // onPlay displays a video with rectangle shape and continer moved to the center
  let brandProps = {}; //{ position: "top-right", backgroundColor: "grey" };
  let arr =
    videosData?.approvedVideosByBrandIdAndTrue instanceof Array
      ? videosData?.approvedVideosByBrandIdAndTrue.map((el) => {
          return {
            url: el?.url,
            videoId: el?.videoId,
            brand: el?.brand,
            position: el?.position,
            name: el?.name,
            rating: el?.rating,
            duration: el?.duration,
          };
        })
      : [];

  brandProps =
    dataBrand?.widgetBrand instanceof Object
      ? dataBrand?.widgetBrand
      : brandProps;

  // let ind = 0

  // Listen for the "invitation" event
  // socket.on("Videos", (data) => {
  //   // Display the notification in the user's dashboard
  //   console.log("Received invitation event:", data);
  //   // console.log(data.message); // or update your state to show the notification
  // });
  // console.log(arr, "videosData");
  const actPlay = (val, ind) => {
    setPlayVid(val);
    setVidIndex(ind);
  };
  const handleClose = () => {
    setOpen(false);
    setPlayVid(false);
  };

  const chgVideo = async (idx) => {
    idx += vidIndex;
    let newIndex = idx < 0 ? videos.length - 1 : idx >= videos.length ? 0 : idx;
    let vidDuration =
      typeof videos[newIndex]?.duration === "number"
        ? videos[newIndex]?.duration
        : Number.parseFloat(videos[newIndex]?.duration);
    vidDuration = isNaN(vidDuration) ? 0 : vidDuration;
    let currentTime = new Date().getTime();

    setVidIndex(newIndex);
    setPlayVid(vidFormat(videos[newIndex]?.url, "", videos[newIndex]));
  };

  let options = (row) => [
    {
      title: (
        <div
          style={{
            minWidth: "105px",
            display: "flex",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <UserDetailsButtonGroup style={{ width: "80%" }}>
            <UserDetailsButtonText
              onClick={() => handleApproveClick(row._id)}
              sx={{
                backgroundColor:
                  !row.approved && !row.disapproved
                    ? "#F7F7F7"
                    : row.approved
                    ? "#ECECF2"
                    : "#F7F7F7",
                width: "48%",
                height: "85%",
                // borderRadius: "10px",
              }}
            >
              Publish
            </UserDetailsButtonText>

            <UserDetailsButtonText
              onClick={() => handleDisapproveClick(row._id)}
              sx={{
                backgroundColor:
                  !row.approved && !row.disapproved
                    ? "#ECECF2"
                    : row.disapproved
                    ? "#ECECF2"
                    : "#F7F7F7",
                width: "48%",
                height: "85%",
                // borderRadius: "10px",
                paddingLeft: "0.5rem",
              }}
            >
              Unpublish
            </UserDetailsButtonText>
          </UserDetailsButtonGroup>
        </div>
      ),
      link: () => {},
    },
    {
      title: (
        <div
          style={{
            minWidth: "105px",
            display: "flex",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <img src={Download} alt="download-icon" />
          <OptionsText style={{ marginLeft: "10px" }}>Download</OptionsText>
        </div>
      ),
      link: () =>
        downloadVideo(
          `${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${row.url}.mp4`
        ),
    },
    {
      title: (
        <div
          style={{
            minWidth: "105px",
            display: "flex",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          <img src={Trash} alt="delete-icon" />
          <OptionsText style={{ marginLeft: "10px" }}>Delete</OptionsText>
        </div>
      ),
      link: () => handleOpenModal(row.videoId),
    },
  ];

  const displayVideos = (row, ind) => {
    return isMobile ? (
      <TableRow
        key={row._id}
        onClick={() => {
          actPlay(vidFormat(row.url, "", row), ind);
        }}
        style={{
          cursor: "pointer",
          transition: "background-color 0.3s", // Smooth transition effect
          borderRadius: 0,
        }}
        className="hoverable-cell"
      >
        <TableCell component="th" scope="row">
          <TableDiv>
            <SubTableDiv>
              <UserDetailsImage style={{ marginRight: 20 }}>
                <VideoComp
                  width="62px"
                  height="40px"
                  item={row}
                  setPlayVid={(val) => actPlay(val, ind)}
                />
              </UserDetailsImage>
              <SubTableText>
                <TopText>{row.name}</TopText>
                <BottomText>
                  <FormatDate dateString={row.createdAt} />
                </BottomText>
              </SubTableText>
            </SubTableDiv>
            <EllipsisLinkBrand
              style={{
                width: "5%",
                height: "5%",
                padding: "5px",
                border: "none",
              }}
              content={options(row)}
            />
          </TableDiv>
        </TableCell>
      </TableRow>
    ) : (
      <TableRow
        key={row._id}
        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => {
          actPlay(vidFormat(row.url, "", row), ind);
        }}
        style={{
          cursor: "pointer",
          transition: "background-color 0.3s",
          // borderRadius: "15px",
          // borderRadius: "0",
        }}
        className="hoverable-cell"
      >
        <TableCell style={{ borderRadius: 0 }}>
          {/* <UserDetailsImage
              ref={btnModalRef}
              onClick={() => handleOpen(row.url)}
            >
              <img
                src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${row.url}.jpeg`}
                style={{ width: "100%" }}
                alt="video img"
              />
            </UserDetailsImage> */}
          <UserDetailsImage>
            <VideoComp
              width="62px"
              height="40px"
              item={row}
              setPlayVid={(val) => actPlay(val, ind)}
            />
          </UserDetailsImage>
        </TableCell>

        <TableCell>
          <UserDetailsText>{row.name}</UserDetailsText>{" "}
        </TableCell>
        {/* <TableCell>
            <UserDetailsText>This is a test title</UserDetailsText>{" "}
          </TableCell> */}
        <TableCell>
          <UserDetailsText> {row.email}</UserDetailsText>
        </TableCell>
        <TableCell>
          <UserDetailsText oneLine>
            <FormatDate dateString={row.createdAt} />
          </UserDetailsText>
        </TableCell>
        <TableCell>
          <UserDetailsText>
            <Timer totalSeconds={row.duration} />
          </UserDetailsText>
        </TableCell>
        {/* <TableCell>
          <UserDetailsStarRating>
            <UserDetailsStar>
              <img src={StarRating}></img>
            </UserDetailsStar>

            <UserDetailsText sx={{ marginLeft: "0.5rem" }}>
              {typeof row.rating === "number" && Number.isInteger(row.rating)
                ? row.rating + ".0"
                : row.rating}
            </UserDetailsText>
          </UserDetailsStarRating>
        </TableCell> */}
        <TableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <BootstrapTooltip title="Edit">
                <img
                  src={EditIcon}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenEditModal(true);
                    setRowItem(row);
                    // handleOpenShareModal(row.videoId);
                  }}
                  alt="edit video"
                />
              </BootstrapTooltip>
            </Box>
            <Box sx={{ paddingLeft: "20px" }}>
              {" "}
              <BootstrapTooltip title="Share">
                <img
                  src={ShareIcon}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenShareModal(row.videoId);
                  }}
                  alt="share video"
                />
              </BootstrapTooltip>
            </Box>
            <Box sx={{ paddingLeft: "20px" }}>
              {" "}
              <BootstrapTooltip title="Download">
                <img
                  src={Download}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    downloadVideo(
                      `${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${row.url}.mp4`
                    );
                  }}
                  alt="video download"
                />
              </BootstrapTooltip>
            </Box>

            <Box sx={{ paddingLeft: "20px" }}>
              <BootstrapTooltip title="Delete">
                <img
                  src={Trash}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenModal(row.videoId);
                  }}
                  alt="video trash"
                />
              </BootstrapTooltip>
            </Box>
          </Box>
        </TableCell>
        <TableCell style={{ borderRadius: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UserDetailsButtonGroup>
              <UserDetailsButtonText
                onClick={(event) => {
                  event.stopPropagation();
                  handleApproveClick(row._id);
                }}
                enable={row.approved}
                // sx={{
                //   backgroundColor:
                //     !row.approved && !row.disapproved
                //       ? "#F7F7F7"
                //       : row.approved
                //       ? "#D4DAE1"
                //       : "#F7F7F7",
                //   width: "48%",
                //   height: "85%",
                //   // borderRadius: "10px",
                // }}
              >
                Publish
              </UserDetailsButtonText>

              <UserDetailsButtonText
                onClick={(event) => {
                  event.stopPropagation();
                  handleDisapproveClick(row._id);
                }}
                enable={(!row.approved && !row.disapproved) || row.disapproved}
                // sx={{
                //   backgroundColor:
                //     !row.approved && !row.disapproved
                //       ? "#D4DAE1"
                //       : row.disapproved
                //       ? "#D4DAE1"
                //       : "#F7F7F7",
                //   width: "48%",
                //   height: "85%",
                //   // borderRadius: "10px",
                //   paddingLeft: "0.5rem",
                // }}
              >
                Unpublish
              </UserDetailsButtonText>
            </UserDetailsButtonGroup>{" "}
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  // Update videos state based on searchQuery and searchResults
  // const videos = searchQuery  ? searchResults : (data?.videosByBrandId || []);
  useEffect(() => {
    if (data && data.videosByBrandId) {
      // Reverse the order of the videos (from ascending to descending)
      const reversedVideos = data.videosByBrandId.slice().reverse();

      // Set the reversed videos in the state
      setVideos(reversedVideos);
      setFullCapacity(data.videosByBrandId.length);
    }
  }, [data]);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filteredVideos = data?.videosByBrandId.filter(
        (video) =>
          (video.name &&
            String(video.name).toLowerCase().indexOf(query.toLowerCase()) !==
              -1) ||
          (video.email &&
            String(video.email).toLowerCase().indexOf(query.toLowerCase()) !==
              -1)
      );
      setVideos(filteredVideos);
    } else {
      // Use searchResults as fallback when searchQuery is empty
      setVideos(data?.videosByBrandId);
    }
  };
  // Sorting by date created in ascending order
  const sortByDateCreated = () => {
    const sortedVideos = [...videos].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    setVideos(sortedVideos);
    setSelectedSortOption("dateCreated");
    setSelectedSortText("Date Created");
  };

  // Sorting by duration in ascending order
  const sortByDuration = () => {
    const sortedVideos = [...videos].sort((a, b) => {
      return b.duration - a.duration;
    });
    setVideos(sortedVideos);
    setSelectedSortOption("duration");
    setSelectedSortText("Duration");
  };

  // Sorting by rating in descending order
  const sortByRating = () => {
    const sortedVideos = [...videos].sort((a, b) => {
      return b.rating - a.rating;
    });
    setVideos(sortedVideos);
    setSelectedSortOption("rating");
    setSelectedSortText("Rating");
  };

  // Function to handle sorting by createdAt field in ascending order
  const handleSortAsc = () => {
    setSortDirection("ascending");
    const sortedVideos = [...videos].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    setVideos(sortedVideos);
  };
  const seeMoreVideos = () => {
    const reversedVideos = data.videosByBrandId.slice().reverse();

    // Set the reversed videos in the state
    setVideos(reversedVideos);
    setSelectedSortOption("videos");
    setSelectedSortText("Videos");
    setChecked(!checked);
  };

  // Function to handle sorting by createdAt field in descending order
  const handleSortDesc = () => {
    setSortDirection("descending");
    const sortedVideos = [...videos].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    setVideos(sortedVideos);
  };

  const handleBodyClick = (event) => {
    const path = event.composedPath(); // Use composedPath() instead of event.path

    // Check if the clicked element is not the button or custom component
    if (!path.includes(sortRef.current)) {
      setShowSort(false); // Set showBrand to false when clicked outside of button or custom component
    }
  };

  useEffect(() => {
    // Add event listener to body element
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const toggleResults = () => {
    setShowSort(!showSort);
  };

  const [approveVideo] = useMutation(APPROVE_VIDEO, {
    refetchQueries: data?.videosByBrandId?.map((video) => ({
      query: GET_SINGULAR_VIDEO,
      variables: { input: { videoId: video.videoId } },
    })),
  });

  const [disapproveVideo] = useMutation(DISAPPROVE_VIDEO, {
    refetchQueries: data?.videosByBrandId?.map((video) => ({
      query: GET_SINGULAR_VIDEO,
      variables: { input: { videoId: video.videoId } },
    })),
  });

  const [approvalState, setApprovalState] = useState({});

  const handleApproveClick = async (videoId) => {
    try {
      await approveVideo({ variables: { input: { videoId: videoId } } });
      await refetchApprovedVideoCount();
      await refetchDisApprovedVideoCount();
      setApprovalState((prevState) => ({
        ...prevState,
        [videoId]: prevState[videoId] === "approved" ? "" : "approved",
      }));
    } catch {}
  };

  const handleDisapproveClick = async (videoId) => {
    try {
      await disapproveVideo({ variables: { input: { videoId: videoId } } });
      await refetchApprovedVideoCount();
      await refetchDisApprovedVideoCount();
      setApprovalState((prevState) => ({
        ...prevState,
        [videoId]: prevState[videoId] === "disapproved" ? "" : "disapproved",
      }));
    } catch {}
  };
  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.round(totalSeconds % 60);

    let timeString = "";

    if (hours > 0) {
      timeString += `${hours} hr `;
    }

    if (minutes > 0) {
      timeString += `${minutes} min `;
    }

    if (seconds > 0) {
      timeString += `${seconds} secs`;
    }

    return timeString.trim();
  }
  function Timer({ totalSeconds }) {
    const timeString = formatTime(totalSeconds);
    return <>{timeString}</>;
  }
  function FormatDate({ dateString }) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const meridiem = time.slice(-2);
    const formattedTime = time.slice(0, -2);
    return `${day} ${month} ${year}, ${formattedTime}${meridiem}`;
  }

  // if (loading) return <Spinner />;
  if (error) return <p>Something Went Wrong</p>;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let tabHeaders = isMobile
    ? ["Name"]
    : [
        "Video",
        "Name",
        "Email",
        "Created at",
        "Duration",
        // "Rating",
        "Actions",
        "Status",
      ];
  function getButtonContent(itemsRemoval, videos) {
    const videosLength = videos.length;

    if (!itemsRemoval) {
      if (videosLength === 0) {
        return ""; // Empty string
      } else if (videosLength > 0 && videosLength === fullCapacity) {
        return ""; // See all button
      } else if (videosLength > 0 && videosLength > 6) {
        return "See All"; // Empty string
      } else {
        return ""; // Empty string
      }
    } else {
      if (videosLength === 0) {
        return ""; // Empty string
      } else if (videosLength > 0 && videosLength > 6) {
        return "See More"; // See more button
      } else {
        return ""; // Empty string
      }
    }
  }

  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      <UserTableDetails width={itemsRemoval}>
        <TableContainer sx={{ marginTop: "1.5rem" }}>
          <TableAnalytics isMobile={isMobile}>
            <TableAnalyticsOne isMobile={isMobile}>
              {itemsRemoval ? (
                <FullAnalyticsHeaderTwo> Videos </FullAnalyticsHeaderTwo>
              ) : (
                <FullAnalyticsHeaderTwo>New Videos </FullAnalyticsHeaderTwo>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  // background: "#F7FBEF",
                  background: "#f7f7f7",
                  // padding: "9px 10px",
                  padding: "10px 0",
                  justifyContent: isMobile ? "space-between" : undefined,
                  width: isMobile ? "fit-content" : undefined,
                  gap: "16px",
                  // height: "44px",
                  // borderRadius: "8px",
                  borderRadius: "12px",
                  marginLeft: !isMobile ? "1rem" : undefined,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    padding: "0 12px",
                    borderRadius: "10px",
                  }}
                >
                  <TableAnalyticsOneText>Published</TableAnalyticsOneText>
                  <TableAnalyticsOnesSubText style={{ color: "#45c160" }}>
                    {approvedData?.approvedVideoCount}
                  </TableAnalyticsOnesSubText>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    padding: "0 12px",
                    borderRadius: "10px",
                  }}
                >
                  <TableAnalyticsOneText>Unpublished</TableAnalyticsOneText>
                  <TableAnalyticsOnesSubText>
                    {disapprovedData?.disapprovedVideoCount}
                  </TableAnalyticsOnesSubText>
                </Box>
              </Box>
            </TableAnalyticsOne>
            <TableAnalyticsTwo isMobile={isMobile}>
              <NavSearch
                style={{ marginTop: "0rem" }}
                className="navigation-img"
                tabIndex="0"
                focus={focusState?.srch}
              >
                <img
                  src={SearchIcon}
                  alt="share-icon"
                  style={{ height: 20, margin: "0 5px" }}
                />
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  style={{ border: "none" }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  value={searchQuery}
                  onFocus={() => {
                    setFocusState({ ...focusState, srch: true });
                  }}
                  onBlur={() => {
                    setFocusState({ ...focusState, srch: false });
                  }}
                  onChange={handleSearch}
                />
              </NavSearch>
              <TableAnalyticsSort
                isMobile={isMobile}
                ref={sortRef}
                onClick={toggleResults}
                focus={showSort}
                // className="navigation-img"
                tabIndex="0"
              >
                <UserDetailsText
                  style={{
                    fontSize: isMobile && "14px",
                    fontWeight: isMobile && 400,
                  }}
                >
                  Sort:{" "}
                </UserDetailsText>
                <UserDetailsText
                  style={{
                    color: "#5D6272",
                    whiteSpace: "nowrap",
                    fontSize: isMobile && "12px",
                    fontWeight: isMobile && 400,
                    width: "100px",
                  }}
                >
                  {selectedSortText}
                </UserDetailsText>
                <img src={ArrowDown} alt="direction" />

                {showSort && (
                  <SortContainer>
                    <SortBoxItem
                      sx={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        alignItems: "center",
                      }}
                      onClick={sortByDateCreated}
                    >
                      <Box sx={{ width: "22%", height: "30px" }}>
                        {" "}
                        {selectedSortOption === "dateCreated" && (
                          <img
                            src={MarkUp}
                            alt="mark-up"
                            style={{ width: "30px" }}
                          />
                        )}
                      </Box>

                      <SortText>Date Created</SortText>
                    </SortBoxItem>
                    <SortBoxItem
                      sx={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        alignItems: "center",
                      }}
                      onClick={sortByRating}
                    >
                      <Box sx={{ width: "22%", height: "30px" }}>
                        {" "}
                        {selectedSortOption === "rating" && (
                          <img
                            src={MarkUp}
                            alt="mark-up"
                            style={{ width: "30px" }}
                          />
                        )}
                      </Box>

                      <SortText>Rating</SortText>
                    </SortBoxItem>
                    <SortBoxItem
                      last
                      sx={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        alignItems: "center",
                      }}
                      onClick={sortByDuration}
                    >
                      <Box sx={{ width: "22%", height: "30px" }}>
                        {" "}
                        {selectedSortOption === "duration" && (
                          <img
                            src={MarkUp}
                            alt="mark-up"
                            style={{ width: "30px" }}
                          />
                        )}
                      </Box>

                      <SortText>Duration</SortText>
                    </SortBoxItem>
                  </SortContainer>
                )}
              </TableAnalyticsSort>
              {/* <TableAnalyticsDirection
                onClick={handleSortAsc}
                className="navigation-img"
                tabIndex="0"
              >
                <img src={Ascending} alt="direction" />
              </TableAnalyticsDirection> */}
              {/* <TableAnalyticsDirection
                onClick={handleSortDesc}
                sx={{ marginRight: "1rem" }}
              >
                <img src={Descending} alt="direction" />
              </TableAnalyticsDirection> */}
              <TableAnalyticsDirection
                onClick={
                  sortDirection === "ascending" ? handleSortDesc : handleSortAsc
                }
                className="navigation-img"
                tabIndex="0"
              >
                <img
                  src={sortDirection === "ascending" ? Descending : Ascending}
                  alt="direction"
                  style={{ height: "30px" }}
                />
              </TableAnalyticsDirection>
            </TableAnalyticsTwo>
          </TableAnalytics>
          <Table
            sx={{
              width: isMobile ? "100%" : undefined,
              minWidth: isMobile ? undefined : 650,
              marginTop: isMobile ? "30px" : undefined,
              marginBottom: itemsRemoval && isMobile && "70px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {tabHeaders.map((header, idx) => (
                  <TableCell key={`TabH${idx}`} style={{ height: "72px" }}>
                    <UserDetailsHeader>{header}</UserDetailsHeader>{" "}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {videos &&
                videos
                  .slice(
                    0,
                    searchQuery || selectedSortOption ? videos.length : 6
                  )
                  .map((row, ind) => displayVideos(row, ind))}

              {/* Your other JSX */}
            </TableBody>
          </Table>
          {searchQuery && videos.length === 0 && (
            <SearchBox
              style={{ width: "500px", height: "500px", margin: "0 auto" }}
            >
              <img
                src={EmptySearch}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </SearchBox>
          )}
          {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <video width="100%" controls>
                <source
                  src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${selectedVideoUrl}.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Modal> */}
          <ModalCust
            open={open === true || playVid}
            modalFalse={true}
            isMobile={isMobile}
          >
            <ModalContent
              open={open === true || playVid}
              modalFalse={true}
              isMobile={isMobile}
            >
              <Wrapper isMobile={isMobile}>
                <img src={CloseIcon} alt="Close" onClick={handleClose} />
              </Wrapper>

              {playVid ? (
                <PlayVideo
                  url={playVid}
                  chgVideo={chgVideo}
                  setVideoPausedTime={setVideoPausedTime}
                  videoPausedStartTime={videoPausedStartTime}
                  videoPausedTime={videoPausedTime}
                  setVideoPausedStartTime={setVideoPausedStartTime}
                  setVideoPlayedStartTime={setVideoPlayedStartTime}
                  item={videos[vidIndex]}
                />
              ) : (
                <h2>""</h2>
              )}
            </ModalContent>
          </ModalCust>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "1rem 0",
              borderTop: "1px solid #eee",
            }}
          >
            {!itemsRemoval ? (
              videos.length <= 5 ? (
                ""
              ) : (
                <InsightButton href={"/brands/${id}/1/Impression"}>
                  <SeeMore
                    style={{
                      position: "relative",
                      margin: "1rem 0",
                      marginBottom: "70px",
                    }}
                    className="button-custom"
                    tabIndex="1"
                  >
                    <SeeMoreText>See All</SeeMoreText>
                  </SeeMore>
                </InsightButton>
              )
            ) : videos.length > 5 && !checked ? (
              <SeeMore
                onClick={seeMoreVideos}
                style={{
                  position: "relative",
                  margin: "1rem 0",
                  marginBottom: "70px",
                }}
                className="button-custom"
                tabIndex="1"
              >
                <SeeMoreText>See More</SeeMoreText>
              </SeeMore>
            ) : (
              ""
            )}
          </Box>
        </TableContainer>
      </UserTableDetails>
      <DeleteModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleModalConfirm={handleModalConfirm}
        isMobile={isMobile}
      />
      <ShareModal
        openModal={openShareModal}
        handleCloseModal={handleCloseShareModal}
        isMobile={isMobile}
        videoUrl={videoUrl}
      />
      <EditVideo
        openDialog={openEditModal}
        onClose={() => setOpenEditModal(false)}
        itemData={rowItem}
        reload={reload}
      />
    </>
  );
}

export default DashboardTable;
