import React from "react";
import { useWorkflowContext } from "./workflowContext";
import { useReactFlow } from "reactflow";
import {
  DeleteModalContainer,
  StyledSectionButton,
} from "../../components/TagsModal/styled";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";

const DeleteNodeModal = ({ selected }) => {
  const { deleteNodeModal, closeNodeDeleteModal } = useWorkflowContext();
  const instance = useReactFlow();
  return (
    <Modal
      open={deleteNodeModal}
      onClose={closeNodeDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DeleteModalContainer>
        <Box sx={{ py: 1, borderBottom: "1px solid #E5E3E3" }}>
          <Typography
            sx={{ fontWeight: 700, fontFamily: "Switzer", px: "8px" }}
            id="modal-modal-title"
            variant="h6"
          >
            Delete Action
          </Typography>
        </Box>

        <Box sx={{ p: "8px", py: 3 }}>
          <Typography sx={{ fontFamily: "Switzer" }}>
            Are you sure you want to delete <strong> {selected?.data?.title??"this"} </strong>action, data of this action cannot be
            recovered.
          </Typography>
        </Box>
        <Stack
          sx={{ px: "8px", py: 2 }}
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
        >
          <StyledSectionButton onClick={closeNodeDeleteModal}>
            Cancel
          </StyledSectionButton>
          <Button
            color="error"
            variant="contained"
            onClick={()=>{
              instance?.deleteElements({nodes:[selected]})
              closeNodeDeleteModal()
            }}
            sx={{
              borderRadius: "15px",
              fontFamily: "Switzer",
              fontSize: "14px",
              textTransform: "none",
              px: 2,
            }}
          >
            Delete
          </Button>
        </Stack>
      </DeleteModalContainer>
    </Modal>
  );
};

export default DeleteNodeModal;
