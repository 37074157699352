// src/store/menu.js
import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    name: "",
    brandId: "",
    id: "",
    access: "view",
    owned: false,
  },
  reducers: {
    setMenu: (state, action) => {
      state.name = action.payload.name;
      state.brandId = action.payload.brandId;
      state.id = action.payload.id;
      state.access = action.payload.access;
      state.owned = action.payload.owned;
    },
    logOutMenu: (state) => {
      state.name = "";
      state.brandId = "";
      state.id = "";
      state.access = "view";
      state.owned = false;
    },
  },
});

export const { setMenu, logOutMenu } = menuSlice.actions;
export const menuReducer = menuSlice.reducer;
