import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

/**
 * A custom button component with configurable properties.
 *
 * @param {Object} props - The component's props.
 * @param {number} [props.width=201] - The width of the button.
 * @param {string} props.title - The text displayed on the button.
 * @param {Function} props.onClick - The click event handler for the button.
 * @param {Object} [props.backgroundStyle] - Additional background styles for the button.
 * @param {boolean} [props.disabled] - Whether the button is disabled.
 * @param {string} [props.addedSvg] - URL of an additional SVG image to display on the button.
 * @param {boolean} [props.small] - Whether to render a smaller version of the button.
 * @param {number} [props.reviewHeight=52] - The height of the button.
 * @returns {JSX.Element} The custom button component.
 */
export default function CustomButton({
  width = 201,
  title,
  onClick,
  backgroundStyle,
  txtStyle = {},
  disabled,
  addedSvg,
  small,
  reviewHeight = 52,
}) {
  // Styles for the text on the button
  const textStyle = {
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    fill: "#fff",
    textAnchor: "middle",
    ...txtStyle,
  };

  // Calculate adjusted heights
  let rHeight = reviewHeight;
  let rNHeight = reviewHeight - 3.5;

  return (
    <Button
      variant="text"
      style={{ padding: 0, textTransform: "capitalize" }}
      onClick={onClick}
      disabled={disabled}
    >
      <svg
        width={width}
        height={`${reviewHeight}`}
        viewBox={small ? "0 0 105 52" : "0 0 205 52"}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={small ? "105" : "205"}
          height={`${rHeight}`}
          rx="10"
          fill="#1a1b24"
          style={backgroundStyle}
        />
        <rect
          x="2.75"
          y="2.75"
          width={small ? "101.5" : "201.5"}
          height={`${rNHeight}`}
          rx="9.25"
          stroke="black"
          strokeWidth="1.5"
        />
        <text x={addedSvg ? "58%" : "50%"} y="60%" style={textStyle}>
          {title}
        </text>
        {addedSvg && (
          <image href={addedSvg} width="25" height="25" x="20" y="10" />
        )}
      </svg>
    </Button>
  );
}

// PropTypes for type checking and documentation
CustomButton.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  backgroundStyle: PropTypes.object,
  disabled: PropTypes.bool,
  addedSvg: PropTypes.string,
  small: PropTypes.bool,
  reviewHeight: PropTypes.number,
};
