import {
  Modal,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Switch,
  IconButton,
} from "@mui/material";
import { ModalContainer, StyledAuthButton } from "../CampaignList/styled";

import MailList from "../SalesList/mailList";
import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import { AUTHENTICATE_MAIL } from "../../graphql/mutations/sendgrid";

export default ({ open, setOpen, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [authenticateMail] = useMutation(AUTHENTICATE_MAIL);
  const [loading, setLoading] = useState(false);
  const [formstate, setFormstate] = useState({ secure: true });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      let { email, password, host, port, secure } = formstate;
      if (!email) {
        enqueueSnackbar("Enter an email Address", {
          variant: "error",
        });
        return;
      }

      if (!host) {
        enqueueSnackbar("Enter a host name", {
          variant: "error",
        });
        return;
      }
      if (!/^\S+@\S+$/i.test(email)) {
        enqueueSnackbar("Enter a valid email Address", {
          variant: "error",
        });
        return;
      }

      ///server
      if (port) port = parseInt(port);
      const { data, errors } = await authenticateMail({
        variables: {
          input: {
            email,
            password,
            host,
            port,
            secure,
          },
        },
      });
      if (!data && errors?.[0]?.message) {
        enqueueSnackbar(errors[0].message, {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          data?.authenticateMail?.flag
            ? "Email Address is valid and added successfully"
            : "It seems your email address or settings are invalid",
          {
            variant: data?.authenticateMail?.flag ? "success" : "error",
          }
        );
        if (data?.authenticateMail?.flag) {
          setFormstate({ secure: true });
          handleClose();
          refetch();
        }
      }
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer
        style={{
          // width: "800px",
          width: "680px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80%",
          overflow: "auto",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            Add New Email Address
          </Typography>
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </Box>

        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            display: "flex",
            gap: "20px",
          }}
        >
          <TextField
            label="Email Address"
            type="email"
            required
            sx={{
              minWidth: 300,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setFormstate({ ...formstate, email: e.target.value })
            }
            value={formstate.email}
          />

          <TextField
            label="Password"
            type="password"
            required
            sx={{
              minWidth: 300,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setFormstate({ ...formstate, password: e.target.value })
            }
            value={formstate.password}
          />
        </Box>
        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            display: "flex",
            gap: "20px",
          }}
        >
          <TextField
            label="Host name"
            type="text"
            required
            sx={{
              minWidth: 300,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setFormstate({ ...formstate, host: e.target.value })
            }
            value={formstate.host}
          />

          <TextField
            label="Port"
            type="number"
            required
            sx={{
              minWidth: 300,
              marginBottom: "10px",
              marginTop: "10px",
            }}
            onChange={(e) =>
              setFormstate({ ...formstate, port: e.target.value })
            }
            value={formstate.port}
          />
        </Box>
        <Box
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
            display: "flex",
            gap: "20px",
          }}
        >
          <IconButton
            style={{
              // position: "absolute",
              // bottom: "8px",
              // right: "48px",
              color: "black",
              fontSize: "12px",
            }}
            title="Secure"
          >
            <Switch
              checked={!!formstate.secure}
              onClick={() =>
                setFormstate({ ...formstate, secure: !formstate.secure })
              }
            />
            <>Secure</>
          </IconButton>
        </Box>

        <Box
          sx={{
            background: "white",

            width: "100%",
          }}
        >
          <StyledAuthButton
            //   type="submit"
            //   disabled={loading}
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "Loading..." : " Submit"}
          </StyledAuthButton>
        </Box>

        {/* <Interpolation /> */}
      </ModalContainer>
    </Modal>
  );
};
