import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";
import { addValueToColor } from "../../utils/colorFormat";

export const ColorButtonOverall = styled(Box)`
  width: ${(props) => props.width};
  pointer-events: ${(props) => props.pointerEvents};
  position: relative;
  height: 50px;
  display: flex;
  opacity: ${(props) => props.disabled && 0.6};
  // justify-content: center;
  justify-content: flex-end;
  align-items: center;
  // cursor: ${(props) => (props.disabled ? undefined : "pointer !important")};
  cursor: ${(props) =>
    props.disabled ? "default !important" : "pointer !important"};

  // transition: transform 0.3s ease-in-out;

  // &:hover {
  //   transform: scale(1.05); /* Scale the button up to 105% when hovered over */
  //}
`;

export const ColorBlankOne = styled(Box)`
  // width:100%;
  position: absolute;
  // border:${(props) =>
    props.pointerEvents === "none"
      ? " 1.5px solid rgba(0, 0, 0, 0.50)"
      : "1.5px solid #000000"};

  border-radius: 14px;
  height: ${(props) => (props.isMobile ? "37px" : "48px")};
  // z-index:2;

  pointer-events: ${(props) =>
    props.background === "#B8A9FF" ? "none" : "auto"};
  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

export const ColorBlankTwo = styled(Box)`
  height: ${(props) => (props.isMobile ? "37px" : "48px")};
  width: 100%;
  border-radius: 15px;
  background: ${(props) =>
    props.pointerEvents === "none"
      ? "rgba(93, 98, 114, 0.21)"
      : props.background};
  // top:${(props) => (props.isMobile ? "3.5%" : "-4.5%")};
  // left:-1%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  padding: 13.5px 16px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  gap: 5px;
  /* Add hover effect when background is #B8A9FF */
  &:hover {
    // border-color: 1px solid #1a1b24;
    background: ${(props) =>
      props?.disabled
        ? undefined
        : props?.hoverBackgroundColor
        ? props?.hoverBackgroundColor
        : addValueToColor(props.background ?? "#1A1B24")};
    // color: ${(props) =>
      props.background === "#B8A9FF" ? "#FFFFFF" : "#1A1B24"};
    pointer-events: auto;
    // cursor: pointer;
    color: ${(props) => (props?.disabled ? undefined : props.hoverColor)};
  }
  &:active {
    background-color: ${(props) => props?.activeBgColor ?? "#3d3e52"};
    border-color: ${(props) => props?.activeBorderColor};
    box-shadow: 0px 0px 4px 0px rgba(69, 193, 126, 0.4);
  }
`;
// box-shadow: 0px 0px 4px 0px ${(props) => props.background ?? "#2c2d3f"};

export const ColorButtonText = styled(Typography)`
  font-family: "DM Sans";
  font-size: ${(props) => (props.isMobile ? "16px" : "16px")};
  font-weight: 400;
  line-height: ${(props) => (props.isMobile ? "15px" : "normal")};
  letter-spacing: 0em;
  // text-align: left;
  order: ${(props) => (props.order ? "1" : "0")};
  color: ${(props) =>
    props.background === "#1A1B24" && props.pointerEvents !== "none"
      ? "white"
      : "#1A1B24"};
  opacity: ${(props) => (props.pointerEvents === "none" ? "0.5" : "1")};
  &:hover {
    color: ${(props) =>
      props.hoverColor
        ? props.hoverColor
        : props.textColor
        ? props.textColor
        : "#000"}; // Set hover color based on hoverColor or textColor prop
  }
`;
