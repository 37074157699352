import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes

import { Container, UnitBox, Text } from "./styled";
import DashboardSvg from "../../assets/menuicons/dashboard.svg";
import SearchSvg from "../../assets/menuicons/search.svg";
import InviteSvg from "../../assets/menuicons/invite.svg";
import NotifySvg from "../../assets/menuicons/notification.svg";
import ProfileSvg from "../../assets/menuicons/profile.svg";

import DashboardSvgActive from "../../assets/menuicons/dashboardActive.svg";
import SearchSvgActive from "../../assets/menuicons/searchActive.svg";
import InviteSvgActive from "../../assets/menuicons/inviteActive.svg";
import NotifySvgActive from "../../assets/menuicons/notificationActive.svg";
import ProfileSvgActive from "../../assets/menuicons/profileActive.svg";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavigationMenu({
  isMobile,
  setClickedMenu,
  clickedMenu,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;

  const menuItems = [
    {
      title: "Dashboard",
      image: DashboardSvg,
      activeImage: DashboardSvgActive,
      link: "/",
      activeStyle: "url",
    },
    {
      title: "Search",
      image: SearchSvg,
      activeImage: SearchSvgActive,
      activeStyle: "menu",
    },
    {
      title: "Invite",
      image: InviteSvg,
      activeImage: InviteSvgActive,
      link: "/invitation",
      activeStyle: "url",
    },
    {
      title: "Notification",
      image: NotifySvg,
      activeImage: NotifySvgActive,
      activeStyle: "menu",
    },
    {
      title: "Profile",
      image: ProfileSvg,
      activeImage: ProfileSvgActive,
      activeStyle: "menu",
    },
  ];

  const handleMenuItemClick = (menuItem) => {
    setIsOpen(!isOpen);
    setClickedMenu(menuItem.title);

    if (menuItem.link && menuItem.link !== "") {
      navigate(menuItem.link);
    }
  };

  return isMobile ? (
    <Container>
      {menuItems.map((menuItem, idx) => (
        <UnitBox
          key={idx}
          onClick={() => handleMenuItemClick(menuItem)}
        >
          <img
            src={
              (menuItem.activeStyle === "url" &&
                menuItem.link &&
                currentUrl === menuItem.link) ||
              (menuItem.activeStyle === "menu" &&
                menuItem.title &&
                clickedMenu === menuItem.title)
                ? menuItem.activeImage
                : menuItem.image
            }
            alt={menuItem.title}
          />
          <Text>{menuItem.title}</Text>
        </UnitBox>
      ))}
    </Container>
  ) : null;
}

// Define PropTypes for component props
NavigationMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setClickedMenu: PropTypes.func.isRequired,
  clickedMenu: PropTypes.string.isRequired,
};
