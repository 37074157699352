import styled from "@emotion/styled";

import { Box, Button, ButtonBase, Divider, Typography } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5.5rem;
`;

export const Image1 = styled.img`
  right: -50%;
  top: -600px;
  position: relative;
  z-index: 500;
`;

export const Image2 = styled.img`
  right: -50%;
  top: -40%;
  position: relative;
  z-index: 500;
`;

export const Image3 = styled.img`
  left: -50%;
  top: -300px;
  position: relative;
  z-index: 500;
`;

export const Header = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 6.25rem;
  line-height: 6.5rem;
  /* or 104% */

  text-align: center;

  color: #000000;
  margin-bottom: 2.6rem;
`;

export const SubHeader = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 1.499rem;
  line-height: 2.375rem;
  leading-trim: both;
  text-edge: cap;
  text-align: center;

  color: #000000;
  margin-bottom: 3.75rem;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonReview = styled(ButtonBase)`
  background: #000000;
  border-radius: 99px;
  transform: rotate(2.25deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  gap: 10px;
  font-family: "Switzer";
  font-style: normal;
  font-weight: 400;
  font-size: 23.99px;
  line-height: 32px;
  color: #ffffff;
  transform: rotate(2.25deg);
`;

export const VideoContainer = styled(Box)`
  // padding: 3.747rem 17rem;
  //   padding: 3.747rem 5rem;
  // padding: 0 5rem;
  padding: 0 0.2rem;
`;

export const VideoCont = styled(Box)`
  border-radius: 14px;
  transform: rotate(${(props) => props?.angle}deg);
  background: url(${(props) => props?.url});
  background-size: cover;
  margin-top: ${(props) => props?.top}px;
  background-position: center;
  background-repeat: no-repeat;
  height: 26rem;
  //   width: 19.75rem;
  // width: 316px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const Description = styled(Box)`
  bottom: 16.08px;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
  max-height: 84.32px;
  max-width: 335px;
`;

export const DescriptionBox = styled(Box)`
  padding: 8.05px 0 11.05px 20.05px;
  width: 60%;
`;
export const Title = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size:${(props) => (props?.isMobile ? "16px" : "18px")};
  // font-size: 70%;
  transform: rotate(${(props) => props?.angle}deg);
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Post = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  transform: rotate(${(props) => props?.angle}deg);
  font-size: 14px;
  // font-size: 50%;
  line-height: 120%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Rating = styled(Box)`
  // width: 44.76px;
  // height: 44.76px;
  // border-radius: 50%;
  // background: #f3b02f;
  // transform: rotate(${(props) => props?.angle}deg);
  // backdrop-filter: blur(2.41826px);
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  // leading-trim: both;
  // text-edge: cap;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-right: 10px;
`;
export const StarRatingBox = styled(Box)`
  display:flex;
  justify-content:center;
  align-items:center;
  background:url(${(props) => props?.background});
  margin-right:10px;
  width: ${(props) => (props?.isMobile ? "30px" : "40px")};
    height: ${(props) => (props?.isMobile ? "30px" : "40px")};
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
 
`;

export const ModalCust = styled(Box)`
  display: ${(props) =>
    props?.open ? "flex" : "none"}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 90000; /* Sit on top */
  display: ${(props) => (props?.modalFalse ? "in" : "flex")};
  justify-content: ${(props) => (props?.modalFalse ? "center" : "center")};
  align-items: ${(props) => (props?.modalFalse ? "center" : "center")};
  left: 0;
  top: ${(props) => (props?.modalFalse ? "0" : "5%")};
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: ${(props) => (props?.modalFalse ? "rgb(0, 0, 0)" : "none")};

  background-color: ${(props) =>
    props?.modalFalse ? "rgba(0, 0, 0, 0.4)" : "none"}; /* Black w/ opacity */
`;

export const ModalContent = styled(Box)`
  background: black;

  width: ${(props) => (props?.isMobile ? "90%" : "70%")};
  // width:auto;
  aspect-ratio: 16/9;
  border-radius: 16px;
  height: 70vh;
  // padding: 11px 11px 12px 11px;
`;
export const Wrapper = styled(Box)`
  position: relative;
  top: 15px;
  left: ${(props) => (props?.isMobile ? "85%" : "95%")};
  width: 0;
  height: 0;
  z-index: 700;
  cursor: pointer;
`;
export const PlayerWrapper = styled(Box)`
  position: relative;
  // padding-top: 56.25%; /* 16:9 aspect ratio */
  width: 100%;
  height: 100%;
`;

export const ActionOverall = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // padding: 0 28.5px;
  position: relative;
  // top: 35%;
  top: -10%;
  // margin-top: -55px;

  z-index: 100;
  width: 100%;
`;
export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 28.5px;
  position: absolute;
  // top: 35%;
  // top: -182px;
  // margin-top: -55px;

  z-index: 100;
  width: 100%;
`;

export const ActionContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  // position: relative;
  // top: 35%;
  // top: -10px;
  margin-top: -90px;
  margin-bottom: 10px;
  z-index: 100;
  width: 98%;
  position: absolute;
`;
export const ImagePlay = styled.div`
  opacity: 0.2;
  
  &:hover {
    opacity: 1;
  }
`;

export const RightDiv = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right:${(props) => (props?.isMobile ? "4px" : "15px")};
`;
export const ProgressBar = styled.div`
  width: 98%;
  height: 4px;
  // overflow: hidden;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  cursor: pointer;
  z-index: 2;
  //  margin-top: -80px;
  margin-bottom: 5px;
  position: relative;
  margin-left: ${(props) => (props?.isMobile ? "0px" : "7px")};
  margin-right: 7px;
`;
export const Progress = styled.div`
  height: 100%;
  width: ${(props) => props.width}%;
  background: #ee1b37;
`;
