import { useState } from "react";
import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import Snackbar from "@mui/material/Snackbar";

import { GET_ORGANIZATIONS_FOR_USER } from "../graphql/queries/organizationQueries";
import { ADD_ORGANIZATION } from "../graphql/mutations/organizationMutation";
import { GET_INDUSTRIES } from "../graphql/queries/industryQueries";
import AWS from "aws-sdk";
import ImageCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropImage from "./CropImage";
import {
  SignUpContainer,
  SignUpHeader,
  SignUpSubHeader,
} from "../pages/OrganizationBrands/styled";
import { MenuItem, TextField } from "@mui/material";
import ButtonCust from "./ButtonCust";
import { useSelector } from "react-redux";
import { StepTitle } from "./Testimonial/ReviewComp/styled";
import ColorButton from "./ColorButton";
import CustomTextBox from "./CustomTextBox";
import CustomSelectBox from "./CustomSelectBox";

export default function StepOneOrganization({ formData, setFormData, onNext }) {
  const [value, setValue] = useState("");
  // const [name, setName] = useState("");
  //   const [industry, setIndustry] = useState("");
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const [focusState, setFocusState] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  // const [createOrganization] = useMutation(ADD_ORGANIZATION, {
  //   variables: { input: { name, industry } },
  //   update(cache, { data: { createOrganization } }) {
  //     const { organizationsForUser } = cache.readQuery({ query: GET_ORGANIZATIONS_FOR_USER });

  //     cache.writeQuery({
  //       query: GET_ORGANIZATIONS_FOR_USER,
  //       data: { organizationsForUser: [...organizationsForUser, createOrganization] },
  //     });
  //   },
  // });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

    return pattern.test(url);
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   if (name === "" ||  industry === "" ) {
  //     setOpenSnackbar(true);
  //     return setSnackbarMessage("Please return all fields");
  //   }

  //   try {

  //     await createOrganization({
  //       variables: { input: { name,  industry } },
  //     });
  //     setOpenSnackbar(true);
  //     setSnackbarMessage("Organization added successfully");
  //     setName("");
  //     setIndustry("");
  //          onNext();
  //   } catch (error) {
  //     console.error("Error creating organization:", error);
  //     setOpenSnackbar(true);
  //     setSnackbarMessage("Error creating organization");
  //   }
  // };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const { error, data } = useQuery(GET_INDUSTRIES);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //
  // };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log(name)
  //   console.log('Before update:', formData);
  //   setFormData((prevFormData) => {
  //     console.log('Previous form data:', prevFormData);
  //     const updatedOrganization = {
  //       ...prevFormData.organization,
  //       [name]: value,
  //     };
  //     console.log('Updated organization:', updatedOrganization);
  //     return {
  //       ...prevFormData,
  //       organization: updatedOrganization,
  //     };
  //   });
  // };

  let TitleHead = isMobile ? StepTitle : SignUpHeader;
  return (
    // <form onSubmit={handleSubmit}>
    //   <h2>Step 1</h2>
    //   <input
    //     type="text"
    //     value={value}
    //     onChange={(e) => setValue(e.target.value)}
    //     placeholder="Enter value"
    //     required
    //   />
    //   <button type="submit">Next</button>
    // </form>
    <>
      {/* snackbar component */}
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />
      <SignUpContainer isMobile={isMobile}>
        <TitleHead>Create New Organization</TitleHead>
        <div className="mt-3" style={{ width: "100%" }}>
          <form>
            <div className="mb-3">
              <SignUpSubHeader>
                Organization Name <span style={{ color: "#4097DA" }}>*</span>
              </SignUpSubHeader>
              <CustomTextBox
                // margin="normal"
                required
                fullWidth
                id="name"
                label=""
                name="name"
                focusState={focusState}
                setFocusState={setFocusState}
                autoComplete="name"
                defaultValue={formData.name}
                autoFocus
                placeholder="Company Inc"
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
              />
            </div>

            <div className="mb-3">
              <SignUpSubHeader>
                Sector <span style={{ color: "#4097DA" }}>*</span>
              </SignUpSubHeader>

              <CustomSelectBox
                id="industry"
                select
                defaultValue=""
                name="industry"
                focusState={focusState}
                setFocusState={setFocusState}
                placeholder="Choose an Option" // Placeholder text
                onChange={(e) => {
                  setFormData({ ...formData, industry: e.target.value });
                }}
                fullWidth
                arrValues={
                  data && data.industrys instanceof Array
                    ? data.industrys.map((industry) => ({
                        name: industry.name,
                        value: industry.name,
                      }))
                    : []
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                marginTop: "1.5rem",
              }}
            >
              <ColorButton
                onClick={onNext}
                title="Continue"
                width={isMobile ? "100%" : "201px"}
                background={!formData.name.trim() ? "#1A1B24" : "#1A1B24"}
                pointerEvents={formData.name.trim() ? "auto" : "none"}
                opacity={formData.name.trim() ? 1 : 0.5}
              />
              {/* <ButtonCust
                width={isMobile && "100%"}
                onClick={onNext}
                title={"Continue"}
              ></ButtonCust> */}
            </div>
          </form>
        </div>
      </SignUpContainer>
    </>
  );
}
