let keyStr = "qwertypoiu";

const changeCharacters = (strChar, addnd = 0) => {
  return String.fromCharCode(strChar.charCodeAt(0) + addnd);
};

const encrypter = (str) => {
  str = String(str);
  // Exclude the first element
  let index = Math.floor(Math.random() * (keyStr.length - 1)) + 1;
  let prefix = keyStr[index];
  let strArr = [];
  for (let i = 0; i < str.length; i++) {
    strArr.push(changeCharacters(str[i], index));
  }
  return `${prefix}${strArr.join("")}`;
};

// console.log(encrypter("idea of mine"));

const decrypter = (str) => {
  let prefix = str[0];
  str = str.substring(1);
  let index = keyStr.indexOf(prefix) * -1;
  let strArr = [];

  for (let i = 0; i < str.length; i++) {
    strArr.push(changeCharacters(str[i], index));
  }
  return strArr.join("");
};

// console.log(decrypter("ynijf%tk%rnsj"));

// export
export const encryptObject = (obj) => {
  if (!(obj instanceof Object)) return null;
  let newObj = {};
  for (let elm in obj) {
    if (!obj[elm]) continue;
    if (typeof elm === "boolean" || obj[elm] instanceof Object) {
      newObj[elm] = obj[elm];
    } else {
      newObj[elm] = encrypter(obj[elm]);
    }
  }
  return newObj;
};

const decryptObject = (obj) => {
  if (!(obj instanceof Object)) return null;
  let newObj = {};
  for (let elm in obj) {
    if (typeof elm === "boolean") {
      newObj[elm] = obj[elm];
    } else {
      newObj[elm] = decrypter(obj[elm]);
    }
  }
  return newObj;
};

// console.log(encryptObject({ card: 123333333, cvc: "111" }));
// console.log(decryptObject({ card: "t567777777", cvc: "t555" }));
