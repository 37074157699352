import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const OverallBrandNameConatiner = styled(Box)`
  width: 100%;
`;
export const BrandNameBox = styled(Box)`
  width: 95%;
  margin: 2rem auto;
`;
export const BadgeBox = styled(Box)`
  color: var(--White-Color, #fff);
  font-family: "DM Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #007bf7;
`;

export const BrandName = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #1a1b24;
`;
export const TabDetails = styled(Box)`
  width: 95%;
  display: flex;
  margin: 1rem auto;
  justify-content: space-between;
  // align-items:center;
  flex-direction: column;
`;
export const TabContainer = styled(Box)`
  width: 30%;
  display: flex;
  // justify-content:space-between;
  align-items: flex-start;
  flex-direction: column;
  // background:#eee;
`;
export const TabSelected = styled(Tab)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props?.isMobile ? "12px" : "14px")};
  padding: ${(props) => props?.isMobile && "12px 6px"};

  text-transform: capitalize;

  // width: ${(props) => props?.isMobile && "25%"};
  min-width: ${(props) => props?.isMobile && "0%"};
  &.Mui-selected {
    background: #e9e9f2;
    color: black;
  }
`;
export const DetailsContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const OrganizationDetails = styled(Box)`
  // width:100%;
  display: flex;
  // justify-content:flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
export const OrganizationDetailsText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  margin-bottom: 10px;
  color: #1a1b24;
`;
export const OrganizationDetailsTextTwo = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  color: #1a1b24;

  opacity: 0.6;
`;

export const OverviewBox = styled(Box)`
  width: 100%;
  margin-top: 2rem;
`;
export const EditBrand = styled(Box)`
  width: 12%;
  height: 44px;
  left: 1188px;
  top: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #8d6cff;
  border: 1px solid #8d6cff;
  border-radius: 7px;
  margin-left: 1rem;
`;
export const EditBrandText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const DeleteBrand = styled(Box)`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12%;
  margin-left: 1rem;
  justify-content: center;
  height: 44px;
  left: 1294px;
  top: 145px;

  border: 1px solid #1a1b24;
  border-radius: 7px;
`;
export const DeleteBrandText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const Overview = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  color: #1a1b24;
`;
export const BrandAnalyticsText = styled(Typography)`
  height: 14px;
  left: 1096px;
  top: 45px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #1a1b24;
`;
export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#8D6CFF" : "#308fe8",
  },
}));

export const SeeMore = styled(Box)`
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  // padding: 12px 30px;
  // gap: 10px;

  // width: 125px;
  // height: 40px;

  // /* White Color */

  // background: #ffffff;
  // /* Element */

  // border: 1px solid #d4dae1;
  // border-radius: 15px;

  // /* Inside auto layout */

  // flex: none;
  // order: 1;
  // flex-grow: 0;

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 10px;
  position: relative;
  top: 50%;
  width: 97px;
  height: 35px;

  /* White Color */

  background: #ffffff;
  /* Element */

  border: 1px solid #d4dae1;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin-top: 0.9rem;
`;
export const SeeMoreText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const CountriesText = styled(Typography)`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  // height:30px;
  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;
  //  margin-left: 1rem
`;
export const CountriesView = styled(Typography)`
  //  height: 32px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`;
export const RequestSubText = styled(Typography)`
  // width: 126px;
  // height: 28px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 75% */

  color: #53545b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 2rem;
`;
export const RequestContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  // width: 100%;
  // height: 56px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const EmailFormContainer = styled(Box)`
  height: auto;
  max-height: 500px; /* Set the desired maximum height */

  padding: 10px 10px 9px 10px;
  overflow-y: auto;
  width: ${(props) => (props?.isMobile ? "100%" : "75%")};
  border: 1px solid #8c8d91;
  border-radius: 10px;
  margin-bottom: ${(props) => (props?.isMobile ? "50px" : undefined)};
`;
export const EmailForm = styled(Box)`
  display: flex;
  flex-direction: column; //
`;
export const EmailFormList = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const EmailItem = styled(Box)`
  // display: flex;
  //   align-items: center;
  //   padding: 5px;
  //   background-color: #f5f5f5;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  gap: 10px;
  border: 1px solid ${({ isValid }) => (isValid ? "none" : "#D93047")};
  // width: 185px;
  // height: 40px;

  /* Primary/French Blue/French Blue 25% */

  background: ${({ isValid }) => (isValid ? "#D9EAF8" : "#FFF5F5")};
  border-radius: 12px;
  width: fit-content;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const EmailItemText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: ${({ isValid }) => (isValid ? "#1A1B24" : "#D93047")};

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const EmailItemImage = styled(Box)`
  & > img {
    color: ${({ isValid }) => (isValid ? "#1A1B24" : "#D93047")};
  }
`;
export const CopyLinkText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const ShareHeader = styled(Typography)`
color:  #1A1B24
font-family: DM Sans;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 150%;
`;
export const ShareText = styled(Typography)`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;
export const OptionsText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  /* identical to box height */

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const DeleteHeader = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  /* identical to box height, or 36px */

  margin-top: 16px;
  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */
`;
export const DeleteText = styled(Typography)`
  font-family: "DM Sans Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  margin-top: 16px;
  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;

  /* Inside auto layout */
`;
export const DeleteButtonText = styled(Typography)`
  // width: 53px;
  // height: 24px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  text-transform: capitalize;
  /* identical to box height, or 24px */

  text-align: center;

  /* Secondary Main */

  /* Inside auto layout */
`;
export const DeleteButton = styled(Button)`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px;
  gap: 10px;

  width: 138px;
  height: 48px;

  /* White */

  /* Element */

  border: 1px solid #d4dae1;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  &:hover {
    background-color: #d93047;
  }
`;
