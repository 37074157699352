import React, { useState } from "react";
import { PreviousButton } from "../../pages/OrganizationBrands/styled";
import EllipsisLinkBrand from "../EllipsisLink/EllipsisLink";
import { DELETE_BRAND } from "../../graphql/mutations/brandMutations";
import { useMutation, useQuery } from "@apollo/client";
import { Snackbar } from "@mui/material";
import UpdateBrandDialog from "../UpdateBrandDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteButton,
  DeleteButtonText,
  DeleteHeader,
  DeleteText,
  OptionsText,
} from "../../pages/Organizations/styled";
import EditIcon from "../../assets/icons/EditIcon.svg";
import Trash from "../../assets/icons/Trash.svg";
import ModalTrash from "../../assets/icons/ModalTrash.svg";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import { useNavigate } from "react-router-dom";
import { GET_BRAND } from "../../graphql/queries/brandQueries";
import Modal from "react-modal";
import Spinner from "../Spinner";
import { setMenu } from "../../redux/menu.slice";
import BottomPopup from "../BottomPopup";
import { GET_USER_ORGANIZATION } from "../../graphql/queries/organizationQueries";

const customStyles = {
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
  content: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px 16px 24px 24px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "507px",
    height: "312px",
    background: "#FFFFFF",
    border: "1px solid #ECECF2",
    borderRadius: "15px",
  },
};

/**
 * A component that provides options for editing and deleting a brand.
 * @component
 * @param {Object} props - The component's props.
 * @param {Array} props.addOptionArray - An array of additional options to add to the dropdown menu.
 * @param {boolean} props.removeLine - A boolean indicating whether to remove the bottom border line from the last option.
 * @returns {JSX.Element} - The rendered component.
 */
function EditDeleteButton({
  addOptionArray = [],
  removeLine,
  containerStyle = {},
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const menu = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(GET_BRAND, {
    variables: { input: { _id: menu.id } },
  });

  const [deleteBrand] = useMutation(DELETE_BRAND, {
    variables: { input: { _id: menu.id } },
    update(cache, { data: { deleteBrand } }) {
      const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
      const { userOrganizations = [] } = existingData || {};

      const updatedUserOrganizations = userOrganizations.map(
        (userOrganization) => {
          if (userOrganization.organization._id === data?.brand?.organization) {
            const updatedBrands = [...userOrganization.brands, deleteBrand];

            return {
              ...userOrganization,
              brands: updatedBrands,
            };
          }
          return userOrganization;
        }
      );

      cache.writeQuery({
        query: GET_USER_ORGANIZATION,
        data: { userOrganizations: updatedUserOrganizations },
      });
    },
    refetchQueries: [{ query: GET_USER_ORGANIZATION }],
  });

  const handleDelete = async () => {
    try {
      await deleteBrand();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleModalConfirm = () => {
    handleDelete();
    handleCloseModal();
    setOpenSnackbar(true);
    setSnackbarMessage("Brand deleted successfully");
    dispatch(setMenu({ name: "", brandId: "", id: "" }));
    navigate("/");
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  /**
   * Generates the options for the dropdown menu.
   * @returns {Array} - An array of menu options.
   */
  const options = () => {
    let optionArr = [
      ...addOptionArray,
      {
        title: (
          <div
            style={{
              minWidth: "105px",
              display: menu?.owned ? "flex" : "none",
              alignItems: "center",
              // borderBottom: removeLine
              //   ? "none"
              //   : menu?.owned
              //   ? "1px solid #ECECF2"
              //   : "none",
              padding: "10px 0",
            }}
          >
            <img src={EditIcon} alt="edit-icon" />
            <OptionsText style={{ marginLeft: "10px" }}>Edit</OptionsText>
          </div>
        ),
        link: () => handleOpenDialog(),
      },
      {
        title: (
          <div
            style={{
              minWidth: "105px",
              display: "flex",
              alignItems: "center",
              padding: "10px 0",
            }}
          >
            <img src={Trash} alt="edit-icon" />
            <OptionsText style={{ marginLeft: "10px" }}>Delete</OptionsText>
          </div>
        ),
        link: () => handleOpenModal(),
      },
    ];
    if (!menu?.owned) optionArr.pop();
    return optionArr;
  };

  let Dropdown = isMobile ? BottomPopup : Modal;

  if (loading) return <Spinner />;

  return (
    <>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={3000}
      />

      <EllipsisLinkBrand
        content={options()}
        style={{ color: "black", ...containerStyle }}
      />

      <UpdateBrandDialog
        openDialog={dialogOpen}
        brand={data && data?.brand}
        onClose={() => setDialogOpen(false)}
      />

      <Dropdown
        isOpen={openModal}
        onRequestClose={handleCloseModal}
        ariaHideApp={false}
        style={customStyles}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <img
            src={RemoveIcon}
            style={{ cursor: "pointer" }}
            alt="modal-delete"
            onClick={handleCloseModal}
          />
        </div>

        <img
          src={ModalTrash}
          style={{ marginTop: "20px" }}
          alt="modal-delete"
        />

        <DeleteHeader>Delete this Brand? </DeleteHeader>
        <DeleteText>
          You are about to delete{" "}
          <span style={{ fontWeight: "600", color: "black" }}>
            {data?.brand.name}.
          </span>
        </DeleteText>
        <div className="d-flex" style={{ marginTop: "26px" }}>
          <DeleteButton onClick={handleCloseModal}>
            <DeleteButtonText sx={{ color: "#28262C" }}>
              {" "}
              Cancel
            </DeleteButtonText>
          </DeleteButton>
          <DeleteButton
            sx={{ background: "#D93047", marginLeft: "1rem" }}
            onClick={handleModalConfirm}
          >
            <DeleteButtonText sx={{ color: "white" }}> Delete</DeleteButtonText>
          </DeleteButton>
        </div>
      </Dropdown>
    </>
  );
}

export default EditDeleteButton;
