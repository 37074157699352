import styled from "styled-components";

export const PreviewWrapper = styled.div`
  display: flex;
  width: 95%;
  margin: 1rem auto;
  // height: 400px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #ececf2;
  background: #fff;

  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.07);
`;
export const ProfileName = styled.p`
  color: #171517;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin-bottom: 0px;
`;
export const ProfileText = styled.p`
  color: #858585;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 10.8px */
`;
export const RequestMessageTextWrraper = styled.div`
  display: flex;

  padding: 18px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 15px;
  background: #007bf7;
  width: 100%;
`;
export const RequestMessageText = styled.p`
  width: 100%;
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
`;
export const EmailName = styled.p`
  color: #1a1b24;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 0px;
`;
export const EmailText = styled.p`
  color: #1a1b24;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-bottom: 0px;
`;
export const ShareThoughts = styled.div`
  display: flex;
  height: 30px;
  width: 132px;
  padding: 9.17px 10.789px;
  justify-content: center;
  align-items: center;
  gap: 5.394px;
  border-radius: 7px;
  background: #1a1b24;
`;
export const ShareThoughtsText = styled.p`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15px */
  margin-bottom: 0px;
`;
export const RightsText = styled.p`
  color: #171517;
  leading-trim: both;
  text-edge: cap;
  font-family: Dm Sans;
  // font-size: 8px;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 9.71px;
  margin-bottom: 0px;
`;
