import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export const MembersContainer = styled(Box)`
  background: white;
  width: 100%;
`;
export const FlexContainer = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
`;
export const TitleHeader = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  gap: 24px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const LeftTitle = styled(Box)`
  // font-family: "Dm Sans";
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1a1b24;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const SubTitle = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #5d6272;
`;
export const SubTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
`;
export const TimeText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #0075ce;
`;

export const ContentContainer = styled(Box)``;
export const OptionText = styled(Box)`
  margin-left: 10px;
`;

export const OptionItem = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const OrganizationText = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 0;
  line-height: 20px;

  color: #1a1b24;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const OwnerText = styled(Box)`
  color: #5d6272;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 20px;
`;

export const InviteMemberText = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const InviteMemberMainText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;
