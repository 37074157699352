import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PaymentComp from "../../components/PaymentComp";
import { BoxCh } from "./styled";
import "../../App.css";

const CheckoutPg = () => {
  let { cartId } = useParams();
  const [items, setItems] = useState([]);
  const [showPay, setShowPay] = useState(false);

  const fetchItems = () => {
    let storedItem;
    try {
      storedItem =
        cartId === "cart-id"
          ? [
              {
                description: "Complete UI/UX Audit",
                id: "prod00001",
                name: "UI/UX Audit",
                price: 2.99,
              },
            ]
          : localStorage.getItem(`${cartId}`)
          ? JSON.parse(localStorage.getItem(`${cartId}`))
          : [];
    } catch {
      storedItem = [];
    }
    setItems(storedItem);
  };

  const deleteItem = (id) => {
    let storedItem = items.filter((el, index) => index !== id);
    setItems(storedItem);
    localStorage.setItem(cartId, JSON.stringify(storedItem));
  };
  let sum = 0;

  const displayItems = () => {
    if (!items || items.length === 0)
      return (
        <div>
          <h2>Checkout </h2>
          <h4>
            <em>No Cart Item</em>
          </h4>
        </div>
      );
    let arrHeader =
      cartId === "cart-id"
        ? ["Name", "Description", "Price"]
        : ["Name", "Description", "Price", "Action"];
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: 500,
          gap: "20px",
          boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.4)",
          height: "fit-content",
        }}
      >
        <div style={{ display: "flex" }}>
          {" "}
          <h2>Checkout </h2>{" "}
        </div>
        <div
          style={{
            borderRadius: "5px",
            backgroundColor: "#ccc",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              background: "#2349B6",
              padding: 10,
            }}
          >
            <div style={{ display: "flex", width: "100%", gap: "20px" }}>
              {arrHeader.map((el, idx) => (
                <BoxCh
                  style={{
                    color: "white",
                  }}
                  key={idx}
                >
                  {el}
                </BoxCh>
              ))}
            </div>
          </div>

          {items instanceof Array &&
            items.map((el, idx) => {
              sum += el.price;
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // background: idx % 2 === 0 ? "#ccc" : "#ddd",
                    background: "white",
                    padding: 10,
                  }}
                  key={idx}
                >
                  <div style={{ display: "flex", width: "100%", gap: "20px" }}>
                    <BoxCh style={{ color: "black" }}>{el.name}</BoxCh>
                    <BoxCh style={{ color: "black" }}>{el.description}</BoxCh>
                    <BoxCh style={{ color: "black" }}>${el.price}</BoxCh>
                    {cartId !== "cart-id" && (
                      <BoxCh
                        style={{
                          borderRadius: 5,
                          background: "whitesmoke",
                          color: "#2349B6",
                          height: 47,
                        }}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteItem(idx)}
                        >
                          delete
                        </span>
                      </BoxCh>
                    )}
                  </div>
                </div>
              );
            })}

          {sum > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                background: "#1A1B24",
                padding: 10,
              }}
            >
              <BoxCh
                style={{
                  background: "transparent",
                  color: "white",
                }}
              >
                TOTAL
              </BoxCh>
              <BoxCh>&nbsp;</BoxCh>
              <BoxCh style={{ color: "white" }}>${sum}</BoxCh>
              {cartId !== "cart-id" && (
                <BoxCh
                  onClick={() => setShowPay(true)}
                  style={{
                    cursor: "pointer",
                    borderRadius: 5,
                    background: "whitesmoke",
                    color: "#2349B6",
                    height: 47,
                  }}
                >
                  PAY
                </BoxCh>
              )}
            </div>
          )}
        </div>
        {cartId === "cart-id" && (
          <BoxCh
            onClick={() => setShowPay(true)}
            style={{
              cursor: "pointer",
              borderRadius: 5,
              width: "40%",
              color: "whitesmoke",
              alignSelf: "flex-end",
              // background: "#2349B6",
              height: 47,
              background: "#419b6c",
            }}
          >
            PAY
          </BoxCh>
        )}
      </div>
    );
  };

  const clearAllItems = () => {
    if (cartId === "cart-id") {
      window.location = "https://marketing.punch.cool/thankyou";
    } else {
      localStorage.removeItem(cartId);
      setItems([]);
      setShowPay(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        paddingTop: "50px",
        fontFamily: "Patron",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "80%",
          minWidth: "400px",
          justifyContent: "space-around",
          gap: "30px",
        }}
      >
        {displayItems()}
        <div
          style={{ width: "400px", visibility: showPay ? "visible" : "hidden" }}
        >
          <PaymentComp
            buttonTitle={`PAY $${sum}`}
            productInformation={items}
            productAmount={sum}
            addContact
            clearAllItems={clearAllItems}
            paymentOwner="Punch"
            noCurve
            fontToUse="Patron"
          />
        </div>
      </div>
    </div>
  );
};
export default CheckoutPg;
