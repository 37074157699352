import { gql } from "@apollo/client";

const GET_VIDEOS_BY_BRAND = gql`
  query VideosByBrandId($brandId: String!) {
    videosByBrandId(brandId: $brandId) {
      _id
      name
      email
      rating
      duration
      position
      createdAt
      url
      videoId
      originalVideoExtension
      originalImageExtension
      videoModified
      brand {
        _id
        name
        description
        sector
        brandId
      }
      approved
      disapproved
      organization
      phoneNumber
      phoneCode
    }
  }
`;
const GET_SINGULAR_VIDEO = gql`
  query video($input: GetVideoInput!) {
    video(input: $input) {
      _id
      name
      email
      rating
      duration
      position
      createdAt
      url
      videoId
      originalVideoExtension
      originalImageExtension
      videoModified
      brand {
        _id
        name
        description
        sector
        brandId
      }
      approved
      disapproved
    }
  }
`;
const GET_SINGULAR_VIDEO_BY_IDENTITY = gql`
  query singleVideo($input: GetVideoIdentityInput!) {
    singleVideo(input: $input) {
      _id
      name
      email
      createdAt
      senderUrl
      senderDuration
      videoId
      questions
      openToRecord
      videoIdentityNumber
      originalVideoExtension
      videoModified
      brand {
        _id
        name
        description
        sector
        brandId
      }
      approved
      disapproved
      position
      organization
      url
      duration
    }
  }
`;
const GET_SINGULAR_SENDER_VIDEO_BY_IDENTITY = gql`
  query singleSenderVideo($input: GetSenderVideoIdentityInput!) {
    singleSenderVideo(input: $input) {
      _id
      name
      email
      createdAt
      senderUrl
      senderDuration
      videoId
      questions
      phoneNumber
      openToRecord
      videoIdentityNumber
      originalVideoExtension
      videoModified
      createdBy {
        _id
        name
        email
      }
      brand {
        _id
        name
        description
        sector
        brandId
      }
      approved
      disapproved
      position
      organization
      url
      duration
    }
  }
`;
const GET_VIDEOS = gql`
  query approvedVideosByBrandIdAndTrue($brandId: String!) {
    approvedVideosByBrandIdAndTrue(brandId: $brandId) {
      _id
      url
      videoId
      position
      name
      rating
      duration
      createdAt
      organization
      originalVideoExtension
      originalImageExtension
      videoModified
      brand {
        position
        backgroundColor
        width
        playerIcon
        borderSize
        color
      }
    }
  }
`;
const APPROVED_VIDEOS_COUNT = gql`
  query approvedVideoCount($brandId: String!) {
    approvedVideoCount(brandId: $brandId)
  }
`;
const COUNT_VIDEOS = gql`
  query countVideos($brandId: String!) {
    countVideos(brandId: $brandId)
  }
`;
const DISAPPROVED_VIDEOS_COUNT = gql`
  query disapprovedVideoCount($brandId: String!) {
    disapprovedVideoCount(brandId: $brandId)
  }
`;
const APPROVED_VIDEOS_DATE_COUNT = gql`
  query approvedVideoDateCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    approvedVideoDateCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      percentChange
      startDate
      endDate
    }
  }
`;
const DISAPPROVED_VIDEOS_DATE_COUNT = gql`
  query disapprovedDateVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    disapprovedDateVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      percentChange
      startDate
      endDate
    }
  }
`;
const DISAPPROVED_VIDEOS_DAYS_COUNT = gql`
  query disapprovedDaysVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    disapprovedDaysVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      date
      percentChange
    }
  }
`;

const APPROVED_VIDEOS_DAYS_COUNT = gql`
  query approvedDaysVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    approvedDaysVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      date
      percentChange
    }
  }
`;
const NEW_VIDEOS_COUNT = gql`
  query newVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    newVideoCount(brandId: $brandId, endDate: $endDate, startDate: $startDate) {
      count
      percentChange
      startDate
      endDate
    }
  }
`;
const NEW_VIDEOS_DAYS_COUNT = gql`
  query newDaysVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    newDaysVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      date
      percentChange
    }
  }
`;
const RATING_DAYS_COUNT = gql`
  query ratingDaysVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    ratingDaysVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      rating
      date
      percentChange
    }
  }
`;
const RATING_AVERAGE = gql`
  query getAverageRating(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    getAverageRating(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    )
  }
`;
const GET_VIDEOS_DATE = gql`
  query videos($startDate: String!, $endDate: String!, $brandId: String!) {
    videos(brandId: $brandId, endDate: $endDate, startDate: $startDate) {
      duration
    }
  }
`;
export {
  GET_VIDEOS_BY_BRAND,
  GET_VIDEOS,
  GET_SINGULAR_VIDEO,
  APPROVED_VIDEOS_COUNT,
  DISAPPROVED_VIDEOS_COUNT,
  COUNT_VIDEOS,
  NEW_VIDEOS_COUNT,
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_DAYS_COUNT,
  APPROVED_VIDEOS_DAYS_COUNT,
  NEW_VIDEOS_DAYS_COUNT,
  RATING_DAYS_COUNT,
  RATING_AVERAGE,
  GET_VIDEOS_DATE,
  GET_SINGULAR_VIDEO_BY_IDENTITY,
  GET_SINGULAR_SENDER_VIDEO_BY_IDENTITY,
};
