import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  DatePickerContainerStyles,
  NodeModalContainerStyle,
} from "./index.styled";
import { Box, Typography } from "@mui/material";
import { CreateButton } from "../../components/TagsModal/styled";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { useNodes } from "reactflow";

const DatePickerModalContent = ({ selected, saveNode, closeModals }) => {
  const [startDate, setStartDate] = useState(new Date());
  // console.log({ startDate }, new Date(selected?.data?.metadata?.value));
  const nodes = useNodes();
  useEffect(() => {
    // console.log("mounted timer");
    const currentNode = nodes.find(({ id }) => id === selected?.id);
    setStartDate(
      currentNode?.data?.metadata?.value
        ? new Date(currentNode?.data?.metadata?.value)
        : new Date()
    );
  }, [selected]);

  let currentDate = new Date(); // Create a new Date object with the current time

  // Create another Date object for the current time plus 2 hours
  let futureDate = new Date();
  futureDate.setHours(currentDate.getHours() + 2);
  // const isValid = startDate > futureDate;
  const isValid = true;
  return (
    <div>
      <NodeModalContainerStyle
        sx={{ minHeight: "300px", width: "520px !important" }}
      >
        <Typography
          sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
          id="modal-modal-title"
          variant="h5"
        >
          Select Date and Time
        </Typography>
        <Box>
          <Box
            sx={{
              mt: 2,
              px: 2,
              py: 1.5,
              pb: 6,
              borderTop: "1px solid #E5E3E3",
            }}
          >
            <DatePickerContainerStyles
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
              >
                {format(startDate, "yyyy-MM-dd HH:mm aa")}
              </Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="MM/dd/yyyy h:mm "
                showTimeInput
                minDate={new Date()}
                timeInputLabel=""
                customTimeInput={<ExampleCustomTimeInput />}
                inline
              />
            </DatePickerContainerStyles>
          </Box>

          {!isValid && (
            <Typography align="center" color={"red"}>
              date too close or invalid
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              px: 2,
              py: 2,
              borderTop: "1px solid #E5E3E3",
            }}
          >
            <CreateButton
              onClick={() => {
                let currentDate = new Date(); // Create a new Date object with the current time

                // Create another Date object for the current time plus 2 hours
                let futureDate = new Date();
                // futureDate.setHours(currentDate.getHours() + 2);

                if (true) {
                  saveNode({
                    ...selected,
                    data: {
                      ...selected?.data,
                      metadata: {
                        ...selected?.data?.metadata,
                        value: startDate.toISOString(),
                      },
                    },
                  });
                  closeModals();
                }
              }}
              type="submit"
              color="primary"
            >
              Select
            </CreateButton>
          </Box>
        </Box>
      </NodeModalContainerStyle>
    </div>
  );
};

export default DatePickerModalContent;

function convertToValidPattern(timeString) {
  var parts = timeString.split(":");
  var hours = parseInt(parts[0]);
  var minutes = parseInt(parts[1]);

  // If hours or minutes are NaN (not a number), set them to 0
  if (isNaN(hours)) {
    hours = 0;
  }
  if (isNaN(minutes)) {
    minutes = 0;
  }

  // Format hours and minutes with leading zeros if necessary
  var formattedHours = hours.toString().padStart(2, "0");
  var formattedMinutes = minutes.toString().padStart(2, "0");

  return formattedHours + ":" + formattedMinutes;
}

const ExampleCustomTimeInput = ({ date, value, onChange }) => {
  // console.log({ value });
  return (
    <input
      className="time-input"
      value={value}
      type="time"
      onChange={(e) => {
        // console.log("target value", e.target.value);
        if (e.target.value) {
          // console.log("passed this why?");
        }
        var enteredTime = e.target.value;
        var convertedTime = convertToValidPattern(enteredTime);

        e.target.value = convertedTime;
        onChange(convertedTime);
      }}
    />
  );
};
