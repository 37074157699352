import { gql } from "@apollo/client";

export const FETCH_SALES_PARAMS = gql`
  query {
    fetchSalesParameters {
      name
      title
    }
  }
`;

export const FETCH_SALES_WORKFLOW = gql`
  query {
    fetchSalesAppWorkFlow {
      _id
      step
      from
      to
      jobCategory
      fromEmails
      subjects
      enabled
      templates {
        _id
        title
        imageData
      }
    }
  }
`;

export const FETCH_JOB_CATEGORIES = gql`
  query fetchJobCategories {
    fetchJobCategories {
      name
    }
  }
`;

export const FETCH_SALES_FIELDS = gql`
  query {
    fetchSalesParameters {
      data
    }
  }
`;
