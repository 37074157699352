import { Box } from "@mui/material";
import {
  LeftTopSubTitle,
  StepTitle,
} from "../Send-Testimonial/ReviewComp/styled";
import { VideoSlider } from "../Send-Testimonial/ReviewComp";

export default ({
  isMobile,
  videoData,
  currentIndex,
  handleNextVideo,
  handlePrevVideo,
  displayForm,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: isMobile ? "100%" : "92%",
          // display: "flex",

          // alignItems: "center",
          position: "relative",
        }}
      >
        <StepTitle style={{ fontSize: "32px" }}>
          Preview your Testimonial
        </StepTitle>

        <LeftTopSubTitle>
          {" "}
          Below is what your review will look like after submitting
        </LeftTopSubTitle>

        <Box sx={{ width: "100%", margin: "32px auto" }}>
          <Box
            sx={{
              width: "332px",
              margin: "0 auto",
              height: "585px",
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
              padding: "6px",
              borderRadius: "16px",
              background: "#FFF",

              /* widget shadow */
              boxShadow: "0px 3px 30px 0px rgba(0, 0, 0, 0.11)",
              backdropFilter: "blur(26px)",
            }}
          >
            <Box
              sx={{
                width: "320px",
                margin: "0 auto",
                height: "573px",
              }}
            >
              <VideoSlider
                videoData={videoData}
                currentIndex={currentIndex}
                onNext={handleNextVideo}
                onPrev={handlePrevVideo}
              />
            </Box>
          </Box>
        </Box>
        <StepTitle>Your Basic Info</StepTitle>

        <LeftTopSubTitle style={{ marginBottom: "16px" }}>
          {" "}
          Your Info below will appear as show in the preview
        </LeftTopSubTitle>

        {displayForm()}

        {/* <ColorButton
                          title={"Add Questions"}
                          background={"#F1F3EC"}
                          greyBg={"greyBg"}
                          width={"180px"}
                          image={Plus}
                          order={true}
                          onClick={openModalQuestion}
                        /> */}
      </Box>
    </Box>
  );
};
