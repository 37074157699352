import { gql } from "@apollo/client";

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($input: AcceptOrganizationInviteInput!) {
    acceptInvitation(input: $input) {
      _id
      accepted
    }
  }
`;
export const DECLINE_INVITE = gql`
  mutation declineInvite($input: DeclineInviteInput!) {
    declineInvite(input: $input) {
      _id
        userId
        createdAt
       notificationMessage{
         message
         type
        status
        invitationCode
        videoName
        videoPosition
        videoRating
        recent
        brandAccessName{
          brand
          access
          brandColor
        }
        thumbnail
        createdAt
        notificationId
      
       }
    }
  }
`;