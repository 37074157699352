import { GET_BRAND_BY_ORGANIZATION } from "../graphql/queries/organizationQueries";
import { useQuery } from "@apollo/client";
import React from "react";
import Spinner from "./Spinner";

function OrganizationBrands({ organization }) {
  const { loading, error, data } = useQuery(GET_BRAND_BY_ORGANIZATION, {
    variables: { id: organization._id },
  });
 

//  if (loading) return <Spinner />; 
  if (error) return <p>Something Went Wrong</p>;
  return (
    <>
      {!loading && !error && (
        <>
          <h3 className="mt-5">Brands Under {organization.name} </h3>
          <table className="table table-hover mt-3 container">
            <thead>
              <tr>
                <th>Name</th>

                <th>Industry</th>
              </tr>
            </thead>
            <tbody>
              {data.brandsOrganization.map((brand) => (
                <tr key={brand.brandId}>
                  <td>{brand.name}</td>

                  <td>{brand.sector}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default OrganizationBrands;
