import React, { useState } from "react";
import {
  ButtonWrapper,
  GenericText,
  ImagePlay,
  LeftTopSubTitle,
  QuestionsBox,
  QuestionsNo,
  QuestionsOverallBox,
  QuestionsText,
  StepTitle,
  StopRecordButton,
  VideoWrapper,
} from "./styled";
import PlayButton from "../../../assets/images/play.svg";
import BlockedAccess from "../../../assets/images/BlockedAccess.svg";
import RemoveIcon from "../../../assets/icons/RemoveIcon.svg";
import NewArrow from "../../../assets/icons/NewArrow.svg";
import UploadWhite from "../../../assets/icons/UploadWhite.svg";
import PermissionBox from "../../../assets/images/PermissionBox.svg";
import Play from "../../../assets/images/play.svg";
import PermissionIcon from "../../../assets/images/PermissionIcon.svg";

import Microphone from "../../../assets/icons/Microphone.svg";
import PermissionVector from "../../../assets/images/PermissionVector.svg";
import RecordUpload from "../../../assets/images/teenage-female.jpg";
import RecordStart from "../RecordComp";
import PlayRecord from "../PlayRecord";
import { useEffect } from "react";
import ColorButton from "../../ColorButton";
import Camera from "../../../assets/icons/Camera.svg";
import Modal from "react-modal";
import { Box } from "@mui/material";
import Webcam from "react-webcam";
import SpinnerBig from "../../SpinnerBig";
import { DeleteHeader, DeleteText } from "../../../pages/Organizations/styled";
import { useRef } from "react";
import RecordButton from "../../RecordButton";
import ReactPlayer from "react-player";
import RecordRTC from "recordrtc";
const customStyles = {
  overlay: {
    background: "white",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "none",
    width: "100vw",
    height: "100vh",
    padding: "0px",
    border: "none",
    overflow: "none",
  },
};
const customStylesThree = {
  overlay: {
    background: "black",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "none",
    width: "100vw",
    height: "100vh",
    padding: "0px",
    border: "none",
    overflow: "none",
  },
};
const customStylesTwo = {
  overlay: {
    background: "rgba(0, 0, 0,0.4)",
  },
  content: {
    top: "0%",
    left: "0%",
    background: "none",
    width: "95vw",
    height: "100vh",
    padding: "0px",
    border: "none",
    overflow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "2.5vw",
  },
};

/**
 * Component for reviewing and recording video.
 * @param {Object} props - Component properties.
 */
const ReviewRecord = ({
  videoInfo,
  setRecordingData,
  setNewStep,
  recordingData,

  // stRecord,
  // setCounterStart,
  // counterStart,
  // videoUrl,
  // startRecording,
  // stopRecording,
  isMobile,
  isVideoOff,
  openModal,
  setOpenModal,
  setGetPermission,
  setGetPermissions,
  trail,
  setRecordedVideoUrlSections,
  recordedVideoUrlSections,
  recordedPicUrlSections,
  setRecordedPicUrlSections,
  newVideoUrl,
  setNewVideoUrl,
  newPicUrl,
  setNewPicUrl,
  openCam,
  setOpenCam,
  runPlayHandle,
  setRunPlayHandle,
  addVideoData,
}) => {
  const webcamRef = useRef();
  const [stRecord, setStRecord] = useState();
  const [startViewing, setStartViewing] = useState(true);
  const [videoUrl, setVideoUrl] = useState(newVideoUrl);
  const [picUrl, setPicUrl] = useState(newPicUrl);
  const [counterStart, setCounterStart] = useState();
  const [duration, setDuration] = useState(trail.duration);
  const [permissions, setPermissions] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const [recordedVideoUrls, setRecordedVideoUrls] = useState(
    recordedVideoUrlSections
  );
  const [recordedPicUrls, setRecordedPicUrls] = useState(
    recordedPicUrlSections
  );
  const [countdownTimer, setCountdownTimer] = useState();
  const [recorder, setRecorder] = useState(null);
  const [buttonDisplay, setButtonDisplay] = useState(false);
  const [stream, setStream] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [recordingQuestions, setRecordingQuestions] = useState(false);
  const [questionDisplay, setQuestionDisplay] = useState(false);
  const [display, setDisplay] = useState(true);
  function onRequestAudioVideoPermissions() {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(function (cameraStream) {
          setPermissions(true);
          setStream(cameraStream);

          setOpenCameraModal(true);
          setVideoUrl("");
          setButtonDisplay(false);
        })
        .catch(function (error) {
          console.error(error);
          setOpenBlockModal(true);
        });
    } else {
      alert("This browser does not support getUserMedia.");
    }
  }
  // console.log(openCameraModal, openCam, "openCameraModal");
  useEffect(() => {
    /**
     * Checks camera and microphone permissions.
     */
    const checkPermissions = async () => {
      try {
        const cameraPermission = await navigator.permissions.query({
          name: "camera",
        });
        const microphonePermission = await navigator.permissions.query({
          name: "microphone",
        });

        if (
          cameraPermission.state === "granted" &&
          microphonePermission.state === "granted"
        ) {
          setPermissions(true);
          // onRequestAudioVideoPermissions(); // Start displaying the camera stream
        } else {
          setPermissions(false);
        }
      } catch (err) {
        // console.log(err);
        setPermissions(false);
      }
    };

    checkPermissions();
  }, []);
  useEffect(() => {
    // if (permissions) {
    //   setOpenCameraModal(true);
    // }
    if (openCam) {
      onRequestAudioVideoPermissions();
      // setOpenCameraModal(!openCameraModal)
    }
    setGetPermission(permissions);
    setGetPermissions(permissions);
  }, [permissions, openCam]);
  useEffect(() => {
    setNewVideoUrl(videoUrl);
    setNewPicUrl(picUrl);
    setRecordedVideoUrlSections(recordedVideoUrls);
    setRecordedPicUrlSections(recordedPicUrls);
  }, [recordedVideoUrls, videoUrl, picUrl, recordedPicUrls]);
  useEffect(() => {
    setVideoUrl(newVideoUrl);
    setPicUrl(newPicUrl);

    setRecordedVideoUrls(recordedVideoUrlSections);
    setRecordedPicUrls(recordedPicUrlSections);
  }, [
    recordedVideoUrlSections,
    newVideoUrl,
    newPicUrl,
    recordedPicUrlSections,
  ]);
  // console.log(
  //   picUrl,
  //   "picUrl",
  //   recordedPicUrls,
  //   "recordedVideoUrls",
  //   recordedPicUrlSections
  // );
  const startRecording = () => {
    setStRecord(true);

    setCounterStart(3);
    setStartViewing(true);
  };
  const pauseRecording = () => {
    setStRecord("pause");
    // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };
  const resumeRecording = () => {
    setStRecord("resume");
  };
  const restartRecording = async () => {
    await stopRecording(); // Stop the previous recording if it's ongoing
    // console.log("restart stopped");

    await setStRecord(undefined);
    // console.log("restart setstRecord ");
    setCurrentQuestionIndex(0);
    // console.log("restart currentindex");

    await onRequestAudioVideoPermissions();
    // console.log("restart stream ");

    // The code below will not be executed after reloading the page
    // console.log("restart Page reloaded");

    // Start a new recording
    await startRecording();
    // console.log("restart continued");
  };

  const nextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    pauseRecording();
  };
  // console.log("Stop recording...", isVideoOff);
  const stopRecording = () => {
    setStRecord(false);
    // setOpenCameraModal(false);
    // setPermissions(false)
    setStartViewing(false);
    setCurrentQuestionIndex(0);
    // console.log("Stop recording...");
  };
  const trailComp = () => {
    setNewStep(1);
    // console.log("success");
  };

  // useEffect(() => {
  //   // Define the event listener function
  //   const handleDeviceChange = async () => {
  //     console.log("Camera or microphone permission has changed");
  //     // Perform necessary actions based on the permission change
  //     try {
  //       navigator.mediaDevices.getUserMedia({ audio: true, video: true })
  //       .then(function(stream) {
  //         console.log('You let me use your mic!')
  //       })
  //       .catch(function(err) {
  //         console.log('No mic for you!')
  //       });
  //       setPermissions(true);
  //     } catch (err) {
  //       console.log(err);
  //       setPermissions(false);
  //     }
  //   };
  //   handleDeviceChange();
  //   // Add event listener for device change
  //   navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     navigator.mediaDevices.removeEventListener(
  //       "devicechange",
  //       handleDeviceChange
  //     );
  //   };
  // }, []);

  const handleCloseBlockModal = () => {
    setOpenBlockModal(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseCameraModal = () => {
    setOpenCameraModal(false);
    setOpenCam(false);
    // setPermissions(false);
    setButtonDisplay(false);
    setRunPlayHandle(false);
  };
  const playHandler = () => {
    setOpenCameraModal(true);
    setPermissions(true);
    setButtonDisplay(true);
  };
  useEffect(() => {
    if (runPlayHandle) {
      playHandler();
    }
  }, [runPlayHandle]);
  const addVideosArray = () => {
    recordedVideoUrls.push(videoUrl);
    setRecordedVideoUrls([...recordedVideoUrls]);
    setOpenCameraModal(false);
    setOpenCam(false);
    addVideoData(videoUrl, duration);
  };
  useEffect(() => {
    setRecordingData({ picUrl, videoUrl, duration });
  }, [picUrl, videoUrl, duration]);
  // console.log(stRecord, "stRecord", videoUrl, picUrl);
  // console.log(videoUrl, "videoUrl");
  // console.log(!(!stRecord && videoUrl));
  // console.log(startViewing, "startViewings");
  // console.log(newVideoUrl, "newVideoUrl", videoUrl);
  // console.log(currentQuestionIndex, "hello", videoUrl);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  return (
    <div
      style={{
        width: "100%",
        background: permissions ? "#ECE8FF" : "#ECE8FF",
        // height: isMobile ? "100%" : "468px",
        borderRadius: "24px",
        display: "flex",
        border: "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <VideoWrapper
        videoInfo={videoInfo}
        // url={picUrl}
        newVideoUrl={newVideoUrl}
        // showBackground={stRecord !== "play"}
        isMobile={isMobile}
        permissions={permissions}
      >
        {permissions ? (
          <>
            {/* {stRecord === undefined && stRecord !== "play" && ( */}
            <Modal
              isOpen={permissions ? openCameraModal : true}
              Close={handleCloseCameraModal}
              style={customStylesThree}
            >
              {!(!stRecord && videoUrl) && (
                <RecordStart
                  startViewing={startViewing}
                  stRecord={isVideoOff ? false : stRecord}
                  setVideoUrl={setVideoUrl}
                  setPicUrl={setPicUrl}
                  setStRecord={setStRecord}
                  setCounterStart={setCounterStart}
                  isMobile={isMobile}
                  counterStart={counterStart}
                  setDuration={setDuration}
                  videoInfo={videoInfo}
                  permissions={permissions}
                  setRecordedPicUrls={setRecordedPicUrls}
                  recordedPicUrls={recordedPicUrls}
                  setCountdownTimer={setCountdownTimer}
                  stream={stream}
                  questions={videoInfo?.questions}
                  setCurrentQuestionIndex={setCurrentQuestionIndex}
                  setDisplay={setDisplay}
                  setRecordingQuestions={setRecordingQuestions}
                  setQuestionDisplay={setQuestionDisplay}
                />
              )}
              {(stRecord === "play" ||
                (videoUrl && stRecord !== "play" && stRecord !== true)) && (
                <>
                  <PlayRecord
                    url={videoUrl}
                    playingRecentVideo={stRecord === "play"}
                    videoTime={duration || 0}
                    setStRecord={setStRecord}
                    setNewStep={setNewStep}
                    setCounterStart={setCounterStart}
                    setStartViewing={setStartViewing}
                    startViewing={startViewing}
                    isMobile={isMobile}
                    setOpenCam={setOpenCam}
                    closeModal={handleCloseCameraModal}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "90%",
                      gap: "50px",
                      // height: "100%",
                      margin: "2rem auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "200px",
                        background: "#2C2C2C",
                        borderRadius: "14px",

                        height: "48px",
                        padding: "12px",
                      }}
                    >
                      <LeftTopSubTitle
                        style={{ color: "white", fontSize: "16px" }}
                      >
                        Your Testimonial
                      </LeftTopSubTitle>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50px",
                          borderRadius: "4px",
                          background: "#FC003F",
                          height: "27px",
                          fontSize: "14px",
                          lineHeight: "10px",
                          color: "white",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontFamily: "Dm Sans",
                        }}
                      >
                        {duration ? formatTime(duration) : "0:00"}
                      </div>
                    </div>
                    {!buttonDisplay ? (
                      <ColorButton
                        pointerEvents="auto"
                        // pointerEvents={
                        //   recordingData.videoUrl ? "auto" : "none"
                        // }
                        title={"Continue"}
                        background={"#007BF7"}
                        width={"125px"}
                        onClick={() => {
                          addVideosArray();
                        }}
                      />
                    ) : null}
                  </div>
                </>
              )}
              {!stRecord &&
                !videoUrl && ( //this will be a function..stRecord !== "play" &&
                  <>
                    <div
                      style={{
                        flexDirection: "column",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "100vh",
                        position: "absolute",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "end",
                          zIndex: "1",
                          top: isMobile ? "77%" : "58%",
                        }}
                      >
                        <div>
                          {videoUrl && (
                            <ImagePlay
                              src={PlayButton}
                              isMobile={isMobile}
                              style={{ zIndex: 8000 }}
                              onClick={() => {
                                setStRecord("play");
                                setStartViewing(false);
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: display ? "flex" : "none",
                          width: "100%",
                          margin: "0 auto",
                          flexDirection: "column",
                          gap: "15px",
                          position: "absolute",
                          zIndex: 2,
                          marginBottom: "85rem",
                        }}
                      >
                        {videoInfo?.questions.map((question, index) => (
                          <li
                            key={index}
                            style={{
                              marginBottom: "10px",
                              listStyleType: "none",
                              display: "flex",
                              color: "#5D6272",
                              width: "90%",
                              maxWidth: "500px",
                              margin: "0 auto",
                              height: "54px",
                              padding: "12px 12px 12px 16px",
                              gap: "15px",
                              alignItems: "center",
                              background: "white",
                              borderRadius: "14px",
                            }}
                          >
                            Q{index + 1}: {question}
                          </li>
                        ))}
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          height: isMobile ? "100%" : "85%",
                          width: "100%",
                          display: "flex",
                          bottom: "-53%",
                          alignItems: "end",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            // justifyContent: "space-evenly",
                            gap: "5px",
                            alignItems: "center",
                            zIndex: 8000,
                            paddingBottom: "20px",
                          }}
                        >
                          {/* <ColorButton
                        background={"#FF9042"}
                        title={picUrl ? "Record Again" : "Record"}
                        width={isMobile ? "43%" : "34%"}
                        image={Camera}
                        isMobile={isMobile}
                        onClick={startRecording}
                      /> */}
                          <p
                            style={{
                              color: "white",
                            }}
                          >
                            Click to Rec
                          </p>
                          <RecordButton
                            startStop={picUrl}
                            stRecord={stRecord}
                            counterStart={counterStart}
                            onClickStart={startRecording}
                            onClickStop={stopRecording}
                            onClickPause={pauseRecording}
                            onClickResume={resumeRecording}
                            currentQuestionIndex={currentQuestionIndex}
                            videoInfo={videoInfo}
                          />
                        </div>
                      </div>
                    </div>
                    <img
                      src={RemoveIcon}
                      style={{
                        cursor: "pointer",
                        marginRight: "24px",
                        position: "absolute",
                        top: 20,
                        left: 20,
                        zIndex: 100,
                        height: 30,
                      }}
                      alt="modal-delete"
                      onClick={handleCloseCameraModal}
                    />
                  </>
                )}

              {/* should Comment */}

              {stRecord === true && counterStart === 0 && (
                <div
                  style={{
                    // marginBottom: isMobile ? "7px" : "28px",
                    bottom: "0%",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                    zIndex: "5",
                    padding: "20px 0",
                  }}
                >
                  <div
                    style={{
                      width: "70px",
                      height: "30px",
                      background: "red",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      borderRadius: "7px",
                    }}
                  >
                    <p style={{ color: "white", marginBottom: "0px" }}>
                      {countdownTimer}
                    </p>
                  </div>

                  {/* )} */}

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-evenly",
                      gap: "5px",
                      alignItems: "center",
                      zIndex: 8000,
                      paddingBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "95%",
                        maxWidth: "450px",
                        margin: "25px auto",
                      }}
                    >
                      {currentQuestionIndex < videoInfo?.questions.length ? (
                        <div>
                          <p>
                            {recordingQuestions ? (
                              <QuestionsOverallBox
                                style={{
                                  color: "black",
                                  background: "white",
                                  padding: "10px",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  {" "}
                                  <QuestionsNo>
                                    Q{currentQuestionIndex + 1}{" "}
                                  </QuestionsNo>
                                  <QuestionsText>
                                    {" "}
                                    {videoInfo?.questions[currentQuestionIndex]}
                                  </QuestionsText>
                                </div>

                                {currentQuestionIndex + 1 <
                                  videoInfo?.questions.length && (
                                  <QuestionsBox
                                    onClick={nextQuestion}
                                    style={{ color: "black" }}
                                  >
                                    Next
                                  </QuestionsBox>
                                )}
                              </QuestionsOverallBox>
                            ) : (
                              ""
                            )}{" "}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <RecordButton
                      startStop={picUrl}
                      stRecord={stRecord}
                      counterStart={counterStart}
                      onClickStart={startRecording}
                      onClickStop={stopRecording}
                      onClickPause={pauseRecording}
                      onClickResume={resumeRecording}
                      currentQuestionIndex={currentQuestionIndex}
                      videoInfo={videoInfo}
                    />

                    {/* {stRecord === undefined ? null : (
                      <div
                        style={{
                          // marginBottom: isMobile ? "7px" : "28px",

                          width: "95%",
                          maxWidth: "450px",
                          margin:"0 auto"

                          display: "flex",
                          justifyContent: "space-between",

                          alignItems: "center",
                        }}
                      >
                        <ColorButton
                          background={"#1A1B24"}
                          title={"Restart"}
                          width={isMobile ? "43%" : "34%"}
                          isMobile={isMobile}
                          onClick={restartRecording}
                        />
                        <ColorButton
                          background={"#007BF7"}
                          title={"Done!"}
                          width={isMobile ? "43%" : "34%"}
                          isMobile={isMobile}
                          onClick={stopRecording}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              )}
              {(stRecord === "pause" || stRecord === "resume") &&
                counterStart === 0 && (
                  <div
                    style={{
                      // marginBottom: isMobile ? "7px" : "28px",
                      bottom: "0%",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      alignItems: "center",
                      zIndex: "5",
                    }}
                  >
                    <div
                      style={{
                        width: "70px",
                        height: "30px",
                        background: "red",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: "7px",
                      }}
                    >
                      <p style={{ color: "white", marginBottom: "0px" }}>
                        {countdownTimer}
                      </p>
                    </div>

                    <div>
                      {/* {recordingQuestions ? (
                    <div>
                      <p>{questions[currentQuestionIndex]}</p>
                      <button onClick={pauseRecording}>Pause</button>
                    </div>
                  ) : ( */}

                      {/* )} */}

                      <div></div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-evenly",
                        gap: "5px",
                        alignItems: "center",
                        zIndex: 8000,
                        paddingBottom: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "95%",
                          maxWidth: "450px",
                          margin: "25px auto",
                        }}
                      >
                        {questionDisplay &&
                          currentQuestionIndex <
                            videoInfo?.questions.length && (
                            <QuestionsOverallBox>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                <QuestionsNo>
                                  Q{currentQuestionIndex + 1}{" "}
                                </QuestionsNo>
                                <QuestionsText>
                                  {" "}
                                  {videoInfo?.questions[currentQuestionIndex]}
                                </QuestionsText>
                              </div>
                              {currentQuestionIndex + 1 <
                                videoInfo?.questions.length && (
                                <QuestionsBox
                                  onClick={nextQuestion}
                                  style={{ color: "black" }}
                                >
                                  next
                                </QuestionsBox>
                              )}
                            </QuestionsOverallBox>
                          )}

                        {!questionDisplay &&
                          currentQuestionIndex < videoInfo?.questions.length &&
                          recordingQuestions && (
                            <QuestionsOverallBox>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                <QuestionsNo>
                                  Q{currentQuestionIndex + 1}{" "}
                                </QuestionsNo>
                                <QuestionsText>
                                  {" "}
                                  {videoInfo?.questions[currentQuestionIndex]}
                                </QuestionsText>
                              </div>
                              {currentQuestionIndex + 1 <
                                videoInfo?.questions.length && (
                                <QuestionsBox
                                  onClick={nextQuestion}
                                  style={{ color: "black" }}
                                >
                                  next
                                </QuestionsBox>
                              )}
                            </QuestionsOverallBox>
                          )}
                      </div>
                      <div
                        style={{
                          width: "92%",
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "space-evenly",
                          gap: "5px",
                          alignItems: "center",
                          zIndex: 8000,
                          paddingBottom: "20px",
                        }}
                      >
                        {/* {currentQuestionIndex + 1 < videoInfo?.questions.length && (
  <>
    {stRecord === "pause" && (
      <p
        style={{
          color: "white",
        }}
      >
        Click to Resume
      </p>
    )}

    <RecordButton
      startStop={picUrl}
      stRecord={stRecord}
      counterStart={counterStart}
      onClickStart={startRecording}
      onClickStop={stopRecording}
      onClickPause={pauseRecording}
      onClickResume={resumeRecording}
      currentQuestionIndex={currentQuestionIndex}
      videoInfo={videoInfo}
    />
  </>
)} */}
                        {stRecord === "pause" && (
                          <p
                            style={{
                              color: "white",
                            }}
                          >
                            Click to Resume
                          </p>
                        )}

                        <RecordButton
                          startStop={picUrl}
                          stRecord={stRecord}
                          counterStart={counterStart}
                          onClickStart={startRecording}
                          onClickStop={stopRecording}
                          onClickPause={pauseRecording}
                          onClickResume={resumeRecording}
                          currentQuestionIndex={currentQuestionIndex}
                          videoInfo={videoInfo}
                        />
                        {stRecord === "pause" ? (
                          <div
                            style={{
                              // marginBottom: isMobile ? "7px" : "28px",

                              width: "100%",
                              maxWidth: "450px",

                              display: "flex",
                              justifyContent: "space-between",

                              alignItems: "center",
                            }}
                          >
                            <ColorButton
                              background={"#1A1B24"}
                              title={"Restart"}
                              width={isMobile ? "43%" : "34%"}
                              isMobile={isMobile}
                              onClick={restartRecording}
                            />
                            <ColorButton
                              background={"#007BF7"}
                              title={"Done!"}
                              width={isMobile ? "43%" : "34%"}
                              isMobile={isMobile}
                              onClick={stopRecording}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
            </Modal>
          </>
        ) : null}

        <Modal
          isOpen={openBlockModal}
          Close={handleCloseBlockModal}
          style={customStyles}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
            onClick={() => setOpenBlockModal(false)}
          >
            {/* <SpinnerBig mode="TUT" /> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}
            >
              <img
                src={RemoveIcon}
                style={{ cursor: "pointer", marginRight: "24px" }}
                alt="modal-delete"
                onClick={handleCloseBlockModal}
              />
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "69%",
                    margin: " 0 auto",
                  }}
                >
                  <StepTitle
                    style={{
                      marginTop: "16px !important",
                      marginBottom: "24px !important",
                    }}
                  >
                    Please enable camera & microphone permission
                  </StepTitle>
                </div>

                <LeftTopSubTitle sx={{ marginTop: "0px !important" }}>
                  You may have accidentally blocked the access
                </LeftTopSubTitle>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <img
                  src={BlockedAccess}
                  style={{ width: "100%" }}
                  alt="modal-delete"
                  onClick={handleCloseBlockModal}
                />
              </div>
              <LeftTopSubTitle sx={{ marginTop: "0px !important" }}>
                or
              </LeftTopSubTitle>

              <div
                style={{
                  width: "100%",
                  display: "flex",

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ColorButton
                  title={"Upload a video file"}
                  background={"#1A1B24"}
                  image={UploadWhite}
                  order={true}
                  width={"213px"}
                  onClick={() => {}}
                />
              </div>
              <LeftTopSubTitle sx={{ marginTop: "0px !important" }}>
                Max file size: 500MB
              </LeftTopSubTitle>

              <div
                style={{
                  width: "100%",
                  display: "flex",

                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={NewArrow}
                  style={{ width: "24px", height: "24px" }}
                  alt="arrow"
                />
                <GenericText color="#1A1B24" onClick={handleCloseBlockModal}>
                  Go back
                </GenericText>
              </div>
              <GenericText color="#007BF7">
                Having trouble recording?
              </GenericText>
            </div>
          </Box>
        </Modal>

        <Modal
          isOpen={openModal}
          Close={handleCloseModal}
          style={customStylesTwo}
        >
          <Box
            sx={
              {
                // position: "absolute",
                // top: isMobile ? "30%" : "10px",
                // left: isMobile ? "0" : "10px",
                // transform: "translate(-50%, -50%)",
                // width: isMobile ? "100%" : "60%",
                // bgcolor: "background.paper",
                // border: "none",
                // boxShadow: 24,
                // p: 4,
                // position: "absolute",
                // top: "0%",
                // left: "119px",
              }
            }
            onClick={() => setOpenBlockModal(false)}
          >
            {/* <SpinnerBig mode="TUT" /> */}

            <div
              style={{
                display: "flex",
                boxSizing: " border-box",

                flexDirection: "column",
                alignItems: "flex-start",
                padding: "16px 16px 24px 24px",

                // top: "50%",
                // left: "50%",
                // right: "auto",
                // bottom: "auto",
                // marginRight: "-50%",
                // transform: "translate(-50%, -50%)",
                // width: "507px",
                // height: "300px",

                background: "#FFFFFF",

                // border: "1px solid #ECECF2",
                borderRadius: "15px",
                // marginLeft: "108px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src={RemoveIcon}
                  style={{ cursor: "pointer" }}
                  alt="modal-delete"
                  onClick={handleCloseModal}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "70px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LeftTopSubTitle
                  sx={{ marginTop: "0px !important", fontSize: "16px" }}
                >
                  Let’s get started by giving Recrowdly
                </LeftTopSubTitle>
                <StepTitle
                  style={{
                    marginTop: "16px !important",
                    marginBottom: "24px !important",
                    fontSize: "24px",
                  }}
                >
                  Access to your mic and cam
                </StepTitle>
              </div>
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 auto",
                }}
              >
                <img
                  src={Microphone}
                  alt="Microphone"
                  width={"40px"}
                  height={"40px"}
                />
                <img src={Camera} alt="Camera" width={"40px"} height={"40px"} />
              </div>
              <LeftTopSubTitle sx={{ fontSize: "16px" }}>
                You can record this with your phone if your webcam is been
                cranky
              </LeftTopSubTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "25px",
                  marginTop: "25px",
                  width: "100%",
                }}
              >
                <ColorButton
                  title={"Request Permission"}
                  background={"#1A1B24"}
                  width={"90%"}
                  onClick={() => {
                    setOpenModal(false);
                    onRequestAudioVideoPermissions();
                  }}
                  buttonTextStyle={{ fontSize: "18px" }}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </VideoWrapper>
    </div>
  );
};

export default ReviewRecord;
