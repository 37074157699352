import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import CTextfield from "../../components/CTextfield";
const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;
const getRandomColor = () => {
  // Generate random RGB values between 0 and 255
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  // Return the color code in RGB format
  return `rgb(${r}, ${g}, ${b})`;
};

export const DashBoardContainer = styled(Box)`
  background: white;
  width: 100%;
`;

export const NameFBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
export const NavContainer = styled(Box)`
  width: 100%;
  height: 80px;
  background: #f6f6fa;
`;
export const NavContainerContent = styled(Box)`
  display: flex;
  width: 95%;
  height: 80px;
  margin: 0 auto;
  background: #f6f6fa;
  justify-content: ${(props) => !props?.isMobile && "space-between"};
`;
export const NavContainerContentOne = styled(Box)`
  display: flex;
  width: ${(props) => (props?.isMobile ? "100%" : "50%")};
  justify-content: flex-start;
  align-items: center;
  // height:100%;
  // margin:0 auto;
  // background: #F6F6FA;
`;
export const NavContainerContentTwo = styled(Box)`
  display: ${(props) => (props?.isMobile ? "none" : "flex")};
  width: 50%;
  justify-content: flex-start;
  align-items: center;
`;
export const NavHeaderLogoText = styled(Box)`
  display: flex;
  // justify-content:space-between;
  align-items: center;
  width: 100%;
  // height: 60px;
  // margin-top: 8px;
  max-width: ${(props) => !props?.isMobile && "400px"};
`;
export const NavImage = styled(Box)`
  width: ${(props) => (props?.isMobile ? "40%" : "35%")};
`;
export const NavDivider = styled(Divider)`
  width: 18px;
  height: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
  // margin-top: 0.5rem;

  border: 1px solid #d4dae1;
  transform: rotate(90deg);
`;
export const NavTitleText = styled(Link)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #1a1b24;
`;
export const NavSearch = styled(Box)`
  box-sizing: border-box;
  height: 48px;
  // margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 310px;
  // max-width: 50vw;
  max-width: 20vw;
  width: 350px;
  background: #ffffff;
  border: ${(props) =>
    props?.focus ? " 1px solid #007bf7" : "1px solid #D4DAE1"};
  border-radius: 15px;
  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.focus ? " 1px solid #007bf7" : "1px solid #1a1b24"};
  }
  &:focus {
    border: 1px solid #007bf7;
  }
`;
export const NavBell = styled(Box)`
  background: white;
  border: 0.3px solid #ffffff;
`;

export const NavArrow = styled(Box)`
  border: 0.3px solid #ffffff;
`;
export const BannerContainer = styled(Box)`
  width: 100%;
  margin-top: 1rem;
`;
export const NameB = styled(Box)`
  color: var(--black, #1a1b24);

  /* Body1/Regular */
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

export const ReqContFl = styled(Box)`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const ReqContFl2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;
export const ReqImg = styled("img")`
  height: 24px;
  width: 24px;
`;

export const ReqCont = styled(Box)`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f7f7f7;
  color: #53545b;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MessageB = styled(Box)`
  display: flex;
  width: 100%;
  padding: 12px 14px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px;
  background: #fafafa;
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);

  /* Body1/Regular */
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  align-self: stretch;
  line-height: 20px; /* 125% */

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-line-clamp: 2;
  line-height: 155%;
  line-clamp: 2;
  &:hover {
    background-color: #fff;
  }
`;
export const NameBox = styled(Box)`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 6.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: ${(props) =>
    props?.active ? "1px solid #8EC7F3" : "1px solid #E0C9FB"};
  background: ${(props) => (props?.active ? "#D8EEFF" : "#F6EEFF")};
  color: ${(props) => (props?.active ? "#31A5FF" : "#9C69D6")};
  text-align: center;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const NavMenuButtonBar = styled(Box)`
  width: ${(props) => props?.isMobile && "50%"};
  position: relative;
  // height: 39.29px;
  cursor: pointer;
  display: flex;
  flex: 1 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 8.5px 16px;
  background: #ffffff;
  height: 42px;
  // border: 1px solid #ECECF2;
  border-radius: 12px;
  // margin-left:0.5rem;
  // padding-left:0.5rem;
  background: white;
  border: ${(props) =>
    props?.focus ? "1px solid #007bf7" : "1px solid #D4DAE1"};
  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};
  &:hover {
    border: ${(props) =>
      props?.focus ? "1px solid #007bf7" : "1px solid #1A1B24"};
  }
`;

export const NavMenuButtonText = styled(Typography)`
  // width: 38px;
  // height: 17px;
  left: 301px;
  top: 29px;
  cursor: pointer;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #1a1b24;
`;
export const NavMenuButtonIcon = styled(IconButton)``;
export const NavMenuButtonPicker = styled(Box)`
  box-sizing: border-box;

  position: absolute;
  width: ${(props) => (props?.isMobile ? undefined : "320px")};
  max-width: ${(props) => props?.isMobile && "270px"};

  // height: 384px;
  left: ${(props) => !props?.isMobile && "0%"};
  right: ${(props) => props?.isMobile && "0%"};

  // top: 55px;
  top: 50px;
  z-index: 5;
  background: #ffffff;
  border: 1px solid #ececf2;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
`;
export const NavMenuButtonPickerSearch = styled(Box)`
  box-sizing: border-box;

  position: absolute;
  width: ${(props) => (props?.isMobile ? `90%` : "291px")};
  height: 40px;
  left: ${(props) => (props?.isMobile ? "16px" : "8px")};
  top: ${(props) => (props?.isMobile ? undefined : "8px")};
  background: #ffffff;

  border-radius: 7px;
  border: ${(props) =>
    props?.focus ? " 1px solid #007bf7" : " 1px solid #ececf2"};

  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.focus ? " 1px solid #007bf7" : "1px solid #1a1b24"};
  }
  &:focus {
    border: 1px solid #007bf7;
  }
`;
export const NavMenuButtonPickerText = styled(TextField)`
  box-sizing: border-box;

  position: absolute;
  width: 239px;
  height: 40px;
  left: 8px;
  top: 8px;

  background: #ffffff;
  border: 1px solid #ececf2;
  border-radius: 7px;
`;
export const NavMenuButtonPickerIcon = styled(IconButton)`
  box-sizing: border-box;

  position: absolute;
  width: 239px;
  height: 40px;
  left: 8px;
  top: 8px;

  background: #ffffff;
  border: 1px solid #ececf2;
  border-radius: 7px;
`;

export const FlexBannerContainer = styled(Box)`
  width: 95%;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  align-items: flex-start;

  margin: 1.5rem auto;
`;
export const FlexBannerContainerTwo = styled(Box)`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem auto;
`;
export const BannerHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  // align-items: center;
  align-items: flex-start;
  width: 50%;
`;
export const BannerBoxUrl = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: ${(props) => (props?.isMobile ? "48px" : "60px")};
  height: ${(props) => (props?.isMobile ? "48px" : "60px")};

  background: ${(props) =>
    props.background
      ? `url(${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${props.background}.jpeg)`
      : props.theme.brandColor};
  border-radius: 12px;
  background-position: center;
  background-size: cover;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const BannerBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: ${(props) => (props?.isMobile ? "48px" : "60px")};
  height: ${(props) => (props?.isMobile ? "48px" : "60px")};

  background: ${(props) =>
    props.background ? `${props.background}` : `${props.background}`};
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const BannerBoxText = styled(Typography)`
  // width: 21px;
  // height: 39px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  /* Black */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const BannerText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #1a1b24;
  padding-bottom: ${(props) => (props?.isMobile ? "0" : "10px")};
`;
export const BannerSubText = styled(Box)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
  ${(props) =>
    props?.isMobile &&
    ` overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:50%`}// margin-top: 0.5rem;
`;
export const BannerCalender = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;

  width: 55px;
  height: 48px;
  cursor: pointer;
  /* Primary/Mauve/Mauve 25% */

  // background: #eee9ff;
  /* Element */

  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #d4dae1;
  border-radius: 11px 0px 0px 11px;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
`;
export const FlexBannerButtonFilter = styled(Typography)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
`;
export const FlexBannerButtonFilterTwo = styled(Box)`
  display: flex;
  justify-content: flex-end;
  // align-items: center;
  align-items: flex-start;
  width: 50%;
  gap: 16px;
position: relative;
`;

export const InsightButton = styled(Link)`
  margin-right: ${(props) => !props?.isMobile && "1rem"};

  text-decoration: none;
`;
export const InsightText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  cursor: pointer;

  color: #ffffff;
`;
export const BannerButton = styled(Box)`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  // z-index:6;
  // width: 358px;
  height: 48px;

  /* White Color */

  background: #ffffff;
  /* Element */

  border: 1px solid #d4dae1;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  // margin-right: 1rem;
  cursor: pointer;
  overflow: hidden;
`;
export const BannerButtonBrand = styled(Button)`
  height: 17px;
  border: 1px solid #eee;
  border-radius: 5px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1a1b24;
  padding: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 1rem;
`;
export const BannerButtonText = styled(Typography)`
  // width: 73px;
  height: 19px;

  font-family: "Dm Sans";

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: right;

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const FilterButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  // border: 1px solid #eee;
  border-radius: 5px;
  // height: 17px;
  // padding: 15px;
  order: 0;
`;
export const FilterButtonTwo = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  border: 1px solid #eee;
  border-radius: 10px;
  height: 40px;
  padding: 6.5px 16px;
  order: 0;
  cursor: pointer;
`;
export const FilterButtonBrand = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  border: 1px solid black;
  border-radius: 5px;
  height: 17px;
  padding: 15px;
  order: 0;
  margin-right: 1rem;
`;
export const FilterButtonIcon = styled(Box)`
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const FilterButtonText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1a1b24;
  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const AnalyticsContainer = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
`;
export const AnalyticsContainerTwo = styled(Box)`
  width: 100%;
  margin: 1.5rem auto;
`;
export const AnalyticsBoxSummmary = styled(Box)`
  box-sizing: border-box;
  width: auto;
  height: 174px;
  left: 32px;
  top: 171px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #d4dae1;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  overflow: ${(props) => (props?.isMobile ? undefined : "hidden")};
`;
export const AnalyticsBoxHeader = styled(Typography)`
  // width: 66px;
  height: 14px;

  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  /* identical to box height, or 88% */

  /* Dark+Grey Palette/Rasin Black/Rasin Black 75% */

  color: #53545b;

  /* Inside auto layout */

  flex: none;
  // order: 1;
  flex-grow: 0;
  margin-left: ${(props) => (props?.isMobile ? "10px" : "5%")};
  // margin-right: ${(props) => (props?.isMobile ? "10px" : "3%")};
`;
export const AnanlyticsBoxDiagram = styled(Box)`
  width: 60%;
  height: 41px;
  left: 16px;
  top: 44px;
  display: flex;
  justify-content: center;
  align-items: "center";
  border-radius: 30px;
  & img {
    max-width: 100%;
  }
`;
export const AnanlyticsBoxNumbers = styled(Box)`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
`;
export const AnanlyticsBoxNumbersText = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 34px;
  color: #1a1b24;
  width: 40%;
`;
export const AnanlyticsBoxNumbersTextDate = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  margin-left: 1rem;
  color: #5d6272;
  white-space: nowrap;
  // @media (max-width: 800px) and (min-width: 450px) {
  //   display: none;
  // }
`;
export const AnanlyticsBoxBadge = styled(Typography)`
  /* Progress direction */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  // gap: 7.61px;

  width: 88.5px;
  height: 27px;

  background: #e4fff1;
  border: 1px solid #45c17e;
  border-radius: 15px;

  /* Inside auto layout */

  font-family: "Switzer";
`;
export const AnanlyticsBoxBadgeText = styled(Typography)`
  // width: 33px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Switzer";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Secondary/Viridian/Viridian 100% */

  color: #009177;

  /* Inside auto layout */
  padding-right: 8px;
`;

export const FullAnalytics = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
`;
export const FullAnalyticsTwo = styled(Box)`
  width: 100%;
  margin: 1.5rem auto;
`;

export const FullAnalyticsBox = styled(Box)`
  // position: absolute;
  width: 684px;
  height: 407px;
  // left: 32px;
  // top: 401px;

  background: #ffffff;
  border: 1px solid #ececf2;
  border-radius: 7px;
`;
export const FullAnalyticsHeader = styled(Typography)`
  // position: absolute;
  // left: 2.22%;
  // right: 85.21%;
  // top: 23.64%;
  // bottom: 74.07%;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  /* Dark+Grey Palette/Rasin Black/Rasin Black 100% */

  color: #1a1b24;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
export const FullAnalyticsHeaderTwo = styled(Typography)`
  // position: absolute;
  // left: 2.22%;
  // right: 85.21%;
  // top: 23.64%;
  // bottom: 74.07%;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height:24px;
  height: 24px;
  /* identical to box height, or 142% */
  margin-left: 10px;
  color: #1a1b24;
`;
export const FullAnalyticsText = styled(Typography)`
  // position: absolute;
  // left: 2.22%;
  // right: 85.21%;
  // top: 23.64%;
  // bottom: 74.07%;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const FullAnalyticsHeaderBox = styled(Box)`
  display: flex;
  background: ${(props) => props.background};
  border-radius: 7px 7px 0px 0px;
  height: 103px;
  padding-top: ${(props) => (props?.isMobile ? "8px" : undefined)};
  align-items: ${(props) => (props?.isMobile ? "flex-start" : "center")};
  // padding-left: 2rem;
  justify-content: space-between;
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
`;
export const FullAnalyticsHeaderBoxContent = styled(Box)`
  display: flex;
  align-items: center;
  ${(props) =>
    props?.isMobile &&
    `
  padding:12px 0px 12px 0px;
  `}
`;

export const FullAnalyticsBoxSummary = styled(Box)`
  // position: absolute;
  width: 684px;
  height: 103px;
  // left: 0px;
  // top: 0px;

  background: linear-gradient(
    90deg,
    rgba(141, 108, 255, 0.15) 50%,
    rgba(141, 108, 255, 0) 100%
  );
  border-radius: 7px 7px 0px 0px;
`;
export const UserTableDetails = styled(Box)`
  width: ${(props) => (props?.width ? "100%" : "95%")};

  margin-right: auto;
  margin-left: auto;
  margin-top: 0rem;
  // margin-bottom: 4rem;
`;
export const UserTableDetailsTwo = styled(Box)`
  width: 100%;

  margin: 0 auto;
`;
export const UserDetailsImage = styled(Box)`
  width: 62px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
`;
export const UserDetailsHeader = styled(Typography)`
  color: #5d6272;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
`;
export const UserDetailsText = styled(Typography)`
  height: 18px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
  ${(props) =>
    props?.oneLine &&
    `white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;`}
`;
export const UserDetailsStarRating = styled(Box)`
  display: flex;
  align-items: center;
`;

export const UserDetailsStar = styled(Box)`
  border-radius: 1px;
`;

export const UserDetailsButtonGroup = styled(Box)`
  border: 1px solid #ececf2;
  padding: 3px;
  display: flex;
  // height: 42px;
  // height: 34px;
  border-radius: 10px;
  // justify-content: center;
  align-items: center;
  background: #f7f7f7;
  // width: 170px;
`;
export const UserDetailsButtonText = styled(Box)`
  // font-family: "DM Sans";
  // font-style: normal;
  // font-weight: 500;
  // font-size: 14px;
  // line-height: 18px;
  // text-transform: Capitalize;
  // color: #1a1b24;

  // /* Inside auto layout */

  // flex: none;
  // order: 0;
  // flex-grow: 0;

  color: #1a1b24;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  height: 34px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  // border-radius: 12px;
  background: ${(props) => (props?.enable ? "#dae1dd" : "#f7f7f7")};
  text-transform: Capitalize;
  transition: background 1s;
`;

export const UserDetailsButtonGroupDivider = styled(Divider)`
  width: 18px;
  height: 0px;
  margin: 0 1rem;

  border: 1px solid #d4dae1;
  transform: rotate(90deg);
`;
export const TableAnalytics = styled(Box)`
  width: 100%;
  height: 103px;
  display: flex;
  flex-wrap: ${(props) => (props?.isMobile ? undefined : "wrap")};
  justify-content: ${(props) =>
    props?.isMobile ? undefined : "space-between"};
  align-items: ${(props) => (props?.isMobile ? "flex-start" : "center")};
  gap: ${(props) => (props?.isMobile ? "10px" : undefined)};

  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};

  border-radius: 7px 7px 0px 0px;
`;
export const TableAnalyticsOne = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : undefined)}; //50%
  min-width: ${(props) => (props?.isMobile ? undefined : "320px")};
  gap: ${(props) => (props?.isMobile ? "10px" : undefined)};
  justify-content: flex-start;
  display: flex;
  flex-direction: ${(props) => (props?.isMobile ? "column" : "row")};
  align-items: ${(props) => (props?.isMobile ? "flex-start" : "center")};
`;
export const TableAnalyticsOneText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  /* identical to box height, or 88% */

  color: #5d6272;
`;
export const TableAnalyticsOnesSubText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  // margin-left: 1rem;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 142% */

  color: #1a1b24;
`;

export const TableAnalyticsTwo = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : undefined)}; //50%
  min-width: ${(props) => (props?.isMobile ? undefined : "320px")};
  gap: ${(props) => (props?.isMobile ? "10px" : undefined)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: ${(props) => (props?.isMobile ? "10px" : undefined)};
`;
export const TableAnalyticsDirection = styled(Box)`
  width: 48px;
  height: 48px;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  /* Element */

  border: 1px solid #d4dae1;
  // border-radius: 10px;
  border-radius: 15px;
`;
export const TableAnalyticsSort = styled(Box)`
  display: flex;

  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 12px 7px;
  gap: 10px;
  margin-left: ${(props) => !props?.isMobile && "1.5rem"};
  // width: 125px;
  height: 48px;
  position: relative;
  /* White Color */
  cursor: pointer;
  background: #ffffff;
  /* Element */

  // border-radius: 10px;
  border-radius: 15px;
  border: ${(props) =>
    props?.focus ? " 1px solid #007bf7" : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.focus ? " 1px solid #007bf7" : "1px solid #1a1b24"};
  }
`;
export const SListItemButton = styled(ListItemButton)`
  border-radius: 14px;
  padding-left: 8px;
`;

export const SListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

export const SearchResultsForm = styled(Box)`
  position: ${(props) => (props?.isMobile ? "relative" : "absolute")};
  width: ${(props) => (props?.isMobile ? undefined : "350px")};
  height: ${(props) => (props?.isMobile ? undefined : "384px")};
  // left: 820px;
  top: ${(props) => (props?.isMobile ? "20px" : "72px")};

  background: #ffffff;
  border: ${(props) => (props?.isMobile ? undefined : "1px solid #ececf2")};
  box-shadow: ${(props) =>
    props?.isMobile ? undefined : "0px 7px 30px rgba(0, 0, 0, 0.07)"};
  border-radius: 12px;
  padding: ${(props) => (props?.isMobile ? undefined : "16px")};
  overflow: auto;
  // z-index: 5;
`;
export const SearchResultsFormDate = styled(Box)`
  position: absolute;
  width: ${(props) => (props?.isMobile ? "90%" : "269px")};
  // height: 40px;
  // left: 140px;
  // top: 170px;
  // padding: 20px;
  // z-index: -2;
  // right: 0%;
  // z-index: 5;
  .ant-picker-range {
    visibility: hidden;

    z-index: -2;
  }
  .ant-picker-panels {
    width: ${(props) => props?.isMobile && "60%"};
    overflow: ${(props) => props?.isMobile && "auto"};
  }

  & ant-picker-panels {
    width: ${(props) => props?.isMobile && "60%"};
    overflow: ${(props) => props?.isMobile && "auto"};
  }

  & ant-picker-range {
    visibiity: hidden;
    z-index: -2;
  }

  .ant-picker-dropdown {
    top: 135px;
    left: ${(props) => (props?.isMobile ? "0" : "1339.27px")};
  }
  & ant-picker-dropdown {
    top: 135px;
    // left: 1339.27px;
    left: ${(props) => (props?.isMobile ? "0" : "1339.27px")};
  }
`;
export const SearchResultsFormDateTwo = styled(Box)`
  position: absolute;
  // width: 269px;
  // height: 40px;
  // left: 30px;
  // top: 370px;
  // padding: 20px;
  // right: 0%;
  // z-index: -2;

  // z-index: 5;
  .ant-picker-range {
    visibility: hidden;

    z-index: -2;
  }
`;

export const SearchResultsBarTop = styled(Box)`
  box-sizing: border-box;

  position: absolute;
  width: 94%;
  height: 40px;
  left: 8px;
  top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ececf2;
  border-radius: 7px;
  padding: 0 10px;
`;
export const SearchResultsBarBottom = styled(Box)`
  box-sizing: border-box;

  position: absolute;
  width: 239px;
  height: 40px;
  left: 8px;
  top: 336px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #ececf2;
  border-radius: 7px;
  cursor: pointer;
`;
export const SearchResultsOrgnBottom = styled(Box)`
  box-sizing: border-box;

  // position: absolute;
  margin: 0.5rem;
  // margin-top:1rem;
  width: 45%;
  height: 40px;
  // left: 8px;
  // top: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #f7f7f7;
  border-radius: 10px;
  // border: 1px solid #ececf2;

  cursor: pointer;
`;
export const SearchResultsOrgnBottomTwo = styled(Box)`
  box-sizing: border-box;

  // position: absolute;
  margin: 0.5rem;
  // margin-top:1rem;
  width: 45%;
  height: 40px;
  // left: 8px;
  // top: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
`;
export const SearchResultsBarText = styled(Typography)`
  width: 78px;
  height: 17px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;

  color: #858995;
`;
export const SearchResultsOrgnText = styled(Link)`
  width: 150px;
  height: 16px;
  padding-left: 0.5rem;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  text-decoration: none;

  color: #858995;
`;
export const SearchResultsBrandText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #979ba6;
`;
export const SearchResultsBrandIcon = styled(IconButton)`
  // position: absolute;
  // left: 85.16%;
  // right: 6.91%;
  // top: 4.69%;
  // bottom: 90.02%;

  // background: #A2A6B0;
`;
export const ProfileSnippet = styled(Box)`
  position: absolute;
  width: 271px;
  // height: 350px;
  // left: 1050px;
  top: 72px;
  z-index: 5;
  background: #ffffff;
  border: 1px solid #ececf2;
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  // border-radius: 7px;
  border-radius: 14px;
`;
export const ProfileSnippetNotification = styled(Box)`
  position: ${(props) => (props?.isMobile ? "relative" : "absolute")};
  width: ${(props) => (props?.isMobile ? "95%" : "500px")};
  height: ${(props) => (props?.isMobile ? "100%" : "500px")};
  right: ${(props) => (props?.isMobile ? undefined : "0")};
  top: ${(props) => (props?.isMobile ? undefined : "55px")};
  z-index: 5;
  background: #ffffff;
  border: ${(props) => (props?.isMobile ? undefined : "1px solid #ececf2")};
  box-shadow: ${(props) =>
    props?.isMobile ? undefined : "0px 7px 30px rgba(0, 0, 0, 0.07)"};
  border-radius: ${(props) => (props?.isMobile ? undefined : "15px")};
  padding-left: ${(props) => (props?.isMobile ? "16px" : undefined)};
  margin-top: ${(props) => (props?.isMobile ? "-16px" : undefined)};
`;
export const ProfileNotificationContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  // width: 95%;
  width: 90%;
  margin: 1.5rem auto;
  align-items: center;
`;
export const ProfileNotificationBox = styled(Box)`
  display: flex;
  // justify-content: space-between;
  width: 100%;
  // margin:1.5rem auto;
  align-items: flex-start;
  padding: 20px;
  border-bottom: 1px solid #ececf2;
  &:hover {
    // border-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #f1f4fc;
  }
`;
export const ProfileNotificationBoxColumn = styled(Box)`
  display: flex;
  // justify-content: space-between;
  width: 95%;
  padding-left: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
`;
export const ProfileNotificationHeadMark = styled(Typography)`
  color: #0075ce;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  cursor: pointer;
`;
export const ProfileNotificationHead = styled(Typography)`
  color: #28262c;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
`;
export const ProfileNotificationText = styled(Typography)`
  color: #1a1b24;
  font-size: 16px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  display: inline;
`;
export const ProfileNotificationTextTwo = styled(Typography)`
  color: #5d6272;
  font-size: 14px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  // width:100%;
  display: inline;
`;
export const ProfileDateBrand = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  width: 100%;
  // padding-left: 1rem;
`;
export const ProfileImageContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  // padding-left: 1rem;
  padding-left: 16px;
`;
export const ProfileImageContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 1rem;
`;
export const ProfileImageContentHeader = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #1a1b24;
`;
export const ProfileImageContentText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  padding-top: 0.2rem;

  color: #5d6272;
`;

export const ProfileImage = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 35px;
  height: 35px;
  border-radius: 50%;
`;
export const ProfileButton = styled(Box)`
  // width: 208px;
  height: 47px;
  display: flex;
  // margin: 0 auto;
  justify-content: center;
  align-self: stretch;
  padding: 11.5px 0px;
  // gap: 10px;
  align-items: center;
  background: #007bf7;
  border-radius: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  /* Add styles for the button when it's clicked */
  &:active {
    background-color: #005ca3;
    transform: translateY(2px);
  }
`;
export const ProfileButtonText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  color: white;
`;
export const ModalBoxContainer = styled(Box)`
  // box-sizing: border-box;

  // position: absolute;
  // width: 40%;

  // left: 470px;
  // top: 58px;
  // margin: 1rem auto;

  background: #ffffff;
  // border: 1px solid #ECECF2;
  // box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
`;
export const ModalBoxHeader = styled(Box)`
  display: flex;

  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
`;
export const ModalBoxText = styled(Typography)`
  // width: 198px;
  height: 17px;
  left: 18px;
  top: 19px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #1a1b24;
`;
export const ModalBoxSubText = styled(Typography)`
  // width: 278px;
  height: 14px;
  left: 18px;
  top: 39px;
  margin-top: 0.3rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #5d6272;
`;
export const ModalColumnContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
`;
export const ModalTextField = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;

  // padding-top:1rem;
`;
export const ModalImageTitle = styled(Typography)`
  height: 17px;
  left: 18px;
  top: 88px;
  margin-top: 1rem;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;

  color: #1a1b24;
`;
export const BrandHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 16px;
  gap: 10px;

  width: ${(props) => (props?.isMobile ? "100%" : "338px")};
  height: 48px;

  /* Neutral palette/Seasalt/Seassalt 100% */

  background: #f7f7f7;
  border-radius: 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 10px;
`;
export const BrandHeaderText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  color: #5d6272;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const ModalImageContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  left: 18px;
  top: 114px;
  margin-top: 0.5rem;
  background: #ececec;
  border-radius: 7px;
`;
export const ModalButton = styled(Button)`
  width: 95%;
  height: 48px;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #8d6cfe 0%, #f2b02f 100%);
  border-radius: 7px;
`;
export const ModalButtonText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
`;

export const SortBoxItem = styled(Box)`
  // border-bottom: ${(props) => !props?.last && "1px solid #d4dae1"};
  height: 100%;
  &:hover {
    border-radius: 10px;
    // background-color: #e9e9f2;
    background-color: #f7f7f7;
  }
`;
export const SortText = styled(Typography)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* Secondary Main */
  margin-left: 5px;
  color: #28262c;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;
export const SortContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  padding-bottom: 10px;
  // gap: 2px;
  position: absolute;
  z-index: 3;
  top: 55px;
  width: 193px;
  height: 124px;

  /* White */

  background: #ffffff;
  /* card  Light Shadow */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  /* Inside auto layout */
`;

export const MobileCarouselDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
  gap: 16px;
  padding: 5px;
  ::-webkit-scrollbar {
    width: 3px !important;
    height: 2px;
    display: none;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;

export const TableDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
`;
export const SubTableDiv = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  flex-direction: row;
`;

export const SubTableText = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const TopText = styled(Box)`
  color: #1a1b24;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
`;

export const BottomText = styled(Box)`
  color: #1a1b24;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const ModalContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  display: flex;
  width: 436px;
  max-width: 70vw;
  height: 100vh;
  padding: 32px 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  background: #fff;
  overflow: auto;

  /* side modal */
  box-shadow: 0px -30px 60px 0px rgba(0, 0, 0, 0.1);
`;

export const Modal1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const Modal1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  align-self: stretch;
`;

export const Modal1_1_1 = styled(Box)`
  display: flex;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Modal1_1_1Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Modal1_1_2 = styled(Box)`
  display: flex;
  // width: 360px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`;

export const Modal1_1_2_1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Modal1_1_2_1_1 = styled(Box)`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px 6.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #8ec7f3;
  background: #d8eeff;
`;

export const Modal1_1_2_1_1Text = styled(Box)`
  color: #31a5ff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Modal1_1_2_1_2Text = styled(Box)`
  color: var(--black, #1a1b24);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
`;
export const Modal1_1_2_2 = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 9px;
`;

export const Modal1_1_2_2Text = styled(Box)`
  color: var(--black, #1a1b24);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const Modal1_2 = styled(Box)`
  display: flex;
  padding: 0px 24px;
  align-items: center;
  gap: 23px;
  align-self: stretch;
`;

export const Modal1_2_1Text = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Modal1_2_2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: ${(props) => (props.bg ? "#e4fff1" : "rgba(217, 48, 48, 0.10)")};
`;

export const Modal1_2_2_1 = styled(Box)`
  display: flex;
  // width: 102px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Modal1_2_2_1Text = styled(Box)`
  color: var(--black, #1a1b24);

  /* Body1/Regular */
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;
export const Modal1_3 = styled(Box)`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const Modal1_3_1Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Modal1_3_2Text = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const HrLine = styled(Box)`
  width: 100%;
  height: 1px;
  background: #ececf2;
`;
export const Modal2 = styled(Box)`
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
export const Modal2_1 = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;
export const Modal2_1_1 = styled(Box)`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const Modal2_1_1_2Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Modal2_1_2 = styled(Box)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;
export const Modal2_1_2_2Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Modal2_2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 80px;
  align-self: stretch;
  justify-content: space-between;
`;

export const Modal2_2_1 = styled(Box)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Modal2_2_1_2Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const UplTop = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

export const UplBottom = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 32px; */
`;

export const UplInner = styled(Box)`
  display: flex;
  width: 672px;
  height: 478px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;
export const UplBTInner = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const UplInner1 = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  flex-direction: column;
  border-radius: 18px;
  border: 1.5px dashed var(--Element, #d4dae1);
  justify-content: center;
  align-items: center;
  gap: 14px;
  cursor: pointer;
`;

export const UplInnerText1 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  display: inline-block;
`;

export const UplInnerText2 = styled(Box)`
  display: inline;
  color: var(--dark-grey-palette-primary-french-blue-french-blue-100, #007bf7);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  text-decoration-line: underline;
`;

export const FormBody = styled(Box)`
  display: flex;
  width: 397px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  // align-self: stretch;
`;

export const FormBody2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const FormBody2_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const FLabel = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;
export const FInput = styled(CTextfield)`
  border-radius: 15px;
  width: 100%;
`;

export const ContactInfo = styled(Typography)`
  color: var(--dark-grey-palette-primary-french-blue-french-blue-100, #007bf7);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
