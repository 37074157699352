import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { StyledButtonContainer, StyledIconButton } from "./styled";
import AddIcon from "../../assets/controls/AddIcon.svg";
import SubtractIcon from "../../assets/controls/SubtractIcon.svg";

/**
 * A component for controlling the thickness of a border.
 *
 * @param {Object} props - The component's properties.
 * @param {number} props.border - The current border thickness.
 * @param {function} props.setBorderWidth - A function to set the border thickness.
 * @returns {JSX.Element} - The rendered BorderThicknessControl component.
 */
const BorderTicknessControl = ({ border, setBorderWidth }) => {
  /**
   * Increment the border thickness.
   */
  const increment = () => {
    setBorderWidth(prevBorder => prevBorder + 1);
  };

  /**
   * Decrement the border thickness, ensuring it doesn't go below 0.
   */
  const decrement = () => {
    if (border !== 0) {
      setBorderWidth(prevBorder => prevBorder - 1);
    }
  };

  return (
    <StyledButtonContainer>
      {/* Decrement button */}
      <StyledIconButton
        color="primary"
        aria-label="decrement"
        onClick={decrement}
      >
        <img src={SubtractIcon} alt="subtract" width="20" />
      </StyledIconButton>
      {/* Display current border thickness */}
      <Typography>{border}px</Typography>
      {/* Increment button */}
      <StyledIconButton
        color="primary"
        aria-label="increment"
        onClick={increment}
      >
        <img src={AddIcon} alt="add" width="20" />
      </StyledIconButton>
    </StyledButtonContainer>
  );
};

// Prop type validation
BorderTicknessControl.propTypes = {
  border: PropTypes.number.isRequired,
  setBorderWidth: PropTypes.func.isRequired,
};

export default BorderTicknessControl;
