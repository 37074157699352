import React, { useState } from "react";
import {
  StyledColor,
  StyledCover,
  StyledPopover,
  StyledSwatch,
  StyledTextField,
} from "./styled";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";

/**
 * ColorControl component represents a color picker control with swatch and input field.
 * @param {Object} props - The component's props.
 * @param {string} props.color - The current selected color value.
 * @param {function} props.setColor - The function to set the selected color.
 * @returns {JSX.Element} - Rendered ColorControl component.
 */
const ColorControl = ({ color, setColor }) => {
  const [focusState, setFocusState] = useState({});
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const [state, setState] = useState({
    displayColorPicker: false,
  });

  /**
   * Handle click event to toggle the color picker display.
   */
  const handleClick = () => {
    let val = !state.displayColorPicker;
    setState({ ...state, displayColorPicker: val });
    setFocusState({ ...focusState, color: val });
  };

  /**
   * Handle close event to hide the color picker.
   */
  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  /**
   * Handle color change event.
   * @param {string} color - The selected color in hexadecimal format.
   */
  const handleChange = (color) => {
    setColor(color.hex);
  };

  return (
    <div
      style={{ width: isMobile && "100% " }}
      onMouseLeave={() => setFocusState({ ...focusState, color: false })}
      onMouseEnter={() => setFocusState({ ...focusState, color: "hover" })}
    >
      <StyledSwatch
        mfocus={focusState.color}
        style={{
          width: isMobile && "100% ",
          borderSize: "1px",
          borderColor:
            focusState.color === "hover"
              ? "#1a1b24"
              : focusState.color
              ? `#007bf7`
              : "#D4DAE1",
          boxShadow:
            focusState.color === true
              ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"
              : undefined,
          ":hover": {
            borderColor:
              focusState.color === "hover"
                ? "#1a1b24"
                : focusState.color
                ? `#007bf7`
                : "#1a1b24",
          },
        }}
      >
        <StyledColor selectedcolor={color} onClick={() => handleClick()} />
        <StyledTextField
          value={String(color).toUpperCase()}
          onChange={(e) => setColor(e.target.value)}
          onFocus={() => setFocusState({ ...focusState, color: true })}
          onBlur={() => setFocusState({ ...focusState, color: false })}
          variant="standard"
          InputProps={{ disableUnderline: true }}
        />
      </StyledSwatch>
      {state.displayColorPicker ? (
        <StyledPopover>
          <StyledCover onClick={() => handleClose()} />
          <SketchPicker
            color={String(color).toUpperCase()}
            onChange={handleChange}
            styles={{
              default: {
                picker: {
                  border: "1px solid #ececf2",
                  boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                  borderRadius: "12px",
                },
              },
            }}
          />
        </StyledPopover>
      ) : null}
    </div>
  );
};

export default ColorControl;
