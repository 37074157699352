import { Box, Typography } from "@mui/material";
import React from "react";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CableRoundedIcon from "@mui/icons-material/CableRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import LocalPostOfficeRoundedIcon from "@mui/icons-material/LocalPostOfficeRounded";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { NodeItemStyle } from "./index.styled";
import { onDragStart } from "../../utils/pageStore";

const iconTypeList = {
  starter: <PlayCircleRoundedIcon color="white" sx={{ height: "70px" }} />,
  tagged: <ContactMailIcon color="white" sx={{ height: "70px" }} />,
  trigger: <CableRoundedIcon color="white" sx={{ height: "70px" }} />,
  delay: <TimelapseRoundedIcon color="white" sx={{ height: "70px" }} />,
  time: <AccessAlarmsIcon color="white" sx={{ height: "70px" }} />,
  ifElseCondition: <AltRouteIcon color="white" sx={{ height: "70px" }} />,
  email: <LocalPostOfficeRoundedIcon color="white" sx={{ height: "70px" }} />,
  newsletter: <NewspaperIcon color="white" sx={{ height: "70px" }} />,
};

export const NodeIconType = ({ type }) => {
  return iconTypeList[type];
};

const CustomNodeItem = ({ data: { name, description, type } }) => {
  return (
    <NodeItemStyle draggable onDragStart={(event) => onDragStart(event, type)}>
      <Box sx={{ px: 1 }}>
        <NodeIconType type={type} />
      </Box>
      <Box sx={{ flex: "1" }}>
        <Typography color={"white"} sx={{ mb: 0.6, fontFamily: "Dm Sans" }}>
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontFamily: "Dm Sans" }}
          color={"#a2a2a2"}
        >
          {description}
        </Typography>
      </Box>
    </NodeItemStyle>
  );
};

export default CustomNodeItem;
