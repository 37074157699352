import * as React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoginPageBanner from "../../assets/images/AuthImageTwo-min.png";
import AuthLayout from "../../components/AuthLayout";
import {
  StyledHeaderText,
  StyledFormLabel,
  TextInputContainer,
  StyledDivider,
  StyledDividerText,
  StyledSignUpButton,
  FlexRowLinksContainer,
  StyledLink,
} from "./styled";
import { Alert, Box, Grid, Paper, Snackbar } from "@mui/material";
import { FlexColumnContainer } from "../../components/AuthLayout/styled";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { SIGNUP } from "../../graphql/mutations/auth";
import { START_FREE_TRIAL } from "../../graphql/mutations/freeTrial";
import { setUser } from "../../redux/auth.slice";
import GoogleAuthButton from "../../components/GoogleAuthButton/googleAuthButton";
import { queryParams } from "../../utils/Helpers";
import RecrowdlyLogo from "../../assets/images/Logo.svg";
import LoginVideo from "../../assets/icons/loginVideo.mp4";
import {
  StyledAuthContainer,
  StyledAuthLogoContainer,
  StyledSpacedBannerHousingContainer,
} from "../../components/AuthLayout/styled";
import { setWindowFlag } from "../../redux/responsive.slice";
import { TabTitle } from "../../utils/HeaderTitle";
import { StyledAuthButton } from "../Login/styled";

export default function Signup() {
  // Set the page title
  TabTitle(`Sign Up`);

  // Form handling
  const { handleSubmit, register } = useForm();

  // Query data
  const queryData = queryParams();

  // Responsive design
  const isMobile = useSelector((state) => state.responsive.isMobile);

  // State for success, modal, snackbar
  const [success, setSuccess] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  // GraphQL mutation
  const [signUp, { data, loading, error }] = useMutation(SIGNUP);
  const [
    startFreeTrial,
    { loading: startFreeTrialLoading, error: startFreeTrialError },
  ] = useMutation(START_FREE_TRIAL);
  // Redux dispatch
  const dispatch = useDispatch();

  const [, setError] = React.useState(null);

  const onSubmit = async (data) => {
    try {
      const signUpResult = await signUp({
        variables: { input: { ...data, invitationId: queryData?.invite_id } },
      });

      if (signUpResult.data.createUser) {
        await startFreeTrial({
          variables: {
            userId: signUpResult.data.createUser._id,
            createdAt: signUpResult.data.createUser.created_at,
          },
        });
      }
      setShowSnackbar(true);

      // Send POST request to server endpoint
      const emailInput = document.querySelector("#email");
    } catch (error) {
      console.error(error);
      if (error) {
        setError(error);
      }
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  // Effect to set user after successful signup
  React.useEffect(() => {
    if (data && data.createUser && !error) {
      setTimeout(() => dispatch(setUser(data.createUser)), 3000);
    }
  }, [data, error, dispatch]);

  // Effect to handle window resize
  React.useEffect(() => {
    const handleWindowResize = () => {
      dispatch(setWindowFlag({ isMobile: window.innerWidth <= 450 }));
    };

    // Initial call and event listener
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch]);

  return (
    <>
      <AuthLayout
        style={{ display: success && "none" }}
        bannerImage={LoginPageBanner}
        headerBannerText="Our Idea is Simple"
        bannerText="Its free! Join us to get organic reviews from users worldwide"
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={0}
          square
          sx={{
            minHeight: !isMobile && "100vh",
            height: isMobile && "100vh",
            width: isMobile ? "100vw" : "75%",
            margin: "0 auto",
            marginBottom: isMobile && "1em",
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              width: isMobile ? "90%" : "80%",
              margin: "0 auto",
              minHeight: "60px",
              height: isMobile && "15%",
            }}
          >
            <StyledAuthLogoContainer>
              <img src={RecrowdlyLogo} alt="logo" />
            </StyledAuthLogoContainer>
          </Box>

          {/* Signup Form */}
          <StyledAuthContainer isMobile={isMobile}>
            <FlexColumnContainer isMobile={isMobile}>
              <StyledHeaderText>Let’s Get You Started</StyledHeaderText>

              <Typography
                variant="h6"
                sx={{
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "18px",
                  letterSpacing: "-0.02em",
                }}
              >
                We are new and free, let us revolutionize your brand
                testimonials.
              </Typography>
            </FlexColumnContainer>
            <GoogleAuthButton
              setSuccess={setSuccess}
              type="Sign up with Google"
              invitationId={queryData?.invite_id}
              onError={(error) => {
                console.error(error);
                // Display error message to user
              }}
            />

            <StyledDivider>
              <StyledDividerText>OR</StyledDividerText>
            </StyledDivider>
            {error && <Alert severity="error">{error?.message}</Alert>}

            <TextInputContainer
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              {/* Name Input */}
              <TextInputContainer>
                <StyledFormLabel sx={{ marginTop: 0.65 }}>
                  Name*
                </StyledFormLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label=""
                  placeholder="Enter your name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    margin: "8px 0px 8px",
                    ".MuiInputBase-root": {
                      // height: "50px",
                      borderRadius: "15px",
                    },
                    ":hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1a1b24 !important",
                        borderWidth: "1px !important",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#007bf7",
                        borderWidth: "1px !important",
                        boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      },
                    },
                  }}
                  {...register("name", { required: true })}
                />
              </TextInputContainer>

              {/* Email Input */}
              <TextInputContainer>
                <StyledFormLabel>Email*</StyledFormLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label=""
                  placeholder="Enter your email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{
                    margin: "8px 0px 8px",
                    ".MuiInputBase-root": {
                      // height: "50px",
                      borderRadius: "15px",
                    },
                    ":hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1a1b24 !important",
                        borderWidth: "1px !important",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#007bf7",
                        borderWidth: "1px !important",
                        boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      },
                    },
                  }}
                  {...register("email", { required: true })}
                />
              </TextInputContainer>

              {/* Password Input */}
              <TextInputContainer>
                <StyledFormLabel>Password*</StyledFormLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label=""
                  placeholder="Enter your password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  sx={{
                    margin: "8px 0px 8px",
                    ".MuiInputBase-root": {
                      // height: "50px",
                      borderRadius: "15px",
                    },
                    ":hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1a1b24 !important",
                        borderWidth: "1px !important",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#007bf7",
                        borderWidth: "1px !important",
                        boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      },
                    },
                  }}
                  {...register("password", { required: true })}
                />
              </TextInputContainer>

              {/* Sign Up Button */}
              <StyledAuthButton
                type="submit" // Change the button type to "button"
                disabled={loading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // onClick={handleSignupClick} // Handle the redirection here
              >
                {loading ? "Loading..." : "   Sign Up"}
              </StyledAuthButton>

              {/* Login Link */}
              <FlexRowLinksContainer>
                <Typography>Already have an account?</Typography>
                <StyledLink href="/login" variant="body2">
                  Login
                </StyledLink>
              </FlexRowLinksContainer>
            </TextInputContainer>
          </StyledAuthContainer>
          {/* <StripePaymentButton /> */}
        </Grid>

        {/* Video (only on non-mobile) */}
        {!isMobile && (
          <Grid item container xs={false} sm={12} md={7}>
            <StyledSpacedBannerHousingContainer>
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                autoPlay
                loop
                muted
              >
                <source src={LoginVideo} type="video/mp4" /> Your browser does
                not support the video tag.
              </video>
            </StyledSpacedBannerHousingContainer>
          </Grid>
        )}

        <>
          {/* ... */}
          {/* Error message */}
          {/* {error && <Alert severity="error">{error?.message}</Alert>} */}
          {/* Snackbar */}
          <Snackbar
            open={showSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={
              data && data.createUser ? "Check Your Email for password!" : ""
            }
          />
          {/* Success message */}
          {success && (
            <Alert severity="success">Sign up successful! Redirecting...</Alert>
          )}
        </>
      </AuthLayout>
    </>
  );
}
