import { gql } from "@apollo/client";

export const CREATE_LEAD_GROUP = gql`
  mutation createLeadGroup($input: CreateLeadGroupInput!) {
    createLeadGroup(input: $input) {
      _id
      name
      createdAt
    }
  }
`;
export const DELETE_SINGLE_LEAD_GROUP = gql`
  mutation deleteLeadGroup($id: String!) {
    deleteLeadGroup(id: $id) {
      flag
    }
  }
`;
