import React from "react";
import { InputBase } from "@mui/material";

/**
 * SearchInput component for displaying a search input field.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.searchFunction - Function to be called when the input value changes (for search functionality).
 * @param {string} props.searchQuery - The current value of the search query.
 * @returns {JSX.Element} Search input field component.
 */
export default function SearchInput({ searchFunction, searchQuery }) {
  return (
    <InputBase
      sx={{
        flex: 1,
        border: "none",
        borderRadius: "10px",
        height: 40,
        padding: "8px 7px",
        boxSizing: "border-box",
      }}
      placeholder="Search Organization"
      onChange={searchFunction}
      value={searchQuery}
    />
  );
}
