import { gql } from "@apollo/client";
import { WORKFLOW } from "../fragments/workflow";

export const ADD_NEW_WORKFLOW = gql`
  ${WORKFLOW}
  mutation addNewWorkflow($input: CreateWorkflowInput!) {
    addNewWorkflow(input: $input) {
      ...WorkflowFields
    }
  }
`;

export const UPDATE_WORKFLOW = gql`
  ${WORKFLOW}
  mutation updateWorkflow($input: UpdateWorkflowInput!) {
    updateWorkflow(input: $input) {
      ...WorkflowFields
    }
  }
`;

export const RUN_WORKFLOW = gql`
  ${WORKFLOW}
  mutation runWorkflow($_id: String!) {
    runWorkflow(_id: $_id) {
      ...WorkflowFields
    }
  }
`;
