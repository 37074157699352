import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lotties/loading.json";

/**
 * LoadingComp is a component for displaying a loading animation.
 * @param {Object} props - Component props.
 * @param {boolean} props.loading - Indicates whether to display the loading animation.
 * @returns {JSX.Element|null} Loading animation component.
 */
const LoadingComp = ({ loading }) => {
  // Configuration for Lottie animation
  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return loading ? (
    <div
      style={{
        height: "45%",
        // position: "absolute",
        zIndex: "10",
        width: "80%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        options={defaultOptions}
        // style={{ width: "70%", margin: "10rem 0rem 0rem " }}
        style={{ width: "60%" }}
      />
    </div>
  ) : null;
};

export default LoadingComp;
