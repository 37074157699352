import React from 'react'
import {useState} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import Snackbar from '@mui/material/Snackbar';
import { GET_ORGANIZATION } from '../graphql/queries/organizationQueries'
import {  UPDATE_ORGANIZATION } from '../graphql/mutations/organizationMutation'
import { GET_INDUSTRIES } from '../graphql/queries/industryQueries';

function UpdateOrganizationModal({organization}) {
    
    const [name, setName] = useState(organization.name);
    const [domainUrl, setDomainUrl] = useState(organization.domainUrl);
    const [industry, setIndustry] = useState(organization.industry);
    const [updateOrganization] = useMutation(UPDATE_ORGANIZATION, {
      variables: { input: {organizationId:organization.organizationId, name, domainUrl, industry } },
     refetchQueries:[{query:GET_ORGANIZATION,variables: { input: { organizationId: organization.organizationId } } }]
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const validateUrl = (url) => {
      const pattern = /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

        return pattern.test(url);
      }
      
      
    const onSubmit = (e) => {
      e.preventDefault();
      // if (name === "" || domainUrl === "" || industry === "") { 
      //   setOpenSnackbar(true);
      //   return setSnackbarMessage("Please return all fields");
         
      // }
      if (!validateUrl(domainUrl)) {
        setOpenSnackbar(true);
        return setSnackbarMessage("Invalid domain URL");
      }
      const updatedFields = {};

      if (name !== organization.name) {
        updatedFields.name = name;
      }
    
      if (domainUrl !== organization.domainUrl) {
        updatedFields.domainUrl = domainUrl;
      }
    
      if (industry !== organization.industry) {
        updatedFields.industry = industry;
      }
 
      updateOrganization({ variables: { input: {organizationId:organization.organizationId, ...updatedFields } } });
      if (!error) {
        setOpenSnackbar(true);
        setSnackbarMessage("Organization Updated successfully");
      }
    
      setName("");
      setIndustry("");
      setDomainUrl("");
    };
    
    const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };
        // get industries for select
        const {  error, data } = useQuery(GET_INDUSTRIES);


  return (
    <>
   {/* snackbar component */}
   <Snackbar
     open={openSnackbar}
     onClose={handleCloseSnackbar}
     message={snackbarMessage}
     autoHideDuration={3000}
   />
<button type="button" className="btn btn-secondary w-25" data-bs-toggle="modal" data-bs-target="#UpdateOrganizationModal">
  <div className="d-flex align-items-center ">
  
    <div className='pl-2'>Update Organization</div>
  </div>
</button>

<div className="modal fade" id="UpdateOrganizationModal"aria-labelledby="updateOrganizationModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="updateOrganizationModalLabel">Update Organization</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form onSubmit={onSubmit}>
            <div className="mb-3">
                <label htmlFor="" className="form-label">Name</label>
                <input type="text" className="form-control" id='name' value ={name} onChange={(e)=>setName(e.target.value)} />
            </div>
            <div className="mb-3">
                <label htmlFor="" className="form-label">Domain Url</label>
                <input type="text" className="form-control" id='domainUrl' value ={domainUrl} onChange={(e)=>setDomainUrl(e.target.value)} />
            </div>
            <div className='mb-3'>
                      <label className='form-label'>Industry/Sector</label>
                      <select
                        id='industryId'
                        className='form-select'
                        value={industry}
                        onChange={(e) => setIndustry(e.target.value)}
                      >
                        <option value=''>Select Industry</option>
                        {data && data.industrys.map((industry) => (
                          <option key={industry.industryId} value={industry.name}>
                            {industry.name}
                          </option>
                        ))}
                      </select>
                    </div>
            <button type='submit' className="btn btn-secondary" data-bs-dismiss="modal">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
    </>

  )
}

export default UpdateOrganizationModal