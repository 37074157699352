import {
  VideoPartWrapper,
  StepTitle,
  LeftTopSubTitle,
  Step2SubDiv,
} from "../ReviewComp/styled";
import { Avatar, Box, Checkbox, Container, Typography } from "@mui/material";
import Play from "../../../assets/images/play.svg";
import ReviewRecord from "../ReviewComp/reviewRecord";
import ReactPlayer from "react-player";
import ColorButton from "../../ColorButton";
import RecordUpload from "../../../assets/images/teenage-female.jpg";
import LoadingComp from "../Loading";
import Upload from "../../../assets/icons/Upload.svg";
import Camera from "../../../assets/icons/Camera.svg";

export default ({
  newStep,
  step,
  brandProps,
  setRecordingData,
  trail,
  isVideoOff,
  setNewStep,
  isMobile,
  setGetPermission,
  setGetPermissions,
  setOpenModal,
  openModal,
  newVideoUrl,
  newPicUrl,
  setNewVideoUrl,
  setNewPicUrl,
  setRecordedVideoUrlSections,
  setRecordedPicUrlSections,
  recordedPicUrlSections,
  recordedVideoUrlSections,
  openCam,
  setOpenCam,
  setRunPlayHandle,
  runPlayHandle,
  addVideoData,
  videoData,
  runPlayHandler,
  onRequestAudioVideoPermissionsModal,
  loading,
  handleVideoUpload,
}) => {
  return (
    <>
      {newStep === 2 && step === 2 && (
        <VideoPartWrapper isMobile={isMobile}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "100%",
                // display: "flex",

                // alignItems: "center",
                position: "relative",
              }}
            >
              <StepTitle>Record or upload a video.</StepTitle>

              <LeftTopSubTitle
                style={{ marginBottom: "32px", fontSize: "16px" }}
              >
                {" "}
                Specify what you expect from the receiver.
              </LeftTopSubTitle>

              <div
                style={{
                  display:
                    !isMobile || (isMobile && step === 2) ? "block" : "none",

                  marginTop: isMobile && "32px",
                }}
              >
                <ReviewRecord
                  brandProps={brandProps}
                  setRecordingData={setRecordingData}
                  trail={trail}
                  isVideoOff={isVideoOff}
                  setNewStep={setNewStep}
                  isMobile={isMobile}
                  setGetPermission={setGetPermission}
                  setGetPermissions={setGetPermissions}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  newVideoUrl={newVideoUrl}
                  newPicUrl={newPicUrl}
                  setNewVideoUrl={setNewVideoUrl}
                  setNewPicUrl={setNewPicUrl}
                  setRecordedVideoUrlSections={setRecordedVideoUrlSections}
                  setRecordedPicUrlSections={setRecordedPicUrlSections}
                  recordedPicUrlSections={recordedPicUrlSections}
                  recordedVideoUrlSections={recordedVideoUrlSections}
                  openCam={openCam}
                  setOpenCam={setOpenCam}
                  setRunPlayHandle={setRunPlayHandle}
                  runPlayHandle={runPlayHandle}
                  addVideoData={addVideoData}
                />
                <div
                  style={{
                    width: isMobile ? "100%" : "100%",

                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {newVideoUrl || videoData.length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        width: isMobile ? "100%" : "100%",
                        height: isMobile ? "100%" : "100%",
                      }}
                    >
                      <ReactPlayer
                        // ref={videoPlayerRef}
                        className="custom-player-play-modal"
                        url={newVideoUrl}
                        // playing={playing}

                        width="100%"
                        // controls={true}
                        height="400px"
                        // onEnded={() => {
                        //   setStRecord();
                        //   playPauseHandler();
                        // }}

                        // onProgress={progressHandler}
                        // onBuffer={bufferStartHandler}
                        // onBufferEnd={bufferEndHandler}
                      />

                      <div
                        onClick={runPlayHandler}
                        style={{
                          position: "absolute",
                          top: isMobile ? "50%" : "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          // zIndex: 2,
                          width: isMobile ? "35px" : "auto",
                          height: isMobile ? "35px" : "auto",

                          color: "white",
                          border: "none",

                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={Play}
                          style={{ width: "100%" }}
                          alt="controls"
                        />
                      </div>
                    </div>
                  ) : (
                    <img
                      src={RecordUpload}
                      // style={{ width: "100%" }}
                      style={{ width: "296px" }}
                      alt="vector"
                    />
                  )}
                </div>
              </div>

              {step === 2 && (
                <div
                  style={{
                    marginBottom: "100px",
                  }}
                >
                  <Step2SubDiv>
                    <ColorButton
                      title={"Camera"}
                      background={"#F1F3EC"}
                      width={"48%"}
                      image={Camera}
                      order={true}
                      greyBg={"greyBg"}
                      imgStyle={{ marginTop: 0 }}
                      onClick={() => {
                        onRequestAudioVideoPermissionsModal();
                      }}
                    />
                    <input
                      type="file"
                      accept="video/*"
                      style={{ display: "none" }}
                      onChange={handleVideoUpload}
                    />
                    <ColorButton
                      image={Upload}
                      order={true}
                      // pointerEvents={
                      //   recordingData.videoUrl ? "auto" : "none"
                      // }
                      title={
                        loading ? <LoadingComp loading={loading} /> : "Upload"
                      }
                      background={"#F1F3EC"}
                      greyBg={"greyBg"}
                      width={"48%"}
                      onClick={() => {
                        document.querySelector('input[type="file"]').click();
                      }}
                    />
                  </Step2SubDiv>
                </div>
              )}
            </Box>
          </Box>
        </VideoPartWrapper>
      )}
    </>
  );
};
