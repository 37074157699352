import styled from "@emotion/styled";
import { Box, IconButton, Typography, TextField } from "@mui/material";

export const StyledButtonContainer = styled(Box)`
  display: flex;
  padding: 3px 3px 3px 6px;
  align-items: center;
  width: ${(props) => props?.isMobile && "100%;"};
  gap: 11px;
  border-radius: 12px;
  border: 1px solid #d4dae1;
  background: #fff;
`;
export const StyledTextField = styled(TextField)`
  // max-width: 80px;
  width: 30px;
  border: 0;
  padding: 2px;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  text-align: center;
`;

export const StyledControlContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  ${(props) =>
    props?.isMobile &&
    `
    justify-content:space-between;
    width:80%
  `}
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  width:30px;
  height:30px;
`;

export const FullItem = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  gap: 2px;
  color: #1a1b24;
`;
export const SubItem = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #808080;

  padding: 10px 0;
  // margin-top: 5%;
`;
