import React, { useState, useEffect, useRef } from "react";
import {
  AnalyticsBoxHeader,
  AnalyticsBoxSummmary,
  AnalyticsContainer,
  AnanlyticsBoxBadge,
  AnanlyticsBoxDiagram,
  AnanlyticsBoxNumbers,
  AnanlyticsBoxNumbersText,
  BannerButton,
  BannerContainer,
  BannerHeader,
  BannerSubText,
  BannerText,
  DashBoardContainer,
  FilterButton,
  FilterButtonIcon,
  FilterButtonText,
  FlexBannerButtonFilter,
  FlexBannerContainer,
  FullAnalytics,
  NavArrow,
  NavBell,
  NavContainer,
  NavDivider,
  NavHeaderLogoText,
  NavImage,
  NavSearch,
  NavTitleText,
  UserTableDetails,
  UserDetailsImage,
  FullAnalyticsHeader,
  UserDetailsHeader,
  UserDetailsText,
  UserDetailsStarRating,
  UserDetailsStar,
  FullAnalyticsHeaderBox,
  FullAnalyticsHeaderBoxContent,
  FullAnalyticsText,
  UserDetailsButtonGroup,
  UserDetailsButtonText,
  UserDetailsButtonGroupDivider,
  AnanlyticsBoxNumbersTextDate,
  SearchResultsFormDate,
  SearchResultsBar,
  SearchResultsBarTop,
  SearchResultsBarBottom,
  SearchResultsBarText,
  SearchResultsBrandText,
  SearchResultsBrandIcon,
  ProfileSnippet,
  ProfileImageContainer,
  ProfileImage,
  ProfileImageContent,
  ProfileImageContentHeader,
  ProfileImageContentText,
  ProfileButton,
  ProfileButtonText,
  ModalBoxContainer,
  ModalBoxHeader,
  ModalBoxText,
  ModalBoxSubText,
  ModalColumnContainer,
  ModalImageTitle,
  ModalImageContainer,
  ModalTextField,
  ModalButton,
  ModalButtonText,
  InsightText,
  InsightButton,
  FlexBannerButtonFilterTwo,
  FlexBannerContainerTwo,
  BannerBox,
  BannerBoxText,
  BannerCalender,
  BannerButtonText,
  FilterButtonTwo,
  BannerBoxUrl,
} from "./styled";
import Covete from "../../assets/icons/Covete.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import Calender from "../../assets/icons/Calender.svg";
import AddImage from "../../assets/icons/AddImage.svg";

import AvatarImage from "../../assets/images/Avatar.svg";
import SpaceShip from "../../assets/images/SpaceShip.svg";
import Dots from "../../assets/images/Dots.svg";
import InsightButtonImage from "../../assets/images/InsightButton.svg";
import NavBellImage from "../../assets/icons/Bell.svg";
import FilterIcon from "../../assets/icons/Filter.svg";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import NavMenuButton from "../../components/NavMenu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Box,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChartCard from "../../components/ChartCard";
import { useDispatch, useSelector } from "react-redux";
import DashboardTable from "../../components/DashboardTable";
import SummaryAnalytics from "../../components/SummaryAnalytics";
import moment from "moment";
import dayjs from "dayjs";
import io from "socket.io-client";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
// import {
//   CheckBoxOutlineBlankOutlined,
//   DraftsOutlined,
//   HomeOutlined,
//   InboxOutlined,
//   MailOutline,
//   ReceiptOutlined,

// } from "@mui/icons-material";
import Layout from "../../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import {
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  GET_VIDEOS_BY_BRAND,
  GET_VIDEOS_DATE,
  NEW_VIDEOS_COUNT,
  RATING_AVERAGE,
} from "../../graphql/queries/videosQueries";
import { useQuery } from "@apollo/client";
import { DatePicker, Select } from "antd";
import "antd/dist/antd.js";
import {
  GET_ANALYTICS_BY_BRAND,
  GET_ANALYTICS_WATCHED,
  GET_TOTAL_ANALYTICSVIDEOS_WATCHED,
  VIDEOS_WATCHED_COUNT,
  VIDEOS_WATCHED_DAYS_COUNT,
  GET_ANALYTICS_PAGE_VISITED,
  GET_ANALYTICS_TIME_WEBSITE_SPENT,
  GET_ANALYTICS_ENGAGEMENT,
  GET_ANALYTICS_IMPRESSION,
} from "../../graphql/queries/analytics";
import ChartCardTwo from "../../components/ChartCardTwo";
import styled from "styled-components";
import { setDate } from "../../redux/date.slice";
import { GET_BRAND } from "../../graphql/queries/brandQueries";
import {
  PreviousButton,
  PreviousButtonText,
} from "../OrganizationBrands/styled";
import EditDeleteButton from "../../components/EditDeleteButton";
import { socket } from "../../App";
import ColorButton from "../../components/ColorButton";
import { TabTitle } from "../../utils/HeaderTitle";
import RequestDialog from "../../components/RequestDialog";
import LeadsComp from "./leadsComp";
import { FETCH_LEADS } from "../../graphql/queries/leads";
// import io from "socket.io-client";

// const { RangePicker } = DatePicker;
// const { Option } = Select;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker-range {
    visibility: hidden;
  }

  .ant-picker-dropdown {
    top: 70%;
  }
  & ant-picker-dropdown {
    // top: 135px;
    // left: 1339.27px;
  }
`;
const customStyles = {
  backgroundColor: "#fff",
  border: "1px solid #d9d9d9",
  borderRadius: "4px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
};

function UserDashboard() {
  const menu = useSelector((state) => state.menu);
  const navigate = useNavigate();
  TabTitle(`${menu.name}`);
  const rangeRef = useRef(null);
  const rangeRef2 = useRef(null);
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [display, setDisplay] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [displayLead, setDisplayLead] = useState(false);
  const [dialogRequestOpen, setDialogRequestOpen] = useState(false);
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const [selectedDays, setSelectedDays] = useState(1);
  const [selectedDaysTwo, setSelectedDaysTwo] = useState("1");
  const [dateRange, setDateRange] = useState([]);
  const [topCalendar, setTopCalendar] = useState(0);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showResults, setShowResults] = React.useState(false);
  ///New Analytics
  const { data: engagementData } = useQuery(GET_ANALYTICS_ENGAGEMENT, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  const { data: dataLeads, refetch: refetchLeads } = useQuery(FETCH_LEADS, {
    variables: {
      input: { br_id: menu.id },
    },
  });

  const { data: averageTimeData } = useQuery(GET_ANALYTICS_TIME_WEBSITE_SPENT, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  const { data: pagesVisitedData } = useQuery(GET_ANALYTICS_PAGE_VISITED, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });

  //////////
  const {
    loading: brandLoading,
    error: brandError,
    data: brandData,
  } = useQuery(GET_BRAND, {
    variables: { input: { _id: menu.id } },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: newLoading,
    error: newError,
    data: newData,
  } = useQuery(NEW_VIDEOS_COUNT, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: ratingLoading,
    error: ratingError,
    data: ratingData,
  } = useQuery(RATING_AVERAGE, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: diasapprovedLoading,
    error: diasapprovedError,
    data: diasapprovedData,
  } = useQuery(DISAPPROVED_VIDEOS_DATE_COUNT, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: approvedLoading,
    error: approvedError,
    data: approvedData,
  } = useQuery(APPROVED_VIDEOS_DATE_COUNT, {
    variables: { brandId: menu.id, startDate: startDate, endDate: endDate },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: watchedLoading,
    error: watchedError,
    data: watchedData,
  } = useQuery(VIDEOS_WATCHED_COUNT, {
    variables: {
      brandId: menu.brandId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: analyticsLoading,
    error: analyticsError,
    data: analyticsData,
  } = useQuery(GET_ANALYTICS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: videosLoading,
    error: videosError,
    data: videosData,
  } = useQuery(GET_VIDEOS_DATE, {
    variables: {
      brandId: menu.brandId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: totalVideosLoading,
    error: totalVideosError,
    data: totalVideosData,
  } = useQuery(GET_TOTAL_ANALYTICSVIDEOS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const dateFormat = "YYYY-MM-DD";

  // const [disapprovedCount, setDisapprovedCount] = useState(null); // State for storing the disapproved video count

  // Update selected days from selected date range
  //  useEffect(() => {
  //   const days = moment(selectedDateRange[1]).diff(
  //     selectedDateRange[0],
  //     "days"
  //   ) + 1;
  //   setSelectedDays(days);
  // }, [selectedDateRange]);

  // Update selected date range based on selected number of days
  useEffect(() => {
    function handleClickOutside(event) {
      if (rangeRef.current && !rangeRef.current.contains(event.target)) {
        // Check if the clicked target is within the Dropdown's internal elements
        const dropdownInternal = document.querySelector(".ant-picker-dropdown");
        if (!dropdownInternal || !dropdownInternal.contains(event.target)) {
          setDisplay(false);
        } else {
          setDisplay(true);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (rangeRef.current) {
      const rect = rangeRef.current.getBoundingClientRect();
      console.log("RectFirst:", rect);
      setTopCalendar(rect.bottom);
      // Do something with the top position
    }
  }, [rangeRef]);
  const handleDaysChange = (value) => {
    setSelectedDaysTwo(value);
  };
  const handleRequestDialog = () => {
    setDialogRequestOpen(true);
  };
  useEffect(() => {
    switch (selectedDaysTwo) {
      case "1":
        setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "7":
        setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "14":
        setStartDate(moment().subtract(14, "days").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "21":
        setStartDate(moment().subtract(21, "days").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "28":
        setStartDate(moment().subtract(28, "days").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        break;
      case "custom":
        setStartDate(moment().subtract(1, "day").format("YYYY-MM-DD"));
        setEndDate(moment().format("YYYY-MM-DD"));
        setDateRange([
          moment(startDate, dateFormat),
          moment(endDate, dateFormat),
        ]);
        break;

      default:
        break;
    }
  }, [selectedDaysTwo]);

  // // Listen for the "invitation" event
  // socket.on("invitedUser", (data) => {
  //   // Display the notification in the user's dashboard
  //   console.log("Received invitation:", data);
  //   console.log(data.message); // or update your state to show the notification
  // });
  // socket.on("Testimonial", (data) => {
  //   // Display the notification in the user's dashboard
  //   console.log("Received Testimonial", data);
  //   // console.log(data.message); // or update your state to show the notification
  // });

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    const startDate = value[0].format("YYYY-MM-DD");
    const endDate = value[1].format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);

    const diffInDays = getDaysDifference(value);
    setSelectedDaysTwo(diffInDays);
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // Calculate the difference between start and end date
  const getDaysDifference = (dateRange) => {
    const startDate = dateRange[0];
    const endDate = dateRange[1];
    const diffInDays = moment.duration(endDate.diff(startDate)).asDays();
    return diffInDays;
  };

  const numSelectedDays = dayjs(endDate).diff(startDate, "day") + 1;
  const [dateText, setDateText] = useState("Yesterday");

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);

  const toggleResults = () => {
    setShowResults((prevState) => !prevState);
  };
  const totalDuration = analyticsData?.analyticsWatchedVideo.reduce(
    (total, item) => {
      return total + item.duration;
    },
    0
  );
  const formattedTotalDuration = analyticsData ? Math.round(totalDuration) : 0;
  let durationString = "";

  if (formattedTotalDuration === 0) {
    durationString = `0`;
  } else if (formattedTotalDuration < 60) {
    durationString = `${formattedTotalDuration} secs`;
  } else if (formattedTotalDuration < 3600) {
    const minutes = Math.floor(formattedTotalDuration / 60);
    const seconds = formattedTotalDuration % 60;
    durationString = `${minutes} mins ${seconds} secs`;
  } else {
    const hours = Math.floor(formattedTotalDuration / 3600);
    const minutes = Math.floor((formattedTotalDuration % 3600) / 60);
    const seconds = formattedTotalDuration % 60;
    durationString = `${hours} hr ${minutes} mins ${seconds} secs`;
  }
  const totalDurationVideos = videosData?.videos.reduce((total, item) => {
    return total + item.duration;
  }, 0);

  const formattedTotalDurationVideos = videosData
    ? Math.round(totalDurationVideos)
    : 0;
  let durationStringVideo = "";

  if (formattedTotalDurationVideos === 0) {
    durationStringVideo = `0`;
  } else if (formattedTotalDurationVideos < 60) {
    durationStringVideo = `${formattedTotalDurationVideos} secs`;
  } else if (formattedTotalDurationVideos < 3600) {
    const minutes = Math.floor(formattedTotalDurationVideos / 60);
    const seconds = formattedTotalDurationVideos % 60;
    durationStringVideo = `${minutes} mins ${seconds} secs`;
  } else {
    const hours = Math.floor(formattedTotalDurationVideos / 3600);
    const minutes = Math.floor((formattedTotalDurationVideos % 3600) / 60);
    const seconds = formattedTotalDurationVideos % 60;
    durationStringVideo = `${hours} hr ${minutes} mins `;
  }
  // if (formattedTotalDuration < 60) {
  //   durationStringVideo = `${formattedTotalDuration} seconds`;
  // } else if (formattedTotalDuration < 3600) {
  //   const minutes = Math.floor(formattedTotalDuration / 60);
  //   const seconds = formattedTotalDuration % 60;
  //   durationStringVideo = `${minutes} minutes ${seconds} seconds`;
  // } else {
  //   const hours = Math.floor(formattedTotalDuration / 3600);
  //   const minutes = Math.floor((formattedTotalDuration % 3600) / 60);
  //   const seconds = formattedTotalDuration % 60;
  //   durationStringVideo = `${hours} hr ${minutes} mins ${seconds} seconds`;
  // }
  // console.log(showResults)
  const handleDateChangeTwo = (date, dateString) => {
    setStartDate(dateString);
    setEndDate(dateString);
  };

  const handleRangeChangeTwo = (dates, dateStrings) => {
    const newStartDate = dateStrings[0];
    const newEndDate = dateStrings[1];

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    dispatch(setDate({ startDate: newStartDate, endDate: newEndDate }));
    const isCustomRangeSelected =
      dateStrings[0] === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
      dateStrings[1] === dayjs().format("YYYY-MM-DD");

    setCustomRangeSelected(isCustomRangeSelected);
  };

  const presetRanges = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "day"), dayjs()],
    "Last 7 Days": [dayjs().subtract(6, "day"), dayjs()],
    "Last 14 Days": [dayjs().subtract(13, "day"), dayjs()],
    "Last 21 Days": [dayjs().subtract(20, "day"), dayjs()],
    "Last 30 Days": [dayjs().subtract(29, "day"), dayjs()],
    "Last 60 Days": [dayjs().subtract(59, "day"), dayjs()],
    "Last 90 Days": [dayjs().subtract(89, "day"), dayjs()],
    "Custom Range": () => [dayjs().subtract(2, "day"), dayjs()],
  };
  // console.log(analyticsData)

  function getCalendarContainer(trigger) {
    return trigger.parentNode;
  }
  // changing date format
  const formattedStartDate = moment(startDate).format("MMM D, YYYY");
  const formattedEndDate = moment(endDate).format("MMM D, YYYY");
  // rating calculation
  const num = ratingData?.getAverageRating;

  const roundedNum = (Math.round(num * 100) / 100).toFixed(2); // "3.44"
  const finalNum = ratingData ? parseFloat(roundedNum).toFixed(1) : 0; // "3.46"

  // total duration calculation

  // Assuming you have the data stored in a variable called 'data'
  const analyticsTotalData = totalVideosData?.analyticsDaysVideoDuration || [];

  const totalVideosDuration = analyticsTotalData.reduce(
    (sum, item) => sum + item.totalDuration,
    0
  );
  const formattedTotalOverallDuration = totalVideosData
    ? Math.round(totalVideosDuration)
    : 0;
  let durationStringOverall = "";

  if (formattedTotalOverallDuration === 0) {
    durationStringOverall = `0`;
  } else if (formattedTotalOverallDuration < 60) {
    durationStringOverall = `${formattedTotalOverallDuration} secs`;
  } else if (formattedTotalOverallDuration < 3600) {
    const minutes = Math.floor(formattedTotalOverallDuration / 60);
    const seconds = formattedTotalOverallDuration % 60;
    durationStringOverall = `${minutes} mins ${seconds} secs`;
  } else {
    const hours = Math.floor(formattedTotalOverallDuration / 3600);
    const minutes = Math.floor((formattedTotalOverallDuration % 3600) / 60);
    const seconds = formattedTotalOverallDuration % 60;
    durationStringOverall = `${hours} hr ${minutes} mins `;
  }

  return (
    <DashBoardContainer>
      <BannerContainer>
        <FlexBannerContainer>
          <BannerHeader>
            <Box>
              {brandData?.brand.imageUrl ? (
                <BannerBoxUrl
                  isMobile={isMobile}
                  background={brandData?.brand.imageUrl}
                ></BannerBoxUrl>
              ) : (
                <BannerBox background={brandData?.brand.brandColor}>
                  <BannerBoxText>
                    {brandData?.brand.name.charAt(0).toUpperCase() || ""}
                  </BannerBoxText>
                </BannerBox>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginLeft: "1rem",
                overflow: "hidden",
              }}
            >
              <BannerText isMobile={isMobile}>
                {menu.name
                  ? menu.name.charAt(0).toUpperCase() + menu.name.slice(1)
                  : "Brand"}
              </BannerText>
              <BannerSubText isMobile={isMobile}>
                Here are some detailed analytics of {menu.name || "brand"} for
                you to work around.
              </BannerSubText>
            </Box>
          </BannerHeader>
          <FlexBannerButtonFilterTwo>
            {/* <div   style={{marginRight:'10px' }}>
                <ColorButton
                    style={{ cursor: "pointer" }}
                    width="160px"
                  background="#B8A9FF"
                  title="Request Review"
                    alt="review Button"
                    onClick={handleRequestDialog}
                  />  
            </div> */}

            {menu.brandId ? (
              <Link
                to={`/brands/${menu.brandId}/0/impression`}
                style={{ textDecoration: "none" }}
              >
                <ColorButton
                  width="136px"
                  background="#1A1B24"
                  title="Insights"
                  isMobile={isMobile}
                  fontWeight="0"
                />
              </Link>
            ) : (
              ""
            )}
            {menu?.owned && !isMobile && (
              <PreviousButton
                style={{
                  width: "130px",

                  // marginLeft: "25px",
                  height: "48px",
                  justifyContent: "center",
                }}
                onClick={() => setDisplayLead(true)}
                // onClick={handleUploadDialog}
                // focus={dialogUploadOpen}
              >
                {/* add badge */}
                <PreviousButtonText>Leads</PreviousButtonText>
              </PreviousButton>
            )}

            {!isMobile && menu?.access === "edit" && <EditDeleteButton />}
          </FlexBannerButtonFilterTwo>
        </FlexBannerContainer>
      </BannerContainer>
      {displayLead ? (
        <FlexBannerContainerTwo>
          <LeadsComp rows={dataLeads?.fetchLeads ?? []} />
        </FlexBannerContainerTwo>
      ) : (
        <>
          <BannerContainer ref={rangeRef2}>
            <FlexBannerContainerTwo>
              <BannerHeader>
                <BannerText>Analytics</BannerText>
              </BannerHeader>

              <FlexBannerButtonFilterTwo ref={rangeRef}>
                <BannerButton
                  data-date-picker-trigger="true"
                  onClick={() => {
                    setDisplay((prevDisplay) => !prevDisplay);
                  }}
                  className="navigation-img"
                  tabIndex="0"
                >
                  <BannerButtonText sx={{ marginLeft: "10px" }}>
                    {dateText}
                  </BannerButtonText>

                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                  {!isMobile && (
                    <BannerButtonText>
                      {formattedStartDate} - {formattedEndDate}
                    </BannerButtonText>
                  )}

                  <BannerCalender>
                    <img src={Calender}></img>
                  </BannerCalender>
                </BannerButton>
                {/* <BannerButton onClick={toggleResults}>
              {startDate}- {endDate}
            </BannerButton> */}
                {/* <DatePicker onChange={handleDateChangeTwo} open={true} /> */}

                {display && (
                  <SearchResultsFormDate isMobile={isMobile}>
                    <StyledRangePicker
                      onChange={handleRangeChangeTwo}
                      ranges={presetRanges}
                      disabledDate={disabledDate}
                      open={display}
                      showToday={false}
                      getCalendarContainer={getCalendarContainer}
                      style={{
                        width: 200,
                      }}
                      popupStyle={
                        isMobile
                          ? {
                              width: "90%",
                              top: "250px",
                              left: "0",
                            }
                          : {
                              // padding: "1% 0",
                              padding: "0",
                              paddingTop: "20px",
                              position: "absolute",
                              width: "auto",
                              left: "auto",
                              right: "2.5%",
                              // top: `${topCalendar + 10}px`,
                            }
                      }
                    />
                  </SearchResultsFormDate>
                )}
                {/* <FilterButtonTwo>
              <FilterButtonIcon>
                <img src={FilterIcon}></img>
              </FilterButtonIcon>
              <FilterButtonText>Filter</FilterButtonText>
            </FilterButtonTwo> */}
              </FlexBannerButtonFilterTwo>
            </FlexBannerContainerTwo>
          </BannerContainer>
          <SummaryAnalytics
            id={menu.id}
            brandId={menu.brandId}
            days={selectedDays}
            startDate={startDate}
            endDate={endDate}
            publishDate={approvedData?.approvedVideoDateCount.count}
            unpublishDate={diasapprovedData?.disapprovedDateVideoCount.count}
            newVideos={newData?.newVideoCount.count}
            engagementData={engagementData?.analyticsDaysEngagement}
            averageTimeData={averageTimeData?.analyticTimeWebSiteSpent}
            pagesVisitedData={pagesVisitedData?.analyticsNumberofVisitedPages}
            publishPercent={approvedData?.approvedVideoDateCount.percentChange}
            unpublishPercent={
              diasapprovedData?.disapprovedDateVideoCount.percentChange
            }
            videosWatched={watchedData?.analyticsWatchedCount.count}
            dateRange={dateText}
            finalNum={finalNum}
            totalVideosDuration={durationStringOverall}
            formattedTotalOverallDuration={formattedTotalOverallDuration}
          />
          <RequestDialog
            openDialog={dialogRequestOpen}
            brand={brandData?.brand}
            onClose={() => setDialogRequestOpen(false)}
            isMobile={isMobile}
          />
          <DashboardTable
            id={menu.brandId}
            name={menu.name}
            brandID={menu.id}
            days={selectedDays}
            startDate={startDate}
            endDate={endDate}
            isMobile={isMobile}
          />
        </>
      )}
    </DashBoardContainer>
  );
}

export default UserDashboard;
