import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
export const FilterButtonIcon = styled(Box)`
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FilterButtonText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1a1b24;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const FilterButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  border-radius: 15px;
border: 1px solid  #D4DAE1;
background: #FFF;
  height: 40px;
  padding: 9.5px 16px;
  order: 0;
  text-transform: capitalize;
`;
