import { useEffect, useState } from "react";
import getCountryTags from "../../utils/countryPix";
import { AddedSelectTag, InputForm } from "./styled";
import { InputAdornment } from "@mui/material";
import countryCodes from "../../utils/countryCodes";
import Inp from "./inp";

export default ({
  el,
  focusState,
  formstate,
  handleChange,
  setFocusState,
  errorstate,
  setCntryCde,
  cntryCde,
  inputStyle = {},
}) => {
  // console.log(cntryCde, "ccode");

  const getDefaultValues = () => {
    let obj = countryCodes.find((countryCode) => countryCode.code === cntryCde);

    // console.log(obj, "default values");
    return obj;
  };

  function getTimeZone() {
    const formatter = new Intl.DateTimeFormat(undefined, {
      timeZoneName: "short",
    });
    const timeZone = formatter.resolvedOptions().timeZone;

    return timeZone;
  }

  const compareWithTimeZone = (arr, text) => {
    return arr
      .map((el) => String(el).trim().toLowerCase())
      .includes(String(text).trim().toLowerCase());
  };

  useEffect(() => {
    if (!cntryCde) {
      let timezone = getTimeZone();
      // console.log("timezone", timezone);
      let obj = countryCodes.find(
        (countryCode) =>
          countryCode?.timezone instanceof Array &&
          compareWithTimeZone(countryCode.timezone, timezone)
      );
      // console.log("timezone", obj);

      if (obj?.code) setCntryCde(obj.code);
      // alert(obj?.code);
      // console.log(country, "Codeee");
    }
  }, [cntryCde]);

  return (
    <InputForm
      style={inputStyle}
      required={el?.required ?? false}
      type={el?.type ?? "text"}
      placeholder={el?.placeholder ?? ""}
      value={formstate[el?.name] || ""}
      defaultValue={formstate[el?.name] || ""} // add defaultValue prop
      borderColor={errorstate[el?.name] && "#D93047"}
      mfocus={
        focusState[el?.name] ||
        (formstate[el?.name] && formstate[el?.name] !== "")
      }
      onChange={(e) => handleChange(e.target.value, el?.name)}
      onFocus={() => setFocusState({ ...focusState, [el?.name]: true })}
      onBlur={() => setFocusState({ ...focusState, [el?.name]: false })}
      startAdornment={
        el.name === "phoneNumber" ? (
          <InputAdornment position="start">
            <Inp
              phoneCode={cntryCde}
              setPhoneCode={setCntryCde}
              getDefaultValues={getDefaultValues()}
            />
          </InputAdornment>
        ) : undefined
      }
    />
  );
};
