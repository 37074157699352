import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const TopText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
  margin-bottom: 5px;
`;
export const BottomText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5d6272;
`;

export const NoActiveDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const PendDiv = styled(Box)`
  background: #ffffff;

  border: 1px solid #ff9042;
  border-radius: 10px;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;
  // height: 27px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  font-family: "Dm Sans";
  color: #000000;
  margin-left: 4px;
  // margin-top: 8px;
  // margin-bottom: 5px;
`;

export const BlankImage = styled(Box)`
  width: 35px;
  height: 35px;
  background: #ffffff;
  border: 1px dashed #d4dae1;
  border-radius: 50%;
  // margin-left: 20px;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
`;
