import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { nodeTypesDataList } from "../../utils/pageStore";
import { useSelector } from "react-redux";
import { GET_BRAND_TAGS } from "../../graphql/queries/brandQueries";
import { useQuery } from "@apollo/client";
import { GET_ALL_TEMPLATES_QUERY } from "../../graphql/mutations/saveTemplateMutation";
import { GET_ALL_LEAD_GROUP } from "../../graphql/queries/leadgroup";

const initModals = Object.keys(nodeTypesDataList).reduce((acc, key) => {
  acc[key] = false;
  return acc;
}, {});
export const WorkflowContext = createContext();

export const WorkflowProvider = ({ children }) => {
  const menu = useSelector((state) => state.menu);
  const [allModals, setAllModals] = useState(initModals);
  const [deleteNodeModal, setDeleteNodeModal] = useState(false);
  const [unAttached, setUnAttached] = useState([]);
  const [emptyData, setEmptyData] = useState([]);
  const [starterOpen, setStarterOpen] = useState(false);
  const [isPublished, setIsPublished] = useState();

  const { data: fetchedData } = useQuery(GET_BRAND_TAGS, {
    variables: {
      input: { _id: menu.id },
    },
  });

  const { data: leadgroups } = useQuery(GET_ALL_LEAD_GROUP, {
    variables: {
      input: { br_id: menu.id },
    },
  });

  const { data: templateData } = useQuery(GET_ALL_TEMPLATES_QUERY);

  const tagsList = useMemo(
    () =>
      fetchedData?.brand?.tags?.map(({ id, name, description }) => ({
        id,
        name,
        description,
      })) ?? [],
    [fetchedData]
  );

  const newsletters = useMemo(
    () => templateData?.getAllTemplates ?? [],
    [templateData]
  );

  const groupList = useMemo(() => leadgroups?.leadGroups ?? [], [leadgroups]);

  // console.log("these are the group", groupList);
  // console.log("these are the newsletters", newsletters);
  const closeNodeDeleteModal = useCallback((type) => {
    setDeleteNodeModal(false);
  }, []);

  const openNodeDeleteModal = useCallback((type) => {
    setDeleteNodeModal(true);
  }, []);

  const openAModal = useCallback((type) => {
    setAllModals((modalObj) => {
      return Object.keys(modalObj).reduce((acc, key) => {
        if (key === type) {
          acc[key] = true;
        } else {
          acc[key] = false;
        }
        return acc;
      }, {});
    });
  }, []);
  const closeModals = useCallback(() => {
    setAllModals(initModals);
  }, []);

  return (
    <WorkflowContext.Provider
      value={{
        openAModal,
        closeModals,
        unAttached,
        setUnAttached,
        emptyData,
        setEmptyData,
        allModals,
        tagsList,
        groupList,
        newsletters,
        deleteNodeModal,
        isPublished,
        setIsPublished,
        closeNodeDeleteModal,
        openNodeDeleteModal,
        setStarterOpen,
        starterOpen,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export const useWorkflowContext = () => {
  return useContext(WorkflowContext);
};
export default WorkflowProvider;
