import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useLazyQuery } from "@apollo/client";
import {
  GET_ORGANIZATION_INVITES,
  GET_ORGANIZATION_USERS,
} from "../../graphql/queries/organizationQueries";
import { Button, Chip, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import NewInviteDialog from "../NewInvite";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ManageAccessDialog from "../ManageAccessDialog";

/**
 * Functional component representing a tab panel.
 * 
 * @param {object} props - Component props.
 * @param {number} props.value - Current tab value.
 * @param {number} props.index - Index of the tab.
 * @param {ReactNode} props.children - Content to be displayed in the tab.
 * @returns {JSX.Element} Tab panel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * Function that returns accessibility props for a tab.
 * 
 * @param {number} index - Index of the tab.
 * @returns {object} Accessibility props for the tab.
 */
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * Component for managing user invitations and access.
 * 
 * @param {object} props - Component props.
 * @param {object} props.organization - Organization data.
 * @returns {JSX.Element} UserInvitationsAndManagement component.
 */
export default function UserInvitationsAndManagement({ organization }) {
  const [getInvites, { data, loading }] = useLazyQuery(
    GET_ORGANIZATION_INVITES
  );

  const [getOrganizationUsers, { data: organizationUsers }] = useLazyQuery(
    GET_ORGANIZATION_USERS
  );

  const { user } = useSelector((state) => state.auth);
  const [invites, setInvites] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [userAccess, setUserAccess] = React.useState(null);
  const [activeOrganization, setActiveOrganization] = React.useState({
    _id: "",
    name: "",
    domainUrl: "",
    industry: "",
  });
  const [open, setOpen] = React.useState(false);
  const [openManageAccess, setOpenManageAccess] = React.useState(false);

  React.useEffect(() => {
    setActiveOrganization((prevState) => {
      return { ...prevState, ...organization?.organization };
    });
  }, [organization]);

  React.useEffect(() => {
    if (organizationUsers?.organizationUsers) {
      setUsers(organizationUsers?.organizationUsers);
    }
  }, [organizationUsers?.organizationUsers]);

  React.useEffect(() => {
    if (data?.invites) {
      setInvites(data?.invites);
    }
  }, [data]);

  const { _id } = activeOrganization;

  React.useEffect(() => {
    if (_id) {
      getInvites({ variables: { input: { organization: _id } } });
      getOrganizationUsers({ variables: { input: _id } });
    }
  }, [_id, getInvites, getOrganizationUsers]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleManageAccess = (data) => {
    setUserAccess(data);
    setOpenManageAccess(true);
  };

  return loading ? (
    <p>Loading...</p>
  ) : _id ? (
    users &&
    users instanceof Array &&
    users[0]?.organization?.user === user?._id ? (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 3,
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Invitations" {...a11yProps(0)} />
                  <Tab label="Users" {...a11yProps(1)} />
                </Tabs>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<AddCircle />}
                  onClick={() => setOpen(true)}
                >
                  new
                </Button>
              </Box>
              <TabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Invitee</TableCell>
                        <TableCell align="center">Invitation Code</TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invites?.map((row) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row?.invitedUserEmail}
                          </TableCell>
                          <TableCell align="center">
                            {row?.invitationCode}
                          </TableCell>
                          <TableCell align="center">
                            {row?.accepted ? (
                              <Chip
                                label="Accepted"
                                color="success"
                                variant="outlined"
                              />
                            ) : (
                              <Chip
                                label="Pending"
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((row) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row?.user?.name}
                          </TableCell>
                          <TableCell align="left">{row?.user?.email}</TableCell>
                          <TableCell align="left">
                            {row?.organization?.user === row?.user?._id ? (
                              <Chip
                                label="Admin"
                                color="success"
                                variant="outlined"
                              />
                            ) : (
                              <Chip
                                label="Basic"
                                color="primary"
                                variant="outlined"
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              disabled={
                                row?.organization?.user === row?.user?._id
                              }
                              onClick={() => handleManageAccess(row)}
                            >
                              Manage
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
        <NewInviteDialog
          open={open}
          setOpen={setOpen}
          id={_id}
          setInvites={setInvites}
        />
        <ManageAccessDialog
          open={openManageAccess}
          setOpen={setOpenManageAccess}
          id={_id}
          setInvites={setInvites}
          userAccess={userAccess}
          users={users}
          setUsers={setUsers}
        />
      </React.Fragment>
    ) : (
      <p>Only organization owner can send invitation!</p>
    )
  ) : (
    <p>No Organization Selected..</p>
  );
}
