import { gql } from "@apollo/client";

export const GET_SINGLE_LEAD_GROUP = gql`
  query leadGroup($id: String!) {
    leadGroup(id: $id) {
      _id
      name
      createdAt
    }
  }
`;
export const GET_ALL_LEAD_GROUP = gql`
  query leadGroups($input: FetchAllLeadGroupInput!) {
    leadGroups(input: $input) {
      _id
      name
      createdAt
    }
  }
`;
