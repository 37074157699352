import React, { useState, useEffect, useRef } from "react";
import BrandAnalytics from "../../components/BrandAnalytics";
import Layout from "../../components/Layout";
import { AnalyticsFrame } from "./styled";

export default function Analytics() {
  return (
    <>
      <AnalyticsFrame>
        <BrandAnalytics
          style={{
            left: "0px",
            top: "0px",
            padding: "0px 50px",
          }}
        />
      </AnalyticsFrame>
    </>
  );
}
