import React, { useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  CheckLabel,
  ControlDiv,
  DisplayDiv,
  DynamicDiv,
  ImageDiv,
  IndicatorDiv,
  LeftDiv,
  LeftSubTitle,
  LeftSubTitleDiv,
  MenuItemC,
  OnButton,
  PreviewButton,
  PreviewText,
  SectionTitle,
  SubTitleDiv,
  SubTitleDiv2,
  WidgetButDiv,
} from "./styled";
import Widget1 from "../../assets/widgetIcon/widget1";
import Widget2 from "../../assets/widgetIcon/widget2";
import Widget3 from "../../assets/widgetIcon/widget3";
import Widget4 from "../../assets/widgetIcon/widget4";

import PreviewIcon from "../../assets/images/preview.svg";
import ColorControl from "../../components/ColorControl";

export default (widgetState, isMobile, handleChange, setModalOpen) => {
  const [focusState, setFocusState] = useState({});
  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("widget") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
        <DisplayDiv
          isMobile={isMobile}
          widsec
          style={{
            height: "216px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <SubTitleDiv isMobile={isMobile}>
            <LeftDiv>
              <LeftSubTitleDiv>Widget Layout</LeftSubTitleDiv>
              <IndicatorDiv
                style={{ marginLeft: isMobile && "3px" }}
                active={widgetState?.widgetVisibility}
              >
                {widgetState?.widgetVisibility ? "ON" : "OFF"}
              </IndicatorDiv>
            </LeftDiv>
            <OnButton
              onClick={() =>
                handleChange(!widgetState?.widgetVisibility, "widgetVisibility")
              }
              sx={{
                ":hover": {
                  border: "1px solid #1a1b24",
                },
              }}
            >
              {" "}
              {widgetState?.widgetVisibility ? "Turn off" : "Turn on"}
            </OnButton>
          </SubTitleDiv>
          <SubTitleDiv2>
            Widget Layout form will be displayed on your site
          </SubTitleDiv2>
          <DynamicDiv isMobile={isMobile}>
            <LeftSubTitle style={{ marginTop: 32, marginBottom: 14 }}>
              Widget Position
            </LeftSubTitle>
            <FormControl
              fullWidth
              size="small"
              sx={{
                // marginBottom: "34px",

                ".MuiInputBase-root": {
                  borderRadius: "15px",
                },
              }}
            >
              <Select
                select
                sx={{
                  height: "50px",

                  ".MuiOutlinedInput-notchedOutline": {
                    border: focusState.position
                      ? `1px solid #007bf7 !important`
                      : "ipx solid #D4DAE1",
                    boxShadow: focusState.position
                      ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40) !important"
                      : undefined,

                    ":hover": {
                      border: focusState.position
                        ? `1px solid #007bf7 !important`
                        : "1px solid #1a1b24",
                    },
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: "12px",
                      padding: "0.4em 0.3em",
                      boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                    },
                  },
                  sx: {
                    borderRadius: "12px",
                    marginTop: "10px",
                    "& .Mui-selected": {
                      backgroundColor: "rgba(25, 118, 210, 0.08)",
                    },
                  },
                }}
                value={widgetState?.position}
                onFocus={() => setFocusState({ ...focusState, position: true })}
                onBlur={() => setFocusState({ ...focusState, position: false })}
                onChange={(e) => handleChange(e.target.value, "position")}
              >
                <MenuItemC value="bottom-left">Bottom Left</MenuItemC>
                <MenuItemC value="bottom-right">Bottom Right</MenuItemC>
              </Select>
            </FormControl>
          </DynamicDiv>
        </DisplayDiv>
        <DisplayDiv
          isMobile={isMobile}
          inactive={!widgetState?.widgetVisibility}
          style={{ height: "calc(100%  - 233px)", marginTop: 16 }}
        >
          {/* <LeftDescription>
        Customize how the widget will look on your website when users want
        to view or leave reviews
      </LeftDescription> */}
          <DynamicDiv isMobile={isMobile}>
            {/* <SectionTitle style={{ marginTop: 0, marginBottom: "16px" }}>
            Widget Button
          </SectionTitle> */}
            {/* <WidgetButDiv>
            <ImageDiv
              onClick={() => handleChange("4", "widgetIcon")}
              active={widgetState?.widgetIcon === "4"}
            >
              <Widget4 />
            </ImageDiv> */}
            {/* <ImageDiv
            onClick={() => handleChange("1", "widgetIcon")}
            active={widgetState?.widgetIcon === "1"}
          >
            
            <Widget1 />
          </ImageDiv> */}
            {/* <ImageDiv
              onClick={() => handleChange("3", "widgetIcon")}
              active={widgetState?.widgetIcon === "3"}
            >
              <Widget3 />
            </ImageDiv>
            <ImageDiv
              onClick={() => handleChange("2", "widgetIcon")}
              active={widgetState?.widgetIcon === "2"}
            > */}
            {/* <img src={Widget2} /> */}
            {/* <Widget2 />
            </ImageDiv> */}

            {/* 
            rEMOVE LATER
            */}
            {/* <ImageTransition /> */}
            {/* </WidgetButDiv> */}

            {/* <SectionTitle style={{ marginBottom: 6 }}>Review Button</SectionTitle>
          <FormGroup
          // style={{ marginTop: "24px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={widgetState?.widgetLeaveReview}
                  onClick={() =>
                    handleChange(
                      !widgetState?.widgetLeaveReview,
                      "widgetLeaveReview"
                    )
                  }
                  sx={{
                    color: "#D9EAF8",
                    "&.Mui-checked": {
                      color: "#4097DA",
                    },
                  }}
                />
              }
              label={
                <CheckLabel>
                  Enable{" "}
                  <span style={{ color: "#000" }}>Leave Review Button</span>
                </CheckLabel>
              }
            />
          </FormGroup>
          <TextField
            value={widgetState?.widgetLeaveReviewText}
            disabled={!widgetState?.widgetLeaveReview}
            sx={{ ".MuiInputBase-root": { borderRadius: "15px" } }}
            inputProps={{ style: { height: "16px" }, maxLength: 15 }}
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, "widgetLeaveReviewText")
            }
          /> */}

            <SectionTitle style={{ marginBottom: 6, marginTop: 0 }}>
              Button Text
            </SectionTitle>

            <TextField
              value={widgetState?.widgetMeetSalesText}
              sx={{
                ".MuiInputBase-root": { height: "50px", borderRadius: "15px" },
                ":hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1a1b24 !important",
                    borderWidth: "1px !important",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#007bf7",
                    borderWidth: "1px !important",
                    boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                  },
                },
                borderRadius: "15px",
              }}
              onFocus={() =>
                setFocusState({ ...focusState, widgetMeetSalesText: true })
              }
              onBlur={() =>
                setFocusState({ ...focusState, widgetMeetSalesText: false })
              }
              inputProps={{ style: { height: "16px" }, maxLength: 15 }}
              fullWidth
              onChange={(e) =>
                handleChange(e.target.value, "widgetMeetSalesText")
              }
            />

            <SectionTitle style={{ marginTop: 12, marginBottom: 6 }}>
              Button Color
            </SectionTitle>

            <ColorControl
              color={widgetState?.buttonColor}
              setColor={(val) => handleChange(val, "buttonColor")}
            />

            {/* <FormGroup style={{ marginTop: "24px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={widgetState?.widgetRating}
                  onClick={() =>
                    handleChange(!widgetState?.widgetRating, "widgetRating")
                  }
                  sx={{
                    color: "#D9EAF8",
                    "&.Mui-checked": {
                      color: "#4097DA",
                    },
                  }}
                />
              }
              label={
                <CheckLabel>
                  Enable <span style={{ color: "#000" }}> Rating</span>
                </CheckLabel>
              }
            />
          </FormGroup> */}

            {isMobile && (
              <SectionTitle
                onClick={() => setModalOpen(true)}
                style={{ marginTop: "16px", marginBottom: "16px" }}
              >
                <PreviewButton>
                  <img src={PreviewIcon} />
                  <PreviewText>Preview</PreviewText>
                </PreviewButton>
              </SectionTitle>
            )}
          </DynamicDiv>
        </DisplayDiv>
      </Grid>
    )
  );
};
