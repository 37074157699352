import { gql } from "@apollo/client";

const GET_BRANDS = gql`
  query brands {
    brands {
      brandId
      name
      imageUrl
      organization {
        name
        industry
        _id
      }
      sector
      description
    }
  }
`;
const GET_BRAND = gql`
  query brand($input: GetBrandInput!) {
    brand(input: $input) {
      _id
      brandId
      name
      imageUrl
      organization {
        name
        industry
        _id
      }
      description
      sector
      borderSize
      backgroundColor
      color
      width
      position
      visibility
      widgetIcon
      borderColor
      brandColor
      option
      divTag
      sectionHeader
      paragraph
      textAlignment
      sectionStyle
      brandUrl
      leaveReview
      sectionHeaderFontSize
      sectionHeaderColor
      sectionParaFontSize
      sectionParaColor
      sectionLeaveReviewText
      sectionLeaveReviewFontSize
      widgetVisibility
      sectionVisibility
      widgetLeaveReview
      sectionLeaveReview
      widgetLeaveReviewText
      sectionHeaderLineHeight
      sectionParaLineHeight
      widgetMeetSalesText
      buttonColor
      text1
      text2
      text3
      picture
      telephone
      socialLinks
      testimonialVideos
      email
      googleReviewUrl
      googleReviewLogo
      googleRedirectUrl
      textMessage
      emailHeading1
      emailText1
      emailTextAction1
      emailGoogleButtonText
      emailText2
      emailContactText
      emailFooter
      emailTestimonialVideos
      emailText3
      googleReviewSet
    }
  }
`;

const GET_WIDGET_BRAND = gql`
  query widgetBrand($input: GetWidgetBrandInput!) {
    widgetBrand(input: $input) {
      _id
      brandId
      name
      organization {
        name
        industry
      }
      description
      sector
      borderSize
      backgroundColor
      color
      width
      position
      widgetIcon
      divTag
      option
    }
  }
`;

const GET_ORGANIZATION_BRANDS = gql`
  query organizationBrands($input: GetOrganizationInput!) {
    organizationBrands(input: $input) {
      _id
      name
    }
  }
`;
const GET_AVERAGE_RATING = gql`
  query getAverageRating($brandId: String!) {
    getAverageRating(brandId: $brandId)
  }
`;

const GET_BRAND_TAGS = gql`
  query brand($input: GetBrandInput!) {
    brand(input: $input) {
      _id
      brandId
      name
      tags {
        id
        name
        description
      }

    }
  }
`;


export {
  GET_BRANDS,
  GET_BRAND,
  GET_ORGANIZATION_BRANDS,
  GET_AVERAGE_RATING,
  GET_WIDGET_BRAND,
  GET_BRAND_TAGS
};
