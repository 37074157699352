import { gql } from "@apollo/client";

export const FETCH_MARKETING_PAGES = gql`
  query fetchPages($br_id: String!) {
    fetchPages(br_id: $br_id) {
      brand {
        _id
        brandId
      }
      pages {
        _id
        link
        title
      }
      updatedAt
    }
  }
`;

export const FETCH_CAMPAIGNS = gql`
  query fetchCampaignPages($br_id: String!) {
    fetchCampaignPages(br_id: $br_id) {
      brand {
        _id
        brandId
      }
      campaignUnits {
        id
        campaignName
        medium
        source
        page {
          link
          title
          _id
        }
      }
      updatedAt
    }
  }
`;

export const FETCH_CAMPAIGN_REPORTS = gql`
  query fetchCampaignReports($input: FetchCampaignReportInput!) {
    fetchCampaignReports(input: $input) {
      dimensions {
        sessionManualSourceMedium
        pagePath
      }
      metrics {
        activeUsers
        sessions
        engagedSessions
        userEngagementDuration
        eventsPerSession
        engagementRate
        avgEngagementTimePerSession
        engagedSessionPerUser
        eventCount
      }
    }
  }
`;
