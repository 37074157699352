import styled from "@emotion/styled";
import { Box, Button, ButtonBase, Divider, Typography } from "@mui/material";

export const InstructionDiv = styled(Box)`
  border-left: 1px dashed #d4dae1;
  margin-left: 13px;
`;

export const DivCont = styled(Box)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StepNumber = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5px 10px;
  gap: 10px;
  width: 28px;
  height: 28px;
  // background: #eee9ff;
  // background: rgba(25, 118, 210, 0.08);
  // background: rgba(25, 118, 210, 0.6);
  background: #e3eefa;
  border-radius: 50%;
  margin-left: -15px;
`;

export const StepContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const StepInstructionDiv = styled(Box)`
  margin-left: 12px;
  width: 80%;
`;

export const StepTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #1a1b24;
`;

export const CopyButton = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 18px;
  gap: 10px;
  height: 40px;
  border: 1px solid #d4dae1;
  border-radius: 10px;
  margin-top: 22px;
  margin-bottom: 37.5px;
  background-color: ${(props) => props?.copied && "#007bf7"};
  &:hover {
    border-color: #1a1b24;
  }
`;

export const CopyText = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => (props?.copied ? "#ffffff" : "#1a1b24")};
  margin-left: ${(props) => (props?.copied ? 0 : "8px")};
`;

export const StepTitle2 = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  display: inline-block;
  margin-bottom: 25.5px;
`;

export const StepTitle2Bold = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1a1b24;
  display: inline-block;
`;

export const StepTitle2Bold2 = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  display: inline-block;
`;

export const StepTitle3 = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  padding-top: 3px;
`;
//Request review before insights
export const VerifiedButton = styled(ButtonBase)`
  height: 40px;
  background: ${(props) =>
    props?.verified === true
      ? "#45C17E"
      : props?.verified === false
      ? "#D93047"
      : props?.verified === undefined && props?.errorProp
      ? "#D93047"
      : "#f7f7f7"};

  border: 1px solid #d4dae1;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 10px;
  box-sizing: border-box;
  margin-left: 12px;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => (props?.verified ? "#FFFFFF" : "#1a1b24")};
  &:hover {
    border-color: #1a1b24;
  }
`;
