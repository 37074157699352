import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const MarketingSideBarContainer = styled(Box)`
  width: 250px;
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  .content {
    display: flex;
    flex-direction: column;
    background-color: #101010;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* padding-left: 20px;
    padding-right: 20px; */
  }
`;