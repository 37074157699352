import { Box, FormControl, Grid, InputBase, Select } from "@mui/material";
import Table from "../../components/Table";
import {
  NameBox,
  NameFBox,
  NameB,
  MessageB,
  ReqContFl,
  ReqContFl2,
  ReqCont,
  ReqImg,
} from "./styled";
import EditIcon from "../../assets/icons/EditIcon.svg";
import Trash from "../../assets/icons/Trash.svg";
import Ellipsis3 from "../../assets/images/ellipsis3.svg";
import { useEffect, useRef, useState } from "react";
import { MenuItemC } from "../Customization/styled";
import ModalComp from "./modalComp";
import UplComp from "./uplComp";
import EditComp from "./editComp";
import LeadsTopComp from "./leadsTopComp";
import { useSnackbar } from "notistack";
import { DELETE_LEAD, UPDATE_LEAD } from "../../graphql/mutations/leads";
import { useMutation, useQuery } from "@apollo/client";
import DeleteModal from "../../components/DeleteModal";
import RequestTestimonyModal from "../../components/RequestTestimonyModal";
import { useNavigate } from "react-router-dom";
import { SeeMore, SeeMoreText } from "../Organizations/styled";
import TwoLinesDiv from "../../components/TwoLinesDiv";
import { BootstrapTooltip } from "../../components/DashboardTable";
import RequestGoogleReviewModal from "../../components/RequestGoogleReviewModal";
import { GET_BRAND_TAGS } from "../../graphql/queries/brandQueries";
import { useSelector } from "react-redux";

export const NameTagBox = ({ name, active, source }) => {
  let strNameArray = String(name).trim().split(" ");
  let strName;
  if (strNameArray.length === 1) {
    strName = `${name[0]}${name[1]}`;
  } else {
    strName = `${strNameArray[0][0]}${strNameArray[1][0]}`;
  }
  return (
    <NameFBox>
      <NameBox active={active}>{strName.toUpperCase()}</NameBox>
      <NameB>
        {name}
        {source ? (
          <>
            <br />
            {`(${source})`}
          </>
        ) : null}
      </NameB>
    </NameFBox>
  );
};

export default ({
  rows: nRows,
  brandObj,
  isMobile,
  page,
  newItemCount,
  reload,
  br_id,
  brandId,
  changeToInstallation,
}) => {
  let rows = [...nRows];
  const sortRef = useRef();
  const selRef = useRef();

  const [sortDirection, setSortDirection] = useState("descending");
  const [searchQuery, setSearchQuery] = useState("");
  const [focusState, setFocusState] = useState({});
  const menu = useSelector((state) => state.menu);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedSortText, setSelectedSortText] = useState("Alphabetical");
  const [selectedSortOption, setSelectedSortOption] = useState("");
  const [showSort, setShowSort] = useState(false);
  const [showSel, setShowSel] = useState(false);
  const [seemore, setSeemore] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);

  const [display, setDisplay] = useState(false);
  const [uplDisplay, setUplDisplay] = useState(false);
  const [edtDisplay, setEdtDisplay] = useState(false);
  const [reqDisplay, setReqDisplay] = useState(false);
  const [googleReqDisplay, setGoogleReqDisplay] = useState(false);
  const [delDisplay, setDelDisplay] = useState(false);
  const [delGrpDisplay, setDelGrpDisplay] = useState(false);
  const [mItem, setMItem] = useState("leads");
  const [rowItem, setRowItem] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [deleteLead] = useMutation(DELETE_LEAD);
  const [updateLead] = useMutation(UPDATE_LEAD);
  const { data: fetchedData, loading: loadingData } = useQuery(GET_BRAND_TAGS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      input: { _id: br_id },
    },
  });
  const [debouncedQuery, setDebouncedQuery] = useState(null);
  const tagsList =
    fetchedData?.brand?.tags?.map(({ id, name, description }) => ({
      id,
      name,
      description,
    })) ?? [];
  const limitRows = 5;

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedQuery !== null) {
      // Perform search or API call with debouncedQuery
      console.log("Performing search with query:", debouncedQuery);
      reload({
        input: { br_id: menu?.id, searchText: debouncedQuery, tag: mItem },
      });
    }
  }, [debouncedQuery]);

  const handleDelete = async () => {
    try {
      let { data, errors } = await deleteLead({
        variables: {
          input: {
            deleteAll: !searchQuery && allChecked && delGrpDisplay,
            br_id: menu?.id,
            lead_id: delDisplay ? [rowItem?._id] : checkedIds,
          },
        },
      });

      if (!data && errors.length > 0) {
        enqueueSnackbar(`Delete Failed: ${errors[0].message ?? ""}`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Deleted successfully", { variant: "success" });
        reload && reload();
        setDelDisplay(false);
        setDelGrpDisplay(false);
        setDisplay(false);
      }
    } catch {
      enqueueSnackbar("Error deleting", { variant: "error" });
    }
  };

  const handleReqTestimonial = () => {
    let { name, phoneNumber, email, _id } = rowItem;
    window.open(
      `/request_testimonial/${brandId}?lid=${_id}&name=${name ?? ""}&phone=${
        phoneNumber ?? ""
      }&email=${email ?? ""}`,
      "_blank"
    );
    setReqDisplay(false);
  };

  const handleChange = async (e, lead_id) => {
    try {
      e.stopPropagation();
      let { data, errors } = await updateLead({
        variables: {
          input: {
            lead_id,
            status: e.target.value,
          },
        },
      });

      if (!data && errors.length > 0) {
        enqueueSnackbar(`Update Failed: ${errors[0].message ?? ""}`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Updated successfully", { variant: "success" });
        reload && reload();
      }
    } catch {
      enqueueSnackbar("Error updating", { variant: "error" });
    }
  };

  if (!(rows instanceof Array)) rows = [];

  // console.log(checkedIds, "check Ids", rowItem);

  const tableHeadCells =
    mItem === "leads"
      ? [
          {
            id: "rname",
            numeric: false,
            disablePadding: true,
            label: "Name",
            width: "16.6%",
          },
          {
            id: "rphoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Phone Number",
            width: "16.6%",
          },
          {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            width: "16.6%",
          },

          {
            id: "rmessage",
            numeric: false,
            disablePadding: true,
            label: "Message",
            width: "16.6%",
          },
          {
            id: "rstatus",
            numeric: false,
            disablePadding: true,
            label: "Status",
            width: "16.6%",
          },
          {
            id: "rtags",
            numeric: false,
            disablePadding: true,
            label: "Tags",
            width: "16.6%",
          },
          {
            id: "saction",
            numeric: false,
            disablePadding: true,
            label: "",
            width: "16.6%",
          },
        ]
      : [
          {
            id: "rname",
            numeric: false,
            disablePadding: true,
            label: "Name",
            width: "16.6%",
          },
          {
            id: "rphoneNumber",
            numeric: false,
            disablePadding: true,
            label: "Phone Number",
            width: "16.6%",
          },
          {
            id: "email",
            numeric: false,
            disablePadding: true,
            label: "Email",
            width: "16.6%",
          },
          {
            id: "rstatus",
            numeric: false,
            disablePadding: true,
            label: "Status",
            width: "16.6%",
          },
          {
            id: "rtags",
            numeric: false,
            disablePadding: true,
            label: "Tags",
            width: "16.6%",
          },
          {
            id: "raction",
            numeric: false,
            disablePadding: true,
            label: "Actions",
            width: "16.6%",
          },
        ];

  const compareString = (val, qry, flg = false) => {
    if (!val) return false;
    return flg
      ? String(val).toLowerCase().trim() == qry.trim().toLowerCase()
      : String(val).toLowerCase().trim().includes(qry.toLowerCase());
  };

  //mutation: delete and update should be done here..

  //////////////
  const formatPhoneNumber = (el) => {
    return el?.phoneCode && el?.phoneNumber
      ? `${el.phoneCode}${Number.parseInt(el.phoneNumber)}`
      : el?.phoneNumber;
  };

  //////////////
  const formatAttachedTags = (el) =>
    tagsList
      ?.filter(({ id }) => el?.attachedTags?.includes(id))
      ?.reduce(
        (acc, { name }, index) => `${acc}${!!index ? ", " : ""}${name}`,
        ""
      );

  const getRows = () => {
    return rows?.map((row, index) => {
      let obj = {};
      obj.phoneNumber = formatPhoneNumber(row);
      obj.rname = (
        <NameTagBox
          active={row.status === "contacted"}
          name={row.name}
          source={row.source}
        />
      );
      obj.rstatus = (
        <FormControl
          key={`status${index}`}
          size="small"
          sx={{
            // marginBottom: "34px",

            ".MuiInputBase-root": {
              borderRadius: "8px",
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Select
            sx={{
              height: "32px",
              color: "#1A1B24",
              /* Body1/Regular */
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "20px",
              paddingTop: "6px",
              width: "160px",

              backgroundColor:
                row.status === "contacted"
                  ? "#E4FFF1"
                  : "rgba(217, 48, 48, 0.10)",

              ".MuiOutlinedInput-notchedOutline": {
                border: focusState[`Status${index}`]
                  ? `1px solid #007bf7 !important`
                  : "1px solid transparent",
                boxShadow: focusState[`Status${index}`]
                  ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40) !important"
                  : undefined,
                height: "auto",
                padding: "4px 12px",
                ":hover": {
                  border: focusState[`Status${index}`]
                    ? `1px solid #007bf7 !important`
                    : "1px solid #1a1b24",
                },
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: "8px",
                  padding: "0.4em 0.3em",
                  boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                },
              },
              sx: {
                borderRadius: "8px",
                marginTop: "5px",
                "& .Mui-selected": {
                  backgroundColor:
                    row.status === "contacted"
                      ? "#E4FFF1"
                      : "rgba(217, 48, 48, 0.10)",
                },
              },
            }}
            value={row.status === "contacted" ? "contacted" : "pending"}
            onFocus={() =>
              setFocusState({ ...focusState, [`Status${index}`]: true })
            }
            onBlur={() =>
              setFocusState({ ...focusState, [`Status${index}`]: false })
            }
            onChange={(e) => handleChange(e, row?._id)}
          >
            <MenuItemC value="contacted">Contacted</MenuItemC>
            <MenuItemC value="pending">Not Contacted</MenuItemC>
          </Select>
        </FormControl>
      );

      // obj.rmessage = <MessageB>{row.message}</MessageB>;
      obj.rmessage = <TwoLinesDiv text={row.message} />;

      obj.rphoneNumber = <NameB>{formatPhoneNumber(row)}</NameB>;
      obj.rtags = <NameB>{formatAttachedTags(row)}</NameB>;

      obj.saction = (
        <img
          src={Ellipsis3}
          style={{ cursor: "pointer" }}
          onClick={() => setDisplay(!display)}
        />
      );
      obj.raction = (
        <ReqContFl>
          {/* <ReqCont
            onClick={(e) => {
              e.stopPropagation();
              setGoogleReqDisplay(true);
              setRowItem(row);
            }}
          >
            Request Google Review
          </ReqCont> */}
          <ReqCont
            onClick={(e) => {
              e.stopPropagation();
              setReqDisplay(true);
              setRowItem(row);
            }}
          >
            Request Testimonial
          </ReqCont>
          <ReqContFl2>
            <BootstrapTooltip title="Edit">
              {" "}
              <ReqImg
                src={EditIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setEdtDisplay(true);
                  setRowItem(row);
                }}
              />
            </BootstrapTooltip>

            <BootstrapTooltip title="Delete">
              <ReqImg
                src={Trash}
                onClick={(e) => {
                  e.stopPropagation();
                  setDelDisplay(true);
                  setRowItem(row);
                }}
              />
            </BootstrapTooltip>
          </ReqContFl2>
        </ReqContFl>
      );
      return { ...row, ...obj };
    });
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const rowClick = (row) => {
    setDisplay(!display);
    setRowItem(row);
  };

  const getItemsClickedFromRow = () => {
    return rows.filter(
      (row) => checkedIds instanceof Array && checkedIds.includes(row?._id)
    );
  };

  const showGoogleReview = () => {
    if (brandObj?.googleReviewSet) {
      setGoogleReqDisplay(true);
    } else {
      changeToInstallation();
    }
  };

  const handleBodyClick = (event) => {
    const path = event.composedPath(); // Use composedPath() instead of event.path

    // Check if the clicked element is not the button or custom component
    if (!path.includes(sortRef.current)) {
      setShowSort(false); // Set showBrand to false when clicked outside of button or custom component
    }
    if (!path.includes(selRef.current)) {
      setShowSel(false); // Set showBrand to false when clicked outside of button or custom component
    }
  };
  useEffect(() => {
    // Add event listener to body element
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    setSeemore(false);
  }, [mItem]);

  // console.log(checkedIds, "checkedIds");
  return (
    <Grid container spacing={0} sx={{ padding: 0 }}>
      <Grid item xs={12} md={12}>
        <LeadsTopComp
          mItem={mItem}
          total={page?.totalAllContacts}
          reload={reload}
          setMItem={setMItem}
          isMobile={isMobile}
          rows={rows}
          allChecked={allChecked}
          focusState={focusState}
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          setFocusState={setFocusState}
          newItemCount={newItemCount}
          sortRef={sortRef}
          setShowSort={setShowSort}
          showSort={showSort}
          selRef={selRef}
          setShowSel={setShowSel}
          showSel={showSel}
          selectedSortText={selectedSortText}
          setSelectedSortText={setSelectedSortText}
          sortDirection={sortDirection}
          newRecordCount={newItemCount}
          setUplDisplay={setUplDisplay}
          selectedSortOption={selectedSortOption}
          idsCount={checkedIds?.length ?? 0}
          triggerReqGoogleReview={showGoogleReview}
          triggerDelete={() => setDelGrpDisplay(true)}
        />
        <Table
          headCells={tableHeadCells}
          rowClick={rowClick}
          rows={getRows()}
          stylehead
          handleCheckAll={setAllChecked}
          withCheckbox
          setCheckedIds={setCheckedIds}
          ind="_id"
          shouldRefresh={`${mItem}${searchQuery}${selectedSortText}${page?.pageCount}${seemore}${rows?.length}`}
        />
        {page?.hasNextPage && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SeeMore
              onClick={() => {
                reload({
                  input: {
                    br_id: menu?.id,
                    searchText: debouncedQuery,
                    tag: mItem,
                    selectedSortText,
                    itemsPerPage: page?.itemsPerPage + limitRows,
                  },
                });
              }}
              style={{
                top: "0 !important",
              }}
              className="button-custom"
              tabIndex="1"
            >
              <SeeMoreText>See More</SeeMoreText>
            </SeeMore>
          </Box>
        )}
        <ModalComp
          data={rowItem}
          triggerReqTestimonial={() => setReqDisplay(true)}
          triggerDelete={() => setDelDisplay(true)}
          // triggerReqGoogleReview={() => setGoogleReqDisplay(true)}
          triggerReqGoogleReview={showGoogleReview}
          display={display}
          setDisplay={setDisplay}
        />
        <UplComp
          reload={reload}
          onClose={() => setUplDisplay(false)}
          openDialog={uplDisplay}
          br_id={br_id}
        />

        <EditComp
          reload={reload}
          tagsList={tagsList}
          onClose={() => setEdtDisplay(false)}
          openDialog={edtDisplay}
          data={rowItem}
        />

        <DeleteModal
          openModal={delDisplay || delGrpDisplay}
          handleCloseModal={() => {
            setDelDisplay(false)
            setDelGrpDisplay(false)
          }}
          handleModalConfirm={handleDelete}
          isMobile={isMobile}
          headerText={`Delete ${
            checkedIds?.length > 1 && delGrpDisplay
              ? "these leads"
              : "this lead"
          }`}
          subHeaderText={`You are about to delete ${
            checkedIds?.length > 1 && delGrpDisplay
              ? "these leads"
              : "this lead"
          }`}
        />
        <RequestTestimonyModal
          openModal={reqDisplay}
          handleCloseModal={() => setReqDisplay(false)}
          handleModalConfirm={handleReqTestimonial}
          isMobile={isMobile}
        />

        <RequestGoogleReviewModal
          openModal={googleReqDisplay}
          handleCloseModal={() => setGoogleReqDisplay(false)}
          // handleModalConfirm={handleDelete}
          item={rowItem}
          isMobile={isMobile}
          formstate={brandObj}
          idsCount={checkedIds?.length ?? 0}
          batchRecord={getItemsClickedFromRow()}
          br_id={br_id}
        />
      </Grid>
    </Grid>
  );
};
