import { Box } from "@mui/material";
import {
  LeftTopSubTitle,
  StepTitle,
  VideoPartWrapper,
  Step2SubDiv,
} from "../Send-Testimonial/ReviewComp/styled";
import ReactPlayer from "react-player";
import Play from "../../assets/images/play.svg";
import RecordUpload from "../../assets/images/teenage-female.jpg";
import ColorButton from "../ColorButton";
import Camera from "../../assets/icons/Camera.svg";
import LoadingComp from "../Send-Testimonial/Loading";
import Upload from "../../assets/icons/Upload.svg";
import Trash from "../../assets/icons/Trash.svg";
import ReviewRecord from "../Send-Testimonial/ReviewComp/reviewRecord";

export default ({
  isMobile,
  videoInfo,
  setRecordingData,
  trail,
  isVideoOff,
  setNewStep,
  setGetPermission,
  setGetPermissions,
  setOpenModal,
  openModal,
  newVideoUrl,
  newPicUrl,
  setNewVideoUrl,
  setNewPicUrl,
  setRecordedVideoUrlSections,
  setRecordedPicUrlSections,
  recordedPicUrlSections,
  recordedVideoUrlSections,
  openCam,
  setOpenCam,
  setRunPlayHandle,
  runPlayHandle,
  addVideoData,
  videoData,
  onRequestAudioVideoPermissionsModal,
  handleVideoUpload,
  loading,
  step,
  runPlayHandler,
  deleteVideoUrl,
}) => {
  return (
    <VideoPartWrapper isMobile={isMobile}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "100%",
            // display: "flex",

            // alignItems: "center",
            position: "relative",
          }}
        >
          <StepTitle style={{ fontSize: "32px" }}>
            Record or upload a video.
          </StepTitle>

          <LeftTopSubTitle style={{ marginBottom: "32px", fontSize: "16px" }}>
            {" "}
            Specify what you expect from the receiver.
          </LeftTopSubTitle>

          <div
            style={{
              display: !isMobile || (isMobile && step === 2) ? "block" : "none",

              marginTop: isMobile && "32px",
            }}
          >
            <ReviewRecord
              videoInfo={videoInfo}
              setRecordingData={setRecordingData}
              trail={trail}
              isVideoOff={isVideoOff}
              setNewStep={setNewStep}
              isMobile={isMobile}
              setGetPermission={setGetPermission}
              setGetPermissions={setGetPermissions}
              setOpenModal={setOpenModal}
              openModal={openModal}
              newVideoUrl={newVideoUrl}
              newPicUrl={newPicUrl}
              setNewVideoUrl={setNewVideoUrl}
              setNewPicUrl={setNewPicUrl}
              setRecordedVideoUrlSections={setRecordedVideoUrlSections}
              setRecordedPicUrlSections={setRecordedPicUrlSections}
              recordedPicUrlSections={recordedPicUrlSections}
              recordedVideoUrlSections={recordedVideoUrlSections}
              openCam={openCam}
              setOpenCam={setOpenCam}
              setRunPlayHandle={setRunPlayHandle}
              runPlayHandle={runPlayHandle}
              addVideoData={addVideoData}
            />
            <div
              style={{
                width: isMobile ? "100%" : "100%",

                display: "flex",
                justifyContent: "center",
              }}
            >
              {newVideoUrl && videoData.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    width: isMobile ? "100%" : "100%",
                    height: isMobile ? "100%" : "100%",
                  }}
                >
                  <ReactPlayer
                    // ref={videoPlayerRef}
                    className="custom-player-play-modal"
                    url={newVideoUrl}
                    // playing={playing}

                    width="100%"
                    // controls={true}
                    height="400px"
                    // onEnded={() => {
                    //   setStRecord();
                    //   playPauseHandler();
                    // }}
                    // onProgress={progressHandler}
                    // onBuffer={bufferStartHandler}
                    // onBufferEnd={bufferEndHandler}
                  />

                  <div
                    onClick={runPlayHandler}
                    style={{
                      position: "absolute",
                      top: isMobile ? "50%" : "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      // zIndex: 2,
                      width: isMobile ? "35px" : "auto",
                      height: isMobile ? "35px" : "auto",

                      color: "white",
                      border: "none",

                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={Play} style={{ width: "100%" }} alt="controls" />
                  </div>
                </div>
              ) : (
                <img
                  src={RecordUpload}
                  // style={{ width: "100%" }}
                  style={{ width: "295px" }}
                  alt="vector"
                />
              )}
            </div>
          </div>

          {step === 2 && (
            <div
              style={{
                marginBottom: "100px",
              }}
            >
              <Step2SubDiv style={{ width: "100%", marginTop: "1rem" }}>
                <ColorButton
                  title={"Camera"}
                  background={"#F1F3EC"}
                  width={"48%"}
                  image={Camera}
                  order={true}
                  greyBg={"greyBg"}
                  imgStyle={{ marginTop: 0 }}
                  onClick={() => {
                    onRequestAudioVideoPermissionsModal();
                  }}
                />
                <input
                  type="file"
                  accept="video/*"
                  style={{ display: "none" }}
                  onChange={handleVideoUpload}
                />
                <ColorButton
                  image={Upload}
                  order={true}
                  // pointerEvents={
                  //   recordingData.videoUrl ? "auto" : "none"
                  // }
                  title={loading ? <LoadingComp loading={loading} /> : "Upload"}
                  background={"#F1F3EC"}
                  greyBg={"greyBg"}
                  width={"48%"}
                  onClick={() => {
                    document.querySelector('input[type="file"]').click();
                  }}
                />
              </Step2SubDiv>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginTop: "1rem",
                }}
              >
                {videoData.map((data, index) => (
                  <div
                    key={index}
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "12.475px",
                      margin: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        position: "relative",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <ReactPlayer
                        className="custom-player-play-modal"
                        url={data.url}
                        controls={false}
                        width="100%"
                        height="100%"
                        onClick={() => setNewVideoUrl(data.url)}
                      />
                      <div
                        style={{
                          position: "absolute",
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {data.url === newVideoUrl ? (
                          <div
                            style={{
                              background: "rgba(0,0,0,0.4)",
                              borderRadius: "12.475px",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <img
                              style={{
                                color: "black",
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              src={Trash}
                              alt="delete"
                              onClick={() => deleteVideoUrl(data.randomString)}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              borderRadius: "12.475px",
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                            onClick={() => setNewVideoUrl(data.url)}
                          >
                            <img
                              style={{
                                color: "black",
                                width: "20px",
                                height: "20px",
                              }}
                              src={Play}
                              alt="play"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Box>
      </Box>
    </VideoPartWrapper>
  );
};
