import * as React from "react";
import TextField from "@mui/material/TextField";
import {
  FlexColumnContainer,
  StyledAuthButton,
  StyledFormLabelText,
} from "./styled";

/**
 * CompanyDetails component displays and allows editing of company details.
 * @param {Object} props - The component's props.
 * @param {Object} props.organization - The organization data to be displayed.
 * @param {string} props.organization.name - The company name.
 * @param {string} props.organization.domainUrl - The company domain URL.
 * @param {string} props.organization.industry - The company industry.
 * @returns {JSX.Element} - Rendered CompanyDetails component.
 */
const CompanyDetails = ({ organization }) => {
  const [activeOrganization, setActiveOrganization] = React.useState({
    name: "",
    domainUrl: "",
    industry: "",
  });

  React.useEffect(() => {
    setActiveOrganization((prevState) => {
      return { ...prevState, ...organization?.organization };
    });
  }, [organization]);

  /**
   * Handle input change event.
   * @param {Object} e - The input change event object.
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setActiveOrganization((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const { name, domainUrl, industry } = activeOrganization;

  return !activeOrganization ? (
    <div></div>
  ) : (
    <FlexColumnContainer
      component="form"
      noValidate
      // onSubmit={handleSubmit(onSubmit)}
    >
      <FlexColumnContainer>
        <StyledFormLabelText>Company Name*</StyledFormLabelText>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label=""
          placeholder=""
          name="name"
          value={name}
          onChange={handleChange}
          autoFocus
        />
      </FlexColumnContainer>

      <FlexColumnContainer>
        <StyledFormLabelText>Domain*</StyledFormLabelText>
        <TextField
          id="domainUrl"
          name="domainUrl"
          margin="normal"
          required
          fullWidth
          value={domainUrl}
          onChange={handleChange}
          label=""
          placeholder=""
          type="text"
        />
      </FlexColumnContainer>
      <FlexColumnContainer>
        <StyledFormLabelText>Industry*</StyledFormLabelText>
        <TextField
          margin="normal"
          required
          fullWidth
          name="industry"
          value={industry}
          onChange={handleChange}
          label=""
          placeholder=""
          type="text"
          id="industry"
        />
      </FlexColumnContainer>
      <StyledAuthButton type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
        Save
      </StyledAuthButton>
    </FlexColumnContainer>
  );
};

export default CompanyDetails;
