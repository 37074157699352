import { gql } from '@apollo/client';
import { USER } from '../fragments/user';

export const START_FREE_TRIAL = gql`
  mutation StartFreeTrial($userId: String!, $createdAt: String!) {
    startFreeTrial(userId: $userId, createdAt: $createdAt) {
      ...UserFields
    }
  }
${USER}`;

export const END_FREE_TRIAL = gql`
  mutation EndFreeTrial($userId: String!) {
    endFreeTrial(userId: $userId)
  }
`;