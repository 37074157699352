import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";

export const ButtonText = styled(Typography)`
  // width: 66px;
  // height: 24px;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;

  /* Secondary Main */

  color: #28262c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const TButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  gap: 10px;
  text-transform: capitalize;
  // width: 180px;
  height: 48px;

  /* White */

  background: #ffffff;
  /* Element */

  // border: 1px solid #d4dae1;
  border-radius: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  border: ${(props) =>
    props?.focus ? " 1px solid #007bf7" : "1px solid #D4DAE1"};

  box-shadow: ${(props) =>
    props?.focus && "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"};

  &:hover {
    border: ${(props) =>
      props?.focus ? " 1px solid #007bf7" : "1px solid #1a1b24"};
  }
`;
