import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { Box } from "@mui/system";

export const WebsiteCardStyle = styled(Card)`
  width: 150px;
  height: 250px;
  border-radius: 10px;
  position: relative;
`;

export const ProductImgStyle = styled("img")({
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export const CardContent = styled(Box)`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top_intro{
      margin-left: .625rem;
    }
  }
  .bottom {
    padding: 0.9375rem 0.625rem;
    width: 100%;
  }
`;

export const Label = styled(Box)`

  width: fit-content;
  height: fit-content;
  background-color: ${(props) =>
    props?.status === "published" ? "#559ced" : "#e75c67"};
  border-radius: 0.375rem;
  padding: 0.1875rem 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
`;
