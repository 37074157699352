import { gql } from "@apollo/client";

const GET_NOTIFICATION_BY_USER = gql`
query NotificationByUserId($userId: String!) {
    notificationByUserId(userId: $userId) {
        _id
        userId
        
        createdAt
       notificationMessage{
         message
         type
        status
        invitationCode
       videoName
        videoPosition
        videoRating
        recent
        owned
        brandAccessName{
          brand
          access
          brandColor
          id
          brandId
          
        }
        thumbnail
        createdAt
        notificationId
      
       }
    }
  }
  
`;

export {
    GET_NOTIFICATION_BY_USER
  };