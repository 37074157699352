import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//graphql
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/client";
//redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import Main from "./router/main.route";
import ErrorBoundary from "./components/ErrorBoundry";
import { SnackbarProvider } from "notistack";
import { io } from "socket.io-client";

const theme = createTheme();
const apiUrl = process.env.REACT_APP_SERVER_URL || "http://localhost:5000";

export const socket = io(
  `ws${apiUrl.includes("https") ? "s" : ""}://${apiUrl.replace(
    /https?:\/\//g,
    ""
  )}`,
  {
    path: "/my-custom-path/",
  }
);
export default function App() {
  const initSocket = () => {
    // this.setState({ socket })
    socket.on("connect", () => console.log("Connected"));
    // socket.on( events.LOGOUT, this.setUsers( false ))
    // socket.on( events.NEW_USER, this.setUsers( true ))
  };

  React.useEffect(() => {
    initSocket();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApolloProvider client={client}>
            <SnackbarProvider maxSnack={3}>
              <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <ErrorBoundary>
                  <Main />
                </ErrorBoundary>
              </Grid>
            </SnackbarProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
