import { FormWrapper, StepTitle, BottomWrapper } from "../ReviewComp/styled";
import { Avatar, Box, Checkbox, Container, Typography } from "@mui/material";
import ColorButton from "../../ColorButton";
import LoadingComp from "../Loading";

export default ({
  newStep,
  step,
  isMobile,
  displayForm,
  mobileFormValidation,
  loading,
}) => {
  return (
    (newStep === 1 || step === 1) && (
      <FormWrapper isMobile={isMobile}>
        <StepTitle>Fill out the receiver's information.</StepTitle>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <StepTitle>Personal Info</StepTitle>
          {!isMobile && (
            <Box
              style={{ marginBottom: "2.7rem", marginLeft: "10px" }}
            >
              <img
                src={TestimonialVectorSix}
                alt="testimonial vector"
              />
            </Box>
          )}
        </div> */}
        <Box
          sx={{
            width: "100%",
            // display: "flex",
            // alignItems: "center",
            marginBottom: "1rem",
            position: "relative",
          }}
        ></Box>

        {displayForm()}

        <BottomWrapper
          style={{
            display: "flex",
            justifyContent: "center",
            marginBlock: "2rem",
            width: "100%",
          }}
        >
          {/* {newStep === 2 && (
              <PreviousButton onClick={handlePrevious}>
                <PreviousButtonText>Previous</PreviousButtonText>
              </PreviousButton>
            )} */}

          <ColorButton
            pointerEvents={"auto"}
            title={loading ? <LoadingComp loading={loading} /> : "Continue"}
            buttonTextStyle={{ fontSize: "18px" }}
            background={"#1A1B24"}
            width={"70%"}
            // onClick={mobileFormValidation}
            onClick={() => mobileFormValidation()}
          />
        </BottomWrapper>
      </FormWrapper>
    )
  );
};
