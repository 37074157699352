import React from "react";
import { DisplayDiv, DisplayDivTitle, EmailBadgeDiv, EmptyDiv } from "./styled";
import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.js";
import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.js";
import EmptyWidget from "../../assets/widgetIcon/EmptyStateWidget.svg";
import CloseIcon from "../../assets/images/Close.svg";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { Grid } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { UserDetailsImage } from "../UserDashboard/styled";
import VideoComp from "../../components/Main/SectionComp/videoComp";

export default (
  widgetState,
  isMobile,
  bodyTagObject,
  setModalOpen,
  socialLinkArray,
  approvedVideos,
  userData
) => {
  const processEnvDataServing=  process.env.REACT_APP_AWS_S3_URL_SERVING
  const pictureUrl = () => {
    if (widgetState?.picture || userData?.findUserById?.thumbnail) {
      return `${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${
        widgetState?.picture ?? userData?.findUserById?.thumbnail
      }.jpeg`;
    }
    return null;
  };
  const justifyPosition = () => {
    switch (widgetState?.position) {
      case "bottom-right":
        return "flex-end";
      default:
        return "flex-start";
    }
  };

  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("emailBadge") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0, ...bodyTagObject }}>
        <DisplayDiv
          right
          isMobile={isMobile}
          preview
          // style={{ padding: "32px" }}
        >
          <>
            <DisplayDivTitle
              isMobile={isMobile}
              style={{ position: "relative", zIndex: 9999 }}
            >
              <div>Preview</div>
              {isMobile && (
                <div>
                  <img src={CloseIcon} onClick={() => setModalOpen(false)} />
                </div>
              )}
            </DisplayDivTitle>
            <EmailBadgeDiv>
              <div style={{ width: "100%", maxWidth: "550px" }}>
                <table
                  style={{
                    width: "100%",
                    maxWidth: "550px",
                    borderCollapse: "collapse",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          padding: 0,
                          margin: 0,
                          verticalAlign: "top",
                          width: "70px",
                          border: "0px",
                        }}
                      >
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  margin: 0,
                                  paddingTop: "0px",
                                  paddingBottom: "0px",
                                  paddingLeft: "0px",
                                  paddingRight: "19px",
                                  textAlign: "left",
                                  border: 0,
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    width: 57,
                                    height: 57,
                                    border: 0,
                                  }}
                                  src={pictureUrl()}
                                  alt=""
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{
                          padding: 0,
                          margin: 0,
                          verticalAlign: "top",
                          border: 0,
                        }}
                      >
                        <table
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <tbody>
                            <tr
                              style={{
                                display: widgetState?.text1 ? "block" : "none",
                              }}
                            >
                              <td
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  paddingTop: "0px",
                                  paddingBottom: "4px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  border: "0px",
                                }}
                              >
                                <p
                                  style={{
                                    lineHeight: 1.2,
                                    margin: 0,
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontStyle: "normal",
                                    textDecoration: "none",
                                    fontFamily: "Helvetica",
                                    fontSize: "14px",
                                    color: "#1a1b24",
                                  }}
                                >
                                  {" "}
                                  {widgetState?.text1}
                                </p>
                              </td>
                            </tr>
                            <tr
                              style={{
                                display: widgetState?.text2 ? "block" : "none",
                              }}
                            >
                              <td
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  paddingTop: "4px",
                                  paddingBottom: "4px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  border: "0px",
                                }}
                              >
                                <p
                                  style={{
                                    lineHeight: 1.2,
                                    margin: 0,
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    textDecoration: "none",
                                    fontFamily: "Helvetica",
                                    fontSize: "14px",
                                    color: "#858995",
                                  }}
                                >
                                  {" "}
                                  {widgetState?.text2}{" "}
                                </p>
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "center" }}>
                              <td
                                style={{
                                  margin: 0,
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  border: "0px",
                                }}
                              >
                                <table
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    maxWidth: "550px",
                                  }}
                                >
                                  <tr
                                    style={{
                                      verticalAlign: "middle",
                                      display: widgetState?.email
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: "5px",
                                        textAlign: "center",
                                        border: "0px",
                                        width: "25px",
                                        lineHeight: 1.2,
                                      }}
                                    >
                                      <img
                                        src="https://asset.emailbadge.com/customize-image?icon=envelope&amp,color=%23858995&amp,size=md"
                                        width="15"
                                        alt="envelope"
                                      />{" "}
                                    </td>
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize: "14px",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        fontStyle: "normal",
                                        textDecoration: "none",
                                        fontFamily: "Helvetica",
                                        color: "#858995",
                                        border: "0px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          textAlign: "left",
                                          lineHeight: 1.2,
                                          color: "#858995",
                                        }}
                                      >
                                        {" "}
                                        {widgetState?.email}
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr
                              style={{
                                verticalAlign: "center",
                                display: widgetState?.telephone
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <td
                                style={{
                                  margin: 0,
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  border: "0px",
                                }}
                              >
                                <table
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    maxWidth: "550px",
                                  }}
                                >
                                  <tr
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: "5px",
                                        textAlign: "center",
                                        border: "0px",
                                        width: "25px",
                                        lineHeight: 1.2,
                                      }}
                                    >
                                      <img
                                        src="https://asset.emailbadge.com/customize-image?icon=phone&amp,color=%23858995&amp,size=md"
                                        width="15"
                                        alt="phone"
                                      />
                                    </td>
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize: "14px",
                                        textAlign: "left",
                                        fontWeight: "normal",
                                        fontStyle: "normal",
                                        textDecoration: "none",
                                        fontFamily: "Helvetica",
                                        color: "#858995",
                                        border: "0px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: 0,
                                          padding: 0,
                                          textAlign: "left",
                                          lineHeight: 1.2,
                                          color: "#858995",
                                        }}
                                      >
                                        {" "}
                                        {widgetState?.telephone}
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  textAlign: "left",
                                  border: "0px",
                                }}
                              >
                                <table
                                  style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    margin: 0,
                                  }}
                                >
                                  <tr>
                                    {socialLinkArray.map((el) =>
                                      widgetState?.socialLinks &&
                                      widgetState?.socialLinks[el.type] ? (
                                        <td
                                          style={{
                                            border: "0px",
                                            width: "14px",
                                            textAlign: "left",
                                            paddingRight: "14px",
                                          }}
                                        >
                                          <a
                                            href={
                                              widgetState?.socialLinks[
                                                el.type
                                              ] ?? ""
                                            }
                                            style={{ textDecoration: "none" }}
                                          >
                                            <img
                                              src={el.url}
                                              width="20"
                                              alt={el?.type}
                                            />
                                          </a>
                                        </td>
                                      ) : null
                                    )}

                                    <td
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        border: "0px",
                                      }}
                                    ></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr
                              style={{
                                display: widgetState?.text3 ? "block" : "none",
                              }}
                            >
                              <td
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  paddingTop: "4px",
                                  paddingBottom: "0px",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                  border: "0px",
                                }}
                              >
                                <p
                                  style={{
                                    lineHeight: 1.2,
                                    margin: 0,
                                    textAlign: "left",
                                    fontWeight: "normal",
                                    fontStyle: "normal",
                                    textDecoration: "none",
                                    fontFamily: "Helvetica",
                                    fontSize: "14px",
                                    color: "#858995",
                                  }}
                                >
                                  {widgetState?.text3}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table>
                          <tr>
                            {widgetState?.testimonialVideos instanceof Array &&
                              widgetState?.testimonialVideos.map((el) => {
                                let videoItem = approvedVideos.find(
                                  (elm) => elm.videoId === el
                                );
                                if (!videoItem) return null;
                                return (
                                  <td align="left">
                                    <UserDetailsImage
                                      style={{ width: 70, height: 80.99 }}
                                    >
                                      <VideoComp
                                        style={{ width: 70, height: 80.99 }}
                                        item={videoItem}
                                        specialIcon
                                      />
                                    </UserDetailsImage>
                                  </td>
                                );
                              })}
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </EmailBadgeDiv>
          </>
        </DisplayDiv>
      </Grid>
    )
  );
};
