import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";
import VideoModal from "../VideoModal";
import SearchSvg from "../../assets/icons/Search2Icon.svg";
import VideoSvg from "../../assets/images/video.svg";
import EmptySearch from "../../assets/images/EmptySearch.svg";
import {
  BottomName,
  Container,
  NameContainer,
  PicContainer,
  SearchBox,
  Title,
  TopName,
} from "./styled";
import { Box } from "@mui/material";
import {
  BannerBox,
  BannerBoxText,
  BannerBoxUrl,
} from "../../pages/UserDashboard/styled";
import { useDispatch } from "react-redux";
import { setMenu } from "../../redux/menu.slice";

export default function SearchList({
  arrList = [],
  addToKey = "L",
  setOpen,
  setVideoToPlay,
  recentArrList = [],
  searchInput,
  setSearchInput,
  loading,
  isMobile,
  setClickedMenu,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rState, setRState] = useState({});

  const linkToSpecificBrand = async (item) => {
    // console.log(item, "sitem");
    const {
      title: name,
      brandId,
      _id: id,
      access: access,
      owned: owned,
    } = item;
    dispatch(setMenu({ name, brandId, id, access, owned }));
    navigate(item?.link);
  };

  const clickFuntion = (item) => {
    switch (item.type) {
      case "recent":
        setSearchInput(item.title);
        return;
      case "brand":
        item?.link && linkToSpecificBrand(item);
        return;

      case "video":
        if (item?.link) {
          setOpen(true);
          setVideoToPlay(item?.link);
          if (isMobile) {
            setClickedMenu();
          }
        }
        return;
      default:
        return;
    }
  };

  const getImage = (item) => {
    switch (item.type) {
      case "recent":
        return <img src={SearchSvg} style={{ marginTop: 8 }} />;
      case "brand":
        return (
          <>
            {item.imageUrl ? (
              <BannerBoxUrl
                style={{ width: "100%", height: "100%" }}
                background={item.imageUrl}
              ></BannerBoxUrl>
            ) : (
              <BannerBox
                style={{ width: "100%", height: "100%" }}
                background={item.brandColor}
              >
                <BannerBoxText>{item.title.charAt(0) || ""}</BannerBoxText>
              </BannerBox>
            )}
          </>
        );
      case "video":

      default:
        return <img src={VideoSvg} style={{ height: 35 }} />;
    }
  };

  let category;

  // console.log(arrList, "arrrrlist");

  const renderItem = (item) => (
    <Container onClick={() => clickFuntion(item)}>
      <PicContainer vid={item.type === "video"} rnd={item.type === "member"}>
        {getImage(item)}
      </PicContainer>
      <NameContainer>
        <TopName>{item.title}</TopName>
        {item.type !== "recent" && <BottomName>{item.subTitle}</BottomName>}
      </NameContainer>
    </Container>
  );

  const videosArr = arrList.filter((el) => el?.type === "video");
  const brandsArr = arrList.filter((el) => el?.type === "brand");
  const membersArr = arrList.filter((el) => el?.type === "member");

  let groupedArr = [
    { title: "Videos", arr: videosArr },
    { title: "Brands", arr: brandsArr },
    { title: "Members", arr: membersArr },
  ];

  const renderSearchedComponent = () => {
    let result = [];
    let i = 0;

    for (let elArr of groupedArr) {
      result.push(
        !elArr.arr || elArr.arr.length === 0 ? null : (
          <>
            <Title divider={i > 0}>{elArr.title}</Title>
            {elArr.arr.map((el) => renderItem(el))}
          </>
        )
      );
      if (elArr.arr && elArr.arr.length > 0) ++i;
    }
    return result;
  };

  const renderRecentComponent = () => {
    if (recentArrList.length === 0) return null;
    let result = [];
    result.push(<Title divider={false}>Recent</Title>);
    const processedTitles = new Set();
    const filteredResults = recentArrList.filter((item) => {
      const searchItem = item.search[0]; // Assuming search is an array with one element

      if (!searchItem) {
        return true; // Skip items with no search data
      }

      const title = searchItem.title;
      const subTitle = searchItem.subTitle;

      if ((title || subTitle) && !processedTitles.has(`${title}-${subTitle}`)) {
        processedTitles.add(`${title}-${subTitle}`);
        return true; // Include the item if it has a title, subTitle, and combination hasn't been processed yet
      }

      return false; // Skip items with duplicate titles
    });

    // console.log("filteredResults",filteredResults);

    // console.log(recentArrList);

    for (let elArr of filteredResults) {
      if (elArr.search instanceof Array) {
        let elem = {};
        elem.type = "recent";
        elem.title = elArr.inputValue;
        // console.log("elem", elem);
        if (elArr.search.length === 1) {
          result.push(<>{renderItem(elArr.search[0])}</>);
        } else {
          result.push(<>{renderItem(elem)}</>);
        }
      }
    }

    return result;
  };

  const renderComponent = () => {
    return searchInput && searchInput.trim().length > 0
      ? renderSearchedComponent()
      : renderRecentComponent();
  };

  return (
    <>
      {(arrList instanceof Array && arrList.length > 0) ||
      (!(searchInput && searchInput.trim().length > 0) &&
        recentArrList.length > 0) ? (
        <>{renderComponent()}</>
      ) : (
        <>
          {!(arrList instanceof Array && arrList.length > 0) &&
            searchInput &&
            searchInput.trim().length > 0 &&
            !loading && (
              <SearchBox>
                <img src={EmptySearch} alt="" />
              </SearchBox>
            )}
        </>
      )}
    </>
  );
}
