import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  CardContent,
  Label,
  ProductImgStyle,
  WebsiteCardStyle,
} from "./styled";
import { CreateButton } from "../../pages/WebsiteBuilder/styled";
import coverImg from "../../assets/images/default-web.jpg";
import { UPDATE_PAGE } from "../../graphql/mutations/pageBuilder";
import { useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";

const Link = styled(RouterLink)`
  /* Add your link styles here */
`;

// Define the ShopProductCard component
const WebsiteCard = ({ website }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatePage] = useMutation(UPDATE_PAGE);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { name, _id, slug, isPublished } = website;

  const handlePublishToggle = async () => {
    try {
      let { data: dataUpdate, errors } = await updatePage({
        variables: {
          input: {
            isPublished: !isPublished,
            _id,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error updating publish`,
          {
            variant: "error",
          }
        );
        return;
      }
      enqueueSnackbar(
        `${
          dataUpdate?.updatePage?.isPublished ? "Published" : "Unpublished"
        } Page`,
        {
          variant: "success",
        }
      );
    } catch (e) {
      enqueueSnackbar(`Publish Update failed ${e}`, {
        variant: "error",
      });
    }
  };

  return (
    <WebsiteCardStyle>
      <ProductImgStyle alt={name} src={coverImg} />
      <CardContent>
        <div className="top">
          <div className="top_intro">
            <Label status={isPublished ? "published" : "draft"}>
              {isPublished ? "Published" : "Draft"}
            </Label>
          </div>

          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              color="white"
              size="large"
            >
              <MoreVertIcon
                sx={{
                  fill: "white",
                }}
              />
            </IconButton>
            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* const options = ["View", "publish"]; */}
              <MenuItem>
                <a
                  target="_blank"
                  href={`/page/${slug}`}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  View
                </a>
                {/* <Link style={{textDecoration:"none", color:"#000"}} to={"/page/" + slug}>View</Link> */}
              </MenuItem>
              <MenuItem onClick={handlePublishToggle}>
                {isPublished ? "Draft" : "Publish"}
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className="bottom">
          <div>
            <Typography component="p" sx={{ mb: 2 }} color={"white"}>
              {name}
            </Typography>
          </div>
          <Link to={"/page-builder/" + slug}>
            <CreateButton sx={{ width: "100%" }}>Manage</CreateButton>
          </Link>
        </div>
      </CardContent>
    </WebsiteCardStyle>
  );
};

export default WebsiteCard;
