import { gql } from "@apollo/client";

export const FETCH_VERIFIED_EMAILS = gql`
  query {
    fetchVerifiedEmails {
      email
      createdAt
    }
  }
`;
