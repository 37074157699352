import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  AnalyticsBoxHeader,
  AnalyticsBoxSummmary,
  AnalyticsContainer,
  AnalyticsContainerTwo,
  AnanlyticsBoxBadge,
  AnanlyticsBoxBadgeText,
  AnanlyticsBoxDiagram,
  AnanlyticsBoxNumbers,
  AnanlyticsBoxNumbersText,
  AnanlyticsBoxNumbersTextDate,
  MobileCarouselDiv,
} from "../pages/UserDashboard/styled";
import VectorOne from "../assets/icons/VectorOne.svg";
import VectorTwo from "../assets/icons/VectorTwo.svg";
import VectorThree from "../assets/icons/VectorThree.svg";
import VectorFour from "../assets/icons/VectoeFour.svg";
import Duration from "../assets/icons/Duration.svg";
import Impression from "../assets/icons/Impression.svg";
import AverageRating from "../assets/icons/AverageRating.svg";
import NewVideos from "../assets/icons/NewVideos.svg";
import Engagements from "../assets/icons/Engagements.svg";
import PageVisited from "../assets/icons/PageVisited.svg";
import AverageTime from "../assets/icons/AverageTime.svg";
import PercentageUp from "../assets/icons/PercentageUp.svg";
import { useQuery } from "@apollo/client";
import {
  APPROVED_VIDEOS_DATE_COUNT,
  DISAPPROVED_VIDEOS_DATE_COUNT,
  GET_VIDEOS_DATE,
  NEW_VIDEOS_COUNT,
  NEW_VIDEOS_DAYS_COUNT,
  RATING_AVERAGE,
  RATING_DAYS_COUNT,
} from "../graphql/queries/videosQueries";
import Spinner from "./Spinner";
import RatingChart from "./RatingChart";
import { useSelector } from "react-redux";
import NewVideoChart from "./NewVideoChart";
import VideosWatchedChart from "./VideosWatchedChart";
import {
  GET_ANALYTICS_WATCHED,
  GET_TOTAL_ANALYTICSVIDEOS_WATCHED,
  VIDEOS_WATCHED_COUNT,
  VIDEOS_WATCHED_DAYS_COUNT,
  GET_ANALYTICS_PAGE_VISITED,
  GET_ANALYTICS_TIME_WEBSITE_SPENT,
  GET_ANALYTICS_ENGAGEMENT,
  GET_ANALYTICS_IMPRESSION,
} from "../graphql/queries/analytics";
import moment from "moment";
import DisapproveChart from "./DisapproveChart";
import ApproveChart from "./ApproveChart";
import dayjs from "dayjs";
import Info from "../assets/icons/Info.svg";
import DurationChart from "./DurationChart";
import PercentageBox from "./PercentageBox";
import NumberBox from "./NumberBox";
import NumberBoxDuration from "./NumberBoxDuration";
import NumberBoxRating from "./NumberBoxRating";
import { Link } from "react-router-dom";
import GenericChart from "./GenericChart";
import CustomTooltip from "./CustomTootip";
function SummaryAnalyticsTwo() {
  const menu = useSelector((state) => state.menu);
  const date = useSelector((state) => state.date);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  // const [startDate, setStartDate] = useState(moment().subtract(1, "day").format("YYYY-MM-DD"));
  // const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const numSelectedDays = dayjs(date.endDate).diff(date.startDate, "day") + 1;
  const [dateText, setDateText] = useState("From Yesterday");

  ///New Analytics
  const { data: engagementData } = useQuery(GET_ANALYTICS_ENGAGEMENT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: averageTimeData } = useQuery(GET_ANALYTICS_TIME_WEBSITE_SPENT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: pagesVisitedData } = useQuery(GET_ANALYTICS_PAGE_VISITED, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: impression_Data } = useQuery(GET_ANALYTICS_IMPRESSION, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  //////////

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      // dateText = "From Yesterday";
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);
  const {
    loading: ratingLoading,
    error: ratingError,
    data: ratingData,
  } = useQuery(RATING_AVERAGE, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: newLoading,
    error: newError,
    data: newData,
  } = useQuery(NEW_VIDEOS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: watchedLoading,
    error: watchedError,
    data: watchedData,
  } = useQuery(VIDEOS_WATCHED_COUNT, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: diasapprovedLoading,
    error: diasapprovedError,
    data: diasapprovedData,
  } = useQuery(DISAPPROVED_VIDEOS_DATE_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: approvedLoading,
    error: approvedError,
    data: approvedData,
  } = useQuery(APPROVED_VIDEOS_DATE_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: analyticsLoading,
    error: analyticsError,
    data: analyticsData,
  } = useQuery(GET_ANALYTICS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: videosLoading,
    error: videosError,
    data: videosData,
  } = useQuery(GET_VIDEOS_DATE, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: totalVideosLoading,
    error: totalVideosError,
    data: totalVideosData,
  } = useQuery(GET_TOTAL_ANALYTICSVIDEOS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: impressionLoading,
    error: impressionError,
    data: impressionData,
  } = useQuery(VIDEOS_WATCHED_DAYS_COUNT, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },

    fetchPolicy: "cache-and-network",
  });

  const {
    loading: ratingLoadingTwo,
    error: ratingErrorTwo,
    data: ratingDataTwo,
  } = useQuery(RATING_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  // console.log(ratingData?.ratingDaysVideoCount);

  const {
    loading: newLoadingTwo,
    error: newErrorTwo,
    data: newDataTwo,
  } = useQuery(NEW_VIDEOS_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  // console.log(newData?.newVideoCount);

  // if (newLoading) return <Spinner />;
  // if (newError) return <p>Something Went Wrong</p>;
  // if (analyticsLoading || analyticsError) {
  //   return <><Spinner/></>;
  // }

  //   if (loading) return <Spinner />;
  //   if (error) return <p>Something Went Wrong</p>;
  const num = ratingData?.getAverageRating;

  const roundedNum = (Math.round(num * 100) / 100).toFixed(2); // "3.44"
  // const finalNum = parseFloat(roundedNum).toFixed(1); // "3.46"
  const finalNum = ratingData ? parseFloat(roundedNum).toFixed(1) : 0; // "3.46"
  // total duration calculation

  // Assuming you have the data stored in a variable called 'data'
  const analyticsTotalData = totalVideosData?.analyticsDaysVideoDuration || [];
  const totalVideosDuration = analyticsTotalData.reduce(
    (sum, item) => sum + item.totalDuration,
    0
  );
  const formattedTotalOverallDuration = totalVideosData
    ? Math.round(totalVideosDuration)
    : 0;
  let durationStringOverall = "";

  if (formattedTotalOverallDuration === 0) {
    durationStringOverall = `0`;
  } else if (formattedTotalOverallDuration < 60) {
    durationStringOverall = `${formattedTotalOverallDuration} secs`;
  } else if (formattedTotalOverallDuration < 3600) {
    const minutes = Math.floor(formattedTotalOverallDuration / 60);
    const seconds = formattedTotalOverallDuration % 60;
    durationStringOverall = `${minutes} mins ${seconds} secs`;
  } else {
    const hours = Math.floor(formattedTotalOverallDuration / 3600);
    const minutes = Math.floor((formattedTotalOverallDuration % 3600) / 60);
    const seconds = formattedTotalOverallDuration % 60;
    durationStringOverall = `${hours} hr ${minutes} mins `;
  }

  let GridDiv = isMobile ? MobileCarouselDiv : Grid;

  return (
    <>
      {" "}
      <AnalyticsContainerTwo sx={{ flexGrow: 1 }}>
        <GridDiv
          container
          spacing={2}
          // sx={{ flexWrap: !isMobile ? "nowrap" : undefined }}
        >
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/duration`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={Duration}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    />
                  </div>

                  <AnalyticsBoxHeader isMobile={isMobile}>
                    Duration
                  </AnalyticsBoxHeader>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBoxDuration
                      durationStringOverall={durationStringOverall || 0}
                      duration={formattedTotalOverallDuration}
                    />
                  </AnanlyticsBoxNumbersText>

                  <AnanlyticsBoxDiagram>
                    <DurationChart
                      id={menu.brandId}
                      startDate={date.startDate}
                      endDate={date.endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      Math.round(
                        totalVideosData?.analyticsDaysVideoDuration[0]
                          .percentChange
                      ) || 0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          {/* Old Impression */}
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/impression`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Impressions"
                  bodyText="Impressions represent video views, counted each time a user clicks or watches."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Impression}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Impressions
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBox
                      count={watchedData?.analyticsWatchedCount.count || 0}
                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <VideosWatchedChart
                      id={menu.brandId}
                      startDate={date.startDate}
                      endDate={date.endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      impressionData?.analyticsDaysVideoCount[0]
                        .percentChange || 0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}
          {/* New Impression */}
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/impression`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Impressions"
                  bodyText="Impressions represent video views, counted each time a user clicks or watches."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Impression}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Impressions
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBox
                      count={
                        impression_Data?.analyticsDaysImpression?.total || 0
                      }
                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={
                        impression_Data?.analyticsDaysImpression?.results
                      }
                      item="count"
                      color="#8D6CFF"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      impression_Data?.analyticsDaysImpression?.percentChange ||
                      0
                    }
                  />
                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          {/* New Impression ... End */}
          {/* <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/new`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={NewVideos}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    ></img>
                  </div>

                  <AnalyticsBoxHeader isMobile={isMobile}>
                    New Videos
                  </AnalyticsBoxHeader>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBox
                      count={newData?.newVideoCount.count || 0}
                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <NewVideoChart
                      id={menu.id}
                      startDate={date.startDate}
                      endDate={date.endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>
                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={newDataTwo?.newDaysVideoCount[0].percentChange || 0}
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/rating`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                    <img
                      src={AverageRating}
                      style={{ maxWidth: !isMobile ? "100%" : undefined }}
                    ></img>
                  </div>

                  <AnalyticsBoxHeader isMobile={isMobile}>
                    Average Rating
                  </AnalyticsBoxHeader>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBoxRating count={finalNum || 0} duration={4000} />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <RatingChart
                      id={menu.id}
                      startDate={date.startDate}
                      endDate={date.endDate}
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      ratingDataTwo?.ratingDaysVideoCount[0].percentChange || 0
                    }
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid> */}

          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/engagement`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Engagement"
                  bodyText="Engagement measures the daily website traffic and assesses the extent of interaction with the widget."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={Engagements}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Engagement
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    {/* <NumberBox
                      count={
                        engagementData?.analyticsDaysEngagement?.average || 0
                      }
                      duration={4000}
                    /> */}
                    {`${
                      engagementData?.analyticsDaysEngagement
                        ?.totalWidgetTraffic || 0
                    }/${
                      engagementData?.analyticsDaysEngagement
                        ?.totalWebsiteTraffic || 0
                    }`}
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={engagementData?.analyticsDaysEngagement?.results}
                      item="dailyTraffic"
                      color="#BF6DFF"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      engagementData?.analyticsDaysEngagement?.percentChange ||
                      0
                    }
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/averageTime`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Average Time"
                  bodyText="Average Time signifies the typical duration visitors spend on the website."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={AverageTime}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Average Time
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBoxDuration
                      durationStringOverall={
                        averageTimeData?.analyticTimeWebSiteSpent?.total || 0
                      }
                      duration={
                        averageTimeData?.analyticTimeWebSiteSpent?.total
                          ? Math.round(
                              averageTimeData?.analyticTimeWebSiteSpent?.total
                            )
                          : 0
                      }
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={
                        averageTimeData?.analyticTimeWebSiteSpent?.results
                      }
                      item="totalDuration"
                      color="#5ACEC7"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      averageTimeData?.analyticTimeWebSiteSpent
                        ?.percentChange || 0
                    }
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              minWidth: !isMobile ? "25%" : "250px",
              width: !isMobile ? "50%" : undefined,
            }}
          >
            <Link
              to={`/brands/${menu.brandId}/0/pageVisited`}
              style={{ textDecoration: "none" }}
            >
              <AnalyticsBoxSummmary isMobile={isMobile}>
                <CustomTooltip
                  headerText="Pages Visited"
                  bodyText="Pages Visited indicates the average number of pages viewed by visitors."
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: !isMobile ? "100%" : undefined }}>
                      <img
                        src={PageVisited}
                        style={{ maxWidth: !isMobile ? "100%" : undefined }}
                      ></img>
                    </div>

                    <AnalyticsBoxHeader isMobile={isMobile}>
                      Pages Visited
                    </AnalyticsBoxHeader>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      <img src={Info} alt="info" />
                    </div>
                  </Box>
                </CustomTooltip>
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "100%" }}
                >
                  <AnanlyticsBoxNumbersText>
                    <NumberBox
                      count={
                        pagesVisitedData?.analyticsNumberofVisitedPages
                          ?.total || 0
                      }
                      // count={pagesVisitedData?.analyticsNumberofVisitedPages
                      //   ?.average  || 0}

                      duration={4000}
                    />
                  </AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxDiagram>
                    <GenericChart
                      genData={
                        pagesVisitedData?.analyticsNumberofVisitedPages?.results
                      }
                      item="avgPages"
                      color="#FAC60F"
                    />
                  </AnanlyticsBoxDiagram>
                </Box>

                <AnanlyticsBoxNumbers>
                  <PercentageBox
                    number={
                      pagesVisitedData?.analyticsNumberofVisitedPages
                        ?.percentChange || 0
                    }
                  />

                  <AnanlyticsBoxNumbersTextDate>
                    {dateText}
                  </AnanlyticsBoxNumbersTextDate>
                </AnanlyticsBoxNumbers>
              </AnalyticsBoxSummmary>
            </Link>
          </Grid>
          {/* <Grid item xs={6} md={3}>
           
            <AnalyticsBoxSummmary>
              <AnalyticsBoxHeader>Published</AnalyticsBoxHeader>
              <AnanlyticsBoxDiagram>
                <ApproveChart  id={menu.id} startDate={date.startDate} endDate={date.endDate}/>
              </AnanlyticsBoxDiagram>

              <AnanlyticsBoxNumbers>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AnanlyticsBoxNumbersText>{approvedData?.approvedVideoDateCount.count}</AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxBadge>{approvedData?.approvedVideoDateCount.percentChange}%+</AnanlyticsBoxBadge>
                </Box>
                <AnanlyticsBoxNumbersTextDate>
                  {dateText}
                </AnanlyticsBoxNumbersTextDate>
              </AnanlyticsBoxNumbers>
            </AnalyticsBoxSummmary>   
          </Grid>
          <Grid item xs={6} md={3}>
          <AnalyticsBoxSummmary>
              <AnalyticsBoxHeader>Unpublished</AnalyticsBoxHeader>
              <AnanlyticsBoxDiagram>
               <DisapproveChart id={menu.id} startDate={date.startDate} endDate={date.endDate}/>
              </AnanlyticsBoxDiagram>

              <AnanlyticsBoxNumbers>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AnanlyticsBoxNumbersText>{diasapprovedData?.disapprovedDateVideoCount.count}</AnanlyticsBoxNumbersText>
                  <AnanlyticsBoxBadge>{diasapprovedData?.disapprovedDateVideoCount.count}%+</AnanlyticsBoxBadge>
                </Box>
                <AnanlyticsBoxNumbersTextDate>
                {dateText}
                </AnanlyticsBoxNumbersTextDate>
              </AnanlyticsBoxNumbers>
            </AnalyticsBoxSummmary>
          </Grid> */}
        </GridDiv>
      </AnalyticsContainerTwo>
    </>
  );
}

export default SummaryAnalyticsTwo;
