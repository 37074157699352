import { uploadToAssetManager } from "./builderUtils";
import blocks_basic_plugin from "grapesjs-blocks-basic";
import gjsForms from "grapesjs-plugin-forms";
import grapesjs from "grapesjs";
import { cmdImport, importSetup } from "./import-preset";
import {
  addCustomFontsToEditor,
  updateFontFamilyList,
} from "../../utils/pageStore";

const grapesjsSetup = (
  setEditor,
  productRef,

  updateAssets,
  setAssets,
  setOpen
) => {
  //initiate grapejs
  const editor = grapesjs.init({
    container: "#editor",
    fromElement: true,
    width: "auto",
    height: "calc(100vh - 60px)",
    storageManager: false,
    colorPicker: { appendTo: "parent", offset: { top: 26, left: -170 } },
    assetManager: {
      storageType: "",
      storeOnChange: true,
      storeAfterUpload: true,
      //   upload: "https://localhost/assets/upload", //for temporary storage
      assets: [],
      uploadFile: function (e) {
        uploadToAssetManager(editor, e, (param) => {
          setAssets((assets) => [
            ...assets,
            { name: param.Key, extension: param.Key.split(".")[1] },
          ]);
          updateAssets(param);
        });
      },
    },
    plugins: [
      (editor) => blocks_basic_plugin(editor, { flexGrid: true }),
      (editor) => gjsForms(editor),
    ],
    layerManager: {
      appendTo: "#layers-container",
    },
    selectorManager: {
      appendTo: "#styles-container",
    },
    blockManager: {
      appendTo: "#blocks",
    },
    styleManager: {
      appendTo: "#styles-container",
      // sectors: [
      //   {
      //     name: 'General',
      //     open: false,
      //     properties: ['display', 'float', 'position', 'top', 'right', 'left', 'bottom'],
      //   },
      //   {
      //     name: 'Flex',
      //     open: false,
      //     properties: [
      //       'flex-direction',
      //       'flex-wrap',
      //       'justify-content',
      //       'align-items',
      //       'align-content',
      //       'order',
      //       'flex-basis',
      //       'flex-grow',
      //       'flex-shrink',
      //       'align-self',
      //     ],
      //   },
      //   {
      //     name: 'Dimension',
      //     open: false,
      //     properties: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
      //   },
      //   {
      //     name: 'Typography',
      //     open: false,
      //     buildProps: ['font-size', 'font-weight', 'letter-spacing', 'color'],
      //     properties: [
      //       {
      //         property: 'font-family',
      //         label: 'Font Family',
      //         type: 'select',
      //         list: [
      //           { label: 'Arial', id: 'Arial, Helvetica, sans-serif' },
      //           { label: 'Courier New', id: "'Courier New', Courier, monospace" },
      //           { label: 'Georgia', id: 'Georgia, serif' },
      //           { label: 'Roboto', id: "'Roboto', sans-serif" },
      //           { label: 'Open Sans', id: "'Open Sans', sans-serif" },
      //           { label: 'Patron', id: 'Patron, sans-serif' }, // Custom Font
      //           { label: 'Sagona', id: 'Sagona, serif' }, // Custom Font
      //           // Add more custom fonts here
      //         ],
      //       },
      //       {
      //         property: 'font-size',
      //         name: 'Size',
      //         defaults: '12px',
      //         min: 1,
      //       },
      //       {
      //         property: 'font-weight',
      //         name: 'Weight',
      //       },
      //       {
      //         property: 'letter-spacing',
      //         name: 'Letter spacing',
      //       },
      //       {
      //         property: 'color',
      //         name: 'Color',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Decorations',
      //     open: false,
      //     properties: ['background-color', 'border-radius', 'border', 'box-shadow', 'background'],
      //   },
      //   {
      //     name: 'Extra',
      //     open: false,
      //     properties: ['opacity', 'transition', 'transform'],
      //   },
      // ],
    },
    traitManager: {
      appendTo: "#trait-container",
    },
    panels: {
      defaults: [
        {
          id: "basic-actions",
          el: ".panel__basic-actions",
          buttons: [
            {
              id: "visibility",
              active: true, // active by default
              className: "btn-toggle-borders",
              label: '<i class="fa fa-clone"></i>',
              command: "sw-visibility", // Built-in command
            },
          ],
        },
        {
          id: "panel-devices",
          el: ".panel__devices",
          buttons: [
            {
              id: "device-desktop",
              className: "fa fa-television",
              command: "set-device-desktop",
              active: true,
              togglable: true,
            },
            {
              id: "device-tablet",
              className: "fa fa-tablet",
              command: "set-device-tablet",
              togglable: true,
            },
            {
              id: "device-mobile",
              className: "fa fa-mobile",
              command: "set-device-mobile",
              togglable: true,
            },
            {
              id: "export-template",
              className: "fa fa-code",
              command: "export-template",
              attributes: { title: "View code" },
            },
            {
              id: cmdImport,
              command: () => editor.runCommand(cmdImport),
              label: `<svg style="display: block; max-width:22px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
              </svg>`,
            },
          ],
        },
      ],
    },
    deviceManager: {
      devices: [
        {
          name: "Desktop",
          width: "1920px",
        },
        {
          id: "tablet",
          name: "Tablet",
          width: "770px",
          widthMedia: "992px",
        },
        {
          name: "Mobile",
          width: "320px",
          widthMedia: "480px",
        },
      ],
    },
  });

  editor.Blocks.add("cart-block", {
    select: true,
    category: "Basic",
    label: "Cart Icon",
    media: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="176" cy="416" r="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="400" cy="416" r="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M48 80h64l48 272h256"/><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128"/></svg>

  `,
    content: `<div style="width:fit-content;height:fit-content"><svg class="cart-icon" stroke="currentColor" fill="currentColor" height="30" width="30" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="176" cy="416" r="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="400" cy="416" r="16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M48 80h64l48 272h256"/><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M160 288h249.44a8 8 0 0 0 7.85-6.43l28.8-144a8 8 0 0 0-7.85-9.57H128"/></svg>
    </div>`,
  });

  editor.Blocks.add("cart-button", {
    select: true,
    category: "Basic",
    label: "Cart Button Wrapper",
    media: `<svg width="200" height="100" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" fill="grey" />
  </svg>`,
    content: `<button class="add-to-cart-btn">GET</button>`,
  });

  editor.Components.addType("button", {
    isComponent: (el) => el?.classList?.contains("add-to-cart-btn"),
    model: {
      defaults: {
        spBut: "button",
        tagName: "button",
        attributes: { type: "button" },
        text: "Get",
        traits: [
          "id",
          {
            name: "text",
            changeProp: true,
          },
          {
            type: "text",
            name: "data-cart_target",
            label: "Cart Target ID",
            placeholder: "Insert Text",
          },

          {
            type: "select", // Type of the trait
            name: "data-product", // (required) The name of the attribute/property to use on component
            label: "Product", // The label you will see in Settings

            options:
              productRef.current instanceof Array ? productRef.current : [],
          },
        ],
      },
      init() {
        const comps = this.components();
        const tChild = comps.length === 1 && comps.models[0];
        const chCnt =
          (tChild && tChild.is("textnode") && tChild.get("content")) || "";
        const text = chCnt || this.get("text");
        this.set("text", text);
        this.on("change:text", this.__onTextChange);
        text !== chCnt && this.__onTextChange();
      },

      __onTextChange() {
        this.components(this.get("text"));
      },
    },
  });

  editor.Components.addType("a", {
    isComponent: (el) => el?.classList?.contains("add-to-cart-btn"),
    model: {
      defaults: {
        spBut: "button",
        tagName: "button",
        attributes: { type: "button" },
        text: "Get",
        traits: [
          "id",
          {
            name: "text",
            changeProp: true,
          },
          {
            type: "text",
            name: "data-cart_target",
            label: "Cart Target ID",
            placeholder: "Insert Text",
          },

          {
            type: "select", // Type of the trait
            name: "data-product", // (required) The name of the attribute/property to use on component
            label: "Product", // The label you will see in Settings

            options:
              productRef.current instanceof Array ? productRef.current : [],
          },
        ],
      },
      init() {
        const comps = this.components();
        const tChild = comps.length === 1 && comps.models[0];
        const chCnt =
          (tChild && tChild.is("textnode") && tChild.get("content")) || "";
        const text = chCnt || this.get("text");
        this.set("text", text);
        this.on("change:text", this.__onTextChange);
        text !== chCnt && this.__onTextChange();
      },

      __onTextChange() {
        this.components(this.get("text"));
      },
    },
  });

  editor.on("component:selected", () => {
    const component = editor.getSelected(); // Component selected in canvas
    const trait = component.getTrait("data-product");
    if (trait) {
      trait.set("options", productRef.current ?? []);
    }
  });

  setEditor(editor);

  editor.on("load", () => {
    addCustomFontsToEditor(editor);
  });
  ////////////////////////////////
  // Commands

  editor.Commands.add("set-device-desktop", {
    run: (editor) => editor.setDevice("Desktop"),
  });
  editor.Commands.add("set-device-mobile", {
    run: (editor) => editor.setDevice("Mobile"),
  });
  editor.Commands.add("set-device-tablet", {
    run: (editor) => editor.setDevice("Tablet"),
  });

  editor.on("component:selected", () => {
    setOpen(true);
  });

  editor.on("style:sector:add", (sector) => {
    // console.log("sector list 1", { sector });
  });
  const styleManager = editor.StyleManager;
  const property = styleManager.getProperty("typography", "font-family");
  // console.log("this is type sector",);
  // console.log("this is type sector", property);
  updateFontFamilyList(editor);

  importSetup(editor, {
    modalImportTitle: "Import Template",
    modalImportLabel:
      '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
    modalImportContent: function (editor) {
      return editor.getHtml() + "<style>" + editor.getCss() + "</style>";
    },
    modalImportButton: "Import",
    useCustomTheme: false,
  });
};

export default grapesjsSetup;
