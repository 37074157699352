import React from 'react'
import Lottie from "react-lottie"
import animationData from "../../src/lotties/Loader Animation1.json"
export default function  Spinner() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    },
  };

return (
  <div style={{width:'100%', height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Lottie 
    options={defaultOptions}
      height={100}
      width={100}
    />
  </div>
);
}
