import * as React from "react";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Typography, Grid, Paper, Box } from "@mui/material";
import AuthPasswordBanner from "../../assets/images/AuthImageTwo-min.png";
import {
  FlexColumnContainer,
  StyledAuthButton,
  StyledFormLabelText,
  StyledHeaderText,
} from "./styled";
import { useForm } from "react-hook-form";
import AuthLayout from "../../components/AuthLayout";
import { FORGOT_PASSWORD } from "../../graphql/mutations/auth";
import Alert from "@mui/material/Alert";
import { useMutation } from "@apollo/client";
import {
  StyledAuthLogoContainer,
  StyledSpacedBannerHousingContainer,
} from "../../components/AuthLayout/styled";

import RecrowdlyLogo from "../../assets/images/recrowdly_logo.svg";
import LoginVideo from "../../assets/icons/loginVideo.mp4";
import { setWindowFlag } from "../../redux/responsive.slice";
import { useDispatch, useSelector } from "react-redux";
import { FlexRowLinksContainer, StyledLink } from "../Login/styled";
import { TabTitle } from "../../utils/HeaderTitle";

export default function ForgotPassword() {
  TabTitle(`Forgot your password`);
  const [sendEmail, setSendEmail] = React.useState("");
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const dispatch = useDispatch();
  const [forgotPassword, { data, error }] = useMutation(FORGOT_PASSWORD);
  const { handleSubmit, register } = useForm();
  const [successful, setSuccessful] = React.useState(false);

  const onSubmit = (data) => {
    setSendEmail(data.email);
    forgotPassword({
      variables: { input: { email: data.email } },
    });
  };

  useEffect(() => {
    if (data && data.forgotPassword) {
      setSuccessful(true);
    }
  }, [data, error]);

  useEffect(() => {
    const handleWindowResize = () => {
      // console.log(window.innerWidth);
      dispatch(setWindowFlag({ isMobile: window.innerWidth <= 450 }));
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <AuthLayout
      bannerImage={AuthPasswordBanner}
      headerBannerText="Our Idea is Simple"
      bannerText="Its free! Join us to get organics reviews from users worldwide"
    >
      {error && <Alert severity="error">{error?.message}</Alert>}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        component={Paper}
        elevation={0}
        square
        sx={{
          // display: "flex",
          // flexDirection: "column",
          //   justifyContent: "flex-start",
          // alignItems: "flex-start",

          height: "100vh",
          width: isMobile ? "100vw" : "75%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "90%" : "80%",
            margin: "0 auto",
            height: "10vh",
          }}
        >
          <StyledAuthLogoContainer>
            <img src={RecrowdlyLogo} alt="logo" />
          </StyledAuthLogoContainer>
        </Box>

        <FlexColumnContainer
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          isMobile={isMobile}
        >
          {error && (
            <Grid item xs={12} sm={12} md={12}>
              <Alert severity="error">{error.message}</Alert>
            </Grid>
          )}
          {successful ? (
            <Grid
              item
              xs={12}
              sm={8}
              md={12}
              sx={{ width: isMobile ? "90%" : "75%", margin: "0 auto" }}
            >
              <StyledHeaderText>Link Sent Successfully</StyledHeaderText>
              <Typography>
                Please check your email. We have sent you a password reset link
                at {sendEmail}
              </Typography>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={8}
              md={12}
              sx={{ width: isMobile ? "90%" : "75%", margin: "0 auto" }}
            >
              <StyledHeaderText>Forgot Password?</StyledHeaderText>
              <Typography>
                We will send you a password reset link on your email.
              </Typography>
              <FlexColumnContainer>
                <StyledFormLabelText>Email*</StyledFormLabelText>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label=""
                  placeholder="Enter your email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{
                    margin: "8px 0px 8px",
                    ".MuiInputBase-root": {
                      // height: "50px",
                      borderRadius: "15px",
                    },
                    ":hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1a1b24 !important",
                        borderWidth: "1px !important",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#007bf7",
                        borderWidth: "1px !important",
                        boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      },
                    },
                  }}
                  {...register("email", { required: true })}
                />

                <StyledAuthButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send reset link
                </StyledAuthButton>
              </FlexColumnContainer>
              <FlexRowLinksContainer sx={{ marginBottom: "10px" }}>
                <Typography>Remember your password?</Typography>
                <StyledLink href="/login" variant="body2">
                  Login
                </StyledLink>
              </FlexRowLinksContainer>
            </Grid>
          )}
        </FlexColumnContainer>
      </Grid>
      {!isMobile && (
        <Grid
          item
          container
          xs={false}
          sm={12}
          md={7}
          sx={{
            // display: "flex",
            // flexDirection: "column",
            //   justifyContent: "flex-start",
            // alignItems: "flex-start",
            height: "100%",
          }}
        >
          <StyledSpacedBannerHousingContainer>
            <video
              style={{
                width: "100%",
                height: "100vh",
                objectFit: "cover",
              }}
              autoPlay
              loop
              muted
            >
              <source src={LoginVideo} type="video/mp4" /> Your browser does not
              support the video tag.
            </video>
          </StyledSpacedBannerHousingContainer>
        </Grid>
      )}
    </AuthLayout>
  );
}
