import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

export const IntContainer = styled(Box)`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`;

export const IntContainerInner = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
`;

export const TimeContainer = styled(Box)`
  display: flex;
  max-width: 620px;
  align-items: center;
  gap: 17px;
  .time-input-container {
    flex: 1 1 0%;
  }
  label {
    font-family: "Dm Sans";
    display: block;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #101010;
  }
  .time-input {
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    border: none; /* Equivalent to border-0 */
    padding-top: 8px; /* Equivalent to py-1.5 */
    padding-bottom: 8px; /* Equivalent to py-1.5 */
    padding-left: 1.75rem; /* Equivalent to pl-7 */
    padding-right: 1.75rem; /* Equivalent to pr-20 */
    color: #000000; /* Equivalent to text-gray-900 */
    border-width: 1px; /* Equivalent to ring-1 */
    border-style: solid; /* Equivalent to ring-inset */
    border-color: #bbb9b9; /* Equivalent to ring-gray-300 */
    width: 100%;

    outline-width: 0px;
    outline-style: none;
    font-size: 24px;
    ::placeholder {
      color: #bbb9b9;
      font-size: 28px;
    }
    :focus {
      outline-width: 1px;
      outline-style: solid;
      outline-color: #000000;
    }
  }
`;

export const CreateButton = styled(Button)`
  height: 40px;
  ${({ color }) =>
    color === "primary"
      ? "background-color: #0c97d1;"
      : `background-color: white;`}
  border-radius: 5px;
  box-sizing: border-box;
  padding: 9px 24px;
  text-transform: capitalize;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${(props) => props.hasBorder && " border: #101010 .125rem solid;"}
  ${({ color }) =>
    color === "primary"
      ? "color: white;"
      : color === "dark"
      ? "color: white;"
      : `color: #101010;`}
  &:hover {
    ${({ color }) =>
      color === "primary"
        ? "background-color: #0c97d1;"
        : color === "dark"
        ? `background-color: #0c97d1;`
        : `background-color: white;`}
    ${({ color }) =>
      color === "primary"
        ? "color: white;"
        : color === "dark"
        ? "color: white;"
        : `color: #101010;`}
  }
`;

export const EmailContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  max-width: 620px;
  margin-bottom: 4px;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 8px;
`;

export const Tag = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${({ isInvalid }) => (isInvalid ? "#ffe6e6" : "#f0f0f0")};
  color: ${({ isInvalid }) => (isInvalid ? "#ff0000" : "#000")};
  padding: 5px 10px;
  border-radius: 4px;
  margin: 5px;
`;

export const RemoveButton = styled.span`
  margin-left: 8px;
  cursor: pointer;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  font-size: 14px;
  min-width: 150px;
`;
