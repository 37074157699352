import React from "react";
import styled from "styled-components";
import UpdatePayment from "../../components/UpdatePayment";
import Layout from "../../components/Layout";
import SubHeader from "../../components/SubHeader";

const StyledFrameScreen = styled.div`
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
    width: 100%;
`;

export default function UpdatePay() {
  return (
    <>
      <SubHeader
        noLinkToLast={false}
        btnTitle=""
        arrLink={[{ title: "Back", link: "/billingplans" }]}
      />
      {/* <StyledFrameScreen> */}
      <UpdatePayment />
      {/* </StyledFrameScreen> */}
    </>
  );
}
