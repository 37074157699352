import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ModalContent, ModalCust, Wrapper } from "./Main/SectionComp/styled";
import PlayVideo from "./Main/SectionComp/PlayVideo";
import CloseIcon from "../assets/icons/SectionClose.svg";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function VideoModal({
  open = false,
  setOpen,
  videoUrl,
  showOnlyPlay,
}) {
  //   const [open, setOpen] = React.useState(false);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let videoName,
    videoEmail,
    videoLink,
    videoObj = {};

  if (videoUrl && typeof videoUrl === "string" && videoUrl !== "") {
    let arr = videoUrl.split("****");

    videoLink =
      arr[0] && `${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${arr[0]}.mp4`;
    let name = arr[1];
    let email = arr[2];
    let position = arr[3];
    let rating = arr[4];
    videoObj = { name, email, position, rating };
  }

  return (
    <ModalCust open={open === true} modalFalse={true}>
      <ModalContent
        isMobile={isMobile}
        open={open === true && "True"}
        modalFalse={true}
      >
        <Wrapper>
          <img src={CloseIcon} alt="Close" onClick={handleClose} />
        </Wrapper>

        {open && (
          <PlayVideo
            url={videoLink}
            chgVideo={() => {}}
            setVideoPausedTime={() => {}}
            videoPausedStartTime={() => {}}
            videoPausedTime={() => {}}
            setVideoPausedStartTime={() => {}}
            setVideoPlayedStartTime={() => {}}
            item={videoObj}
            showOnlyPlay={showOnlyPlay}
          />
        )}
      </ModalContent>
    </ModalCust>
  );
}
