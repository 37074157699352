import styled from "styled-components";

const setPosition___ = (val = "") => {
  val = val.trim().toString();
  switch (val) {
    case "flex-start":
      return `left:0; top:0;`;
    case "flex-middle":
      return `left:50vw; top:0;`;
    case "flex-end":
    default:
      return `left:70vw; top:0;`;
  }
};

// const setPosition = (val) => {
//   val = typeof val === "string" ? val.trim().toString() : "";
//   switch (val) {
//     case "top-left":
//       return `justify-content:flex-start;align-items:flex-start;`;
//     case "top-center":
//       return `justify-content:center;align-items:flex-start;`;
//     case "top-right":
//       return `justify-content:flex-end;align-items:flex-start;`;
//     case "center-left":
//       return `justify-content:flex-start;align-items:center;`;
//     case "center-right":
//       return `justify-content:flex-end;align-items:center;`;
//     case "center-center":
//       return `justify-content:center;align-items:center;`;
//     case "bottom-center":
//       return `justify-content:center;align-items:flex-end;`;
//     case "bottom-right":
//       return `justify-content:flex-end;align-items:flex-end;`;
//     case "bottom-left":
//     default:
//       return `justify-content:flex-start;align-items:flex-end;`;
//   }
// };

const setPosition = (val) => {
  val = typeof val === "string" ? val.trim().toString() : "";
  switch (val) {
    case "top-left":
      return `top:50px;left:50px;`;
    case "top-center":
      return `top:50px;left:50%;`;
    case "top-right":
      return `top:50px;right:50px;`;
    case "center-left":
      return `top:50%;left:50px;`;
    case "center-right":
      return `top:50%;right:50px;`;
    case "center-center":
      return `top:50%;left:50%;`;
    case "bottom-center":
      return `bottom:50px;left:50%;`;
    case "bottom-right":
      return `bottom:50px;right:50px;`;
    case "bottom-left":
    default:
      return `bottom:50px;left:50px;`;
  }
};

export const RestFullWrapper = styled.div`
  // height: 100vh;
  // width: 100vw;
  position: fixed;
  // top: 0;
  // left: 0;
  // display: flex;
  // background: transparent;
  z-index: 9999999999;
  ${(props) => setPosition(props?.brandProps?.position)}
`;

export const RestWrapper = styled.div`
  width: ${(props) => props?.brandProps?.width ?? "150px"};
  height: ${(props) => props?.brandProps?.height ?? "150px"};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

export const ImageRest = styled.div`
  width: ${(props) => props?.brandProps?.width ?? "150px"};
  height: ${(props) => props?.brandProps?.height ?? "150px"};
  border-radius: 50%;
  // background-image: url(${(props) => props.backgroundImage});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props?.brandProps?.backgroundColor ?? "grey"};
  color: ${(props) => props?.brandProps?.color ?? "black"};
  border: ${(props) => props?.brandProps?.borderSize ?? "0"};
`;

export const LaunchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
`;

export const PlayButton = styled.button`
  color: ${(props) => props?.brandProps?.color ?? "black"};
`;

export const RestImage = styled.img`
  cursor: pointer;
  width: 40px;
`;
