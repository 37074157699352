import { gql } from "@apollo/client";
import { PAGE } from "../fragments/pageBuilder";
import { WORKFLOW } from "../fragments/workflow";

export const GET_ALL_WORKFLOWS = gql`
  ${WORKFLOW}
  query workflows($input: FetchAllWorkflowInput!) {
    workflows(input: $input) {
      ...WorkflowFields
    }
  }
`;

export const GET_SINGLE_WORKFLOW = gql`
  ${WORKFLOW}
  query workflow($id: String!) {
    workflow(id: $id) {
      ...WorkflowFields
    }
  }
`;