import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

export const FContainer = styled(Box)`
  width: 100vw;
  margin-top: -16px;
  margin-left: 16px;
  padding: 16px;
`;

export const TopTitle = styled(Box)`
  color: #000;
  text-align: center;
  font-family: Dm Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
`;

export const TopLink = styled(Box)`
  color: #0075ce;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
`;

export const ProfileDiv = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const NameDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

export const TopName = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 154%; /* 36.96px */
`;

export const BottomName = styled(Box)`
  color: #8c8d91;
  font-family: Dm Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ItemDiv = styled(Box)`
  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
`;

export const SubItemDiv = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SubItemDivText = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
