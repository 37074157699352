import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import PlusButton from "../../assets/icons/PlusButton.svg";
import MainArrow from "../../assets/icons/MainArrow.svg";
import SmallArrow from "../../assets/icons/SmallArrow.svg";
import Stars from "../../assets/icons/Stars.svg";
import UpdateCardComp from "../../components/UpdateCardComp";
import { IS_ON_FREE_TRIAL } from "../../graphql/queries/freeTrialQueries";
import calculateDaysLeft from "../../utils/calculateTrialDays";
import {
  StyledFrame,
  BillingFrame,
  BContainer,
  BCont1,
  BContH1,
  BCont1_1,
  BCont1_1_1,
  BCont1_1_1Text,
  BCont1_1_2,
  BCont1_1_2_1,
  BCont1_1_1Inner,
  BCont1_1_1Inner2,
  BCont1_1_1Inner2Text1,
  BCont1_1_1Inner2Text2,
  BCont1_1_1Inner1Text1,
  BCont2_1_1,
  BCont2_1_2,
  BCont2_1_2Text,
  BCont3,
  BCButton,
  BContSP,
  BContSP2,
} from "./styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "../../components/Table";
import Paper from "@mui/material/Paper";
import SubHeader from "../../components/SubHeader";
import ThreeStars from "../../assets/images/threeStars.svg";
import ColorButton from "../../components/ColorButton";
import PlusIcon from "../../assets/images/plus.svg";
import { Grid } from "@mui/material";
import { FETCH_PAYMENT_DETAILS } from "../../graphql/queries/payment";
import { CANCEL_SUBSCRIPTION } from "../../graphql/mutations/payment";
import { setUser } from "../../redux/auth.slice";
import { useSnackbar } from "notistack";
import { dateDifference } from "../../utils/numberFormat";

export default function BillingPlans() {
  // State variable to control the visibility of the UpdateCard component
  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const [focusState, setFocusState] = useState({});
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { subscription, status, amount } = useSelector(
    (state) => state.payment
  );
  const date = useSelector((state) => new Date(state.payment.date));

  // Hook to navigate programmatically with React Router
  const navigate = useNavigate();

  // Extract the user object from the Redux store
  const { user } = useSelector((state) => state.auth);

  const prices = {
    monthly: process.env.REACT_APP_MONTHLY_PRICE,
    yearly: process.env.REACT_APP_YEARLY_PRICE,
  };

  const { loading, error, data, refetch } = useQuery(FETCH_PAYMENT_DETAILS);
  // Calculate the number of days left based on the subscription type

  const calculateDays = () => {
    let expDate = user?.subExpDate;
    if (expDate) {
      let daysRemaining = Math.floor(
        (new Date(expDate).getTime() - new Date().getTime()) /
          (24 * 3600 * 1000)
      );

      let daysLeft = dateDifference(new Date().getTime(), new Date(expDate));
      // daysLeft = dateDifference(new Date().getTime(), new Date("2024-01-15"));
      // console.log(daysLeft, "days left");

      let strDate = new Date(expDate).toLocaleDateString("en-GB", {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
      });

      if (user?.isTrial) {
        return !daysRemaining || daysRemaining < 0 ? (
          "Your plan has ended. Update your subscription"
        ) : (
          <>
            Your plan expires on {strDate} {<BContSP>{daysLeft} left.</BContSP>}
          </>
        );
      }

      return user?.renewal ? (
        !daysRemaining || daysRemaining < 0 ? (
          "Has problem in renewing. Please contact contact support."
        ) : (
          <>
            Will be renewed on {strDate}
            {<BContSP2>{daysLeft} left.</BContSP2>}
          </>
        )
      ) : !daysRemaining || daysRemaining < 0 ? (
        `Subscription has ended on ${strDate}  `
      ) : (
        `You have cancelled your subscription, will end by ${strDate}`
      );
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Effect to update the freeTrialDays and daysLeft state variables when the free trial data changes

  // Function to toggle the visibility of the UpdateCard component
  const toggleUpdateCard = () => {
    user && user?.lastCardDigits
      ? setShowUpdateCard(!showUpdateCard)
      : navigate("/update_pay");
  };

  const tableHeadCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "20%",
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: true,
      label: "Amount",
      width: "20%",
    },

    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
      width: "20%",
    },
    {
      id: "paymentId",
      numeric: false,
      disablePadding: true,
      label: "Reference",
      width: "20%",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      width: "20%",
    },
  ];

  const getRows = () => {
    if (!(data?.getPaymentDetails instanceof Array)) return [];
    return data.getPaymentDetails.map((el) => {
      let obj = {};
      obj.status = getStatus(el.status);
      obj.amount = `$${Number(el.amount).toFixed(2)}`;
      obj.type = `${capitalizeFirstLetter(el.subscriptionType)} plan`;
      obj.date = new Date(el.updatedAt).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return { ...el, ...obj };
    });
  };

  const getStatus = (val) => {
    let text = val === "succeeded" ? "Completed" : val;
    let textColor =
      val === "succeeded" ? "#45A85B" : val === "pending" ? "#E59905" : "red";
    return <span style={{ color: textColor }}>{text}</span>;
  };

  // Function to navigate to the "/pay" route
  const handleClick = () => {
    navigate("/pay");
  };

  const handleCancelSubscription = async () => {
    try {
      const { data } = await cancelSubscription();
      dispatch(setUser(data.cancelSubscription));
      enqueueSnackbar(`Subscription successfully cancelled`, {
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar(`Canceling failed: ${err}`, {
        variant: "error",
      });
    }
  };

  const displayBoard = () => {
    return user?.isTrial ? (
      <>
        <BCont1_1_1Inner2Text1>Recrowdly free trial</BCont1_1_1Inner2Text1>
        <BCont1_1_1Inner2Text2>
          {process.env.REACT_APP_TRIAL_DAYS} days free
        </BCont1_1_1Inner2Text2>
      </>
    ) : user?.subscriptionType === "monthly" ? (
      <>
        <BCont1_1_1Inner2Text1>
          Recrowdly Monthly Subscription
        </BCont1_1_1Inner2Text1>
        <BCont1_1_1Inner2Text2>${prices.monthly}/month</BCont1_1_1Inner2Text2>
      </>
    ) : (
      <>
        <BCont1_1_1Inner2Text1>
          Recrowdly Yearly Subscription
        </BCont1_1_1Inner2Text1>
        <BCont1_1_1Inner2Text2>${prices.yearly}/month</BCont1_1_1Inner2Text2>
      </>
    );
  };

  const actionButton = () => {
    return !user?.isTrial && user?.renewal ? (
      <BCButton>
        <ColorButton
          style={{ cursor: "pointer", height: "54px" }}
          background="#1A1B24"
          title="Update Subscription"
          width="45%"
          onClick={handleClick}
        />
        <ColorButton
          style={{ cursor: "pointer", height: "54px" }}
          background="#F1F3EC"
          title="Cancel Subscription"
          width="45%"
          onClick={handleCancelSubscription}
          buttonTextStyle={{
            color: "#D93030",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "DM Sans",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "10.15px",
          }}
          hoverBackgroundColor="#f1f3ec"
          activeBgColor={"#F1F3EC"}
          activeBorderColor={"#007bf7"}
          innerStyle={{
            borderRadius: "14px",
            border: focusState.cancel
              ? "1px solid #1a1b24"
              : "1px solid #D93030",
          }}
          onMouseLeave={() => setFocusState({ ...focusState, cancel: false })}
          onMouseEnter={() => setFocusState({ ...focusState, cancel: true })}
        />
      </BCButton>
    ) : (
      <ColorButton
        style={{ cursor: "pointer", height: "54px" }}
        background="#1A1B24"
        title="Update Subscription"
        width="100%"
        onClick={handleClick}
      />
    );
  };

  return (
    <>
      <SubHeader
        btnTitle=""
        btnLink="/brands/"
        arrLink={[
          { title: "Dashboard", link: "/" },
          { title: "Billings & Plans" },
        ]}
      />
      <BContainer>
        <BCont1>
          <BContH1>Billing & Plans</BContH1>
          <BCont1_1>
            <BCont1_1_1>
              <BCont1_1_1Text>Current Plan</BCont1_1_1Text>
              <BCont1_1_2>
                <BCont1_1_2_1>
                  <img src={ThreeStars} alt="" />
                  <BCont1_1_1Inner>
                    <BCont1_1_1Inner2>{displayBoard()}</BCont1_1_1Inner2>
                    <BCont1_1_1Inner1Text1>
                      {calculateDays()}
                    </BCont1_1_1Inner1Text1>
                  </BCont1_1_1Inner>
                </BCont1_1_2_1>
                {actionButton()}
              </BCont1_1_2>
            </BCont1_1_1>
            <BCont2_1_1>
              <BCont1_1_1Text>Payment Method</BCont1_1_1Text>
              {showUpdateCard ? (
                <UpdateCardComp
                  user={user}
                  setShowUpdateCard={setShowUpdateCard}
                />
              ) : (
                <BCont2_1_2
                  onClick={toggleUpdateCard}
                  sx={{
                    ":hover": {
                      border: "1px solid #1a1b24",
                    },
                  }}
                  // onMouseEnter={() =>
                  //   setFocusState({ ...focusState, addPaPanel: true })
                  // }
                  // onMouseLeave={() =>
                  //   setFocusState({ ...focusState, addPaPanel: false })
                  // }
                >
                  <img src={PlusIcon} alt="" />
                  <BCont2_1_2Text>Add payment method</BCont2_1_2Text>
                </BCont2_1_2>
              )}
            </BCont2_1_1>
          </BCont1_1>
        </BCont1>
        <BCont3>Billing History</BCont3>
        <Grid container spacing={0} sx={{ padding: 0 }}>
          <Grid item xs={12} md={12}>
            <Table headCells={tableHeadCells} rows={getRows()} stylehead />
          </Grid>
        </Grid>
      </BContainer>
    </>
  );
}
