import React from "react";
import Customization from "../../pages/Customization";
import Layout from "../../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { GET_BRAND } from "../../graphql/queries/brandQueries";
import { useQuery } from "@apollo/client";
import MainArrow from "../../assets/icons/MainArrow.svg";
import SmallArrow from "../../assets/icons/SmallArrow.svg";
import {
  VideoSubFrame,
  HomeNav,
  NavWrapper,
  NavTextWrapper,
  BackHomeNavWrapper,
  NavDown,
  SmallTextWrap,
  VideosSmallText,
} from "../VideosPage/styled";

export default function CustomizationPAge() {
  const menu = useSelector((state) => state.menu);
  const { loading, error, data, refetch } = useQuery(GET_BRAND, {
    variables: { input: { _id: menu.id } },
  });
  const isMobile = useSelector((state) => state.responsive.isMobile);
  return (
    <>
      {/* <VideoSubFrame>
                <img alt="Vuesax twotone arrow" src={MainArrow} />
                            <HomeNav>Home</HomeNav>
                    <NavWrapper>
                        <NavTextWrapper>
                            
                        </NavTextWrapper>
                        <BackHomeNavWrapper>
                            <NavDown alt="Vuesax twotone arrow" src={SmallArrow} />
                        </BackHomeNavWrapper>
                        <SmallTextWrap>
                            <VideosSmallText>Videos</VideosSmallText>
                        </SmallTextWrap>
                    </NavWrapper>
                </VideoSubFrame> */}
      <Customization
        brandId={menu.brandId}
        _id={menu.id}
        data={data?.brand}
        handleRefetch={refetch}
        isMobile={isMobile}
      />
    </>
  );
}
