import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material"; //

export const Container = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  background: #fff;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
  z-index: 99999999;
`;

export const UnitBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const Text = styled(Box)`
  color: #858995;
  font-family: Dm Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
