import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { HiMiniDocumentDuplicate } from "react-icons/hi2";
import tempreview from "./tempreview.jpg";
import {
  Container,
  DisplayDiv,
  SubTitle,
  Title,
  TemplateCard,
  ButtonContainer,
  TemplateImage,
  CardContent,
} from "./styled";
import ColorButton from "../../components/ColorButton";
import Dialog from "../../components/Dialog";
import Modal from "./Modal";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import {
  SAVE_TEMPLATE_MUTATION,
  GET_TEMPLATE_BY_ID_QUERY,
  GET_ALL_TEMPLATES_QUERY,
  DELETE_TEMPLATE_MUTATION,
} from "../../graphql/mutations/saveTemplateMutation"; // Import the GraphQL mutation
import TemplatesAnalytics from "./templatesAnalytics";
import NewsletterAnalytics from "../../components/NewsletterAnalytics";
import { createUniqueTitle } from "../../utils/pageStore";
import { enqueueSnackbar } from "notistack";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import { FETCH_SALES_FIELDS } from "../../graphql/queries/salesApp";

const Templates = () => {
  const [templateCards, setTemplateCards] = useState([]);
  const [showMessage, setShowMessage] = useState(true);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [showTitleDialog, setShowTitleDialog] = useState(false);
  const [newsletterTitle, setNewsletterTitle] = useState("");
  const [newsletterFolder, setNewsletterFolder] = useState("Root");
  const [displayMode, setDisplayMode] = useState("email");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [savedTemplate, setSavedTemplate] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [templateToDuplicate, setTemplateToDuplicate] = useState(null);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [duplicateTemplateDialogOpen, setDuplicateTemplateDialogOpen] =
    useState(false);
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [loadedHTML, setLoadedHTML] = useState("");
  const [loadedDesignHTML, setLoadedDesignHTML] = useState("");
  const [newsletterFlag, setNewsletterFlag] = useState("Newsletter list");
  const [saveTemplateMutation] = useMutation(SAVE_TEMPLATE_MUTATION);
  const client = useApolloClient();
  const [designData, setDesignData] = useState(null);
  const [deleteTemplateMutation] = useMutation(DELETE_TEMPLATE_MUTATION);
  const [previewImage, setPreviewImage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedHTMLContent, setSelectedHTMLContent] = useState("");

  const {
    loading: templateLoading,
    data: templateData,
    refetch: refetchTemplates,
  } = useQuery(GET_ALL_TEMPLATES_QUERY);

  const { data: salesLeadsFields, refetch: refetchSalesFields } =
    useQuery(FETCH_SALES_FIELDS);

  const uniqueGeneratedTitle = useMemo(() => {
    if (templateData?.getAllTemplates && templateToDuplicate?.title) {
      const otherTitles = templateData?.getAllTemplates.map(
        (template) => template.title
      );
      return createUniqueTitle(templateToDuplicate?.title, otherTitles);
    }
    return "";
  }, [templateData, templateToDuplicate]);

  const handleDuplicateNewsLetter = async () => {
    try {
      setIsDuplicateLoading(true);
      let writtenTitle = newTitle.trim();
      if (newTitle.trim()) {
        const otherTitles = templateData?.getAllTemplates.map(
          (template) => template.title
        );
        writtenTitle = createUniqueTitle(newTitle.trim(), otherTitles);
      }

      const { data: dataUpdate, errors } = await saveTemplateMutation({
        variables: {
          input: {
            title: writtenTitle || uniqueGeneratedTitle,
            layoutType: templateToDuplicate?.title,
            designBody: templateToDuplicate?.designBody,
            designHTML: templateToDuplicate?.designHTML,
            imageData: templateToDuplicate?.imageData,
          },
        },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(`error duplicating template`, {
          variant: "error",
        });
        return;
      }
      if (dataUpdate) {
        refetchTemplates();
        setDuplicateTemplateDialogOpen(false);
        setTemplateToDuplicate({});
        setNewTitle("");
      }
      enqueueSnackbar("successful", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`error duplicating template ${e}`, {
        variant: "error",
      });
    } finally {
      setIsDuplicateLoading(false);
    }
  };

  const handleSaveTemplate = (
    templateData,
    imageData,
    creationDateTime,
    designData,
    previewImage
  ) => {
    setSavedTemplate(templateData);
    setDesignData(designData);
    // console.log("Design data:", designData);
    setPreviewImage(previewImage);
    // console.log("previewImage received:", previewImage);

    const currentDate = new Date().toISOString();
    if (templateData.id) {
      const foundObjIndex = templateCards.findIndex(
        (elm) => elm.id === templateData.id
      );
      let updatedTemplateCards = [];
      let obj = {
        id: templateData.id,
        title: templateData.title,
        subtitle: templateData.folder,
        description: templateData.displayMode === "email" ? "Email" : "Web",
        lastUpdate: currentDate,
        creationDateTime: creationDateTime,
        imageData: imageData || tempreview,
        previewImage: previewImage,
      };
      if (foundObjIndex === -1) {
        updatedTemplateCards = [...templateCards, obj];
      } else {
        updatedTemplateCards[foundObjIndex] = obj;
      }

      setTemplateCards(updatedTemplateCards);
    }

    setShowMessage(false);
    setShowTitleDialog(false);
    setModalIsOpen(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleContinueToEditor = () => {
    if (!newsletterTitle.trim()) {
      setOpenSnackbar(true);
      setSnackbarMessage("Please enter a newsletter title before continuing.");
      return;
    }
    setShowTitleDialog(false);
    /////////
    setPreviewImage(null);
    setLoadedDesignHTML("");
    setLoadedHTML("");
    setSelectedHTMLContent("");
    //////////
    setModalIsOpen(true);
  };

  const handleUploadTemplate = () => {
    setUploadDialogOpen(true);
  };

  const handleEditTemplate = async (id) => {
    // console.log("Template ID:", id);

    try {
      const { data } = await client.query({
        query: GET_TEMPLATE_BY_ID_QUERY,
        variables: {
          id: id,
        },
      });

      if (data && data.getTemplateById) {
        const fetchedTemplate = data.getTemplateById;

        // console.log("Fetched Template ID:", fetchedTemplate.id);

        setSavedTemplate(fetchedTemplate);
        setNewsletterTitle(fetchedTemplate.title);
        setModalIsOpen(true);
        setEditMode(true);
        setLoadedDesignHTML(JSON.parse(fetchedTemplate.designBody));
        setLoadedHTML(fetchedTemplate.designHTML);

        // console.log("Preview Image:", fetchedTemplate.imageData);
        // console.log("Set PreviewImage:", previewImage);
      } else {
        console.error("Template not found or returned null data!");
      }
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  const handleDeleteTemplate = async (id) => {
    setTemplateToDelete(id);
    setConfirmDeleteDialogOpen(true);
  };

  const confirmDeleteTemplate = async () => {
    setConfirmDeleteDialogOpen(false);
    try {
      const { data } = await deleteTemplateMutation({
        variables: {
          input: {
            id: templateToDelete,
          },
        },
      });

      if (data && data.deleteTemplate) {
        const updatedTemplateCards = templateCards.filter(
          (card) => card.id !== templateToDelete
        );
        setTemplateCards(updatedTemplateCards);
        if (updatedTemplateCards.length === 0) {
          setShowMessage(true);
        }
      } else {
        console.error("Failed to delete template");
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  const handleFileUploadClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const htmlContent = event.target.result;

      const fileName = file.name.replace(/\.[^/.]+$/, "");
      setNewsletterTitle(fileName);

      setModalIsOpen(true);
      setSelectedHTMLContent(htmlContent);

      setUploadDialogOpen(false);
    };

    reader.readAsText(file);
  };

  const handleCreateTemplate = () => {
    setEditMode(false);
    setNewsletterTitle("");
    setShowTitleDialog(true);
  };

  const format_date = (lastUpdate) => {
    if (!lastUpdate) {
      return "Updated...";
    }

    try {
      const date = new Date(lastUpdate);
      const options = {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const formattedDate = date.toLocaleString("en-US", options);
      return formattedDate; // Remove the comma after the day
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Date updating...";
    }
  };

  const getLeadsFields = () => {
    try {
      return JSON.parse(salesLeadsFields?.fetchSalesParameters?.data ?? "[]");
    } catch {
      return [];
    }
  };

  useEffect(() => {
    // console.log("Template loading:", templateLoading);
    // console.log("Template data:", templateData);
    if (templateData && templateData.getAllTemplates) {
      setTemplateCards(templateData.getAllTemplates);
      setShowMessage(false);
    }
  }, [templateData, templateLoading]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Title>Newsletters</Title>
          <SubTitle>
            Create a newsletter or upload a newsletter template.
          </SubTitle>
        </Grid>
        <Grid item xs={6} style={{ alignContent: "center" }}>
          <ButtonContainer
            style={{
              position: "relative",
              marginRight: "-50px",
              justifyContent: "flex-end",
            }}
          >
            {/* <ColorButton
              title={`${newsletterFlag ? "Hide" : "Show"} Analytics`}
              background="#D6DAE0"
              textColor="#fff"
              hoverColor="black"
              hoverBackgroundColor="silver"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              onClick={() => setNewsletterFlag(!newsletterFlag)}
              style={{ marginRight: "16px" }}
            /> */}
            <Select
              onChange={(e) => setNewsletterFlag(e.target.value)}
              value={newsletterFlag}
              sx={{
                width: "200px",
                fontFamily: "Switzer",
                borderRadius: "12px",
                mr: 2,
                ".MuiSelect-select": {
                  py: "9px !important",
                },
              }}
            >
              {["Newsletter list", "Overview", "Data Analytics"].map(
                (value) => (
                  <MenuItem
                    key={value}
                    sx={{ fontFamily: "Switzer" }}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                )
              )}
            </Select>
            <ColorButton
              title="Create Newsletter Template"
              background="black"
              textColor="#fff"
              hoverColor="black"
              hoverBackgroundColor="#464646"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              startIcon={<AddIcon />}
              buttonTextStyle={{ color: "white" }}
              innerStyle={{ color: "green" }}
              onClick={handleCreateTemplate}
              style={{ marginRight: "16px" }}
            />
          </ButtonContainer>
        </Grid>
        <Grid item xs={12}>
          {newsletterFlag === "Overview" ? (
            <TemplatesAnalytics />
          ) : newsletterFlag === "Data Analytics" ? (
            <NewsletterAnalytics />
          ) : (
            <DisplayDiv style={{ position: "relative" }}>
              {/* Display template cards */}
              <div
                style={{
                  borderWidth: "2px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "stretch",
                }}
              >
                {showMessage ? (
                  <div
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      marginTop: "60px",
                      marginBottom: "-45px",
                      marginLeft: "40%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      justifySelf: "center",
                      fontStyle: "italic",
                      color: "grey",
                      alignSelf: "center",
                      fontFamily: "DM Sans",
                    }}
                  >
                    You're yet to create or upload a newsletter template
                  </div>
                ) : (
                  templateCards.map((card) => (
                    <Box key={card.id} sx={{ py: "20px" }}>
                      <TemplateCard
                        style={{
                          margin: "16px",
                          borderWidth: "20px",
                          borderColor: "grey",
                          width: "180px",
                          height: "100%",
                          display: "flex",
                          paddingBottom: "20px",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          backgroundColor: "#D6DAE0",
                        }}
                      >
                        {/* {console.log("Card image data:", card.imageData)} */}

                        <TemplateImage
                          src={card.imageData ? card.imageData : tempreview}
                          alt="Template Preview"
                          style={{
                            padding: "10px",
                            width: "100%",
                            height: "75%", // Set the preview image height to 75% of the card height
                            objectFit: "cover", // Ensure the image covers the container
                          }}
                        />

                        <CardContent>
                          <Title
                            style={{
                              marginTop: "-5px",
                              marginBottom: "-1px",
                              fontSize: "1rem",
                              lineHeight: "normal",
                            }}
                          >
                            {card.title}
                          </Title>
                          <SubTitle>{card.description}</SubTitle>
                          <SubTitle style={{ fontSize: "10px" }}>
                            {format_date(card.lastUpdate)}
                          </SubTitle>
                        </CardContent>
                        <Box
                          className="glass"
                          sx={{
                            transform: "scale(0.9)",
                            position: "absolute",
                            display: "flex",
                            bottom: "8px",
                            right: "0px",
                            color: "black",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setTemplateToDuplicate(card);
                              setDuplicateTemplateDialogOpen(true);
                            }}
                            style={{
                              color: "black",
                            }}
                          >
                            <HiMiniDocumentDuplicate />
                          </IconButton>
                          <IconButton
                            onClick={() => handleEditTemplate(card.id)}
                            style={{
                              color: "black",
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteTemplate(card.id)}
                            style={{
                              color: "black",
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </TemplateCard>
                    </Box>
                  ))
                )}
              </div>
            </DisplayDiv>
          )}
        </Grid>
      </Grid>

      {showTitleDialog && (
        <Dialog
          open={showTitleDialog}
          onClose={() => setShowTitleDialog(false)}
          BackdropProps={{ onClick: () => setShowTitleDialog(false) }}
          title="Create New Template"
          setOpen={setShowTitleDialog}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={RemoveIcon}
              style={{ cursor: "pointer", marginBottom: "20px" }}
              onClick={() => setShowTitleDialog(false)}
            />
          </Box>

          <div style={{ marginBottom: "16px" }}>
            <TextField
              label="Newsletter Template Title"
              variant="outlined"
              value={newsletterTitle}
              onChange={(e) => setNewsletterTitle(e.target.value)}
              fullWidth
              autoFocus
              required
            />
          </div>

          <ColorButton
            title="Continue to Editor"
            background="black"
            textColor="#fff"
            hoverColor="black"
            hoverBackgroundColor="#464646"
            borderWidth="2px"
            activeBorderColor="grey"
            activeBgColor="grey"
            hoverBorder="grey"
            buttonTextStyle={{ color: "white" }}
            innerStyle={{ color: "green" }}
            onClick={handleContinueToEditor}
            style={{ marginTop: "10px" }}
          />
        </Dialog>
      )}

      {/* {uploadDialogOpen && (
        <Dialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          title="Upload Newsletter Template"
          setOpen={setUploadDialogOpen}
        >
          <div style={{ textAlign: "center", padding: "20px" }}>
            <CloseIcon
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={() => setUploadDialogOpen(false)}
            />

            <div
              style={{
                border: "2px dashed #ccc",
                padding: "20px",
                borderRadius: "10px",
                marginBottom: "20px",
                fontFamily: "Switzer",
              }}
            >
              Drag and drop a newsletter template file here or{" "}
              <span
                style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
                onClick={handleFileUploadClick}
              >
                click here to upload one
              </span>
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
            </div>
            <ColorButton
              title="Upload Template..."
              background="#D6DAE0"
              textColor="#000"
              hoverColor="black"
              hoverBackgroundColor="silver"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              onClick={() => document.getElementById("fileInput").click()} // Trigger file input click
            />
          </div>
        </Dialog>
      )} */}

      {confirmDeleteDialogOpen && (
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={() => setConfirmDeleteDialogOpen(false)}
          title="Confirm Delete"
          style={{ borderRadius: "10px" }}
        >
          <div
            style={{
              textAlign: "center",
              padding: "15px",
              borderRadius: "10px",
            }}
          >
            Are you sure you want to delete this template? Note that this action
            cannot be reversed.
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ColorButton
                title="Yes, Delete Template"
                background="brown"
                buttonTextStyle={{ color: "white" }}
                hoverColor="black"
                hoverBackgroundColor="#d32f2f"
                borderWidth="2px"
                activeBorderColor="grey"
                activeBgColor="grey"
                hoverBorder="grey"
                onClick={confirmDeleteTemplate}
                style={{ marginRight: "10px" }}
              />
              <ColorButton
                title="Cancel"
                background="#D6DAE0"
                buttonTextStyle={{ color: "#000" }}
                hoverColor="black"
                hoverBackgroundColor="silver"
                borderWidth="2px"
                activeBorderColor="grey"
                activeBgColor="grey"
                hoverBorder="grey"
                onClick={() => setConfirmDeleteDialogOpen(false)}
              />
            </div>
          </div>
        </Dialog>
      )}

      <Dialog
        open={duplicateTemplateDialogOpen}
        onClose={() => setDuplicateTemplateDialogOpen(false)}
        title="Confirm duplicate"
        style={{ borderRadius: "10px" }}
        setOpen={setDuplicateTemplateDialogOpen}
      >
        <div
          style={{
            borderRadius: "10px",
            fontFamily: "Dm Sans",
          }}
        >
          <p>
            Are you sure you want to Duplicate{" "}
            <strong>
              <em>{templateToDuplicate?.title}</em>
            </strong>
            ?. You can enter a new title before you proceed{" "}
          </p>
          <div>
            <label
              style={{ fontWeight: "bold", fontSize: "14px", display: "block" }}
            >
              Title <em>(optional)</em>
            </label>
            <TextField
              margin="normal"
              name="title"
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
              label=""
              placeholder={uniqueGeneratedTitle}
              type="text"
              id="title"
              sx={{
                margin: "0px",
                ".MuiInputBase-root": {
                  // height: "50px",
                  borderRadius: "12px",
                },
                ":hover": {
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#1a1b24 !important",
                    borderWidth: "1px !important",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  input: {
                    padding: "10px 10px !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#007bf7",
                    borderWidth: "1px !important",
                    boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                  },
                },
              }}
            />
          </div>

          <div
            style={{
              marginTop: "20px",
              display: "flex",
            }}
          >
            <ColorButton
              title="Duplicate Template"
              background="#191919"
              buttonTextStyle={{ color: "white" }}
              hoverColor="black"
              hoverBackgroundColor="#363232"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              disabled={isDuplicateLoading}
              onClick={handleDuplicateNewsLetter}
              style={{ marginRight: "10px" }}
            />
            <ColorButton
              title="Cancel"
              background="#D6DAE0"
              buttonTextStyle={{ color: "#000" }}
              hoverColor="black"
              hoverBackgroundColor="silver"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              onClick={() => setDuplicateTemplateDialogOpen(false)}
            />
          </div>
        </div>
      </Dialog>

      <Modal
        leadsFields={getLeadsFields()}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onSave={(
          templateData,
          imageData,
          creationDateTime,
          designData,
          previewImage
        ) =>
          handleSaveTemplate(
            templateData,
            imageData,
            creationDateTime,
            designData,
            previewImage
          )
        }
        newsletterTitle={newsletterTitle}
        newsletterFolder={newsletterFolder}
        displayMode={displayMode}
        savedTemplate={savedTemplate}
        loadedHTML={loadedHTML}
        selectedHTMLContent={selectedHTMLContent}
        loadedDesignHTML={loadedDesignHTML}
        saveTemplateMutation={saveTemplateMutation}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        designData={designData}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        editMode={editMode}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default Templates;
