import React, { useEffect, useState, useRef } from "react";
import {
  Drawer,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Stack,
  Grid,
  Container,
  Box,
  Divider,
  InputBase,
  Paper,
  IconButton,
  ListItemButton,
  Modal,
  TextField,
  Avatar,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  Overview,
  OverviewBox,
  BrandAnalyticsText,
  SeeMore,
  SeeMoreText,
  CountriesText,
  CountriesView,
  DetailsContainer,
} from "../pages/Organizations/styled";
import SummaryAnalytics from "./SummaryAnalytics";
import {
  BannerButton,
  BannerButtonBrand,
  BannerButtonText,
  BannerCalender,
  FilterButton,
  FilterButtonBrand,
  FilterButtonIcon,
  FilterButtonText,
  FlexBannerButtonFilter,
  FlexBannerButtonFilterTwo,
  FullAnalytics,
  FullAnalyticsHeader,
  FullAnalyticsHeaderBox,
  FullAnalyticsHeaderBoxContent,
  FullAnalyticsText,
  FullAnalyticsTwo,
  SearchResultsFormDateTwo,
} from "../pages/UserDashboard/styled";
import ChartCard from "./ChartCard";
import FilterIcon from "../assets/icons/Filter.svg";
import UnitedStates from "../assets/icons/UnitedStates.svg";
import Calender from "../assets/icons/Calender.svg";
import India from "../assets/icons/India.svg";
import Phone from "../assets/images/Phone.svg";
import Tablet from "../assets/images/Tablet.svg";
import Desktop from "../assets/images/Desktop.svg";
import AverageRating from "../assets/icons/AverageRating.svg";
import Impression from "../assets/icons/Impression.svg";
import NewVideos from "../assets/icons/NewVideos.svg";
import Duration from "../assets/icons/Duration.svg";
import Dash from "../assets/images/Dash.svg";
import NoCountry from "../assets/images/NoCounty.svg";
import NoDevice from "../assets/images/NoDevice.svg";
import Engagements from "../assets/icons/Engagements.svg";
import PageVisited from "../assets/icons/PageVisited.svg";
import AverageTime from "../assets/icons/AverageTime.svg";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
// import styled from "@emotion/styled";
import SummaryAnalyticsTwo from "./SummaryAnalyticsTwo";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ANALYTICS,
  GET_ANALYTICS_BY_BRAND,
  GET_ANALYTICS_ENGAGEMENT,
  GET_ANALYTICS_IMPRESSION,
  GET_ANALYTICS_PAGE_VISITED,
  GET_ANALYTICS_TIME_WEBSITE_SPENT,
  GET_DISTINCT_VIDEOS_WATCHED,
  GET_TOTAL_ANALYTICSVIDEOS_WATCHED,
  VIDEOS_WATCHED_COUNT,
  VIDEOS_WATCHED_DAYS_COUNT,
} from "../graphql/queries/analytics";
import { useQuery } from "@apollo/client";
import {
  APPROVED_VIDEOS_DAYS_COUNT,
  COUNT_VIDEOS,
  DISAPPROVED_VIDEOS_DAYS_COUNT,
  GET_VIDEOS_BY_BRAND,
  NEW_VIDEOS_DAYS_COUNT,
  RATING_AVERAGE,
  RATING_DAYS_COUNT,
} from "../graphql/queries/videosQueries";
import moment from "moment";
import Select from "react-select";
import NewVideoChart from "./NewVideoChart";
import DisapproveChart from "./DisapproveChart";
import ApproveChart from "./ApproveChart";
import ChartCardTwo from "./ChartCardTwo";
import ChartCardThree from "./ChartCardThree";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import styled from "styled-components";
import { setDate } from "../redux/date.slice";
import { useNavigate, useParams } from "react-router-dom";
import ChartCardNew from "./ChartCardNew";
import GenericChartCard from "./GenericChartCard";
import DateRange from "./DateRange";
// const data = [
//   { country: 'United States', views: 3, value: 60, logo: UnitedStates },
//   { country: 'India', views: 4, value: 70, logo: India },
//   { country: 'China', views: 5, value: 80, logo: UnitedStates },
//   { country: 'Germany', views: 2, value: 20, logo: UnitedStates },
//   { country: 'Nigeria', views: 1, value: 50, logo: UnitedStates },
// ];
// const dataTwo = [
//   { device: 'Desktop/Laptop', views: 3, value: 60},
//   { device: 'Mobile', views: 4, value: 70},

// ];
const StyledRangePicker = styled(DatePicker.RangePicker)`
  .ant-picker-range {
    visibility: hidden;
  }

  & .ant-picker-dropdown {
    top: 135px;
    left: 1339.27px;
  }
`;
// const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: value > 50 ? "#8D6CFF" : "#F3B02F",
//   },
// }));

const Country = ({ country, views, value, logo, isMobile }) => {
  const [flagUrl, setFlagUrl] = useState("");

  useEffect(() => {
    async function fetchFlag() {
      const response = await fetch(
        // `https://restcountries.com/v2/name/Nigeria?fullText=true`
        `https://restcountries.com/v3.1/name/${country}?fullText=true`
      );
      const data = await response.json();
      if (data.length > 0) {
        setFlagUrl(data[0].flags.svg);
      }
    }
    fetchFlag();
  }, [country]);

  return (
    <Grid
      item
      sx={{ width: isMobile ? "100%" : "45%" }}
      style={{ paddingLeft: "0px" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "0.5rem 0",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {flagUrl && (
            <img
              src={flagUrl}
              alt="country-flag"
              style={{ width: "24px", height: "24px", marginRight: "8px" }}
            />
          )}
          <CountriesText>{country}</CountriesText>
        </Box>
        <img src={Dash} alt="dash-icon" />
        <Box>
          {/* <CountriesText>{views || 0} view</CountriesText> */}
          <CountriesView>{views} views</CountriesView>
        </Box>
      </Box>
    </Grid>
  );
};

const Device = ({ device, views, value, isMobile, index }) => {
  let deviceImage;

  // Determine the appropriate image based on the device
  if (device === "Desktop/Laptop") {
    deviceImage = Desktop; // Replace 'DesktopImage' with the actual image source for desktop
  } else if (device === "Tablet") {
    deviceImage = Tablet; // Replace 'TabletImage' with the actual image source for tablet
  } else if (device === "Mobile") {
    deviceImage = Phone; // Replace 'PhoneImage' with the actual image source for phone
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5px",
        padding: "10px",
        width: index > 0 ? "33%" : "auto",
        borderLeft: index > 0 ? "2px solid #C8E292" : "none",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
        <Box sx={{ marginLeft: "0px" }}>
          <CountriesView>{views} views</CountriesView>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: isMobile ? "0.1rem" : "2rem",
        }}
      >
        {/* Render the image based on the device */}
        {deviceImage && (
          <img
            src={deviceImage}
            alt="device-icon"
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
        )}
        <CountriesText>{device}</CountriesText>
      </Box>
    </Box>
  );
};

function BrandAnalytics() {
  const menu = useSelector((state) => state.menu);
  const date = useSelector((state) => state.date);
  const rangeRef = useRef(null);
  const dispatch = useDispatch();
  const [dialogRequestOpen, setDialogRequestOpen] = useState(false);
  const [customRangeSelected, setCustomRangeSelected] = useState(false);
  const navigate = useNavigate();
  let { chart: chart } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(date.startDate);
  const [selectedDays, setSelectedDays] = useState(1);
  const [endDate, setEndDate] = useState(date.endDate);
  const [showResults, setShowResults] = useState(false);
  const numSelectedDays = dayjs(endDate).diff(startDate, "day") + 1;
  const [dateText, setDateText] = useState("Yesterday");
  const [display, setDisplay] = useState(false);
  const [open, setOpen] = useState(false);
  const [topCalendar, setTopCalendar] = useState(0);
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const reformatDuration = (formattedTotalOverallDuration = "") => {
    let durationStringOverall = "";

    if (formattedTotalOverallDuration === 0) {
      durationStringOverall = `0`;
    } else if (formattedTotalOverallDuration < 60) {
      durationStringOverall = `${Math.floor(
        formattedTotalOverallDuration
      )} secs`;
    } else if (formattedTotalOverallDuration < 3600) {
      const minutes = Math.floor(formattedTotalOverallDuration / 60);
      const seconds = Math.floor(formattedTotalOverallDuration % 60);

      durationStringOverall = `${minutes} mins ${seconds} secs`;
    } else {
      const hours = Math.floor(formattedTotalOverallDuration / 3600);
      const minutes = Math.floor((formattedTotalOverallDuration % 3600) / 60);
      const seconds = Math.floor(formattedTotalOverallDuration % 60);
      durationStringOverall = `${hours} hr ${minutes} mins `;
    }
    return durationStringOverall;
  };

  // Update dateText whenever selectedDays changes
  useEffect(() => {
    let dateText;
    if (numSelectedDays == "1") {
      dateText = "Today";
    } else if (numSelectedDays == "2") {
      dateText = "Yesterday";
    } else {
      dateText = `Last ${numSelectedDays} days`;
    }
    setDateText(dateText);
  }, [numSelectedDays]);

  ///New Analytics
  const { data: engagementData } = useQuery(GET_ANALYTICS_ENGAGEMENT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: averageTimeData } = useQuery(GET_ANALYTICS_TIME_WEBSITE_SPENT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: pagesVisitedData } = useQuery(GET_ANALYTICS_PAGE_VISITED, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: impression_Data } = useQuery(GET_ANALYTICS_IMPRESSION, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  //////////

  // const { error:analyticsError, data:analyticsData } = useQuery(GET_ANALYTICS);
  const {
    loading: analyticsLoading,
    error: analyticsError,
    data: analyticsData,
  } = useQuery(GET_ANALYTICS_BY_BRAND, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: watchedLoading,
    error: watchedError,
    data: watchedData,
  } = useQuery(VIDEOS_WATCHED_DAYS_COUNT, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: durationLoading,
    error: durationError,
    data: durationData,
  } = useQuery(GET_TOTAL_ANALYTICSVIDEOS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: newVideoLoading,
    error: newVideoError,
    data: newVideoData,
  } = useQuery(NEW_VIDEOS_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: countVideosLoading,
    error: countVideosError,
    data: countVideosData,
  } = useQuery(COUNT_VIDEOS, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: ratingLoading,
    error: ratingError,
    data: ratingData,
  } = useQuery(RATING_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: disapproveLoading,
    error: disapproveError,
    data: disapproveData,
  } = useQuery(DISAPPROVED_VIDEOS_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: approveLoading,
    error: approveError,
    data: approveData,
  } = useQuery(APPROVED_VIDEOS_DAYS_COUNT, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: watchedVideosLoading,
    error: watchedVideosError,
    data: watchedVideosData,
  } = useQuery(VIDEOS_WATCHED_COUNT, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: distinctVideosLoading,
    error: distinctVideosError,
    data: distinctVideosData,
  } = useQuery(GET_DISTINCT_VIDEOS_WATCHED, {
    variables: {
      brandId: menu.brandId,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    loading: ratingAverageLoading,
    error: ratingAverageError,
    data: ratingAverageData,
  } = useQuery(RATING_AVERAGE, {
    variables: {
      brandId: menu.id,
      startDate: date.startDate,
      endDate: date.endDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const [selectedChart, setSelectedChart] = useState(chart);

  useEffect(() => {
    function handleClickOutside(event) {
      if (rangeRef.current && !rangeRef.current.contains(event.target)) {
        // Check if the clicked target is within the Dropdown's internal elements
        const dropdownInternal = document.querySelector(".ant-picker-dropdown");
        if (!dropdownInternal || !dropdownInternal.contains(event.target)) {
          setDisplay(false);
        } else {
          setDisplay(true);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (rangeRef.current) {
      const rect = rangeRef.current.getBoundingClientRect();
      // console.log("RectSecond:", rect);

      setTopCalendar(rect.bottom);
      // Do something with the top position
    }
  }, [rangeRef]);

  const data = analyticsData?.analyticsByBrandId.reduce((acc, cur) => {
    const existingCountry = acc.find((item) => item.country === cur.country);
    if (existingCountry) {
      // increment the views count if country already exists
      existingCountry.views++;
    } else {
      // add a new country to the array
      acc.push({
        country: cur.country,
        views: 1,
        value: Math.floor(Math.random() * 100), // set a random value for demo purposes
        logo: UnitedStates, // set logo to null for demo purposes
      });
    }
    return acc;
  }, []);

  const dataTwo = analyticsData?.analyticsByBrandId.reduce((acc, cur) => {
    const existingDevice = acc.find((item) => item.device === cur.device);
    if (existingDevice) {
      // increment the views count if device already exists
      existingDevice.views++;
    } else {
      // add a new device to the array
      acc.push({
        device: cur.device,
        views: 1,
        value: Math.floor(Math.random() * 100), // set a random value for demo purposes
        // logo: UnitedStates  // set logo to null for demo purposes
      });
    }
    return acc;
  }, []);
  useEffect(() => {
    setSelectedChart(chart);
  }, [chart]);
  const chartOptions = [
    // { value: "impression", label: "Impression", image: Impression },
    {
      value: "impression",
      label: "Impression",
      image: Impression,
      data: impression_Data?.analyticsDaysImpression?.results || [],
      item: "count",
      color: "#8D6CFF",
      overall: impression_Data?.analyticsDaysImpression?.total ?? 0,
    },

    // { value: "new", label: "New Video ", image: NewVideos },
    // {
    //   value: "duration",
    //   label: "Average View Duration ",
    //   image: Duration,
    // },
    // {
    //   value: "rating",
    //   label: "Average Rating ",
    //   image: AverageRating,
    // },
    {
      value: "engagement",
      label: "Engagement",
      image: Engagements,
      data: engagementData?.analyticsDaysEngagement?.results || [],
      item: "dailyTraffic",
      color: "#BF6DFF",
      overall: engagementData?.analyticsDaysEngagement?.total
        ? Math.round(engagementData?.analyticsDaysEngagement?.total * 100) / 100
        : 0, //
    },
    {
      value: "averageTime",
      label: "Average Time ",
      image: AverageTime,
      data: averageTimeData?.analyticTimeWebSiteSpent?.results || [],
      item: "totalDuration",
      color: "#5ACEC7",
      overall: averageTimeData?.analyticTimeWebSiteSpent?.total
        ? reformatDuration(averageTimeData?.analyticTimeWebSiteSpent?.total)
        : 0,
    },
    {
      value: "pageVisited",
      label: "Page Visited ",
      image: PageVisited,
      data: pagesVisitedData?.analyticsNumberofVisitedPages?.results || [],
      item: "avgPages",
      color: "#FAC60F",
      overall: pagesVisitedData?.analyticsNumberofVisitedPages?.total
        ? Math.round(
            pagesVisitedData?.analyticsNumberofVisitedPages?.total * 100
          ) / 100
        : 0, //average
    },
    // { value: "DisapprovedChart", label: "Disapproved Chart", image: "impression.png" },
    // { value: "ApprovedChart", label: "Approved Chart", image: "impression.png" },
  ];

  const handleChartChange = (selectedOption) => {
    setSelectedChart(selectedOption.value);
    navigate(`/brands/${menu.brandId}/0/${selectedOption.value}`);
  };
  const totalNewVideosData = newVideoData?.newDaysVideoCount || [];
  const totalNewVideos = totalNewVideosData.reduce(
    (sum, item) => sum + item.count,
    0
  );
  const analyticsTotalData = durationData?.analyticsDaysVideoDuration || [];
  const totalVideosDuration = analyticsTotalData.reduce(
    (sum, item) => sum + item.totalDuration,
    0
  );
  const formattedTotalOverallDuration = durationData
    ? Math.round(totalVideosDuration)
    : 0;
  //
  let durationStringOverall = reformatDuration(formattedTotalOverallDuration);
  // rating average
  const num = ratingAverageData?.getAverageRating;

  const roundedNum = (Math.round(num * 100) / 100).toFixed(2); // "3.44"
  const finalNum = parseFloat(roundedNum).toFixed(1); // "3.46"
  const renderChartComponent = () => {
    switch (selectedChart) {
      // case "impression":
      //   return (
      //     <ChartCard
      //       brandId={menu.brandId}
      //       startDate={startDate}
      //       endDate={endDate}
      //       cardData={watchedData?.analyticsDaysVideoCount}
      //       isMobile={isMobile}
      //     />
      //   );
      // case "new":
      //   return (
      //     <ChartCardNew
      //       brandId={menu.brandId}
      //       startDate={startDate}
      //       endDate={endDate}
      //       cardData={newVideoData?.newDaysVideoCount}
      //       isMobile={isMobile}
      //     />
      //   );
      // case "duration":
      //   return (
      //     <ChartCardTwo
      //       brandId={menu.brandId}
      //       startDate={startDate}
      //       endDate={endDate}
      //       cardData={durationData?.analyticsDaysVideoDuration}
      //       isMobile={isMobile}
      //     />
      //   );
      // case "rating":
      //   return (
      //     <ChartCardThree
      //       brandId={menu.brandId}
      //       startDate={startDate}
      //       endDate={endDate}
      //       cardData={ratingData?.ratingDaysVideoCount}
      //       isMobile={isMobile}
      //     />
      //   );
      // case "DisapprovedChart":
      //   return (
      //     <ChartCard
      //       brandId={menu.brandId}
      //       startDate={startDate}
      //       endDate={endDate}
      //       cardData={disapproveData?.disapprovedDaysVideoCount}
      //       isMobile={isMobile}
      //     />
      //   );
      // case "ApprovedChart":
      // return (
      //   <ChartCard
      //     brandId={menu.brandId}
      //     startDate={startDate}
      //     endDate={endDate}
      //     cardData={approveData?.approvedDaysVideoCount}
      //     isMobile={isMobile}
      //   />
      // );
      case "impression":
      case "engagement":
      case "averageTime":
      case "pageVisited":
        let findRecord = chartOptions.find((el) => el.value === selectedChart);
        if (!findRecord) return null;
        let { label, data, item, color } = findRecord;
        return (
          <GenericChartCard
            brandId={menu.brandId}
            startDate={startDate}
            endDate={endDate}
            cardData={data}
            cardLabel={label}
            isMobile={isMobile}
            item={item}
            color={color}
          />
        );
      default:
        return null;
    }
  };
  const renderAnalyticsData = () => {
    switch (selectedChart) {
      case "impression":
        return (
          <>
            <FullAnalyticsHeaderBoxContent
              isMobile={isMobile}
              style={{ marginRight: "1rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FullAnalyticsText>Overall Impression</FullAnalyticsText>
                <FullAnalyticsHeader sx={{ marginTop: "0.5rem" }}>
                  {impression_Data?.analyticsDaysImpression?.total}
                </FullAnalyticsHeader>
              </Box>

              <Box
                sx={{
                  width: "46px",
                  height: "0px",

                  /* Primary/French Blue/French Blue 50% */

                  border: "2px solid #80BAE7",
                  transform: "rotate(-90deg)",
                }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // paddingLeft: "5.5rem",
                }}
              >
                <FullAnalyticsText>Total Videos</FullAnalyticsText>
                <FullAnalyticsHeader
                  sx={{ fontFamily: "Switzer", marginTop: "0.5rem" }}
                >
                  {distinctVideosData?.analyticsDistinctWatchedCount.count}
                </FullAnalyticsHeader>
              </Box>
            </FullAnalyticsHeaderBoxContent>
          </>
        );
      // case "new":
      //   return (
      //     <>
      //       <FullAnalyticsHeaderBoxContent
      //         isMobile={isMobile}
      //         style={{ marginRight: "1rem" }}
      //       >
      //         <Box sx={{ display: "flex", flexDirection: "column" }}>
      //           <FullAnalyticsText>Overall Videos</FullAnalyticsText>
      //           <FullAnalyticsHeader sx={{ marginTop: "0.5rem" }}>
      //             {totalNewVideos}
      //           </FullAnalyticsHeader>
      //         </Box>
      //         <Box
      //           sx={{
      //             width: "46px",
      //             height: "0px",

      //             /* Primary/French Blue/French Blue 50% */

      //             border: "2px solid #80BAE7",
      //             transform: "rotate(-90deg)",
      //           }}
      //         ></Box>
      //         <Box
      //           sx={{
      //             display: "flex",
      //             flexDirection: "column",
      //             // paddingLeft: "5.5rem",
      //           }}
      //         >
      //           <FullAnalyticsText>Total Videos</FullAnalyticsText>
      //           <FullAnalyticsHeader
      //             sx={{ fontFamily: "Switzer", marginTop: "0.5rem" }}
      //           >
      //             {countVideosData?.countVideos}
      //           </FullAnalyticsHeader>
      //         </Box>
      //       </FullAnalyticsHeaderBoxContent>
      //     </>
      //   );
      // case "duration":
      //   return (
      //     <>
      //       <FullAnalyticsHeaderBoxContent
      //         isMobile={isMobile}
      //         style={{ marginRight: "1rem" }}
      //       >
      //         <Box sx={{ display: "flex", flexDirection: "column" }}>
      //           <FullAnalyticsText>Overall Duration</FullAnalyticsText>
      //           <FullAnalyticsHeader sx={{ marginTop: "0.5rem" }}>
      //             {durationStringOverall}
      //           </FullAnalyticsHeader>
      //         </Box>
      //         <Box
      //           sx={{
      //             width: "46px",
      //             height: "0px",

      //             /* Primary/French Blue/French Blue 50% */

      //             border: "2px solid #80BAE7",
      //             transform: "rotate(-90deg)",
      //           }}
      //         ></Box>
      //         <Box
      //           sx={{
      //             display: "flex",
      //             flexDirection: "column",
      //             // paddingLeft: "5.5rem",
      //           }}
      //         >
      //           <FullAnalyticsText>Total Videos</FullAnalyticsText>
      //           <FullAnalyticsHeader
      //             sx={{ fontFamily: "Switzer", marginTop: "0.5rem" }}
      //           >
      //             {distinctVideosData?.analyticsDistinctWatchedCount.count}
      //           </FullAnalyticsHeader>
      //         </Box>
      //       </FullAnalyticsHeaderBoxContent>
      //     </>
      //   );
      // case "rating":
      // return (
      //   <>
      //     <FullAnalyticsHeaderBoxContent
      //       isMobile={isMobile}
      //       style={{ marginRight: "1rem" }}
      //     >
      //       <Box sx={{ display: "flex", flexDirection: "column" }}>
      //         <FullAnalyticsText>Overall Rating</FullAnalyticsText>
      //         <FullAnalyticsHeader sx={{ marginTop: "0.5rem" }}>
      //           {finalNum}
      //         </FullAnalyticsHeader>
      //       </Box>
      //       <Box
      //         sx={{
      //           width: "46px",
      //           height: "0px",

      //           /* Primary/French Blue/French   Blue 50% */

      //           border: "2px solid #80BAE7",
      //           transform: "rotate(-90deg)",
      //         }}
      //       ></Box>
      //       <Box
      //         sx={{
      //           display: "flex",
      //           flexDirection: "column",
      //           // paddingLeft: "5.5rem",
      //         }}
      //       >
      //         <FullAnalyticsText>Total Videos</FullAnalyticsText>
      //         <FullAnalyticsHeader
      //           sx={{ fontFamily: "Switzer", marginTop: "0.5rem" }}
      //         >
      //           {totalNewVideos}
      //         </FullAnalyticsHeader>
      //       </Box>
      //     </FullAnalyticsHeaderBoxContent>
      //   </>
      // );
      // Add cases for other options as needed

      case "engagement":
      case "averageTime":
      case "pageVisited":
        let { label, overall } = chartOptions.find(
          (el) => el.value === selectedChart
        );
        let title = label ?? "";
        return (
          <>
            <FullAnalyticsHeaderBoxContent
              isMobile={isMobile}
              style={{ marginRight: "1rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <FullAnalyticsText>{`Overall ${title}`}</FullAnalyticsText>
                <FullAnalyticsHeader sx={{ marginTop: "0.5rem" }}>
                  {overall}
                </FullAnalyticsHeader>
              </Box>
            </FullAnalyticsHeaderBoxContent>
          </>
        );
      default:
        return null;
    }
  };
  const handleRangeChangeTwo = (dates, dateStrings) => {
    const newStartDate = dateStrings[0];
    const newEndDate = dateStrings[1];

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    dispatch(setDate({ startDate: newStartDate, endDate: newEndDate }));
    const isCustomRangeSelected =
      dateStrings[0] === dayjs().subtract(2, "day").format("YYYY-MM-DD") &&
      dateStrings[1] === dayjs().format("YYYY-MM-DD");

    setCustomRangeSelected(isCustomRangeSelected);
  };
  const presetRanges = {
    Today: [dayjs(), dayjs()],
    Yesterday: [dayjs().subtract(1, "day"), dayjs()],
    "Last 7 Days": [dayjs().subtract(6, "day"), dayjs()],
    "Last 14 Days": [dayjs().subtract(13, "day"), dayjs()],
    "Last 21 Days": [dayjs().subtract(20, "day"), dayjs()],
    "Last 30 Days": [dayjs().subtract(29, "day"), dayjs()],
    "Last 60 Days": [dayjs().subtract(59, "day"), dayjs()],
    "Last 90 Days": [dayjs().subtract(89, "day"), dayjs()],
    "Custom Range": () => [dayjs().subtract(2, "day"), dayjs()],
  };

  function getCalendarContainer(trigger) {
    return trigger.parentNode;
  }
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // changing date format
  const formattedStartDate = moment(startDate).format("MMM D, YYYY");
  const formattedEndDate = moment(endDate).format("MMM D, YYYY");

  return (
    <>
      <OverviewBox>
        <DetailsContainer>
          <Overview>Analytics</Overview>
          <DateRange style={{ width: "100%" }} />
          {/* <FlexBannerButtonFilterTwo ref={rangeRef}>
            <BannerButton
              style={{ marginRight: "0rem" }}
              data-date-picker-trigger="true"
              onClick={() => {
                setDisplay((prevDisplay) => !prevDisplay);
              }}
              className="navigation-img"
              tabIndex="0"
              autofocus
            >
              <BannerButtonText sx={{ marginLeft: "10px" }}>
                {dateText}
              </BannerButtonText>

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              {!isMobile && (
                <BannerButtonText>
                  {formattedStartDate} - {formattedEndDate}
                </BannerButtonText>
              )}

              <BannerCalender>
                <img src={Calender}></img>
              </BannerCalender>
            </BannerButton>
            <StyledRangePicker
              id={{
                start: startDate,
                end: endDate,
              }}
              value={[dayjs(startDate), dayjs(endDate)]}
              onChange={handleRangeChangeTwo}
              ranges={presetRanges}
              disabledDate={disabledDate}
              open={display}
              showToday={false}
              getCalendarContainer={getCalendarContainer}
              style={{
                width: 0,
                position: "absolute",
                zIndex: -1,
                // visibility: "hidden",
                overflow: "hidden",
              }}
              popupStyle={
                isMobile
                  ? {
                      width: "90%",
                      top: "450px",
                      left: "0",
                    }
                  : {
                      // padding: "1.5% 0",
                      padding: "0",
                      paddingTop: "20px",
                      position: "absolute",
                      width: "auto",
                      left: "auto",
                      right: "2.5%",
                      // top: `calc(${topCalendar + 10}px`,
                    }
              }
            />
          </FlexBannerButtonFilterTwo> */}
        </DetailsContainer>
      </OverviewBox>
      <SummaryAnalyticsTwo />
      <FullAnalyticsTwo>
        <Grid>
          <Grid item xs={12} md={12}>
            {/* <FullAnalyticsHeader>New Videos</FullAnalyticsHeader> */}
            <Box
              sx={{
                border: "1px solid #D4DAE1",
                borderRadius: "12px",
                padding: "0 20px",
              }}
            >
              <FullAnalyticsHeaderBox
                isMobile={isMobile}
                // background={
                //   " linear-gradient(90deg, rgba(141, 108, 255, 0.15) 50%, rgba(141, 108, 255, 0) 100%);"
                // }
              >
                <FlexBannerButtonFilter>
                  {/*             
                    <BrandAnalyticsText sx={{textDecoration:"underline"}} >10m</BrandAnalyticsText>
                    <BrandAnalyticsText>20m</BrandAnalyticsText>
                    <BrandAnalyticsText>1 day</BrandAnalyticsText>
                
                    
                    <BannerButtonBrand>Mar 16 2023</BannerButtonBrand> */}

                  <div>
                    <Select
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#007bf7" : "#D4DAE1",
                          width: "256px",
                          boxShadow: state.isFocused
                            ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)"
                            : undefined, // Border color when the Select is being hovered

                          // borderRadius: "8px",
                          borderRadius: "13px",
                          height: "44px",
                          ":hover": {
                            border: state.isFocused
                              ? "1px solid #007bf7"
                              : "1px solid #1a1b24",
                            // Add any other styles you want for the hover effect
                          },
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          borderRadius: "13px",
                          padding: "0.3em 0.4em",
                          border: "1px solid #ececf2",
                          boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "13px",
                          backgroundColor: state.isSelected
                            ? "rgba(25, 118, 210, 0.08)"
                            : state.isHovered
                            ? "#f7f7f7"
                            : "white",
                          color: "#1a1b24",
                          cursor: "pointer",
                          margin: "4px auto",
                          ":hover": {
                            backgroundColor: "#f7f7f7",
                            color: "#1a1b24",
                          },
                        }),
                      }}
                      options={chartOptions}
                      value={chartOptions.find(
                        (option) => option.value === selectedChart
                      )}
                      formatOptionLabel={(option) => (
                        <div className="option-option">
                          <img
                            src={option.image}
                            alt="option-image"
                            style={{
                              width: " 22.5px",
                              height: "22.5px",
                              margin: "4px auto",
                            }}
                          />
                          <span
                            style={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: "16px",
                              lineHeight: "20px",
                              marginLeft: "25px",
                            }}
                          >
                            {option.label}
                          </span>
                        </div>
                      )}
                      onChange={handleChartChange}
                    />
                  </div>
                </FlexBannerButtonFilter>
                {renderAnalyticsData()}
              </FullAnalyticsHeaderBox>
              {/* <ChartCard brandId={menu.brandId} startDate={startDate} endDate={endDate} /> */}
              <div>{renderChartComponent()}</div>
            </Box>
          </Grid>
        </Grid>
      </FullAnalyticsTwo>

      <FullAnalyticsTwo>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #D4DAE1",

                borderRadius: "12px",
                marginTop: "1rem",
                position: "relative",
                padding: "2rem",
                height: " 290px",
              }}
            >
              <FullAnalyticsHeader>Countries</FullAnalyticsHeader>
              {data?.length > 0 ? (
                <>
                  <Grid
                    container
                    spacing={2}
                    sx={{ width: "100%", margin: "0 auto", columnGap: "35px" }}
                  >
                    {data?.map((d, i) => (
                      <>
                        <Country key={i} {...d} isMobile={isMobile} />
                      </>
                    ))}
                  </Grid>
                  {/* <SeeMore>
                    <SeeMoreText>See More</SeeMoreText>
                  </SeeMore> */}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1.5rem",
                  }}
                >
                  <img src={NoCountry} alt="no-country" />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ marginBottom: isMobile && "40px" }}>
            <Box
              sx={{
                border: "1px solid #D4DAE1",

                borderRadius: "12px",
                marginTop: "1rem",
                position: "relative",
                padding: "2rem",
                height: " 290px",
              }}
            >
              <FullAnalyticsHeader>Devices</FullAnalyticsHeader>
              {dataTwo?.length > 0 ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    justifyContent:
                      dataTwo?.length === 2 ? "center" : "space-between",
                    display: "flex",
                    margin: "0 auto",
                    height: "142px",
                    alignItems: "center",
                    background: "#F7FBEF",
                    borderRadius: " 8px",
                    padding: "0 15px",
                    marginTop: "2.1rem",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  {dataTwo?.map((d, i) => (
                    <>
                      <Device key={i} {...d} isMobile={isMobile} index={i} />
                      {/* <SeeMore>
                      <SeeMoreText>See More</SeeMoreText>
                    </SeeMore> */}
                    </>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1.5rem",
                  }}
                >
                  <img src={NoDevice} alt="no-device" />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </FullAnalyticsTwo>
    </>
  );
}

export default BrandAnalytics;
