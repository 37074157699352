import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Tab, Tabs } from "@mui/material";

//------website listpage-------------
export const BuilderContainer = styled(Box)`
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 100px;
`;

export const BoxCh = styled("div")`
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #2349b6;
  flex-direction: column;
`;

export const CreateButton = styled(Button)`
  height: 40px;
  ${({ color }) =>
    color === "primary"
      ? "background-color: #0c97d1;"
      : `background-color: white;`}
  border-radius: 5px;
  box-sizing: border-box;
  padding: 9px 24px;
  text-transform: capitalize;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  ${(props) => props.hasBorder && " border: #101010 .125rem solid;"}
  ${({ color }) => (color === "primary" ? "color: white;" : `color: #101010;`)}
  &:hover {
    ${({ color }) =>
      color === "primary"
        ? "background-color: #0c97d1;"
        : `background-color: white;`}
    ${({ color }) =>
      color === "primary" ? "color: white;" : `color: #101010;`}
  }
`;

//-------------main builder--------------------

export const PageListStyle = styled(Box)`
  margin-top: 40px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #363636;
  }
  .title {
    display: flex;
    align-items: center;
    font-family: "Dm Sans";
    font-style: normal;
    color: white;
    font-size: 0.875rem;
    margin-bottom: 0.65rem;
  }
  .items {
    display: flex;
    justify-content: space-between;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 0.375rem;
    padding: 9px 24px;
    text-transform: capitalize;
    font-family: "Dm Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    &.active {
      background-color: #333;
    }
    :hover {
      background-color: #333;
    }
  }
`;

export const SideBarContentBox = styled(Box)`
  font-family: "Dm Sans";
  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;
  .title_nav {
    padding: 10px;
  }
  .main_content {
    flex: 1;
    overflow-y: auto;
    /* padding: 10px; */
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #474747;
    }
  }
`;

export const AntTabs = styled(Tabs)`
  /* border-bottom: 1px solid #e8e8e8; */
  margin-top: 1.25rem;
  .MuiTabs-flexContainer {
    width: 100%;
  }
  width: 100% !important;
  & .MuiTabs-indicator {
    background-color: #3a3a3a;
  }
`;

export const AntTab = styled(Tab)`
  text-transform: none;
  color: #505050;
  min-width: 50%;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    min-width: "50%";
  }
  margin-right: ${({ theme }) => theme.spacing(1)};
  /* color: rgba(0, 0, 0, 0.85); */
  font-family: "Dm Sans";
  font-size: 0.875rem;
  &:hover {
    color: #fff;
    opacity: 1;
  }
  &.Mui-selected {
    color: white;
  }
  &.Mui-focusVisible {
    background-color: #d1eaff;
  }
`;

export const CustomTabPanel = styled(Box)`
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  #block{
    position:relative;
  }
  #block{
    position:relative;
  }
`;

export const LayerBlocksContainer = styled(Box)`
  overflow-y: scroll;
`;

export const BuilderNav = styled(Box)`
  background-color: #101010;
  height: 60px;
  padding: 0 1.25rem;
  display: flex;
  font-family: "Dm Sans";
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
`;

export const SliderStyle = styled(Box)`
  font-family: "Dm Sans";
  width: 250px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(100vh - 60px);
  .slider_content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #474747;
    }
    /* display:${({ show }) => (show ? "block" : "none")}; */
    background-color: #101010;
    .trait-container{
      position: relative;
    }
    .styles-container{
      position: relative;
    }
  }
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    ${({ show }) => (show ? "" : "reverse")} both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    ${({ show }) => (show ? "" : "reverse")} both;
  /* transform: translateX(-100%); */
  .close_btn_area {
    position: relative;
    height: 0;
    overflow: visible;
  }
`;

export const StyledAuthButton = styled(Button)`
  background: #070b0d;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  // border-radius: 7px;
  border-radius: 15px;
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  height: 46px;
  font-family: Dm Sans !important;
  &:hover {
    background: #1a1b24;
  }
`;

export const TextInputContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledFormLabel = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  @media (max-width: 900px) {
  }
`;

export const ModalContainer = styled(Box)`
  border-radius: 8px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  padding: 1rem;
  transform: translate(-50%, -50%);
`;
