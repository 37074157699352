import React, { useState, useEffect } from "react";

const LeavePagePopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Standard for most browsers
      alert(message);
      return message; // For some older browsers
    };

    sessionStorage.getItem("uploadProgressFlag") === "1" &&
      window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [sessionStorage.getItem("uploadProgressFlag")]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div style={{ display: showPopup ? "block" : "none" }}>
      <div className="popup-content">
        <p>Are you sure you want to leave?</p>
        <button onClick={handleClosePopup}>Stay</button>
      </div>
    </div>
  );
};

export default LeavePagePopup;
