import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const StyledWidget = styled(Box)`
  width: 45.79px;
  height: 45.79px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(props) =>
    props.bordercolor && props.border
      ? props.border + "px solid " + props?.bordercolor
      : "none"};
  background-color: ${(props) =>
    props.backgroundcolor ? props.backgroundcolor : "#000000"};
`;

export const StyledRowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const StyledButton = styled(Button)`
  text-transform: capitalize;
  background-color: ${(props) =>
    props.selectedcolor ? props.selectedcolor : "red"};
`;
export const StyledFormLabel = styled(Typography)`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
`;
