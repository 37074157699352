import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CTextfield from "../../components/CTextfield";

export const PContainer = styled(Box)`
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const PSect = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
export const PLabel = styled(Box)`
  color: #1a1b24;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  align-self: stretch;
`;
export const PTextField = styled(CTextfield)`
  width: 100%;
  border: none;
  background: #f9f9f9;

  .MuiOutlinedInput-notchedOutline {
    // border: 1px solid #ececf2;
  }
`;

export const MiniDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;
