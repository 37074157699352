import React from 'react'
import { MarketingSideBarContainer } from './styled'
import logoWhite from "../../assets/images/logo-white.svg"
import { Box } from '@mui/material'

const index = ({children,sxStyle}) => {
  return (
    <MarketingSideBarContainer sx={sxStyle}>
        <div className='content'>
          <Box sx={{width:"100%",pt:4,display:"flex", justifyContent:"center"}}>
          <img src={logoWhite} style={{height:40,marginBottom:50}}/>            
          </Box>

            {children}
        </div>
    </MarketingSideBarContainer>
  )
}

export default index