import React from "react";
import { DisplayDiv, DisplayDivTitle, EmptyDiv } from "./styled";
import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.js";
import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.js";
import EmptyWidget from "../../assets/widgetIcon/EmptyStateWidget.svg";
import CloseIcon from "../../assets/images/Close.svg";
import { Grid } from "@mui/material";
export default (widgetState, isMobile, bodyTagObject, setModalOpen) => {
  const justifyPosition = () => {
    switch (widgetState?.position) {
      case "bottom-right":
        return "flex-end";
      default:
        return "flex-start";
    }
  };

  let buttonContent =
    widgetState?.widgetMeetSalesText &&
    String(widgetState?.widgetMeetSalesText).trim() !== ""
      ? widgetState?.widgetMeetSalesText
      : "Contact sales";

  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("widget") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0, ...bodyTagObject }}>
        <DisplayDiv
          right
          isMobile={isMobile}
          preview
          // style={{ padding: "32px" }}
        >
          <>
            <DisplayDivTitle
              isMobile={isMobile}
              style={{ position: "relative", zIndex: 9999 }}
            >
              <div>Preview</div>
              {isMobile && (
                <div>
                  <img src={CloseIcon} onClick={() => setModalOpen(false)} />
                </div>
              )}
            </DisplayDivTitle>

            {widgetState?.widgetVisibility ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: justifyPosition(),
                  alignItems: "flex-end",
                  height: "calc(100% - 48px )",
                }}
              >
                {widgetState?.position === "bottom-right" ? (
                  <WidgetDisplay
                    leaveReview={widgetState?.widgetLeaveReview}
                    leaveReviewText={widgetState?.widgetLeaveReviewText}
                    rating={widgetState?.widgetRating}
                    buttonColor={widgetState?.buttonColor}
                    widgetMeetSalesText={buttonContent}
                  />
                ) : (
                  <WidgetDisplay
                    leaveReview={widgetState?.widgetLeaveReview}
                    leaveReviewText={widgetState?.widgetLeaveReviewText}
                    rating={widgetState?.widgetRating}
                    buttonColor={widgetState?.buttonColor}
                    widgetMeetSalesText={buttonContent}
                  />
                )}
              </div>
            ) : (
              <EmptyDiv>
                <img src={EmptyWidget} />
              </EmptyDiv>
            )}
          </>
        </DisplayDiv>
      </Grid>
    )
  );
};
