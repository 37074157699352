import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { StyledFormLabel } from "../../pages/Signup/styled";
import GoogleIcons from "../../assets/icons/GoogleIcons.svg";
import { gapi } from "gapi-script";
import { setUser } from "../../redux/auth.slice";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { GOOGLE_LOGIN } from "../../graphql/mutations/auth";

/**
 * GoogleAuthButton is a component that allows users to sign in using Google.
 * @component
 * @param {string} type - The type of Google sign-in button (e.g., "Sign in with Google").
 * @param {string} invitationId - The invitation ID if applicable.
 * @param {function} setSuccess - A callback function to set a success state.
 * @returns {JSX.Element}
 */
function GoogleAuthButton({ type, invitationId, setSuccess }) {
  const [nFocus, setNFocus] = useState(false);
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const dispatch = useDispatch();
  const [googleAuth, { data }] = useMutation(GOOGLE_LOGIN);

  useEffect(() => {
    // Initialize Google API client
    function init() {
      gapi.load("auth2", () => {
        gapi.auth2.init({
          client_id: secretKey,
        });
      });
    }

    init();
  }, [secretKey]);

  /**
   * Handle the Google sign-in success callback.
   * @param {Object} googleUser - Google user object.
   */
  const onSuccess = async (googleUser) => {
    try {
      const idToken = googleUser.getAuthResponse().id_token;
      const response = await googleAuth({
        variables: { input: { idToken, invitationId } },
      });

      if (response.data && response.data.googleAuth) {
        dispatch(setUser(response.data.googleAuth));
        setSuccess && setSuccess(true);
      }
    } catch (error) {
      console.error("Google sign-in failed.", error);
    }
  };

  /**
   * Handle Google sign-in error.
   * @param {Object} err - Error object.
   */
  const googleError = (err) => {
    console.error("Google sign-in failed.", err);
  };

  // Google auth button styling-------
  const buttonStyle = {
    width: "100%",
    background: "#ffffff",
    border: nFocus ? "1px solid #007bf7" : "1px solid #D4DAE1",
    boxShadow: nFocus ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40)" : undefined,
    // border: nFocus ? :"1px solid #c1c7ca",
    borderRadius: "15px",
    textTransform: "capitalize",
    fontWeight: 500,
    fontSize: "10px",
    letterSpacing: "-0.02em",
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 48,
    padding: 10,
  };

  return (
    <div
      onMouseEnter={() => setNFocus(true)}
      onMouseLeave={() => setNFocus(false)}
      style={{ width: "100%" }}
    >
      <GoogleLogin
        clientId={secretKey}
        render={(renderProps) => (
          <button onClick={renderProps.onClick} style={buttonStyle}>
            <img src={GoogleIcons} alt="google icon" />
            <StyledFormLabel sx={{ marginLeft: 2 }}>{type}</StyledFormLabel>
          </button>
        )}
        onSuccess={onSuccess}
        onFailure={googleError}
      />
    </div>
  );
}

export default GoogleAuthButton;
