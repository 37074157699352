import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import Camera from "../../../assets/icons/Camera.svg";
import Play from "../../../assets/images/play.svg";
import CloseCamera from "../../../assets/icons/CloseCamera.svg";
import PauseIcon from "../../../assets/images/PauseImage.svg";
import ColorButton from "../../ColorButton";
import Control from "../../Control";
let count = 0;
const PlayRecord = ({
  url,
  closeModal,
  setStRecord,
  setNewStep,
  setCounterStart,
  setStartViewing,
  startViewing,
  style = {},
  isMobile,
  playingRecentVideo,
  setOpenCam,
}) => {
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const [videoState, setVideoState] = useState({
    playing: playingRecentVideo,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  // console.log("playingRecentVideo", playingRecentVideo);
  const [play, setPlay] = useState(playingRecentVideo);
  const { playing, muted, volume, playbackRate, played, seeking, buffer } =
    videoState;
  // console.log("videoState", videoState);
  const videoPlay = () => setPlay(!play);
  const videoStyle = {
    cursor: "pointer",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    transform: !isMobile && "scaleX(-1)", // Apply horizontal flip
    ...style, // Include additional styles passed via props
  };

  const togglePlayPause = () => {
    setPlay(!play);
    setVideoState({ ...videoState, playing: !videoState.playing });
    setStartViewing(startViewing);
  };
  const handleContinue = () => {
    setNewStep(1);
  };
  const startRecording = () => {
    setStRecord(true);
    setCounterStart(3);
    setStartViewing(true);
  };
  const seekHandler = (e, value) => {
    // console.log("Aplayy", parseFloat(value / 100));
    // console.log("hello");
    setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoPlayerRef.current.seekTo(parseFloat(value / 100));
  };
  const seekMouseUpHandler = (e, value) => {
    // console.log(value);

    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };
  const onSeekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const playPauseHandler = () => {
    //plays and pause the video (toggling)
    setPlay(!play);
    setVideoState({ ...videoState, playing: !videoState.playing });
  };
  const progressHandler = (state) => {
    try {
      if (count > 3) {
        // console.log("close");
        controlRef.current.style.visibility = "hidden"; // toggling player control container
      } else if (controlRef.current.style.visibility === "visible") {
        count += 1;
      }

      if (!seeking) {
        setVideoState({ ...videoState, ...state });
      }
    } catch {}
  };
  const bufferStartHandler = () => {
    // console.log("Bufering.......");
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    // console.log("buffering stoped ,,,,,,play");
    setVideoState({ ...videoState, buffer: false });
  };

  return (
    <div style={{ width: "100%", height: "90%" }}>
      <ReactPlayer
        ref={videoPlayerRef}
        className="custom-player-play"
        url={url}
        playing={playing}
        width="100%"
        controls={false}
        height="100%"
        onEnded={() => {
          setStRecord();
          playPauseHandler();
        }}
        onProgress={progressHandler}
        onBuffer={bufferStartHandler}
        onBufferEnd={bufferEndHandler}
      />
      {/* Button or overlay to toggle play/pause */}
      <div
        onClick={playPauseHandler}
        style={{
          position: "absolute",
          top: isMobile ? "50%" : "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // zIndex: 2,
          width: isMobile ? "35px" : "auto",
          height: isMobile ? "35px" : "auto",

          // color: "white",
          color: "transparent",
          border: "none",

          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <img
          src={play ? PauseIcon : Play}
          style={{ width: "100%" }}
          alt="controls"
        />
      </div>
      <div
        onClick={() => {
          closeModal();
          setOpenCam(false);
        }}
        style={{
          position: "absolute",
          top: isMobile ? "3%" : "10%",
          left: "3%",
          // transform: "translate(-50%, -50%)",
          zIndex: 2,
          width: isMobile ? "44px" : "auto",
          height: isMobile ? "44px" : "auto",

          color: "white",
          border: "none",

          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        <img src={CloseCamera} style={{ width: "100%" }} alt="controls" />
      </div>
      {/* {!play && (
        <div
          style={{
            position: "absolute",
            bottom: isMobile ? "15%" : "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,

            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            cursor: "pointer",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <ColorButton
            background={"#1A1B24"}
            title="Record Again"
            width={isMobile ? "60%" : "34%"}
            isMobile={isMobile}
            image={Camera}
            onClick={startRecording}
          />
        </div>
      )} */}
    </div>
  );
};

export default PlayRecord;
