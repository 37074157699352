import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
// Remove the import statement for PropTypes since it is already imported below
// import PropTypes from "prop-types";
// Remove the import statement for Box since it is already imported above
// import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_PENDING_INVITATION } from "../../graphql/queries/invitation";
import InvitationsTable from "../../components/InvitationsTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tab-panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function InvitationRequest() {
  const [invitations, setInvitations] = React.useState([]);
  const { data } = useQuery(GET_PENDING_INVITATION);

  React.useEffect(() => {
    if (data?.pendingInvites) {
      setInvitations(data?.pendingInvites);
    }
  }, [data?.pendingInvites]);

  return (
    <Box sx={{ width: "100%" }}>
      <InvitationsTable invitations={invitations ? invitations : []} />
    </Box>
  );
}