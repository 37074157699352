import React from "react";
import TestimonialRecord from "../../components/Testimonial";
import { Link, useParams } from "react-router-dom";
import { HeaderBox } from "./styled";

import NewArrow from "../../assets/icons/NewArrow.svg";
import TestimonialVectorTwo from "../../assets/icons/TestimonialVectorTwo.svg";
import { VectorTwo } from "../../components/Testimonial/ReviewComp/styled";
import { TabTitle } from "../../utils/HeaderTitle";
import SubHeader from "../../components/SubHeader";

function Testimonial() {
  const { id } = useParams();

  TabTitle(`Convert - Recrowdly || Testimonial`);
  return (
    <>
      <HeaderBox style={{ width: "100%" }}>
        {/* <Link
          to={`${process.env.REACT_APP_CLIENT_URL}`}
          style={{ textDecoration: "none" }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={NewArrow}
              alt="testimonial-logo"
              style={{ width: "26px", height: "26px", marginLeft: "1rem" }}
            />
            <p
              style={{
                color: "#1A1B24",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                // fontWeight: "500",
                fontWeight: "bold",
                lineHeight: "normal",
                marginBottom: "0px",
              }}
            >
              Back to Dashboard
            </p>
          </div>
        </Link> */}

        <SubHeader
          arrLink={[
            { title: "Dashboard", link: "/" },
            { title: "Insight Page", link: `/brands/${id}/0/impression` },
            { title: "Request Review" },
          ]}
        />
      </HeaderBox>

      <TestimonialRecord brandId={id} />
    </>
  );
}

export default Testimonial;
