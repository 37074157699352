import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  TimeScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";
import moment from "moment";

// import moment.js date adapter for Chart.js
import "chartjs-adapter-moment";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  TimeScale,
  LinearScale,
  PointElement,
  Filler
);
function NewsletterChartCard({ isMobile,labels,datasets }) {






  const newData = {
    labels,
    datasets
  };

  const DISPLAY = false;
  const BORDER = true;
  const CHART_AREA = true;
  const TICKS = true;
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderColor: "#36A2EB",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        mode: "index",
        intersect: false,
        displayColors: true,
      },
    },
    scales: {
      x: {
        type: "time", // set x-axis scale type to 'time'
        time: {
          tooltipFormat: "ll", // set tooltip format
          unit: getUnit(labels), // determine appropriate time unit based on range of dates
          displayFormats: {
            day: "MMM D", // set display format for day units
            week: "MMM D", // set display format for week units
            month: "MMM", // set display format for month units
            quarter: "Q", // set display format for quarter units
          },
          parser: "moment", // use moment.js to parse dates
          max: moment(labels[labels.length-1]),
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          font: {
            size: 10,
          },
        },
        border: {
          display: BORDER,
        },
        grid: {
          display: DISPLAY,
          drawOnChartArea: CHART_AREA,
          drawTicks: TICKS,
        },
      },
      y: {
        beginAtZero: true, // set the y-axis minimum value to 0
        border: {
          display: false,
        },
        grid: {
          display: true,
          drawOnChartArea: CHART_AREA,
          drawTicks: TICKS,
        },
      },
    },
  };

  // remove the 'label' property from the dataset
  // newData.datasets[0].label = undefined;
  return (
    <div
      className="chart-container"
      style={{
        position: "relative",
        height: "400px",
        width: "100%",
        marginTop: isMobile ? "30px" : undefined,
      }}
    >
      <Line data={newData} options={options}></Line>
    </div>
  );
}
// helper function to determine appropriate time unit based on range of dates
function getUnit(data) {
  const firstDate = moment(data[0]);
  const lastDate = moment(data[data.length - 1]);

  const diffInDays = lastDate.diff(firstDate, "days");
  if (diffInDays >= 365) {
    return "year";
  } else if (diffInDays >= 90) {
    return "quarter";
  } else if (diffInDays >= 30) {
    return "month";
  } else if (diffInDays >= 7) {
    return "week";
  } else {
    return "day";
  }
}
export default NewsletterChartCard;
