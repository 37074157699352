import React, { useState, useRef } from "react";
import ColorButton from "../ColorButton";
import {
  DisclaimerText,
  LeftTopSubTitle,
  RequestDisclaimer,
  StepTitle,
} from "../Send-Testimonial/ReviewComp/styled";
import Play from "../../assets/images/play.svg";
import PauseIcon from "../../assets/images/PauseImage.svg";
import ReactPlayer from "react-player";
import { ProgressBar } from "./styled";
import { vidFormat } from "../../utils/format";

function SendStepOne({ videoInfo, setNewStep, setStep, isMobile, sender }) {
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const playerRef = useRef(null);

  const mobileFormValidation = () => {
    setStep(2);
    setNewStep(2);
  };

  const handlePlay = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleSeekMouseDown = (e) => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <StepTitle style={{ fontSize: "32px" }}>
          Hi {videoInfo?.name}, here is an Intro Video from {sender}!
        </StepTitle>
        <LeftTopSubTitle style={{ fontSize: "16px" }}>
          {" "}
          Watch the recording from {sender} below
        </LeftTopSubTitle>

        <div style={{ marginTop: "20px", width: "100%" }}>
          <div
            style={{
              height: "500px",
              borderRadius: "15px",
              background: "#F1F3EC",
              width: "100%",
              position: "relative",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-10px",
                zIndex: "20",
                width: "101%",
              }}
            >
              <ProgressBar
                type="range"
                min={0}
                max={1}
                step="any"
                value={played}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
              ></ProgressBar>
            </div>

            <ReactPlayer
              ref={playerRef}
              className="custom-player-play-modal"
              // url={`${process.env.REACT_APP_AWS_S3_URL_SERVING}OriginalVideo${videoInfo?.senderUrl}.mp4`}
              // url={`${process.env.REACT_APP_AWS_S3_URL_SERVING}OriginalVideo${videoInfo?.senderUrl}.mp4`}
              url={vidFormat(videoInfo?.senderUrl, "serve", videoInfo)}
              playing={playing}
              onProgress={handleProgress}
              width="100%"
              height="100%"
            />
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-58px",
                background: "transparent",
              }}
              onClick={handlePlay}
            >
              <img
                src={playing ? PauseIcon : Play}
                style={{ width: "58px", height: "58px" }}
                alt="controls"
              />
            </div>
          </div>

          {videoInfo?.openToRecord ? (
            <RequestDisclaimer>
              <DisclaimerText>
                {sender} has offered to leave a Testimonial for your business
              </DisclaimerText>
            </RequestDisclaimer>
          ) : (
            ""
          )}

          <div
            style={{
              margin: "3rem auto",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ColorButton
              title={"Record your testimonial"}
              width={isMobile ? "100%" : "70%"}
              background={"#1A1B24"}
              onClick={mobileFormValidation}
              buttonTextStyle={{ fontSize: "18px" }}
              innerStyle={{ textAlign: "center" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendStepOne;

// import React, { useState ,useRef} from "react";
// import ColorButton from "../ColorButton";
// import {
//   DisclaimerText,
//   LeftTopSubTitle,
//   RequestDisclaimer,
//   StepTitle,
// } from "../Send-Testimonial/ReviewComp/styled";
// import Play from "../../assets/images/play.svg";
// import PauseIcon from "../../assets/images/PauseImage.svg";
// import ReactPlayer from "react-player";
// import { ActionContainer2, Progress, ProgressBar } from "./styled";
// function SendStepOne({ videoInfo, setNewStep, setStep,isMobile }) {
//   const mobileFormValidation = () => {
//     setStep(2);
//     setNewStep(2);
//   };
// const [play, setPlay] = useState(false)
// const progressRef = useRef(null);
// const [pWidth, setPWidth] = useState(0);
// const [currDuration, setCurrDuration] = useState(0);
// const [fullscreen, setFullscreen] = useState(false);
// const [videoPlayedStartTime, setVideoPlayedStartTime] = useState(0);
// const [videoPausedTime, setVideoPausedTime] = useState(0);
// const [videoPausedStartTime, setVideoPausedStartTime] = useState(0);
// const videoRef = useRef(null);
//   const topRef = useRef(null);
// const handlePlay = (e) => {
//   e.stopPropagation();
//     // setAutoPlayState(false);//was to attend to autoPlay
//     if (play && typeof play === "boolean") {
//       // videoRef.current.pause();

//       setPlay("pause");
//       setVideoPausedStartTime(new Date().getTime());
//     } else {
//       // videoRef.current.play();
//       console.log(play, "playing");
//       if (play === "pause") {
//         let pauseTime =
//           videoPausedTime + new Date().getTime() - videoPausedStartTime;
//         console.log(pauseTime, "pause");
//         setVideoPausedTime(pauseTime);
//       }
//       setPlay(true);

//       // setVideoPausedStartTime(
//       //   videoPausedStartTime + new Date().getTime() - videoPausedTime
//       // );
//     }
// }

// const handleClick = (event) => {
//   event.stopPropagation();

//   let rem = 0;
//   //brandProps?.position === "bottom-left" ? 83 : window.innerWidth - 367;
//   rem = 0;
//   const progressDiv = progressRef.current;
//   const topRefDiv = topRef.current;
//   rem = window.innerWidth - event.clientX;
//   // const clickPosition = event.clientX - progressDiv.offsetLeft - rem;

//   const progressBarWidth = progressDiv.offsetWidth;
//   const topRefWidth = topRefDiv.offsetWidth;
//   // const clickPosition =
//   //   progressBarWidth - rem + (topRefWidth - progressBarWidth) / 2;
//   let righthandsideRem =
//     window.innerWidth - topRefDiv.getBoundingClientRect().right;
//   let clickPosition =
//     progressBarWidth -
//     (rem - righthandsideRem - (topRefWidth - progressBarWidth) / 2);
//   // console.log(
//   //   clickPosition,
//   //   "clickposition",
//   //   event.clientX,
//   //   "clientX",
//   //   progressDiv.offsetLeft,
//   //   "offsetleft",
//   //   topRefWidth,
//   //   "toprefwidth",
//   //   progressBarWidth,
//   //   "progressbarwidth",
//   //   window.innerWidth,
//   //   "windowinnerwidth",
//   //   righthandsideRem,
//   //   "rightside remaining"
//   // );
//   const clickPercentage = (clickPosition / progressBarWidth) * 100;

//   let playTime = (clickPercentage / 100) * videoInfo?.duration;
//   videoRef?.current?.seekTo(playTime, "seconds");
//   // videoRef?.current?.play();
//   setPWidth(clickPercentage > 100 ? 100 : clickPercentage);
// };

// console.log(play,'playing')
//   return (
//     <div style={{ width: "100%", height: "100%" }}>
//       <div
//         style={{
//           width: "100%",
//           height: "100%",
//           margin: "0 auto",
//           display: "flex",
//           flexDirection: "column",
//           gap: "5px",
//         }}
//       >
//         {/* <div style={{ width: "100%",margin:"40px 0px" }}>
//           <img src={Logo} alt="image" />
//         </div> */}

//         <StepTitle>
//           Hi {videoInfo?.name}, here is an Intro Video from Greg!
//         </StepTitle>
//         <LeftTopSubTitle> Watch the recording from Greg below</LeftTopSubTitle>

//         <div style={{ marginTop: "20px", width: "100%",}}        ref={topRef}>
//           <div
//             style={{
//               height: "500px",
//               borderRadius: "15px",
//               background: "#F1F3EC",
//               width: "100%",
//               position:"relative",
//               display:"flex",alignItems:"center"
//             }}
//           >
//             <ActionContainer2 fullscreen={fullscreen} isMobile={isMobile}>
//                   <ProgressBar
//                     onClick={handleClick}
//                     fullscreen={fullscreen}
//                     ref={progressRef}
//                   >
//                     <Progress fullscreen={fullscreen} width={pWidth} />
//                   </ProgressBar>
//                   </ActionContainer2>
//             <ReactPlayer
//               className="custom-player-play-modal"
//               url={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Video${videoInfo?.senderUrl}.mp4`}
//               playing={play}
//               width="100%"
//               onEnded={() => {
//                 setPWidth(100);

//               }}
//               // controls={true}
//               height="100%"
//             />
//             <div style={{position:"absolute",display:"flex",justifyContent:"center",width:"100%" }} onClick={handlePlay}>
//             <img
//           src={play ? PauseIcon : Play}
//           style={{ width: "58px",height:"58px" }}
//           alt="controls"
//         />
//         </div>
//             </div>

//             {videoInfo?.openToRecord ? (
//               <RequestDisclaimer>
//                 <DisclaimerText>
//                   Greg has offered to leave a Testimonial for your business
//                 </DisclaimerText>
//               </RequestDisclaimer>
//             ) : (
//               ""
//             )}

//             <div style={{ margin: "3rem auto", width: "100%",display:"flex",justifyContent:"center" }}>
//               <ColorButton
//                 title={"Record your testimonial"}
//                 width="70%"
//                 background={"#1A1B24"}
//                 onClick={mobileFormValidation}
//               />
//             </div>

//         </div>
//       </div>
//     </div>
//   );
// }

// export default SendStepOne;
