import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import axios from "axios";
import iconcreditcardcvc from "../../assets/icons/iconcreditcardcvc.svg";
import iconcreditcardamex from "../../assets/icons/iconcreditcardamex.svg";
import iconcreditcardvisa from "../../assets/icons/iconcreditcardvisa.svg";
import iconcreditcardmastercard from "../../assets/icons/iconcreditcardmastercard.svg";
import {
  CreditCardBrands,
  CreditCardCvc,
  UContButton,
  UContText,
  UContainer,
  UContainerInner,
  UFooter,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { updateCardInfo } from "../../redux/card.slice";
import { StyledFrame } from "./styled";
import DOMPurify from "dompurify";
import { UPDATE_CARD_DETAILS } from "../../graphql/mutations/payment";
import { encryptObject } from "../../utils/encrDecr";
import { useSnackbar } from "notistack";

import { useMutation } from "@apollo/client";
import {
  FooterS,
  FooterS1_1,
  FooterS1_1L,
  Input2,
} from "../PaymentComp/styled";
import PayComp from "../../pages/Pay/payComp";
import ColorButton from "../ColorButton";
import { setUser } from "../../redux/auth.slice";
import { identifyCardType } from "../../utils/format";

export default function UpdatePayment() {
  const [country, setCountry] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const profileData = useSelector((state) => state.auth);

  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiration, setExpiration] = useState("");

  const [cardData, setCardData] = useState({
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const [cardHolderName, setCardHolderName] = useState("");
  const [mouseState, setMouseState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");
  const navigate = useNavigate();
  const [updateCard] = useMutation(UPDATE_CARD_DETAILS);

  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    try {
      event?.preventDefault();
      setIsLoading(true);
      let token = "";
      let paymentData = {
        cardNumber: Number(cardData?.number),
        cardMonth: Number(cardData?.exp_month),
        cardExpYear: Number(cardData?.exp_year),
        cardCVC: Number(cardData?.cvc),
        cardHolderName,
        currency: "usd",
      };

      if (error) {
        console.error("Stripe token creation error:", error);

        enqueueSnackbar(`Card Update failed`, {
          variant: "error",
        });
      } else {
        const { data } = await updateCard({
          variables: { input: encryptObject(paymentData) },
        });

        if (data && data?.updateCardDetails?._id) {
          ///can later add to user account
          // console.log("Card info updated successfully");
          enqueueSnackbar("Card info updated successfully", {
            variant: "success",
          });

          dispatch(setUser(data.updateCardDetails));

          navigate("/billingplans");
        } else {
          console.error("Error updating card info:");

          enqueueSnackbar(
            "An error occurred while updating the card info. Please try again later.",
            {
              variant: "error",
            }
          );
        }
      }
    } catch (err) {
      // console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UContainer>
      <UContainerInner>
        <UContText>Add a payment method</UContText>
        <PayComp
          setError={setError}
          cardData={cardData}
          setCardData={setCardData}
          cardHolderName={cardHolderName}
          setCardHolderName={setCardHolderName}
          expiration={expiration}
          setExpiration={setExpiration}
          country={country}
          setCountry={setCountry}
        />
        <UContButton>
          <ColorButton
            title={"Cancel"}
            background={"#F1F3EC"}
            width={"45%"}
            onClick={() => navigate("/billingplans")}
            disabled={isLoading}
            onMouseLeave={() => setMouseState({ ...mouseState, cancel: false })}
            onMouseEnter={() => setMouseState({ ...mouseState, cancel: true })}
            hoverBackgroundColor="#F1F3EC"
            activeBgColor={"#F1F3EC"}
            activeBorderColor={"#007bf7"}
            innerStyle={{
              border: mouseState?.cancel
                ? "1px solid #1a1b24"
                : "1px solid #DAE1DD",
            }}
          />
          <ColorButton
            title={isLoading ? <CircularProgress size={24} /> : "Save"}
            background={"#1A1B24"}
            width={"45%"}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </UContButton>
        <UFooter>
          <FooterS1_1 style={{ textAlign: "center", width: "100%" }}>
            This site is protected by reCAPTCHA and the Google{" "}
            <FooterS1_1L>Privacy Policy</FooterS1_1L> and
            <FooterS1_1L>Terms of service</FooterS1_1L> apply. By continuing, I
            warrant that this location information is accurate.
          </FooterS1_1>
        </UFooter>
      </UContainerInner>
    </UContainer>
  );
}
