import styled from "@emotion/styled";
import { Image } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const Title = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #1a1b24;
  margin-left: 1.7em;
`;

export const VTitle = styled(Box)`
  margin-bottom: 5px;
  // margin-top: 5px;
  width: 100%;
  // padding: 5px;
  padding: 10px;
  cursor: pointer;
  background: ${(props) => props?.active && "#f7f7f7"};
  border-radius: ${(props) => props?.active && "8px"};
  &:hover {
    background: #f7f7f7;
    border-radius: 8px;
  }
`;

export const LabelContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${(props) => !props?.specialIcon && "2px"};
`;
export const LabelIconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -12px;
  width: 80%;
  padding-left: 5px;
  // overflow: hidden;
  border-radius: 6px;
`;

export const LabelTextContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  // align-items: center;
  margin-top: ${(props) => !props?.specialIcon && "2px"};
  overflow: scroll;
`;

export const ApplyContainer = styled(Box)`
  display: flex;
  justify-content: ${(props) =>
    props?.isMobile ? "space-between" : "flex-end"};
  align-items: ${(props) => (props?.isMobile ? "center" : "flex-end")};
  padding: ${(props) => (props?.isMobile ? "16px 0" : "0.6em 0em")};
`;

export const VTitleText = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #5d6272;
  margin-bottom: 5px;
`;

export const VTitleDescription = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #858995;
`;

export const VTDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const VTitleCustDescription = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #858995;
  border-bottom: 1px solid #ececf2;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-bottom: 10px;
`;

export const CustomizeDiv = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #0075ce;
  margin-bottom: 5px;

  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0px;
`;

export const JustDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileDivTitle = styled.div`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
`;
