import { gql } from "@apollo/client";

export const SEND_EMAIL = gql`
  mutation SendEmail(
    $recipientEmails: [String!]!
    $subject: String!
    $body: String!
    $emailTag: String!
    $fromEmail: String!
    $brand: String!
    $recipientType: String!
    $leadGroupId: String!
  ) {
    sendEmail(
      recipientEmails: $recipientEmails
      subject: $subject
      body: $body
      emailTag: $emailTag
      fromEmail: $fromEmail
      brand: $brand
      recipientType: $recipientType
      leadGroupId: $leadGroupId
    )
  }
`;
