import React, { useState, useEffect } from "react";
import {
  CreateButton,
  ModalContainer,
  StyledAuthButton,
  StyledFormLabel,
  TextInputContainer,
  TitleText,
} from "./styled";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import WorkflowCard from "../WorkflowCard";
import { nanoid } from "nanoid";
import TagsModal from "../TagsModal";
import { useMutation, useQuery } from "@apollo/client";
import { ADD_NEW_WORKFLOW } from "../../graphql/mutations/workflowMutations";
import { GET_ALL_WORKFLOWS } from "../../graphql/queries/workflowQueries";
import { GET_BRAND_TAGS } from "../../graphql/queries/brandQueries";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";

const WorkflowList = ({ br_id, brandId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createWorkflow] = useMutation(ADD_NEW_WORKFLOW);
  const { data: fetchedTagsData } = useQuery(GET_BRAND_TAGS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      input: { _id: br_id },
    },
  });
  const tagsList =
    fetchedTagsData?.brand?.tags?.map(({ id, name, description }) => ({
      id,
      name,
      description,
    })) ?? [];

  const { data, loading: loadingData } = useQuery(GET_ALL_WORKFLOWS, {
    variables: {
      input: { brand: br_id },
    },
  });
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();
  // const list = getPagesFromStorage();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  // console.log({ errors });

  useEffect(() => {
    // console.log("data", data);
    if (data && data?.workflows instanceof Array) {
      setList(data?.workflows);
    }
  }, [data]);

  const onSubmit = async (values) => {
    try {
      // console.log(values);
      if (!values instanceof Object) {
        enqueueSnackbar(`Page Creation failed...`, {
          variant: "error",
        });
        return;
      }

      let { data, errors } = await createWorkflow({
        variables: { input: { ...values, brand: br_id } },
      });
      // console.log("this is the data", data);
      if (!data && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) ||
            `Error creating Workflow`,
          {
            variant: "error",
          }
        );

        return;
      }

      navigate("/workflow/" + data.addNewWorkflow?._id);
    } catch (err) {
      enqueueSnackbar(`Page Creation failed ${err}`, {
        variant: "error",
      });
    }
    //this is where you can create new page

    // const storageArray = getPagesFromStorage();
    // const newFile = {
    //   id: nanoid(),
    //   name: data.name,
    //   domain: data.domain,
    //   header: data.header,
    // };
    // savePagesToStorage([...storageArray, newFile]);
  };

  return (
    <Box sx={{ minHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleText sx={{ my: 3 }}>My Workflows</TitleText>
        <Box sx={{ display: "flex" }}>
          <CreateButton
            color="primary"
            sx={{ mx: 2, background: "#1a1b24" }}
            onClick={() => setOpenModal(true)}
            startIcon={<LocalOfferRoundedIcon />}
          >
            Tags
          </CreateButton>
          <CreateButton hasBorder onClick={handleOpen} startIcon={<AddIcon />}>
            Create Workflow
          </CreateButton>
        </Box>
      </Box>
      {loadingData ? (
        "Loading ....."
      ) : list?.length ? (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {list.map((x) => (
            <Box sx={{ mr: 2 }}>
              <WorkflowCard key={x._id} workflow={x} />
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ fontWeight: 700 }}
              align="center"
              id="modal-modal-title"
              variant="h5"
            >
              You have Not Created Any Workflow Yet
            </Typography>
            <CreateButton
              sx={{ mt: 2 }}
              hasBorder
              onClick={handleOpen}
              startIcon={<AddIcon />}
            >
              Create Workflow
            </CreateButton>
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={RemoveIcon}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Typography
            sx={{ fontWeight: 700 }}
            id="modal-modal-title"
            variant="h5"
          >
            Create New Workflow
          </Typography>
          <Box>
            <TextInputContainer
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              {/* Name Input */}
              <TextInputContainer>
                <StyledFormLabel sx={{ marginTop: 0.65 }}>
                  Name*
                </StyledFormLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label=""
                  placeholder="Enter Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    margin: "8px 0px 8px",
                    ".MuiInputBase-root": {
                      // height: "50px",
                      borderRadius: "15px",
                    },
                    ":hover": {
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1a1b24 !important",
                        borderWidth: "1px !important",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#007bf7",
                        borderWidth: "1px !important",
                        boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                      },
                    },
                  }}
                  {...register("name", { required: true })}
                />
              </TextInputContainer>

              {/* Sign Up Button */}
              <StyledAuthButton
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {loading ? "Loading..." : "   Create workflow"}
              </StyledAuthButton>
            </TextInputContainer>
          </Box>
        </ModalContainer>
      </Modal>
      <TagsModal
        brandId={brandId}
        br_id={br_id}
        open={openModal}
        tagsList={tagsList}
        handleClose={() => setOpenModal(false)}
      />
    </Box>
  );
};

export default WorkflowList;
