import React, {useState} from "react";

import { DELETE_ORGANIZATION } from "../graphql/mutations/organizationMutation";
import { useMutation } from "@apollo/client";
import {  GET_ORGANIZATIONS_FOR_USER } from "../graphql/queries/organizationQueries";
import { Snackbar } from "@mui/material";
import Modal from "react-modal";
import { GET_BRANDS } from "../graphql/queries/brandQueries";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


function OrganizationRow({ organization }) {
    
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const [openModal, setOpenModal] = useState(false);
    
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION, {
    
    variables: { input: { organizationId: organization.organizationId } },

    refetchQueries:[{query:GET_BRANDS}],
    update(cache, { data: { deleteOrganization } }) {
      const { organizationsForUser } = cache.readQuery({ query: GET_ORGANIZATIONS_FOR_USER });
      
      cache.writeQuery({
        query: GET_ORGANIZATIONS_FOR_USER,
        data: {
          organizationsForUser: organizationsForUser.filter((organization) => organization.organizationId !== deleteOrganization.organizationId),
        },
      });
    },
  });

  

  const handleDelete = async () => {
    try {
      await deleteOrganization();
    
        
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseSnackbar = () => {
      setOpenSnackbar(false);
    };
  
    
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleModalConfirm = () => {
    handleDelete();
    handleCloseModal();
    setOpenSnackbar(true);
    setSnackbarMessage("Organization deleted successfully");
  };
  return (
    <>
    <TableRow>
      <TableCell>{organization.name}</TableCell>
      <TableCell>{organization.domainUrl}</TableCell>
      <TableCell>{organization.industry}</TableCell>
      <TableCell>
        <div className="d-flex">
        <a className="btn btn-light " href={`/organizations/${organization.organizationId}`}>view</a>
        <button className="btn btn-danger btn-sm" onClick={handleOpenModal} >
        delete
        </button>
        </div>
      </TableCell>
    </TableRow>




    <Snackbar
     open={openSnackbar}
     onClose={handleCloseSnackbar}
     message={snackbarMessage}
     autoHideDuration={3000}
   />
    <Modal isOpen={openModal} onRequest
Close={handleCloseModal} ariaHideApp={false}>
<h2>Are you sure you want to delete this organization? </h2>
<p>This action cannot be undone. For if you delete this organization the brands associated with it will be deleted also</p>
<div className="d-flex">
<button className="btn btn-danger mr-3" onClick={handleModalConfirm}>
Confirm
</button>
<button className="btn btn-light" onClick={handleCloseModal}>
Cancel
</button>
</div>
</Modal>

    </>
  );
}

export default OrganizationRow;
