import * as React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoginPageBanner from "../../assets/images/AuthImageOne.svg";
import {
  FlexColumnContainer,
  FlexRowLinksContainer,
  StlyedHeaderSubText,
  StyledAuthButton,
  StyledDivider,
  StyledDividerText,
  StyledFormLabelText,
  StyledFormTextField,
  StyledHeaderText,
  StyledLink,
} from "./styled";
import AuthLayout from "../../components/AuthLayout";

import RecrowdlyLogo from "../../assets/images/Logo.svg";
import LoginVideo from "../../assets/icons/loginVideo.mp4";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../graphql/mutations/auth";

import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/auth.slice";
import { Alert, Box, Grid, Paper } from "@mui/material";
import GoogleAuthButton from "../../components/GoogleAuthButton/googleAuthButton";
import { queryParams } from "../../utils/Helpers";
import { TabTitle } from "../../utils/HeaderTitle";
import {
  StyledAuthContainer,
  StyledAuthLogoContainer,
  StyledSpacedBannerHousingContainer,
} from "../../components/AuthLayout/styled";
import { setWindowFlag } from "../../redux/responsive.slice";

export default function Login() {
  TabTitle("Sign In");
  const { handleSubmit, register } = useForm();
  const [login, { data, loading, error }] = useMutation(LOGIN);
  const [focusState, setFocusState] = React.useState({});
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.responsive.isMobile);

  const queryData = queryParams();

  const onSubmit = (data) => {
    login({
      variables: { input: data },
    });
  };

  React.useEffect(() => {
    if (data && data.login) {
      // console.log(data.login, "logindet");
      dispatch(setUser(data.login));
    }
  }, [data, dispatch]);
  React.useEffect(() => {
    const handleWindowResize = () => {
      // console.log(window.innerWidth);
      dispatch(setWindowFlag({ isMobile: window.innerWidth <= 450 }));
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <AuthLayout
      bannerImage={LoginPageBanner}
      headerBannerText="Our Idea is Simple"
      bannerText="Its free! Join us to get organics reviews from users worldwide"
    >
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        component={Paper}
        elevation={0}
        square
        sx={{
          // display: "flex",
          // flexDirection: "column",
          //   justifyContent: "flex-start",
          // alignItems: "flex-start",
          height: "100vh",
          width: isMobile ? "100vw" : "75%",
          margin: "0 auto",
          marginBottom: isMobile && "5%",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "90%" : "80%",
            margin: "0 auto",
            height: isMobile && "15%",
          }}
        >
          <StyledAuthLogoContainer>
            <img src={RecrowdlyLogo} alt="logo" />
          </StyledAuthLogoContainer>
        </Box>
        <StyledAuthContainer isMobile={isMobile}>
          <FlexColumnContainer sx={{ marginTop: !isMobile && "3.5em" }}>
            <StyledHeaderText>Welcome Back</StyledHeaderText>
            <StlyedHeaderSubText>
              Let us help you revolutionise testimonials and client success.
            </StlyedHeaderSubText>
          </FlexColumnContainer>

          <GoogleAuthButton
            type="Login with Google"
            invitationId={queryData?.invite_id}
          />

          <StyledDivider>
            <StyledDividerText>OR</StyledDividerText>
          </StyledDivider>
          <FlexColumnContainer
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {error && <Alert severity="error">{error?.message}</Alert>}
            <FlexColumnContainer>
              <StyledFormLabelText sx={{ margin: "8px 0px 0px" }}>
                Email*
              </StyledFormLabelText>
              <StyledFormTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label=""
                nfocus={focusState?.email}
                onFocus={() => setFocusState({ ...focusState, email: true })}
                onBlur={() => setFocusState({ ...focusState, email: false })}
                placeholder="Enter your email"
                name="email"
                autoComplete="email"
                {...register("email", { required: true })}
                autoFocus
                inputProps={{ sx: { height: 15 } }}
                sx={{
                  margin: "8px 0px 8px",
                  ".MuiInputBase-root": {
                    // height: "50px",
                    borderRadius: "15px",
                  },
                  ":hover": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1a1b24 !important",
                      borderWidth: "1px !important",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#007bf7",
                      borderWidth: "1px !important",
                      boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                    },
                  },
                }}
              />
            </FlexColumnContainer>

            <FlexColumnContainer>
              <StyledFormLabelText sx={{ margin: "8px 0px 0px" }}>
                Password*
              </StyledFormLabelText>
              <StyledFormTextField
                margin="normal"
                required
                fullWidth
                name="password"
                {...register("password", { required: true })}
                label=""
                nfocus={focusState?.password}
                onFocus={() => setFocusState({ ...focusState, password: true })}
                onBlur={() => setFocusState({ ...focusState, password: false })}
                placeholder="Enter your password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputProps={{ sx: { height: 15 } }}
                sx={{
                  margin: "8px 0px 8px",
                  ".MuiInputBase-root": {
                    // height: "50px",
                    borderRadius: "15px",
                  },
                  ":hover": {
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#1a1b24 !important",
                      borderWidth: "1px !important",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#007bf7",
                      borderWidth: "1px !important",
                      boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                    },
                  },
                }}
              />
            </FlexColumnContainer>
            <StyledLink href="/forgotPassword" variant="body2">
              Forgot Password?
            </StyledLink>
            <StyledAuthButton
              type="submit"
              fullWidth
              disabled={loading}
              variant="contained"
              sx={{ mt: 2, mb: 2, borderRadius: "15px" }}
            >
              {loading ? "Loading.." : "Login"}
            </StyledAuthButton>
          </FlexColumnContainer>
          <FlexRowLinksContainer sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{
                fontFamily: "Dm Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "-0.28px",
              }}
            >
              Don’t have an account?
            </Typography>
            <StyledLink href="/signup" variant="body2">
              Signup
            </StyledLink>
          </FlexRowLinksContainer>
        </StyledAuthContainer>
      </Grid>
      {!isMobile && (
        <Grid
          item
          container
          xs={false}
          sm={12}
          md={7}
          sx={{
            // display: "flex",
            // flexDirection: "column",
            //   justifyContent: "flex-start",
            // alignItems: "flex-start",
            height: "100%",
          }}
        >
          <StyledSpacedBannerHousingContainer>
            <video
              style={{
                width: "100%",
                height: "100vh",
                objectFit: "cover",
              }}
              autoPlay
              loop
              muted
            >
              <source src={LoginVideo} type="video/mp4" /> Your browser does not
              support the video tag.
            </video>
          </StyledSpacedBannerHousingContainer>
        </Grid>
      )}
    </AuthLayout>
  );
}
