import React, { useState, useEffect } from "react";
import LaunchComp from "./LaunchComp";
import RecordComp from "./RecordComp";
import RestComp from "./RestComp";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import ReviewComp from "./ReviewComp";
import { RestFullWrapper } from "./styled";
import axios from "axios";
import { GET_VIDEOS } from "../../graphql/queries/videosQueries";
import { SAVE_ANALYTIC } from "../../graphql/mutations/videoMutation";
import { GET_WIDGET_BRAND } from "../../graphql/queries/brandQueries";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { useDispatch } from "react-redux";
import { setWindowFlag } from "../../redux/responsive.slice";

/**
 * TestimonialRecord component to manage the testimonial recording process.
 * @param {Object} props - Component properties.
 * @param {string} props.brandId - The brand ID.
 * @param {string} props.userId - The user ID.
 */
const TestimonialRecord = ({ brandId, userId }) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [locationData, setLocationData] = useState({});
  const [timeClickedStart, setTimeClickedStart] = useState(0);
  const [timeClickedEnd, setTimeClickedEnd] = useState(0);

  // Fetch videos data using Apollo Client
  const { loading, error, data } = useQuery(GET_VIDEOS, {
    variables: { brandId: brandId },
  });

  const { data: dataBrand, loading: brandLoading } = useQuery(
    GET_WIDGET_BRAND,
    {
      variables: { input: { brandId: brandId } },
    }
  );

  const [saveAnalytic] = useMutation(SAVE_ANALYTIC, {
    onCompleted(data) {
      // console.log(data, "success");
    },
  });

  // Get a list of videos and extract the brand properties
  let brandProps = {};
  let arr =
    data?.approvedVideosByBrandIdAndTrue instanceof Array
      ? data?.approvedVideosByBrandIdAndTrue.map((el) => {
          brandProps = el?.brand ?? brandProps;
          return {
            url: el?.url,
            videoId: el?.videoId,
            brand: el?.brand,
            position: el?.position,
            name: el?.name,
            rating: el?.rating,
            duration: el?.duration,
          };
        })
      : [];

  // If there are no videos, use the brand data as brandProps
  if (
    !loading &&
    !error &&
    data?.approvedVideosByBrandIdAndTrue instanceof Array &&
    data?.approvedVideosByBrandIdAndTrue.length === 0
  ) {
    brandProps =
      dataBrand?.widgetBrand instanceof Object
        ? dataBrand?.widgetBrand
        : brandProps;
  }

  // Set some default values for brandProps
  brandProps =
    brandProps instanceof Object
      ? {
          ...brandProps,
          borderSize:
            brandProps?.borderSize && Number.parseFloat(brandProps?.borderSize),
        }
      : brandProps;

  /**
   * Get the type of the device based on the user agent.
   * @param {string} browserName - The user agent string.
   * @returns {string} - The type of device (e.g., "Mobile", "Tablet", "Desktop/Laptop").
   */
  const getDeviceType = (browserName) => {
    browserName = browserName.trim().toLowerCase();
    if (browserName.indexOf("mobile") !== -1) {
      // Device is a mobile device
      return "Mobile";
    } else if (browserName.indexOf("tablet") !== -1) {
      // Device is a tablet device
      return "Tablet";
    } else {
      // Device is a desktop/laptop
      return "Desktop/Laptop";
    }
  };

  /**
   * Get location data (IP address, country, etc.) using an external API.
   */
  const getLocationData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      // console.log(res, "location response");
      const browserName = navigator.userAgent;
      let resObj = res.data instanceof Object ? res.data : {};
      let device = getDeviceType(browserName);
      let obj = {
        browserName,
        device,
        ipAddress: resObj?.IPv4,
        country: resObj?.country_name,
        brandId: brandId,
      };
      setLocationData(obj);
    } catch (e) {
      // console.log(e);
    }
  };

  /**
   * Record the time when the recording was launched.
   */
  const getLaunchedTime = async () => {
    if (clicked === "play" && timeClickedStart === 0)
      setTimeClickedStart(new Date().getTime());
    if (clicked === undefined && timeClickedStart !== 0) {
      let endTime = new Date();
      setTimeClickedEnd(endTime.getTime());
      let resp = await saveAnalytic({
        variables: {
          input: {
            type: "ActivePeriod",
            ...locationData,
            timeLaunched: new Date(timeClickedStart),
            timeClosed: endTime,
          },
        },
      });
      // console.log("resp3", resp);
    }
  };

  /**
   * Record the time when the window was closed.
   */
  const getWindowClosedTIme = () => {
    if (clicked !== undefined && timeClickedStart > 0) {
      setTimeClickedEnd(new Date().getTime());
    }
  };

  // Add a window resize event listener to handle responsive design
  useEffect(() => {
    const handleWindowResize = () => {
      // console.log(window.innerWidth);
      dispatch(setWindowFlag({ isMobile: window.innerWidth <= 450 }));
    };
    handleWindowResize();
    getLocationData();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Record the time when the recording was launched
  useEffect(() => {
    getLaunchedTime();
  }, [clicked]);

  // Handle loading state
  if (loading) return null;

  return (
    <>
      {/* Display the ReviewComp component */}
      <ReviewComp
        brandProps={dataBrand?.widgetBrand}
        publicKey={brandId}
        setClicked={setClicked}
        userData={userId ? { userId: userId } : {}}
      />
    </>
  );
};

export default TestimonialRecord;
