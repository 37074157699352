import { TextField } from "@mui/material";
import { useState } from "react";

export default ({
  outlineStyle = {},
  rootStyle = {},
  otherSx = {},
  multiline,
  focusedFielsetStyle = {},
  ...rest
}) => {
  return (
    <TextField
      multiline={multiline}
      sx={{
        ".MuiInputBase-root": {
          height: "50px",
          borderRadius: "15px",
          ...rootStyle,
        },

        ":hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#1a1b24 !important",
            borderWidth: "1px !important",
          },
        },
        ".MuiOutlinedInput-notchedOutline": {
          ...outlineStyle,
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#007bf7",
            borderWidth: "1px !important",
            boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
            ...focusedFielsetStyle,
          },
        },

        borderRadius: "15px",
        ...otherSx,
      }}
      {...rest}
    />
  );
};
