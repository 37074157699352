import React from "react";
import Modal from "@mui/material/Modal";
import { useWorkflowContext } from "./workflowContext";
import TagModalContent from "./tagModalContent";
import ConditionalModalContent from "./conditionalModalContent";
import EmailModalContent from "./emailModalContent";
import NewsletterModalContent from "./newsletterModalContent";
import DatePickerModalContent from "./DatePickerModalContent";
import TimeDelayModalContent from "./TimeDelayModalContent";

const NodeOptionModal = ({ selected, saveNode }) => {
  const { allModals, tagsList, closeModals,newsletters } = useWorkflowContext();
  return (
    <>
      <Modal
        open={Object.keys(allModals).some((key) => allModals[key])}
        onClose={closeModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {allModals["tagged"] && !!selected && (
            <TagModalContent
              selected={selected}
              tagsList={tagsList}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}

          {allModals["ifElseCondition"] && !!selected && (
            <ConditionalModalContent
              selected={selected}
              tagsList={tagsList}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}
          {allModals["email"] && !!selected && (
            <EmailModalContent
              selected={selected}
              tagsList={tagsList}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}
          {allModals["time"] && !!selected && (
            <DatePickerModalContent
              selected={selected}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}
          {allModals["newsletter"] && !!selected && (
            <NewsletterModalContent
              selected={selected}
              newsletters={newsletters}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}
          {allModals["delay"] && !!selected && (
            <TimeDelayModalContent
              selected={selected}
              saveNode={saveNode}
              closeModals={closeModals}
            />
          )}
        </>
      </Modal>
    </>
  );
};

export default NodeOptionModal;
