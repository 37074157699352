import { gql } from "@apollo/client";
import { PAGE } from "../fragments/pageBuilder";

export const GET_WEBPAGES = gql`
  ${PAGE}
  query pages($input: FetchAllPagesInput!) {
    pages(input: $input) {
      ...PageFields
    }
  }
`;

export const GET_SINGLE_WEBPAGE = gql`
  ${PAGE}
  query page($id: String!) {
    pages(id: $id) {
      ...PageFields
    }
  }
`;

export const GET_PAGE_BY_SLUG = gql`
  ${PAGE}
  query getPageBySlug($slug: String!) {
    getPageBySlug(slug: $slug) {
      ...PageFields
    }
  }
`;
