import { Box, IconButton } from "@mui/material";

import Table from "../Table";

import { UPDATE_SALESWORKFLOW } from "../../graphql/mutations/salesApp";
import { useEffect, useState } from "react";
import { TitleText } from "../CampaignList/styled";
import AddModal from "./addModal";
import { FETCH_SALES_FIELDS, FETCH_SALES_WORKFLOW } from "../../graphql/queries/salesApp";
import { useQuery, useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../DeleteModal";
import { DELETE_SALESWORKFLOW } from "../../graphql/mutations/salesApp";
import { useSnackbar } from "notistack";
import Switch from "@mui/material/Switch";
import { CreateButton } from "./styled";
import TimeSlotModal from "../TimeSlotsModal";
import { GET_SALES_TIMESLOT } from "../../graphql/queries/timeslotQueries";
import TestMailModal from "./TestMailModal";

export default ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [formstate, setFormstate] = useState({ mailId: [] });
  const {
    data,
    loading: loadingData,
    refetch,
  } = useQuery(FETCH_SALES_WORKFLOW);
  const { data: salesLeadsFields, refetch: refetchSalesFields } =
  useQuery(FETCH_SALES_FIELDS);
  const {
    data: timeSlotData,
    loading: loadingTmeSlot,
    refetch: refetchTimeSlot,
  } = useQuery(GET_SALES_TIMESLOT);
  const [deleteSalesWorkflow] = useMutation(DELETE_SALESWORKFLOW);
  const [updateSalesWorkflow] = useMutation(UPDATE_SALESWORKFLOW);

  console.log({ timeSlotData });

  const tableHeadCells = [
    {
      id: "step",
      numeric: false,
      disablePadding: true,
      label: "Step",
      width: "20%",
    },
    {
      id: "jobCategory",
      numeric: false,
      disablePadding: true,
      label: "Job Category",
      width: "20%",
    },
    {
      id: "templatesItems",
      numeric: false,
      disablePadding: true,
      label: "Templates",
      width: "20%",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
      width: "20%",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "20%",
    },
  ];

  const reload = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const pickItem = (item) => {
    setFormstate({
      _id: item._id,
      step: item.step,
      from: item?.from || "00:00",
      to: item?.to || "23:59",
      jobCategory: item.jobCategory,
      fromEmails:item.fromEmails,
      mailId: (item.templates instanceof Array ? item.templates : []).map(
        (elm, idx) => {
          let obj = {};
          obj.id = elm._id;
          obj.fromEmail = item.fromEmails && item.fromEmails[idx];
          obj.subject = item.subjects && item.subjects[idx];
          return obj;
        }
      ),
    });
  };
  const handleEdit = (item) => {
    pickItem(item);
    setOpenModal(true);
  };

  const triggerDelete = (item) => {
    pickItem(item);
    setOpenDelModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteSalesWorkflow({
        variables: {
          _id: formstate._id,
        },
      });
      enqueueSnackbar("Deleted Successfully", {
        variant: "success",
      });
      setFormstate({ mailId: [] });
      setOpenDelModal(false);
      refetch();
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };

  const changeStatus = async (obj) => {
    try {
      await updateSalesWorkflow({
        variables: {
          input: {
            enabled: !obj.enabled,
            _id: obj._id,
            step: obj.step,
            jobCategory: obj.jobCategory,
          },
        },
      });
      enqueueSnackbar("Status changed Successfully", {
        variant: "success",
      });
      // setRerendder(Math.random());

      // setFormstate({ mailId: [] });
      // setOpenDelModal(false);
      refetch();
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };

  const getRows = () => {
    let rows = (
      data?.fetchSalesAppWorkFlow instanceof Array
        ? data?.fetchSalesAppWorkFlow
        : []
    ).map((row) => {
      let obj = { ...row };
      obj.templatesItems = (row.templates instanceof Array ? row.templates : [])
        .map((item) => item.title)
        .join(",");
      obj.actions = (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            style={{
              // position: "absolute",
              // bottom: "8px",
              // right: "40px",
              color: "black",
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => triggerDelete(row)}
            style={{
              // position: "absolute",
              // bottom: "8px",
              // right: "8px",
              color: "black",
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton
            style={{
              // position: "absolute",
              // bottom: "8px",
              // right: "48px",
              color: "black",
            }}
            title={row.enabled ? "Disable" : "Enable"}
          >
            <Switch checked={row.enabled} onClick={() => changeStatus(row)} />
          </IconButton>
        </div>
      );
      obj.status = obj.enabled ? "enabled" : "disabled";
      return obj;
    });
    rows.sort((a, b) => a.step - b.step);
    return rows;
  };
  return (
    <Box sx={{ minHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleText sx={{ my: 3 }}> Sales App Integration</TitleText>
        <Box>
        <CreateButton
            hasBorder
            sx={{
              // background: "#000000 !important",
              // color: "#fff !important",
              mr: 2,
            }}
            onClick={() => {
              setOpenTest(true);
            }}
          >
            Test Sample Mail
          </CreateButton>
          {!!timeSlotData &&<CreateButton
            hasBorder
            sx={{
              background: "#000000 !important",
              color: "#fff !important",
              mr: 2,
            }}
            onClick={() => {
              setOpenTimeSlotModal(true);
            }}
          >
            Sales TimeSlot
          </CreateButton>}
          <CreateButton
            hasBorder
            onClick={() => {
              setOpenModal(true);
              setFormstate({ mailId: [] });
            }}
          >
            Add New Step
          </CreateButton>
        </Box>
      </Box>

      {loadingData && loading ? (
        "Loading ..."
      ) : (
        <Table
          headCells={tableHeadCells}
          // rowClick={rowClick}
          rows={getRows()}
          stylehead
        />
      )}
      <TimeSlotModal
        type="pipeline"
        open={openTimeSlotModal}
        handleClose={(reload) =>{ 
          if(reload){
            refetchTimeSlot()
          }
          setOpenTimeSlotModal(false)
        }}
        timeSlotData={timeSlotData?.getSalesTimeSlot}
      />
      <AddModal
        open={openModal}
        setOpen={setOpenModal}
        formstate={formstate}
        setFormstate={setFormstate}
        refetch={reload}
      />
      <DeleteModal
        openModal={openDelModal}
        handleCloseModal={() => setOpenDelModal(false)}
        handleModalConfirm={handleDelete}
        isMobile={isMobile}
        headerText={`Delete this sales Workflow`}
        subHeaderText={`You are about to delete this workflow.`}
      />
      <TestMailModal leadsFieldData={JSON.parse(salesLeadsFields?.fetchSalesParameters?.data ?? "{}")} open={openTest} setOpen={setOpenTest}/>
    </Box>
  );
};
