import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { IS_ON_FREE_TRIAL } from "../graphql/queries/freeTrialQueries";

const RequireAuth = ({ children }) => {
  const { authenticated, userId } = useSelector((state) => state.auth);
  // const { data } = useQuery(IS_ON_FREE_TRIAL, {
  //   variables: { userId },
  //   skip: !authenticated, // Skip the query if the user is not authenticated
  // });

  let location = useLocation();
  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate
        to={location?.search ? `/login${location?.search}` : "/login"}
        state={{ from: location }}
        replace
      />
    );
  }

  // if (data && !data.isOnFreeTrial) {
  //   return <Navigate to="/pay" replace />;
  // }

  return <>{children}</>;
};

export default RequireAuth;
