import ReactDOM from "react-dom/client";

// import { Portal } from "@mui/base/Portal";

// export default ({ children }) => {
//   return (
//     <Portal container={() => document.getElementById("portal-root")}>
//       {children}
//     </Portal>
//   );
// };

const root = ReactDOM.createRoot(document.getElementById("portal-root"));
// export const portalRootFirstChild =  ReactDOM.createRoot(
//   document.getElementById("portal-root").firstChild
// );

export default (domElement) => {
  if (domElement) {
    root.render(<>{domElement}</>);
  }
};
