
import styledMUI from "@emotion/styled";
import styled from "@emotion/styled";
import { Box, Button, ButtonBase, Divider, Typography } from "@mui/material";
// export const ProgressBar = styled.div`
//   ${(props) =>
//     props?.fullscreen
//       ? `width: 95%;
//       left: 2.5%;
//       height: 4px;
//       overflow: hidden;
//       background: rgba(255, 255, 255, 0.7);
//       border-radius: 10px;
//       cursor: pointer;
//       position: absolute;
//       bottom: 3%;`
//       : `width: 100%;
//   // height: 6px;
//   height: 0.86vh;
//   // height: 1.29vh;
//   overflow: hidden;
//   background: #DAE1DD;

// transition: height 0.5s;

//   cursor: pointer;
//   &:hover {
    
//     // height: 9px;
//     height: 2.29vh;
    
//   }
  
//   `};
// `;
export const ProgressBar = styled.input`
  width: 100%;
  height: 8px;
  /* Add additional styling properties as needed */
overflow: hidden;


`;
export const Progress = styled.div`
  height: 100%;
  // transistion: height 0.5s;
  width: ${(props) => props.width}%;
  // background: #ee1b37;
  background: ${(props) => (props?.fullscreen ? "#ee1b37" : "#007BF7")};
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: ${(props) => (props?.isMobile ? undefined : "0 1%")};
  position: absolute;
  // padding: 0 8.5px;
  // width: ${(props) => (props?.isMobile ? "100%" : "95%")};
  z-index: 100;
  width: 85%;

  opacity: 1;
`;

export const ActionContainer2 = styled.div`
  position: absolute;
  height: ${(props) => props?.fullscreen && "100%"};
  z-index: 10000;

  width: 100%;
  top: ${(props) => (props?.fullscreen ? undefined : "0")};
  bottom: ${(props) => (props?.fullscreen ? "5%" : undefined)};

  // overflow: hidden;
`;
