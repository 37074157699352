import React, { useState } from "react";
import { DashBoardContainer } from "../UserDashboard/styled";
import Layout from "../../components/Layout";
import StepTwoBrand from "../../components/AddBrandModal";
import { ADD_BRAND } from "../../graphql/mutations/brandMutations";
import { GET_USER_ORGANIZATION } from "../../graphql/queries/organizationQueries";
import { useMutation, useQuery } from "@apollo/client";
import ProgressBar from "../../components/ProgressBar";
import Invitation from "../Invitation";
import { Snackbar } from "@mui/material";
import { USER_OGRANIZATION_INVITE } from "../../graphql/mutations/organizationMutation";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  PreviousButton,
  PreviousButtonText,
  SkipButton,
} from "../OrganizationBrands/styled";
import ButtonCust from "../../components/ButtonCust";
import { useSnackbar } from "notistack";
import ColorButton from "../../components/ColorButton";
import SubHeader from "../../components/SubHeader";
import { TabTitle } from "../../utils/HeaderTitle";

function CreateBrand() {
  TabTitle(`Create`);
  const { orgId } = useParams();
  const location = useLocation();
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const orgName = location.state.orgName;
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("body");
  const [errorMessage, setErrorMessage] = useState({});
  // const [name, setName] = useState("");
  // const [domainUrl, setDomainUrl] = useState("");
  // const [industry, setIndustry] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [step, setStep] = useState(1);
  const [inviteState, setInviteState] = useState({});
  const [numberOfInput, setNumberOfInput] = useState(2);
  const [btnLoading, setBtnLoading] = useState(false);

  // console.log(inviteState, "inviteState");
  const [formDataOrganization, setFormDataOrganization] = useState({
    name: "",
    industry: "",
    // other organization fields
  });
  const [formDataBrand, setFormDataBrand] = useState({
    name: "",
    description: "",
    sector: "",
    organization: orgId,
    imageUrl: "",
    brandUrl: "",

    // other brand fields
  });
  const [organizationDataOne, setOrganizationData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createBrand] = useMutation(
    ADD_BRAND
    //   , {
    //   update(cache, { data: { createBrand } }) {
    //     const existingData = cache.readQuery({ query: GET_USER_ORGANIZATION });
    //     const { userOrganizations = [] } = existingData || {};

    //     const updatedUserOrganizations = userOrganizations.map(
    //       (userOrganization) => {
    //         if (
    //           userOrganization.organization._id === formDataBrand.organization
    //         ) {
    //           const updatedBrands = [...userOrganization.brands, createBrand];

    //           return {
    //             ...userOrganization,
    //             brands: updatedBrands,
    //           };
    //         }
    //         return userOrganization;
    //       }
    //     );

    //     cache.writeQuery({
    //       query: GET_USER_ORGANIZATION,
    //       data: { userOrganizations: updatedUserOrganizations },
    //     });
    //   },
    //   refetchQueries: [{ query: GET_USER_ORGANIZATION }],
    // }
  );
  const [inviteUser] = useMutation(USER_OGRANIZATION_INVITE);

  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_USER_ORGANIZATION
  );

  // dialog modal
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  let emailReq =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // if (organizationLoading) {
  //   return <Spinner />;
  // }
  // if (organizationData?.userOrganizations?.length > 0) {
  //   const firstBrand = organizationData?.userOrganizations[0].brands[0];
  //   console.log(firstBrand);
  //   if (firstBrand) {
  //     const {
  //       brand: { name: name },
  //       brand: { _id: id },
  //       brand: { brandId: brandId },
  //     } = firstBrand;

  //     dispatch(setMenu({ name, brandId, id }));
  //     dispatch(setDate({ startDate: startDate, endDate: endDate }));
  //   } else {
  //     // Handle the case when brands[0] is undefined or empty
  //     // Add appropriate logic or error handling here
  //     console.log("no -brands");
  //     dispatch(setMenu({ name: "", brandId: "", id: "" }));
  //     dispatch(setDate({ startDate: "", endDate: "" }));
  //   }
  // }

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const steps = ["Create Brand", "Invite Members"];
  const getBrandAccess = (brandInput = []) => {
    // console.log("getBrandAccess", brandInput);
    return brandInput.map((item) => ({
      access: item?.sub ?? "view",
      brand: item?.value,
    }));
  };

  const handleFormSubmit = async () => {
    // Perform final submission of organization and brand data
    try {
      // Submit brand data
      // await createBrand({
      //   variables: {
      //     input: {
      //       name: formDataBrand.name,
      //       sector: formDataBrand.sector,
      //       organization: formDataBrand.organization,
      //       brandUrl: formDataBrand.brandUrl,
      //       imageUrl: formDataBrand.imageUrl,
      //       description: formDataBrand.description,
      //     },
      //   },
      // });
      // setOpenSnackbar(true);
      // setSnackbarMessage("brand added successfully");
      let messageArr = [];

      try {
        setBtnLoading(true);
        let errorFlag = false;
        for (let i = 0; i < numberOfInput; i++) {
          if (inviteState[`error${i}`]) {
            messageArr.push({
              type: "error",
              description: `Error: Please make sure all the email addresses are valid`,
            });
            return;
          }
          if (
            inviteState[`email${i}`] &&
            inviteState[`email${i}`].trim() !== "" &&
            !inviteState[`brand${i}`]
          ) {
            messageArr.push({
              type: "error",
              description: `Error: Empty brand assigned to ${inviteState[
                `email${i}`
              ]?.trim()}  `,
            });
            errorFlag = true;
          }
        }
        if (errorFlag) return;

        for (let i = 0; i < numberOfInput; i++) {
          let emailInput = `email${i}`;
          let brandInputValue = inviteState[`brand${i}`]; //use later
          let emailInputValue = inviteState[emailInput]?.toString().trim();

          if (emailReq.test(emailInputValue)) {
            try {
              //send to server
              let { data: dataInvite, errors: errorInvite } = await inviteUser({
                variables: {
                  input: {
                    invitedUserEmail: emailInputValue,
                    organization: orgId,
                    brands: getBrandAccess(brandInputValue),
                  },
                },
              });
              if (dataInvite) {
                messageArr.push({
                  type: "success",
                  description: `${emailInputValue} successfully invited `,
                });
              } else {
                if (
                  errorInvite &&
                  errorInvite instanceof Array &&
                  errorInvite[0]?.message
                ) {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue} : ${errorInvite[0]?.message}   `,
                  });
                } else {
                  messageArr.push({
                    type: "error",
                    description: `Error occured with ${emailInputValue}   `,
                  });
                }
              }
            } catch (err) {
              messageArr.push({
                type: "error",
                description: `${emailInputValue} not invited: ${err}  `,
              });
            }
          }
        }
      } catch (e) {
        // console.log(e);
        messageArr.push({
          type: "error",
          description: `Error occurred... ${e} `,
        });
      } finally {
        setBtnLoading(false);
        showMessage(messageArr);
        navigate("/");
      }
      // Handle success or display appropriate feedback
    } catch (error) {
      // Handle error or display appropriate feedback
      // console.log("error", error);
    }
    setStep(step + 1);
  };
  const validateUrl = (url) => {
    const pattern =
      /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/;

    return pattern.test(url);
  };
  const handleBrandSubmit = async () => {
    const newErrorMessages = {};

    if (formDataBrand.name.trim() === "") {
      newErrorMessages.name = "Name is required";
    }
    if (!formDataBrand.sector || formDataBrand.sector.trim() === "") {
      newErrorMessages.sector = "Industry is required";
    }
    if (!formDataBrand.description || formDataBrand.description.trim() === "") {
      newErrorMessages.description = "Description is required";
    }

    if (
      formDataBrand.brandUrl.trim() !== "" &&
      !validateUrl(formDataBrand.brandUrl)
    ) {
      newErrorMessages.brandUrl = "Please add https://";
    }
    setErrorMessage(newErrorMessages);

    if (Object.keys(newErrorMessages).length === 0) {
      try {
        // Submit brand data
        await createBrand({
          variables: {
            input: {
              name: formDataBrand.name,
              sector: formDataBrand.sector,
              organization: formDataBrand.organization,
              brandUrl: formDataBrand.brandUrl,
              imageUrl: formDataBrand.imageUrl,
              description: formDataBrand.description,
            },
          },
        });
        setOpenSnackbar(true);
        setSnackbarMessage("Brand added successfully");
        let messageArr = [];
        return true;
        // Handle success or display appropriate feedback
      } catch (error) {
        // Handle error or display appropriate feedback
        // console.log("error", error);
        return false;
      }
    }
  };
  const showMessage = (messageArr) => {
    if (messageArr.length > 0) {
      for (let item of messageArr) {
        enqueueSnackbar(item?.description, { variant: item?.type });
      }
    }
    return;
  };
  const handleBrandNext = async () => {
    const isSubmitSuccessful = await handleBrandSubmit();
    if (isSubmitSuccessful) {
      setStep(step + 1);
    }
  };
  const handleInputChange = (field, value) => {
    setFormDataBrand({ ...formDataBrand, [field]: value });

    // Clear the error message for the specific field
    setErrorMessage((prevErrorMessages) => ({
      ...prevErrorMessages,
      [field]: "",
    }));
  };
  // console.log(formDataOrganization);
  // console.log(formDataBrand);
  return (
    <>
      <DashBoardContainer>
        {isMobile && (
          <SubHeader arrLink={[{ title: "Dashboard", link: "/" }]} />
        )}
        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={3000}
        />

        <div>
          <ProgressBar
            steps={steps}
            isMobile={isMobile}
            activeStep={step - 1}
          />

          {step === 1 && (
            <StepTwoBrand
              formData={formDataBrand}
              onPrevious={handlePrevious}
              onNext={handleBrandNext}
              orgName={orgName}
              styleStep={false}
              errorMessages={errorMessage}
              handleInputChange={handleInputChange}
            />
          )}
          {step === 2 && (
            <Invitation
              inputNumber={2}
              inputOrganization={orgId}
              description="You can update user permissions on the team page after setting up."
              setInviteFormstate={setInviteState}
              onPrevious={handlePrevious}
              removeLayout={true}
            />
          )}

          {step === 2 && (
            <>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                    maxWidth: "769px",

                    margin: "1.5rem auto",
                  }}
                >
                  <PreviousButton onClick={handlePrevious}>
                    <PreviousButtonText>Previous</PreviousButtonText>
                  </PreviousButton>

                  <ColorButton
                    onClick={handleFormSubmit}
                    title="Finish"
                    width={isMobile ? "45%" : "201px"}
                    background="#1A1B24"
                  />
                </div>
              </div>
              {!isMobile && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "769px",
                    margin: "2rem auto",
                  }}
                >
                  <SkipButton onClick={() => navigate("/")}>
                    Skip for now
                  </SkipButton>
                </div>
              )}
            </>
          )}
        </div>
      </DashBoardContainer>
    </>
  );
}

export default CreateBrand;
