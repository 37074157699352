import React from "react";

import {
  DisclaimerText,
  LeftTopSubTitle,
  RequestDisclaimer,
  StepTitle,
} from "../Testimonial/ReviewComp/styled";

import ColorButton from "../ColorButton";
import ReviewComp from "../Send-Testimonial/ReviewComp";
function SendTestimonial({ videoDetails }) {
  const videoInfo = videoDetails;
  return (
    <ReviewComp videoInfo={videoInfo} publicKey={videoInfo?.brand?.brandId} />
  );
}

export default SendTestimonial;
