import styled from "@emotion/styled";
import { css, keyframes } from "styled-components";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

const expandAnimation = keyframes`
  from {
    height: 0;
    
  }
  to {
    
    height: fit-content; /* Set the specific height you want */
  }
`;

export const Container = styled(Box)`
  border-radius: 18px 18px 0px 0px;
  background: #fff;
  /* modal shad */
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.14);
  display: none;
  padding-top: 32px;
  left: 0;

  height: 0;
  // overflow: hidden;
  overflow: auto;

  width: 100%;

  gap: 2px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  z-index: 50000;

  ${({ isOpen }) =>
    isOpen &&
    css`
      animation: ${expandAnimation} 0.3s ease; /* Customize the animation duration and timing function */
      height: fit-content; /* Set the specific height you want */
      display: block;
    `}
`;

export const BodyContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  position: fixed;

  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 9999999;
`;

export const BodyTitle = styled(Box)`
  display: flex;
  padding: 0px 16px 20px;
  margin-top: -10px;
  align-items: center;
  width: 100%;
`;

export const BodyTitleProper = styled(Box)`
  text-align: center;
  width: 80%;
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
