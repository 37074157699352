import React from "react";
import TransitionsModal from "../Dialog";
import {
	FlexColumnContainer,
	StyledAuthButton,
	StyledDivider,
	StyledDividerText,
	StyledFormLabelText,
} from "./styled";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { USER_OGRANIZATION_INVITE } from "../../graphql/mutations/organizationMutation";
import { useCallback, useEffect, useState } from "react";
import { Alert, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { GET_ORGANIZATION_BRANDS } from "../../graphql/queries/brandQueries";

const NewInviteDialog = ({ open, setOpen, id, setInvites }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [selected, setSelected] = useState([]);
	const [
		getOrganizationBrands,
		{ data: organizationBrands, loading: loadingGetBrands },
	] = useLazyQuery(GET_ORGANIZATION_BRANDS);

	const handleClickVariant = useCallback(
		(variant, message) => {
			// variant could be success, error, warning, info, or default
			enqueueSnackbar(message, { variant });
		},
		[enqueueSnackbar]
	);

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = organizationBrands?.organizationBrands?.map(
				(n) => n._id
			);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const { handleSubmit, register } = useForm();

	const [inviteUser, { data, loading, error }] = useMutation(
		USER_OGRANIZATION_INVITE
	);

	const onSubmit = (data) => {
		inviteUser({
			variables: { input: { ...data, organization: id, brands: selected } },
		});
	};

	useEffect(() => {
		if (data?.inviteUser) {
			setInvites((prevInvites) => {
				return [...prevInvites, { ...data?.inviteUser }];
			});
			setOpen(false);
			handleClickVariant("success", "invitation sent!");
		}
	}, [data, setInvites, setOpen, handleClickVariant]);

	useEffect(() => {
		if (id) {
			getOrganizationBrands({ variables: { input: { organizationId: id } } });
		}
	}, [id, getOrganizationBrands]);

	return (
		<TransitionsModal open={open} setOpen={setOpen}>
			<FlexColumnContainer
				component="form"
				noValidate
				onSubmit={handleSubmit(onSubmit)}>
				{error && <Alert severity="error">{error?.message}</Alert>}
				{loadingGetBrands && <Typography> Loading brands...</Typography>}
				<FlexColumnContainer>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={
									organizationBrands?.organizationBrands?.length > 0 &&
									selected.length ===
										organizationBrands?.organizationBrands?.length
								}
								onChange={handleSelectAllClick}
								inputProps={{
									"aria-label": "select all desserts",
								}}
							/>
						}
						label="All brands"
					/>
					<StyledDivider>
						<StyledDividerText>OR </StyledDividerText>
					</StyledDivider>

					<FormGroup row>
						{organizationBrands?.organizationBrands?.map((row, index) => {
							const isItemSelected = isSelected(row._id);
							const labelId = `enhanced-table-checkbox-${index}`;

							return (
								<FormControlLabel
									key={index}
									control={
										<Checkbox
											onChange={(event) => handleClick(event, row._id)}
											color="primary"
											checked={isItemSelected}
											inputProps={{
												"aria-labelledby": labelId,
											}}
										/>
									}
									label={row?.name}
								/>
							);
						})}
					</FormGroup>
					<StyledFormLabelText>Email*</StyledFormLabelText>
					<TextField
						margin="normal"
						required
						fullWidth
						id="invitedUserEmail"
						label=""
						placeholder="Enter your user email..."
						name="invitedUserEmail"
						autoComplete="invitedUserEmail"
						{...register("invitedUserEmail", { required: true })}
						autoFocus
					/>
				</FlexColumnContainer>
				<StyledAuthButton
					type="submit"
					fullWidth
					variant="contained"
					sx={{ mt: 3, mb: 2 }}
					disabled={loading}>
					{loading ? "...loading" : "Save"}
				</StyledAuthButton>
			</FlexColumnContainer>
		</TransitionsModal>
	);
};

export default NewInviteDialog;
