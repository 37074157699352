import { gql } from "@apollo/client";
import { PAGE } from "../fragments/pageBuilder";

export const ADD_NEW_PAGE = gql`
  ${PAGE}
  mutation addNewPage($input: CreatePageInput!) {
    addNewPage(input: $input) {
      ...PageFields
    }
  }
`;

export const UPDATE_PAGE = gql`
  ${PAGE}
  mutation updatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      ...PageFields
    }
  }
`;
