import * as React from "react";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Typography, Grid, Paper, Box } from "@mui/material";
import AuthPasswordBanner from "../../assets/images/AuthImageTwo-min.png";
import {
  FlexColumnContainer,
  StyledAuthButton,
  StyledFormLabelText,
  StyledHeaderText,
} from "./styled";
import { useForm } from "react-hook-form";
import AuthLayout from "../../components/AuthLayout";
import { CHANGE_PASSWORD } from "../../graphql/mutations/auth";
import Alert from "@mui/material/Alert";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StyledAuthLogoContainer,
  StyledSpacedBannerHousingContainer,
} from "../../components/AuthLayout/styled";
import RecrowdlyLogo from "../../assets/images/recrowdly_logo.svg";
import LoginVideo from "../../assets/icons/loginVideo.mp4";
import { TabTitle } from "../../utils/HeaderTitle";
export default function ResetPassword() {

  TabTitle(`Reset your password`);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const id = searchParams.get("id");
 
  const [resetPassword, { data, error }] = useMutation(CHANGE_PASSWORD);
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.resetPassword) {
      navigate("/login");
    }
  }, [data, error, navigate]);

  const onSubmit = (data) => {
    resetPassword({
      variables: {
        input: {
          password: data.password,
          password2: data.password2,
          token: token,
          userId: id,
        },
      },
    });
  };
  return (
    <AuthLayout
      bannerImage={AuthPasswordBanner}
      headerBannerText="Our Idea is Simple"
      bannerText="Its free! Join us to get organics reviews from users worldwide"
    >
    
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        component={Paper}
        elevation={0}
        square
        sx={{
          // display: "flex",
          // flexDirection: "column",
          //   justifyContent: "flex-start",
          // alignItems: "flex-start",
          height: "100vh",
          width: "75%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            width: "85%",
            margin: "0 auto",
            height: "10vh",
          }}
        >
          <StyledAuthLogoContainer>
            <img src={RecrowdlyLogo} alt="logo" />
          </StyledAuthLogoContainer>
        </Box>
        <Box
          
          xs={12}
          sm={8}
          md={12}
          sx={{ width: "85%", margin: "0 auto" }}
        >
          <StyledHeaderText>Reset Password</StyledHeaderText>
          <Typography>
            Please reset your password, make sure not to use old password
          </Typography>

          <FlexColumnContainer
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            {error && (
              <Grid item xs={12} sm={12} md={12}>
                <Alert severity="error">{error.message}</Alert>
              </Grid>
            )}

            <FlexColumnContainer>
              <StyledFormLabelText>Password*</StyledFormLabelText>
              <TextField
                type="password"
                margin="normal"
                required
                fullWidth
                id="password"
                label=""
                placeholder="Enter new password"
                name="password"
                autoComplete="password"
                autoFocus
                {...register("password", { required: true })}
              />
            </FlexColumnContainer>
            <FlexColumnContainer>
              <StyledFormLabelText>Confirm-Password*</StyledFormLabelText>
              <TextField
                type="password"
                margin="normal"
                required
                fullWidth
                id="email"
                label=""
                placeholder="Confirm new password"
                name="confirmPassword"
                autoComplete="confirmPassword"
                autoFocus
                {...register("password2", { required: true })}
              />
            </FlexColumnContainer>

            <StyledAuthButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset password
            </StyledAuthButton>
          </FlexColumnContainer>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={false}
        sm={12}
        md={7}
        sx={{
          // display: "flex",
          // flexDirection: "column",
          //   justifyContent: "flex-start",
          // alignItems: "flex-start",
          height: "100%",
        }}
      >
        <StyledSpacedBannerHousingContainer>
          <video
            style={{
              width: "100%",
              height: "100vh",
              objectFit: "cover",
            }}
            autoPlay
            loop
            muted
          >
            <source src={LoginVideo} type="video/mp4" /> Your browser does not
            support the video tag.
          </video>
        </StyledSpacedBannerHousingContainer>
      </Grid>
    </AuthLayout>
  );
}
