import {
  Section2,
  Section2_1,
  Section2_1Text,
  Section2_2,
  Section2_2Text1,
  Section2_2_1,
  Section2_2_1_1,
  Section2_2_1_2,
  Section2_2_1_2T1,
  Section2_2_1_2T2,
  Section2_2_1_3,
  Section2_2_1_3T1,
  Section2_2_1_3_1Box,
  Section2_2_1_3_1Box1T1,
  Section2_2_1_3_1Box2,
  Section2_2_1_3_1Box2T,
  Section2_2_1_3_1Box2But,
  Section2_2_1_3_1T3,
  Section2_2_1_4,
  Section2_2_1_4B,
  Section2_2_1_4BT1,
  Section2_2_1_4B2,
  Section2_2_1_5,
  Section2_2_1_5B1,
  Section2_2_1_5B2,
  Section2_2_1_3_1Box1,
} from "./styled";

import { UserDetailsImage } from "../../UserDashboard/styled";
import VideoComp from "../../../components/Main/SectionComp/videoComp";

import GRFile from "../../../assets/images/reviewlottieex.svg";
import RecrowdlyImg from "../../../assets/images/recrowdlyimg.svg";
import FacebookIcon from "../../../assets/icons/Facebook.svg";
import GIcon2 from "../../../assets/icons/GIcon2.svg";

import InstagramIcon from "../../../assets/icons/Instagram.svg";
import LinkedInIcon from "../../../assets/icons/Linkedin.svg";
import TwitterIcon from "../../../assets/icons/Twitter.svg";

import { imgFormat } from "../../../utils/format";

export default ({
  formstate,
  approvedVideos,
  isMobile,
  widgetData,
  embed = false,
}) => {
  return (
    <Section2
      item
      xs={12}
      md={6}
      sx={{
        paddingTop: 0,
        width: embed ? "100% !important" : undefined,
        maxWidth: embed ? "100% !important" : undefined,
      }}
    >
      {!embed && (
        <Section2_1>
          <Section2_1Text>Guide</Section2_1Text>
          <img src={GRFile} style={{ alignSelf: "stretch" }} />
        </Section2_1>
      )}
      <Section2_2
        sx={{
          overflow: embed ? "auto !important" : undefined,
          height: embed ? "260px" : undefined,
        }}
      >
        {!embed && <Section2_2Text1>Email Preview</Section2_2Text1>}
        <Section2_2_1>
          <Section2_2_1_1>
            <img
              src={
                formstate?.googleReviewLogo
                  ? imgFormat(formstate?.googleReviewLogo)
                  : widgetData?.imageUrl
                  ? imgFormat(widgetData?.imageUrl)
                  : null
              }
            />
          </Section2_2_1_1>
          <Section2_2_1_2>
            <Section2_2_1_2T1>{formstate?.emailHeading1}</Section2_2_1_2T1>
            <Section2_2_1_2T2>{formstate?.emailText1}</Section2_2_1_2T2>
          </Section2_2_1_2>
          <Section2_2_1_3>
            <Section2_2_1_3T1>{formstate?.emailTextAction1}</Section2_2_1_3T1>
            <Section2_2_1_3_1Box>
              {formstate?.emailGoogleButtonText && (
                <Section2_2_1_3_1Box1>
                  <img src={GIcon2} />
                  <Section2_2_1_3_1Box1T1>
                    {formstate?.emailGoogleButtonText}
                  </Section2_2_1_3_1Box1T1>
                </Section2_2_1_3_1Box1>
              )}
              <Section2_2_1_3_1Box2>
                <Section2_2_1_3_1Box2T>
                  {formstate?.emailText2}
                </Section2_2_1_3_1Box2T>
                {formstate?.emailContactText && (
                  <Section2_2_1_3_1Box2But style={{ width: "auto" }}>
                    {formstate?.emailContactText}
                  </Section2_2_1_3_1Box2But>
                )}
              </Section2_2_1_3_1Box2>
              <Section2_2_1_3_1T3>{formstate?.emailText3}</Section2_2_1_3_1T3>
            </Section2_2_1_3_1Box>
          </Section2_2_1_3>
          <Section2_2_1_4>
            <Section2_2_1_4B>
              <Section2_2_1_4BT1>
                See what our thrilled customers are saying!
              </Section2_2_1_4BT1>
              <Section2_2_1_4B2>
                {/* Images */}
                {formstate?.emailTestimonialVideos instanceof Array &&
                  approvedVideos instanceof Array &&
                  formstate?.emailTestimonialVideos.map((el, index) => {
                    let videoItem = approvedVideos.find(
                      (elm) => elm.videoId === el
                    );
                    if (!videoItem) return null;
                    return (
                      <UserDetailsImage
                        key={`Vid${index}`}
                        style={{
                          width: 70,
                          height: 80.99,
                        }}
                      >
                        <VideoComp
                          style={{ width: 70, height: 80.99 }}
                          item={videoItem}
                          specialIcon
                          backgroundColor="#1a1b24"
                          width={"100%"}
                        />
                      </UserDetailsImage>
                    );
                  })}
              </Section2_2_1_4B2>
            </Section2_2_1_4B>
          </Section2_2_1_4>
          <Section2_2_1_5>
            <Section2_2_1_5B1>
              <>Powered by</>
              <img src={RecrowdlyImg} />
            </Section2_2_1_5B1>
            <Section2_2_1_5B2>
              <img src={FacebookIcon} style={{ height: 16, width: 16 }} />
              <img src={InstagramIcon} style={{ height: 16, width: 16 }} />
              <img src={LinkedInIcon} style={{ height: 16, width: 16 }} />
              <img src={TwitterIcon} style={{ height: 16, width: 16 }} />
            </Section2_2_1_5B2>
          </Section2_2_1_5>
        </Section2_2_1>
      </Section2_2>
    </Section2>
  );
};
