import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";

export const Container = styled(Box)`
  // background: white;
  width: 100%;
  padding-top: 28px;
  // padding-left: 32px;
  margin-top: -53px;
  margin-bottom: 30px;
`;

export const MenuItemC = styled(MenuItem)`
  cursor: pointer;
  padding: 10px 0 10px 10px;
  margin: 6px 0;
  width: 100%;
  border-radius: ${(props) => !props.isMobile && "12px"};

  &:hover {
    background-color: ${(props) => !props.isMobile && "#f7f7f7"};
  }
`;

export const Title = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-top: ${(props) => (props?.isMobile ? undefined : "43px")};
  margin-bottom: 12px;
`;

export const SubTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d6272;
`;
export const SubTitleDiv = styled(Box)`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props?.isMobile ? "100%" : "80%")};
  // width: 87%;
  // margin-top: 23px;
`;
export const SubTitleDiv2 = styled(Box)`
  font-size: 14px;
  color: #0075ce;
  font-family: Dm Sans;
  line-height: 20px;
  width: 100%;
  // margin-top: 10px;
`;

export const LeftDiv = styled(Box)`
  display: flex;
  flexdirection: row;
  justify-content: flex-start;
  // border: 1px solid #ccc;
`;

export const IndicatorDiv = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;

  height: 28px;

  background: ${(props) => (props?.active ? "#5ee690" : "#D4DAE1")};
  border-radius: 8px;
  margin-left: 12px;
`;

export const LeftSubTitleDiv = styled(Box)`
  color: #1a1b24;
  font-size: 16px;
  font-family: Dm Sans;
  font-weight: 500;
  line-height: 150%;
`;

export const RadioItem = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  opacity: 0.6;
`;

export const LeftTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #1a1b24;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const OnButton = styled(ButtonBase)`
  display: flex;
  padding: 0 25.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #d4dae1;
  color: #1a1b24;
  font-size: 14px;
  font-family: Dm Sans;
  height: 40px;

  cursor: pointer;
`;

export const TabButton = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.5px 24px;
  gap: 10px;

  height: 40px;
  background: ${(props) => (props?.active ? "#d9eaf8" : "#fff")};
  border: ${(props) =>
    props?.active ? "1px solid #007BF7" : "1px solid #d4dae1"};

  margin-right: 16px;
  cursor: pointer;

  border-radius: 14px;
`;

export const TabDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

export const TypeButton = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 18px;
  gap: 10px;
  height: 38px;
  border-radius: 10px;
  background: ${(props) => (props?.active ? "#d9eaf8" : "#fff")};
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  cursor: pointer;
`;
export const TabText = styled(Box)`
  // font-family: "Dm Sans";
  font-style: normal;

  font-size: 14px;

  /* identical to box height */

  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;

  font-weight: 500;
  line-height: normal;
  color: ${(props) => (props?.active ? "#1a1b24" : "#5D6272")};
`;

export const LeftDescription = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d6272;
  margin-bottom: 32px;
`;

export const DynamicDiv = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : "60%")};
`;

export const LeftSubTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  margin-bottom: 13.77px;
`;

export const WidgetButDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // min-height: 64px;
`;

export const TypeDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const DisplayDiv = styled(Box)`
  box-sizing: border-box;
  border: 1px solid ${(props) => (props?.isMobile ? "#D4DAE1" : "#D4DAE1")}; //f7f7f7
  border-radius: 10px;
  padding: 24px;
  // padding: 25px 12px;
  // height: 485px;
  background: ${(props) => props?.right && "#f7f7f7"};

  height: ${(props) => (props?.widsec ? "fit-content" : "100%")};
  opacity: ${(props) => (props?.inactive ? 0.36000001430511475 : 1)};
  pointer-events: ${(props) => (props?.inactive ? "none" : undefined)};
  ${(props) =>
    props?.isMobile &&
    props?.preview &&
    `
    overflow: hidden;
    overflow-y: hidden;
    position: fixed;
    width:calc(100% - 32px);
    background:#fff;
    max-height: 80%;
    bottom: 36px;
    z-index:99;
    
  `};
`;

export const SectionDivType = styled(Box)`
  display: flex;
  padding: 36px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;

  /* Dropdown shadow */
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(26px);
  height: fit-content;
  ${(props) =>
    props?.section &&
    `display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  padding: 36px;
`}
`;

export const DisplayDivTitle = styled(Box)`
  // font-family: "Dm Sans";
  // font-style: normal;
  // font-weight: 500;
  // font-size: 14px;
  // line-height: 18px;
  // color: #5d6272;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1a1b24;
  // padding-left: 10px;
  margin-bottom: 24px;
  ${(props) =>
    props?.isMobile &&
    `display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%
`}// padding-left: 23px;
`;
export const DynamicSectionDiv = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : "80%")};
  // width: 87%;

  margin-bottom: 32px;
`;

export const EmailBadgeDiv = styled(Box)`
  display: flex;

  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: #fff;
  width: 80%;
`;

export const StylTextField = styled(TextField)`
  border-radius: 15px;
`;

export const SectionTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1a1b24;
  margin-top: 24px;
  margin-bottom: 14px;
`;
export const SectionSubTitle = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const SocialLinkDiv = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: relative;
`;
export const SocialOutlineBox = styled(Box)`
  border: ${(props) =>
    props?.checked ? "1px solid rgba(0, 117, 206, 1)" : "none"};
  border-radius: 10px;
  padding: ${(props) => (props?.checked ? "4px" : "4px")};
  margin-bottom: 16px;
`;

export const ControlDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 60%;
  flex-direction: row;
  margin-top: 14px;
  flex-wrap: wrap;
`;

export const AlignContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const SectionDiv = styled(Box)`
  height: 90%;
  ${(props) =>
    props?.section &&
    `display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`}
`;

export const SectionHeader = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  // font-size: 24px;
  // line-height: 30px;
  line-height: 160%;

  text-align: center;
  color: #1a1b24;
`;
export const SubSectionHeader = styled(Box)`
  // margin-top: 8px;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  // font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #1a1b24;
`;

export const VideoDiv = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ReviewDiv = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 27px;
  gap: 10px;
  // height: 36px;
  background: #1a1b24;
  border-radius: 100px;
  cursor: pointer;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 17px;
  line-height: 160%;
  color: #ffffff;
`;
export const ReviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ImageDiv = styled(Box)`
  ${(props) =>
    props.active &&
    ` 
  border: 1px solid #0075ce;
  border-radius: 10px;`}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 16px;
  gap: 16px;
  height: 70px;
  background: #ffffff;

  cursor: pointer;
`;

export const CheckLabel = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  text-align: center;

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;
`;

export const SectionHeadContainer = styled(Box)`
  display: flex;
  justify-content: ${(props) =>
    props?.textAlign === "left" ? "space-between" : "center"};
  align-items: center;
  flex-direction: ${(props) =>
    props?.textAlign === "left" ? "row" : "column"};
  width: 100%;
`;

export const TopHead = styled(Box)`
  width: ${(props) =>
    props?.textAlign === "left" && props?.review ? "70%" : "100%"};
`;

export const EmptyDiv = styled(Box)`
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80%;
`;

export const PreviewButton = styled(ButtonBase)`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 9px;
  border-radius: 15px;
  border: 1px solid #d4dae1;
  cursor: pointer;
`;

export const PreviewText = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PictureBox = styled(Box)`
  display: flex;
  padding: 25px 17.5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #eeeef2;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;

export const PictureContent = styled(Box)`
  z-index: 15;
  color: #1a1b24;
  text-align: center;
  font-family: Dm Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SocialInputDiv = styled(Box)`
  display: flex;
  width: 576px;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  z-index: 1000;
  top: 2%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.14);
`;

export const SocialInputTitleDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SocialInputTitleDiv2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const SocialInputTitleDiv3 = styled(Box)`
  display: flex;
  padding: 5px 0px 16px 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const SocialInputTitleDiv4 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const IconDiv = styled(Box)`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
  border: ${(props) => props?.active && "1px solid #d9eaf8"};
`;

export const LinkDiv = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

export const LinkInputDiv = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

export const VideoLabel = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export const VideoName = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

export const VideoDate = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
`;
