import { gql } from "@apollo/client";

export const UPDATE_LEAD = gql`
  mutation updateLead($input: UpdateContactsalesInput!) {
    updateLead(input: $input) {
      _id
      status
    }
  }
`;

export const UPDATE_LEAD_CONTACTS = gql`
  mutation updateLeadContacts($input: UpdateContactsalesInputII!) {
    updateLeadContacts(input: $input) {
      _id
      status
      attachedTags
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation deleteLead($input: DeleteContactsalesInput!) {
    deleteLead(input: $input) {
      flag
    }
  }
`;

export const UPLOAD_CSV = gql`
  mutation uploadContactsCSV($file: Upload!, $br_id: String!, $leadgroup:String!) {
    uploadContactsCSV(file: $file, br_id: $br_id, leadgroup:$leadgroup) {
      flag
    }
  }
`;
