import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, TimeScale, LinearScale, PointElement, Filler } from 'chart.js';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Spinner from './Spinner';
import { DISAPPROVED_VIDEOS_DAYS_COUNT } from '../graphql/queries/videosQueries';

// import moment.js date adapter for Chart.js
import 'chartjs-adapter-moment';
import { VIDEOS_WATCHED_DAYS_COUNT } from '../graphql/queries/analytics';

ChartJS.register(Title, Tooltip, LineElement, Legend, TimeScale, LinearScale, PointElement, Filler);
function ChartCardThree({cardData,isMobile}) {

  const [previousData, setPreviousData] = useState(cardData);

  useEffect(() => {
    if (cardData) {
      setPreviousData(cardData);
    }
  }, [cardData]);

  if (!previousData) {
    return null; // Or handle the case when no data is available
  }

  const videoCounts = previousData.slice(0, -1).map(({ date, rating,percentChange }) => ({ date: moment(date), rating,percentChange }));
  const lastDate = moment(videoCounts[videoCounts.length - 1].date).subtract(1, 'day');
  const newData = {
    labels: videoCounts.map(({ date }) => date),
    datasets: [
      {
        data: videoCounts.map(({ rating }) => rating),
        borderColor: '#00D6DD',
        tension: 0.5,
        borderWidth: 1,
        pointStyle: 'none',
      },
    ],
  };

    
    const DISPLAY = false;
const BORDER = true;
const CHART_AREA = true;
const TICKS = true;
    const options = {
        responsive: true,
  maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            mode: 'index',
            intersect: false,
            displayColors: false,
            callbacks: {
              title: (items) => {
                const item = items[0];
                const date = item.label;
              
                return `Date:   ${date}`;
              },
              label: (item) => {
                const dataIndex = item.dataIndex;
    const count = videoCounts[dataIndex].rating;

    return `Ratings: ${count}`;
              },
            },
          },
        },
        scales: {
          x: {
            type: 'time', // set x-axis scale type to 'time'
        time: {
          tooltipFormat: 'll', // set tooltip format
          unit: getUnit(videoCounts), // determine appropriate time unit based on range of dates
          displayFormats: {
            day: 'MMM D', // set display format for day units
            week: 'MMM D', // set display format for week units
            month: 'MMM', // set display format for month units
            quarter: 'Q', // set display format for quarter units
          },
          parser: 'moment', // use moment.js to parse dates
          max:lastDate,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          maxRotation: 0,
          font: {
            size: 10,
          },
        },
            border: {
              display: BORDER
            },
            grid: {
              display: DISPLAY,
              drawOnChartArea: CHART_AREA,
              drawTicks: TICKS,
            }
          },
          y: {
            beginAtZero: true, // set the y-axis minimum value to 0
            border: {
              display: false
            },
            grid: {
                display: true,
                drawOnChartArea: CHART_AREA,
                drawTicks: TICKS,
              },
            }
          }
        }
      
       // remove the 'label' property from the dataset
       newData.datasets[0].label = undefined;
  return (
    <div className="chart-container" style={{position: "relative", height:"300px", width:"100%",marginTop:isMobile ? "30px" :undefined}}>
        <Line data={newData} options={options}></Line>
    </div>
  )
}
// helper function to determine appropriate time unit based on range of dates
function getUnit(data) {
  const firstDate = moment(data[0].date);
  const lastDate = moment(data[data.length - 1]);

  const diffInDays = lastDate.diff(firstDate, 'days');
  if (diffInDays >= 365) {
  return 'year';
  } else if (diffInDays >= 90) {
  return 'quarter';
  } else if (diffInDays >= 30) {
  return 'month';
  } else if (diffInDays >= 7) {
  return 'week';
  } else {
  return 'day';
  }
  }
export default ChartCardThree