import React from "react";
import {
  ColorButtonInner,
  ColorButtonOverall,
  ColorButtonStart,
  ColorButtonStop,
} from "../RecordButton/styled";
function RecordSenderButton({
  startStop,
  onClickStart,
  onClickStop,
  stRecord,
  counterStart,
}) {
  return (
    <div>
      <ColorButtonOverall>
        <ColorButtonInner>
          {stRecord === true && counterStart === 0 ? (
            <ColorButtonStop onClick={onClickStop}></ColorButtonStop>
          ) : (
            <ColorButtonStart onClick={onClickStart}></ColorButtonStart>
          )}
        </ColorButtonInner>
      </ColorButtonOverall>
    </div>
  );
}
export default RecordSenderButton;
