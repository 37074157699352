import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserInput!, $userThumbnail: Upload!) {
    updateUserProfile(input: $input, userThumbnail: $userThumbnail) {
      _id
      thumbnail
    }
  }
`;
export const UPDATE_PROFILE_NO_FILE = gql`
  mutation updateUserProfileWithoutImage($input: UpdateUserInput!) {
    updateUserProfileWithoutImage(input: $input) {
      name
      thumbnail
      email
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword($input: ChangePasswordInput!) {
    changeUserPassword(input: $input) {
      name
      email
    }
  }
`;
export const VERIFY_BRAND_URL = gql`
 
mutation doesCoveteScriptExist($input: BrandUrlInput!) {
  doesCoveteScriptExist(input: $input) 
}
`;
