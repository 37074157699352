import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import {
  FInput,
  FLabel,
  FormBody,
  FormBody2,
  FormBody2_1,
  UplTop,
} from "./styled";
import CloseIcon from "../../assets/icons/RemoveIcon.svg";
import ColorButton from "../../components/ColorButton";
import axios from "axios";

import { useEffect, useState } from "react";
import { UPDATE_LEAD_CONTACTS } from "../../graphql/mutations/leads";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import countryCodes from "../../utils/countryCodes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default ({ openDialog, onClose, data, reload, tagsList }) => {
  const [updateContacts] = useMutation(UPDATE_LEAD_CONTACTS);
  const [formstate, setFormstate] = useState({});
  const [cntryCde, setCntryCde] = useState();
  const [focusState, setFocusState] = useState({});
  const [formValid, setFormValid] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // console.log(data);
  const validation = () => {
    let { name, email, phoneNumber } = formstate;

    let emailReg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let phoneReg =
      /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (
      emailReg.test(email) &&
      name &&
      String(name).trim().length > 1 &&
      (!phoneNumber ||
        (phoneNumber &&
          String(phoneNumber).trim().length > 0 &&
          phoneReg.test(phoneNumber)))
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const getCountryFlag = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      let resObj = res.data instanceof Object ? res.data : {};
      let countryCode = resObj.country_code;
      let ctryCode = countryCodes.find(
        (el) =>
          String(el.country).trim().toLowerCase() ===
          String(countryCode).trim().toLowerCase()
      )?.code;
      if (ctryCode) setCntryCde(ctryCode);
    } catch {}
  };

  const getValues = async () => {
    let { name, email, phoneNumber, phoneCode, attachedTags } = data;
    setFormstate({
      ...formstate,
      name,
      email,
      phoneNumber,
      tags: tagsList
        ?.filter(({ id }) => attachedTags?.includes(id))
        ?.map(({ id }) => id),
    });
    if (phoneCode) {
      let ctryCode = countryCodes.find((el) => el.code === phoneCode)?.code;
      if (ctryCode) {
        setCntryCde(phoneCode);
        return;
      }
    }
    await getCountryFlag();
  };

  const handleSubmit = async () => {
    try {
      let { name, email, phoneNumber, tags } = formstate;
      let obj = { name, email, phoneNumber, attachedTags: tags };
      if (cntryCde) obj.phoneCode = cntryCde;
      let { data: dataContact, errors } = await updateContacts({
        variables: {
          input: { ...obj, lead_id: data?._id },
        },
      });

      if (!dataContact && errors.length > 0) {
        enqueueSnackbar(`Error: ${errors[0].message ?? ""}`, {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Update Successful", { variant: "success" });
        reload && reload();
        onClose();
      }
    } catch {
      enqueueSnackbar("Error Updating ", { variant: "error" });
    }
  };

  useEffect(() => {
    getValues();
  }, [data]);

  useEffect(() => {
    validation();
  }, [formstate]);

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          padding: "1% 1% 3% 1%",
          boxShadow: "0px 7px 30px 0px rgba(0, 0, 0, 0.14)",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <UplTop>
          <img
            alt="close"
            src={CloseIcon}
            onClick={onClose}
            style={{ cursor: "pointer", width: "24px", height: "24px" }}
          />
        </UplTop>
        <FormBody>
          <FormBody2>
            <FormBody2_1>
              <FLabel>Name</FLabel>
              <FInput
                placeholder="John Doe"
                value={formstate.name ?? ""}
                onChange={(e) => {
                  setFormstate({ ...formstate, name: e.target.value });
                }}
              />
            </FormBody2_1>
            <FormBody2_1>
              <FLabel>Email</FLabel>
              <FInput
                placeholder="abcd@gmail.com"
                value={formstate.email ?? ""}
                onChange={(e) => {
                  setFormstate({ ...formstate, email: e.target.value });
                }}
              />
            </FormBody2_1>
            <FormBody2_1>
              <FLabel>Phone Number (Optional)</FLabel>
              <PhoneNumberInput
                inputStyle={{
                  marginBottom: 0,
                }}
                el={{ name: "phoneNumber", placeholder: "90 7861 102" }}
                focusState={focusState}
                formstate={formstate}
                handleChange={(val, name) => {
                  if (val && !/^-?\d+$/.test(val)) return;
                  setFormstate({ ...formstate, [name]: val });
                }}
                setFocusState={setFocusState}
                errorstate={{}}
                setCntryCde={setCntryCde}
                cntryCde={cntryCde}
              />
            </FormBody2_1>
            <FormBody2_1>
              <FLabel>Tags (Optional)</FLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                sx={{
                  width: "100%",
                  borderRadius: "12px",
                }}
                value={formstate?.tags}
                name="tags"
                onChange={(event) => {
                  const {
                    target: { value, name },
                  } = event;
                  setFormstate({
                    ...formstate,
                    [name]:
                      typeof value === "string" ? value.split(",") : value,
                  });
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {tagsList
                      ?.filter(({ id }) => selected?.includes(id))
                      .map(({ id, name }) => (
                        <Chip key={id} label={name} />
                      ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {tagsList.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={formstate?.tags?.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormBody2_1>
          </FormBody2>
          <ColorButton
            style={{ cursor: "pointer" }}
            width="100%"
            background="#1A1B24"
            title="Save Changes"
            alt="Save Changes"
            fontWeight="0"
            onClick={handleSubmit}
            disabled={!formValid}
          />
        </FormBody>
      </DialogContent>
    </Dialog>
  );
};
