import React from "react";

import Layout from "../../components/Layout";
import Organizations from "../../components/Organizations";
import AddOrganizationModal from "../../components/AddOrganizationModal";
import Brands from "../../components/Brands";
import AddBrandModal from "../../components/AddBrandModal";
import Search from "../../components/SearchBox";

function Organization() {
  return (
    <>
      <div>
        <Search />
      </div>
      <hr />
      <div>
        <AddBrandModal />
        <AddOrganizationModal />
      </div>
      <Brands />
      <hr />
      <Organizations />
    </>
  );
}

export default Organization;
