import { Box, Checkbox, FormControlLabel } from "@mui/material";
import {
  LeftTopSubTitle,
  RequestText,
  StepTitle,
  ViaEmail,
  ViaSms,
  ViaSmsText,
} from "../ReviewComp/styled";
import Message from "../../../assets/icons/Message.svg";
import Comment from "../../../assets/icons/Comment.svg";

export default ({
  newStep,
  step,
  isMobile,
  isCheckedSms,
  handleCheckboxChangeSms,
  isCheckedEmail,
  handleCheckboxChangeEmail,
}) => {
  return (
    <>
      {(newStep === 4 || step === 4) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "100%",
              // display: "flex",

              // alignItems: "center",
              position: "relative",
            }}
          >
            <StepTitle>Select your options</StepTitle>

            <LeftTopSubTitle> You can select multiple options.</LeftTopSubTitle>
            <ViaSms isCheckedSms={isCheckedSms}>
              <ViaSmsText>
                <img style={{}} src={Comment} alt="Sms" />
                <RequestText>
                  Send request via{" "}
                  <span style={{ fontWeight: "bold" }}>SMS</span>{" "}
                </RequestText>
              </ViaSmsText>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedSms}
                    onChange={handleCheckboxChangeSms}
                    name="changeToSms"
                  />
                }
                sx={{ marginRight: "0px" }}
              />
            </ViaSms>
            <ViaEmail isCheckedEmail={isCheckedEmail}>
              <ViaSmsText>
                <img style={{}} src={Message} alt="Sms" />
                <RequestText>
                  Send request via{" "}
                  <span style={{ fontWeight: "bold" }}>Email</span>
                </RequestText>
              </ViaSmsText>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedEmail}
                    onChange={handleCheckboxChangeEmail}
                    name="changeToEmail"
                  />
                }
                sx={{ marginRight: "0px" }}
              />
            </ViaEmail>
          </Box>
        </Box>
      )}
    </>
  );
};
