import { gql } from "@apollo/client";

const VIDEOS_WATCHED_COUNT = gql`
  query analyticsWatchedCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsWatchedCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      percentChange
      startDate
      endDate
    }
  }
`;
const VIDEOS_WATCHED_DAYS_COUNT = gql`
  query analyticsDaysVideoCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsDaysVideoCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
      date
      percentChange
    }
  }
`;
const GET_ANALYTICS = gql`
  query analytics {
    analytics {
      country
      device
      duration
    }
  }
`;
const GET_ANALYTICS_WATCHED = gql`
  query analyticsWatchedVideo(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsWatchedVideo(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      duration
    }
  }
`;
const GET_TOTAL_ANALYTICSVIDEOS_WATCHED = gql`
  query analyticsDaysVideoDuration(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsDaysVideoDuration(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      totalDuration
      date
      percentChange
    }
  }
`;
const GET_ANALYTICS_BY_BRAND = gql`
  query analyticsByBrandId(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsByBrandId(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      country
      device
      duration
    }
  }
`;
const GET_DISTINCT_VIDEOS_WATCHED = gql`
  query analyticsDistinctWatchedCount(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsDistinctWatchedCount(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      count
    }
  }
`;

const GET_ANALYTICS_IMPRESSION = gql`
  query analyticsDaysImpression(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsDaysImpression(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      results {
        count
        date
      }
      percentChange
      total
      average
    }
  }
`;

const GET_ANALYTICS_ENGAGEMENT = gql`
  query analyticsDaysEngagement(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsDaysEngagement(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      results {
        dailyTraffic
        date
      }
      percentChange
      total
      average
      totalWidgetTraffic
      totalWebsiteTraffic
    }
  }
`;

const GET_ANALYTICS_TIME_WEBSITE_SPENT = gql`
  query analyticTimeWebSiteSpent(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticTimeWebSiteSpent(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      results {
        totalDuration
        date
      }
      percentChange
      total
      average
    }
  }
`;

const GET_ANALYTICS_PAGE_VISITED = gql`
  query analyticsNumberofVisitedPages(
    $startDate: String!
    $endDate: String!
    $brandId: String!
  ) {
    analyticsNumberofVisitedPages(
      brandId: $brandId
      endDate: $endDate
      startDate: $startDate
    ) {
      results {
        avgPages
        date
      }
      percentChange
      total
      average
    }
  }
`;
export {
  VIDEOS_WATCHED_DAYS_COUNT,
  VIDEOS_WATCHED_COUNT,
  GET_ANALYTICS,
  GET_ANALYTICS_BY_BRAND,
  GET_ANALYTICS_WATCHED,
  GET_TOTAL_ANALYTICSVIDEOS_WATCHED,
  GET_DISTINCT_VIDEOS_WATCHED,
  GET_ANALYTICS_PAGE_VISITED,
  GET_ANALYTICS_TIME_WEBSITE_SPENT,
  GET_ANALYTICS_ENGAGEMENT,
  GET_ANALYTICS_IMPRESSION,
};
