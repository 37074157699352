import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  NodeModalContainerStyle,
  StyledLabel,
  StyledTagButton,
} from "./index.styled";
import { CreateButton } from "../../components/TagsModal/styled";
import { useNodes } from "reactflow";
import { Controller, useFieldArray, useForm } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ConditionalModalContent = ({
  selected,
  tagsList,
  saveNode,
  closeModals,
}) => {
  const nodes = useNodes();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      filter: "",
      condition: "",
      value: "",
    },
  });
  const filterValue = watch("filter");
  const conditionValue = watch("condition");
  const closeValueField =
    conditionValue === "is not blank" || conditionValue === "is blank";
  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(`Field changed to:`, value);
      // console.log({ type });
      if (name == "filter") {
        // console.log(`Field changed to: ${value}`);
        reset({
          ...getValues(),
          condition: "",
          value: "",
        });
      }
      if (
        name === "condition" &&
        type === "change" &&
        (value.condition === "is not blank" || value.condition === "is blank")
      ) {
        reset({
          ...getValues(),
          value: "N/A",
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (selected) {
      const currentNodeStatement = nodes.find(({ id }) => id === selected?.id)
        ?.data?.metadata;
      if (currentNodeStatement?.filter) {
        reset({
          filter: currentNodeStatement?.filter ?? "",
          condition: currentNodeStatement?.condition ?? "",
          value: currentNodeStatement?.value ?? "",
        });
      }
    }

    return () => {};
  }, [selected]);

  const onSubmit = (data) => {
    closeModals();
    saveNode({
      ...selected,
      data: {
        ...selected?.data,
        metadata: {
          ...selected?.data?.metadata,
          ...data,
        },
      },
    });
  };
  return (
    <NodeModalContainerStyle sx={{ minHeight: "300px", width: "750px" }}>
      <Typography
        sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
        id="modal-modal-title"
        variant="h5"
      >
        If/Else Condition
      </Typography>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            mt: 2,
            px: 2,
            py: 1.5,
            pb: 6,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <Typography
            sx={{ color: "#595959", fontSize: "14px", paddingBottom: 2 }}
          >
            Segment Filters
          </Typography>

          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            <Box sx={{ width: "30%" }}>
              <Controller
                rules={{
                  required: "filter is required",
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    error={errors?.["filter"]?.message}
                    placeholder="select filter"
                    sx={{
                      width: "100%",
                      fontFamily: "Switzer",
                      borderRadius: "12px",
                      mb: 2,
                    }}
                    onChange={onChange}
                    value={value}
                    helperText={errors?.["filter"]?.message}
                  >
                    <MenuItem value={""} disabled>
                      Select filter
                    </MenuItem>
                    {["tags", "Email Address", "Name", "Phone Number"].map(
                      (name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
                control={control}
                name={"filter"}
              />
            </Box>
            {!filterValue ? (
              <></>
            ) : filterValue === "tags" ? (
              <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
                <Box sx={{ width: "40%" }}>
                  <Controller
                    rules={{
                      required: "condition is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        error={errors?.["condition"]?.message}
                        placeholder="select filter"
                        onChange={onChange}
                        value={value}
                        sx={{
                          width: "100%",
                          fontFamily: "Switzer",
                          borderRadius: "12px",
                          mb: 2,
                        }}
                        helperText={errors?.["condition"]?.message}
                      >
                        {["contact is tagged", "contact is not tagged"].map(
                          (name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    )}
                    control={control}
                    name={"condition"}
                  />
                </Box>
                <Box sx={{ width: "40%" }}>
                  <Controller
                    rules={{
                      required: "select a tag",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        placeholder="select tags"
                        onChange={onChange}
                        value={value}
                        sx={{
                          width: "100%",
                          fontFamily: "Switzer",
                          borderRadius: "12px",
                          mb: 2,
                        }}
                      >
                        {tagsList.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={control}
                    name={"value"}
                  />
                </Box>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2} sx={{ flex: 1 }}>
                <Box sx={{ width: "40%" }}>
                  <Controller
                    rules={{
                      required: "select a condition",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        error={errors?.["condition"]?.message}
                        placeholder="select filter"
                        onChange={onChange}
                        value={value}
                        sx={{
                          width: "100%",
                          fontFamily: "Switzer",
                          borderRadius: "12px",
                          mb: 2,
                        }}
                        helperText={errors?.["condition"]?.message}
                      >
                        {[
                          "is",
                          "is not",
                          "contains",
                          "does not contain",
                          "starts with",
                          "ends with",
                          "is blank",
                          "is not blank",
                        ].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    control={control}
                    name={"condition"}
                  />
                </Box>
                {!closeValueField && (
                  <Box sx={{ width: "40%" }}>
                    <Controller
                      rules={{
                        required: "enter text",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="value"
                          label=""
                          placeholder="Enter text"
                          onChange={onChange}
                          value={value}
                          autoComplete="name"
                          sx={{
                            width: "100%",
                            margin: "0px 0px 0px",
                            ".MuiInputBase-root": {
                              // height: "50px",
                              borderRadius: "15px",
                            },
                            ":hover": {
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#1a1b24 !important",
                                borderWidth: "1px !important",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#007bf7",
                                borderWidth: "1px !important",
                                boxShadow:
                                  "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                              },
                            },
                          }}
                        />
                      )}
                      control={control}
                      name={"value"}
                    />
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            px: 2,
            py: 2,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <CreateButton type="submit" color="primary">
            Save
          </CreateButton>
        </Box>
      </Box>
    </NodeModalContainerStyle>
  );
};

export default ConditionalModalContent;
