import React from "react";
import { Grid } from "@mui/material";
import EmptySection from "../../assets/widgetIcon/EmptyStateSection.svg";
import {
  DisplayDiv,
  DisplayDivTitle,
  EmptyDiv,
  ReviewContainer,
  ReviewDiv,
  SectionDiv,
  SectionDivType,
  SectionHeadContainer,
  SectionHeader,
  SubSectionHeader,
  TopHead,
  VideoDiv,
} from "./styled";
import SecWidget1 from "../../assets/widgetIcon/secwidget1.svg";
import SecWidget2 from "../../assets/widgetIcon/secwidget2.svg";
import SecWidget3 from "../../assets/widgetIcon/secwidget3.svg";
import SecWidget4 from "../../assets/widgetIcon/secwidget4.svg";
import SecWidgetFull1 from "../../assets/widgetIcon/secwidgetfull1.js";
// import SecWidgetFull1 from "../../assets/widgetIcon/secwidgetfull1.svg";
import SecWidgetFull2 from "../../assets/widgetIcon/secwidgetfull2";
import SecWidgetFull3 from "../../assets/widgetIcon/secwidgetfull3";
import SecWidgetFullmobile1 from "../../assets/widgetIcon/secwidgetfull1mobile";
// import SecWidgetFullmobile2 from "../../assets/widgetIcon/secwidgetfull2mobile.svg";
import SecWidgetFullmobile3 from "../../assets/widgetIcon/secwidgetfull3mobile";
import CloseIcon from "../../assets/images/Close.svg";

export default (widgetState, isMobile, bodyTagObject, setModalOpen) => {
  return (
    typeof widgetState?.option === "string" &&
    widgetState?.option.includes("section") && (
      <Grid item xs={12} md={6} sx={{ paddingTop: 0, ...bodyTagObject }}>
        <DisplayDiv
          right
          isMobile={isMobile}
          preview
          style={{ overflow: !isMobile && "hidden" }}
        >
          <DisplayDivTitle
            isMobile={isMobile}
            style={{
              maxHeight: "10%",
              marginBottom: 42,
              zIndex: 9999,
              position: "relative",
            }}
          >
            <div>Preview</div>
            {isMobile && (
              <div>
                <img src={CloseIcon} onClick={() => setModalOpen(false)} />
              </div>
            )}
          </DisplayDivTitle>
          {widgetState?.sectionVisibility ? (
            <SectionDiv section>
              <SectionDivType section>
                <SectionHeadContainer textAlign={widgetState.textAlignment}>
                  <TopHead
                    textAlign={widgetState.textAlignment}
                    review={widgetState?.sectionLeaveReview}
                  >
                    <SectionHeader
                      style={{
                        textAlign: widgetState.textAlignment,
                        fontSize: `${widgetState.sectionHeaderFontSize}em`,
                        lineHeight: `${widgetState.sectionHeaderLineHeight}em`,
                        color: `${widgetState.sectionHeaderColor}`,
                      }}
                    >
                      {widgetState.sectionHeader}
                    </SectionHeader>
                    <SubSectionHeader
                      style={{
                        textAlign: widgetState.textAlignment,
                        fontSize: `${widgetState.sectionParaFontSize}em`,
                        lineHeight: `${widgetState.sectionParaLineHeight}em`,
                        color: `${widgetState.sectionParaColor}`,
                      }}
                    >
                      {widgetState.paragraph}
                    </SubSectionHeader>
                  </TopHead>
                  {widgetState?.sectionLeaveReview && (
                    <ReviewContainer>
                      <ReviewDiv
                        style={{
                          fontSize: `${widgetState.sectionLeaveReviewFontSize}em`,
                        }}
                      >
                        {widgetState.sectionLeaveReviewText}
                      </ReviewDiv>
                    </ReviewContainer>
                  )}
                </SectionHeadContainer>

                <VideoDiv>
                  {widgetState?.sectionStyle === "classic" &&
                    (isMobile ? (
                      <SecWidgetFullmobile1
                        rating={widgetState?.sectionRating}
                      />
                    ) : (
                      <SecWidgetFull1 rating={widgetState?.sectionRating} />
                    ))}
                  {widgetState?.sectionStyle === "standard" && (
                    // <img src={SecWidgetFull2} />
                    <SecWidgetFull2 rating={widgetState?.sectionRating} />
                  )}
                  {widgetState?.sectionStyle === "flex" &&
                    (isMobile ? (
                      <SecWidgetFullmobile3
                        rating={widgetState?.sectionRating}
                      />
                    ) : (
                      <SecWidgetFull3 rating={widgetState?.sectionRating} />
                    ))}
                </VideoDiv>
              </SectionDivType>
            </SectionDiv>
          ) : (
            <EmptyDiv>
              <img src={EmptySection} />
            </EmptyDiv>
          )}
        </DisplayDiv>
      </Grid>
    )
  );
};
