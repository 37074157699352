import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

/**
 * Modal dialog component.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.open - Whether the modal is open or closed.
 * @param {Function} props.setOpen - Function to control the modal's open state.
 * @param {string} props.content - Content to be displayed in the modal.
 * @param {Array} props.actions - An array of action buttons to be displayed.
 * @returns {JSX.Element} - Rendered modal dialog component.
 */
export default function ({ open = false, setOpen, content, actions = [] }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            border: "2px solid #000",
            borderRadius: 5,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="transition-modal-title"
            sx={{ textAlign: "justify", marginBottom: 5 }}
            variant="h6"
            component="h2"
          >
            {content}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {actions instanceof Array &&
              actions.map((item, index) => (
                <Button
                  key={`But${index}`}
                  style={item.style}
                  onClick={item.actFunction}
                  variant={item.variant}
                  color={item.color}
                  size={item.size}
                >
                  {item.title}
                </Button>
              ))}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
