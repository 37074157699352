import { createSlice } from '@reduxjs/toolkit';

const cardInfoSlice = createSlice({
  name: 'cardInfo',
  initialState: {
    lastFourDigits: '',
    expirationDate: '',
  },
  reducers: {
    updateCardInfo: (state, action) => {
      state.lastFourDigits = action.payload.lastFourDigits;
      state.expirationDate = action.payload.expirationDate;
    },
  },
});

export const { updateCardInfo } = cardInfoSlice.actions;

export const cardInfoReducer = cardInfoSlice.reducer;