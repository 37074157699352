import AWS from "aws-sdk";
import { nanoid } from "nanoid";

export function uploadToAssetManager(editor, e, handleLoaded) {
  // console.log("upload a file function");
  const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
  // console.log("this is the file:", { files });
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    region: "us-east-1",
  });
  let addParams = Date.now();
  // Upload image to S3 bucket
  let paramsArray = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const key = `asset-file-${nanoid()}${addParams}.${file.type.split("/")[1]}`;
    paramsArray.push({
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: key,
      Body: file,
    });
  }
  paramsArray.forEach((params) => {
    s3.upload(params)
      .promise()
      .then((value) => {
        //   {
        //     "ETag": "\"38a7f861d2c6e5624db27fd3ea086876\"",
        //     "ServerSideEncryption": "AES256",
        //     "Location": "https://covete.s3.amazonaws.com/Image1712241978310.gif",
        //     "key": "Image1712241978310.gif",
        //     "Key": "Image1712241978310.gif",
        //     "Bucket": "covete"
        // }
        // console.log("loaded file", value);
        handleLoaded(params);
        editor?.AssetManager &&
          editor.AssetManager.add({
            src: assetsFileFormat({ name: value.Key }),
            name: value.Key,
          });
      })
      .catch((err) => {
        // console.log({ err });
      });
  });
}

export const assetsFileFormat = (asset, type = "serve") => {
  return `${
    type === "serve"
      ? process.env.REACT_APP_AWS_S3_URL_SERVING?.trim()
      : process.env.REACT_APP_AWS_S3_URL_UPLOAD?.trim()
  }${asset.name}`;
};
