import { gql } from "@apollo/client";

export const ADD_NEW_MARKETING_PAGE = gql`
  mutation addNewMarketingPage($input: CreateMarketingPageInput!) {
    addNewMarketingPage(input: $input) {
      brand {
        _id
        brandId
      }
      pages {
        link
        _id
        title
      }
      updatedAt
    }
  }
`;

export const ADD_NEW_CAMPAIGN_PAGE = gql`
  mutation addNewCampaignPage($input: CreateCampaignInput!) {
    addNewCampaignPage(input: $input) {
      brand {
        _id
        brandId
      }
      campaignUnits {
        id
        campaignName
        medium
        source
        page {
          link
          title
          _id
        }
      }
      updatedAt
    }
  }
`;
