import React from 'react';
import TransitionsModal from "../Dialog";
import {
  FlexColumnContainer,
  StyledAuthButton,
  StyledDivider,
  StyledDividerText,
} from "./styled";
import FormGroup from "@mui/material/FormGroup";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { Alert, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { GET_ORGANIZATION_BRANDS } from "../../graphql/queries/brandQueries";
import { UPDATE_USER_ORGANIZATION_ACCESS } from "../../graphql/mutations/userOrganization";

/**
 * A custom form group component for rendering checkboxes for each brand.
 *
 * @param {object} props - Component properties.
 * @param {object} props.row - Brand data for the row.
 * @param {number} props.index - Index of the row.
 * @param {function} props.handleClick - A function to handle checkbox clicks.
 * @param {function} props.isSelected - A function to determine if a brand is selected.
 */
const CustomFormGroup = ({ row, index, handleClick, isSelected }) => {
  const isItemSelected = isSelected(row._id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <FormControlLabel
      key={index}
      control={
        <Checkbox
          onChange={(event) => handleClick(event, row._id)}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      }
      label={row?.name}
    />
  );
};

/**
 * A dialog for managing user access to brands.
 *
 * @param {object} props - Component properties.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {function} props.setOpen - A function to control the dialog's open state.
 * @param {string} props.id - The organization ID.
 * @param {object} props.userAccess - The user's access data.
 * @param {array} props.users - The list of users.
 * @param {function} props.setUsers - A function to set the list of users.
 */
const ManageAccessDialog = ({
  open,
  setOpen,
  id,
  userAccess,
  users,
  setUsers
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);
  const [
    getOrganizationBrands,
    { data: organizationBrands, loading: loadingGetBrands },
  ] = useLazyQuery(GET_ORGANIZATION_BRANDS);

  const handleClickVariant = useCallback(
    (variant, message) => {
      // Variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = organizationBrands?.organizationBrands?.map(
        (n) => n._id
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const { handleSubmit } = useForm();

  const [updateUserOrganizationAccess, { data, loading, error }] = useMutation(
    UPDATE_USER_ORGANIZATION_ACCESS
  );

  const onSubmit = () => {
    updateUserOrganizationAccess({
      variables: { input: { _id: userAccess?._id, brands: selected } },
    });
  };

  useEffect(() => {
    if (data?.updateUserOrganizationAccess) {
      setUsers((prevUsers) => {
        const temUsers = prevUsers;
        const index = temUsers?.findIndex(
          (user) => user?._id === data?.updateUserOrganizationAccess?._id
        );
        temUsers[index] = data?.updateUserOrganizationAccess;

        return temUsers;
      });
      setOpen(false);
      handleClickVariant("success", "Access updated!");
    }
  }, [data, setOpen, handleClickVariant, setUsers]);

  useEffect(() => {
    if (id) {
      getOrganizationBrands({ variables: { input: { organizationId: id } }});
    }
  }, [id, getOrganizationBrands]);

  useEffect(() => {
    if (userAccess?.brands) {
      setSelected(userAccess?.brands);
    }
  }, [userAccess?.brands]);

  return (
    <TransitionsModal open={open} setOpen={setOpen}>
      <FlexColumnContainer
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        {error && <Alert severity="error">{error?.message}</Alert>}
        {loadingGetBrands && <Typography> Loading brands...</Typography>}
        <FlexColumnContainer>
          <Typography variant="h6">{userAccess?.user?.name}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={
                  organizationBrands?.organizationBrands?.length > 0 &&
                  selected.length ===
                    organizationBrands?.organizationBrands?.length
                }
                onChange={handleSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            }
            label="All brands"
          />
          <StyledDivider>
            <StyledDividerText>OR</StyledDividerText>
          </StyledDivider>

          <FormGroup row>
            {organizationBrands?.organizationBrands?.map((row, index) => (
              <CustomFormGroup
                key={index}
                row={row}
                index={index}
                handleClick={handleClick}
                isSelected={isSelected}
              />
            ))}
          </FormGroup>
        </FlexColumnContainer>
        <StyledAuthButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={loading}
        >
          {loading ? "...loading" : "Save"}
        </StyledAuthButton>
      </FlexColumnContainer>
    </TransitionsModal>
  );
};

export default ManageAccessDialog;
