import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../../redux/auth.slice";
import { logOutMenu } from "../../redux/menu.slice";
import { logOutDate } from "../../redux/date.slice";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import SpinnerBig from "../../components/SpinnerBig";
import { useMutation } from "@apollo/client";
import { ACCEPT_INVITATION } from "../../graphql/mutations/invitation";
import { useSnackbar } from "notistack";

export default function () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.auth);
  const { id, idv, userId } = useParams();
  const [acceptInvitation, { data, loading }] = useMutation(ACCEPT_INVITATION);
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    if (idv) {
      dispatch(logOutUser());
      dispatch(logOutMenu());
      dispatch(logOutDate());
      navigate(`/signup?invite_id=${idv}`);
    } else if (userId && id) {
      try {
        // console.log(
        //   profileData,
        //   "profile",
        //   userId,
        //   userId !== profileData?.user?._id,
        //   profileData?.user?._id
        // );

        if (!data?.acceptInvitation) {
          let resp = await acceptInvitation({
            variables: { input: { invitationCode: id } },
          });
          // console.log(resp, "invitation");
          if (data?.acceptInvitation instanceof Object) {
            enqueueSnackbar("Invitation accepted !", {
              variant: "error",
            });
          }
        }
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      }

      if (userId !== profileData?.user?._id) {
        dispatch(logOutUser());
        dispatch(logOutMenu());
        dispatch(logOutDate());
      }
      navigate(`/`);
    } else {
      enqueueSnackbar("Invalid Invite", {
        variant: "error",
      });
      navigate(`/`);
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <SpinnerBig />;
}
