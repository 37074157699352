// src/store/menu.js
import { createSlice } from "@reduxjs/toolkit";

const responsiveSlice = createSlice({
  name: "responsive",
  initialState: {
    isMobile: false,
  },
  reducers: {
    setWindowFlag: (state, action) => {
      state.isMobile = action.payload.isMobile;
    },
  },
});

export const { setWindowFlag } = responsiveSlice.actions;
export const responsiveReducer = responsiveSlice.reducer;
