import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";

export const FooterS = styled(Box)`
  display: flex;
  width: 482px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
`;

export const FooterS1_1 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  display: inline-block;
`;

export const FooterS1_1L = styled(Box)`
  color: var(--dark-grey-palette-primary-french-blue-french-blue-100, #007bf7);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  color: #007bf7;
  display: inline-block;
  cursor: pointer;
`;

export const StyledFrameScreen = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
  margin: 0 auto;

  & .text-wrapper-4 {
    color: #1A1B24;
    font-family: "DM Sans", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28.8px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    width: 95%
    --webkit-box-align: center;
  }
`;

export const FrameContainer = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 600px) {
    gap: 16px;
  }
`;

export const CardPanel = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%; /* changed from 483px */

  @media screen and (min-width: 600px) {
    width: 483px;
  }
`;

export const Field = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: #f9f9f9;
  border: 1px solid;
  border-color: #ececf2;
  border-radius: 12px;
  display: flex;
  gap: 8px;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 100%;

  /* Hover border styling */
  &:hover {
    border-color: rgb(0, 123, 247);
    border-width: 1px;
  }

  &:active {
    border-color: rgb(0, 123, 247);
    border-width: 2px;
  }
`;

export const InputText = styled.div`
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.3x;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 1);
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.3x;
  padding: 10px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  height: 45px;
  flex: 1;
  outline: none;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const FieldCardNumber = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%; /* changed from 483px */

  @media screen and (min-width: 600px) {
    width: 483px;
  }
`;

export const Div2 = styled.div`
  align-items: flex-start;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
  width: 100%;
`;

export const TextWrapper = styled.div`
  align-self: stretch;
  color: #1a1b24;
  font-family: "DM Sans", Helvetica;
  font-size: 0.875em;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20.3px;
  margin-top: -1px;
  position: relative;
`;

export const Field2 = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: #f9f9f9;
  border: 1px solid;
  border-color: #ececf2;
  border-radius: 12px;
  display: flex;
  gap: 8px;
  height: 50px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 100%;

  /* Hover border styling */
  &:hover {
    border-color: rgb(0, 123, 247);
    border-width: 1px;
  }

  &:active {
    border-color: rgb(0, 123, 247);
    border-width: 2px;
  }
`;

export const Input2 = styled.input`
  color: rgba(0, 0, 0, 1);*
  font-family: "DM Sans", Helvetica;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.2px;
  position: relative;
  background-color: transparent;
  height: 45px;
  padding: 10px;
  outline: none;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const Select = styled("select")`
  color: rgba(0, 0, 0, 1);*
  font-family: "DM Sans", Helvetica;
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.2px;
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  max-width: 600px;
  padding: 10px;
`;

// export const DesignConponentInstanceNode = styled.div`
//   background-image = url(./image.svg) !important;
//   position: relative !important;
// `;

export const Tablist = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%; /* changed from 483px */
  gap: 16px;

  @media screen and (min-width: 600px) {
    width: 483px;
    justify-content: space-between;
  }
`;

export const DivWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 45%; /* changed from 482px */

  & .by-placing-your {
    color: transparent;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrap {
    color: #5d6272;
  }

  & .span {
    color: #0075ce;
    text-decoration: underline;
  }

  & .div {
    color: #333;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: -1px;
    position: relative;
    width: 100%; /* changed from 483px */
  }

  @media screen and (min-width: 600px) {
    width: 482px;
    & .div {
      width: 483px;
    }
  }
`;

export const Div3 = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%; /* changed from 483px */

  @media screen and (min-width: 600px) {
    width: 483px;
  }
`;

export const Input3 = styled.input`
  background: transparent;
  border: none;
  color: rgba(0, 0, 0, 1);*
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.2px;
  padding: 0;
  padding: 0;
  position: relative;
`;

export const ArrowsDiagram = styled.img`
  height: 24px;
  position: relative;
  width: 24px;
`;

export const DivWrapper2 = styled.button`
  align-items: center;
  background-color: #1a1b24;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  border-radius: 14px;
  display: flex;
  gap: 10px;
  height: 54px;
  justify-content: center;
  overflow: hidden;
  padding: 30px 40px;
  position: relative;
  width: 100%; /* changed from 482px */

  /* Add hover effect */
  &:hover {
    background-color: #2c2d3f;
    cursor: pointer;
  }

  /* Add active effect */
  &:active {
    background-color: #3d3e52;
    box-shadow: 0px 5px 5px rgba(110, 79, 217, 0.15);
  }

  @media screen and (min-width: 600px) {
    width: 482px;
  }
`;

export const TextWrapper2 = styled.div`
  color: #fff;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 10.1px;
  margin-top: -1px;
  margin-bottom: 0;
  position: relative;
  width: fit-content;
`;

export const WrapContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; /* changed from 482px */

  & .by-placing-your {
    color: transparent;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  & .text-wrapper {
    color: #5d6272;
  }

  & .span {
    color: #0075ce;
    text-decoration: underline;
  }

  & .div {
    color: #333;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    margin-right: -1px;
    position: relative;
    width: 100%; /* changed from 483px */
  }

  @media screen and (min-width: 600px) {
    width: 482px;
    & .div {
      width: 483px;
    }
  }
`;

export const CreditCardBrands = styled.div`
  left: 400px !important;
  position: absolute !important;
  top: 13px !important;
  height: 24px !important;
  background-image: url(https://cdn.animaapp.com/projects/651e72dd9ec05e1c21b1b06c/releases/65425f30dd186255643c2045/img/icon-creditcard-discover-3.svg) !important;
  position: absolute !important;
`;

// export const MasterCard = styled.div`
export const CreditCardCvc = styled.div`
  height: 24px !important;
  left: 200px !important;
  position: absolute !important;
  top: 13px !important;
  width: 24px !important;
`;
