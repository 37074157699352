import { ButtonText, TButton } from "./styled";

export default ({
  clickFn,
  focused,
  width = "212px",
  height = "48px",
  title,
  imgSrc,
  ...rest
}) => {
  return (
    <TButton
      style={{
        width,
        height,
        justifyContent: "center",
      }}
      onClick={() => clickFn && clickFn()}
      focus={focused}
      {...rest}
    >
      {imgSrc && <img src={imgSrc} />}
      <ButtonText>{title}</ButtonText>
    </TButton>
  );
};
