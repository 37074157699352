import React from "react";
import PropTypes from "prop-types";
import {
  BodyContainer,
  BodyTitle,
  BodyTitleProper,
  Container,
} from "./styled";
import CloseIcon from "../../assets/images/Close.svg";

/**
 * Modal component that displays a popup when isOpen is true.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {function} props.setIsOpen - Callback function to toggle the modal's open state.
 * @param {React.ReactNode} props.children - Content to display within the modal.
 * @param {string} [props.title] - Optional title for the modal.
 * @param {Object} [props.containerStyle] - Additional styles for the modal container.
 * @param {Object} [props.bodyContainerStyle] - Additional styles for the modal body container.
 * @returns {JSX.Element|null} The modal component.
 */
const Modal = ({
  isOpen,
  setIsOpen,
  children,
  title,
  containerStyle = {},
  bodyContainerStyle = {},
}) => {
  // Render the modal if isOpen is true
  return isOpen ? (
    <BodyContainer style={bodyContainerStyle}>
      <Container style={containerStyle} isOpen={isOpen}>
        {title && (
          <BodyTitle>
            {/* Close button */}
            <img
              src={CloseIcon}
              alt="Close"
              onClick={() => setIsOpen(false)}
              role="button" // Add role="button" for accessibility
            />
            {/* Modal title */}
            <BodyTitleProper>{title}</BodyTitleProper>
          </BodyTitle>
        )}
        {/* Modal content */}
        {children}
      </Container>
    </BodyContainer>
  ) : null;
};

// PropTypes for type checking and documentation
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  containerStyle: PropTypes.object,
  bodyContainerStyle: PropTypes.object,
};

export default Modal;
