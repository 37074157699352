import { useEffect, useRef } from "react";
import { Handle, Position, useNodeId, useReactFlow, useStore } from "reactflow";
import { StarterNodeItemStyle } from "./index.styled";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { NodeIconType } from "./NodeItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useWorkflowContext } from "./workflowContext";
import { format } from "date-fns";

const StarterNode = ({ data, isConnectable }) => {
  const nodeId = useNodeId();
  const mounted = useRef(true);
  const { setNodes } = useReactFlow();
  const { setStarterOpen, starterOpen, groupList } = useWorkflowContext();
  const selectedGroup = groupList.find(
    (el) => el?._id === data?.metadata?.value
  );

  useEffect(() => {
    if (
      mounted.current &&
      groupList &&
      !selectedGroup &&
      data?.metadata?.value
    ) {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === nodeId) {
            node.data = {
              ...node.data,
              metadata: { ...node.data.metadata, value: null },
            };
          }
          return node;
        })
      );
      mounted.current = false;
    }
  }, [groupList,data]);
  const isSourceConnected = useStore((s) =>
    s.edges.some((edge) => edge.source === nodeId)
  );

  return (
    <>
      <StarterNodeItemStyle
        className="nodrag"
        variant="white"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box sx={{ px: 1 }}>
          <NodeIconType type={data.type} />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <Typography
                color={"black"}
                sx={{ mb: 0.2, fontFamily: "Dm Sans", fontWeight: 700 }}
              >
                {selectedGroup ? `${selectedGroup?.name} Attached` : data.title}
              </Typography>
              <Typography
                color={"black"}
                sx={{
                  mb: 0.2,
                  fontSize: "10px",
                  fontFamily: "Dm Sans",
                  fontWeight: 700,
                }}
              >
                {selectedGroup
                  ? format(selectedGroup?.createdAt, "dd MMM, yyyy pp")
                  : ""}
              </Typography>
            </div>

            <div>
              <IconButton
                onClick={() => setStarterOpen(!starterOpen)}
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </Stack>

          <Typography
            variant="body2"
            sx={{ fontFamily: "Dm Sans" }}
            color={"#555555"}
          >
            {/* id:{nodeId} */}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Dm Sans" }}
            color={"#555555"}
          >
            {data.description}
          </Typography>
        </Box>
      </StarterNodeItemStyle>
      {/* <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={handleStyle}
          isConnectable={isConnectable}
        /> */}
      <Handle
        type="source"
        className="handle bottom"
        isConnectableStart={!isSourceConnected}
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </>
  );
};

export default StarterNode;
