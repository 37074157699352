import styled from "styled-components";

const setMargin = (val) => {
  val = typeof val === "string" ? val.trim().toString() : "";
  switch (val) {
    case "top-left":
    case "top-center":
    case "top-right":
      return `margin-top: 0px;`; //height:110%

    case "center-right":
      return `margin-right: 3em;`; //height:90%

    case "center-left":
      return `margin-left: 2.5em;`; //height:90%
    case "center-center":
      return `margin-left: 0.5em;`; //height:90%
    case "bottom-center":
    case "bottom-right":
    case "bottom-left":
    default:
      return ``; //height: 90%
  }
};

const setFullWrapperPosition = (val) => {
  val = typeof val === "string" ? val.trim().toString() : "";
  switch (val) {
    case "top-left":
    case "top-center":
    case "top-right":
      return `justify-content: flex-start;flex-direction:column-reverse;`;

    case "center-right":
    case "center-left":
    case "center-center":
      return `justify-content: center;`;
    case "bottom-center":
    case "bottom-right":
    case "bottom-left":
    default:
      return `justify-content: flex-end;`;
  }
};

export const Image = styled.img`
  height: 1em;
`;

export const ArrowImage = styled.img`
  cursor: pointer;
`;

export const StarWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const MajorWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: transparent;
`;

export const Wrapper = styled.div`
  width: 282px;
  // height: 90%;

  background: ${(props) => props?.brandProps?.backgroundColor ?? "#070b0d"};
  border-radius: 7px;
  padding: 7px;
  margin-bottom: 11px;
  ${(props) => setMargin(props?.brandProps?.position)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const WrapperInner = styled.div``;

export const FullWrapper = styled.div`
  display: flex;
  // margin-left: 2em;
  // margin-right: ${(props) =>
    ["top-right", "bottom-right"].includes(
      props?.brandProps?.position?.toString().trim()
    ) && "2em"};

  flex-direction: column;
  height: 85%;
  color: ${(props) => props?.brandProps?.position ?? "white"};
  ${(props) => setFullWrapperPosition(props?.brandProps?.position)};
`;

export const SectionFullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  // height: 85%;
  color: ${(props) => props?.brandProps?.position ?? "white"};
`;

export const SectionWrapper = styled.div`
  width: 100%;
  // height: 100%;
  max-width: 400px;
  // max-height: 400px;

  // background: ${(props) => props?.brandProps?.backgroundColor ?? "#070b0d"};
  border-radius: 7px;
  padding: 7px;
  margin-bottom: 11px;
  // ${(props) => setMargin(props?.brandProps?.position)};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // color: white;
  padding: 2px;
`;

export const TopText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #e5e5e6;
`;

export const TopSubText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #e5e5e6;
  line-height: 18px;
`;

export const TopRightText = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  color: #8d6cff;
  cursor: pointer;
`;

export const SectionVideoWrapper = styled.div`
  width: 100%;
  // height: 80%;

  border-radius: 6.6773px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url(${(props) => props?.url});
  background-repeat: no-repeat;
  background-color: ${(props) =>
    props?.brandProps?.backgroundColor ?? "#070b0d"};
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 80%;
  margin-top: 7px;

  border-radius: 6.6773px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url(${(props) => props?.url});
  background-repeat: no-repeat;
  background-color: ${(props) =>
    props?.brandProps?.backgroundColor ?? "#070b0d"};
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
`;

export const PlayIconWrapper = styled.img`
  position: relative;
  z-index: 10;
  cursor: pointer;
`;
export const PlayIconWrapperAbs = styled.img`
  position: relative;
  z-index: 10;
  cursor: pointer;
  left: 19rem;
  top: -11rem;
`;

export const SliderWrapper = styled.div`
  // border: 1px solid blue;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
`;

export const Thumbnails = styled.div`
  min-width: 75px;
  height: 66px;
  margin-right: 5px;
  background: ${(props) => props?.brandProps?.backgroundColor ?? "#ccc"};
  border-radius: 6.6773px;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url(${(props) => props?.url});
  aspect-ratio: 1;

  background-position: center;
  cursor: pointer;
  border: ${(props) =>
    props?.active
      ? `1px solid ${props?.brandProps?.borderColor ?? "#FEb02f"}`
      : undefined};
`;

export const Direction = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: row;
  color: white;
  margin-top: -45px;
  justify-content: space-between;
`;
