import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ContentContainer = styled(Box)`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

export const TopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const TopContainer1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

export const TopContainer1_1 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
`;

export const TopContainer1_2 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const TopContainer2 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const MiddleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const MiddleContainer1 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;

export const BottomContainer = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
  display: inline-block;
`;

export const BottomContainer1 = styled(Box)`
  color: var(--dark-grey-palette-primary-french-blue-french-blue-100, #007bf7);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration-line: underline;
  text-transform: capitalize;
  display: inline-block;
  cursor: pointer;
`;

export const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const DetailContainer1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const DetailContainerB = styled(Box)`
  display: flex;
  padding: 28px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--Element, #d4dae1);
  background: var(--Base-White, #fff);
`;

export const SubDetBCont = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;
export const SubDetB = styled(Box)`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const SubDetBText = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 58.333% */
`;

export const SubDetBoard = styled(Box)`
  display: flex;
  padding: 28px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  align-self: stretch;
`;
