import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";

export const StyledButtonContainer = styled(Box)`
  background: #ffffff;
  margin-top: 22px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 5px;
  width: 100%;
  max-width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 5px;
  height: 34px;
  width: 34px;
`;
