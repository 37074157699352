// Save form data to localStorage
export function savePagesToStorage(data) {
  localStorage.setItem("workflows", JSON.stringify(data));
}

// Retrieve form data from localStorage
export function getPagesFromStorage() {
  const storedData = localStorage.getItem("workflows");
  return storedData ? JSON.parse(storedData) : [];
}
// Save tag data to localStorage
export function saveTagsToStorage(data) {
  localStorage.setItem("tags-table", JSON.stringify(data));
}

// Retrieve form data from localStorage
export function getTagsFromStorage() {
  const storedData = localStorage.getItem("tags-table");
  return storedData ? JSON.parse(storedData) : [];
}

export const getEditorDocument = (editor) => {
  const doc = editor.Canvas.getDocument();
  return doc;
};

export const punchFontString = `
    @font-face {
      font-family: 'Patron';
      src: url('https://cdn.punch.cool/Patron-Regular.otf') format('opentype');
    }
    @font-face {
      font-family: 'Sagona';
      src: url('https://cdn.punch.cool/ReneBieder-Sagona-Medium.otf') format('opentype');
    }
  `;

export const addCustomFontsToEditor = (editor) => {
  const iframe = editor.Canvas.getFrameEl();
  const doc = iframe.contentDocument || iframe.contentWindow.document;

  const style = doc.createElement("style");
  style.innerHTML = punchFontString;

  doc.head.appendChild(style);
};

export const updateFontFamilyList = (editor) => {
  const sm = editor.StyleManager;

  const fontFamilyProperty = sm.getProperty("typography", "font-family");
  const customFonts = [
    { label: "Arial", id: "Arial, Helvetica, sans-serif" },
    { label: "Courier New", id: "'Courier New', Courier, monospace" },
    { label: "Georgia", id: "Georgia, serif" },
    { label: "Roboto", id: "'Roboto', sans-serif" },
    { label: "Open Sans", id: "'Open Sans', sans-serif" },
    { label: "Patron", id: "Patron, sans-serif" }, // Custom Font
    { label: "Sagona", id: "Sagona, serif" }, // Custom Font
    // Add more custom fonts here
  ];

  if (fontFamilyProperty) {
    fontFamilyProperty.set("options", customFonts);
  } else {
    sm.addProperty("typography", {
      property: "font-family",
      label: "Font family",
      type: "select",
      defaults: "Arial, Helvetica, sans-serif",
      options: customFonts,
    });
  }

  // sm.addProperty(
  //   "typography",
  //   {
  //     property: "font-family",
  //     label: "Font family",
  //     type: "select",
  //     defaults: "Arial, Helvetica, sans-serif",
  //     options: [
  //       { label: "Arial", id: "Arial, Helvetica, sans-serif" },
  //       { label: "Courier New", id: "'Courier New', Courier, monospace" },
  //       { label: "Georgia", id: "Georgia, serif" },
  //       { label: "Roboto", id: "'Roboto', sans-serif" },
  //       { label: "Open Sans", id: "'Open Sans', sans-serif" },
  //       { label: "Patron", id: "Patron, sans-serif" }, // Custom Font
  //       { label: "Sagona", id: "Sagona, serif" }, // Custom Font
  //     ],
  //   },
  //   {}
  // );
};

export const nodeTypesDataList = {
  starter: {
    name: "All Leads Attached",
    description: "including imported contacts.",
    type: "starter",
  },
  tagged: {
    name: "Add/Remove Tags",
    description: "Attach multiple tags to leads.",
    type: "tagged",
  },
  email: {
    name: "Send Emails",
    description: "Curate and send emails",
    type: "email",
  },
  // trigger: {
  //   name: "Trigger",
  //   description: "Await certain event to continue the workflow",
  //   type: "trigger",
  // },
  delay: {
    name: "Time Delay",
    description: "Make delays by minutes, hours or days",
    type: "delay",
  },
  time: {
    name: "Pick A Date",
    description: "pick a date to proceed a workflow",
    type: "time",
  },
  ifElseCondition: {
    name: "If/Else Condition",
    description: "selected grouped mails in tags",
    type: "ifElseCondition",
  },
  newsletter: {
    name: "Send Newsletter",
    description: "select and send newsletter template",
    type: "newsletter",
  },
};

export const onDragStart = (event, nodeType) => {
  // console.log("drag started", nodeType);
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.effectAllowed = "move";
};

export function findLongestArray(arrays) {
  if (!Array.isArray(arrays) || arrays.length === 0) {
    return null; // Return null if input is not an array or if it's empty
  }
  let longestArray = arrays[0];
  for (let i = 1; i < arrays.length; i++) {
    if (arrays[i].length > longestArray.length) {
      longestArray = arrays[i];
    }
  }
  return longestArray;
}

export function getDateRange(startDate, endDate) {
  const dateArray = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

export const newsletterAnalyticsItems = {
  bounce: {
    title: "Bounced",
    description: "The email failed to be delivered to the recipient's inbox.",
    color: "#118df0",
  },
  open: {
    title: "Opened",
    description: "The recipient has opened the email.",
    color: "#BF6DFF",
  },
  click: {
    title: "Clicked",
    description: "A link within the email was clicked by the recipient.",
    color: "#5ACEC7",
  },
  delivered: {
    title: "Delivered",
    description:
      "The email was successfully delivered to the recipient's inbox.",
    color: "#FAC60F",
  },
  unsubscribe: {
    title: "UnSubscribed",
    description: "The recipient has opted out of receiving future emails.",
    color: "#ff4b68",
  },
  spamreport: {
    title: "Spam",
    description: "The email was reported as spam by the recipient.",
    color: "#95acc4",
  },
  systemBounce: {
    title: "Test Bounce",
    description: "The email was once bounced/dropped/deferred.",
    color: "#E9EEF3",
  },
};

export const validateTime = (from, to) => {
  const [fromHours, fromMinutes] = from.split(":").map(Number);
  const [toHours, toMinutes] = to.split(":").map(Number);

  if (fromHours < toHours) {
    return true; // 'to' is later in the day than 'from'
  } else if (fromHours === toHours && fromMinutes < toMinutes) {
    return true; // 'to' is later in the same hour than 'from'
  }

  return false; // 'to' is earlier or the same time as 'from'
};

export const timeZones = [
  { value: "Etc/GMT+12", label: "GMT-12:00", abbr: "AoE" },
  { value: "Pacific/Midway", label: "GMT-11:00", abbr: "SST" },
  { value: "Pacific/Honolulu", label: "GMT-10:00", abbr: "HST" },
  { value: "America/Anchorage", label: "GMT-09:00", abbr: "AKST" },
  { value: "America/Los_Angeles", label: "GMT-08:00", abbr: "PST" },
  { value: "America/Denver", label: "GMT-07:00", abbr: "MST" },
  { value: "America/Chicago", label: "GMT-06:00", abbr: "CST" },
  { value: "America/New_York", label: "GMT-05:00", abbr: "EST" },
  { value: "America/Caracas", label: "GMT-04:30", abbr: "VET" },
  { value: "America/Halifax", label: "GMT-04:00", abbr: "AST" },
  { value: "America/St_Johns", label: "GMT-03:30", abbr: "NST" },
  { value: "America/Argentina/Buenos_Aires", label: "GMT-03:00", abbr: "ART" },
  { value: "Atlantic/South_Georgia", label: "GMT-02:00", abbr: "GST" },
  { value: "Atlantic/Azores", label: "GMT-01:00", abbr: "AZOT" },
  { value: "Europe/London", label: "GMT+00:00", abbr: "GMT" },
  { value: "Europe/Berlin", label: "GMT+01:00", abbr: "CET" },
  { value: "Europe/Helsinki", label: "GMT+02:00", abbr: "EET" },
  { value: "Europe/Moscow", label: "GMT+03:00", abbr: "MSK" },
  { value: "Asia/Tehran", label: "GMT+03:30", abbr: "IRST" },
  { value: "Asia/Dubai", label: "GMT+04:00", abbr: "GST" },
  { value: "Asia/Kabul", label: "GMT+04:30", abbr: "AFT" },
  { value: "Asia/Karachi", label: "GMT+05:00", abbr: "PKT" },
  { value: "Asia/Kolkata", label: "GMT+05:30", abbr: "IST" },
  { value: "Asia/Kathmandu", label: "GMT+05:45", abbr: "NPT" },
  { value: "Asia/Dhaka", label: "GMT+06:00", abbr: "BST" },
  { value: "Asia/Rangoon", label: "GMT+06:30", abbr: "MMT" },
  { value: "Asia/Bangkok", label: "GMT+07:00", abbr: "ICT" },
  { value: "Asia/Hong_Kong", label: "GMT+08:00", abbr: "HKT" },
  { value: "Asia/Tokyo", label: "GMT+09:00", abbr: "JST" },
  { value: "Australia/Adelaide", label: "GMT+09:30", abbr: "ACDT" },
  { value: "Australia/Sydney", label: "GMT+10:00", abbr: "AEDT" },
  { value: "Pacific/Noumea", label: "GMT+11:00", abbr: "NCT" },
  { value: "Pacific/Auckland", label: "GMT+12:00", abbr: "NZDT" },
  { value: "Pacific/Tongatapu", label: "GMT+13:00", abbr: "TOT" },
];

export function createUniqueTitle(title, existingTitles) {
  // Check if the title already contains a suffix indicating duplication
  const regex = / \(\d+\)$/; // Matches a space followed by a number in parentheses at the end of the string

  let baseTitle = title;
  let number = 1;

  // If the title already contains a suffix, extract the base title and number
  if (regex.test(title)) {
    const match = title.match(/(.*) \((\d+)\)$/); // Extracts the base title and number from the string
    baseTitle = match[1];
    number = parseInt(match[2]) + 1;
  }

  let newTitle = title;

  // Increment the number until we find a unique title
  while (existingTitles.includes(newTitle)) {
    newTitle = `${baseTitle} (${number})`;
    number++;
  }

  return newTitle;
}
