import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Widget1 from "../../assets/widgetIcon/widget1.svg";
import Widget2 from "../../assets/widgetIcon/widget2.svg";
import Widget3 from "../../assets/widgetIcon/widget3.svg";
// import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.svg";
// import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.svg";
import CopyIcon from "../../assets/icons/Copy.svg";
import MarkUpIcon from "../../assets/icons/MarkUp.svg";
import {
  Container,
  DisplayDiv,
  DisplayDivTitle,
  DynamicDiv,
  DynamicSectionDiv,
  LeftDescription,
  LeftSubTitle,
  LeftTitle,
  RadioItem,
  ReviewContainer,
  ReviewDiv,
  SectionDiv,
  SectionHeader,
  SectionTitle,
  SubSectionHeader,
  SubTitle,
  TabButton,
  TabDiv,
  TabText,
  Title,
  VideoDiv,
  WidgetButDiv,
} from "../Customization/styled";
import ButtonCust from "../../components/ButtonCust";
import {
  CopyButton,
  InstructionDiv,
  StepContainer,
  StepInstructionDiv,
  StepNumber,
  StepTitle,
  CopyText,
  StepTitle2,
  StepTitle2Bold2,
  StepTitle2Bold,
  StepTitle3,
  VerifiedButton,
  DivCont,
} from "./styled";
import CodeViewer from "../../components/CodeViewer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WidgetSvg from "../../assets/widgetIcon/installwidget.svg";
// import SecWidgetFull1 from "../../assets/widgetIcon/secwidgetfull1.svg";
// import SecWidgetFull2 from "../../assets/widgetIcon/secwidgetfull2.svg";
// import SecWidgetFull3 from "../../assets/widgetIcon/secwidgetfull3.svg";
import WidgetDisplay from "../../assets/widgetIcon/widgetDisplay.js";
import WidgetDisplayR from "../../assets/widgetIcon/widgetDisplayR.js";
import axios from "axios";
import { VERIFY_BRAND_URL } from "../../graphql/mutations/user";
import { useMutation } from "@apollo/client";
import GoogleRvInstall from "./googleRvInstall";

export default function ({
  brandId,
  data,
  isMobile,
  setScrollToReview,
  scrollToReview,
  reload,
}) {
  // console.log(data, "dataa");
  const [verified, setVerified] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const divRef = useRef(null);
  const defaultObject = {
    position: data?.position ?? "bottom-left",
    visibility: data?.visibility ?? true,
    sectionHeader: data?.sectionHeader ?? "Review Section Header",
    paragraph:
      data?.paragraph ??
      "Paragraph associated to the review section can come here",
    sectionStyle: data?.sectionStyle ?? "classic",
    textAlignment: data?.textAlignment ?? "center",
    option: "widgetsection",
    // option: data?.option ?? "widget",
    widgetIcon: data?.widgetIcon ?? "1",
    leaveReview: data?.leaveReview ?? true,
    brandId,
  };
  const [widgetState, setWidgetState] = useState({
    ...defaultObject,
    // code: `<script id="covete_script" value="${brandId}" src="${
    //   process.env.REACT_APP_WIDGET_LINK_URL ?? "."
    // }/index.js" defer ></script>`,
    code: `<script id="recrowdly" value="${brandId}" src="${process.env.REACT_APP_WIDGET_LINK_URL}/recrowdly.js" defer ></script>`,
    codesection: `<div id="forSection" ></div>`,
    copied1: false,
    copied2: false,
    verified1: false,
    verified2: false,
  });

  // const [active, setActive] = useState("widget");
  const [
    verifyBrandUrl,
    { data: verifyData, loading: verifyLoading, error: verifyError },
  ] = useMutation(VERIFY_BRAND_URL);

  const isBrandUrlVerified = async () => {
    try {
      await verifyBrandUrl({
        variables: {
          input: {
            brandUrl: data.brandUrl,
          },
        },
      });
    } catch (error) {
      // Handle any errors that occur during the mutation
      console.error("Error marking notifications as old:", error);
    }
  };
  const showBoxBox = () => {
    setShowBox(!showBox);
  };
  const handleChange = (val, key) =>
    setWidgetState({ ...widgetState, [key]: val });

  const copyHandleChange = (val, key) => {
    handleChange(val, key);
    setTimeout(() => handleChange(!val, key), 2000);
  };

  const justifyPosition = () => {
    switch (widgetState?.position) {
      case "bottom-right":
        return "flex-end";
      default:
        return "flex-start";
    }
  };
  // console.log("verifyData", verifyData?.doesCoveteScriptExist);
  // console.log("verifyData", verifyError);
  const copyComponent = (state, copiedVar, copiedName) => {
    return (
      <CopyToClipboard
        text={state}
        onCopy={() => copyHandleChange(!copiedVar, copiedName)}
      >
        <CopyButton copied={copiedVar}>
          <img src={copiedVar ? MarkUpIcon : CopyIcon} />{" "}
          <CopyText copied={copiedVar}>
            {" "}
            {copiedVar ? "Copied" : "Copy code"}
          </CopyText>
        </CopyButton>
      </CopyToClipboard>
    );
  };

  const widgetContent = () => (
    <div
      // style={{ display: widgetState.option === "widget" ? "block" : "none" }}
      style={{ display: "block" }}
    >
      <InstructionDiv>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <StepInstructionDiv>
            <StepTitle style={{ marginBottom: 18 }}>Copy code</StepTitle>
            <CodeViewer code={widgetState?.code} disabled />
            {copyComponent(widgetState?.code, widgetState?.copied1, "copied1")}
          </StepInstructionDiv>
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <StepInstructionDiv>
            <StepTitle2>
              <StepTitle2Bold2> Paste </StepTitle2Bold2> the code into the{" "}
              <StepTitle2Bold>{`<head>`}</StepTitle2Bold> of the page{" "}
              {widgetState?.option === "section" && (
                <>
                  and make sure the <StepTitle2Bold>{`<div>`}</StepTitle2Bold>{" "}
                  with the specified <StepTitle2Bold>id</StepTitle2Bold> is in
                  the page{" "}
                </>
              )}
            </StepTitle2>
          </StepInstructionDiv>
        </StepContainer>
        <StepContainer>
          <StepNumber>3</StepNumber>
          <StepInstructionDiv
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: -5,
            }}
          >
            <StepTitle3 style={{ paddingTop: 0, marginTop: -5 }}>
              Verify that your code was installed.
            </StepTitle3>
            <VerifiedButton
              verified={verifyData?.doesCoveteScriptExist}
              errorProp={verifyError}
              onClick={isBrandUrlVerified}
            >
              {verifyLoading
                ? "Loading..."
                : verifyData?.doesCoveteScriptExist === true
                ? "Verified"
                : verifyData?.doesCoveteScriptExist === false
                ? "Could not verify, retry?"
                : verifyData?.doesCoveteScriptExist === undefined && verifyError
                ? "Could not verify, retry?"
                : "Verify Installation"}
            </VerifiedButton>
          </StepInstructionDiv>
        </StepContainer>
      </InstructionDiv>
    </div>
  );

  const sectionContent = () => (
    <div
      style={{
        display: String(widgetState.option).includes("section")
          ? "block"
          : "none",
      }}
    >
      <InstructionDiv>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <StepInstructionDiv>
            <StepTitle style={{ marginBottom: 18 }}>Copy code</StepTitle>
            <CodeViewer code={widgetState?.code} disabled />
            {copyComponent(widgetState?.code, widgetState?.copied1, "copied1")}
          </StepInstructionDiv>
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <StepInstructionDiv>
            <StepTitle2>
              <StepTitle2Bold2> Paste </StepTitle2Bold2> the code just before{" "}
              <StepTitle2Bold>{`</head>`}</StepTitle2Bold> tag of the page.{" "}
            </StepTitle2>
          </StepInstructionDiv>
        </StepContainer>

        <StepContainer sx={{ marginTop: "10px" }}>
          <StepNumber>3</StepNumber>
          <StepInstructionDiv
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: -5,
            }}
          >
            <StepTitle3 style={{ paddingTop: 0, marginTop: -5 }}>
              Verify that your code was installed.
            </StepTitle3>
            <VerifiedButton
              verified={verifyData?.doesCoveteScriptExist}
              errorProp={verifyError}
              onClick={isBrandUrlVerified}
            >
              {verifyLoading
                ? "Loading..."
                : verifyData?.doesCoveteScriptExist === true
                ? "Verified"
                : verifyData?.doesCoveteScriptExist === false
                ? "Could not verify, retry?"
                : verifyData?.doesCoveteScriptExist === undefined && verifyError
                ? "Could not verify, retry?"
                : "Verify Installation"}
            </VerifiedButton>
          </StepInstructionDiv>
        </StepContainer>
      </InstructionDiv>
    </div>
  );

  const widgetAppearance = () =>
    String(widgetState.option).includes("widget") && (
      <DisplayDiv widsec={widgetState.option === "widgetsection"}>
        <DisplayDivTitle style={{ maxHeight: "10%" }}>
          How{"  "}
          <span style={{ fontWeight: 600, color: "#1A1B24" }}>
            widget form
          </span>{" "}
          will appear on your platform
        </DisplayDivTitle>
        {data?.brandUrl ? (
          <>
            <iframe
              style={{
                width: "100%",
                height:
                  widgetState.option === "widgetsection" ? "300px" : "85%",
                overflow: "hidden",
              }}
              src={data?.brandUrl}
            />
            <div
              style={{
                display: "flex",
                justifyContent: justifyPosition(),
                position: "relative",
                top: "-50%",
                marginTop: widgetState.option === "widgetsection" && "-30%",
              }}
            >
              {widgetState?.position === "bottom-right" ? (
                <WidgetDisplayR
                  height="200"
                  shift="-100"
                  leaveReview={widgetState?.leaveReview}
                />
              ) : (
                <WidgetDisplay
                  height="200"
                  shift="100"
                  leaveReview={widgetState?.leaveReview}
                />
              )}
            </div>
          </>
        ) : (
          <img src={WidgetSvg} />
        )}
      </DisplayDiv>
    );

  // const sectionAppearance = () =>
  //   String(widgetState.option).includes("section") && (
  //     <DisplayDiv widsec={widgetState.option === "widgetsection"}>
  //       <DisplayDivTitle style={{ maxHeight: "10%" }}>
  //         How{"  "}
  //         <span style={{ fontWeight: 600, color: "#1A1B24" }}>
  //           section form
  //         </span>{" "}
  //         will appear on your platform
  //       </DisplayDivTitle>
  //       <SectionDiv>
  //         <div>
  //           <SectionHeader style={{ textAlign: widgetState.textAlignment }}>
  //             {widgetState.sectionHeader}
  //           </SectionHeader>
  //           <SubSectionHeader style={{ textAlign: widgetState.textAlignment }}>
  //             {widgetState.paragraph}
  //           </SubSectionHeader>
  //           <ReviewContainer>
  //             {widgetState?.leaveReview && <ReviewDiv>Leave review</ReviewDiv>}
  //           </ReviewContainer>
  //           <VideoDiv>
  //             {widgetState?.sectionStyle === "classic" && (
  //               <img src={SecWidgetFull1} />
  //             )}
  //             {widgetState?.sectionStyle === "standard" && (
  //               <img src={SecWidgetFull2} />
  //             )}
  //             {widgetState?.sectionStyle === "flex" && (
  //               <img src={SecWidgetFull3} />
  //             )}
  //           </VideoDiv>
  //         </div>
  //       </SectionDiv>
  //     </DisplayDiv>
  //   );

  useEffect(() => {
    if (scrollToReview) {
      // divRef?.current?.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({ top: 700, behavior: "smooth" });
      // setScrollToReview(false);
    }
  }, [scrollToReview]);
  return (
    <Container>
      <Grid container spacing={2} sx={{ mb: 0 }}>
        <Grid item xs={12} md={12}>
          <Title isMobile={isMobile} style={{ marginBottom: "16px" }}>
            Installation
          </Title>
          {/* <LeftTitle style={{ marginTop: 0 }}>Layout form</LeftTitle> */}
          {/* <TabDiv style={{ marginBottom: "24px" }}>
            <TabButton
              onClick={() => handleChange("widget", "option")}
              active={widgetState?.option === "widget"}
            >
              <TabText>Widget</TabText>
            </TabButton>
            <TabButton
              onClick={() => handleChange("section", "option")}
              active={widgetState?.option === "section"}
            >
              <TabText>Section</TabText>
            </TabButton>
            <TabButton
              onClick={() => handleChange("widgetsection", "option")}
              active={widgetState?.option === "widgetsection"}
            >
              <TabText>Widget & Section</TabText>
            </TabButton>
          </TabDiv> */}
          <LeftDescription style={{ marginBottom: "16px" }}>
            You can get{" "}
            <span style={{ fontWeight: 700, color: "#1A1B24" }}>Covete</span>{" "}
            running on your website with 3 easy steps.{" "}
            <span style={{ fontWeight: 700, color: "#1A1B24" }}>
              (Approx 5 mins)
            </span>
          </LeftDescription>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{ paddingTop: 0, marginBottom: isMobile && "50px" }}
        >
          {/* {sectionComponent()}
          {widgetComponent()} */}
          <DisplayDiv>
            <DivCont>
              {widgetContent()}
              {/* {sectionContent()} */}
            </DivCont>
          </DisplayDiv>
        </Grid>
        <>
          {false && (
            <Grid
              item
              xs={12}
              md={12}
              sx={{ paddingTop: 0, marginBottom: isMobile && "50px" }}
            >
              <GoogleRvInstall
                brandId={brandId}
                isMobile={isMobile}
                widgetData={data}
                ref={divRef}
                reload={reload}
              />
            </Grid>
          )}
        </>
        {/* <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
          {widgetAppearance()}
          {sectionAppearance()}
        </Grid> */}
      </Grid>
    </Container>
  );
}
