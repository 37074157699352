import React, { useState, useEffect } from "react";

const NumberBox = ({ count, duration }) => {
  const [currentCount, setCurrentCount] = useState(0);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    let animationFrameId;

    const startAnimation = (timestamp) => {
      if (!startTime) {
        setStartTime(timestamp);
      }

      const elapsed = timestamp - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const current = Math.floor(progress * count);

      setCurrentCount(current);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(startAnimation);
      }
    };

    animationFrameId = requestAnimationFrame(startAnimation);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [count, duration]);

  return <div>{currentCount}</div>;
};

export default NumberBox;

