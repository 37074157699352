import styled from "styled-components";

export const StyledFrame = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 52px;
  position: relative;

  & .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 42px;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    @media screen and (max-width: 768px) {
      margin-bottom: 2rem;
    }
  }

  & .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 18px;
    position: relative;
    width: 100%;
  }

  & .text-con {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans";
    font-style: normal;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 38.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    /* Add media queries for smaller screens */
    @media screen and (max-width: 768px) {
      // width: 100%;
      font-size: 26px;
    }
  }

  & .text-wrapper-2 {
    color: #242423;
    font-family: "DM Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
    width: 100%;
    @media screen and (max-width: 768px) {
    flex-direction: column;
    }
  }

  & #monthly-subscription {
    align-items: center;
    background-color: #ffffff;
    border: 1.5px solid;
    border-color: #ECECF2;
    border-radius: 11px;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    gap: 12px;
    height: 86px;
    padding: 15px 16px;
    position: relative;
    width: 230px;
    /* Add hover effect */
    &:hover {
      border-color: #007bf7;
    }
  
    /* Add active effect */
    &:active {
      border-color: #007bf7;
      box-shadow: 0px 0px 4px #007bf766;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  & .div-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    position: relative;
  }

  & .text-wrapper-3 {
    color: #5D6272;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 18px;
    position: relative;
  }

  & .text-wrapper-4 {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .save-wrapper {
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    opacity: 0;
    padding: 5px 12px;
    position: relative;
  }

  & .save {
    color: #ffffff;
    font-family: "DM Sans", Helvetica;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  & .path {
    height: 14px;
    left: 189px;
    position: absolute;
    top: 15px;
    width: 19px;
    @media screen and (max-width: 768px) {
      height: 14px;
      width: 19px;
      top: 18px;
      left: 285px;
    }
    @media screen and (max-width: 430px) {
      left: 355px;
    }
    @media screen and (max-width: 414px) {
      left: 342px;
    }
    @media screen and (max-width: 390px) {
      left: 320px;
    }
    @media screen and (max-width: 375px) {
      left: 300px;
    }
  }

  & #yearly-subscription {
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #ECECF2;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 86px;
    justify-content: center;
    padding: 15px 16px;
    position: relative;
    width: 230px;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    /* Add hover effect */
    &:hover {
      border-color: #007bf7;
    }
  
    /* Add active effect */
    &:active {
      border-color: #007bf7;
      box-shadow: 0px 0px 4px #007bf766;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .subscription-option {
    /* Define your inactive styles here */
    border: 1px solid #000;
    /* Add any other styles for inactive here */
  }
  
  /* Active style for subscription-option */
  .subscription-option.selected {
    /* Define your active styles here */
    border: 1px solid #007bff;
    /* Add any other styles for active here */
  }

  & .div-wrapper {
    align-items: center;
    background-color: #000;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 5px 12px;
    position: relative;
  }

  & .div-7 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 494px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  & .text-wrapper-5 {
    color: #242423;
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-8 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  & .frame-wrapper-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }

  & .div-9 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  & .text-wrapper-6 {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .vector {
    align-self: stretch;
    height: 1px;
    margin-top: -0.5px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  & .img {
    align-self: stretch;
    height: 1px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }

  & .text-wrapper-7 {
    color: #000;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  /* Add media queries for smaller screens */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
    display: inline-flex;
    flex: 0 0 auto;
  }
  /* Add media queries for even smaller screens */
  @media (max-width: 480px) {
    gap: 16px;
  }
`;