import React from "react";

export default function ({ style, active, changeHandler }) {
  return (
    <div style={style} onClick={() => changeHandler && changeHandler("left")}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {active && <rect width="32" height="32" rx="4" fill="#D9EAF8" />}

        <path
          d="M8 8.5H24"
          stroke="#323232"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 13.5H20"
          stroke="#323232"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 18.5H24"
          stroke="#323232"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 23.5H16"
          stroke="#323232"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
