import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  width: 352px;
  max-width: 100%;
  background: var(--neutral-palette-platinum-platinum-25, #f7f7f8);
`;

export const SubContainer1 = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const SubContainer1_1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
`;

export const SubContainer1_1Item1 = styled(Box)`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 8px 6.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #8ec7f3;
  background: #d8eeff;
  color: #31a5ff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const SubContainer1_1Item2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

export const SubContainer1_1Item2_1 = styled(Box)`
  color: var(--black, #1a1b24);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
`;

export const SubContainer1_1Item2_2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SubContainer1_1Item2_2_1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--dark-grey-palette-primary-black-black-100, #000);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
`;

export const SubContainer1_1Item2_2_2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-50, #8c8d91);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 13px */
`;

export const SubContainer1_2 = styled(Box)`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(
    --dark-grey-palette-primary-french-blue-french-blue-100,
    #007bf7
  );
  color: var(--White-Color, #fff);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
`;

export const SubContainer2 = styled(Box)`
  align-self: stretch;
  color: var(--dark-grey-palette-rasin-black-rasin-black-75, #53545b);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  height: 93px;
  overflow: auto;
`;
