import { TextField } from "@mui/material";

export default ({ focusState, noheight, setFocusState, name, ...rest }) => {
  return (
    <TextField
      {...rest}
      sx={{
        ".MuiInputBase-root": {
          height: noheight ? undefined : "50px",
          borderRadius: "15px",
        },
        ":hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#1a1b24 !important",
            borderWidth: "1px !important",
          },
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#007bf7",
            borderWidth: "1px !important",
            boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
          },
        },
      }}
      onFocus={() => setFocusState({ ...focusState, [name]: true })}
      onBlur={() => setFocusState({ ...focusState, [name]: false })}
      inputProps={{ style: { height: noheight ? undefined : "16px" } }}
    />
  );
};
