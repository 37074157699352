import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";

export const GDiv = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const GDiv1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const GDiv1Text1 = styled(Box)`
  color: #000;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const GDiv1Text2 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
`;

export const GDivGrid = styled(Grid)`
  padding: 0px;
  gap: 36px;
  display: flex;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Section1 = styled(Grid)`
  display: flex;
  // width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  // flex-shrink: 0;
  // align-self: stretch;
  // border: 1px solid #ccc;
`;
export const Section1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
`;

export const Section1_1Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;

export const Section1_1Inp = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%;
`;

export const Section1_2 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const Section1_2Box = styled(Box)`
  display: flex;
  height: 216px;
  padding: 10px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--stroke-path-color, #ececf2);
  background: #fafafa;
`;

export const Section1_2BottomBox = styled(Box)`
  color: #515151;
  text-align: right;
  font-family: "DM Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  width: 100%;
`;

export const GDivHr = styled(Box)`
  background: linear-gradient(
    90deg,
    rgba(177, 177, 177, 0.15) 50%,
    #fcfbff 100%
  );
  height: 1px;
  width: 100%;
`;

export const Section1_3 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
`;

export const Section1_3_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 28px;
  align-self: stretch;
  width: 100%;
`;

export const Section1_3_1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;
export const Section1_3_1_1Text = styled(Box)`
  color: #1a1b24;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
`;

export const Section1_3_1_1_2 = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  border: 1px solid var(--stroke-path-color, #ececf2);
  background: #fafafa;
`;

export const Section1_3_1_2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Section1_3_1_2_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
export const Section1_3_1_2_1Text = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-75, #858995);
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
`;

export const Section1_3_1_2_1Box = styled(Box)`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  border: 1px solid var(--stroke-path-color, #ececf2);
  background: #fafafa;
`;
export const Section1_3_1_3 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const Section1_3_1_2Box = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

export const Section1_3_1_3x = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

export const Section2 = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  // flex: 1 0 0;
  // align-self: stretch;
`;

export const Section2_1 = styled(Box)`
  display: flex;
  height: max-content;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 15px;
  border: 1px solid var(--Element, #d4dae1);
  background: var(--neutral-palette-seasalt-seassalt-100, #f7f7f7);
`;

export const Section2_1Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

export const Section2_2 = styled(Box)`
  display: flex;
  padding: 24px;
  // flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  align-self: stretch;
  // height: calc(100% - 400px);
  border-radius: 15px;
  border: 1px solid var(--Element, #d4dae1);
  background: var(--neutral-palette-seasalt-seassalt-100, #f7f7f7);
`;

export const Section2_2Text1 = styled(Box)`
  align-self: stretch;
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
`;

export const Section2_2_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: space-between;
  // flex: 1;
  align-items: center;
  gap: 32px;
  width: 100%;
`;

export const Section2_2_1_1 = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Section2_2_1_2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 80%;
`;

export const Section2_2_1_2T1 = styled(Box)`
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  width: 100%;
`;

export const Section2_2_1_2T2 = styled(Box)`
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  width: 100%;
`;
export const Section2_2_1_3 = styled(Box)`
  display: flex;
  width: 80%;
  padding: 36px 72px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  background: var(--neutral-palette-platinum-platinum-50, #eeeef2);
`;

export const Section2_2_1_3T1 = styled(Box)`
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  width: 100%;
`;
export const Section2_2_1_3_1Box = styled(Box)`
  display: flex;
  // width: 526px;
  width: 100%;
  padding: 36px 72px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  background: var(--neutral-palette-platinum-platinum-50, #eeeef2);
`;

export const Section2_2_1_3_1Box1 = styled(Box)`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Successful, #45c160);
`;

export const Section2_2_1_3_1Box1T1 = styled(Box)`
  color: var(--White-Color, #fff);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;

export const Section2_2_1_3_1Box2 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const Section2_2_1_3_1Box2T = styled(Box)`
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const Section2_2_1_3_1Box2But = styled(Box)`
  display: flex;
  width: 225px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Bounced, #d93030);
  color: var(--White-Color, #fff);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;

export const Section2_2_1_3_1T3 = styled(Box)`
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;

export const Section2_2_1_4 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`;

export const Section2_2_1_4B = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;
export const Section2_2_1_4BT1 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

export const Section2_2_1_4B2 = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 22px;
`;

export const Section2_2_1_5 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;
export const Section2_2_1_5B1 = styled(Box)`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 6px;
  color: var(--White-Color, #fff);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  background: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
`;
export const Section2_2_1_5B2 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 9.8px;
`;
