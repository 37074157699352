import { Box } from "@mui/material";
import { StepTitle } from "../ReviewComp/styled";
import ViaPreview from "../../ViaPreview";
import { fetchResourceFromURI } from "../../../utils/format";
import VideoSnapshot from "video-snapshot";
import { useState, useEffect } from "react";

export default ({
  step,
  newStep,
  viaText,
  requestMessageText,
  isMobile,
  formstate,
  recordingData,
}) => {
  const [picUrlSrc, setPicUrlSrc] = useState();
  const picUrlFn = async () => {
    let { videoUrl } = recordingData;
    if (!videoUrl || String(videoUrl).trim() === "") return "";
    const vid = await fetchResourceFromURI(videoUrl);
    const snapshoter = new VideoSnapshot(vid);
    const previewSrc = await snapshoter.takeSnapshot();
    setPicUrlSrc(previewSrc);
  };

  useEffect(() => {
    picUrlFn();
  }, [recordingData]);

  return (
    <>
      {" "}
      {(newStep === 5 || step === 5) && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: isMobile ? "100%" : "100%",
              // display: "flex",

              // alignItems: "center",
              position: "relative",
            }}
          >
            <StepTitle style={{ fontSize: "24px" }}>
              Here's a preview!
            </StepTitle>
            <ViaPreview
              viaType={viaText}
              requestMessageText={requestMessageText}
              formstate={formstate}
              picUrl={picUrlSrc}
              isMobile={isMobile}
            />
          </Box>
        </Box>
      )}
    </>
  );
};
