import { Box } from "@mui/material";
import AddIcon from "../../assets/icons/Add.svg";
import {
  PreviousButton,
  PreviousButtonText,
} from "../../pages/OrganizationBrands/styled";
import { useEffect, useRef } from "react";
export default ({
  getRootProps,
  videoSrc,
  removeVideo,
  isDragActive,
  VideoSvg,
  getInputProps,
  fullWidth,
  isMobile,
  triggerOpen,
  setTriggerOpen,
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (triggerOpen && inputRef.current) {
      inputRef.current.click();
      setTriggerOpen(false);
    }
  }, [triggerOpen]);

  return (
    <div
      style={{
        width: "100%",
        // width: isMobile || fullWidth ? "100%" : "calc(100% - 230px)",

        height: "100%",
        cursor: "pointer",
        maxHeight: "672px",
      }}
    >
      <div
        {...getRootProps()}
        className="dropzone"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} ref={inputRef} />
        {videoSrc ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <video
              style={{
                width: "95%",
                height: "100%",
                // maxHeight: "300px",
                borderRadius: "15px",
                background: "black",
              }}
              controls
              onClick={(e) => e.stopPropagation()}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        ) : isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={VideoSvg} alt="video-img" />
            <p
              style={{
                width: "100%",
                marginTop: 14,

                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                color: "#1A1B24",
                textAlign: "center",
                cursor: "pointer",
                fontFamily: "DM Sans",
              }}
            >
              Drag and drop a video, or{" "}
              <span
                style={{
                  fontSize: 14,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "160%",
                  textDecorationLine: "underline",
                  color: "#007BF7",
                }}
              >
                Browsey
              </span>{" "}
              <br /> Minimum 1600px width recommended. Max 100MB
            </p>
          </Box>
        )}
      </div>
    </div>
  );
};
