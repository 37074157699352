// subscriptionSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'inactive', // Subscription status (e.g., 'active', 'inactive', 'canceled')
  plan: null, // Subscription plan details
  expirationDate: null, // Date when the subscription expires
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionStatus: (state, action) => {
      state.status = action.payload;
    },
    setSubscriptionPlan: (state, action) => {
      state.plan = action.payload;
    },
    setExpirationDate: (state, action) => {
      state.expirationDate = action.payload;
    },
  },
});

export const {
  setSubscriptionStatus,
  setSubscriptionPlan,
  setExpirationDate,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
