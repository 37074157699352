import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AnalyticsCard,
  AnalyticsImage,
  AnalyticsImageTwo,
  AnalyticsText,
  AnalyticsTextWrapper,
  ButtonWrapper,
  CardContainer,
  CardOneWrapper,
  CardSubDiv,
  Cards,
  CustomizeCard,
  CustomizeHeading,
  CustomizeSubText,
  CustomizeText,
  CustomizeWrapper,
  DashboardMainFrame,
  DashboardSubFrame,
  ExploreIconWrap,
  ExploreIconWrapFour,
  ExploreIconWrapThree,
  ExploreIconWrapTwo,
  ExploreImage,
  ExploreText,
  ExploreTextTwo,
  FirstImage,
  FrameWrapper,
  HeadingParagraph,
  ImageEllipse,
  LeadsCard,
  LeadsText,
  LeadsTextWrapper,
  MainRequestWrap,
  NewText,
  NewTextNumber,
  NewVideoWrapper,
  PublishedNumber,
  RequestButton,
  RequestText,
  ShareButton,
  ShareText,
  SmallHeadingFrame,
  SmallScreenCard,
  SmallScreenText,
  SmallTextWrapper,
  StoryCard,
  StoryHeading,
  StoryImage,
  SubBodyWrap,
  TextFrameOne,
  TextWrapperTwo,
  TopBodyWrap,
  TotalDiv,
  TotalText,
  TotalTextWrapper,
  TotalWrapper,
  UnpublishedNumber,
  UnpublishedText,
  UnpublishedVideoWrapper,
  UploadText,
  UploadWrapper,
  VideoCard,
  VideoCardContent,
  VideoCardSubFrame,
  VideosText,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import Arrow from "../../assets/icons/GroupArrow.svg";
import Layout from "../../components/Layout";
import Whatsapp from "../../assets/icons/Whatsapp.svg";
import MiniFacebook from "../../assets/icons/MiniFacebook.svg";
import MiniInstagram from "../../assets/icons/MiniInstagram.svg";
import MiniLinkedIn from "../../assets/icons/MiniLinkedIn.svg";
import MiniPinterest from "../../assets/icons/MiniPinterest.svg";
import BlueAnalyticsLine from "../../assets/icons/BlueAnalyticsLine.svg";
import VideoEllipse from "../../assets/images/AvatarTwo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

function MainDashboard() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();

  const handleAnalyticsClick = () => {
    navigate("/analytics");
  };

  const handleVideospageClick = () => {
    navigate("/videopage");
  };

  const handleCustomizationClick = () => {
    navigate("/customizationpage");
  };

  return (
    <>
      {/* <DashboardContainer> */}
      <>
        <DashboardMainFrame>
          <DashboardSubFrame>
            {isSmallScreen ? (
              <>
                <SmallHeadingFrame>
                  <SmallTextWrapper>Hello, Laurels!</SmallTextWrapper>
                  <HeadingParagraph>
                    Powering success with customer stories.
                  </HeadingParagraph>
                </SmallHeadingFrame>
                <SmallScreenCard>
                  <SmallScreenText>
                    Ask customers to tell
                    <br />
                    their story.
                  </SmallScreenText>
                  
                  <ButtonWrapper>
                    <RequestButton>
                      <RequestText>Request Testimonials</RequestText>
                    </RequestButton>
                    <UploadWrapper>
                      <UploadText>Upload Testimonials</UploadText>
                    </UploadWrapper>
                  </ButtonWrapper>
                </SmallScreenCard>
              </>
            ) : (
              <FrameWrapper>
                <TopBodyWrap>
                  <TextFrameOne>
                    <TextWrapperTwo>Here’s today’s pulse.</TextWrapperTwo>
                    <HeadingParagraph>
                      Powering success with customer stories.
                    </HeadingParagraph>
                  </TextFrameOne>
                  <SubBodyWrap>
                    <UploadWrapper>
                      <UploadText>Upload Testimonials</UploadText>
                    </UploadWrapper>
                    <MainRequestWrap>
                      <RequestButton>
                        <RequestText>Request Testimonials</RequestText>
                      </RequestButton>
                    </MainRequestWrap>
                  </SubBodyWrap>
                </TopBodyWrap>
              </FrameWrapper>
            )}
            <Cards>
              <CardOneWrapper>
                <VideoCard>
                  <TotalDiv>
                    <TotalWrapper>
                      <TotalTextWrapper>
                        <TotalText>Total</TotalText>
                      </TotalTextWrapper>
                      <VideosText>0 Videos</VideosText>
                    </TotalWrapper>
                    <ImageEllipse>
                      <FirstImage alt="Ellipse" src={VideoEllipse} />
                      {/* <img className="ellipse-2" alt="Ellipse" src="ellipse-4159.svg" />
                  <img className="ellipse-3" alt="Ellipse" src="ellipse-4160.svg" />
                  <img className="ellipse-4" alt="Ellipse" src="ellipse-4161.svg" /> */}
                    </ImageEllipse>
                  </TotalDiv>
                  <VideoCardContent>
                    <VideoCardSubFrame>
                      <NewVideoWrapper>
                        <NewText>New/</NewText>
                        <NewTextNumber>00</NewTextNumber>
                      </NewVideoWrapper>
                      <NewVideoWrapper>
                        <NewText>Published/</NewText>
                        <PublishedNumber>00</PublishedNumber>
                      </NewVideoWrapper>
                      <UnpublishedVideoWrapper>
                        <UnpublishedText>Unpublished/</UnpublishedText>
                        <UnpublishedNumber>00</UnpublishedNumber>
                      </UnpublishedVideoWrapper>
                    </VideoCardSubFrame>
                    <ExploreIconWrap>
                      <ExploreText>Explore</ExploreText>
                      <ExploreImage
                        alt="Arrows diagrams"
                        src={Arrow}
                        onClick={handleVideospageClick}
                        style={{ cursor: "pointer" }}
                      />
                    </ExploreIconWrap>
                  </VideoCardContent>
                </VideoCard>
                <StoryCard>
                  <StoryHeading>
                    share your story <br />
                    with potential customers.
                  </StoryHeading>
                  <StoryImage>
                    <img src={Whatsapp} alt="Whatsapp" />
                    <img src={MiniFacebook} alt="Whatsapp" />
                    <img src={MiniInstagram} alt="Whatsapp" />
                    <img src={MiniLinkedIn} alt="Whatsapp" />
                    <img src={MiniPinterest} alt="Whatsapp" />
                  </StoryImage>
                  <ShareButton>
                    <ShareText>Share Testimonials</ShareText>
                  </ShareButton>
                </StoryCard>
              </CardOneWrapper>
              <CardContainer>
                <CustomizeCard>
                  <CustomizeWrapper>
                    <CustomizeHeading>
                      <CustomizeText>Customize</CustomizeText>
                      <CustomizeSubText>
                        You can make it look even better.
                      </CustomizeSubText>
                    </CustomizeHeading>
                  </CustomizeWrapper>
                  <ExploreIconWrapTwo>
                    <ExploreTextTwo>Explore</ExploreTextTwo>
                    <ExploreImage
                      alt="Arrows diagrams"
                      src={Arrow}
                      onClick={handleCustomizationClick}
                      style={{ cursor: "pointer" }}
                    />
                  </ExploreIconWrapTwo>
                </CustomizeCard>
                <CustomizeCard>
                  <AnalyticsCard>
                    <AnalyticsTextWrapper>
                      <AnalyticsText>Analytics</AnalyticsText>
                      {/* <AnalyticsImage alt="Group" src={AnalyticsLine} /> */}
                      <AnalyticsImageTwo alt="Group" src={BlueAnalyticsLine} />
                    </AnalyticsTextWrapper>
                  </AnalyticsCard>
                  <ExploreIconWrapTwo>
                    <ExploreTextTwo>Explore</ExploreTextTwo>
                    <ExploreImage
                      alt="Arrows diagrams"
                      src={Arrow}
                      onClick={handleAnalyticsClick}
                      style={{ cursor: "pointer" }}
                    />
                  </ExploreIconWrapTwo>
                </CustomizeCard>
                <CustomizeCard>
                  <CustomizeHeading>
                    <LeadsTextWrapper>Leads</LeadsTextWrapper>
                    <LeadsText>
                      Contact info fetched from your website
                    </LeadsText>
                  </CustomizeHeading>
                  <ExploreIconWrapTwo>
                    <ExploreTextTwo>Explore</ExploreTextTwo>
                    <ExploreImage alt="Arrows diagrams" src={Arrow} />
                  </ExploreIconWrapTwo>
                </CustomizeCard>
              </CardContainer>
            </Cards>
            {/* <Wrap
          className="wrap-instance"
          frame="frame-48095711-2.svg"
          profileClassName="design-component-instance-node"
        /> */}
          </DashboardSubFrame>
        </DashboardMainFrame>
      </>
    </>
  );
}

export default MainDashboard;
