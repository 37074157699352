import * as React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowUp from "../../assets/images/ArrowUp.svg";
import Covete from "../../assets/icons/Covete.svg";
import LoginVideo from "../../assets/icons/loginVideo.mp4";
import {
  StyledAuthContainer,
  StyledAuthLogoContainer,
  StyledBannerHeaderText,
  StyledBannerText,
  StyledSpacedBannerHousingContainer,
  StyledSpacedColumContainer,
  StyledSpacedRowContainer,
} from "./styled";

/**
 * A reusable layout component for authentication-related pages or components.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The content to be rendered within the layout.
 * @param {string} props.bannerImage - The path to the banner image.
 * @param {string} props.headerBannerText - The header banner text.
 * @param {string} props.bannerText - The banner text.
 * @param {Object} props.style - Custom styles to be applied to the layout container.
 * @returns {React.ReactNode} The AuthLayout component.
 */
export default function AuthLayout({
  children,
  bannerImage,
  headerBannerText,
  bannerText,
  style,
}) {
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      spacing={2}
      sx={{
        ...style,
        height: "100%",
      }}
    >
      {children}
    </Grid>
  );
}
