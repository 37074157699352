import { Box, InputBase, Select } from "@mui/material";
import {
  ContactInfo,
  NavSearch,
  SortBoxItem,
  SortContainer,
  SortText,
  TableAnalytics,
  TableAnalyticsDirection,
  TableAnalyticsOne,
  TableAnalyticsOneText,
  TableAnalyticsOnesSubText,
  TableAnalyticsSort,
  TableAnalyticsTwo,
  UserDetailsText,
} from "./styled";
import { MenuItemC } from "../Customization/styled";
import {
  PreviousButton,
  PreviousButtonText,
} from "../OrganizationBrands/styled";
import Upload from "../../assets/icons/Upload.svg";
import Ascending from "../../assets/icons/Ascending.svg";
import SearchIcon from "../../assets/icons/SearchIcon.svg";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import Descending from "../../assets/icons/Descending.svg";
import MarkUp from "../../assets/icons/MarkUp.svg";
import Trash from "../../assets/icons/Trash.svg";
import GIcon from "../../assets/icons/GoogleIcon.svg";
import LeadsGroupModal from "../../components/leadsGroup";
import { useState } from "react";
import ColorButton from "../../components/ColorButton";
import { useSelector } from "react-redux";

export default ({
  mItem,
  setMItem,
  total,
  isMobile,
  rows,
  reload,
  focusState,
  searchQuery,
  handleSearch,
  setFocusState,
  sortRef,
  setShowSort,
  showSort,
  allChecked,
  selectedSortText,
  setSelectedSortText,
  sortDirection,
  newRecordCount,
  selectedSortOption,
  setUplDisplay,
  selRef,
  showSel,
  setShowSel,
  idsCount,
  triggerReqGoogleReview,
  triggerDelete,
}) => {
  const [groupLeadsModal, setGroupLeadsModal] = useState(false);
  let arrSelect = [
    { value: "leads", label: "Leads" },
    { value: "uplContacts", label: "Uploaded Contacts" },
  ];
  const menu = useSelector((state) => state.menu);
  const handleListClose = () => {
    setGroupLeadsModal(false);
  };
  let arrSelect2 = [
    "Alphabetical",
    "Recently Updated",
    "Contacted",
    "Not Contacted",
  ];
  console.log({ allChecked });
  return (
    <TableAnalytics isMobile={isMobile}>
      <TableAnalyticsOne isMobile={isMobile}>
        {/* <FullAnalyticsHeaderTwo>Leads</FullAnalyticsHeaderTwo> */}

        <TableAnalyticsSort
          isMobile={isMobile}
          ref={selRef}
          onClick={() => setShowSel(!showSel)}
          focus={showSel}
          // className="navigation-img"
          tabIndex="0"
          sx={{
            width: "220px",
            marginLeft: "0px !important",
            justifyContent: "space-between !important",
            padding: "12px !important",
          }}
        >
          <UserDetailsText
            style={{
              color: "#5D6272",
              whiteSpace: "nowrap",
              fontSize: isMobile && "12px",
              fontWeight: isMobile && 400,
              width: "120px",
            }}
          >
            {arrSelect.find((elm) => elm.value === mItem)?.label}
          </UserDetailsText>
          <img src={ArrowDown} alt="direction" />

          {showSel && (
            <SortContainer sx={{ width: "220px !important" }}>
              {arrSelect.map((elm, ind) => (
                <SortBoxItem
                  sx={{
                    display: "flex",
                    paddingLeft: "10px",
                    width: "100%",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setMItem(elm.value);
                    reload({
                      input: {
                        br_id: menu?.id,
                        tag: elm.value,
                        searchText: searchQuery,
                      },
                    });
                  }}
                  key={`${ind}SL`}
                >
                  <SortText>{elm.label}</SortText>
                </SortBoxItem>
              ))}
            </SortContainer>
          )}
        </TableAnalyticsSort>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            // background: "#F7FBEF",
            background: "#f7f7f7",
            // padding: "9px 10px",
            padding: "10px 0",
            justifyContent: isMobile ? "space-between" : undefined,
            width: isMobile ? "fit-content" : undefined,
            gap: "16px",
            // height: "44px",
            // borderRadius: "8px",
            borderRadius: "12px",
            marginLeft: !isMobile ? "1rem" : undefined,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              padding: "0 12px",
              borderRadius: "10px",
            }}
          >
            <TableAnalyticsOneText>Total</TableAnalyticsOneText>
            <TableAnalyticsOnesSubText style={{ color: "#45c160" }}>
              {total ?? "0"}
            </TableAnalyticsOnesSubText>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              padding: "0 12px",
              borderRadius: "10px",
            }}
          >
            <TableAnalyticsOneText>New</TableAnalyticsOneText>
            <TableAnalyticsOnesSubText>
              {newRecordCount}
            </TableAnalyticsOnesSubText>
          </Box>
        </Box>
      </TableAnalyticsOne>

      <TableAnalyticsTwo
        isMobile={isMobile}
        sx={{ gap: !isMobile ? "10px" : undefined }}
      >
        {idsCount > 0 ? (
          <>
            <ContactInfo>
              {`${!searchQuery && allChecked ? total : idsCount} Contact${
                idsCount > 1 ? "s" : ""
              } selected`}
            </ContactInfo>
            {/* <PreviousButton
              style={{
                width: "250px",

                // marginLeft: "25px",
                height: "48px",
                justifyContent: "center",
              }}
              onClick={triggerReqGoogleReview}
              // focus={dialogUploadOpen}
            >
              <img src={GIcon} alt="GIcon" />
              <PreviousButtonText style={{ fontWeight: 600 }}>
                Request Google Reviews
              </PreviousButtonText>
            </PreviousButton> */}

            <PreviousButton
              style={{
                width: "250px",

                // marginLeft: "25px",
                height: "48px",
                justifyContent: "center",
              }}
              onClick={triggerDelete}
              // focus={dialogUploadOpen}
            >
              <img src={Trash} alt="del" />
              <PreviousButtonText style={{ fontWeight: 600 }}>
                Delete Selected Leads
              </PreviousButtonText>
            </PreviousButton>
          </>
        ) : (
          <>
            <ColorButton
              title="Group list"
              background="black"
              textColor="#fff"
              hoverColor="black"
              hoverBackgroundColor="#464646"
              borderWidth="2px"
              activeBorderColor="grey"
              activeBgColor="grey"
              hoverBorder="grey"
              buttonTextStyle={{ color: "white" }}
              innerStyle={{ color: "green" }}
              onClick={() => setGroupLeadsModal(true)}
              style={{ marginRight: "16px" }}
            />
            <PreviousButton
              style={{
                width: "212px",

                // marginLeft: "25px",
                height: "48px",
                justifyContent: "center",
              }}
              // onClick={() => fileInputRef?.current?.click()}
              onClick={() => setUplDisplay(true)}
              // onClick={handleUploadDialog}
              // focus={dialogUploadOpen}
            >
              <img src={Upload} alt="Upload" />
              <PreviousButtonText style={{ fontWeight: 600 }}>
                Upload Contacts
              </PreviousButtonText>
            </PreviousButton>

            <NavSearch
              style={{ marginTop: "0rem" }}
              className="navigation-img"
              tabIndex="0"
              focus={focusState?.srch}
            >
              <img
                src={SearchIcon}
                alt="share-icon"
                style={{ height: 20, margin: "0 5px" }}
              />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                style={{ border: "none" }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchQuery}
                onFocus={() => {
                  setFocusState({ ...focusState, srch: true });
                }}
                onBlur={() => {
                  setFocusState({ ...focusState, srch: false });
                }}
                onChange={handleSearch}
              />
            </NavSearch>
            <TableAnalyticsSort
              isMobile={isMobile}
              ref={sortRef}
              onClick={() => setShowSort(!showSort)}
              focus={showSort}
              // className="navigation-img"
              tabIndex="0"
              sx={{
                width: "230px",
                marginLeft: "0px !important",
                justifyContent: "space-between !important",
                padding: "12px !important",
              }}
            >
              <UserDetailsText
                style={{
                  fontSize: isMobile && "14px",
                  fontWeight: isMobile && 400,
                }}
              >
                Sort:{" "}
              </UserDetailsText>
              <UserDetailsText
                style={{
                  color: "#5D6272",
                  whiteSpace: "nowrap",
                  fontSize: isMobile && "12px",
                  fontWeight: isMobile && 400,
                  width: "150px",
                }}
              >
                {selectedSortText}
              </UserDetailsText>
              <img src={ArrowDown} alt="direction" />

              {showSort && (
                <SortContainer
                  sx={{
                    width: "230px !important",
                    height: "200px !important",
                    marginLeft: "-12px",
                  }}
                >
                  {arrSelect2.map((el, idx) => (
                    <SortBoxItem
                      sx={{
                        display: "flex",
                        paddingLeft: "10px",
                        width: "100%",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        reload({
                          input: {
                            br_id: menu?.id,
                            selectedSortText: el,
                            tag: mItem,
                          },
                        });
                        setSelectedSortText(el);
                      }}
                      key={`Sel${idx}`}
                    >
                      <SortText>{el}</SortText>
                    </SortBoxItem>
                  ))}
                </SortContainer>
              )}
            </TableAnalyticsSort>
          </>
        )}
        <LeadsGroupModal
          open={groupLeadsModal}
          handleClose={handleListClose}
          reloadLeadList={reload}
        />
      </TableAnalyticsTwo>
    </TableAnalytics>
  );
};
