import { Dialog, DialogContent, TextField } from "@mui/material";
import {
  UplBTInner,
  UplBottom,
  UplInner,
  UplInner1,
  UplInnerText1,
  UplInnerText2,
  UplTop,
} from "./styled";
import ColorButton from "../../components/ColorButton";
import CloseIcon from "../../assets/icons/RemoveIcon.svg";
import XlsxIcon from "../../assets/images/xlsx.svg";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPLOAD_CSV } from "../../graphql/mutations/leads";
import { useEffect, useState } from "react";
import CSVFormat from "../../assets/format.csv";
import TransparentButton from "../../components/TransparentButton";
import {
  CREATE_LEAD_GROUP,
  DELETE_SINGLE_LEAD_GROUP,
} from "../../graphql/mutations/leadgroup";
import CircularProgress from "@mui/material/CircularProgress";

export default ({ openDialog, onClose, reload, br_id }) => {
  const [uploadCSV] = useMutation(UPLOAD_CSV);
  const [deleteLeadGroup] = useMutation(DELETE_SINGLE_LEAD_GROUP);
  const [createLeadGroup] = useMutation(CREATE_LEAD_GROUP);
  const [fileInput, setFileInput] = useState(null);
  const [listName, setListName] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const onDrop = async (selectedFile) => {
    // console.log(selectedFile);
    let fileToUse = selectedFile[0];
    if (!fileToUse || fileToUse.type !== "text/csv") {
      enqueueSnackbar("File Type is not CSV", { variant: "error" });
      return;
    }
    setFileInput(fileToUse);
  };
  // Use the useDropzone hook to enable drag-and-drop functionality
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    }, // Accept any csv file type
    maxFiles: 1, // Allow only one file
  });

  const handleSubmit = async () => {
    try {
      setBtnLoading(true);
      if (!listName) {
        setIsTouched(true);
        return;
      }
      let { data: groupData, errors: groupError } = await createLeadGroup({
        variables: {
          input: {
            name: listName,
            brand: br_id,
          },
        },
      });

      if (groupError) {
        enqueueSnackbar(`Group Creation failed: ${groupError[0].message}`, {
          variant: "error",
        });
        return;
      }
      let { data, errors } = await uploadCSV({
        variables: {
          file: fileInput,
          br_id,
          leadgroup: groupData?.createLeadGroup?._id,
        },
      });
      // console.log(data, errors, "Upload APolo");
      // console.log("Data uploaded:", data); // Output the uploaded data
      if (!data && errors && errors.length > 0) {
        enqueueSnackbar(
          `Upload Failed: ${
            errors[0] && errors[0].message ? errors[0].message : ""
          }`,
          {
            variant: "error",
          }
        );
        await deleteLeadGroup({
          variables: { id: groupData?.createLeadGroup?._id },
        });
      } else {
        enqueueSnackbar("Upload successful", { variant: "success" });
        reload && reload();
        onClose();
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Error Uploading File", { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    setFileInput(null);
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          padding: "1% 1% 3% 1%",
          boxShadow: "0px 7px 30px 0px rgba(0, 0, 0, 0.14)",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <UplTop>
          <img
            alt="close"
            src={CloseIcon}
            onClick={onClose}
            style={{ cursor: "pointer", width: "24px", height: "24px" }}
          />
        </UplTop>
        <UplBottom>
          <TextField
            error={!listName && isTouched}
            margin="normal"
            name="listName"
            value={listName}
            onChange={(e) => {
              setIsTouched(true);
              setListName(e.target.value);
            }}
            label=""
            placeholder="Group Name"
            type="text"
            id="listName"
            sx={{
              margin: "8px 0px 8px",
              ".MuiInputBase-root": {
                // height: "50px",
                borderRadius: "15px",
              },
              ":hover": {
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#1a1b24 !important",
                  borderWidth: "1px !important",
                },
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#007bf7",
                  borderWidth: "1px !important",
                  boxShadow: "0px 0px 4px 0px rgba(69, 193, 126, 0.40)",
                },
              },
            }}
            helperText={!listName && isTouched && "List Name is required"}
            onFocus={() => setIsTouched(true)}
          />
          <UplInner>
            <UplInner1 {...getRootProps()}>
              <input {...getInputProps()} />
              <img src={XlsxIcon} />
              <UplInnerText1>
                {fileInput ? (
                  fileInput.name
                ) : isDragActive ? (
                  <>Drop the file here</>
                ) : (
                  <>
                    Drag a CSV .csv file, or{" "}
                    <UplInnerText2>Browse</UplInnerText2>
                  </>
                )}
              </UplInnerText1>
            </UplInner1>
          </UplInner>
          <UplBTInner>
            {/* <ColorButton
              style={{ cursor: "pointer" }}
              width="45%"
              background="#FFF"
              title="Discard"
              alt="Discard"
              fontWeight="0"
              onClick={onClose}
              buttonTextStyle={{ color: "#1A1B24" }}
            /> */}
            <TransparentButton width="45%" title="Discard" onClick={onClose} />
            <ColorButton
              style={{ cursor: "pointer" }}
              width="45%"
              background="#1A1B24"
              title={btnLoading ? <CircularProgress /> : "Upload Contacts"}
              alt="Upload Contacts"
              fontWeight="0"
              disabled={!fileInput || !listName || btnLoading}
              onClick={handleSubmit}
            />
          </UplBTInner>
        </UplBottom>
        <a href={CSVFormat} target="_blank">
          Download Format
        </a>
      </DialogContent>
    </Dialog>
  );
};
