import { Box, Typography } from "@mui/material";
import React from "react";
import { Handle, useStore } from "reactflow";

const ConditionHandle = ({ data, ...props }) => {
  const isSourceConnectedTwiceDifferById = useStore((s) => {
    for (let index = 0; index < s.edges.length; index++) {
      const edge = s.edges[index];
      if (edge.source === props.nodeId && edge.sourceHandle === props.id) {
        return true;
      }
    }
    if (!s.edges.some((edge) => edge.target === props.nodeId)) return true;
    return false;
  });

  return (
    <Handle
      {...props}
      isConnectableStart={!isSourceConnectedTwiceDifferById}
      style={{
        zIndex: 1,
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          pointerEvents: "none",
        }}
      >
        <Box>
          <Typography variant="caption" sx={{ fontFamily: "Dm Sans" }}>
            {props.id === "yes" ? "Yes" : "No"}
          </Typography>
        </Box>
      </div>
    </Handle>
  );
};

export default ConditionHandle;
