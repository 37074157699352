import { useEffect, useRef, useState } from "react";
import { Container, DivText, OptionDiv, OptionItem } from "./styled";
import Dots from "../../assets/images/Dots.svg";
import React from 'react';
/**
 * A component that displays a button with ellipsis (...) to trigger a dropdown menu.
 * @component
 * @param {Object} props - The component's props.
 * @param {Array} props.content - An array of options to display in the dropdown menu.
 * @param {number} props.index - The index of the component.
 * @param {boolean} props.last - A boolean indicating whether this is the last component.
 * @returns {JSX.Element} - The rendered component.
 */
export default function EllipsisLink({ content, index, last }) {
  const [display, setDisplay] = useState(false);
  const divRef = useRef(null);

  /**
   * Displays the content of the dropdown menu.
   * @returns {JSX.Element} - The rendered dropdown menu content.
   */
  const displayContent = () => {
    if (content instanceof Array) {
      return content.map((el, ind, arr) => {
        return (
          <OptionItem
            last={arr.length - 1 === ind}
            onClick={() => {
              el?.link && el.link();
              setDisplay(false);
            }}
            key={`${index}_${ind}`}
          >
            {el?.title}
          </OptionItem>
        );
      });
    }
    return null;
  };

  useEffect(() => {
    /**
     * Closes the dropdown when clicking outside of it.
     * @param {MouseEvent} e - The mouse event object.
     */
    const closeOutsideDiv = (e) => {
      if (divRef.current && display && !divRef.current.contains(e.target)) {
        setDisplay(false);
      }
    };
    document.addEventListener("mousedown", closeOutsideDiv);

    return () => document.removeEventListener("mousedown", closeOutsideDiv);
  }, [divRef, display]);

  return (
    <Container ref={divRef}>
      <DivText onClick={() => setDisplay(!display)}>
        {" "}
        <img src={Dots} alt="ellipsis-icon" />
      </DivText>
      {display && <OptionDiv>{displayContent()}</OptionDiv>}
    </Container>
  );
}
