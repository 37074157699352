import { Box } from "@mui/material";
import React from "react";
import {
  ColorBlankOne,
  ColorBlankTwo,
  ColorButtonOverall,
  ColorButtonText,
} from "./styled";
import Camera from "../../assets/icons/Camera.svg";

/**
 * ColorButton component represents a clickable button with customizable background, title, and optional image.
 * @param {Object} props - The component's props.
 * @param {string} props.background - The background color of the button.
 * @param {string} props.title - The title or text displayed on the button.
 * @param {string} props.width - The width of the button.
 * @param {string} props.image - The optional image to display on the button.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @param {number} props.order - The order of the button.
 * @param {string} props.pointerEvents - CSS property for pointer events.
 * @param {boolean} props.isMobile - Indicates if the component is being used in a mobile context.
 * @returns {JSX.Element} - Rendered ColorButton component.
 */

function ColorButton({
  background,
  title,
  width,
  image,
  startIcon,
  onClick,
  order,
  pointerEvents,
  isMobile,
  buttonTextStyle = {},
  innerStyle = {},
  imgStyle = {},
  disabled,
  hoverColor,
  hoverBackgroundColor,
  hoverBorder,
  activeBgColor,
  activeBorderColor,
  borderWidth, // New prop for border width
  borderColor, // New prop for border color
  ...rest
}) {
  return (
    <ColorButtonOverall
      width={width}
      onClick={() => !disabled && onClick && onClick()}
      pointerEvents={pointerEvents || disabled}
      disabled={disabled}
      startIcon={startIcon}
      background={background}
      borderWidth={borderWidth} // Pass borderWidth prop
      borderColor={borderColor} // Pass borderColor prop
      {...rest}
    >
      <ColorBlankTwo
        background={background}
        pointerEvents={pointerEvents}
        isMobile={isMobile}
        style={innerStyle}
        startIcon={startIcon}
        hoverBackgroundColor={hoverBackgroundColor}
        hoverBorder={hoverBorder}
        hoverColor={hoverColor}
        borderWidth={borderWidth} // Pass borderWidth prop
        borderColor={borderColor} // Pass borderColor prop
        activeBgColor={activeBgColor}
        activeBorderColor={activeBorderColor}
        disabled={disabled}
      >
        {image ? (
          <>
            <ColorButtonText
              pointerEvents={pointerEvents}
              order={order}
              isMobile={isMobile}
              background={background}
              borderWidth={borderWidth} // Pass borderWidth prop
              borderColor={borderColor} // Pass borderColor prop
              startIcon={startIcon}
              style={buttonTextStyle}
            >
              {title}
            </ColorButtonText>

            <img
              style={{
                marginTop: isMobile ? "0px" : "2px",
                marginLeft: "5px",
                width: isMobile ? "17px" : "30px",
                height: isMobile ? "17px" : "30px",
                ...imgStyle,
              }}
              src={image}
              alt=""
            />
          </>
        ) : (
          <ColorButtonText
            pointerEvents={pointerEvents}
            style={buttonTextStyle}
            startIcon={startIcon}
            isMobile={isMobile}
            borderWidth={borderWidth} // Pass borderWidth prop
            borderColor={borderColor} // Pass borderColor prop
            background={background}
            hoverColor={hoverColor}
          >
            {title}
          </ColorButtonText>
        )}
      </ColorBlankTwo>
    </ColorButtonOverall>
  );
}

export default ColorButton;
