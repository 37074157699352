import React from "react";
import { imgFormat, vidFormat } from "../../utils/format";

export const emailBadgeHtml = (
  widgetState,
  userData,
  socialLinkArray,
  approvedVideos
) => {
  const pictureUrl = () => {
    if (widgetState?.picture || userData?.findUserById?.thumbnail) {
      return `${process.env.REACT_APP_AWS_S3_URL}Image${
        widgetState?.picture ?? userData?.findUserById?.thumbnail
      }.jpeg`;
    }
    return null;
  };
  let code = `<div style=" width:100%; max-width:550px; ">
  <table style=" width:100%; max-width:550px; border-collapse:collapse; ">
   <tbody>
      <tr>
         <td style=" padding:0; margin:0; vertical-align:top; width:70px; border: 0px; ">
            <table style=" width:100%; border-collapse:collapse; ">
               <tbody>
                  <tr>
                     <td style=" margin:0; padding-top:0px; padding-bottom:0px; padding-left:0px; padding-right:19px; text-align:left; border:0px; ">
                        <img style=" border-radius:50%; height:57px " width="57" src="${pictureUrl()}" border="0" alt=""> 
                     </td>
                  </tr>
               </tbody>
            </table>
         </td>
         <td style=" padding:0; margin:0; vertical-align:top; border: 0px; ">
            <table style=" width:100%; border-collapse:collapse; ">
                <tbody>
                    <tr style="display: ${
                      widgetState?.text1 ? "block" : "none"
                    }">
                        <td style=" margin:0; padding:0; padding-top:0px; padding-bottom:4px; padding-left:0px; padding-right:0px; border:0px; ">
                            <p style=" line-height:1.2; margin:0; text-align:left; font-weight:bold; font-style:normal; text-decoration:none; font-family:Helvetica; font-size:14px; color:#1a1b24; "> ${
                              widgetState?.text1
                            } </p>
                        </td>
                    </tr>
                <tr style="display: ${widgetState?.text2 ? "block" : "none"}">
                    <td style=" margin:0; padding:0; padding-top:4px; padding-bottom:4px; padding-left:0px; padding-right:0px; border:0px; ">
                        <p style=" line-height:1.2; margin:0; text-align:left; font-weight:normal; font-style:normal; text-decoration:none; font-family:Helvetica; font-size:14px; color:#858995; "> ${
                          widgetState?.text2
                        } </p>
                    </td>
                </tr>
                <tr style="vertical-align:center; display: ${
                  widgetState?.email ? "block" : "none"
                }">
                    <td style=" margin:0; padding-top:2px; padding-bottom:2px; padding-left:0px; padding-right:0px; border:0px; ">
                        <table style=" border-collapse:collapse; width:100%; max-width:550px; ">
                            <tr style="vertical-align:middle;">
                            <td style=" margin:0; padding:5px; text-align:center; border:0px; width:25px; line-height:1.2; "> <img src="https://asset.emailbadge.com/customize-image?icon=envelope&amp;color=%23858995&amp;size=md" width="15" alt="envelope"> </td>
                            <td style=" margin:0; padding:0; font-size:14px; text-align:left; font-weight:normal; font-style:normal; text-decoration:none; font-family:Helvetica; color:#858995; border:0px; ">
                                <p style=" margin:0; padding:0; text-align:left; line-height:1.2; color:#858995; "> ${
                                  widgetState?.email
                                } </p>
                            </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr style="vertical-align:center; display: ${
                  widgetState?.telephone ? "block" : "none"
                }">
                    <td style=" margin:0; padding-top:2px; padding-bottom:2px; padding-left:0px; padding-right:0px; border:0px; ">
                        <table style=" border-collapse:collapse; width:100%; max-width:550px; ">
                            <tr style="vertical-align:middle;">
                            <td style=" margin:0; padding:5px; text-align:center; border:0px; width:25px; line-height:1.2; "> <img src="https://asset.emailbadge.com/customize-image?icon=phone&amp;color=%23858995&amp;size=md" width="15" alt="phone"> </td>
                            <td style=" margin:0; padding:0; font-size:14px; text-align:left; font-weight:normal; font-style:normal; text-decoration:none; font-family:Helvetica; color:#858995; border:0px; ">
                                <p style=" margin:0; padding:0; text-align:left; line-height:1.2; color:#858995; "> ${
                                  widgetState?.telephone
                                } </p>
                            </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style=" padding-top:2px; padding-bottom:2px; padding-left:0px; padding-right:0px; text-align:left; border:0px; ">
                        <table style=" border-collapse:collapse; width:100%; margin:0; ">
                            <tr>
                                ${socialLinkArray
                                  .map((el) =>
                                    widgetState?.socialLinks &&
                                    widgetState?.socialLinks[el.type]
                                      ? `<td style=" border:0px; width:14px; text-align:left; padding-right:14px; "> <a href="" style="text-decoration:none;"> <img src="${el.url}" width="20" alt="facebook"> </a> </td>`
                                      : ""
                                  )
                                  .join("")}
                                <td style="padding:0;margin:0;border:0px"></td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr style="display: ${widgetState?.text3 ? "block" : "none"}">
                    <td style=" margin:0; padding:0; padding-top:4px; padding-bottom:0px; padding-left:0px; padding-right:0px; border:0px; ">
                        <p style=" line-height:1.2; margin:0; text-align:left; font-weight:normal; font-style:normal; text-decoration:none; font-family:Helvetica; font-size:14px; color:#858995; "> ${
                          widgetState?.text3
                        } </p>
                    </td>
                </tr>
                </tbody>
            </table>
            
            <table>
                <tr>
                    ${
                      widgetState?.testimonialVideos instanceof Array &&
                      widgetState?.testimonialVideos
                        .map((el) => {
                          let videoItem = approvedVideos.find(
                            (elm) => elm.videoId === el
                          );
                          return videoItem
                            ? `<td  align="left">
                                <div  style="width: 70px;
                                    height: 80.99px;
                                    border-radius: 15px;
                                    cursor: pointer;overflow:hidden;">
                                    <a href="${vidFormat(
                                      videoItem?.url,
                                      "",
                                      videoItem
                                    )}" >
                                      <img class="max-width" border="0" style="display:block; color:#000000; text-decoration:none; height:100%; height:auto !important; width:100%" width="60" alt="" data-proportionally-constrained="true" data-responsive="true" src="${imgFormat(
                                        videoItem?.url,
                                        "",
                                        videoItem
                                      )}"> 
                                    </a>
                                </div>                                
                              </td>`
                            : "";
                        })
                        .join("")
                    }
                   
                </tr>
            </table>
        </td>
        </tr>
        </tbody>      
     </table>
  </div> `;

  return code;
};
