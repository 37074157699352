import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export const PCContainer = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
  display: flex;

  // justify-content: space-between;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 32px;
`;

export const PChkBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PCC1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
`;

export const PCC1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  min-width: 40%;
`;
export const PCC1_T1 = styled(Box)`
  color: var(--Black, #000);
  text-align: center;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
`;

export const PCC1_T2 = styled(Box)`
  color: #242423;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
`;

export const PCC1_1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
`;

export const PCC1_2 = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const PCC1_2_1 = styled(Box)`
  display: flex;
  width: 270px;
  cursor: pointer;
  position: relative;
  height: 86px;
  padding: 15px 16px;
  align-items: center;
  gap: 12px;
  border: 1.5px solid transparent;
  ${(props) =>
    props?.active &&
    `border-radius: 11px;
  border: 1.5px solid #007bf7;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(69, 193, 126, 0.40);`};
`;

export const PCC1_2_1B = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
`;

export const PCC1_2_1BT1 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
`;
export const PCC1_2_1BT2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
`;
export const PCC1_2_1BT2A = styled(Box)`
  display: flex;
  align-items: center;
  gap: 18px;
`;

export const PCC1_2_1BT2_2 = styled(Box)`
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  color: var(--White-Color, #fff);
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const PCC1_3 = styled(Box)`
  display: flex;
  width: 494px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;
export const PCC1_3_1 = styled(Box)`
  color: #242423;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
`;

export const PCC1_3_2 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
export const PCC1_3_2Sect = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const PCC1_3_2Sect1 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

export const PCCHr = styled("hr")`
  color: black;
  width: 100%;
  height: 0;
  margin-bottom: 0px;
  margin-top: 0px;
`;

export const PCC1_3_2Sect2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;

export const PCC2 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  min-width: 40%;
`;
export const PCCardPanel = styled(Box)`
  display: flex;
  width: 483px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const PCCardP1 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-75, #858995);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 23.2px */
`;

export const PCCardP2 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
`;

export const PCCardPanel_1 = styled(Box)`
  display: flex;
  height: 50px;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #ececf2;
  background: #f9f9f9;
`;
export const PCC2_1 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  text-align: center;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
`;

export const PImage = styled("img")`
  position: absolute;
  top: 24%;
  right: 7%;
  display: ${(props) => (props?.active ? "block" : "none")};
`;
export const StyledElement = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
`;

export const HeadDiv = styled.div`
  background-color: #ffffff;
  height: auto;
  position: relative;
  max-width: 100%;
  display: flex;
`;

export const StyledFrameScreen = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: row;
  gap: 72px;
  position: relative;
  justify-content: ${(props) => !props?.isMobile && "space-between"};
  margin-top: 30px;
  padding: 16px;

  @media (min-width: 600px) {
    max-width: 1440px;
    padding: 40px 16px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    display: inline-flex;
    justify-content: center;
    width: 100%;
    gap: 42px;
    position: relative;
    margin-bottom: 3rem;
    -webkit-box-align: center;
    -webkit-box-pack: center;
  }
  & .nextButton {
    background-color: #1a1b24; /* Updated to the specified background color */
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 20px; /* Adjust the margin as needed */
    cursor: pointer;
    margin-bottom: 34px; /* New styling */
    position: relative; /* New styling */
    border-radius: 18px;
    font-family: "DM Sans";
    /* Media queries for responsive design */
    @media screen and (max-width: 430px) {
      min-width: 396px;
    }

    @media screen and (max-width: 414px) {
      min-width: 360px;
    }

    @media screen and (max-width: 375px) {
      min-width: 345px;
    }

    @media screen and (max-width: 360px) {
      min-width: 330px;
    }
  }
`;

export const MainArrow = styled.img`
   {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;
  }
`;

export const ArrowDiv = styled.div`
   {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
`;

export const ArrowDivWrapper = styled.div`
   {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    height: 19px;
    padding: 0px 0px 2px;
    position: relative;
  }
`;

export const ArrowText = styled.div`
   {
    color: rgba(26, 27, 36, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.5px;
    position: relative;
    width: fit-content;
  }
`;

export const ArrowMainDiv = styled.div`
   {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 299px;
    height: 24px;
  }
`;
