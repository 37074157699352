import React from 'react'

import {useQuery} from '@apollo/client'


import Spinner from './Spinner'

import { GET_BRANDS } from '../graphql/queries/brandQueries'
import BrandCard from './BrandCard'


function Brands() {
    const {loading,error,data}=useQuery(GET_BRANDS)
    // if(loading) return <Spinner/>
    if(error) return <p>Something went wrong</p>
    
  return (
    <>
    <div className='container'>

    {data.brands.length > 0 ? (<div className='row mt-5 ' >
        {data.brands.map((brand)=>{
            return <BrandCard key={brand.brandId} brand={brand}/>

        })}

    </div>):(<p>No brands</p>)}

      
    </div>
    
    
    </>
  )
}

export default Brands