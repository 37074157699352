import { isNonNullObject } from "@apollo/client/utilities";
import { createCanvas, loadImage } from "canvas";

export const imgFormat = (el, type = "serve", obj = {}) => {
  let extension = obj?.originalImageExtension;
  return `${
    type === "serve"
      ? process.env.REACT_APP_AWS_S3_URL_SERVING?.trim()
      : process.env.REACT_APP_AWS_S3_URL_UPLOAD?.trim()
  }Image${el}.${extension && extension !== "" ? extension : "jpeg"}`;
};

// export const vidFormat = (el, type = "serve") =>
//   `${
//     type === "serve"
//       ? process.env.REACT_APP_AWS_S3_URL_SERVING?.trim()
//       : process.env.REACT_APP_AWS_S3_URL_UPLOAD?.trim()
//   }Video${el}.mp4`;

export const vidFormat = (el, type = "serve", obj = {}) => {
  let hostUrl =
    type === "serve"
      ? process.env.REACT_APP_AWS_S3_URL_SERVING?.trim()
      : process.env.REACT_APP_AWS_S3_URL_UPLOAD?.trim();
  if (!obj?.originalVideoExtension) return `${hostUrl}Video${el}.mp4`;
  let prefix = obj?.videoModified ? "Video" : "OriginalVideo";
  let suffix = obj?.videoModified ? "mp4" : obj?.originalVideoExtension;
  return `${hostUrl}${prefix}${el}.${suffix}`;
};

export const fetchResourceFromURI = async (uri) => {
  const response = await fetch(uri);
  // console.log(response, "Fetching resource");
  const blob = await response.blob();

  return blob;
};

export const onChangeTextHandler = (val, name, fState, setFState) => {
  let obj = { ...fState };
  obj[name] = val;
  setFState(obj);
};

export const ellipsisFileName = (val = "", num = 11) => {
  let valArr = val.split(".");
  let strSuffix = valArr.pop();
  let str = valArr.join(".");
  return ellipsis(str, num) + "." + strSuffix;
};

export const ellipsis = (val, num = 11) => {
  return String(val).trim().length > num
    ? `${String(val).trim().substring(0, num)}...`
    : String(val).trim();
};

export const getTitle = (val, num = 11) => {
  return typeof val === "string" && val.trim().length > num ? val : undefined;
};
export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};

export const getCountryName = (val) => {
  let arr = [{ title: "united states", code: "US" }];
  let findRecord = arr.find(
    (el) => el.title.trim().toLowerCase() === val.trim().toLowerCase()
  );
  return findRecord ? findRecord?.code : val;
};

export const parseStringToObject = (val, type = []) => {
  try {
    return JSON.parse(val);
  } catch {
    return type;
  }
};

export const parseObjectToString = (val, type = []) => {
  try {
    return JSON.stringify(val);
  } catch {
    return val;
  }
};

export const exportToJPeg = async (file) => {
  try {
    const image = await loadImage(file);

    const canvas = createCanvas(image.width, image.height);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, image.width, image.height);

    // Convert the image to PNG format
    const convertedImageData = canvas.toDataURL("image/jpeg");

    // Create a new Blob with the converted image data
    const convertedBlob = await fetch(convertedImageData).then((res) =>
      res.blob()
    );

    // Create a new File with the Blob and original file name
    // const convertedFile = new File(
    //   [convertedBlob],
    //   file.name.replace(/\.[^/.]+$/, ".png"),
    //   {
    //     type: "image/png",
    //     lastModified: Date.now(),
    //   }
    // );
    return convertedBlob;
  } catch (err) {
    // console.log(err);
    return;
  }
};

export const identifyCardType = (cardNumber) => {
  // Define regular expressions for card types
  var visaRegex = /^4\d{12}(\d{3})?$/;
  var mastercardRegex = /^5[1-5]\d{14}$/;
  var amexRegex = /^3[47]\d{13}$/;
  var discoverRegex = /^6(?:011|5\d{2})\d{12}$/;

  // Check the card number against each regex
  if (visaRegex.test(cardNumber)) {
    return "visa";
  } else if (mastercardRegex.test(cardNumber)) {
    return "mastercard";
  } else if (amexRegex.test(cardNumber)) {
    return "amex";
  } else if (discoverRegex.test(cardNumber)) {
    return "discover";
  } else {
    return "Unknown";
  }
};

export const remainingTextCount = (text, count) => {
  let diff = count - text?.length;
  return `${isNaN(diff) ? 0 : diff} Characters Remaining`;
};

export const validateInput = (
  val,
  type,
  message = null,
  enqueueSnackbar = null
) => {
  let truthValue;
  switch (type) {
    case "tel":
      truthValue = /^-?\d+$/.test(val);
      break;
    case "email":
      truthValue = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
      break;
    case "url":
      truthValue =
        /^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/.test(
          val
        );
      break;
    default:
      truthValue = val && String(val).trim() !== "";
  }

  if (!truthValue) {
    message &&
      enqueueSnackbar &&
      enqueueSnackbar(message, {
        variant: "error",
      });
  }

  return !truthValue;
};
