import {
  ImageRounded,
  VideoImage,
  VideoImageCont,
  VideoImageFullWrapper,
  VideoImageWrapper,
} from "./styled";
import DeleteIcon from "../../assets/images/roundedDelete.svg";
import React, { useState } from "react";

export default React.memo(({ image, clickFn, clickDeleteFn }) => {
  const [hover, setHover] = useState(false);
  // console.log(image, "pics");
  if (image) {
    image = URL.createObjectURL(image);
  }
  return (
    <VideoImageFullWrapper
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      <VideoImageWrapper onClick={() => clickFn()}>
        <VideoImageCont>
          <VideoImage src={image} />
        </VideoImageCont>
      </VideoImageWrapper>
      <ImageRounded hover={hover}>
        {hover && (
          <img
            src={DeleteIcon}
            onClick={() => clickDeleteFn()}
            alt=""
            style={{ height: 22 }}
          />
        )}
      </ImageRounded>
    </VideoImageFullWrapper>
  );
});
