import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const FadeContainer = styled.div`
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 500ms;
  animation: ${(props) => (props.show ? fadeIn : fadeOut)} 500ms;
`;
