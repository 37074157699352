import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const BContainer = styled(Box)`
  width: 95%;
  margin: 1.5rem auto;
`;

export const BCont1 = styled(Box)`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
`;
export const BCont1_1 = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 117px;
  // width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const BCont1_1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;
export const BCont2_1_2 = styled(Box)`
  display: flex;
  padding: 9px 16px 9px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #dae1dd;
  background: #f1f3ec;
  cursor: pointer;
`;

export const BCont3 = styled(Box)`
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 42px;
`;
export const BCont2_1_2Text = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;
export const BCont2_1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  // min-width: 512px;
`;

export const BCont1_1_1Inner = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;
export const BCont1_1_1Inner2 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const BCont1_1_1Inner2Text1 = styled(Box)`
  color: var(--Black, #000);
  text-align: center;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 33.6px */
`;
export const BCont1_1_1Inner1Text1 = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
`;

export const BCont1_1_1Inner2Text2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 20px */
`;

export const BCont1_1_2_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;
export const BCont1_1_2 = styled(Box)`
  display: flex;
  width: 512px;
  height: 269px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 14px;
  border: 1px solid #ececf2;
  background: #f1f3ec;
`;

export const BCont1_1_1Text = styled(Box)`
  color: #242423;
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 24px */
`;

export const BContH1 = styled(Box)`
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const BContSP = styled(Box)`
  display: inline-block;
  color: var(--Bounced, #d93030);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;

  line-height: 100%; /* 16px */
`;
export const BContSP2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  margin-left: 10px;
  display: inline-block;
`;

export const BCButton = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledFrame = styled.div`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  padding: 16px 10%;
  margin-top: 12px;
  width: 80%;
  // Add media queries for different screen sizes
  @media (max-width: 1200px) {
    padding: 16px 8%;
  }

  @media (max-width: 992px) {
    padding: 16px 5%;
    // width: 100%
  }

  @media (max-width: 768px) {
    padding: 16px 3%;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 42px;
    margin-bottom: 117px;
  }

  @media (max-width: 576px) {
    padding: 16px 3.5%;
    // width: 100%;
  }

  & .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 299px;
    height: 24px;
  }

  & .tab-icons {
    height: 24px;
    position: relative;
    width: 24px;
  }

  & .div-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }

  & .div-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    height: 19px;
    padding: 0px 0px 2px;
    position: relative;
  }

  & .text-wrapper {
    color: rgba(26, 27, 36, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.5px;
    position: relative;
    width: fit-content;
  }

  & .vuesax-twotone-arrow {
    height: 16px;
    position: relative;
    transform: rotate(90deg);
    width: 16px;
  }

  & .img {
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-90deg);
    width: 16px;
  }

  & .billing-plans {
    color: rgba(133, 137, 149, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.5px;
    position: relative;
    width: fit-content;
  }

  & .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 33px;
    position: relative;
  }

  & .div-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }

  & .billing-plans-2 {
    color: #000000;
    font-family: "DM Sans", Helvetica;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  & .div-5 {
    display: flex;
    flex: 0 0 auto;
    position: relative;
    justify-content: space-between;
    width: 100%;
    gap: 117px;
    @media (max-width: 768px) {
      width: 100%; /* Adjusted width for smaller screens */
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  & .div-6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }

  & .div-62 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }

  & .text-wrapper-2 {
    color: #242423;
    font-family: "DM Sans", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-7 {
    align-items: flex-start;
    background-color: #f1f3ec;
    border: 1px solid;
    border-color: #ececf2;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 269px;
    padding: 10px;
    position: relative;
    width: 456px; /* Default width */

    @media (max-width: 768px) {
      width: 395px; /* Adjusted width for smaller screens */
    }

    @media (max-width: 430x) {
      width: 395px; /* Adjusted width for smaller screens */
    }

    @media (max-width: 390px) {
      width: 375px; /* Adjusted width for smaller screens */
    }

    @media (max-width: 375px) {
      width: 360px; /* Adjusted width for smaller screens */
    }
  }

  & .div-8 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }

  & .interface-essential {
    height: 34px !important;
    position: relative !important;
    width: 34px !important;
  }

  & .div-9 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }

  & .text-wrapper-3 {
    color: #000;
    font-family: "DM Sans", Helvetica;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33.6px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-wrapper-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }

  & .text-wrapper-4 {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .p {
    color: rgba(0, 0, 0, 0.5);
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-wrapper-3 {
    align-items: center;
    align-self: stretch;
    background-color: rgba(26, 27, 36, 1);
    border-radius: 14px;
    display: flex;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 24px 40px;
    position: relative;
    width: 100%;

    /* Add hover effect */
    &:hover {
      background-color: #2c2d3f;
      cursor: pointer;
    }

    /* Add active effect */
    &:active {
      background-color: #3d3e52;
      box-shadow: 0px 5px 5px rgba(110, 79, 217, 0.15);
    }
  }

  & .text-wrapper-5 {
    color: #fff;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 10.1px;
    margin-bottom: -78.5px;
    margin-top: -80.5px;
    position: relative;
    width: fit-content;
  }

  & .basic-alert-icons {
    align-items: center;
    background-color: #f1f3ec;
    border: 1px solid;
    border-color: #dae1dd;
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 11px;
    padding: 11px;
    justify-content: center;
    // padding: 9px 16px 9px 12px;
    position: relative;
  }
  .tab-icons {
    height: 24px;
    position: relative;
    width: relative;
  }

  & .group {
    // background-image: url{PlusButton};
    // background-size: 100% 100%;
    height: 24px;
    left: 0.5px;
    position: relative;
    top: 0.5px;
    width: 24px;
  }

  & .txt-wrapper-6 {
    color: rgba(0, 0, 0, 1);
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  & .text-wrapper-7 {
    color: #000000;
    font-family: "DM Sans", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
`;

export const BillingFrame = styled.div`
   {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    width: 100%;
  }

  & .billing-wrap {
    color: #000000;
    font-family: "DM Sans";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }

  // & .billing-group {
  //   height: 254px;
  //   position: relative;
  //   width: 1142px;
  // }

  // & .billing-overlap-group {
  //   height: 253px;
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 1142px;
  // }

  // & .vector {
  //   height: 1px;
  //   left: 0;
  //   object-fit: cover;
  //   position: absolute;
  //   top: 38px;
  //   width: 1134px;
  // }

  // & .img {
  //   height: 1px;
  //   left: 1px;
  //   object-fit: cover;
  //   position: absolute;
  //   top: 182px;
  //   width: 1134px;
  // }

  // & .vector-2 {
  //   height: 1px;
  //   left: 1px;
  //   object-fit: cover;
  //   position: absolute;
  //   top: 110px;
  //   width: 1134px;
  // }

  // & .billing-div {
  //   align-items: flex-start;
  //   display: flex;
  //   justify-content: space-between;
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 1142px;
  // }

  // & .billing-div-2 {
  //   height: 253px;
  //   position: relative;
  //   width: 170px;
  // }

  // & .billing-wrapper-2 {
  //   color: var(--darkgrey-palettepaynes-graypaynes-gray-100);
  //   font-family: "DM Sans-Regular", Helvetica;
  //   font-size: 16px;
  //   font-weight: 400;
  //   left: 0;
  //   letter-spacing: 0;
  //   line-height: 14px;
  //   position: absolute;
  //   top: -1px;
  //   white-space: nowrap;
  // }

  // & .billing-group-2 {
  //   height: 163px;
  //   left: 0;
  //   position: absolute;
  //   top: 65px;
  //   width: 167px;
  // }

  // & .billing-wrapper-3 {
  //   color: var(--darkgrey-paletterasin-blackrasin-black-100);
  //   font-family: "DM Sans-Regular", Helvetica;
  //   font-size: 16px;
  //   font-weight: 400;
  //   left: 0;
  //   letter-spacing: 0;
  //   line-height: 18px;
  //   position: absolute;
  //   top: 145px;
  //   white-space: nowrap;
  // }

  // & .billing-wrapper-4 {
  //   color: var(--darkgrey-paletterasin-blackrasin-black-100);
  //   font-family: "DM Sans-Regular", Helvetica;
  //   font-size: 16px;
  //   font-weight: 400;
  //   left: 0;
  //   letter-spacing: 0;
  //   line-height: 18px;
  //   position: absolute;
  //   top: 0;
  //   white-space: nowrap;
  // }

  // & .billing-wrapper-5 {
  //   color: var(--darkgrey-paletterasin-blackrasin-black-100);
  //   font-family: "DM Sans-Regular", Helvetica;
  //   font-size: 16px;
  //   font-weight: 400;
  //   left: 0;
  //   letter-spacing: 0;
  //   line-height: 18px;
  //   position: absolute;
  //   top: 72px;
  //   white-space: nowrap;
  // }

  // & .billing-div-3 {
  //   height: 253px;
  //   position: relative;
  //   width: 130px;
  // }

  // & .billing-group-3 {
  //   height: 163px;
  //   left: 0;
  //   position: absolute;
  //   top: 65px;
  //   width: 71px;
  // }

  // & .billing-div-4 {
  //   height: 251px;
  //   position: relative;
  //   width: 93px;
  // }

  // & .billing-group-4 {
  //   height: 163px;
  //   left: 0;
  //   position: absolute;
  //   top: 64px;
  //   width: 102px;
  // }

  // & .billing-group-5 {
  //   height: 163px;
  //   left: 0;
  //   position: absolute;
  //   top: 64px;
  //   width: 107px;
  // }

  // & .billing-group-6 {
  //   height: 232px;
  //   margin-right: -2px;
  //   position: relative;
  //   width: 108.01px;
  // }

  // & .billing-wrapper-6 {
  //   color: var(--darkgrey-palettepaynes-graypaynes-gray-100);
  //   font-family: "DM Sans-Medium", Helvetica;
  //   font-size: 16px;
  //   font-weight: 500;
  //   left: 0;
  //   letter-spacing: 0;
  //   line-height: 14px;
  //   position: absolute;
  //   top: 0;
  //   white-space: nowrap;
  // }

  // & .billing-frame-wrapper {
  //   align-items: center;
  //   display: inline-flex;
  //   gap: 24px;
  //   left: 0;
  //   position: absolute;
  //   top: 54px;
  // }

  // & .billing-div-wrapper {
  //   align-items: center;
  //   background-color: #e0f7ea;
  //   border-radius: 10px;
  //   display: inline-flex;
  //   flex: 0 0 auto;
  //   gap: 10px;
  //   height: 34px;
  //   justify-content: center;
  //   padding: 10px 16px;
  //   position: relative;
  // }

  // & .billing-wrapper-7 {
  //   color: #45a75a;
  //   font-family: "DM Sans-Medium", Helvetica;
  //   font-size: 14px;
  //   font-weight: 500;
  //   letter-spacing: 0;
  //   line-height: normal;
  //   margin-bottom: -1px;
  //   margin-top: -3px;
  //   position: relative;
  //   width: fit-content;
  // }

  // & .billing-wrapper-2 {
  //   align-items: center;
  //   background-color: #e0f7ea;
  //   border-radius: 10px;
  //   display: inline-flex;
  //   gap: 10px;
  //   height: 34px;
  //   justify-content: center;
  //   left: 0;
  //   padding: 10px 16px;
  //   position: absolute;
  //   top: 129px;
  // }

  // & .div-5 {
  //   align-items: center;
  //   display: inline-flex;
  //   gap: 24px;
  //   left: 0;
  //   position: absolute;
  //   top: 198px;
  // }

  // & .div-wrapper-3 {
  //   align-items: center;
  //   background-color: #ffecbc;
  //   border-radius: 10px;
  //   display: inline-flex;
  //   flex: 0 0 auto;
  //   gap: 10px;
  //   height: 34px;
  //   justify-content: center;
  //   padding: 10px 16px;
  //   position: relative;
  // }

  // & .text-wrapper-8 {
  //   color: #e59904;
  //   font-family: "DM Sans-Medium", Helvetica;
  //   font-size: 14px;
  //   font-weight: 500;
  //   letter-spacing: 0;
  //   line-height: normal;
  //   margin-bottom: -1px;
  //   margin-top: -3px;
  //   position: relative;
  //   width: fit-content;
  // }

  // & .vector-3 {
  //   height: 1px;
  //   left: -4652px;
  //   object-fit: cover;
  //   position: absolute;
  //   top: 16355px;
  //   // width: 1133px;
  // }
`;

// export const Frame = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex-direction: column;
//   gap: 33px;
//   position: absolute;
//   left: 151px;
//   top: 139px
// `;

// export const Div = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   flex-direction: column;
//   gap: 32px;
//   position: relative;
// `;

// export const BilingPlans = styled.div`
//   color: #000000;
//   font-family: "DM Sans-SemiBold", Helvetica;
//   font-size: 32px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: normal;
//   margin-top: -1px;
//   position: relative;
//   width: fit-content;
// `;

// export const Div2 = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   gap: 117px;
//   position: relative;
// `;

// export const Div3 = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   flex-direction: column;
//   gap: 24px;
//   position: relative;
// `;

// export const TextWrapper = styled.div`
//   color: #242423;
//   font-family: "DM Sans-SemiBold", Helvetica;
//   font-size: 24px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: 24px;
//   margin-top: -1px;
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// `;

// export const Div4 = styled.div`
//   align-items: flex-start;
//   background-color: #f1f3ec;
//   border: 1px solid;
//   border-color: var(--stroke-path-color);
//   border-radius: 14px;
//   display: flex;
//   flex-direction: column;
//   gap: 24px;
//   height: 269px;
//   padding: 24px;
//   position: relative;
//   width: 512px;
// `;

// export const Div5 = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   flex-direction: column;
//   gap: 16px;
//   position: relative;
// `;

// export const InterfaceEssential = styled.div`
//   height: 34px !important;
//   position: relative !important;
//   width: 34px !important;
// `;

// export const Div6 = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   flex-direction: column;
//   gap: 12px;
//   position: relative;
// `;

// export const TextWrapper2 = styled.div`
//   color: var(--black);
//   font-family: "DM Sans-Medium", Helvetica;
//   font-size: 28px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 33.6px;
//   margin-top: -1px;
//   position: relative;
//   text-align: center;
//   white-space: nowrap;
//   width: fit-content;
// `;

// export const DivWrapper = styled.div`
//   align-items: flex-start;
//   display: inline-flex;
//   flex: 0 0 auto;
//   gap: 12px;
//   position: relative;
// `;

// export const TextWrapper3 = styled.div`
//   color: var(--darkgrey-paletterasin-blackrasin-black-100);
//   font-family: "DM Sans-Medium", Helvetica;
//   font-size: 20px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 20px;
//   margin-top: -1px;
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// `;

// export const P = styled.p`
//   color: var(--darkgrey-palettepaynes-graypaynes-gray-100);
//   font-family: "DM Sans-Regular", Helvetica;
//   font-size: 16px;
//   font-weight: 400;
//   letter-spacing: 0;
//   line-height: 16px;
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// `;

// export const DivWrapper2 = styled.div`
//   align-items: center;
//   align-self: stretch;
//   background-color: "rgba(0, 0, 0, 1)";
//   border-radius: 14px;
//   display: flex;
//   gap: 10px;
//   height: 54px;
//   justify-content: center;
//   margin-bottom: -2px;
//   overflow: hidden;
//   padding: 100px 40px;
//   position: relative;
//   width: 100%;
// `;

// export const TextWrapper4 = styled.div`
//   color: "rgba(255, 255, 255, 1)";
//   font-family: "DM Sans-SemiBold", Helvetica;
//   font-size: 18px;
//   font-weight: 600;
//   letter-spacing: 0;
//   line-height: 10.1px;
//   margin-bottom: -78.5px;
//   margin-top: -80.5px;
//   position: relative;
//   width: fit-content;
// `;

// export const BasicAlertIcons = styled.div`
//   align-items: center;
//   background-color: #f1f3ec;
//   border: 1px solid;
//   border-color: #dae1dd;
//   border-radius: 12px;
//   display: inline-flex;
//   flex: 0 0 auto;
//   gap: 4px;
//   justify-content: center;
//   padding: 9px 16px 9px 12px;
//   position: relative;
// `;

// export const TabIcons = styled.div`
//   height: 24px;
//   position: relative;
//   width: 24px;
// `;

// export const Group = styled.div`
//   background-image: url(./union.svg);
//   background-size: 100% 100%;
//   height: 14px;
//   left: 5px;
//   position: relative;
//   top: 5px;
//   width: 14px;
// `;

// export const TextWrapper5 = styled.div`
//   color: var(--darkgrey-paletterasin-blackrasin-black-100);
//   font-family: "DM Sans-Medium", Helvetica;
//   font-size: 14px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 21px;
//   position: relative;
//   white-space: nowrap;
//   width: fit-content;
// `;

// export const TextWrapper6 = styled.div`
//   color: #000000;
//   font-family: "DM Sans-Medium", Helvetica;
//   font-size: 24px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: normal;
//   position: relative;
//   width: fit-content;
// `;
