import { UPL_VIDEO } from "../../graphql/mutations/videoMutation";
import React from 'react';
import { useMutation, useQuery } from "@apollo/client";

export default () => {
  const [uploadVideo] = useMutation(UPL_VIDEO);
  const handleUpload = async (e) => {
    await uploadVideo({
      variables: {
        input: e.target.files[0],
      },
    });
  };

  return <input type="file" onChange={handleUpload} />;
};
