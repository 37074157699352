import React, { useEffect, useState, useRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {
  ApplyContainer,
  CustomizeDiv,
  JustDiv,
  LabelContainer,
  LabelIconContainer,
  LabelTextContainer,
  MobileDivTitle,
  Title,
  VTDiv,
  VTitle,
  VTitleCustDescription,
  VTitleDescription,
  VTitleText,
} from "./styled";
import { IconButton } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import Button from "../Button";
import Iconize from "../Iconize";
import MarkUp from "../../assets/icons/MarkUp.svg";
import { useSelector } from "react-redux";
import BottomPopup from "../BottomPopup";

/**
 * Component for multiple select with checkboxes and sub-selects.
 *
 * @param {Object} props - Component props
 * @param {Array} props.subSelectData - Data for sub-selects
 * @param {Array} props.data - Data for multiple select
 * @param {number} props.dkey - Key for the component
 * @param {string} props.title - Title of the component
 * @param {boolean} props.apply - Apply button visibility
 * @param {string} props.placeholder - Placeholder text for empty selection
 * @param {function} props.setValues - Function to set selected values
 * @param {Array} props.defaultValue - Default selected values
 * @param {string} props.widthForm - Width of the form
 * @param {boolean} props.viewAllInput - Visibility of "View All" input
 * @param {number} props.menuMarginLeft - Margin left for the menu
 * @param {boolean} props.display - Component visibility
 * @param {boolean} props.disabled - Disable the component
 * @param {boolean} props.removeBorder - Remove the border of the component
 * @param {boolean} props.isOpen - Control the open state of the component
 * @param {function} props.setIsOpen - Function to set the open state
 * @param {React.Element} props.mobileDivTitle - Mobile div title component
 */
export default function MultipleSelectCheckmarks({
  subSelectData = [],
  data,
  dkey = 0,
  title,
  apply,
  applyFn,
  placeholder,
  setValues,
  defaultValue,
  widthForm,
  viewAllInput,
  menuMarginLeft,
  display = true,
  disabled = false,
  removeBorder,
  isOpen = undefined,
  setIsOpen,
  mobileDivTitle,
  specialIcon,
  selheight,
  fullWidth = false,
}) {
  // Check if mobile view
  const isMobile = useSelector((state) => state.responsive.isMobile);

  // State for selected items
  const [pickedItems, setPickedItems] = useState([]);
  const [fstate, setFstate] = useState({});

  // Refs for DOM elements
  const containerRef = useRef(null);
  const selectRef = useRef(null);
  const divRef = useRef(null);

  // Change event handler
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let val = typeof value === "string" ? value.split(",") : value;
    setPickedItems(val);
    if (subSelectData?.length > 0 && setValues) {
      let arr = val.map((el) => ({
        sub: fstate[`sub${el}`] ?? subSelectData[0]?.value,
        value: el,
      }));
      setValues(arr);
    } else if (setValues) {
      setValues(val);
    }
  };

  // Handle sub-select change
  const handleSubChange = (valueOfSub, subDtValue, dtValue) => {
    let obj = { ...fstate, [subDtValue]: valueOfSub };
    setFstate(obj);
    if (setValues) {
      let arr = [];
      let removeSelectedPickedItem = pickedItems.filter((el) => el !== dtValue);

      for (let el of removeSelectedPickedItem) {
        arr.push({ sub: obj[`sub${el}`], value: el });
      }
      arr.push({ sub: obj[`sub${dtValue}`], value: dtValue });
      setValues(arr);
    }
    if (!pickedItems.find((el) => el === dtValue)) {
      setPickedItems([...pickedItems, dtValue]);
    }
  };

  // Handle apply button click
  const handleApply = () => {
    let arr = pickedItems.map((el) => ({ sub: fstate[`sub${el}`], value: el }));
    if (isOpen) setIsOpen && setIsOpen(undefined);
    applyFn && applyFn(arr);
  };

  // Generate sub-select element
  const subSelect = (dtValue) =>
    subSelectData?.length > 0 && (
      <span
        className="exclude"
        style={{ alignContent: "center" }}
        onClick={(e) => e.stopPropagation()}
      >
        <Select
          sx={{
            border: "1px solid #D4DAE1",
            borderRadius: "8px",
            padding: "9px 12px",
            width: "146px",
            height: "40px",
            marginLeft: "9px",
            ".MuiInputBase-root": { height: selheight ?? "40px" },
          }}
          MenuProps={{
            style: {
              fontFamily: "Dm Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              color: "#5D6272",
              zIndex: isMobile && 9 * 10e9,
            },
          }}
          onChange={(e) => {
            handleSubChange(e.target.value, `sub${dtValue}`, dtValue);
          }}
          value={fstate[`sub${dtValue}`] ?? subSelectData[0]?.value}
        >
          {subSelectData.map((item, index) => (
            <MenuItem
              style={{ zIndex: isMobile && 9 * 10e9 }}
              value={item?.value}
              key={`${index}Sub${dtValue}`}
            >
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </span>
    );

  // Function to test all checkboxes
  const testAllChecks = (selected, sub = "view") => {
    if (data.length === selected.length) {
      let dataSelectedlength = data.filter(
        (itm) => (fstate[`sub${itm?.value}`] ?? "view") === sub
      )?.length;
      if (data.length === dataSelectedlength) return true;
    }
    return false;
  };

  // Render selected items
  const renderFunction = (selected) => {
    if (!selected?.length || selected.length === 0) return placeholder;

    for (let el of subSelectData) {
      if (testAllChecks(selected, el.value)) {
        return (
          <LabelIconContainer ref={containerRef}>
            <Iconize
              title="Brands"
              color={`#ccc`}
              background={"white"}
              role={specialIcon ? `All Items` : `Can ${el.value} all`}
              showRoleName
              styleTitle
            />
          </LabelIconContainer>
        );
      }
    }

    let assumedWidth = specialIcon ? 60 : 30,
      sumWidth = 0,
      count = 0;
    let displaySelected = selected.map((item, ind) => {
      let objSelected = data.find((itm) => itm.value === item);
      if (!objSelected) {
        // console.log("no object");
        return null;
      }

      objSelected.labelMenu = objSelected?.lbl ?? objSelected?.label;
      let role =
        subSelectData.length > 0
          ? `Can ${fstate[`sub${objSelected?.value}`] ?? "view"}`
          : "";
      let txtLength = `${role} ${objSelected?.labelMenu}`.length;
      sumWidth += ind === 0 ? txtLength : txtLength + 5;
      if (sumWidth < assumedWidth) {
        return (
          <Iconize
            title={objSelected?.labelMenu}
            color={objSelected?.color}
            background={objSelected?.background}
            role={role}
            showRoleName
          />
        );
      } else {
        count++;
        return null;
      }
    });

    return (
      <LabelIconContainer ref={containerRef}>
        {count === 0 ? (
          displaySelected
        ) : (
          <>
            {displaySelected}
            <Iconize
              title={`+${count}`}
              background={"#EEE9FF"}
              role={``}
              showRoleName
              placeRight
            />
          </>
        )}
      </LabelIconContainer>
    );
  };

  // Render label for data item
  const renderLabel = (dt) => (
    <LabelContainer specialIcon={specialIcon}>
      {specialIcon ? (
        <>{dt?.image}</>
      ) : (
        <Iconize
          title={dt?.label}
          color={dt?.color}
          background={dt?.background}

          // image={dt?.image}
        />
      )}{" "}
      <LabelTextContainer
        specialIcon={specialIcon}
        sx={{
          "::-webkit-scrollbar": { width: 0, height: 0 },
        }}
      >
        {dt?.label}
      </LabelTextContainer>
    </LabelContainer>
  );

  // Set default values
  const setDefaultValues = () => {
    if (defaultValue && defaultValue instanceof Array) {
      let arr1 = [],
        arr2 = [],
        obj = {};
      for (let el of defaultValue) {
        if (subSelectData?.length === 0) {
          arr1.push(el);
        } else {
          arr1.push(el?.value);
          arr2.push({ sub: el?.sub, value: el?.value });
          obj[`sub${el?.value}`] = el?.sub;
        }
      }
      setPickedItems(arr1);
      if (subSelectData?.length > 0) {
        setFstate(obj);
      }
      if (subSelectData?.length > 0 && setValues) {
        setValues(arr2);
      }
    } else {
      setPickedItems([]);
      if (subSelectData?.length > 0) {
        setFstate({});
      }
      if (subSelectData?.length > 0 && setValues) {
        setValues([]);
      }
    }
  };

  // Set values for all items
  const renderToAll = (item) => {
    if (data && data instanceof Array) {
      let arr1 = [],
        arr2 = [],
        obj = {};
      for (let el of data) {
        arr1.push(el?.value);
        if (subSelectData?.length > 0) {
          arr2.push({ sub: item, value: el?.value });
          obj[`sub${el?.value}`] = item;
        }
      }
      setPickedItems(arr1);
      if (subSelectData?.length > 0) {
        setFstate(obj);
      }
      if (subSelectData?.length > 0 && setValues) {
        setValues(arr2);
      }
    }
  };

  // Render the "View All" input
  const renderViewAll = () =>
    data?.length > 0 &&
    viewAllInput && (
      <div
        ref={divRef}
        style={{
          display: "block",
          paddingTop: 5,
        }}
      >
        {isMobile && (
          <>
            {mobileDivTitle}
            <MobileDivTitle
              style={{
                justifyContent: mobileDivTitle && "flex-start",
                paddingLeft: mobileDivTitle && 10,
              }}
            >
              Assign Permission
            </MobileDivTitle>
          </>
        )}
        <div style={{ pointerEvents: !display && "none" }}>
          <VTitle
            active={testAllChecks(pickedItems, "view")}
            onClick={() => {
              renderToAll("view");
            }}
          >
            <VTDiv>
              <div>
                <VTitleText>Can view all</VTitleText>
                <VTitleDescription>
                  Invitee have can view all organisation brands
                </VTitleDescription>
              </div>

              {testAllChecks(pickedItems, "view") && <img src={MarkUp} />}
            </VTDiv>
          </VTitle>
          <VTitle
            active={testAllChecks(pickedItems, "edit")}
            onClick={() => {
              renderToAll("edit");
            }}
          >
            <VTDiv>
              <div>
                <VTitleText>Can edit all</VTitleText>
                <VTitleDescription>
                  Invitee can edit all organisation brands
                </VTitleDescription>
              </div>

              {testAllChecks(pickedItems, "edit") && <img src={MarkUp} />}
            </VTDiv>
          </VTitle>
        </div>
      </div>
    );

  // Render the customized div
  const renderCustomizedDiv = () =>
    data?.length > 0 &&
    viewAllInput && (
      <>
        {display && <CustomizeDiv>Customize permissions</CustomizeDiv>}
        <VTitleCustDescription>
          {display ? "Configure permission type for each brand" : " "}
        </VTitleCustDescription>
      </>
    );

  // Render the apply button
  const renderApply = () =>
    apply &&
    data &&
    data instanceof Array &&
    data.length > 0 && (
      <ApplyContainer
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: 500,
          background: "white",
        }}
        isMobile={isMobile}
      >
        <Button onClick={setDefaultValues}>Cancel</Button>
        <Button
          onClick={handleApply}
          style={{
            background: "#1A1B24", //"#B8A9FF","#1A1B24"
            marginLeft: 10,
            color: "#fff",
          }}
        >
          Apply
        </Button>
      </ApplyContainer>
    );

  // Item height for the menu
  let ITEM_HEIGHT = 100;

  const ITEM_PADDING_TOP = 8;

  let addObjMenuProps = isMobile
    ? {
        style: {
          background: "rgba(0,0,0,0.2)",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          zIndex: 9 * 10e9,
        },
      }
    : {};

  // Menu properties
  const MenuProps = {
    PaperProps: {
      [isMobile ? "sx" : "style"]: {
        maxHeight: isMobile ? undefined : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: isMobile ? "100vw" : 400,
        maxWidth: isMobile && "100vw",
        padding: "0 15px 0 15px",
        marginLeft: isMobile ? "-16px" : menuMarginLeft ?? "3%",
        borderRadius: isMobile && "18px 18px 0px 0px",
        bottom: isMobile && "0px",
        top: isMobile && "auto !important",
      },
      onScroll: (event) => {},
    },
    ...addObjMenuProps,
  };

  // Set default values on component mount
  useEffect(() => {
    setDefaultValues();
  }, []);

  // Determine the container type based on the mobile view
  let DIV = isMobile ? BottomPopup : JustDiv;

  return (
    <div
      id={dkey !== 0 ? dkey : undefined}
      style={{ width: fullWidth ? "100%" : undefined }}
    >
      <FormControl sx={{ width: widthForm ?? "100%" }}>
        <Select
          multiple
          displayEmpty
          open={isOpen}
          value={pickedItems}
          onChange={handleChange}
          renderValue={renderFunction}
          MenuProps={MenuProps}
          disabled={disabled}
          inputProps={disabled && { IconComponent: () => null }}
          fullWidth={fullWidth}
          sx={{
            height: "41px",
            border: !removeBorder && "none",
            borderRadius: "14px",
            width: fullWidth ? "100%" : undefined,
            maxWidth: specialIcon
              ? undefined
              : pickedItems.length >= 1
              ? "270px"
              : "none",
            ".MuiOutlinedInput-notchedOutline": { border: removeBorder && "0" },
          }}
          ref={selectRef}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 500,
              background: "white",
            }}
          >
            {renderViewAll()}
            {renderCustomizedDiv()}
          </div>

          {data.map((dt) => {
            if (!display && pickedItems.indexOf(dt?.value) === -1) return null;
            return (
              <MenuItem
                key={`${dt?.value}M${dkey}`}
                value={dt?.value}
                style={{ overflow: "hidden", paddingLeft: 0 }}
                disabled={!display}
              >
                <Checkbox checked={pickedItems.indexOf(dt?.value) > -1} />
                <ListItemText primary={renderLabel(dt)} />
                {subSelect(dt?.value)}
              </MenuItem>
            );
          })}

          {renderApply()}
        </Select>
      </FormControl>
    </div>
  );
}
