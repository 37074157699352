import { Avatar, IconButton } from "@mui/material";
import React from "react";
import AvatarImage from "../../assets/images/Avatar.svg";
import {
  BlankImage,
  BottomText,
  Container,
  NoActiveDiv,
  PendDiv,
  TextContainer,
  TopText,
} from "./styled";

export default function ({ upperText, lowerText, image, active = true }) {
  const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;
  // console.log(upperText);
  image =
    !image || image == ""
      ? AvatarImage
      : `${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${image}.jpeg`;
  return (
    <Container>
      {active ? (
        <IconButton size="small" sx={{ ml: 0 }}>
          <Avatar
            alt="Avatar image"
            style={{ height: 35, width: 35 }}
            src={image}
          />
        </IconButton>
      ) : (
        <BlankImage />
      )}

      <TextContainer>
        {active ? (
          <>
            <TopText>{upperText}</TopText>
            <BottomText>{lowerText}</BottomText>
          </>
        ) : (
          <NoActiveDiv>
            <TopText style={{ marginBottom: 0 }}>{lowerText} </TopText>
            <PendDiv>Pending</PendDiv>
          </NoActiveDiv>
        )}
      </TextContainer>
    </Container>
  );
}
