import { gql } from "@apollo/client";
import { USER } from "../fragments/user";

export const PROCESS_PAYMENT = gql`
  ${USER}
  mutation processPayment($input: PaymentInput!) {
    processPayment(input: $input) {
      ...UserFields
    }
  }
`;

export const PROCESS_CUSTOMIZED_PAYMENT = gql`
  mutation processCustomizedPayment($input: CustomizedPagePaymentInput!) {
    processCustomizedPayment(input: $input) {
      flag
    }
  }
`;

export const UPDATE_CARD_DETAILS = gql`
  ${USER}
  mutation updateCardDetails($input: PaymentInput!) {
    updateCardDetails(input: $input) {
      ...UserFields
    }
  }
`;

export const REMOVE_CARD_DETAILS = gql`
  ${USER}
  mutation removePaymentCard {
    removePaymentCard {
      ...UserFields
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  ${USER}
  mutation cancelSubscription {
    cancelSubscription {
      ...UserFields
    }
  }
`;
