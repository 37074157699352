import React, { useState } from "react";
import PropTypes from "prop-types";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

/**
 * A code editor component for displaying and editing code.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.code - The initial code to display in the editor.
 * @param {boolean} [props.disabled] - Whether the editor is disabled.
 * @param {string} [props.codeId] - The unique identifier for the editor.
 * @param {Function} props.changeHandler - The event handler for code changes.
 * @param {string} props.name - The name of the code editor.
 * @returns {JSX.Element} The code editor component.
 */
export default function CodeEditor({ code, disabled, codeId, changeHandler, name }) {
  const [codeValue, setCodeValue] = useState(code);

  // Function to highlight code with line numbers
  const hightlightWithLineNumbers = (input, language) =>
    highlight(input, language)
      .split("\n")
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join("\n");

  return (
    <Editor
      key={codeId ?? "codeArea"}
      value={codeValue}
      onValueChange={(code) => {
        setCodeValue(code);
        changeHandler && changeHandler(code, name);
      }}
      highlight={(code) => hightlightWithLineNumbers(code, languages.js)}
      padding={10}
      textareaId={codeId ?? "codeArea"}
      className="editor"
      disabled={disabled}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 18,
        outline: 0,
        background: "#FFFFFF",
        border: "1px solid #ECECF2",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.06)",
        backdropFilter: "blur(26px)",
        borderRadius: "9px",
        width: "100%",
      }}
    />
  );
}

// PropTypes for type checking and documentation
CodeEditor.propTypes = {
  code: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  codeId: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
