import styled from "@emotion/styled";
import { Box, Button, Divider, Typography } from "@mui/material";

export const StyledFormLabelText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 1rem;
`;
export const StyledHeaderText = styled(Typography)`
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  margin-bottom: 1.5rem;
`;
export const StyledBannerText = styled(Typography)`
  font-family: "Switzer";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;
export const StyledBannerHeaderText = styled(Typography)`
  font-weight: 500;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;
export const StyledDividerText = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: -10px;
`;

export const StyledSpacedColumContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  padding-bottom: 25px;
  justify-content: space-between;
  border-radius: 21px;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
`;
export const StyledSpacedBannerHousingContainer = styled(Box)`
  height: 100%;
  display: flex;
  width: 100%;
  padding: 15px;
  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledSpacedRowContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledAuthButton = styled(Button)`
  background: #070b0d;
  box-shadow: 0px 15px 15px rgba(110, 79, 217, 0.15);
  // border-radius: 7px;
  border-radius: 15px;
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  height: 46px;
  font-family: Dm Sans !important;
  &:hover {
    background: #1a1b24;
  }
`;
export const GoogleAuthButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #c1c7ca;
  border-radius: 7px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const FlexRowContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const FlexColumnContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAuthContainer = styled(Box)`
  margin-left: 86px;
  margin-right: 86px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  @media (max-width: 1024px) {
    margin-top: 4px;
    margin-left: 26px;
    margin-right: 26px;
  }
  @media (max-width: 768px) {
    margin-top: 4px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;
export const StyledAuthLogoContainer = styled(Box)`
  margin-top: 24px;
  width: 100%;
  display: flex;
  margin-bottom: 204px;
  @media (max-width: 1024px) {
    margin-bottom: 104px;
  }
  @media (max-width: 768px) {
    margin-bottom: 84px;
  }
`;
