import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import AddImage from "../../assets/icons/AddImage.svg";
import BlankMessage from "../../assets/images/BlankMessage.svg";
import Lock from "../../assets/images/Lock.svg";
import HidePassword from "../../assets/images/HidePassword.svg";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";
import AvatarImage from "../../assets/images/Avatar.svg";

import EditProfileButton from "../../assets/images/EditProfileButton.svg";
import RoundEdit from "../../assets/images/RoundEdit.svg";
import Layout from "../../components/Layout";
import { DashBoardContainer } from "../UserDashboard/styled";
import SubHeader from "../../components/SubHeader";
import {
  FormContainer,
  FormLabel,
  HidePasswordBox,
  InputBox,
  PasswordChange,
  ProfileBoxContainer,
  ProfileEmail,
  ProfileEmailContainer,
  ProfileEmailText,
  ProfileImage,
  ProfileName,
  ProfileNameContainer,
} from "./styled";
import { GET_USER_BY_ID } from "../../graphql/queries/user";
import { useMutation, useQuery } from "@apollo/client";
import { Alert, Avatar, Box, TextField, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import AWS from "aws-sdk";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../../App.css";
import {
  CHANGE_PASSWORD,
  UPDATE_PROFILE_NO_FILE,
} from "../../graphql/mutations/user";
import { setUser } from "../../redux/auth.slice";
import { useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ColorButton from "../../components/ColorButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { queryParams } from "../../utils/Helpers";
import {
  ProfileDiv,
  TopContainer,
  TopTitle,
} from "../../components/MobileProfile/styled";
import { TabTitle } from "../../utils/HeaderTitle";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    width: "93px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Dm Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: " 15px",
  },
}));

function Profilepage() {
  TabTitle(`Profile`);
  const profileData = useSelector((state) => state.auth);
  const isMobile = useSelector((state) => state.responsive.isMobile);
  const queryData = queryParams();
  // console.log(queryData, "queryData");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(GET_USER_BY_ID, {
    variables: { input: { userId: profileData?.user?._id } },
  });
  const userDetails = data?.findUserById;
  // console.log(userDetails);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [mobileLoading, setMobileLoading] = useState(false);
  const [email, setEmail] = useState(undefined);

  const [editProfile, setEditProfile] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [showText, setShowText] = useState("");
  const [showImage, setShowImage] = useState(true);
  const [src, setSrc] = useState(null);
  const [filen, setFile] = useState(null);
  const [blob, setBlob] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  // console.log(thumbnail);
  // console.log(name);
  // console.log(userDetails?.thumbnail);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 0,
    y: 0,
    width: 50,
    height: 50,
  });
  const [croppedThumbnail, setCroppedThumbnail] = useState(null);
  const imageRef = useRef(null);
  let fileUrl = "";
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState(newPassword);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const { handleSubmit, register } = useForm();
  const processEnvDataServing = process.env.REACT_APP_AWS_S3_URL_SERVING;
  // const [changePassword, { data:passwordData, loading:passwordLoading, error:passwordError }] = useMutation(CHANGE_PASSWORD);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [
    changePassword,
    { data: passwordData, loading: passwordLoading, error: passwordError },
  ] = useMutation(CHANGE_PASSWORD);
  const [updateImage] = useMutation(UPDATE_PROFILE_NO_FILE, {
    refetchQueries: [
      {
        query: GET_USER_BY_ID,
        variables: { input: { userId: profileData?.user?._id } },
      },
    ],
  });
  const [updateName] = useMutation(UPDATE_PROFILE_NO_FILE, {
    refetchQueries: [
      {
        query: GET_USER_BY_ID,
        variables: { input: { userId: profileData?.user?._id } },
      },
    ],
  });
  // console.log(src, "srcFile");
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFile(file);
      // console.log(file, "file");
      const reader = new FileReader();

      reader.addEventListener("load", () => setSrc(reader.result));
      // reader.addEventListener("load", () => console.log(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShowCrop(true);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    imageRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // setCrop(percentCrop);
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedThumbnail = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setCroppedThumbnail(croppedThumbnail);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        setBlob(blob);
        blob.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, "image/jpeg");
    });
  };

  const uploadToS3 = async () => {
    try {
      if (croppedThumbnail) {
        // Initialize S3 object
        const s3 = new AWS.S3({
          accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
          region: "us-east-1",
        });
        let addParams = Date.now();
        // Upload image to S3 bucket
        const key = `Image${addParams}.${blob.type.split("/")[1]}`;
        const params = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: key,
          Body: blob,
        };

        const data = await s3.upload(params).promise();

        setThumbnail(addParams.toString());

        await updateImage({
          variables: {
            input: {
              thumbnail: addParams.toString(),
            },
          },
        });

        setOpenSnackbar(true);
        setSnackbarMessage("Profile Image Changed");
        setShowCrop(false);
        setCroppedThumbnail(null);
      }
    } catch (err) {
      console.error("Upload Error", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Upload Error");
    }
  };

  const handleCropCancel = () => {
    setSrc(null);
    setCroppedThumbnail(null);
    setShowCrop(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleEditProfile = () => {
    setEditProfile(true);
  };
  const handleCloseInput = () => {
    setEditProfile(false);
    if (isMobile) {
      inputRef.current.value = ""; // Clear the input value using ref
    }
  };

  const handleChangeNameFn = async (event) => {
    try {
      setMobileLoading(true);
      await updateName({
        variables: {
          input: {
            name: event.target.value,
          },
        },
      });

      // After saving, reset the state
      setEditProfile(false);
      setOpenSnackbar(true);
      setSnackbarMessage("Name Changed Successfully");
    } catch (error) {
      console.error("Error updating name:", error);
    } finally {
      setMobileLoading(false);
    }
  };

  const handleNameChange = async (event) => {
    setName(event.target.value);

    if (event.key === "Enter") {
      await handleChangeNameFn(event);
    }
  };

  const showPasswordBox = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((prevShow) => !prevShow);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShow) => !prevShow);
  };
  const onSubmit = async (data) => {
    const { currentPassword, newPassword } = data;
    // const confirmNewPassword = newPassword;
    if (newPassword.length < 6) {
      setNewPassword("New Password should be at least 6 characters long.");
      return;
    }
    // Form validation

    try {
      // Submit password change mutation
      const passwordResult = await changePassword({
        variables: {
          input: {
            currentPassword,
            newPassword,
            confirmNewPassword: newPassword,
          },
        },
      });

      // Check if password change was successful
      if (passwordResult && passwordResult.data) {
        const passwordData = passwordResult.data.changeUserPassword;
        if (
          typeof passwordData === "object" &&
          Object.keys(passwordData).length > 0
        ) {
          setOpenSnackbar(true);
          setSnackbarMessage("Password Changed Successfully");
          // Reset form fields
          setCurrentPassword("");
          setNewPassword("");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setMobileLoading(true);
    await handleSubmit(onSubmit)();
    setMobileLoading(false);
  };
  // console.log(passwordError)
  // console.log(newPassword);
  const inputNameBox = () => (
    <InputBox isMobile={isMobile}>
      {" "}
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={!isMobile && handleNameChange}
        style={{
          border: "none",
          outlineStyle: "none",
          fontFamily: "Dm Sans",
          fontSize: isMobile ? "16px" : "24px",
          lineHeight: isMobile ? "60%" : "154%",
          fontStyle: "normal",
        }}
        ref={inputRef}
      />
      <img
        src={RemoveIcon}
        style={{
          marginLeft: "1.5rem",
          width: "20px",
          marginTop: "2px",
        }}
        alt="edit-profile-button"
        onClick={handleCloseInput}
      />
    </InputBox>
  );

  const uploadBox = () => {
    // console.log("srccccfile", src);
    return (
      <>
        {showCrop && (
          <div
            className="crop-container"
            style={{
              padding: "10px",
              position: "absolute",
              marginLeft: !isMobile && "7rem",
              width: isMobile && "80vw",
              zIndex: "5",
              background: "#FFFFFF",
              border: "1px solid #ECECF2",
              boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
              minWidth: 200,
            }}
          >
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              ruleOfThirds
              // style={{ width: 200, height: 200 }}
            />
            <div
              className="crop-buttons"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ColorButton
                onClick={uploadToS3}
                title="Save"
                width="45%"
                background="#B8A9FF"
              ></ColorButton>
              <ColorButton
                onClick={handleCropCancel}
                title="Cancel"
                width="45%"
              >
                Cancel
              </ColorButton>
            </div>
          </div>
        )}
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
      </>
    );
  };

  const passwordBox = () => (
    <>
      <form style={{ width: isMobile && "100%" }}>
        <FormContainer isMobile={isMobile}>
          <div style={{ width: isMobile ? "100%" : "48%", marginTop: "20px" }}>
            <FormLabel htmlFor="currentPassword">Current Password:</FormLabel>
            <TextField
              type={showCurrentPassword ? "text" : "password"}
              id="currentPassword"
              {...register("currentPassword", { required: true })}
              error={errors.currentPassword ? true : false}
              helperText={
                errors.currentPassword ? "Current password is required" : ""
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleCurrentPasswordVisibility}>
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ margin: "8px 0px 8px" }}
            />
          </div>
          <div style={{ width: isMobile ? "100%" : "48%", marginTop: "20px" }}>
            <FormLabel htmlFor="newPassword">New Password:</FormLabel>
            <TextField
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              {...register("newPassword", { required: true })}
              error={errors.newPassword ? true : false}
              helperText={errors.newPassword ? "New password is required" : ""}
              sx={{ margin: "8px 0px 8px" }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleNewPasswordVisibility}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </FormContainer>
        {newPassword && <Alert severity="error">{newPassword}</Alert>}
        {passwordError && (
          <Alert severity="error">{passwordError?.message}</Alert>
        )}
        <div
          style={
            isMobile
              ? { position: "absolute", bottom: "80px", width: "95%" }
              : { marginTop: "2rem" }
          }
        >
          <ColorButton
            onClick={handleFormSubmit}
            title={passwordLoading ? "Loading" : isMobile ? "Update" : "Submit"}
            background="#1A1B24"
            width={isMobile ? "95%" : "184px"}
          />
        </div>
      </form>
    </>
  );
  useEffect(() => {
    setName(userDetails?.name);
    setThumbnail(userDetails?.thumbnail);
    setEmail(userDetails?.email);
  }, [userDetails?.thumbnail, userDetails?.name, userDetails?.email]);

  return (
    <>
      <DashBoardContainer>
        <SubHeader
          btnTitle=""
          btnLink="/profile/"
          menuLink={isMobile && queryData?.type ? "Profile" : undefined}
          arrLink={[
            {
              title: "Go back to dashboard",
              link: "/",
            },
            { title: "Profile" },
          ]}
        />
        <ProfileBoxContainer isMobile={isMobile}>
          {isMobile ? (
            queryData?.type === "security" ? (
              <>
                <TopContainer style={{ padding: 0, marginTop: -16 }}>
                  <TopTitle>Security</TopTitle>
                </TopContainer>
                <>{passwordBox()}</>
              </>
            ) : (
              <>
                <TopContainer style={{ padding: 0, marginTop: -16 }}>
                  <TopTitle>Profile</TopTitle>
                </TopContainer>
                <ProfileDiv
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 0,
                    position: "relative",
                  }}
                >
                  {thumbnail ? (
                    <Avatar
                      alt="Avatar image"
                      src={`${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${thumbnail}.jpeg`}
                      sx={{ height: "80px", width: "80px" }}
                    />
                  ) : (
                    <Avatar
                      alt="Avatar image"
                      src={AvatarImage}
                      sx={{ height: "80px", width: "80px" }}
                    />
                  )}
                  <div
                    onClick={() =>
                      document.getElementById("image-upload").click()
                    }
                    style={{ position: "absolute", top: "50px", left: "50%" }}
                  >
                    <img src={RoundEdit} />
                  </div>

                  {/* <NameDiv>
                  <TopName>{name}</TopName>
                  <BottomName>{email}</BottomName>
                </NameDiv> */}
                </ProfileDiv>
                <FormLabel style={{ marginBottom: 6 }}>Name:</FormLabel>
                {inputNameBox()}
                <FormLabel
                  style={{ marginBottom: 6, marginTop: 34, color: "#8C8D91" }}
                >
                  Email:
                </FormLabel>
                <InputBox
                  isMobile={isMobile}
                  style={{ background: "#F9F9F9", color: "#8C8D91" }}
                >
                  {email}
                </InputBox>
                <div
                  style={{
                    position: "absolute",
                    bottom: "80px",
                    width: "95%",
                  }}
                >
                  <ColorButton
                    onClick={handleChangeNameFn}
                    title={mobileLoading ? "Loading" : "Update"}
                    background="#1A1B24"
                    width="95%"
                  />
                </div>
                {uploadBox()}
              </>
            )
          ) : (
            <>
              <ProfileNameContainer>
                {/* {
                data.thumbnail?:
              } */}
                <Box>
                  <div>
                    <div
                      className="upload-container"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="upload-box"
                        style={{
                          width: "100px",
                          height: "100px",
                          backgroundImage: thumbnail
                            ? `url(${process.env.REACT_APP_AWS_S3_URL_SERVING}Image${thumbnail}.jpeg)`
                            : "#F7F7F7",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "100px",
                          marginTop: "0.5rem",
                          zIndex: "2",
                        }}
                        onMouseEnter={() => {
                          setShowText(true);
                          setShowImage(false);
                        }}
                        onMouseLeave={() => {
                          setShowText(false);
                          setShowImage(true);
                        }}
                        onClick={() =>
                          document.getElementById("image-upload").click()
                        }
                      >
                        {!croppedThumbnail && (
                          <>
                            {showImage && !thumbnail && <img src={AddImage} />}
                            {showText && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={EditProfileButton}
                                  style={{ width: "20px" }}
                                  alt="edit-profile-button"
                                />
                                <p
                                  className="upload-text"
                                  style={{
                                    fontSize: "10px",
                                    lineHeight: "12px",
                                    fontFamily: "Dm Sans",
                                    // color: "#FFFFFF",
                                    color: "#1a1b24",

                                    paddingLeft: "0.5rem",
                                    paddingRight: "0.5rem",
                                    marginTop: "10px",
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    textShadow:
                                      "0px 1px 4px rgba(0, 0, 0, 0.35)",
                                  }}
                                >
                                  Change profile picture
                                </p>
                              </div>
                            )}
                          </>
                        )}
                        {croppedThumbnail && (
                          <>
                            <img
                              src={croppedThumbnail}
                              alt="Cropped image"
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "100px",
                              }}
                            />
                          </>
                        )}
                      </div>
                      {uploadBox()}
                    </div>
                  </div>
                </Box>
                {editProfile ? (
                  <>{inputNameBox()}</>
                ) : (
                  <>
                    {" "}
                    <ProfileName>{name}</ProfileName>
                    <BootstrapTooltip title="Edit Profile Name">
                      <img
                        src={EditProfileButton}
                        style={{
                          marginLeft: "1.5rem",
                          width: "20px",
                          marginTop: "2px",
                        }}
                        alt="edit-profile-button"
                        onClick={handleEditProfile}
                      />
                    </BootstrapTooltip>
                  </>
                )}
              </ProfileNameContainer>
              <ProfileEmailContainer>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={BlankMessage} alt="message" />
                  <ProfileEmailText>Email address:</ProfileEmailText>
                </Box>

                <ProfileEmail>{email}</ProfileEmail>
              </ProfileEmailContainer>
              <ProfileEmailContainer>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={Lock} alt="message" />
                    <ProfileEmailText>Password:</ProfileEmailText>
                  </Box>

                  <PasswordChange onClick={showPasswordBox}>
                    {showPassword ? "Hide" : "Change"}
                  </PasswordChange>
                </Box>
                {showPassword ? (
                  <>{passwordBox()}</>
                ) : (
                  <HidePasswordBox>
                    {" "}
                    <img src={HidePassword} alt="message" />
                  </HidePasswordBox>
                )}
              </ProfileEmailContainer>
            </>
          )}
        </ProfileBoxContainer>
      </DashBoardContainer>
    </>
  );
}

export default Profilepage;
