import { FormControl, Select } from "@mui/material";
import { MenuItemC } from "../../pages/Customization/styled";

export default ({
  name,
  focusState,
  formStyle = {},
  setFocusState,
  arrValues,
  ...rest
}) => {
  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        // marginBottom: "34px",

        ".MuiInputBase-root": {
          borderRadius: "15px",
        },
        ...formStyle,
      }}
    >
      <Select
        {...rest}
        sx={{
          height: "50px",

          ".MuiOutlinedInput-notchedOutline": {
            border: focusState[name]
              ? `1px solid #007bf7 !important`
              : "ipx solid #D4DAE1",
            boxShadow: focusState[name]
              ? "0px 0px 4px 0px rgba(69, 193, 126, 0.40) !important"
              : undefined,

            ":hover": {
              border: focusState[name]
                ? `1px solid #007bf7 !important`
                : "1px solid #1a1b24",
            },
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: "12px",
              padding: "0.4em 0.3em",
              boxShadow: "0px 7px 30px rgba(0, 0, 0, 0.07)",
            },
          },
          sx: {
            borderRadius: "12px",
            marginTop: "10px",
            "& .Mui-selected": {
              backgroundColor: "rgba(25, 118, 210, 0.08)",
            },
          },
        }}
        onFocus={() => setFocusState({ ...focusState, [name]: true })}
        onBlur={() => setFocusState({ ...focusState, [name]: false })}
      >
        {arrValues.map((el, i) => (
          <MenuItemC key={i} value={el?.value}>
            {el?.name}
          </MenuItemC>
        ))}
      </Select>
    </FormControl>
  );
};
