import { gql } from "@apollo/client";

const ADD_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      _id
      organizationId
      name
      industry
    }
  }
`;

const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      _id
      name
      
      industry
      organizationId
    }
  }
`;
const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      name
      industry
      
    
      organizationId
    }
  }
`;
const USER_OGRANIZATION_INVITE = gql`
  mutation inviteUser($input: CreateUserOrganizationInviteInput!) {
    inviteUser(input: $input) {
      _id
      organization
      invitationCode
      invitedUserEmail
      createdAt
    }
  }
`;
const TESTIMONIAL_INVITE = gql`
mutation testimonialInvite($input: TestimonialInviteInput!) {
  testimonialInvite(input: $input) {
    invitedUserEmail
  brandId
  }
}
`;

const RESEND_USER_OGRANIZATION_INVITE = gql`
  mutation resendInviteUser($input: ResendUserOrganizationInviteInput!) {
    resendInviteUser(input: $input) {
      _id
      organization
      invitationCode
      invitedUserEmail
      createdAt
    }
  }
`;

const DELETE_USER_OGRANIZATION_INVITE = gql`
  mutation deleteInviteUser($input: DeleteInviteInput!) {
    deleteInviteUser(input: $input) {
      _id
      organization
      invitationCode
      invitedUserEmail
      createdAt
    }
  }
`;

export {
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  USER_OGRANIZATION_INVITE,
  RESEND_USER_OGRANIZATION_INVITE,
  DELETE_USER_OGRANIZATION_INVITE,
  TESTIMONIAL_INVITE
};
