import React from "react";
import PropTypes from "prop-types";
import { getTitle } from "../../../utils/format";
import {
  Description,
  DescriptionBox,
  Post,
  Rating,
  StarRatingBox,
  Title,
} from "./styled";
import { Box } from "@mui/material";
import StarRating from "../../../assets/images/StarRating.svg";

export default function ({ name, angle, rating, position, style, isMobile }) {
  // alert(name)

  return (
    <Description style={style} angle={angle}>
      <DescriptionBox angle={angle}>
        <Title title={getTitle(name, 15)} angle={angle} isMobile={isMobile}>
          {name}
        </Title>

        <Post title={getTitle(position, 18)} angle={angle}>
          {position ? position : <>{""}</>}
        </Post>
      </DescriptionBox>
      {rating ? (
        <StarRatingBox background={StarRating} isMobile={isMobile}>
          <Rating angle={angle}>{rating}</Rating>
        </StarRatingBox>
      ) : (
        ""
      )}
    </Description>
  );
}

// Define PropTypes for type checking
// PersonInfo.propTypes = {
//   name: PropTypes.string.isRequired,
//   angle: PropTypes.number.isRequired,
//   rating: PropTypes.number.isRequired,
//   position: PropTypes.string,
//   style: PropTypes.object,
// };
