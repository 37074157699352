import styled from "@emotion/styled";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";

// export const DisplayDiv = styled(Box)`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 10px;
//   background-color: #f7f7f7;
// `;

export const DivCont = styled(Box)`
  width: 70%; /* Adjust as needed */
`;

export const MessageContainer = styled.div`
  // Add your styling for the message container here
`;

export const MessageText = styled.p`
  // Add your styling for the message text here
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 120px);
`;

export const InstructionDiv = styled(Box)`
  /* Styles for the instruction div */
  margin-top: 20px;
`;

// export const DivCont = styled(Box)`
//   /* Styles for the button container */
//   display: flex;
//   gap: 10px;
// `;

export const StepNumber = styled(Box)`
  /* Styles for the step number */
  font-size: 16px;
  font-weight: bold;
  color: #1a1b24;
  background-color: #e3eefa;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepContainer = styled(Box)`
  /* Styles for the step container */
  margin-top: 20px;
`;

export const StepInstructionDiv = styled(Box)`
  /* Styles for the step instruction div */
  margin-left: 20px;
`;

export const StepTitle = styled(Box)`
  /* Styles for the step title */
  font-size: 18px;
  font-weight: bold;
  color: #1a1b24;
`;

export const CopyButton = styled(Button)`
  /* Styles for the copy button */
  border-radius: 10px;
`;

export const CopyText = styled(Box)`
  /* Styles for the copy text */
  font-size: 14px;
  color: #1a1b24;
  margin-left: 8px;
`;

export const StepTitle2 = styled(Box)`
  /* Styles for the second step title */
  font-size: 16px;
  color: #1a1b24;
  margin-bottom: 20px;
`;

export const StepTitle2Bold = styled(Box)`
  /* Styles for the bold part of the second step title */
  font-weight: bold;
`;

export const StepTitle2Bold2 = styled(Box)`
  /* Styles for another bold part of the second step title */
  font-weight: bold;
`;

export const StepTitle3 = styled(Box)`
  /* Styles for the third step title */
  font-size: 16px;
  color: #1a1b24;
  margin-top: 20px;
`;

export const VerifiedButton = styled(Button)`
  /* Styles for the verified button */
  border-radius: 15px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
`;

export const Container = styled(Box)`
  // background: white;
  width: 100%;
  padding-top: 28px;
  // padding-left: 32px;
  margin-top: -53px;
  margin-bottom: 30px;
`;

export const ColorButtonText = styled.span`
  color: ${(props) => (props.textColor ? props.textColor : "#000")};
`;

export const MenuItemC = styled(MenuItem)`
  cursor: pointer;
  padding: 10px 0 10px 10px;
  margin: 6px 0;
  width: 100%;
  border-radius: ${(props) => !props.isMobile && "12px"};

  &:hover {
    background-color: ${(props) => !props.isMobile && "#f7f7f7"};
  }
`;

export const Title = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin-top: ${(props) => (props?.isMobile ? undefined : "43px")};
  margin-bottom: 12px;
`;

export const SubTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d6272;
`;
export const SubTitleDiv = styled(Box)`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  align-items: center;
  width: ${(props) => (props?.isMobile ? "100%" : "80%")};
  // width: 87%;
  // margin-top: 23px;
`;
export const SubTitleDiv2 = styled(Box)`
  font-size: 14px;
  color: #0075ce;
  font-family: Dm Sans;
  line-height: 20px;
  width: 100%;
  // margin-top: 10px;
`;

export const LeftDiv = styled(Box)`
  display: flex;
  flexdirection: row;
  justify-content: flex-start;
  // border: 1px solid #ccc;
`;

export const IndicatorDiv = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;

  height: 28px;

  background: ${(props) => (props?.active ? "#5ee690" : "#D4DAE1")};
  border-radius: 8px;
  margin-left: 12px;
`;

export const LeftSubTitleDiv = styled(Box)`
  color: #1a1b24;
  font-size: 16px;
  font-family: Dm Sans;
  font-weight: 500;
  line-height: 150%;
`;

export const RadioItem = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  opacity: 0.6;
`;

export const LeftTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #1a1b24;
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const OnButton = styled(ButtonBase)`
  display: flex;
  padding: 0 25.5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #d4dae1;
  color: #1a1b24;
  font-size: 14px;
  font-family: Dm Sans;
  height: 40px;

  cursor: pointer;
`;

export const TabButton = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.5px 24px;
  gap: 10px;

  height: 40px;
  background: ${(props) => (props?.active ? "#d9eaf8" : "#fff")};
  border: ${(props) =>
    props?.active ? "1px solid #007BF7" : "1px solid #d4dae1"};

  margin-right: 16px;
  cursor: pointer;

  border-radius: 14px;
`;

export const TabDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

export const TypeButton = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 18px;
  gap: 10px;
  height: 38px;
  border-radius: 10px;
  background: ${(props) => (props?.active ? "#d9eaf8" : "#fff")};
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  cursor: pointer;
`;
export const TabText = styled(Box)`
  // font-family: "Dm Sans";
  font-style: normal;

  font-size: 14px;

  /* identical to box height */

  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;

  font-weight: 500;
  line-height: normal;
  color: ${(props) => (props?.active ? "#1a1b24" : "#5D6272")};
`;

export const LeftDescription = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5d6272;
  margin-bottom: 32px;
`;

export const DynamicDiv = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : "60%")};
`;

export const LeftSubTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1a1b24;
  margin-bottom: 13.77px;
`;

export const WidgetButDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // min-height: 64px;
`;

export const TypeDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const DisplayDiv = styled(Box)`
  box-sizing: border-box;
  border: 1px solid ${(props) => (props?.isMobile ? "#D4DAE1" : "#D4DAE1")};
  border-radius: 10px;
  padding: 24px;
  height: 200%; /* Adjust the height as needed */
  position: relative; /* Position relative for absolute positioning */
  marting-bottom: 16px;
`;

export const TemplateCard = styled.div`
  width: 18%;
  height: 10%; /* Adjust height as needed */
  background-color: #f8f9fa; /* Background color for the card */
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .glass {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const TemplateImage = styled.img`
  width: 100%;
  height: 75%; /* 75% of the card height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
`;

export const CardContent = styled.div`
  padding: 10px; /* Padding for content */
  color: #495057; /* Default text color */
`;

export const DeleteButton = styled.button`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
`;

export const SectionDivType = styled(Box)`
  display: flex;
  padding: 36px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #fff;

  /* Dropdown shadow */
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(26px);
  height: fit-content;
  ${(props) =>
    props?.section &&
    `display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    padding: 36px;
  `}
`;

export const DisplayDivTitle = styled(Box)`
  // font-family: "Dm Sans";
  // font-style: normal;
  // font-weight: 500;
  // font-size: 14px;
  // line-height: 18px;
  // color: #5d6272;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1a1b24;
  // padding-left: 10px;
  margin-bottom: 24px;
  ${(props) =>
    props?.isMobile &&
    `display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%
  `}// padding-left: 23px;
`;
export const DynamicSectionDiv = styled(Box)`
  width: ${(props) => (props?.isMobile ? "100%" : "80%")};
  // width: 87%;

  margin-bottom: 32px;
`;

export const EmailBadgeDiv = styled(Box)`
  display: flex;

  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  background: #fff;
  width: 80%;
`;

export const StylTextField = styled(TextField)`
  border-radius: 15px;
`;

export const SectionTitle = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1a1b24;
  margin-top: 24px;
  margin-bottom: 14px;
`;
export const SectionSubTitle = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const SocialLinkDiv = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: relative;
`;
export const SocialOutlineBox = styled(Box)`
  border: ${(props) =>
    props?.checked ? "1px solid rgba(0, 117, 206, 1)" : "none"};
  border-radius: 10px;
  padding: ${(props) => (props?.checked ? "4px" : "4px")};
  margin-bottom: 16px;
`;

export const ControlDiv = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 60%;
  flex-direction: row;
  margin-top: 14px;
  flex-wrap: wrap;
`;

export const AlignContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

export const ButtonContainer = styled(Box)`
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  right: 20px; /* Adjust the right position as needed */
  display: flex;
  flex-direction: row;
`;

export const SectionDiv = styled(Box)`
  height: 90%;
  ${(props) =>
    props?.section &&
    `display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;

export const SectionHeader = styled(Box)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 600;
  // font-size: 24px;
  // line-height: 30px;
  line-height: 160%;

  text-align: center;
  color: #1a1b24;
`;
export const SubSectionHeader = styled(Box)`
  // margin-top: 8px;
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  // font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #1a1b24;
`;

export const VideoDiv = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const ReviewDiv = styled(ButtonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 27px;
  gap: 10px;
  // height: 36px;
  background: #1a1b24;
  border-radius: 100px;
  cursor: pointer;

  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // line-height: 17px;
  line-height: 160%;
  color: #ffffff;
`;
export const ReviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ImageDiv = styled(Box)`
  ${(props) =>
    props.active &&
    ` 
    border: 1px solid #0075ce;
    border-radius: 10px;`}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 16px;
  gap: 16px;
  height: 70px;
  background: #ffffff;

  cursor: pointer;
`;

export const CheckLabel = styled(Typography)`
  font-family: "Dm Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  text-align: center;

  /* Dark+Grey Palette/Payne’s Gray/Payne’s Gray 100% */

  color: #5d6272;
`;

export const SectionHeadContainer = styled(Box)`
  display: flex;
  justify-content: ${(props) =>
    props?.textAlign === "left" ? "space-between" : "center"};
  align-items: center;
  flex-direction: ${(props) =>
    props?.textAlign === "left" ? "row" : "column"};
  width: 100%;
`;

export const TopHead = styled(Box)`
  width: ${(props) =>
    props?.textAlign === "left" && props?.review ? "70%" : "100%"};
`;

export const EmptyDiv = styled(Box)`
  justify-content: center;
  display: flex;
  align-items: center;
  height: 80%;
`;

export const PreviewButton = styled(ButtonBase)`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 9px;
  border-radius: 15px;
  border: 1px solid #d4dae1;
  cursor: pointer;
`;

export const PreviewText = styled(Box)`
  color: #1a1b24;
  font-family: Dm Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const PictureBox = styled(Box)`
  display: flex;
  padding: 25px 17.5px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #eeeef2;
  flex-direction: column;
  cursor: pointer;
  position: relative;
`;
