import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, noLeft, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#1A1B24",

    left: (props) => (props?.noLeft ? undefined : "20% !important"),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1A1B24",
    // width: "93px",
    // height: "32px",
    padding: "10px 16px 14px 16px",

    borderRadius: "7px",

    marginLeft: "10px",
  },
}));

function CustomTooltip({ headerText, bodyText, children, noLeft }) {
  return (
    <BootstrapTooltip
      noLeft={noLeft}
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="toolheader">{headerText}</div>
          <div className="toolbody">{bodyText}</div>
        </div>
      }
    >
      {children}
    </BootstrapTooltip>
  );
}

export default CustomTooltip;
