import React from 'react'

import {useQuery} from '@apollo/client'

import {  GET_ORGANIZATIONS_FOR_USER } from '../graphql/queries/organizationQueries'
import OrganizationRow from './OrganizationRow'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Organizations() {
    const {loading,error,data}=useQuery(GET_ORGANIZATIONS_FOR_USER)
    if (loading) return <p> Loading </p>;
    if(error) return <p>Something went wrong</p>
  
  return (
    <>{!loading && !error &&(
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell >Domain Url</TableCell>
              <TableCell >Industry</TableCell>
              <TableCell >Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                {data.organizationsForUser.map(organization=>(
                    <OrganizationRow key={organization.organizationId} organization={organization}  />
                ))}
            </TableBody>
      </Table>
    </TableContainer>
    )}</>
  )
}

export default Organizations