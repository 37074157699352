import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PContainer = styled(Box)`
  display: flex;
  width: 512px;
  min-height: 269px;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid var(--Element, #d4dae1);
  background: #fff;
`;

export const PCont1 = styled(Box)`
  display: flex;
  height: 142.5px;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

export const PCont1_1 = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
`;

export const PCont1_1_1 = styled(Box)`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const PCont1_1_1Box = styled(Box)`
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--Element, #d4dae1);
  width: 64px;
  height: 64px;
  padding: 17px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const PCont1_1Text = styled(Box)`
  color: var(--dark-grey-palette-paynes-gray-paynes-gray-100, #5d6272);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
`;

export const PCont1_2 = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`;

export const PCont1_1Inner = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const PCont1_1_2 = styled(Box)`
  color: var(--dark-grey-palette-rasin-black-rasin-black-100, #1a1b24);
  leading-trim: both;
  text-edge: cap;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
`;

export const UpdateCardFrame = styled.div`
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d4dae1;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
//   justify-content: space-between;
  padding: 21px;
  position: relative;
  width: 456px;
  height: 244px;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 65px;
  width: 395px; /* Adjusted width for smaller screens */
}

@media (max-width: 430x) {
  width: 395px; /* Adjusted width for smaller screens */
}

@media (max-width: 390px) {
  width: 375px; /* Adjusted width for smaller screens */
}

@media (max-width: 375px) {
  width: 360px; /* Adjusted width for smaller screens */
}

  & .update-div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 13px;
    position: relative;
  }

  & .update-div-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    position: relative;
  }

  & .payments-finance {
    align-items: center;
    border: 1px solid;
    border-color: rgb(212, 218, 225);
    border-radius: 12px;
    display: flex;
    gap: 10px;
    height: 64px;
    justify-content: center;
    padding: 17px 12px;
    position: relative;
    width: 64px;
  }

  & .icon-creditcard-visa-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14.34px;
    justify-content: center;
    margin-left: -1.75px;
    margin-right: -1.75px;
    position: relative;
  }

  & .icon-creditcard-visa {
    height: 29px !important;
    position: relative !important;
    width: 43.5px !important;
  }

  & .update-div-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  & .p-wrapper {
    color: #000;
    font-family: "DM Sans-Medium", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .update-wrap {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 2px;
    position: relative;
  }

  & .ellipse {
    background-color: #000;
    border-radius: 2.59px;
    height: 5.19px;
    position: relative;
    width: 5.19px;
  }

  & .p-wrapper-2 {
    color: rgba(0, 0, 0, 0.5);
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .basic-icons-wrapper {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }

  & .basic-icons {
    align-items: center;
    background-color: #f1f3ec;
    border: 1px solid;
    border-color: #dae1dd;
    border-radius: 12px;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 48px;
    justify-content: center;
    padding: 9px 24px;
    position: relative;
    &:hover {
      background-color: #e1e3dd; // Change as needed
      cursor: pointer;
    }
  
    &:active {
      background-color: #d1d3cd; // Change as needed
    }
  }

  & .p-wrapper-3 {
    color: #1A1B24;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;
