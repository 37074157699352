import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  NodeModalContainerStyle,
  StyledTagButton,
} from "./index.styled";
import { CreateButton } from "../../components/TagsModal/styled";
import { useNodes } from "reactflow";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";

const HEADINGS = [
  "Heading 1",
  "Heading 2",
  "Heading 3",
  "Heading 4",
  "Heading 5",
  "Heading 6",
];

export const formats = [
  "align",
  "background",
  "blockquote",
  "bold",
  "bullet",
  "code",
  "code-block",
  "color",
  "direction",
  "font",
  "formula",
  "header",
  "image",
  "indent",
  "italic",
  "link",
  "list",
  "script",
  "size",
  "strike",
  "table",
  "underline",
  "video",
];
const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-font">
      <option selected />
    </select>
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1" />
      <option value="2" />
      <option value="3" />
      <option value="4" />
      <option selected />
    </select>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <select className="ql-color">
      <option value="red" />
      <option value="green" />
      <option value="blue" />
      <option value="orange" />
      <option value="violet" />
      <option value="#d0d1d2" />
      <option selected />
    </select>
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="check" />

    <StyledTagButton cusColor="orange" className="ql-insertName">
      {"name"}
    </StyledTagButton>
    <StyledTagButton cusColor="blue" className="ql-insertEmail">
      {"email"}
    </StyledTagButton>
  </div>
);
const EmailModalContent = ({ selected, saveNode, closeModals }) => {
  const [content, setContent] = useState("");
  const quillRef = useRef();
  const nodes = useNodes();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      tag: "",
      subject: "",
    },
  });

  const onSubmit=({subject,tag}) => {
    closeModals();
    saveNode({
      ...selected,
      data: {
        ...selected?.data,
        metadata: {
          ...selected?.data?.metadata,
          subject,
          tag: [`${tag}-wf-${Date.now()}`],
          body: content,
        },
      },
    });
  }

  const insertName = useCallback(() => {
    const cursorPosition = quillRef?.current?.getEditor().getSelection().index;
    quillRef?.current?.getEditor().insertText(cursorPosition, "{{name}}");
    quillRef?.current?.getEditor().setSelection(cursorPosition + 10);
  }, []);

  const insertEmail = useCallback(() => {
    const cursorPosition = quillRef?.current?.getEditor().getSelection().index;
    quillRef?.current?.getEditor().insertText(cursorPosition, "{{email}}");
    quillRef?.current?.getEditor().setSelection(cursorPosition + 9);
  }, []);

  useEffect(() => {
    const currentNode = nodes.find(({ id }) => id === selected?.id);
    const tagPattern = selected?.data?.metadata?.tag?.[0] ?? "";
    const regex = /^([a-zA-Z0-9]+)-wf-(\d+)$/;
    const match = tagPattern.match(regex);
    const tag = match?.[1] ?? "";
    reset({
      subject: currentNode?.data?.metadata?.subject ?? "",
      tag: tag,
    }); 
    setContent(currentNode?.data?.metadata?.body);
  }, [selected]);

  return (
    <NodeModalContainerStyle
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ minHeight: "300px", width: "750px" }}
    >
      <Typography
        sx={{ fontWeight: 700, px: 2, fontFamily: "Switzer", mt: 2 }}
        id="modal-modal-title"
        variant="h5"
        required
      >
        Create Mail
      </Typography>
      <Box>
        <Box
          sx={{
            mt: 2,
            px: 2,
            py: 1.5,
            pb: 6,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <Box>
            <TextField
             error={errors?.["subject"]}
              label="Subject"
              variant="standard"
              type="text"
              required
              sx={{
                width: "100%",
                marginBottom: "20px",
              }}
              {...register("subject", { required: true })}
              helperText={errors?.["subject"]?.message}
            />
            <TextField
             error={errors?.["tag"]}
              label="tag"
              variant="standard"
              type="text"
              required
              sx={{
                width: "40%",
                marginBottom: "20px",
              }}             
              {...register("tag", {
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9]*$/,
                  message: "allowed characters are a-z, A-Z, 0-9",
                },
              })}
              helperText={errors?.["tag"]?.message}
            />
            <Box>
              <CustomToolbar />
              <ReactQuill
                ref={quillRef}
                modules={{
                  toolbar: {
                    container: "#toolbar",
                    handlers: {
                      insertName,
                      insertEmail,
                    },
                  },
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                formats={formats}
                theme="snow"
                value={content}
                onChange={setContent}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            px: 2,
            py: 2,
            borderTop: "1px solid #E5E3E3",
          }}
        >
          <CreateButton type="submit" color="primary">
            Save
          </CreateButton>
        </Box>
      </Box>
    </NodeModalContainerStyle>
  );
};

export default EmailModalContent;
