// import { async } from 'q';
import { useEffect, useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import Layout from '../../components/Layout';
import Snackbar from '@mui/material/Snackbar';
import ProgressBar from '../../components/ProgressBar';
import ButtonCust from '../../components/ButtonCust';
import SpinnerBig from '../../components/SpinnerBig';

const CardsContainer = styled.div`
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled.div`
  height: 30rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceCircle = styled.div`
  border: 0.5rem solid #fff;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.1rem 0.1rem, 1rem rgba(19, 20, 19, 0.342);
`;

const PriceText = styled.p`
  font-size: 3rem;
  color: white;
  text-shadow: 0.1rem 0.1rem, 1rem rgba(19, 20, 19, 0.342);
`;

const PricingPlan = () => {
  const [prices, setPrices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    setLoading(true);
    try {
      const { data: response } = await axios.get('http://localhost:3001/prices');
      setPrices(response.data);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const createSession = async (priceId) => {
    try {
      const { data: response } = await axios.post('http://localhost:3001/checkout_sessions', {
        priceId,
      });
      window.location = response.url;
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage('Error creating checkout session');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Layout>
      <>
        <Snackbar
          open={openSnackbar}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          autoHideDuration={3000}
        />
        {loading && <SpinnerBig />}
        {!loading && !error && (
          <Container>
            <CardsContainer>
              {prices.map((price) => (
                <Card key={price.id} style={{ width: '18rem', height: '25rem', marginRight: '2rem' }}>
                  <CardHeader>
                    <PriceCircle>
                      <PriceText>${price.unit_amount / 100}</PriceText>
                    </PriceCircle>
                  </CardHeader>
                  <Card.Body>
                    <Card.Title style={{ fontSize: '2rem' }}>{price.nickname}</Card.Title>
                    <Button variant="primary" className="mt-2" onClick={() => createSession(price.id)}>
                      Subscribe
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </CardsContainer>
          </Container>
        )}
        {error && <div>Error fetching prices</div>}
      </>
    </Layout>
  );
};

export default PricingPlan;