import { gql } from "@apollo/client";

export const FETCH_LEADS = gql`
  query fetchLeads($input: FetchContactsalesInput!) {
    fetchLeads(input: $input) {
      data {
        _id
        name
        email
        phoneNumber
        message
        status
        newItem
        tag
        updateAt
        phoneCode
        source
        leadGroup {
          _id
          name
        }
        attachedTags
      }
      page {
        total
        pageCount
        pageNumber
        itemsPerPage
        hasNextPage
        hasPrevPage
        totalAllContacts
      }
    }
  }
`;

export const FETCH_NEW_LEADS_COUNT = gql`
  query fetchNewLeadCount($input: FetchContactsalesInput!) {
    fetchNewLeadCount(input: $input) {
      count
    }
  }
`;
