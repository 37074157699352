import { gql } from "@apollo/client";

export const ADDPLACE_ID = gql`
  mutation addPlaceID($input: GoogleReviewPlaceIDInput!) {
    addPlaceID(input: $input) {
      _id
    }
  }
`;

export const SEND_GOOGLE_REVIEW_REQUEST = gql`
  mutation sendGoogleRequest($input: RequestGoogleReviewInput!) {
    sendGoogleRequest(input: $input) {
      _id
    }
  }
`;
