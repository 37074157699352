import React, { useEffect, useRef, useState } from "react";
import {
  DeleteModalContainer,
  ModalContainer,
  StyledSectionButton,
} from "../TagsModal/styled.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Modal from "@mui/material/Modal";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import { Box, Typography, Stack, Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_ALL_LEAD_GROUP } from "../../graphql/queries/leadgroup";
import { format } from "date-fns";
import { DELETE_SINGLE_LEAD_GROUP } from "../../graphql/mutations/leadgroup.js";
import RemoveIcon from "../../assets/icons/RemoveIcon.svg";

const tableHead = ["Name", "Created At"];
const LeadsGroupModal = ({ open, handleClose, reloadLeadList }) => {
  const menu = useSelector((state) => state.menu);
  const br_id = menu?.id;
  const [groupList, setGroupList] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const toggleDelete = () => setOpenDelete((x) => !x);
  const {
    data: list,
    loading: leadsLoading,
    refetch,
  } = useQuery(GET_ALL_LEAD_GROUP, {
    variables: {
      input: { br_id },
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    // console.log("this effect is running");
    if (list?.leadGroups) {
      setGroupList(list?.leadGroups);
    }
  }, [list]);

  useEffect(() => {
    if (list?.leadGroups && open) {
      refetch();
    }
  }, [open]);
  const [deleteLeadGroup] = useMutation(DELETE_SINGLE_LEAD_GROUP);

  const handleDelete = async () => {
    setLoading(true);
    try {
      let { data: dataUpdate, errors } = await deleteLeadGroup({
        variables: { id: currentGroup?._id },
      });
      if (!dataUpdate && errors) {
        enqueueSnackbar(
          (errors && errors[0] && errors[0]?.message) || "Error Deleting group",
          {
            variant: "error",
          }
        );

        return;
      }
      if (dataUpdate) {
        refetch();
        reloadLeadList && reloadLeadList();
      }
      setOpenDelete(false);
      enqueueSnackbar(`deleted successfully`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(`delete failed`, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContainer>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img
              src={RemoveIcon}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Typography
            sx={{ fontWeight: 700, fontFamily: "Switzer", mt: 2 }}
            id="modal-modal-title"
            variant="h5"
          >
            Leads Group List
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              borderTop: "2px solid #E5E3E3",
            }}
          ></Box>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table
              sx={{ minWidth: 500 }}
              stickyHeader
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {tableHead.map((title) => (
                    <TableCell
                      sx={{ fontWeight: 700, py: 2, fontFamily: "Switzer" }}
                      key={title}
                    >
                      {title}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{ fontWeight: 700, py: 2, fontFamily: "Switzer" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupList instanceof Array &&
                  groupList?.map((row) => (
                    <TableRow
                      key={row._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td:last-child, & td:first-child": {
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                        },
                      }}
                    >
                      <TableCell sx={{ fontFamily: "Switzer" }}>
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ fontFamily: "Switzer" }}>
                        {format(row?.createdAt, "dd MMM, yyyy pp")}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          color="error"
                          onClick={() => {
                            setCurrentGroup(row);
                            setOpenDelete(true);
                          }}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalContainer>
      </Modal>
      <>
        <Modal
          open={openDelete}
          onClose={toggleDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DeleteModalContainer>
            <Box sx={{ py: 1, borderBottom: "1px solid #E5E3E3" }}>
              <Typography
                sx={{ fontWeight: 700, fontFamily: "Switzer", px: "8px" }}
                id="modal-modal-title"
                variant="h6"
              >
                Delete Selected Items
              </Typography>
            </Box>

            <Box sx={{ p: "8px", py: 3 }}>
              <Typography sx={{ fontFamily: "Switzer" }}>
                Are you sure you want to delete
                <strong>
                  {" "}
                  <em>{currentGroup?.name}</em>{" "}
                </strong>
                Group?
              </Typography>
            </Box>
            <Stack
              sx={{ px: "8px", py: 2 }}
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
            >
              <StyledSectionButton onClick={toggleDelete}>
                Cancel
              </StyledSectionButton>
              <Button
                color="error"
                variant="contained"
                disabled={loading}
                sx={{
                  borderRadius: "15px",
                  fontFamily: "Switzer",
                  fontSize: "14px",
                  textTransform: "none",
                  px: 2,
                }}
                onClick={handleDelete}
              >
                {loading ? "loading..." : "Delete"}
              </Button>
            </Stack>
          </DeleteModalContainer>
        </Modal>
      </>
    </>
  );
};

export default LeadsGroupModal;
