import {gql} from '@apollo/client'


const GET_INDUSTRIES =gql`
query industrys{
    industrys{
        _id
        industryId
        name
      
    }
}
`
export {GET_INDUSTRIES}


