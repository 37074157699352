import React, { useState } from 'react'
import {Line} from 'react-chartjs-2'
import {Chart as ChartJS,Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler} from 'chart.js'
import { DISAPPROVED_VIDEOS_DAYS_COUNT } from '../graphql/queries/videosQueries';
import { useQuery } from '@apollo/client';
import Spinner from './Spinner';
ChartJS.register(
    Title,Tooltip,LineElement,Legend,CategoryScale,LinearScale,PointElement,Filler
)
function DisapproveChart({id, startDate, endDate}) {
  const { loading: approvedLoading, error: approvedError, data: approvedData } = useQuery(DISAPPROVED_VIDEOS_DAYS_COUNT, {
    variables: { brandId: id, startDate: startDate, endDate: endDate },
    fetchPolicy: 'cache-and-network',
  });

  if (approvedLoading || approvedError) {
    return <Spinner />;
  }

  const videoCounts = approvedData?.disapprovedDaysVideoCount.slice(0, -1).map(({ date, count,percentChange }) => ({ date, count,percentChange }));


  const newData = {
    labels: videoCounts.map(({ date }) => date),
    datasets: [
      {
        data: videoCounts.map(({ count }) => count),
        borderColor: '#8D6CFF',
        tension: 0.5,
        borderWidth: 1,
        pointStyle: 'none',
      },
    ],
  };

  const percentageChange = [];

  // Calculate percentage change between adjacent data points
  for (let i = 1; i < videoCounts.length; i++) {
    const prevCount = videoCounts[i - 1].count;
    const currentCount = videoCounts[i].count;
    const change = ((currentCount - prevCount) / prevCount) * 100;
    percentageChange.push(change.toFixed(2));
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          // Display percentage change as tooltip label
          label: (context) => {
            const label = context.dataset.label || '';
            if (label) {
              return `${label}: ${context.parsed.y} (${percentageChange[context.dataIndex]}%)`;
            }
            return `${context.parsed.y} (${percentageChange[context.dataIndex]}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  // remove the 'label' property from the dataset
  newData.datasets[0].label = undefined;

  return (
    <div className="chart-container" style={{ height: '50px', position: 'relative' }}>
      <Line data={newData} options={options} style={{ width: '100%' }} />
    </div>
  );
}

export default DisapproveChart

// import React from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, LineElement, Legend, TimeScale, LinearScale, PointElement, Filler } from 'chart.js';
// import { useQuery } from '@apollo/client';
// import moment from 'moment';
// import Spinner from './Spinner';
// import { DISAPPROVED_VIDEOS_DAYS_COUNT } from '../graphql/queries/videosQueries';

// // import moment.js date adapter for Chart.js
// import 'chartjs-adapter-moment';

// ChartJS.register(Title, Tooltip, LineElement, Legend, TimeScale, LinearScale, PointElement, Filler);

// function DisapproveChart({ id, days }) {
//   const { loading: approvedLoading, error: approvedError, data: approvedData } = useQuery(DISAPPROVED_VIDEOS_DAYS_COUNT, {
//     variables: { brandId: id, days: days },
//     fetchPolicy: 'cache-and-network',
//   });

//   if (approvedLoading || approvedError) {
//     return <Spinner />;
//   }

//   const videoCounts = approvedData?.disapprovedDaysVideoCount.map(({ date, count }) => ({ date: moment(date), count }));

//   const newData = {
//     labels: videoCounts.map(({ date }) => date),
//     datasets: [
//       {
//         data: videoCounts.map(({ count }) => count),
//         borderColor: '#8D6CFF',
//         tension: 0.5,
//         borderWidth: 1,
//         pointStyle: 'none',
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//     scales: {
//       x: {
//         type: 'time', // set x-axis scale type to 'time'
//         time: {
//           tooltipFormat: 'll', // set tooltip format
//           unit: getUnit(videoCounts), // determine appropriate time unit based on range of dates
//           displayFormats: {
//             day: 'MMM D', // set display format for day units
//             week: 'MMM D', // set display format for week units
//             month: 'MMM', // set display format for month units
//             quarter: 'Q', // set display format for quarter units
//           },
//           parser: 'moment', // use moment.js to parse dates
//         },
//         ticks: {
//           autoSkip: true,
//           maxTicksLimit: 10,
//           maxRotation: 0,
//           font: {
//             size: 10,
//           },
//         },
//         grid: {
//           display: true,
//           drawOnChartArea: false,
//           drawTicks: true,
//         },
//       },
//       y: {
//         ticks: {
//           display: false,
//         },
//         border: {
//           display: false,
//         },
//         grid: {
//           display: false,
//           drawOnChartArea: true,
//           drawTicks: false,
//         },
//       },
//     },
//     elements: {
//       point: {
//         radius: 0,
//       },
//     },
//   };

//   // remove the 'label' property from the dataset
//   newData.datasets[0].label = undefined;

//   return (
//     <div className="chart-container" style={{ height: '50px', position: 'relative' }}>
//       <Line data={newData} options={options} style={{ width: '100%' }} />
//     </div>
//   );
// }

// // helper function to determine appropriate time unit based on range of dates
// function getUnit(data) {
//   const firstDate = moment(data[0].date);
//   const lastDate = moment(data[data.length - 1]);

//   const diffInDays = lastDate.diff(firstDate, 'days');
//   if (diffInDays >= 365) {
//   return 'year';
//   } else if (diffInDays >= 90) {
//   return 'quarter';
//   } else if (diffInDays >= 30) {
//   return 'month';
//   } else if (diffInDays >= 7) {
//   return 'week';
//   } else {
//   return 'day';
//   }
//   }
  
//   export default DisapproveChart;
  
  

