import { Box, IconButton } from "@mui/material";

import Table from "../Table";

import { useEffect, useState } from "react";
import { TitleText } from "../CampaignList/styled";

import { useQuery, useMutation } from "@apollo/client";

import { useSnackbar } from "notistack";
import { CreateButton } from "../SalesList/styled";

import AddModal from "./addModal";
import { FETCH_VERIFIED_EMAILS } from "../../graphql/queries/sendgrid";

export default ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    data,
    loading: loadingData,
    refetch,
  } = useQuery(FETCH_VERIFIED_EMAILS);

  const reload = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const tableHeadCells = [
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email Address",
      width: "20%",
    },
  ];

  const getRows = () => {
    let rows = (
      data?.fetchVerifiedEmails instanceof Array
        ? data?.fetchVerifiedEmails
        : []
    ).map((row) => {
      let obj = { ...row };

      return obj;
    });

    return rows;
  };
  return (
    <Box sx={{ minHeight: "400px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TitleText sx={{ my: 3 }}>Verified Email Addresses</TitleText>
        <Box>
          <CreateButton
            hasBorder
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Add New
          </CreateButton>
        </Box>
      </Box>

      {loadingData && loading ? (
        "Loading ..."
      ) : (
        <Table
          headCells={tableHeadCells}
          // rowClick={rowClick}
          rows={getRows()}
          stylehead
        />
      )}
      <AddModal open={openModal} setOpen={setOpenModal} refetch={reload} />
    </Box>
  );
};
